/* eslint-disable dot-notation */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

// ICONS
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { useUrlQuery } from '../../hooks/useUrlQuery';
import FeedbackAlert, { FeedbackAlertType } from '../FeedbackAlert';

export type FilterType = {
  text: string;
  key: string;
  disabled: boolean;
  icon: ReactNode;
};

export type ListType = {
  text: string;
  key: string;
  disabled: boolean;
  icon: ReactNode;
  filters: FilterType[];
  content: React.ReactNode;
  badge?: number;
  count?: number;
  hidden?: boolean;
};

type RulesType = {
  value: string;
  allow: string;
};

type PermissionsType = {
  manager: boolean;
};

const lastPermition: PermissionsType = {
  manager: false,
};

function ListOption(props: { icon: ReactNode; text: string; badgeContent?: number }) {
  const theme = useTheme();

  const { icon, text, badgeContent = 0 } = props;
  const widthSmLess = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flex: 1,
        justifyContent: widthSmLess ? 'center' : 'flex-start',
        alignItems: 'center',
      }}
    >
      <Badge
        color="warning"
        badgeContent={badgeContent}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {icon}
      </Badge>

      <Typography sx={{ display: widthSmLess ? 'none' : 'flex' }}>{text}</Typography>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      />
    </Box>
  );
}
export interface IPageHomeWithMenu {
  viewList: ListType[];
  defaultView: string;
  topButtons?: ReactNode;
}

export default function PageHomeWithMenu({ viewList, topButtons, defaultView }: IPageHomeWithMenu) {
  const theme = useTheme();
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();

  const currentUrlView = urlQuery.get('view') ?? defaultView;
  const currentUrlFilter = urlQuery.get('filter') ?? 'started';
  const currentUrlPage = urlQuery.get('page') ?? '1';

  const widthSmLess = useMediaQuery(theme.breakpoints.down('sm'));

  const [permissions, setPermissions] = useState<PermissionsType>(lastPermition);

  const [fetching, setFetching] = useState(false);

  const selectedView = viewList.find(v => v.key === currentUrlView);
  const selectedFilter = selectedView?.filters?.find(v => v.key === currentUrlFilter);

  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [feedbackType, setFeedbackType] = useState<FeedbackAlertType>('success');

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      const filter = 'all';

      const params = new URLSearchParams({ view: nextView, filter });
      navigate({ search: params.toString() });
    }
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, nextFilter: string) => {
    if (nextFilter !== null) {
      const params = new URLSearchParams({ view: currentUrlView, filter: nextFilter });
      navigate({ search: params.toString() });
    }
  };

  const onSubmited = (success: boolean, msg: string) => {
    setFeedbackMsg('');

    let text = msg.toString();
    if (success) {
      setFeedbackType('success');
    } else {
      setFeedbackType('error');
      if (text === 'invalid_expected_end_date') {
        text = 'Data fim previsto não pode ser vazia ou menor que a data de início previsto.';
      } else if (text === 'Error: Request failed with status code 404') {
        text = 'API não encontrada ou indisponível';
      }
    }

    setFeedbackMsg(text);
  };

  const renderMenu = useMemo(
    () => (
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
        {widthSmLess && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
            <Typography>{selectedView?.text?.toUpperCase()}</Typography>
          </Box>
        )}
        <ToggleButtonGroup
          orientation={widthSmLess ? 'horizontal' : 'vertical'}
          value={selectedView?.key}
          exclusive
          onChange={handleViewChange}
          fullWidth
        >
          {viewList?.map((v, i) => {
            const { icon, text, hidden } = v;
            const key = `${v.key}-${i}`;
            const disabled = v.key === 'management' ? !permissions?.manager ?? true : v.disabled;

            return (
              !hidden && (
                <ToggleButton value={v.key} aria-label="list" key={key} color="info" fullWidth disabled={disabled}>
                  <ListOption text={text} icon={icon} badgeContent={v.count} />
                </ToggleButton>
              )
            );
          })}
        </ToggleButtonGroup>
      </Paper>
    ),
    [selectedView, widthSmLess, permissions],
  );

  const renderQuickFilter = useMemo(
    () => (
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flex: 1,
            color: theme.palette.mode === 'dark' ? '#c1c1c1' : '#767676',
          }}
        >
          <FilterAltOutlinedIcon />
          <Typography>Filtro rápido</Typography>
        </Box>
        {widthSmLess && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
            <Typography>{selectedFilter?.text?.toUpperCase()}</Typography>
          </Box>
        )}
        <ToggleButtonGroup
          orientation={widthSmLess ? 'horizontal' : 'vertical'}
          value={selectedFilter?.key}
          exclusive
          onChange={handleFilterChange}
          fullWidth
        >
          {selectedView?.filters.map((filter, index) => {
            const { icon, text, key } = filter;
            const keyList = `${filter.key}-${index}`;
            return (
              <ToggleButton
                value={key}
                aria-label="list"
                key={keyList}
                color="info"
                fullWidth
                disabled={filter.disabled}
              >
                <ListOption text={text} icon={icon} />
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Paper>
    ),
    [selectedView, selectedFilter, widthSmLess],
  );

  const renderContent = useMemo(() => selectedView?.content, [selectedView]);
  const renderTopButtons = useMemo(
    () => (topButtons !== null ? <Box sx={{ display: 'flex', flex: 1, gap: 2 }}>{topButtons}</Box> : null),
    [topButtons],
  );
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flex: 1, width: '100%' }}>
            {renderTopButtons}
            {renderMenu}
            {selectedView?.filters?.length > 0 && renderQuickFilter}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          {renderContent}
        </Grid>
      </Grid>

      <FeedbackAlert message={feedbackMsg} show={feedbackMsg?.length > 0} type={feedbackType} />
    </Box>
  );
}
export {};
