import React, { useCallback, useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, Tooltip, ResponsiveContainer, Legend, YAxis } from 'recharts';
import { useTheme } from '@mui/material/styles';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import { Box, Grid, CircularProgress, Typography } from '@mui/material';

// import { ResponsiveLine } from '@nivo/line';

// import { Box } from '@mui/material';
import { blue, green, grey, orange, purple, red } from '@mui/material/colors';
import Axios, { Canceler } from 'axios';
import styles from './styles';
import api from '../../../../../services/api';
import { minuteToHours } from '../../../../../utils/functions';

export interface ChartLineProps {
  stage: string;
  month?: number;
  year?: number;
}

type TCurve = {
  date: string;
  date_day: string;
  hours_planned: number;
  hours_planned_formated: string;
  hours_realized: number;
  hours_realized_formated: string;
  hours_capacity: number;
  hours_capacity_formated: string;
};
type TTotal = {
  hours_planned: number;
  hours_planned_formated: string;
  hours_realized: number;
  hours_realized_formated: string;
  hours_available: number;
  hours_available_formated: string;
  hours_capacity: number;
  hours_capacity_formated: string;
  hours_deviation: number;
  hours_deviation_formated: string;
  weight_planned: number;
  weight_realized: number;
  weight_deviation: number;
  amount_operator: number;
  hours_efficiency: number;
  weight_efficiency: number;
  productivity_hours: number;
  productivity_operator: number;
};
type TInfo = {
  month: number;
  month_name: string;
  year: number;
  stage: string;
  stage_name: string;
  stage_nickname: string;
  max_value: number;
};
type TResponse = {
  result: {
    info: TInfo;
    total: TTotal;
    curve: TCurve[];
  };
};

type TDetailsData = {
  totalPlained: string;
  totalRealized: string;
  cumulativeProgress: string;
  deviation: string;
  plannedHours: string;
  hoursAvailable: string;
  appointedHours: string;
  appointedDeviation: string;
  operatorsCount: string;
  scheduledWorkingDays: string;
  consumedWorkingDays: string;
  remainingWorkingDays: string;
  average: string;
  averageMonth: string;
  variation: string;
  operatedAmount: string;
};

export default function ChartLine(props: ChartLineProps) {
  const { stage, month, year } = props;

  const classes = styles();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const capacityColor = darkMode ? purple[200] : purple[700];
  const plannedColor = darkMode ? blue[300] : blue[700];
  const realizedColor = darkMode ? orange[300] : orange[700];
  const capacityColorDark = darkMode ? purple[800] : purple[900];
  const plannedColorDark = darkMode ? blue[800] : blue[900];
  const realizedColorDark = darkMode ? orange[700] : orange[900];
  const redColor = darkMode ? red[300] : red[400];
  const greenColor = darkMode ? green[300] : green[400];
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<TDetailsData>({} as TDetailsData);
  const [data, setData] = useState<TCurve[]>([]);
  const [info, setInfo] = useState<TInfo>(null);
  const [total, setTotal] = useState<TTotal>(null);
  const [maxValue, setMaxValue] = useState(0);

  const loadData = useCallback(async (_stage, _month, _year) => {
    let cancel: Canceler;
    if (!loading) {
      setLoading(true);
      try {
        const { data: responseData } = await api.get<TResponse>('erp/production/curve', {
          params: { stage: _stage, month: _month, year: _year },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });
        const { result } = responseData;
        setData(result.curve);
        setInfo(result.info);
        setTotal(result.total);
        setMaxValue(Math.round(0.1 * result.info.max_value + result.info.max_value));
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    return () => cancel();
  }, []);

  useEffect(() => {
    loadData(stage, month, year);
  }, [loadData, month, year, stage]);

  function CustomizedXAxisTick(_props: any) {
    const { x, y, payload } = _props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={4}
          dy={0}
          fontSize={10}
          textAnchor="end"
          fill={darkMode ? grey[500] : grey[800]}
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
  function CustomizedYAxisTick(_props: any) {
    const { x, y, payload } = _props;

    return (
      <g transform={`translate(${x - 10},${y})`}>
        <text x={0} y={4} dy={0} fontSize={10} textAnchor="end" fill={darkMode ? grey[500] : grey[800]}>
          {minuteToHours(payload.value * 60)}h
        </text>
      </g>
    );
  }

  function CustomizedLabel(_props: any) {
    const { x, y, value } = _props;

    return (
      <text x={x} y={y} dy={-4} fill={darkMode ? grey[400] : grey[800]} fontSize={8} textAnchor="middle">
        {`${value},00%`}
      </text>
    );
  }

  function CustomizedLegend(value: string, entry: any) {
    // const { color } = entry;
    return (
      <span style={{ color: darkMode ? grey[400] : grey[800], fontSize: 11 }}>
        {value === 'hours_planned' ? 'Planejado' : value === 'hours_realized' ? 'Realizado' : 'Capacidade'}
      </span>
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltipChartContainer}>
          <span className={classes.tooltipChartTitle}>{`${label}`}</span>
          {payload[0] && (
            <div className={classes.tooltipChartPlaned}>
              <span>Capacidade:</span>
              <span style={{ color: capacityColor, textAlign: 'right' }}>
                {`${minuteToHours(payload[0]?.value * 60)}`}
              </span>
            </div>
          )}
          {payload[1] && (
            <div className={classes.tooltipChartPlaned}>
              <span>Planejado:</span>
              <span style={{ color: plannedColor, textAlign: 'right' }}>
                {`${minuteToHours(payload[1]?.value * 60)}`}
              </span>
            </div>
          )}
          {payload[2] && (
            <div className={classes.tooltipChartPlaned}>
              <span>Realizado:</span>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {payload[1]?.value > payload[2]?.value ? (
                  <ArrowDropDownIcon style={{ color: redColor }} />
                ) : payload[1]?.value === payload[2]?.value ? (
                  <ArrowRightIcon />
                ) : (
                  <ArrowDropUpIcon style={{ color: greenColor }} />
                )}
                <span style={{ color: realizedColor, textAlign: 'right' }}>{`${minuteToHours(
                  payload[2]?.value * 60,
                )}`}</span>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {loading && (
        <Box
          sx={{
            display: 'flex',
            bgcolor: _theme => (_theme.palette.mode === 'dark' ? 'rgb(20 20 20 / 50%)' : 'rgb(255 255 255 / 50%)'),
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box className={classes.chartBox}>
        <Box className={classes.chartTitle} sx={{ flexDirection: 'column' }}>
          <span
            style={{ width: '100%', height: '100%' }}
          >{`Curva S da Produção - ${info?.month_name} de ${info?.year}`}</span>
          <span style={{ width: '100%', height: '100%' }}>{`${info?.stage} - ${info?.stage_name}`}</span>
        </Box>
        <Box className={classes.chartContent}>
          <ResponsiveContainer minHeight={240}>
            <LineChart data={data} margin={{ left: 0, right: 10, top: 20, bottom: 12 }}>
              <CartesianGrid stroke={darkMode ? grey[700] : grey[300]} />
              <XAxis dataKey="date" interval="preserveStartEnd" tick={<CustomizedXAxisTick />} />
              <YAxis
                type="number"
                domain={[0, maxValue]}
                dataKey="hours_capacity"
                interval="preserveStartEnd"
                tick={<CustomizedYAxisTick />}
              />
              <Tooltip content={CustomTooltip} />

              <Legend formatter={CustomizedLegend} />
              <Line
                type="linear"
                dataKey="hours_capacity"
                stroke={capacityColor}
                activeDot={{ r: 5, stroke: capacityColorDark }}
                dot={{ stroke: capacityColor, fill: capacityColor }}
              />
              <Line
                type="linear"
                dataKey="hours_planned"
                stroke={plannedColor}
                activeDot={{ r: 5, stroke: plannedColorDark }}
                dot={{ stroke: plannedColor, fill: plannedColor }}
              />
              <Line
                type="linear"
                dataKey="hours_realized"
                stroke={realizedColor}
                dot={{ stroke: realizedColor, fill: realizedColor }}
                activeDot={{ r: 5, stroke: realizedColorDark }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Box className={classes.chartDetails}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ pb: 1 }}>
                <Typography sx={{ fontSize: 14 }}>Produção / Hora</Typography>
              </Box>
              <Box className={classes.detailsGrid}>
                <Box className={classes.detailsContainer}>
                  <span>Planejado</span>
                  <span>{total?.hours_planned_formated || '00:00'} h</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Realizado</span>
                  <span>{total?.hours_realized_formated || '00:00'} h</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Desvio</span>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: _theme =>
                        _theme.palette.mode === 'dark'
                          ? total?.hours_deviation > 0
                            ? green[200]
                            : red[200]
                          : total?.hours_deviation > 0
                          ? green[600]
                          : red[600],
                    }}
                  >
                    {total?.hours_deviation_formated || '00:00'} h
                  </Typography>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Disponível</span>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: _theme =>
                        _theme.palette.mode === 'dark'
                          ? total?.hours_available > 0
                            ? green[200]
                            : red[200]
                          : total?.hours_available > 0
                          ? green[600]
                          : red[600],
                    }}
                  >
                    {total?.hours_available_formated || '00:00'} h
                  </Typography>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Eficiência</span>
                  <Typography sx={{ fontSize: 14 }}>
                    {total?.hours_efficiency?.toLocaleString('pt-BR') || '0'} %
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ pb: 1 }}>
                <Typography sx={{ fontSize: 14 }}>Produção / Peso</Typography>
              </Box>
              <Box className={classes.detailsGrid}>
                <Box className={classes.detailsContainer}>
                  <span>Planejado</span>
                  <span>{total?.weight_planned?.toLocaleString('pt-BR') || '0'} ton</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Realizado</span>
                  <span>{total?.weight_realized?.toLocaleString('pt-BR') || '0'} ton</span>
                </Box>

                <Box className={classes.detailsContainer}>
                  <span>Desvio</span>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: _theme =>
                        _theme.palette.mode === 'dark'
                          ? total?.weight_deviation > 0
                            ? green[200]
                            : red[200]
                          : total?.weight_deviation > 0
                          ? green[600]
                          : red[600],
                    }}
                  >
                    {total?.weight_deviation?.toLocaleString('pt-BR') || '0'} ton
                  </Typography>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Eficiência</span>
                  <Typography sx={{ fontSize: 14 }}>
                    {total?.weight_efficiency?.toLocaleString('pt-BR') || '0'} %
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ pb: 1 }}>
                <Typography sx={{ fontSize: 14 }}>Produtividade</Typography>
              </Box>
              <Box className={classes.detailsGrid}>
                <Box className={classes.detailsContainer}>
                  <span>Total Operador(es)</span>
                  <span>{total?.amount_operator?.toLocaleString('pt-BR') || '0'}</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Peso / Hora</span>
                  <span>{total?.productivity_hours?.toLocaleString('pt-BR') || '0'} ton/h</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span>Peso / Operador</span>
                  <span>{total?.productivity_operator?.toLocaleString('pt-BR') || '0'} ton/op</span>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
