import React, { useEffect, useRef, useState } from 'react';
import Axios, { Canceler, CancelTokenSource } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import api from '../../services/api';
import Comment from '../Comment';

import styles from './styles';

export type OrderMessageProps = {
  id: string;
};

export type OrderMessageDataType = {
  results: OrderMessageType[];
  filters: {
    result_limit: number;
    page_current: number;
    order: string[];
    id: number[];
    situation: number[];
    created_at: string[];
  };
  statistics: {
    total: {
      results: number;
      pages: number;
    };
  };
};

export type OrderMessageType = {
  id: string;
  order_id: number;
  user_id: number;
  user_name: string;
  user_sector: string;
  text: string;
  created_at: string[];
  my_message: boolean;
};

export default function OrderMessage(props: OrderMessageProps) {
  const classes = styles();
  const mountedRef = useRef(true);
  const textRef = useRef<HTMLInputElement>(null);
  const [gettingData, setGettingData] = useState(false);
  const [postingMessage, setPostingMessage] = useState(false);
  const [data, setData] = useState<OrderMessageDataType>();
  const [messages, setMessages] = useState<OrderMessageType[]>([] as OrderMessageType[]);

  const [pageCurrent, setPageCurrent] = useState(1);

  useEffect(() => {
    getData(1);
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    // document?.querySelector('.messages-div')?.scrollTo(0, document?.querySelector('.messages-div')?.scrollHeight || 0);
  }, [data]);

  async function getData(page_current = 1) {
    if (!mountedRef.current) return;
    if (gettingData) return;
    let cancel: Canceler;
    try {
      setGettingData(true);
      const response: OrderMessageDataType = await (
        await api.get('erp/orders/messages', {
          params: { order_id: props?.id, page_current, order: ['created_at', 'desc'] },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
      ).data;

      if (response?.results?.length > 0) {
        setData(response);
        setMessages(response.results);
      } else {
        setData({} as OrderMessageDataType);
      }
      setGettingData(false);
    } catch (error: any) {
      const data = error?.response?.data;
      if (data) {
        let errorMessage = data?.message;
        if (!errorMessage) errorMessage = data?.error;
        if (errorMessage === '') errorMessage = '';
        else if (errorMessage === '') errorMessage = '';
        else errorMessage = '';
      }
    }
    return () => cancel();
  }

  async function getMoreData() {
    if (gettingData) return;
    let cancel: Canceler;
    try {
      setGettingData(true);
      const response: OrderMessageDataType = await (
        await api.get('erp/orders/messages', {
          params: { order_id: props?.id, page_current: pageCurrent + 1, order: ['created_at', 'desc'] },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
      ).data;

      if (response?.results?.length > 0) {
        // setData(response);
        setMessages([...messages, ...response.results]);
        setPageCurrent(pageCurrent + 1);
      }
      setGettingData(false);
    } catch (error: any) {
      const data = error?.response?.data;
      if (data) {
        let errorMessage = data?.message;
        if (!errorMessage) errorMessage = data?.error;
        if (errorMessage === '') errorMessage = '';
        else if (errorMessage === '') errorMessage = '';
        else errorMessage = '';
      }
      setGettingData(false);
    }
    return () => cancel();
  }

  async function handleSendMessage() {
    const messageText = (textRef?.current?.children[0].children[0] as HTMLInputElement).value || '';
    if (messageText?.trim().length === 0) return;

    if (!postingMessage) {
      try {
        setPostingMessage(true);
        const data = new FormData();
        data.append('order_id', props?.id);
        data.append('text', messageText);

        const response = await api.put('erp/orders/messages', data);
        if (response) {
          (textRef?.current?.children[0].children[0] as HTMLInputElement).value = '';
          getData();
        }
        setPostingMessage(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setPostingMessage(false);
      }
    }
  }

  return (
    <Box className={classes.root}>
      {messages?.length > 0 ? (
        <div id="messagesContainer" className={classes.messagesContainer}>
          <InfiniteScroll
            dataLength={messages.length}
            next={getMoreData}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            inverse //
            hasMore={pageCurrent < (data?.statistics?.total?.pages || 1)}
            loader={
              <Box className={classes.loadingMessageBox}>
                <Typography className={classes.loadingMessageText}>Carregando...</Typography>
              </Box>
            }
            scrollableTarget="messagesContainer"
          >
            {messages?.map((message: OrderMessageType) => {
              return (
                <Comment
                  key={`message_order_${message.id}`}
                  userId={message.user_id}
                  userName={message.user_name}
                  userSector={message.user_sector}
                  text={`${message.text}`}
                  date={message.created_at[0]}
                  date_formated={message.created_at[1]}
                  fullWidth
                  flip={message.my_message}
                  readCheck={false}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <Box className={classes.messagesContainer} display="flex" justifyContent="center" alignItems="center">
          <Typography className={classes.loadingMessageText}>Sem mensagens</Typography>
        </Box>
      )}
      <Box className={classes.sendMessageContainer}>
        <Box width="100%">
          <TextField
            size="small"
            className={classes.sendMessageInput}
            fullWidth
            id="send-message-input"
            variant="outlined"
            placeholder="Digite a sua mensagem aqui..."
            ref={textRef}
          />
        </Box>
        <Box>
          <Tooltip title="Enviar">
            <IconButton aria-label="send" onClick={handleSendMessage} size="large">
              <SendIcon fontSize="medium" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
