import Add from './add';
import Attendance from './attendance';
import Dashboard from './dashboard';
import Pending from './pending';
import Reports from './reports';
import Search from './search';
import View from './view';
import Statistics from './statistics';
import Home from './home';

export default {
  Add,
  Attendance,
  Dashboard,
  Pending,
  Reports,
  Search,
  View,
  Statistics,
  Home,
};
