import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {
  Box,
  Grid,
  Icon,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TableSortLabel,
  Tooltip,
  Snackbar,
  CircularProgress,
  IconButton,
} from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FaFileExcel } from 'react-icons/fa';

import { Alert, Pagination, Skeleton } from '@mui/lab/';

import moment from 'moment';
import Axios, { Canceler } from 'axios';
import Filters, { FiltersInputsProps } from '../../../components/Filters';
import api from '../../../services/api';
import styles from './styles';
import { ValueProps } from '../../../components/inputs/InputSearchAsync';

// moment(result.datent).format('DD/MM/YYYY')

export interface AccessData {
  total: number;
  page: number;
  pageTotal: number;
  limit: number;
  data: AccessProps[];
}

export interface AccessSearchProps {
  page?: number;
  order_by?: string;
  limit?: number;
  order_dir?: string;
  date_start?: string;
  date_end?: string;
  devices?: ValueProps[];
}

export interface AccessProps {
  numcad: string;
  nomfun: string;
  numcra: string;
  datacc: string;
  horacc: number;
  seqacc: string;
  diracc: string;
  codrlg: number;
  desrlg: string;
  tipacc: number;
  desred: string;
  lag: string;
  lng: string;
  datacc_formated: string;
  horacc_formated: string;
  diracc_formated: string;
}
export interface HeaderColumnProps {
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}
export interface RowColumnProps {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}
export interface RulesProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}
export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export default function MyAccesses() {
  const classes = styles();

  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 00:00' });

  const [accessData, setAccessData] = React.useState<AccessData>({} as AccessData);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [wattingPdfGenerated, setWattingPdfGenerated] = useState<boolean>(false);
  const [wattingXlsxGenerated, setWattingXlsxGenerated] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(parseInt(localStorage.getItem('search-hcm-myaccess-limit') || '10', 10));
  const [orderDir, setOrderDir] = useState<OrderProp>(
    (localStorage.getItem('search-hcm-myaccess-order-dir') || 'asc') as OrderProp,
  );
  const [orderBy, setOrderBy] = useState<string>(localStorage.getItem('search-hcm-myaccess-order-by') || 'date');

  const [searchDevices, setSearchDevices] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-hcm-myaccess-devices') || '[]'),
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const columns = [
    { colSpan: 1, aling: 'left', sort: false, fieldName: '', text: 'Cadastro / Nome', tooltipTitle: '' },
    { colSpan: 1, aling: 'left', sort: false, fieldName: '', text: 'Crachá', tooltipTitle: '' },
    { colSpan: 1, aling: 'left', sort: true, fieldName: 'date', text: 'Data', tooltipTitle: 'Ordenar por Data' },
    { colSpan: 1, aling: 'left', sort: false, fieldName: '', text: 'Hora', tooltipTitle: '' },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'direction',
      text: 'Direção',
      tooltipTitle: 'Ordenar por Direção',
    },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'device',
      text: 'Dispositivo',
      tooltipTitle: 'Ordernar por Dispositivo',
    },
    { colSpan: 1, aling: 'left', sort: true, fieldName: 'type', text: 'Tipo Acesso', tooltipTitle: 'Tipo Acesso' },
  ] as HeaderColumnProps[];
  const rowColumn = [
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
  ] as RowColumnProps[];

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-hcm-myaccess-limit') || '10',
    '',
    '',
    localStorage.getItem('search-hcm-myaccess-devices') || '[]',
  ]);

  React.useEffect(() => {
    let active = true;
    if (active) {
      getUserData({});
    }
    return () => {
      active = false;
    };
  }, []);

  const getUserData = ({
    page = 1,
    order_by = orderBy,
    order_dir = orderDir,
    limit = Number(inputsValue[0]),
    date_start = inputsValue[1],
    date_end = inputsValue[2],
    devices = searchDevices,
  }: AccessSearchProps) => {
    setCurrentPage(page);
    let cancel: Canceler;
    if (!loadingData) {
      let devicesFilter = '';
      for (const device of devices) {
        devicesFilter += `;${device.id}`;
      }
      const params = {
        page,
        limit,
        order_by,
        order_dir,
        date_start,
        date_end,
        devices: devicesFilter,
      };
      setLoadingData(true);

      api
        .get('hcm/myaccesses/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          setAccessData(response.data);
          setLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > accessData.pageTotal) return;
    getUserData({ page });
  };

  const handleApplyFilter = (e: any[]) => {
    setInputsValue(e);
    setSearchDevices(e[3]);
    getUserData({
      page: 1,
      order_by: orderBy,
      order_dir: orderDir,
      limit: Number(e[0]),
      date_start: e[1],
      date_end: e[2],
      devices: e[3],
    });
  };
  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem('search-users-order-by', index);
    localStorage.setItem('search-users-order-dir', isAsc ? 'desc' : 'asc');
    getUserData({ page: currentPage, order_by: index, order_dir: isAsc ? 'desc' : 'asc' });
  };

  const RenderResult = () => {
    const user = accessData.data;
    const emptyRows = user?.length > 0 ? limit - user?.length : limit - 1;
    moment.locale('pt-br');
    const rowsSkeleton = [] as number[];
    for (let i = 0; i < limit; i++) {
      rowsSkeleton.push(i);
    }

    return (
      <Box mt={2}>
        <Paper className={classes.tablePaper}>
          <Box p={1}>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="table"
              >
                <TableHead>
                  <TableRow>
                    {columns?.map((column: HeaderColumnProps, index) => {
                      return (
                        <TableCell
                          key={index}
                          width={column.width}
                          colSpan={column.colSpan}
                          align={column.aling}
                          sortDirection={orderBy === column.fieldName ? orderDir : false}
                        >
                          <Tooltip title={column.tooltipTitle} aria-label={`orderby-${column.fieldName}`}>
                            {column.sort ? (
                              <TableSortLabel
                                active={orderBy === column.fieldName}
                                direction={orderBy === column.fieldName ? orderDir : 'asc'}
                                onClick={() => {
                                  handleSort(column.fieldName);
                                }}
                              >
                                {column.text}
                                {orderBy === column.fieldName ? (
                                  <span className={classes.tableVisuallyHidden}>
                                    {orderDir === 'desc' ? 'desc' : 'asc'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              <Typography
                                style={{
                                  fontSize: '0.875rem',
                                  fontWeight: 500,
                                  lineHeight: '1.5rem',
                                  letterSpacing: '0.01071em',
                                }}
                              >
                                {column.text}
                              </Typography>
                            )}
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? (
                    rowsSkeleton?.map((row: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell key={index} style={{ padding: 2 }} colSpan={rowColumn?.length + 1} height={33}>
                            <Skeleton variant="text" />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : accessData.data?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={rowColumn?.length} align="center">
                        <Typography
                          variant="subtitle1"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Não foi encontrado nenhum Resultado.{' '}
                          <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente
                          novamente.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    accessData.data?.map((access: AccessProps, index) => {
                      const rowId = `row-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={rowId}>
                          <TableCell
                            style={{ minWidth: 280 }}
                            align="left"
                          >{`${access.numcad} - ${access.nomfun}`}</TableCell>
                          <TableCell align="left">{access.numcra}</TableCell>
                          <TableCell width={120} align="left">
                            {access.datacc_formated}
                          </TableCell>
                          <TableCell width={80} align="left">
                            {access.horacc_formated}
                          </TableCell>
                          <TableCell width={80} align="left">
                            {access.diracc_formated}
                          </TableCell>
                          <TableCell style={{ minWidth: 240 }} align="left">
                            {access.desrlg}
                            {access.codrlg === 97 && (
                              <Tooltip title="Visualizar no Google Maps" aria-label="view">
                                <IconButton
                                  onClick={() => {
                                    window.open(
                                      `https://www.google.com/maps/search/?api=1&query=${access.lag}%2C${access.lng}`,
                                    );
                                  }}
                                  size="small"
                                  style={{ marginLeft: 8 }}
                                >
                                  <Icon>map</Icon>
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell style={{ minWidth: 160 }} align="left">
                            {access.desred}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>

        <div className={classes.actions}>
          <Typography
            variant="caption"
            style={{ marginBottom: 4 }}
          >{`${accessData.total} Registros encontrados`}</Typography>

          <Pagination
            size="medium"
            count={accessData.pageTotal}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            disabled={accessData.pageTotal <= 1}
            onChange={handlePagination}
          />
        </div>
      </Box>
    );
  };

  const filtersInputs = [
    {
      type: 'combobox',
      id: 'hcm-myaccess-device-limit',
      label: 'Limite',
      defaultValue: 10,
      comboBoxItems: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    },
    {
      type: 'date',
      id: 'hcm-myaccess-date-start',
      label: 'Data (Inicio)',
      dateType: 'start',

      defaultValue: '',
    },
    {
      type: 'date',
      id: 'hcm-myaccess-date-end',
      label: 'Data (Fim)',
      dateType: 'end',

      defaultValue: '',
    },
    {
      type: 'device',
      id: 'hcm-myaccess-devices',
      defaultValue: '[]',
    },
  ] as FiltersInputsProps[];

  const exportPDF = () => {
    let cancel: Canceler;
    if (!wattingPdfGenerated) {
      setWattingPdfGenerated(true);

      let devicesFilter = '';

      for (const device of searchDevices) {
        devicesFilter += `;${device.id}`;
      }
      const params = {
        page: 1,
        limit: accessData.total,
        order_by: orderBy,
        order_dir: orderDir,
        date_start: inputsValue[1],
        date_end: inputsValue[2],
        devices: devicesFilter,
      };

      api
        .get('hcm/myaccesses/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const doc = new jsPDF({ orientation: 'landscape', unit: 'pt' });
          doc.setFontSize(15);
          doc.text('IntraIMIC - Consulta de Acessos', 40, 30);
          doc.setFontSize(10);

          doc.text(
            `Data: ${moment
              .utc(params.date_start !== '' ? params.date_start : Date.now())
              .format('DD/MM/YYYY')} a ${moment
              .utc(params.date_end !== '' ? params.date_end : Date.now())
              .format('DD/MM/YYYY')}`,
            40,
            45,
          );

          const responseData = response.data as AccessData;
          const tableData = [] as any[];

          for (let i = 0; i < responseData.data?.length; i += 1) {
            tableData.push([
              `${accessData.data[i].numcad} - ${accessData.data[i].nomfun}`,
              accessData.data[i].numcra,
              accessData.data[i].datacc_formated,
              accessData.data[i].horacc_formated,
              accessData.data[i].diracc_formated,
              accessData.data[i].desrlg,
              accessData.data[i].desred,
            ]);
          }

          autoTable(doc, {
            startY: 52,
            head: [['Cadastro / Nome', 'Crachá', 'Data', 'Hora', 'Direção', 'Dispositivo', 'Tipo Acesso']],
            body: tableData,
          });

          doc.save('intraIMIC_accessos.pdf');
          setWattingPdfGenerated(false);
        })
        .catch(error => {
          console.error(error);
          setWattingPdfGenerated(false);
        });
    }
    return () => cancel();
  };

  function exportToCSV(csvData: any, fileName: string) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);

    ws.A1.v = 'CODIGO';
    ws.B1.v = 'NOME';
    ws.C1.v = 'CRACHA';
    ws.D1.v = 'SEQ. ACESSO';
    ws.E1.v = 'DIR. ACESSO';
    ws.F1.v = 'COD. DISPOSITIVO';
    ws.G1.v = 'DES. DISPOSITIVO';
    ws.H1.v = 'TIPO ACESSO';
    ws.I1.v = 'DESCRICAO ACESSO';
    ws.J1.v = 'OBS. MARCAÇÃO';
    ws.K1.v = 'LATITUDE';
    ws.L1.v = 'LONGITUDE';
    ws.M1.v = 'DATA';
    ws.N1.v = 'HORA';
    ws.O1.v = 'DIRECAO';

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  const exportXlsx = () => {
    let cancel: Canceler;
    if (!wattingXlsxGenerated) {
      setWattingXlsxGenerated(true);

      let devicesFilter = '';

      for (const device of searchDevices) {
        devicesFilter += `;${device.id}`;
      }

      const params = {
        page: 1,
        limit: accessData.total,
        order_by: orderBy,
        order_dir: orderDir,
        date_start: inputsValue[1],
        date_end: inputsValue[2],
        devices: devicesFilter,
        return_type: 'excel',
      };

      api
        .get('hcm/myaccesses/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const responseData = response.data as AccessData;
          exportToCSV(responseData.data, 'intraIMIC_accessos');
          setWattingXlsxGenerated(false);
        })
        .catch(error => {
          console.error(error);
          setWattingXlsxGenerated(false);
        });
    }
    return () => cancel();
  };
  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      <Box className={classes.root}>
        <Grid container className={classes.contentRoot}>
          <Grid item xs={12}>
            <Paper>
              <Box p={1.5} display="flex" flexDirection="row" alignItems="center">
                {`Data: ${moment.utc(inputsValue[1] !== '' ? inputsValue[1] : Date.now()).format('DD/MM/YYYY')} a 
                ${moment.utc(inputsValue[2] !== '' ? inputsValue[2] : Date.now()).format('DD/MM/YYYY')}`}
                <Box flexGrow={1} />
                <Tooltip title={`${wattingPdfGenerated ? '' : 'Exportar para PDF'}`} aria-label="export-pdf">
                  <Button color="secondary" variant="contained" onClick={exportPDF} disabled={wattingPdfGenerated}>
                    {wattingPdfGenerated ? (
                      <CircularProgress size={24} style={{ marginRight: 8 }} />
                    ) : (
                      <Icon style={{ marginRight: 8 }}>picture_as_pdf</Icon>
                    )}
                    {wattingPdfGenerated ? 'GERANDO' : 'SALVAR'}
                  </Button>
                </Tooltip>
                <Tooltip title={`${wattingXlsxGenerated ? '' : 'Exportar para XLSX'}`} aria-label="export-xlsx">
                  <Button
                    color="success"
                    variant="contained"
                    onClick={exportXlsx}
                    disabled={wattingXlsxGenerated}
                    style={{ marginLeft: 16 }}
                  >
                    {wattingXlsxGenerated ? (
                      <CircularProgress size={24} style={{ marginRight: 8 }} />
                    ) : (
                      <FaFileExcel style={{ marginRight: 8, fontSize: 22 }} />
                    )}
                    {wattingXlsxGenerated ? 'GERANDO' : 'SALVAR'}
                  </Button>
                </Tooltip>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <RenderResult />
          </Grid>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarType} onClose={handleSnackbarClose}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
    </Box>
  );
}
