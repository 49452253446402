import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',

      alignItems: 'center',
      flexDirection: 'column',
    },
    filterContainer: {
      width: '100%',
    },
    filterRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 16,
      padding: 16,
      marginBottom: 16,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: 16,
      backgroundColor: darkMode ? '#2d2d2d' : '#ffffff',
      padding: 16,
      borderRadius: 4,
    },

    gridContainer: { width: '100%' },
    loadingText: {
      fontWeight: 'bold',
      color: darkMode ? '#d0d0d0' : '#9c9c9c',
      fontSize: 16,
    },
    filterButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
    },
    filterButtonText: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8,
      fontSize: 12,
    },
    indicatorsDataContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: 4,
      marginBottom: 4,
    },
    indicatorsDataText: {
      color: darkMode ? '#d0d0d0' : '#585858',
    },
    indicatorsDataValue: {
      marginLeft: 8,
    },
    indicatorsDataValueLink: {
      textDecoration: 'none',
      color: darkMode ? '#80b1ef' : '#2f5280',
      fontWeight: 'bold',
      '&:hover': {
        color: darkMode ? '#bfdbff' : '#1171ef',
      },
    },
    tableOutDateContainer: {
      marginTop: 8,
      maxHeight: 300,
    },
    filterTitle: {
      color: darkMode ? '#d0d0d0' : '#585858',
      fontSize: 12,
      marginBottom: 8,
    },
  });
});
