import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TableSortLabel,
  Tooltip,
  Snackbar,
  Badge,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Alert, Pagination, Rating, Skeleton } from '@mui/lab/';

import moment from 'moment';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ForumIcon from '@mui/icons-material/Forum';

import Axios, { Canceler } from 'axios';
import { ValueProps } from '../../../components/inputs/InputSearchAsync';
import ProfileTooltip from '../../../components/ProfileTooltip';
import Filters, { FiltersInputsProps } from '../../../components/Filters';
import { Helpdesk, HelpdeskData } from '../dashboard';
import api from '../../../services/api';
import styles from './styles';

interface UserProps {
  id: string;
  name: string;
  departament: string;
}
export interface HeaderColumnProps {
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}

export interface RowColumnProps {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}

export interface RulesProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}

export interface HelpDeskSearchProps {
  current_page?: number;
  order_by?: string;
  data_limit?: number;
  order_dir?: string;
  id?: string;
  situation?: string;
  created_at_start?: string;
  created_at_end?: string;
  owner?: UserProps[];
  target?: ValueProps[];
  attendance?: UserProps[];
  delay?: number;
  tag?: string;
}

export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export interface HepdeskSearchPageProps {
  darkMode?: boolean;
  search?: HelpDeskSearchProps;
}

export default function HelpdeskPeddingPage({ search }: HepdeskSearchPageProps) {
  const classes = styles();
  moment.locale('pt-br');
  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 00:00' });

  const [gettingHelpdeskData, setGettingHelpdeskData] = useState<boolean>(false);

  const [helpdeskData, setHelpdeskData] = useState<HelpdeskData>({} as HelpdeskData);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [dataLimit, setDataLimit] = useState<number>(
    parseInt(
      localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-data-limit`) ||
        '10',
      10,
    ),
  );
  const [orderDir, setOrderDir] = useState<OrderProp>(
    (localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-order-dir`) ||
      'asc') as OrderProp,
  );
  const [orderBy, setOrderBy] = useState<string>(
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-order-by`) || 'name',
  );

  const [searchTargets, setSearchTargets] = useState<ValueProps[]>(
    JSON.parse(
      localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-target`) || '[]',
    ),
  );
  const [searchAttendances, setSearchAttendances] = useState<UserProps[]>(
    JSON.parse(
      localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-attendance`) ||
        '[]',
    ),
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const columns = [
    { aling: 'center', sort: false, width: '32px', fieldName: 'open_helpdesk', text: '', tooltipTitle: '' },
    {
      aling: 'right',
      sort: true,
      width: '104px',
      fieldName: 'id',
      text: 'Número',
      tooltipTitle: 'Ordernar por Número',
    },
    {
      aling: 'left',
      sort: true,
      width: '164px',
      fieldName: 'situation',
      text: 'Situação',
      tooltipTitle: 'Ordernar por Situação',
    },
    search?.situation === '2;3' && {
      aling: 'center',
      sort: false,
      width: '134px',
      fieldName: 'rate',
      text: 'Avaliação',
      tooltipTitle: '',
    },

    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'owner',
      text: 'Solicitante',
      tooltipTitle: 'Ordernar por Solicitante',
    },
    {
      aling: 'right',
      sort: false,
      width: '96px',
      fieldName: 'posts',
      text: 'Trâmites',
      tooltipTitle: '',
    },
    { aling: 'left', sort: false, width: '480px', fieldName: 'title', text: 'Título', tooltipTitle: '' },
    {
      aling: 'left',
      sort: true,
      width: '164px',
      fieldName: 'created_at',
      text: 'Abertura',
      tooltipTitle: 'Ordernar por Abertura',
    },
    {
      aling: 'left',
      sort: true,
      width: '112px',
      fieldName: 'deadline',
      text: 'Prazo',
      tooltipTitle: 'Ordernar por Prazo de Atendimento',
    },
    search?.situation === '2;3' && {
      aling: 'left',
      sort: true,
      width: '164px',
      fieldName: 'ended_in',
      text: 'Finalizado',
      tooltipTitle: 'Ordernar por data de finalização',
    },
    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'target',
      text: 'Setor Atendimento',
      tooltipTitle: 'Ordernar por Setor Atendimento',
    },
    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'attendance',
      text: 'Atendente',
      tooltipTitle: 'Ordernar por Atendente',
    },
    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'category',
      text: 'Categoria',
      tooltipTitle: 'Ordernar por Categoria',
    },
    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'subcategory',
      text: 'Subcategoria',
      tooltipTitle: 'Ordernar por Subcategoria',
    },

    { aling: 'center', sort: false, width: '128px', fieldName: 'days_spent', text: 'Dias Gastos', tooltipTitle: '' },
    { aling: 'center', sort: false, width: '128px', fieldName: 'days_delay', text: 'Dias Atraso', tooltipTitle: '' },
  ] as HeaderColumnProps[];

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-data-limit`) || '10',
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-created-at-start`) ||
      '',
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-created-at-end`) ||
      '',
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-target`) || '[]',
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-attendance`) || '[]',
    localStorage.getItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-tag`) || '',
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      getHelpdeskData({});
    }
    return () => {
      active = false;
    };
  }, []);

  const getHelpdeskData = ({
    current_page = 1,
    order_by = orderBy,
    order_dir = orderDir,
    data_limit = Number(inputsValue[0]),
    created_at_start = inputsValue[1],
    created_at_end = inputsValue[2],
    target = searchTargets,
    attendance = searchAttendances,
    tag = inputsValue[5],
  }: HelpDeskSearchProps) => {
    let cancel: Canceler;
    if (!gettingHelpdeskData) {
      setGettingHelpdeskData(true);
      let targetFilter = '';
      let attendanceFilter = '';

      for (const item of target) {
        targetFilter += `;${item.id}`;
      }
      for (const item of attendance) {
        attendanceFilter += `;${item.id}`;
      }
      const params = {
        current_page,
        data_limit,
        order_by,
        order_dir,

        situation: search?.situation || '0;1',
        created_at_start,
        created_at_end,
        owner: 'my',
        target: targetFilter,
        attendance: attendanceFilter,
        tag,
      };

      api
        .get('helpdesk/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setHelpdeskData(response.data);
          setGettingHelpdeskData(false);
        })
        .catch((error: any) => {
          const msg = error?.response?.data?.message;
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
          setGettingHelpdeskData(false);
        });
    }
    return () => cancel();
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handlePagination = (event: any, current_page: number) => {
    if (current_page < 1 || current_page > helpdeskData.total_page) return;
    setCurrentPage(current_page);
    getHelpdeskData({ current_page });
  };

  const handleApplyFilter = (e: any[]) => {
    setInputsValue(e);
    setDataLimit(e[0]);
    setSearchTargets(e[3]);
    setSearchAttendances(e[4]);

    getHelpdeskData({
      current_page: 1,
      order_by: orderBy,
      order_dir: orderDir,
      data_limit: Number(e[0]),
      created_at_start: e[1],
      created_at_end: e[2],
      target: e[3],
      attendance: e[4],
      tag: e[5],
    });
  };

  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem(`search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-order-by`, index);
    localStorage.setItem(
      `search-helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-order-dir`,
      isAsc ? 'desc' : 'asc',
    );
    getHelpdeskData({ current_page: currentPage, order_by: index, order_dir: isAsc ? 'desc' : 'asc' });
  };

  const RenderResult = (props: {
    helpdesk: HelpdeskData;
    loaddingData: boolean;
    currentPage: number;
    dataLimit: number;
    columns: HeaderColumnProps[];
  }) => {
    const { helpdesk, loaddingData, currentPage, dataLimit, columns } = props;
    const newDataLimit = dataLimit < 1 ? 30 : dataLimit;
    const emptyRows = helpdesk?.data?.length > 0 ? newDataLimit - helpdesk?.data?.length : newDataLimit - 1;
    const rowsSkeleton = [] as number[];

    for (let i = 0; i < newDataLimit; i++) {
      rowsSkeleton.push(i);
    }

    return (
      <Box mt={2}>
        <Paper className={classes.tablePaper}>
          <Box p={1}>
            {!loaddingData && helpdesk?.data?.length === 0 ? (
              <Box
                className={classes.tableContainer}
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Não foi encontrado nenhum Resultado.{' '}
                  <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente novamente.
                </Typography>
              </Box>
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead>
                    <TableRow>
                      {columns?.map((column: HeaderColumnProps, index) => {
                        return (
                          <TableCell
                            key={index}
                            style={{ minWidth: column.width, width: column.width, maxWidth: column.width }}
                            colSpan={column.colSpan}
                            align={column.aling}
                            sortDirection={orderBy === column.fieldName ? orderDir : false}
                          >
                            <Tooltip title={column.tooltipTitle} aria-label={`orderby-${column.fieldName}`}>
                              {column.sort ? (
                                <TableSortLabel
                                  active={orderBy === column.fieldName}
                                  direction={orderBy === column.fieldName ? orderDir : 'asc'}
                                  onClick={() => {
                                    handleSort(column.fieldName);
                                  }}
                                >
                                  {column.text}
                                  {orderBy === column.fieldName ? (
                                    <span className={classes.tableVisuallyHidden}>
                                      {orderDir === 'desc' ? 'desc' : 'asc'}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                <Typography style={{ fontSize: 14 }}>{column.text}</Typography>
                              )}
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaddingData
                      ? rowsSkeleton?.map((row: any, index: any) => {
                          return (
                            <TableRow key={index}>
                              {columns?.map((column: RowColumnProps, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    style={{ padding: 4 }}
                                    colSpan={column.colSpan}
                                    width={column.width}
                                    height={37}
                                    align={column.aling}
                                  >
                                    <Skeleton variant="text" />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : helpdesk?.data?.map((helpdesk: Helpdesk, index) => {
                          const rowId = `row-${index}`;
                          return (
                            <TableRow hover tabIndex={-1} key={rowId}>
                              {columns?.map((column: HeaderColumnProps, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    colSpan={column.colSpan}
                                    align={column.aling}
                                    style={{
                                      minWidth: column.width,
                                      width: column.width,
                                      maxWidth: column.width,
                                    }}
                                  >
                                    {column.fieldName === 'open_helpdesk' && (
                                      <Tooltip title={`Abrir Helpdesk ${helpdesk?.id}`} aria-label="view">
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          component={Link}
                                          to={`/helpdesk/${helpdesk?.id}`}
                                          style={{
                                            margin: 2,
                                            marginLeft: 6,
                                            padding: 2,
                                            minWidth: 32,
                                            maxWidth: 32,
                                          }}
                                          size="large"
                                          startIcon={
                                            <Icon
                                              fontSize="small"
                                              style={{
                                                padding: 0,
                                                marginLeft: 12,
                                              }}
                                            >
                                              open_in_new
                                            </Icon>
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                    {column.fieldName === 'id' && <> {helpdesk?.id}</>}
                                    {column.fieldName === 'situation' && (
                                      <Box display="flex" alignItems="center">
                                        {helpdesk?.situation === 0 && (
                                          <WarningIcon className={classes.warningIimeSituationColor} />
                                        )}
                                        {helpdesk?.situation === 1 && (
                                          <WatchLaterIcon
                                            className={
                                              helpdesk?.days_delay === 0
                                                ? classes.onTimeSituationColor
                                                : classes.offTimeSituationColor
                                            }
                                          />
                                        )}
                                        {helpdesk?.situation === 2 && (
                                          <CheckCircleIcon
                                            className={
                                              helpdesk?.days_delay === 0
                                                ? classes.onTimeSituationColor
                                                : classes.offTimeSituationColor
                                            }
                                          />
                                        )}
                                        {helpdesk?.situation === 3 && (
                                          <CancelIcon className={classes.offTimeSituationColor} />
                                        )}

                                        <Typography
                                          style={{ marginLeft: 4, fontSize: 14 }}
                                        >{`${helpdesk?.situation_description}`}</Typography>
                                      </Box>
                                    )}

                                    {column.fieldName === 'owner' && (
                                      <ProfileTooltip userId={helpdesk?.owner}>
                                        <div style={{ cursor: 'default' }}>{helpdesk?.owner_name}</div>
                                      </ProfileTooltip>
                                    )}
                                    {column.fieldName === 'title' && <>{helpdesk?.title}</>}
                                    {column.fieldName === 'created_at' && <>{helpdesk?.created_at_formated}</>}
                                    {column.fieldName === 'ended_in' && <>{helpdesk?.ended_in_formated}</>}
                                    {column.fieldName === 'deadline' && <>{helpdesk?.deadline_formated}</>}
                                    {column.fieldName === 'target' && <>{helpdesk?.target_description}</>}

                                    {column.fieldName === 'attendance' && (
                                      <ProfileTooltip userId={helpdesk?.attendance}>
                                        <div style={{ cursor: 'default' }}>{helpdesk?.attendance_name}</div>
                                      </ProfileTooltip>
                                    )}
                                    {column.fieldName === 'category' && <>{helpdesk?.category_description}</>}
                                    {column.fieldName === 'subcategory' && <>{helpdesk?.subcategory_description}</>}
                                    {column.fieldName === 'days_spent' && <>{helpdesk?.days_spent}</>}
                                    {column.fieldName === 'days_delay' && <>{helpdesk?.days_delay}</>}
                                    {column.fieldName === 'posts' && (
                                      <>
                                        {helpdesk?.posts?.length}
                                        <Badge
                                          badgeContent={helpdesk?.posts_unreaded}
                                          color="error"
                                          variant="dot"
                                          style={{ marginLeft: 8 }}
                                        >
                                          <ForumIcon style={{ fontSize: 16 }} />
                                        </Badge>
                                      </>
                                    )}
                                    {column.fieldName === 'rate' && (
                                      <>
                                        <Rating
                                          size="small"
                                          name="read-only"
                                          value={helpdesk?.rate}
                                          precision={1}
                                          readOnly
                                        />
                                      </>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    {!loaddingData && emptyRows > 0 && (
                      <TableRow style={{ height: 37 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Paper>

        <div className={classes.actions}>
          <Typography
            variant="caption"
            style={{ marginBottom: 4 }}
          >{`${helpdeskData.data_total} Registros encontrados`}</Typography>

          <Pagination
            size="medium"
            count={helpdeskData.total_page}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            disabled={loaddingData || helpdeskData.total_page <= 1}
            onChange={handlePagination}
          />
        </div>
      </Box>
    );
  };

  const filtersInputs = [
    {
      type: 'combobox',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-data-limit`,
      label: 'Limite',
      defaultValue: 10,
      comboBoxItems: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 0, text: 'Tudo' },
      ],
    },
    {
      type: 'date',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-created-at-start`,
      label: 'Data Geração (De)',
      dateType: 'start',
      defaultValue: '',
    },
    {
      type: 'date',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-created-at-end`,
      label: 'Data Geração (Até)',
      dateType: 'end',
      defaultValue: '',
    },
    {
      type: 'helpdesk-target',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-target`,
      label: 'Setor Atendimento (+)',
      placeholder: 'Pesquisar Setor',
      noOptionsText: 'Nenhum setor encontrado.',
      defaultValue: '[]',
    },
    {
      type: 'user',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-attendance`,
      label: 'Atendente (+)',
      placeholder: 'Pesquisar Atendente',
      noOptionsText: 'Nenhum atendente encontrado.',
      defaultValue: '[]',
    },
    {
      type: 'text',
      id: `helpdesk-${search?.situation === '0;1' ? 'pending' : 'finalized'}-tag`,
      label: 'Palava Chave',
      defaultValue: '',
    },
  ] as FiltersInputsProps[];

  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      <Box className={classes.root}>
        <Grid container className={classes.contentRoot}>
          <Grid item xs={12}>
            <RenderResult
              helpdesk={helpdeskData}
              loaddingData={gettingHelpdeskData}
              currentPage={currentPage}
              dataLimit={dataLimit}
              columns={columns}
            />
          </Grid>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarType} onClose={handleSnackbarClose}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
    </Box>
  );
}
