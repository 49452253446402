import React, { useEffect, useState } from 'react';
import Axios, { Canceler } from 'axios';
import { Box, Typography, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import styles from './styles';

import api from '../../../../services/api';
import ProfileTooltip from '../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../utils/functions';

type UserType = {
  id: number;
  type: number;
  number: number;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  device_id: string;
  device_name: string;
  access_time: number;
  access_hours: number;
  access_minutes: number;
  access_hours_formated: string;
  access_minutes_formated: string;
  access_date: string;
};

export default function AttendanceListPage() {
  const classes = styles();
  const [data, setData] = useState<UserType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const loadingText = 'Carregando...';

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<UserType[]>('manager/attendance', {
          cancelToken: new Axios.CancelToken(async c => {
            cancel = c;
          }),
        });
        setData(response.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    let active = true;
    if (active) {
      getData();
    }
    return () => {
      active = false;
    };
  }, []);

  if (loadingData)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>{loadingText}</Typography>
      </Box>
    );
  if (data?.length === 0)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>Nenhuma marcação encontrada</Typography>
      </Box>
    );
  return (
    <>
      {data.map(user => {
        const userId = user.id.toString();
        const nickName = user.nickname.split(' ');
        let newName = '';

        for (let index = 0; index < nickName.length; index++) {
          if (index < 2) {
            newName += nickName[index];
          }
          newName += ' ';
        }
        const userName = newName.trim();
        const userSector = user.sector;
        const accessDate = user.access_date;
        const accessHours = user.access_hours_formated;
        const accessMinutes = user.access_minutes_formated;

        return (
          <Box
            key={user.id}
            className={`${classes.itemContainer} ${
              user.access_time < 0 || user.access_time > 460
                ? classes.titleItemRed
                : user.access_time > 450
                ? classes.titleItemYellow
                : classes.titleItemGreen
            }`}
          >
            <Box className={classes.hoursContainer}>
              {user.access_time < 0 ? (
                <PriorityHighIcon className={`${classes.noHoursIcon} ${classes.colorRed}`} />
              ) : (
                <>
                  <Typography className={classes.hoursValue}>{`${accessHours}:${accessMinutes}`}</Typography>
                  <Typography className={classes.hoursDescription}>horas</Typography>
                </>
              )}
            </Box>
            <Box className={classes.avatarContainer}>
              <Link to={`/profile/${userId}`} className={classes.avatarLink} style={{ textDecoration: 'none' }}>
                <ProfileTooltip userId={userId}>
                  <Avatar
                    alt={`${userName}`}
                    src={avatarUrl(userId, true)}
                    aria-label={`${userName.toLowerCase()}`}
                    className={classes.avatar}
                  />
                </ProfileTooltip>
              </Link>
              <Box className={classes.avatarDescriptionContainer}>
                <Box width="100%">
                  <Typography className={classes.avatarDescriptionName}>{userName}</Typography>
                </Box>
                <Box className={classes.dateDescriptionContainer}>
                  {user.access_time >= 0 ? (
                    <>
                      <Typography className={classes.dateDescription}>Dispositivo:</Typography>
                      <Typography
                        className={classes.avatarDescriptionDateValue}
                      >{`${user.device_id} - ${user.device_name}`}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={classes.avatarDescriptionDateValue}>Nenhum acesso encontrado</Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}
