import React, { useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  Paper,
  Typography,
  createStyles,
  Theme,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TableSortLabel,
  Tooltip,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Alert, Pagination, Skeleton } from '@mui/lab/';

import { deepPurple, grey, orange } from '@mui/material/colors';
import moment from 'moment';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Axios, { Canceler } from 'axios';
import Filters, { FiltersInputsProps } from '../../../../components/Filters';
import api from '../../../../services/api';
import styles from './styles';

// moment(result.datent).format('DD/MM/YYYY')
export interface UserDataProps {
  total: number;
  page: number;
  pageTotal: number;
  limit: number;
  data: UserProps[];
}
export interface UserSearchProps {
  page?: number;
  sortBy?: string;
  limit?: number;
  sortDir?: string;
  login?: string;
  name?: string;
  employee_position?: string;
  situation?: number;
  logged_at?: string;
}
export interface UserProps {
  ROW: number;
  id: string;
  type: string;
  login: string;
  email: string;
  email2: string;
  created_at: string;
  updated_at: string;
  logged_at: string;
  situation: number;
  erp_company_id: string;
  erp_branch_id: string;
  erp_id: string;
  tipcol: string;
  numcad: string;
  vetorh_situation: string;
  name: string;
  employee_position: string;
  rules: RulesProps[];
}
export interface HeaderColumnProps {
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}
export interface RowColumnProps {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}
export interface RulesProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}
export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export default function AdminUsersPage() {
  const classes = styles();
  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 00:00' });

  const [userData, setUserData] = React.useState<UserDataProps>({} as UserDataProps);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingSyncUsers, setLoadingSyncUsers] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(parseInt(localStorage.getItem('search-users-limit') || '10', 10));
  const [orderDir, setOrderDir] = useState<OrderProp>(
    (localStorage.getItem('search-users-order-dir') || 'asc') as OrderProp,
  );
  const [orderBy, setOrderBy] = useState<string>(localStorage.getItem('search-users-order-by') || 'name');

  const [forceRecreateImageSync, setForceRecreateImageSync] = useState<boolean>(false);
  const [forceAllUsersSync, setForceAllUsersSync] = useState<boolean>(false);
  const [importUsersDialogOpen, setImportUsersDialogOpen] = useState<boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const columns = [
    { colSpan: 1, aling: 'center', sort: false, fieldName: '', text: '', tooltipTitle: '' },
    { colSpan: 1, aling: 'center', sort: false, fieldName: '', text: '', tooltipTitle: '' },
    { colSpan: 1, aling: 'left', sort: true, fieldName: 'login', text: 'Login', tooltipTitle: 'Ordernar por Login' },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'erp_id',
      text: 'Cod. ERP',
      tooltipTitle: 'Ordernar por Cod. ERP',
    },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'tipcol',
      text: 'Tipo HCM',
      tooltipTitle: 'Ordernar por Tipo HCM',
    },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'numcad',
      text: 'Cad. HCM',
      tooltipTitle: 'Ordernar por Cadastro HCM',
    },
    {
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'vetorh_situation',
      text: 'Situação HCM',
      tooltipTitle: 'Ordernar por Situação HCM',
    },

    { colSpan: 1, aling: 'left', sort: true, fieldName: 'name', text: 'Nome', tooltipTitle: 'Ordernar por Nome' },
    { colSpan: 1, aling: 'left', sort: true, fieldName: 'ep', text: 'Cargo', tooltipTitle: 'Ordernar por Cargo' },

    {
      colSpan: 1,
      aling: 'center',
      sort: true,
      fieldName: 'logged_at',
      text: 'Último Acesso',
      tooltipTitle: 'Ordernar por Último Acesso',
    },
  ] as HeaderColumnProps[];
  const rowColumn = [
    { aling: 'center', text: '', colSpan: 1, width: '46px' },
    { aling: 'center', text: '', colSpan: 1, width: '46px' },
    { aling: 'left', text: '', colSpan: 1, width: '114px' },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: 'left', text: '', colSpan: 1 },
    { aling: '', text: '', colSpan: 1 },
    { aling: 'center', text: '', colSpan: 1, width: '150px' },
    { aling: 'center', text: '', colSpan: 1, width: '46px' },
  ] as RowColumnProps[];

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-users-limit') || '10',
    localStorage.getItem('search-users-login') || '',
    localStorage.getItem('search-users-name') || '',
    localStorage.getItem('search-users-employee-position') || '',
    localStorage.getItem('search-users-situation') || '',
    localStorage.getItem('search-users-logged-at') || '',
  ]);

  React.useEffect(() => {
    let active = true;
    if (active) {
      getUserData({});
    }
    return () => {
      active = false;
    };
  }, []);

  const getUserData = ({
    page = 1,
    sortBy = orderBy,
    sortDir = orderDir,
    limit = Number(inputsValue[0]),
    login = inputsValue[1],
    name = inputsValue[2],
    employee_position = inputsValue[3],
    situation = Number(inputsValue[4]),
    logged_at = inputsValue[5],
  }: UserSearchProps) => {
    setCurrentPage(page);
    let cancel: Canceler;
    if (!loadingData) {
      const params = {
        page,
        limit,
        sortBy,
        sortDir,
        logged_at,
        situation,
        login,
        name,
        employee_position,
      };
      setLoadingData(true);

      api
        .get('intranet/users/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          setUserData(response.data);
          setLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > userData.pageTotal) return;
    getUserData({ page });
  };

  const handleSyncUsers = () => {
    if (!loadingSyncUsers) {
      setLoadingSyncUsers(true);

      api
        .post('intranet/users/import', {
          regenerate_image: forceRecreateImageSync,
          all_users: forceAllUsersSync,
          onDownloadProgress: progressEvent => {
            // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length']);
            // const percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            // console.log('completed: ', total, percentCompleted);
          },
        })
        .then(response => {
          getUserData({});
          setSnackbarType('success');
          setSnackbarMsg('Usuários importados com sucesso');
          setOpenSnackbar(true);
          setLoadingSyncUsers(false);
        })
        .catch(error => {
          setLoadingData(false);
          setSnackbarType('error');
          setSnackbarMsg(error?.message);
          setOpenSnackbar(true);
          setLoadingSyncUsers(false);
        });
    }
  };
  const handleOpenSyncDialog = () => {
    setImportUsersDialogOpen(true);
  };
  const handleCloseSyncDialog = () => {
    if (!loadingSyncUsers) {
      setImportUsersDialogOpen(false);
    }
  };
  const handleApplyFilter = (e: string[]) => {
    setInputsValue(e);
    getUserData({
      page: 1,
      sortBy: orderBy,
      sortDir: orderDir,
      limit: Number(e[0]),
      login: e[1],
      name: e[2],
      employee_position: e[3],
      situation: Number(e[4]),
      logged_at: e[5],
    });
  };
  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem('search-users-order-by', index);
    localStorage.setItem('search-users-order-dir', isAsc ? 'desc' : 'asc');
    getUserData({ page: currentPage, sortBy: index, sortDir: isAsc ? 'desc' : 'asc' });
  };

  const handleForceRecreateImageSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceRecreateImageSync(event.target.checked);
  };

  const handleForceAllUsersSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceAllUsersSync(event.target.checked);
  };

  const RenderResult = () => {
    const user = userData.data;
    const emptyRows = user?.length > 0 ? limit - user?.length : limit - 1;
    moment.locale('pt-br');
    const rowsSkeleton = [] as number[];
    for (let i = 0; i < limit; i++) {
      rowsSkeleton.push(i);
    }

    return (
      <Box mt={2}>
        <Paper className={classes.tablePaper}>
          <Box p={1}>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="table"
              >
                <TableHead>
                  <TableRow>
                    {columns?.map((column: HeaderColumnProps, index) => {
                      return (
                        <TableCell
                          key={index}
                          width={column.width}
                          colSpan={column.colSpan}
                          align={column.aling}
                          sortDirection={orderBy === column.fieldName ? orderDir : false}
                        >
                          <Tooltip title={column.tooltipTitle} aria-label={`orderby-${column.fieldName}`}>
                            {column.sort ? (
                              <TableSortLabel
                                active={orderBy === column.fieldName}
                                direction={orderBy === column.fieldName ? orderDir : 'asc'}
                                onClick={() => {
                                  handleSort(column.fieldName);
                                }}
                              >
                                {column.text}
                                {orderBy === column.fieldName ? (
                                  <span className={classes.tableVisuallyHidden}>
                                    {orderDir === 'desc' ? 'desc' : 'asc'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              <Typography>{column.text}</Typography>
                            )}
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? (
                    rowsSkeleton?.map((row: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          {rowColumn?.map((column: RowColumnProps, index) => {
                            return (
                              <TableCell
                                key={index}
                                style={{ padding: 4 }}
                                colSpan={column.colSpan}
                                width={column.width}
                                height={33}
                                align={column.aling}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : userData.data?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={rowColumn?.length} align="center">
                        <Typography
                          variant="subtitle1"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Não foi encontrado nenhum Resultado.{' '}
                          <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente
                          novamente.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    userData.data?.map((user: UserProps, index) => {
                      const rowId = `row-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={rowId}>
                          <TableCell width={16} padding="none" align="center">
                            <Tooltip title="Editar" aria-label="editar">
                              <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={`/admin/users/${user.id}`}
                                style={{
                                  marginLeft: 6,
                                  padding: 0,
                                  minWidth: 32,
                                }}
                                size="large"
                                startIcon={
                                  <Icon
                                    fontSize="small"
                                    style={{
                                      marginLeft: 12,
                                      padding: 0,
                                    }}
                                  >
                                    edit
                                  </Icon>
                                }
                              />
                            </Tooltip>
                          </TableCell>
                          <TableCell width={16} padding="none" align="center">
                            <Tooltip title={`${user.situation === 1 ? 'Ativo' : 'Inativo'}`} aria-label="situation">
                              <PowerSettingsNewIcon
                                className={user.situation === 1 ? classes.colorGreen : classes.colorRed}
                              />
                            </Tooltip>
                          </TableCell>
                          <TableCell width={24} align="left">
                            {user.login}
                          </TableCell>
                          <TableCell width={40} align="left">
                            {user.erp_id}
                          </TableCell>
                          <TableCell width={40} align="left">
                            {user.tipcol}
                          </TableCell>
                          <TableCell width={40} align="left">
                            {user.numcad}
                          </TableCell>
                          <TableCell width={40} align="left">
                            {user.vetorh_situation}
                          </TableCell>
                          <TableCell align="left">{user.name}</TableCell>
                          <TableCell align="left">{user.employee_position}</TableCell>

                          <TableCell width={150} align="center">
                            {moment.utc(user.logged_at).format('DD/MM/YYYY HH:mm')}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>

        <div className={classes.actions}>
          <Typography
            variant="caption"
            style={{ marginBottom: 4 }}
          >{`${userData.total} Registros encontrados`}</Typography>

          <Pagination
            size="medium"
            count={userData.pageTotal}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            disabled={userData.pageTotal <= 1}
            onChange={handlePagination}
          />
        </div>
      </Box>
    );
  };
  const filtersInputs = [
    {
      type: 'combobox',
      id: 'users-limit',
      label: 'Limite',
      defaultValue: 10,
      comboBoxItems: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    },
    {
      type: 'text',
      id: 'users-login',
      label: 'Login',
      defaultValue: '',
    },
    {
      type: 'text',
      id: 'users-name',
      label: 'Nome',
      defaultValue: '',
    },
    {
      type: 'text',
      id: 'users-job',
      label: 'Cargo',
      defaultValue: '',
    },
    {
      type: 'combobox',
      id: 'users-situation',
      label: 'Situação',
      defaultValue: -1,
      comboBoxItems: [
        { value: -1, text: 'Todos' },
        { value: 0, text: 'Inativo' },
        { value: 1, text: 'Ativo' },
      ],
    },
    {
      type: 'date',
      id: 'users-last-access',
      label: 'Ultimo Acesso',
      defaultValue: '',
    },
  ] as FiltersInputsProps[];

  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      <Box className={classes.root}>
        <Grid container className={classes.contentRoot}>
          <Grid item xs={12}>
            <Paper>
              <Box p={1.5} display="flex" flexDirection="row">
                <Box>
                  <Tooltip title="Novo Usuário" aria-label="novo-usuario">
                    <Button color="primary" variant="contained" component={Link} to="/admin/users/add">
                      NOVO
                    </Button>
                  </Tooltip>
                </Box>
                <Box flexGrow={1} />
                <Box>
                  <Tooltip title="Sincronizar Usuários com o ERP" aria-label="sincronizar-usuario">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleOpenSyncDialog}
                      disabled={loadingSyncUsers}
                    >
                      Sincronizar
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <RenderResult />
          </Grid>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarType} onClose={handleSnackbarClose}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
      <Dialog onClose={handleCloseSyncDialog} aria-labelledby="customized-dialog-title" open={importUsersDialogOpen}>
        <DialogTitle id="customized-dialog-title">Sincronizar Usuários</DialogTitle>
        <DialogContent dividers>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={forceRecreateImageSync}
                  color="primary"
                  onChange={handleForceRecreateImageSyncChange}
                  name="forceRecreateImageSync"
                />
              }
              disabled={loadingSyncUsers}
              label={
                <Box display="flex" alignItems="center">
                  <Typography>Forçar atualização das Fotos</Typography>
                  <Typography className={classes.syncDescriptionRecreateImage}>(Isso pode demorar)</Typography>
                </Box>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={forceAllUsersSync}
                  color="primary"
                  onChange={handleForceAllUsersSyncChange}
                  name="forceAllUsersSync"
                />
              }
              disabled={loadingSyncUsers}
              label={
                <Box display="flex" alignItems="center">
                  <Typography>Incluir usuários demitidos</Typography>
                  <Typography className={classes.syncDescriptionRecreateImage}>(Isso pode demorar muito)</Typography>
                </Box>
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSyncDialog} color="secondary" disabled={loadingSyncUsers}>
            FECHAR
          </Button>
          <Button autoFocus color="primary" onClick={handleSyncUsers} disabled={loadingSyncUsers}>
            {loadingSyncUsers && <CircularProgress size={24} style={{ marginRight: 8 }} />} IMPORTAR
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
