import React from 'react';
import { Button, Icon, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import styles from './styles';
import notFoundImg from '../../../images/access_denied.svg';
import notFoundLightImg from '../../../images/access_denied.svg';

interface PageProps {
  darkMode?: boolean;
}

export default function AccessDenied(props: PageProps) {
  const classes = styles();
  const { darkMode } = props;

  return (
    <div className={classes.root}>
      <img src={darkMode ? notFoundImg : notFoundLightImg} alt="denied" className={classes.img} />
      <div className={classes.content}>
        <Typography variant="h6" className={classes.text}>
          Você não tem autorização para acessar esse conteudo.
        </Typography>
        <div className={classes.button}>
          <Button variant="contained" color="primary" startIcon={<Icon>home</Icon>} component={Link} to="/dashboard">
            Home
          </Button>
        </div>
      </div>
    </div>
  );
}
AccessDenied.defaultProps = {
  darkMode: false,
};
