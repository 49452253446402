import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    separator: {
      height: '100%',

      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: darkMode ? '#616161' : '#d7d7d7',
    },
    filterRoot: {
      overflowY: 'auto',
      maxHeight: 240,
      height: 120,
      paddingLeft: 16,
    },
    buttonIcon: {
      marginRight: 8,
    },
    typeFilterText: {
      fontSize: 14,
      color: darkMode ? '#bdbdbd' : '#444444',
      height: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 1,
    },
    depositsFilterText: {
      fontSize: 14,
      color: darkMode ? '#bdbdbd' : '#444444',
    },
    taskFeedback: {},
    taskFeedbackError: {
      color: darkMode ? '#e57373' : '#e57373',
      width: '100%',
    },
    taskFeedbackSuccess: {
      color: darkMode ? '#7fe573' : '#7fe573',
      width: '100%',
    },
  });
});
