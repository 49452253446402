import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { format, addBusinessDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ClearIcon from '@mui/icons-material/Clear';

import InputSearchAsync, { ValueProps } from '../../../../../components/inputs/InputSearchAsync';
import { humanFileSize, toDate } from '../../../../../utils/functions';
import api from '../../../../../services/api';
import { InputEngOsTask } from '../../../../../components/inputs/InputEngOsTask';

type TaskType = {
  id: number;
  description: string;
};

type FormStateType = {
  key: string;
  order_number: string;
  budget_number: string;
  helpdesk_id: string;
  product: ValueProps;
  drawn: ValueProps;
  task: TaskType;
  days: number;
  classification: string;
  description: string;
  attendant: ValueProps;
  expected_start_date: string;
  expected_end_date: string;
  obs: string;
  rework: number;
};

type ErrorStateType = {
  order_error: string;
  budget_error: string;
  helpdesk_error: string;
  product_error: string;
  drawn_error: string;
  task_error: string;
  start_date_error: string;
  end_date_error: string;
  attendant_error: string;
  attachments_error: string;
};

interface EnOsNewOSFormProps {
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  onSubmited?: (success: boolean, msg: string) => void;
}

const today = new Date().toISOString().split('T')[0];

const defaultFormState: FormStateType = {
  key: 'order',
  order_number: '',
  budget_number: '',
  helpdesk_id: '',
  drawn: null,
  product: null,
  task: {} as TaskType,
  classification: '',
  description: '',
  attendant: null,
  expected_start_date: today,
  expected_end_date: today,
  obs: '',
  rework: 0,
  days: 0,
};

const defaultErrorState: ErrorStateType = {
  order_error: '',
  budget_error: '',
  product_error: '',
  drawn_error: '',
  helpdesk_error: '',
  task_error: '',
  start_date_error: '',
  end_date_error: '',
  attendant_error: '',
  attachments_error: '',
};

function EnOsNewOSForm(props: EnOsNewOSFormProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const refInputAttachment = useRef<HTMLInputElement | null>(null);
  const { handleClose, handleOpen, open, onSubmited } = props;

  let totalSize = 0;

  const [formState, setFormState] = useState(defaultFormState);
  const [errorState, setErrorState] = useState<ErrorStateType>(defaultErrorState);

  const [submiting, setSubmiting] = useState<boolean>(false);

  const [attachments, setAttachments] = useState<File[]>([]);
  const [osKey, setOsKey] = useState('order');

  const resetFormState = () => {
    setErrorState(defaultErrorState);
    setFormState(defaultFormState);
    setOsKey('order');
  };

  function handleValideData() {
    let order_error = '';
    let budget_error = '';
    const product_error = '';
    const drawn_error = '';
    let helpdesk_error = '';
    let task_error = '';
    let start_date_error = '';
    let end_date_error = '';
    let attendant_error = '';

    const attachments_error = '';

    if (formState.key === 'order' && (formState.order_number?.length === 0 || !formState.order_number)) {
      order_error = 'É necessário informar um pedido';
      budget_error = '';
      helpdesk_error = '';
    }
    if (formState.key === 'budget' && (formState.budget_number?.length === 0 || !formState.budget_number)) {
      order_error = '';
      budget_error = 'É necessário informar um orçamento';
      helpdesk_error = '';
    }
    if (formState.key === 'helpdesk' && (formState.helpdesk_id?.length === 0 || !formState.helpdesk_id)) {
      order_error = '';
      budget_error = '';
      helpdesk_error = 'É necessário informar um helpdesk';
    }

    if (!formState.task?.id || formState.task.id < 1) {
      task_error = 'É necessário informar uma tarefa';
    }
    if (!formState.expected_start_date || formState.expected_start_date.length === 0) {
      start_date_error = 'É necessário informar uma data de início';
    }
    if (!formState.expected_end_date || formState.expected_end_date.length === 0) {
      end_date_error = 'É necessário informar uma data de fim';
    }
    if (!formState.attendant || formState.attendant?.id?.length === 0) {
      attendant_error = 'É necessário informar um atendente';
    }
    setErrorState({
      order_error,
      budget_error,
      helpdesk_error,
      product_error,
      drawn_error,
      task_error,
      start_date_error,
      end_date_error,
      attendant_error,
      attachments_error,
    });
    if (
      order_error.length > 0 ||
      budget_error.length > 0 ||
      task_error.length > 0 ||
      start_date_error.length > 0 ||
      end_date_error.length > 0 ||
      attendant_error.length > 0 ||
      attachments_error.length > 0
    )
      return false;

    return true;
  }

  const handleSubmit = async () => {
    if (submiting) return;
    setSubmiting(true);
    if (handleValideData()) {
      const data = new FormData();
      data.append('order_number', formState.order_number);
      data.append('budget_number', formState.budget_number);
      data.append('helpdesk_id', formState.helpdesk_id);
      data.append('product_id', formState.product?.id?.toString());
      data.append('drawn_id', formState.drawn?.id?.toString());
      data.append('task_id', formState.task.id?.toString());
      data.append('attendant_id', formState.attendant?.id?.toString());
      data.append('expected_start_date', formState.expected_start_date);
      data.append('expected_end_date', formState.expected_end_date);
      data.append('rework', formState.rework.toString());
      data.append('obs', formState.obs);
      attachments.map(attachment => data.append('files', attachment));

      try {
        const { data: os } = await api.put('erp/engineering/os/add', data);
        if (os?.id > 0) {
          onSubmited?.(true, 'Inserido com sucesso.');
          resetFormState();
        }
      } catch (error) {
        const { data: response } = error.response;

        let { message } = response;

        if (message === 'access_denied') message = 'Você não tem permissão para criar O.S.';
        if (message === 'invalid_key') message = 'É nessário informar pelo menos um pedido, orçamento ou helpdesk';
        if (message === 'invalid_order_number') message = 'Pedido inválido';
        if (message === 'invalid_budget_number') message = 'Orçamento inválido';
        if (message === 'invalid_helpdesk_id') message = 'Helpdesk inválido';
        if (message === 'invalid_product_id') message = 'Produto inválido';
        if (message === 'invalid_drawn_id') message = 'Desenho inválido';
        if (message === 'invalid_task_id') message = 'Tarefa inválido';
        if (message === 'invalid_attendant_id') message = 'Atendente inválido';
        if (message === 'invalid_expected_start_date') message = 'Data prevista de início inválida';
        if (message === 'invalid_expected_end_date') message = 'Data prevista de fim inválida';
        if (message === 'insert_error') message = 'Erro ao criar O.S, tente novamente';

        onSubmited?.(false, message);
      }
    }
    setSubmiting(false);
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    const { value } = e.target;
    setFormState(old => ({ ...old, [id]: value }));
  };

  const handleExpedtedStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let { expected_end_date } = formState;
    const startDate = toDate(value);
    expected_end_date = format(addBusinessDays(startDate, formState.days), 'yyyy-MM-dd');
    setFormState(old => ({ ...old, expected_start_date: value, expected_end_date }));
  };

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachments;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of event.target.files) {
          newAttachments.push(attachment);
        }
      } catch (err) {
        console.error(err);
      }

      setAttachments([...newAttachments]);
    }
  };

  const handleRemoveAllAttachments = () => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    setAttachments([]);
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachments;
    newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);
  };

  const handleOnCloseDialog = () => {
    if (submiting) return;
    handleClose?.();
  };

  const handleClickCancelDialog = () => {
    setAttachments([]);
    setFormState(defaultFormState);
    handleClose?.();
  };

  useEffect(() => {
    if (open) {
      handleOpen?.();
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOrderOrBudgetOrHelpdeskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    let { order_number } = formState;
    let { budget_number } = formState;
    let { helpdesk_id } = formState;
    if (value === 'order') {
      budget_number = '';
      helpdesk_id = '';
    }
    if (value === 'budget') {
      order_number = '';
      helpdesk_id = '';
    } else {
      order_number = '';
      budget_number = '';
    }
    setFormState(old => ({ ...old, key: value, order_number, budget_number, helpdesk_id }));
    setErrorState(old => ({ ...old, order_error: '', budget_error: '', helpdesk_error: '' }));
    setOsKey(value);
  };

  const inputBudget = useMemo(
    () => (
      <TextField
        fullWidth
        id="budget_number"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const budget_number = e?.target?.value ?? '';
          const budget_error = budget_number?.length > 0 ? '' : 'É necessário informar um orçamento';
          setFormState(old => ({ ...old, budget_number }));
          setErrorState(old => ({ ...old, budget_error }));
        }}
        label="Orçamento"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.budget_error?.length > 0}
        helperText={errorState.budget_error}
        size="small"
        type="number"
      />
    ),
    [formState.budget_number, errorState.budget_error],
  );

  const inputOrder = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-orders"
        id="order_number"
        onChange={(e: ValueProps) => {
          const order_number = e?.id ?? '';
          const order_error = order_number?.length > 0 ? '' : 'É necessário informar um pedido';
          setFormState(old => ({ ...old, order_number }));
          setErrorState(old => ({ ...old, order_error }));
        }}
        label="Pedido"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        error={errorState.order_error?.length > 0}
        helperText={errorState.order_error}
      />
    ),
    [formState.order_number, errorState.order_error],
  );

  const inputHelpdesk = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-id"
        id="helpdesk_id"
        onChange={(e: ValueProps) => {
          const helpdesk_id = e?.id ?? '';
          const helpdesk_error = helpdesk_id?.length > 0 ? '' : 'É necessário informar um helpdesk';
          setFormState(old => ({ ...old, helpdesk_id }));
          setErrorState(old => ({ ...old, helpdesk_error }));
        }}
        label="Helpdesk"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        searchParams={{ order: 'DESC', target: 5 }}
        error={errorState.helpdesk_error?.length > 0}
        helperText={errorState.helpdesk_error}
      />
    ),
    [formState.helpdesk_id, errorState.helpdesk_error],
  );

  const inputTask = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <InputEngOsTask
          onSelect={(_task: any) => {
            const endDate = addBusinessDays(toDate(formState?.expected_start_date), _task?.days ?? 0);
            setFormState(old => ({ ...old, task: _task, expected_end_date: format(endDate, 'yyyy-MM-dd') }));
            setErrorState(old => ({ ...old, task_error: _task?.id ? '' : errorState.task_error }));
          }}
          onClear={() => {
            setErrorState(old => ({ ...old, task_error: 'É necessário informar uma tarefa' }));
          }}
          rework={formState.rework === 1}
          error={errorState.task_error?.length > 0}
          helperText={errorState.task_error}
        />
        <TextField
          sx={{ width: 124 }}
          label="Retrabalho"
          id="rework"
          type="string"
          size="small"
          value={formState.rework === 0 ? 'Não' : 'Sim'}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <Switch
                sx={{ mr: 1 }}
                checked={formState.rework === 1}
                size="small"
                color="warning"
                onChange={e => {
                  setFormState(old => ({ ...old, rework: e.target.checked === true ? 1 : 0 }));
                }}
              />
            ),
          }}
        />
      </Box>
    ),
    [formState.task, formState.rework, errorState.task_error],
  );

  const inputUsers = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        id="attendant"
        value={formState.attendant}
        searchParams={{ sectores: '233;142;145;231;232' }}
        onChange={(e: ValueProps) => {
          const attendant_error = e?.id ? '' : 'É necessário informar um atendente';
          setErrorState(old => ({ ...old, attendant_error }));
          setFormState(old => ({ ...old, attendant: e }));
        }}
        label="Atendente"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        error={errorState.attendant_error?.length > 0}
        helperText={errorState.attendant_error}
      />
    ),
    [formState.attendant, errorState.attendant_error],
  );

  const inputProduct = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-products"
        id="product"
        value={formState.product}
        searchParams={{ type: 'P' }}
        onChange={(e: ValueProps) => {
          setFormState(old => ({ ...old, product: e }));
        }}
        label="Produto"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        error={errorState.product_error?.length > 0}
        helperText={errorState.product_error}
      />
    ),
    [formState.product, errorState.product_error],
  );

  const inputDrawn = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-drawn"
        id="drawn"
        value={formState.drawn}
        searchParams={{ type: 'P' }}
        onChange={(e: ValueProps) => {
          setFormState(old => ({ ...old, drawn: e }));
        }}
        label="Desenho"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        error={errorState.drawn_error?.length > 0}
        helperText={errorState.drawn_error}
      />
    ),
    [formState.drawn, errorState.drawn_error],
  );

  const inputStartDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Início Previsto"
        id="expected_start_date"
        type="date"
        onChange={handleExpedtedStartDateChange}
        value={formState.expected_start_date}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.start_date_error?.length > 0}
        helperText={errorState.start_date_error}
      />
    ),
    [formState.expected_start_date, formState.expected_end_date, formState.days, errorState.start_date_error],
  );

  const inputEndDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Fim Previsto"
        id="expected_end_date"
        type="date"
        onChange={handleFieldChange}
        value={formState.expected_end_date}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.end_date_error?.length > 0}
        helperText={errorState.end_date_error}
      />
    ),
    [formState.expected_end_date, formState.expected_start_date, formState.days, errorState.end_date_error],
  );

  const inputObs = useMemo(
    () => (
      <TextField
        fullWidth
        label="Obs."
        id="obs"
        type="text"
        onChange={handleFieldChange}
        value={formState.obs}
        size="small"
        multiline
        minRows={4}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
      />
    ),
    [formState.obs],
  );

  const inputAttachment = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography sx={{ fontSize: 12 }}>Arquivos</Typography>

        <Box
          sx={{
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
              border: `1px solid`,
            },
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            p: 1,
            maxHeight: 128,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              flex: 1,
              height: '100%',
            }}
          >
            {attachments?.length === 0 ? (
              <Box sx={{ p: 0.5 }}>
                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                  Nenhum arquivo informado
                </Typography>
              </Box>
            ) : (
              attachments?.map((attachment: File, index: number) => {
                const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                const name = attachment.name.toLocaleLowerCase();
                const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                const size = humanFileSize(attachment.size);
                const key = `${index}-${name}-${type}-${size}`;
                totalSize += attachment.size;
                return (
                  <Box key={key} sx={{ display: 'flex', gap: 1, flex: 1, alignItems: 'center' }}>
                    <Tooltip title="Remover arquivo" arrow disableInteractive>
                      <IconButton
                        color="error"
                        aria-label="remove file"
                        component="label"
                        size="small"
                        onClick={() => handleRemoveAttachments(index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                      {name} ({size})
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {attachments?.length > 0 && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption">{`${attachments?.length} ${
                attachments?.length === 1 ? 'arquivo selecionado' : 'arquivos selecionados'
              } (${humanFileSize(totalSize)})`}</Typography>
            </Box>
          )}
          <Tooltip title="Remover todos os anexos">
            <IconButton
              color="error"
              aria-label="remove files"
              component="label"
              size="small"
              disabled={attachments?.length === 0}
              onClick={() => handleRemoveAllAttachments()}
            >
              <DeleteSweepOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Selecionar arquivo">
            <IconButton color="primary" aria-label="upload picture" component="label" size="small">
              <input
                style={{ display: 'none' }}
                id="attachment-input"
                hidden
                type="file"
                multiple
                ref={refInputAttachment}
                onChange={handleAddAttachments}
              />
              <NoteAddOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),
    [attachments],
  );

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md" disableEscapeKeyDown>
      <DialogTitle>Nova O.S.</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
          }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="order-group-label"
              name="order-buttons-group"
              onChange={handleOrderOrBudgetOrHelpdeskChange}
              value={osKey}
            >
              <FormControlLabel value="order" control={<Radio />} label="Pedido" />
              <FormControlLabel value="budget" control={<Radio />} label="Orçamento" />
              <FormControlLabel value="helpdesk" control={<Radio />} label="Helpdesk" />
            </RadioGroup>
          </FormControl>
          {osKey === 'order' && inputOrder}
          {osKey === 'budget' && inputBudget}
          {osKey === 'helpdesk' && inputHelpdesk}
          {inputProduct}
          {inputDrawn}
          {inputTask}

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            {inputStartDate}
            {inputEndDate}
          </Box>
          {inputUsers}

          {inputObs}
          {inputAttachment}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClickCancelDialog} disabled={submiting}>
          Cancelar
        </Button>
        <Button color="success" variant="contained" onClick={handleSubmit} disabled={submiting}>
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { EnOsNewOSForm };
