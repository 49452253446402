import React from 'react';
import Axios, { Canceler } from 'axios';
import { Box } from '@mui/material';
import styles from './styles';
import api from '../../../../services/api';

export default function StatisticsPage(props: any) {
  const classes = styles();
  return <Box />;
}
