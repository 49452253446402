import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, Tooltip, useTheme, TextFieldProps } from '@mui/material';

import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { EngOsTasksSelectForm } from '../EngOsTasksSelectForm';

type InputTaskType = {
  id: number;
  description: string;
};

type InputEngOsTaskProps = TextFieldProps & {
  onSelect: (task: InputTaskType) => void;
  onClear?: () => void;
  initialValue?: InputTaskType;
  rework?: boolean;
};

function InputEngOsTask({ onSelect, initialValue, rework = false, onClear, ...props }: InputEngOsTaskProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [task, setTask] = useState<InputTaskType>(initialValue || null);
  const [openSelectTaskForm, setOpenSelectTaskForm] = useState(false);

  useEffect(() => {
    setTask(initialValue);
  }, [initialValue]);

  return (
    <>
      <TextField
        {...props}
        fullWidth
        label="Tarefa"
        id="parent_task"
        type="string"
        value={task?.description || ''}
        size="small"
        placeholder="Selecionar Tarefa"
        onDoubleClick={() => setOpenSelectTaskForm(true)}
        InputProps={{
          readOnly: true,
          startAdornment: !rework ? (
            <InputAdornment position="start" />
          ) : (
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mr: 1 }}>
              <Tooltip title="Retrabalho">
                <SettingsBackupRestoreOutlinedIcon sx={{ cursor: 'default' }} color="warning" />
              </Tooltip>
            </Box>
          ),
          endAdornment: (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {task && (
                <Tooltip title="Limpar">
                  <IconButton
                    color="default"
                    aria-label="clear"
                    component="label"
                    onClick={() => {
                      setTask(null);
                      onClear();
                    }}
                    size="small"
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Selecionar Tarefa">
                <IconButton
                  color="default"
                  aria-label="select"
                  component="label"
                  onClick={() => setOpenSelectTaskForm(true)}
                  size="small"
                >
                  <ListAltOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        }}
      />

      <EngOsTasksSelectForm
        open={openSelectTaskForm}
        onSelect={_task => {
          setTask(_task);
          setOpenSelectTaskForm(false);
          onSelect?.(_task);
        }}
        onClose={() => {
          setOpenSelectTaskForm(false);
        }}
      />
    </>
  );
}
export { InputEngOsTask };
export type { InputEngOsTaskProps, InputTaskType };
