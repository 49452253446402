import Dashboard from './dashboard';
import Accesses from './accesses';
import Dayoff from './dayoff';
import Vacation from './vacation';

export default {
  Dashboard,
  Accesses,
  Dayoff,
  Vacation,
};
