import React from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';

import ScaleLoader from 'react-spinners/ScaleLoader';

interface IDashboardInfoCard {
  icon: React.ReactNode;
  title?: React.ReactNode;
  content: React.ReactNode;
  subcontent: React.ReactNode;
  color?: string;
  loading: boolean;
  onClick?: () => void;
}

function DashboardInfoCard({ content, icon, subcontent, title, color, loading, onClick = null }: IDashboardInfoCard) {
  const theme = useTheme();
  const isClickable = onClick !== null;
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 1,
        p: 2,
        height: '100%',
        flex: 1,
        borderLeft: `4px solid ${color}`,
        cursor: isClickable ? 'pointer' : 'inherit',
        transition: 'opacity 0.3s',
        '&:hover': isClickable
          ? {
              opacity: 0.8,
            }
          : {},
      }}
      onClick={onClick}
    >
      {title && <Typography variant="subtitle2">{title}</Typography>}

      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flex: 1,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {icon}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            width: '100%',
          }}
        >
          {content}
          {subcontent}
        </Box>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              backgroundColor: theme.palette.background.paper,
              opacity: 0.8,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
            }}
          >
            <ScaleLoader
              color={theme.palette.info.main}
              loading={loading}
              cssOverride={{
                display: 'block',
                margin: '0 auto',
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export { DashboardInfoCard };
