import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  InputBase,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { ChatComment } from '../../../components/ChatComment';
import api from '../../../services/api';
import FeedbackAlert, { FeedbackProps } from '../../../components/FeedbackAlert';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { avatarUrl } from '../../../utils/functions';

interface Props {
  id: number | string;
  open: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
}
type UserDataType = {
  id: number;
  name: string;
  employee_position: string;
  sector: string;
  birthday_date: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  photo: string;
  my: true;
};
function BirthdayCelebrateForm({ id, open, handleClose, handleOpen }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const refInputText = useRef<HTMLInputElement>(null);

  const [submiting, setSubmiting] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [userData, setUserData] = useState<UserDataType>(null);

  const [feedback, setFeedback] = useState<FeedbackProps>({
    message: '',
    show: false,
  });

  const handleOnCloseDialog = () => {
    handleClose?.();
  };

  const postCelebration = async (text: string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`hcm/birthdays/${id}/celebrate`, {
        text,
      });

      setFeedback({
        message: 'Mensagem enviada com sucesso',
        show: true,
        type: 'success',
      });

      const input = refInputText.current.children[0] as HTMLInputElement;
      input.value = '';
      handleOnCloseDialog();
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'celebration_already_sent'
            ? 'Mensagem já enviada'
            : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setSubmiting(false);
  };

  const getUserInfo = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<UserDataType>(`/intranet/users/${id}/profile`);
      data.photo = avatarUrl(id);
      setUserData(data);
    } catch (error) {}
    setFetching(false);
  };

  const renderFeedbackAlert = useMemo(
    () => (
      <FeedbackAlert
        message={feedback.message}
        show={feedback.show}
        durations={8000}
        type={feedback.type}
        onClose={() =>
          setFeedback(old => ({
            ...old,
            show: false,
          }))
        }
      />
    ),
    [feedback],
  );

  useEffect(() => {
    if (open) {
      handleOpen?.();
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md">
        <DialogTitle>
          HCM - Comemorar aniversário
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleOnCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',

              alignItems: 'center',
              gap: 2,
            }}
          >
            <Avatar
              alt={`${userData?.name}`}
              src={avatarUrl(id, true)}
              aria-label={`${userData?.name.toLowerCase()}`}
              sx={{ height: '4rem', width: '4rem' }}
            />
            <Box>
              <Typography sx={{ fontSize: '1rem' }}>{userData?.name}</Typography>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                }}
              >
                {userData?.sector}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',

              bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
              borderRadius: 1,
              borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
              borderStyle: 'solid',
              borderWidth: 1,
              overflowY: 'auto',
            }}
          >
            <InputBase
              ref={refInputText}
              fullWidth
              multiline
              minRows={3}
              maxRows={12}
              placeholder="Digite a sua mensagem aqui..."
              inputProps={{ 'aria-label': 'pesquisar' }}
              sx={{ px: 1 }}
              // onChange={handleSearchTextChange}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 3, gap: 2 }}>
          <Button color="inherit" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="warning"
            variant="outlined"
            onClick={() => {
              const input = refInputText.current.children[0] as HTMLInputElement;
              postCelebration(input?.value ?? '');
            }}
          >
            Comemorar
          </Button>
        </DialogActions>
      </Dialog>
      {renderFeedbackAlert}
    </>
  );
}
export { BirthdayCelebrateForm };
