import React, { useEffect, useState } from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import api from '../services/api';

type TaskType = {
  id: number;
  description: string;
  parent_task: number;
  situation: number;
  updated_at: string;
  updated_at_formated: string;
  updated_by: number;
  updated_by_name: string;
  days: number;
  childrens: TaskType[];
};

interface EngOsTasksSelectFormProps {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelect: (task: TaskType) => void;
  onClose: () => void;
  onOpen?: () => void;
  // eslint-disable-next-line no-unused-vars
}

function EngOsTasksSelectForm({ open, onClose, onOpen, onSelect }: EngOsTasksSelectFormProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [task, setTask] = useState<TaskType>({ id: 0 } as TaskType);

  const fetchingData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<TaskType[]>('erp/engineering/os/tasks/tree', {
        params: {
          situation: 1,
        },
      });

      setTasks([...data]);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setFetching(false);
  };

  const renderTask = (_task: TaskType) => {
    return (
      <TreeItem
        key={_task.id}
        nodeId={_task.id.toString()}
        label={`${_task.description} ${
          _task.childrens.length > 0 ? '' : ` - (${_task.days} ${_task.days > 0 ? 'dias' : 'dia'})`
        }`}
        onClick={() => {
          if (_task.childrens.length === 0) {
            setTask(_task);
          } else {
            setTask({ id: 0 } as TaskType);
          }
        }}
      >
        {_task.childrens.map(children => renderTask(children))}
      </TreeItem>
    );
  };

  const handleOnSelect = () => {
    if (fetching || task.id < 1) return;
    onSelect?.(task);
  };

  const handleOnClose = () => {
    if (fetching) return;
    onClose?.();
  };
  const handleOnOpen = () => {
    setTask({ id: 0 } as TaskType);
    onOpen?.();
  };

  useEffect(() => {
    handleOnOpen?.();
    fetchingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth={true} maxWidth="md" disableEscapeKeyDown>
      <DialogTitle> OS - Selecionar Tarefa</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
            height: '100%',
            filter: fetching ? 'blur(1.5px)' : '',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1, width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Typography>Tarefas</Typography>
            </Box>
            {fetching ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                  height: 240,
                }}
              >
                <Typography color={theme.palette.text.secondary}>Carregando</Typography>
              </Box>
            ) : tasks.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Typography color={theme.palette.text.secondary}>Nenhuma tarefa encontrada</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  bgcolor: isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.5)',
                  p: 1,
                  borderRadius: 1,
                  border: `1px solid ${isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.3)'}`,
                }}
              >
                <TreeView
                  aria-label="task navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  // selected={task.toString()}
                  sx={{
                    height: 240,
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  {tasks?.map(task => renderTask(task))}
                </TreeView>
              </Box>
            )}
          </Box>
        </Box>
        {fetching && (
          <Box
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              height: '100%',
              width: '100%',
              userSelect: 'none',
            }}
          >
            Carregando...
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleOnClose}>
          Cancelar
        </Button>
        <Button color="success" variant="contained" onClick={handleOnSelect} disabled={fetching || task.id < 1}>
          SELECIONAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export { EngOsTasksSelectForm };
export type { TaskType };
