import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  Input,
  InputLabel,
  Button,
  Alert,
  Snackbar,
} from '@mui/material';

import { Edit as EditIcon, Info as InfoIcon } from '@mui/icons-material';

import Axios, { Canceler } from 'axios';

import userUndefinedImg from '../../../images/user-undefined.jpg';
import api from '../../../services/api';
import styles from './styles';
import { avatarUrl } from '../../../utils/functions';

export interface UserProfileData {
  id: string;
  my: boolean;
  login: string;
  type: string;
  name: string;
  birthday_date: string;
  employee_position: string;
  sector: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  logged_at: string;
  situation: string;
}

interface State {
  textmask: string;
  numberformat: string;
}

export default function ProfilePage() {
  const classes = styles();
  const [loadingData, setLoadingData] = useState(false);
  const [userData, setUserData] = useState<UserProfileData>();
  const targetRequest = '11';
  const titleRequest = 'Alteração nos dados cadastrais';
  const textRequest = `<p>Gentileza alterar as informações listadas a baixo do meu usuário para:</p><p><br></p>`;
  const [email1, setEmail1] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');

  const [updating, setUpdating] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const loadUserData = () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      api
        .get(`intranet/users/my/profile`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          const user: UserProfileData = response.data;

          setUserData(user);
          setEmail1(user?.email || '');
          setPhone1(user?.phone1 || '');
          setPhone2(user?.phone2 || '');
          setLoadingData(false);
        })
        .catch((error: any) => {
          const { data } = error.response;
          if (data) {
            const errorMessage = data?.message;
            console.error(errorMessage);
          }
          setLoadingData(false);
        });
    }
    return () => cancel();
  };
  const handleOnChangeEmail1 = event => {
    const value = event?.target?.value;

    setEmail1(value);
  };
  const handleOnChangePhone1 = event => {
    const value = event?.target?.value;

    setPhone1(value);
  };
  const handleOnChangePhone2 = event => {
    const value = event?.target?.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1');
    setPhone2(value);
  };

  async function HandleUpdateProfile() {
    let cancel: Canceler;
    if (updating) return;
    setUpdating(true);
    try {
      const response = await api.post('intranet/users/profile', {
        email1,
        phone1,
        phone2,

        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      setSnackbarType('success');
      setSnackbarMsg('Informações atualizadas com sucesso');
      setOpenSnackbar(true);
      setUpdating(false);
    } catch (error) {
      console.error(error);
      const { data } = error.response;
      setSnackbarType('error');
      let msgError = '';
      if (data?.message?.length > 0) {
        msgError = '';
      } else {
        msgError = 'Erro desconhecido, favor contate o departamento de TI';
      }
      setSnackbarMsg(msgError);
      setOpenSnackbar(true);
      setUpdating(false);
    }
    return () => cancel();
  }
  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.contentRoot}>
          <Paper className={classes.topBar}>
            <Box className={classes.profileContainer}>
              <img
                className={classes.profileImage}
                src={avatarUrl(userData?.id)}
                alt="Foto"
                onError={(e: any) => {
                  e.target.src = avatarUrl(0);
                }}
              />

              <Box className={classes.profileDataContainer}>
                <Typography className={classes.profileName}>{userData?.name}</Typography>
                <Typography className={classes.profileSector}>{userData?.sector}</Typography>
                <Typography className={classes.profilePosition}>{userData?.employee_position}</Typography>
              </Box>
              <Box className={classes.actionsContainer}>
                <Tooltip title="Solicitar alteração">
                  <IconButton
                    color="warning"
                    onClick={() => {
                      window.open(
                        `/helpdesk/add/?target=${targetRequest}&title=${titleRequest}&private=true&text=${textRequest}`,
                      );
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box className={classes.contentRoot}>
          <Paper>
            <Box className={classes.aboutContainer}>
              <Box className={classes.aboutTitleContainer}>
                <InfoIcon className={classes.aboutTitleIcon} color="warning" />
                <Typography className={classes.aboutTitleText}>Sobre</Typography>
              </Box>
              <Box className={classes.aboutDataContainer}>
                <TextField
                  label="Email Corporativo"
                  variant="outlined"
                  size="small"
                  value={email1}
                  onChange={handleOnChangeEmail1}
                  fullWidth
                />

                <TextField
                  label="Ramal"
                  variant="outlined"
                  size="small"
                  value={phone1}
                  onChange={handleOnChangePhone1}
                  fullWidth
                />
                <TextField
                  label="Celular"
                  variant="outlined"
                  size="small"
                  value={phone2}
                  onChange={handleOnChangePhone2}
                  fullWidth
                />
              </Box>
              <Box className={classes.aboutActionsContainer}>
                <Tooltip title={!updating ? 'Salvar as alteração' : ''}>
                  <Button variant="contained" disabled={updating} onClick={HandleUpdateProfile} style={{ width: 120 }}>
                    {!updating ? 'Salvar' : 'Salvando'}
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
