import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, Button, Tooltip, Snackbar, TextField, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { ResponsivePie } from '@nivo/pie';

import { Alert } from '@mui/lab/';

import { yellow } from '@mui/material/colors';
import moment from 'moment';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ForumIcon from '@mui/icons-material/Forum';

import Axios, { Canceler } from 'axios';
import { HelpDeskSearchProps } from '../search';
import ProfileTooltip from '../../../components/ProfileTooltip';
import { ValueProps } from '../../../components/inputs/InputSearchAsync';
import { Helpdesk, HelpdeskData } from '../dashboard';
import Filters, { FiltersInputsProps } from '../../../components/Filters';
import api from '../../../services/api';
import styles from './styles';
import Comment from '../../../components/Comment';

export interface HeaderColumnProps {
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}

export interface RowColumnProps {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}

export interface RulesProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}

export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export interface HelpdeskAttendancePageProps {
  darkMode?: boolean;
  search?: HelpDeskSearchProps;
}
interface ChartsDataProps {
  id: string;
  label: string;
  value: number;
  color: string;
}

function RenderResult(props: { gettingHelpdeskData; classes; helpdeskData; window; helpdeskChartData; darkMode }) {
  const { gettingHelpdeskData, classes, helpdeskData, window, helpdeskChartData, darkMode } = props;
  return (
    <Box mt={2}>
      {gettingHelpdeskData ? (
        <Box className={classes.gettingHelpdeskDataRoot}>
          <Typography>CARREGANDO...</Typography>
        </Box>
      ) : (
        <Box>
          {helpdeskData?.data?.length === 0 ? (
            <Box className={classes.gettingHelpdeskDataRoot}>
              <Typography>NENHUM HELPDESK ENCONTRADO</Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {window.navigator.userAgent.indexOf('Windows NT 5.1') === -1 && (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Paper className={classes.helpdeskPaperChartRoot}>
                    <Box display="flex" justifyContent="center" alignItems="center" height={48}>
                      <Typography>ESTATÍSTICAS</Typography>
                    </Box>
                    <Box width="100%" height="100%" maxHeight="calc(100% - 48px)">
                      <ResponsivePie
                        data={helpdeskChartData}
                        animate
                        activeOuterRadiusOffset={2}
                        innerRadius={0.6}
                        padAngle={2}
                        cornerRadius={2}
                        arcLinkLabelsTextColor={darkMode ? '#f3f3f3' : '#252525'}
                        arcLabelsTextColor="#424242"
                        arcLinkLabelsDiagonalLength={10}
                        arcLinkLabelsStraightLength={15}
                        arcLinkLabelsColor={{
                          from: 'color',
                        }}
                        arcLinkLabel={d => `${d.label}: ${d.value}`}
                        arcLinkLabelsThickness={3}
                        margin={{ top: 24, right: 108, bottom: 24, left: 108 }}
                        colors={{ datum: 'data.color' }}
                        // theme={{ textColor: darkMode ? '#f3f3f3' : '#252525' }}
                        tooltip={CustomTooltipCharts}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', TotalCharts]}
                        legends={[]}
                        borderWidth={1}
                        borderColor="black"
                        enableArcLabels={false}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}
              {helpdeskData?.data?.map((helpdesk: Helpdesk, index) => {
                const rowId = `row-${index}`;
                const post = helpdesk.posts?.[helpdesk.posts.length - 1];
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={rowId}>
                    <Paper
                      className={`${classes.helpdeskPaperRoot} ${
                        helpdesk.situation === 0
                          ? classes.borderLeftColorYellow
                          : helpdesk.situation === 1 && helpdesk.days_delay === 0
                            ? classes.borderLeftColorGreen
                            : classes.borderLeftColorRed
                      }`}
                    >
                      <Box className={classes.helpdeskDataRoot}>
                        <TextField
                          id="helpdesk-deadline"
                          label="Número"
                          value={helpdesk.id}
                          variant="outlined"
                          size="small"
                          style={{ marginRight: 4, maxWidth: 72 }}
                        />

                        <TextField
                          id="helpdesk-delay"
                          label="Situação"
                          InputProps={{
                            startAdornment:
                              helpdesk.situation === 0 ? (
                                <WarningIcon
                                  className={classes.warningIimeSituationColor}
                                  style={{ marginRight: 8, cursor: 'default' }}
                                />
                              ) : helpdesk.situation === 1 ? (
                                <WatchLaterIcon
                                  className={
                                    helpdesk.days_delay === 0
                                      ? classes.onTimeSituationColor
                                      : classes.offTimeSituationColor
                                  }
                                  style={{ marginRight: 8, cursor: 'default' }}
                                />
                              ) : helpdesk.situation === 2 ? (
                                <CheckCircleIcon
                                  className={
                                    helpdesk.days_delay === 0
                                      ? classes.onTimeSituationColor
                                      : classes.offTimeSituationColor
                                  }
                                  style={{ marginRight: 8, cursor: 'default' }}
                                />
                              ) : (
                                helpdesk.situation === 3 && (
                                  <CancelIcon
                                    className={classes.offTimeSituationColor}
                                    style={{ marginRight: 8, cursor: 'default' }}
                                  />
                                )
                              ),
                          }}
                          value={helpdesk.situation_description}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{ marginLeft: 4, marginRight: 4 }}
                        />
                        <TextField
                          id="helpdesk-delay"
                          label="Atraso"
                          value={`${helpdesk.days_delay} ${helpdesk.days_delay <= 1 ? 'dia' : 'dias'}`}
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 4, maxWidth: 72 }}
                        />

                        <TextField
                          id="helpdesk-delay"
                          label="Trâmites"
                          value={helpdesk.posts?.length}
                          fullWidth
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 8, maxWidth: 84 }}
                          InputProps={{
                            startAdornment: (
                              <Tooltip
                                title={`${helpdesk.posts?.length} Trâmites${
                                  helpdesk.posts_unreaded > 0 ? ` e ${helpdesk.posts_unreaded} não lido(s)` : ''
                                }`}
                              >
                                <Badge
                                  badgeContent={helpdesk.posts_unreaded}
                                  color="error"
                                  variant="dot"
                                  style={{ marginRight: 8 }}
                                >
                                  <ForumIcon style={{ fontSize: 21, cursor: 'default' }} />
                                </Badge>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Box>

                      <Box className={classes.helpdeskDataRoot}>
                        <TextField
                          id="helpdesk-delay"
                          label="Abertura"
                          value={helpdesk.created_at_formated}
                          fullWidth
                          variant="outlined"
                          size="small"
                          style={{ marginRight: 4 }}
                        />
                        <TextField
                          id="helpdesk-deadline"
                          label="Prazo"
                          value={
                            helpdesk.deadline_formated === '00/00/0000' ? '-------------' : helpdesk.deadline_formated
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 4 }}
                          InputProps={{
                            startAdornment: helpdesk.days_remaining === 0 && helpdesk.situation === 1 && (
                              <Tooltip title="Último dia!">
                                <WarningIcon
                                  className={classes.warningIimeSituationColor}
                                  style={{ marginRight: 8, cursor: 'default' }}
                                />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Box>
                      <Box className={classes.helpdeskDataRoot}>
                        <TextField
                          id="helpdesk-"
                          label="Solicitante"
                          value={helpdesk.owner_name}
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <ProfileTooltip userId={helpdesk.owner}>
                                <AccountCircleIcon style={{ marginRight: 8, cursor: 'default' }} />
                              </ProfileTooltip>
                            ),
                          }}
                        />
                      </Box>
                      <Box className={classes.helpdeskDataRoot}>
                        <TextField
                          id="helpdesk-target"
                          label="Setor Atendimento"
                          value={helpdesk.target_description === null ? '-------------' : helpdesk.target_description}
                          fullWidth
                          variant="outlined"
                          size="small"
                          style={{ marginRight: 4 }}
                        />

                        <TextField
                          id="helpdesk-attendance"
                          label="Atendente"
                          value={helpdesk.attendance_name === null ? '-------------' : helpdesk.attendance_name}
                          fullWidth
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 4 }}
                          InputProps={{
                            startAdornment: (
                              <ProfileTooltip userId={helpdesk.attendance}>
                                <AccountCircleIcon style={{ marginRight: 8, cursor: 'default' }} />
                              </ProfileTooltip>
                            ),
                          }}
                        />
                      </Box>
                      <Box className={classes.helpdeskDataRoot}>
                        <TextField
                          id="helpdesk-"
                          label="Título"
                          value={helpdesk.title}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Box>

                      <Box className={classes.helpdeskTextRoot} height={144} maxHeight={144} pb={2}>
                        <Typography className={classes.helpdeskTextTitle}>Descrição</Typography>
                        <Box
                          className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.helpdeskDescription}`}
                          dangerouslySetInnerHTML={{
                            __html: `${helpdesk.description}`.replace(
                              /{UPLOAD_PATH}/g,
                              `${api.getUri()}/static/uploads`,
                            ),
                          }}
                        />
                      </Box>

                      <Box className={classes.helpdeskTextRoot} height={144} maxHeight={144} pb={2}>
                        <Typography className={classes.helpdeskTextTitle}>Último Trâmite</Typography>
                        {post && (
                          <Box className={classes.helpdeskLastPostContainer}>
                            <Comment
                              userId={post?.owner}
                              userName={post?.owner_name}
                              userSector={post.owner_sector}
                              text={post?.text}
                              date={post?.created_at}
                              date_formated={post?.created_at_formated}
                              fullWidth
                              flip={post?.is_my}
                              system={post?.type >= 2}
                              readCheck
                              readed={post?.readed}
                              readed_date={post?.readed_at_formated}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box className={classes.helpdeskDataRoot}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={`/helpdesk/${helpdesk.id}`}
                        >
                          VISUALIZAR
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
}
function CustomTooltipCharts(e: any, darkMode: boolean) {
  return (
    <div
      style={{
        background: darkMode ? '#2b2b2b' : '#f9f9f9',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: darkMode ? '#c3c3c3' : '#313131',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '5px 9px',
      }}
    >
      <div style={{ whiteSpace: 'pre', display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            display: 'block',
            borderRadius: 999999,
            width: '12px',
            height: '12px',
            backgroundColor: e.datum.color,
            marginRight: '7px',
          }}
        />
        <span style={{ color: darkMode ? '#e0e0e0' : '#313131' }}>
          {e.datum.label}: <strong>{e.datum.value}</strong>
        </span>
      </div>
    </div>
  );
}

function TotalCharts({ dataWithArc, centerX, centerY }: any, darkMode: boolean) {
  let total = 0;
  dataWithArc.forEach((datum: { value: number }) => {
    total += datum.value;
  });

  return (
    <>
      <text
        x={centerX}
        y={centerY - 20}
        textAnchor="middle"
        dominantBaseline="central"
        fill={darkMode ? '#cccccc' : '#313131'}
        style={{
          fontFamily: 'Roboto,  sans-serif',
          fontSize: 10,
          fontWeight: 300,
        }}
      >
        Total
      </text>
      <text
        x={centerX}
        y={centerY + 2}
        textAnchor="middle"
        dominantBaseline="central"
        fill={darkMode ? '#e0e0e0' : '#313131'}
        style={{
          fontSize: 32,
          fontWeight: 600,
        }}
      >
        {total}
      </text>
    </>
  );
}

export default function HelpdeskAttendancePage(props: HelpdeskAttendancePageProps) {
  const classes = styles();
  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 00:00' });
  const { search, darkMode } = props;

  const [gettingHelpdeskData, setGettingHelpdeskData] = useState<boolean>(false);

  const [helpdeskData, setHelpdeskData] = useState<HelpdeskData>({} as HelpdeskData);

  const [searchSituation, setSearchSituation] = useState<string>(
    localStorage.getItem('search-helpdesk-attendance-situation') || 'all',
  );
  const [searchAttendances, setSearchAttendances] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-helpdesk-attendance-attendance') || '[]'),
  );

  const [searchOrderDir, setSearchOrderDir] = useState<OrderProp>(
    (localStorage.getItem('search-helpdesk-attendance-order-dir') || 'asc') as OrderProp,
  );
  const [searchOrderBy, setSearchOrderBy] = useState<string>(
    localStorage.getItem('search-helpdesk-attendance-order-by') || 'created_at',
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    let active = true;
    if (active) {
      getHelpdeskData({ ...search });
    }
    return () => {
      active = false;
    };
  }, []);

  const [helpdeskChartData, setHelpdeskChartData] = useState<ChartsDataProps[]>([]);

  const getHelpdeskData = ({
    current_page = 1,
    order_by = searchOrderBy,
    order_dir = searchOrderDir,
    data_limit = 0,
    attendance = searchAttendances,
    situation = search?.situation,
    inputSearchSituation = searchSituation,
  }: HelpDeskSearchProps & { inputSearchSituation?: string }) => {
    let cancel: Canceler;
    if (!gettingHelpdeskData) {
      let attendanceFilter = '';

      for (const item of attendance) {
        attendanceFilter += `;${item.id}`;
      }
      const params = {
        current_page,
        data_limit,
        order_by,
        order_dir,
        situation,
        attendance: attendanceFilter,
        attendance_only: true,
      };

      setGettingHelpdeskData(true);

      api
        .get('helpdesk/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          const responseData = response?.data as HelpdeskData;

          const newData = [] as Helpdesk[];

          for (const data of responseData?.data) {
            if (data.days_delay > 0 && data.situation === 1) {
              if (inputSearchSituation === 'all' || inputSearchSituation === 'out-time') newData.push(data);
            } else if (data.days_delay === 0 && data.situation === 1) {
              if (inputSearchSituation === 'all' || inputSearchSituation === 'on-time') newData.push(data);
            } else if (data.situation === 0) {
              if (inputSearchSituation === 'all' || inputSearchSituation === 'pedding') newData.push(data);
            } else {
              newData.push(data);
            }
          }

          responseData.data = [...newData];

          const newHelpdeskChartData = [] as ChartsDataProps[];
          if (responseData?.statistics?.pending > 0) {
            newHelpdeskChartData.push({
              id: 'helpdesk_pending',
              label: 'PENDENTE',
              value: responseData?.statistics?.pending,
              color: darkMode ? yellow[400] : yellow[900],
            });
          }

          if (responseData?.statistics?.in_progress_in_time > 0) {
            newHelpdeskChartData.push({
              id: 'helpdesk_in_progress_in_time',
              label: 'NO PRAZO',
              value: responseData?.statistics?.in_progress_in_time,
              color: darkMode ? '#5fe870' : '#2cc307',
            });
          }
          if (responseData?.statistics?.in_progress_out_of_time > 0) {
            newHelpdeskChartData.push({
              id: 'helpdesk_in_progress_out_of_time',
              label: 'ATRASADO',
              value: responseData?.statistics?.in_progress_out_of_time,
              color: darkMode ? '#ff7575' : '#f44336',
            });
          }

          setHelpdeskChartData(newHelpdeskChartData);

          setHelpdeskData(responseData);
          setGettingHelpdeskData(false);
        })
        .catch((error: any) => {
          const { data } = error?.response;
          let msg = 'Erro desconhecido, contate o TI.';
          if (data) {
            msg = data.error;
            if (!msg) msg = error.message;
            console.error(msg);
          }
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
          setGettingHelpdeskData(false);
        });
    }
    return () => cancel();
  };
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-helpdesk-attendance-situation') || 'all',
    localStorage.getItem('search-helpdesk-attendance-attendance') || '[]',
  ]);

  const handleApplyFilter = (e: any[]) => {
    setInputsValue(e);
    setSearchSituation(e[0]);
    setSearchAttendances(e[1]);
    setSearchOrderBy(e[2]);
    setSearchOrderDir(e[3]);

    getHelpdeskData({
      inputSearchSituation: e[0],
      attendance: e[1],
      order_by: e[2],
      order_dir: e[3],
      ...search,
    });
  };

  const filtersInputs = [
    {
      type: 'combobox',
      id: 'helpdesk-attendance-situation',
      label: 'Situação',
      defaultValue: 'all',
      comboBoxItems: [
        { value: 'all', text: 'Todos' },
        { value: 'on-time', text: 'No Prazo' },
        { value: 'out-time', text: 'Atrasados' },
        { value: 'pedding', text: 'Pendentes' },
      ],
    },
    {
      type: 'user',
      id: 'helpdesk-attendance-attendance',
      label: 'Atendente (+)',
      placeholder: 'Pesquisar Atendente',
      noOptionsText: 'Nenhum atendente encontrado.',
      defaultValue: '[]',
    },
    {
      type: 'combobox',
      id: 'helpdesk-attendance-order-by',
      label: 'Ordenar por',
      defaultValue: 'created_at',
      className: classes.comboBoxOrderDir,
      comboBoxItems: [
        { value: 'id', text: 'Número' },
        { value: 'situation', text: 'Situação' },
        { value: 'created_at', text: 'Abertura' },
        { value: 'deadline', text: 'Prazo' },
        { value: 'owner', text: 'Soliciante' },
        { value: 'attendance', text: 'Atendente' },
        { value: 'category', text: 'Categoria' },
        { value: 'subcategory', text: 'Subcategoria' },
      ],
    },
    {
      type: 'combobox',
      id: 'helpdesk-attendance-order-dir',
      label: 'Direção',
      defaultValue: 'asc',
      className: classes.comboBoxOrderBy,
      comboBoxItems: [
        { value: 'asc', text: 'A-Z' },
        { value: 'desc', text: 'Z-A' },
      ],
    },
  ] as FiltersInputsProps[];

  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      <Box className={classes.root}>
        <Grid container className={classes.contentRoot}>
          <Grid item xs={12}>
            <RenderResult
              gettingHelpdeskData={gettingHelpdeskData}
              classes={classes}
              helpdeskData={helpdeskData}
              window={window}
              helpdeskChartData={helpdeskChartData}
              darkMode={darkMode}
            />
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
