import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

import styles from './styles';

export type MessageAlertType = 'success' | 'error' | 'info' | 'warning';
interface Props {
  message: string;
  show: boolean;
  type?: MessageAlertType;
  anchorOrigin?: { horizontal: 'center' | 'left' | 'right'; vertical: 'bottom' | 'top' };
  autoHideDuration?: number;
  onClose?: () => void;
}

function MessageAlert({
  message = '',
  type = 'success',
  show = false,
  onClose = null,
  autoHideDuration = 6000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
}: Props) {
  const classes = styles();

  const [showAlert, setShowAlert] = useState(show);
  const handleSnackbarClose = () => {
    setShowAlert(false);
    onClose?.();
  };
  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  return (
    <>
      <Snackbar
        open={showAlert}
        autoHideDuration={autoHideDuration}
        onClose={handleSnackbarClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert severity={type} onClose={handleSnackbarClose}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export { MessageAlert };
