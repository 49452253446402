import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EggIcon from '@mui/icons-material/Egg';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { FaCarrot } from 'react-icons/fa';
import { GiCook } from 'react-icons/gi';
import { orange } from '@mui/material/colors';

function MenuCard() {
  const [responseData, setResponseData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const Row = (props: { first?: boolean; title: string; content: string; icon: ReactElement }) => {
    const { first = false, content, title, icon } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 1,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box
          sx={{
            minWidth: 28,
            maxWidth: 28,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'warning.light',
          }}
        >
          {icon}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            {title}
          </Typography>
          {loadingData ? (
            <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} height={18} width="50%" />
          ) : (
            <Typography>{content}</Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <MenuBookIcon sx={{ color: 'warning.light' }} />
          <Typography>Cardápio do Dia</Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            pt: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {!responseData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 1,
                minHeight: 60,
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              <NoMealsIcon />
              <Typography>Nenhum Cardápio foi definido para hoje</Typography>
            </Box>
          ) : (
            <>
              <Row first={true} icon={<RestaurantIcon />} title="Prato Principal" content="-" />
              <Row icon={<EggIcon />} title="Ovo" content="-" />
              <Row icon={<RiceBowlIcon />} title="Arroz" content="-" />
              <Row icon={<SoupKitchenIcon />} title="Feijão" content="-" />
              <Row icon={<DinnerDiningIcon />} title="Guarnição" content="-" />
              <Row icon={<FaCarrot style={{ fontSize: '1.3rem' }} />} title="Salada" content="-" />
              <Row icon={<IcecreamIcon />} title="Sobremesa" content="-" />
              <Row icon={<GiCook style={{ fontSize: '1.5rem' }} />} title="Prato do Chef" content="-" />
            </>
          )}
        </Box>

        <Box
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            borderTop: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Button size="small" variant="outlined" color="warning" component={Link} disabled to="/hcm/menu">
            Ver todos
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export { MenuCard };
