import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { HelpdeskCard } from './HelpdeskCard';
import api from '../../../../../services/api';

interface IHelpdeskRunCard {}

function HelpdeskRunCard() {
  const theme = useTheme();
  const [total, setTotal] = useState({ progress: 0, open: 0 });
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/progress');
      const { data: open } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');
      const newTotal = total;

      newTotal.progress = data.total;
      newTotal.open = open.total;

      setTotal(newTotal);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <HelpdeskCard
        loading={loading}
        title="Solicitações em andamento"
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total.progress)}
          </Typography>
        }
        subcontent={
          <Typography variant="caption">
            {total.open > 0 ? `${((total.progress / total.open) * 100).toFixed(0)}% em andamento` : '0% em andamento'}
          </Typography>
        }
        icon={<PlayCircleFilledWhiteOutlinedIcon color="success" sx={{ fontSize: 64 }} />}
        color={theme.palette.success.main}
      />
    );
  }, [total.progress, total.open, theme.palette.success.main]);

  return <>{renderCard}</>;
}

export { HelpdeskRunCard };
