import React from 'react';
import { Box, Paper, Typography, Grid, IconButton, Button, Tooltip } from '@mui/material';
import {
  AirplaneTicketOutlined as VacationIcon,
  FactCheckOutlined as FactCheckOutlinedIcon,
  MoreTime as MoreTimeIcon,
  Add as AddIcon,
  AccessTime as HourBankIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import styles from './styles';
import VacationsList from './VacationsList';
import AttendanceList from './AttendanceList';
import DayOffList from './DayOffList';
import UnderDevelopmentComponent from '../../../components/UnderDevelopment';
import HourBankList from './HourBankList';

export default function DashboardPage() {
  const classes = styles();
  const dev = false;
  if (dev) return <UnderDevelopmentComponent />;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Paper className={classes.root}>
            <Box className={classes.titleContainer}>
              <HourBankIcon className={`${classes.titleIcon} ${classes.colorOrange}`} />
              <Typography className={`${classes.titleText} ${classes.colorOrange}`}>Banco de Horas</Typography>
            </Box>

            <Box className={classes.container}>
              <HourBankList />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Paper className={classes.root}>
            <Box className={classes.titleContainer}>
              <VacationIcon className={`${classes.titleIcon} ${classes.colorGreen}`} />
              <Typography className={`${classes.titleText} ${classes.colorGreen}`}>Programação de Férias</Typography>
            </Box>

            <Box
              className={classes.container}
              style={{ height: 'calc(100vh - 308px)', maxHeight: 'calc(100vh - 308px)' }}
            >
              <VacationsList />
            </Box>
            <Tooltip title="Calendário de Férias">
              <Button
                color="success"
                variant="outlined"
                component={Link}
                style={{ width: '100%', marginTop: 12 }}
                to="/manager/vacation/calendar"
              >
                <CalendarMonthIcon />
              </Button>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Paper className={classes.root}>
            <Box className={classes.titleContainer}>
              <MoreTimeIcon className={`${classes.titleIcon} ${classes.colorBlue}`} />
              <Typography className={`${classes.titleText} ${classes.colorBlue}`}>Programação de Folgas</Typography>
            </Box>

            <Box
              className={classes.container}
              style={{ height: 'calc(100vh - 308px)', maxHeight: 'calc(100vh - 308px)' }}
            >
              <DayOffList />
            </Box>
            <Tooltip title="Programar Folgas">
              <Button
                color="success"
                variant="outlined"
                component={Link}
                style={{ width: '100%', marginTop: 12 }}
                to="/manager/dayoff/add"
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Paper className={classes.root}>
            <Box className={classes.titleContainer}>
              <FactCheckOutlinedIcon className={`${classes.titleIcon} ${classes.colorPurple}`} />
              <Typography className={`${classes.titleText} ${classes.colorPurple}`}>Lista de Presença</Typography>
            </Box>

            <Box className={classes.container}>
              <AttendanceList />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
