import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';
import { SxProps, Theme, useTheme } from '@mui/material';

function NewCircularProgress(props: CircularProgressProps) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  return (
    <Box sx={{ position: 'absolute', fontSize: 20, top: 'calc(45% - 10px)', left: 'calc(50% - 10px)' }}>
      <CircularProgress
        {...props}
        variant="determinate"
        sx={{
          color: theme.palette.grey[!isDark ? 200 : 800],
        }}
        size={40}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: !isDark ? '#1a90ff' : '#308fe8',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
const Loading = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const full: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: isDark ? 'rgba(0, 0, 0, .3)' : 'rgba(255, 255, 255, .3)',
    pointerEvents: 'none',
    userSelect: 'none',
    zIndex: 10,
  };
  return (
    <Box sx={full}>
      <NewCircularProgress />
    </Box>
  );
};
export { Loading };
