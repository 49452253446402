import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import underDevelopmentImg from '../../../images/under_construction.svg';
import underDevelopmentImgDark from '../../../images/under_construction_dark.svg';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      height: 'calc(100vh - 198px)',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      maxHeight: '320px',
      padding: 4,
      backgroundImage: `url("${darkMode ? underDevelopmentImgDark : underDevelopmentImg}")`,
      backgroundSize: '320px',
      backgroundPositionX: '50%',
      backgroundPositionY: '50%',
      backgroundRepeat: 'no-repeat',
    },
    iconWarning: {
      padding: 0,
      color: darkMode ? yellow[500] : yellow[900],
      maxWidth: '480px',
      fontSize: 64,
      textAlign: 'center',
      margin: 0,
    },
    text: {
      padding: 4,
      fontWeight: 'bold',
      color: darkMode ? grey[50] : grey[900],
      maxWidth: '480px',
      textAlign: 'center',
    },
  });
});
