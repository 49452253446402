import React, { useState } from 'react';
import { Tooltip, ResponsiveContainer, Legend, Pie, PieChart, Cell } from 'recharts';
import { useTheme } from '@mui/material/styles';

import { Box, Grid, useMediaQuery } from '@mui/material';

// import { ResponsiveLine } from '@nivo/line';

// import { Box } from '@mui/material';
import { blue, green, grey, orange, purple, red, teal, yellow } from '@mui/material/colors';
import styles from './styles';

export interface ProductionNoteProps {}

type TDetailsData = {
  capacity: string;
  hoursAvailable: string;
  hoursNote: string;
  productionNewItems: string;
  poReprocess: string;
  poDirection: string;
  poInternalService: string;
  machineStoppedHours: string;
};

export default function ProductionNote(props: ProductionNoteProps) {
  const classes = styles();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const pieBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const [details, setDetails] = useState<TDetailsData>({} as TDetailsData);

  const data = [
    { name: 'Produção produtos novos', value: 71.4, color: darkMode ? blue[400] : blue[600] },
    { name: "OP's Reprocesso ", value: 7.1, color: darkMode ? teal[200] : teal[400] },
    { name: "OP's Diretoria ", value: 3.6, color: darkMode ? yellow[400] : yellow[700] },
    { name: "OP's Servirços Internos ", value: 5.7, color: darkMode ? orange[400] : orange[700] },
    { name: "OP's Produtos Revitalizados ", value: 4.3, color: darkMode ? green[400] : green[700] },
    { name: 'Desvio / Absenteismo', value: 2.9, color: darkMode ? red[300] : red[500] },
    { name: 'Horas Maquinas Paradas', value: 3.8, color: darkMode ? purple[300] : purple[600] },
  ];

  function CustomizedLabel(_props: any) {
    const RADIAN = Math.PI / 180;

    const { cx, cy, midAngle, outerRadius, fill, percent, value } = _props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 8}
          y={ey + 4}
          textAnchor={textAnchor}
          fill={darkMode ? grey[400] : grey[800]}
          fontSize={10}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  }

  function CustomizedLegend(value: string, entry: any) {
    const { color, payload } = entry;

    return (
      <span style={{ color: darkMode ? grey[400] : grey[800], fontSize: 11, minWidth: '100%' }}>
        {/* <span style={{ color }}>{`(${(payload.percent * 100).toFixed(2)}%) `}</span> */}
        {value}
      </span>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    const data = payload[0]?.payload;
    if (active && data && payload && payload.length) {
      return (
        <div className={classes.tooltipChartContainer}>
          <span className={classes.tooltipChartTitle}>{`${data.name}`}</span>

          <div className={classes.tooltipChartPlaned}>
            <span style={{ color: data.color, textAlign: 'center' }}> {`${data.value}%`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box className={classes.chartBox}>
        <Box className={classes.chartTitle}>
          <span>Apontamentos - Período 00/00/0000 á 00/00/0000</span>
        </Box>
        <Box className={classes.chartContent}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Box className={classes.detailsGrid}>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Capacidade</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Horas disponíveis</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Horas apontadas</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Produção produtos novos</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>OP&apos;s Reprocesso</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>OP&apos;s Diretoria</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>OP&apos;s Serviços Internos</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>OP&apos;s Produtos Revitalizados</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Desvio / Absenteísmo</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
                <Box className={classes.detailsContainer}>
                  <span style={{ width: '50%' }}>Horas Maquinas Paradas</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0</span>
                  <span style={{ width: '25%', textAlign: 'right' }}>0%</span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
              <ResponsiveContainer minHeight={360}>
                <PieChart margin={{ left: 22, right: 22, top: 48, bottom: 16 }}>
                  <Pie
                    dataKey="value"
                    data={data}
                    fill="#82ca9d"
                    label={CustomizedLabel}
                    cx="50%"
                    cy="42%"
                    startAngle={360}
                    endAngle={0}
                  >
                    {data.map((entry, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Legend formatter={CustomizedLegend} layout="horizontal" verticalAlign="bottom" align="left" />
                  <Tooltip content={CustomTooltip} />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
