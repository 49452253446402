import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    liquidContainer: {
      margin: '0 auto',
    },
    liquidLoading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      height: '300px',
      width: '300px',
      borderRadius: '50%',
      borderStyle: 'solid',
      borderColor: darkMode ? '#636363' : '#757575',
      borderWidth: '15px',

      '& > div': {
        color: darkMode ? '#636363' : '#757575',
      },
    },
  });
});
