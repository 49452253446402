import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, green, grey, orange, purple, red } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      padding: 12,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      height: 'calc(100vh - 260px)',
      maxHeight: 'calc(100vh - 260px)',
      overflowY: 'auto',
      padding: 8,
      backgroundColor: darkMode ? grey[800] : grey[300],
      borderRadius: 6,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 16,
    },
    titleIcon: {
      fontSize: 32,

      marginRight: 16,
    },
    colorPurple: {
      color: darkMode ? purple[200] : purple[600],
    },
    colorBlue: {
      color: darkMode ? blue[300] : blue[600],
    },
    colorGreen: {
      color: darkMode ? green[300] : green[600],
    },
    colorOrange: {
      color: darkMode ? orange[300] : orange[600],
    },
    titleText: {
      fontSize: 24,
    },
  });
});
