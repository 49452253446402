import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    actionsRoot: {
      display: 'flex',
      alignItems: 'baseline',
      padding: 12,
      gap: 12,
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: 1080,
    },
    boxContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 8,
      paddingTop: 14,
      width: '100%',
      maxWidth: 1080,
    },
    textBox: {
      borderStyle: 'solid',
      borderColor: '#bcbcbc',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#fafafa',
      color: '#060606',
      height: '100%',
      flex: 1,
      overflowY: 'auto',
      width: '100%',
      padding: 8,
      '&:hover': {
        borderColor: darkMode ? '#bdbdbd' : '#4c4c4c',
      },
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    titleDescription: {
      fontSize: 12,
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
      marginTop: 12,
      marginBottom: 6,
    },
    paperTitle: {
      fontSize: 18,
      textTransform: 'uppercase',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
    },
    titleRating: {
      fontSize: 11.5,
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
      marginLeft: 5,
    },

    attachmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 6,
      paddingTop: 0,
    },
    linksContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 6,
      paddingTop: 0,
    },
    attachmentItemContainer: {
      backgroundColor: darkMode ? grey[900] : grey[300],
      margin: 8,

      width: 86,
      height: 106,
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 6,
      '& button': {
        height: 26,

        minWidth: 42,
        padding: 0,
      },

      '& :last-child': {},
    },
    attachmentItemName: {
      fontSize: 11,
      color: darkMode ? grey[400] : grey[900],
      marginLeft: 4,
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'none',
    },
    attachmentItemTypeContainer: {
      color: darkMode ? grey[800] : grey[900],

      backgroundColor: darkMode ? grey[400] : grey[900],
      margin: 4,
      borderRadius: 6,
      padding: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    attachmentItemTypeName: {
      fontSize: 11,
      maxWidth: 24,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    attachmentItemTypeImg: {
      maxWidth: '100%',
      maxHeight: '48px!important',
      borderRadius: 6,
      padding: 4,
    },
    attachmentItemSize: {
      fontSize: 9,
      color: darkMode ? grey[500] : grey[900],
      margin: 4,
    },
    attachmentCountText: {
      fontSize: 12,
      color: darkMode ? grey[500] : grey[900],
      margin: 4,
      marginLeft: 16,
    },
    newCommentRoot: {
      margin: 8,
      marginLeft: 12,
      marginRight: 2,
      padding: 6,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    newCommentInput: {
      backgroundColor: darkMode ? '#585858' : '#ffffff',
      marginRight: 8,
    },
    newCommentSendButton: {},
    newCommentSendButtonDisabled: {
      margin: 12,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 4,
      width: '100%',
      maxWidth: 1080,
    },
    accordionTitleIcon: {
      height: 48,
      fontSize: 32,
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
    },
    accordionTitleBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 16,
    },
    noRateText: {
      fontSize: 14,
      textTransform: 'uppercase',
      fontStyle: 'italic',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
      marginLeft: 8,
    },
    accordionTitleText: {
      fontSize: 18,
      textTransform: 'uppercase',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
    },
    accordionTitleSubtext: {
      fontSize: 12,
      color: darkMode ? grey[500] : grey[900],
    },
    postAccordionAttachmentsContainer: {
      margin: 8,
    },
    buttonRoot: {
      marginBottom: 14,
      marginTop: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    onTimeSituationColor: {
      color: darkMode ? '#93fb9b' : '#13a71e',
    },
    offTimeSituationColor: {
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    warningIimeSituationColor: {
      color: darkMode ? yellow[400] : yellow[900],
    },
    extendBoxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    requestExtendBoxContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 16,
    },
    extendBox: {
      padding: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 192,
      borderRadius: 8,
      borderStyle: 'solid',
      borderWidth: 1,
    },

    wattingText: {
      color: darkMode ? '#fff494' : '#716e02',
      borderColor: darkMode ? '#fff494' : '#716e02',
      fontSize: 12,
    },
    extendDeniedText: {
      color: darkMode ? '#ff8c8c' : '#e53935',
      fontSize: 12,
      borderColor: darkMode ? '#ff8c8c' : '#e53935',
    },
    extendAcceptedText: {
      color: darkMode ? '#90fb3f' : '#367704',
      fontSize: 12,
      borderColor: darkMode ? '#90fb3f' : '#367704',
    },
    textContainer: {
      font: '16px Arial',
      height: '100%',
      minHeight: 260,
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bcbcbc',
      width: '100%',
      overflowX: 'scroll',
    },
    helpdeskDataRoot: {
      display: 'flex',
      margin: 8,
    },
    tableLinks: {
      width: '100%',
      overflow: 'scroll',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: darkMode ? '#616161' : '#b3b3b3',
      backgroundColor: darkMode ? '#3c3c3c' : '#ffffff',
    },
    tableLinksHeader: {
      '&> th': {
        backgroundColor: darkMode ? '#2b2b2b' : '#eaeaea',
      },
      '&> th > span': {
        color: darkMode ? '#cccccc' : '#2f2f2f',
        fontWeight: 'bold',
      },
    },
  });
});
