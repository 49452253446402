import React, { useEffect, useMemo, useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import api from '../../../../../services/api';
import { DashboardInfoCard } from '../../../../../components/DashboardInfoCard';

interface IEndendCard {}

function EndendCard() {
  const theme = useTheme();
  const [total, setTotal] = useState({ pending: 0, open: 0 });

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/pending');
      const { data: open } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');
      const newTotal = total;

      newTotal.pending = data.total;
      newTotal.open = open.total;

      setTotal(newTotal);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <DashboardInfoCard
        loading={loading}
        title=""
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total.pending)}
          </Typography>
        }
        subcontent={
          <Typography variant="caption">
            {total.open > 1
              ? `Atendidas (${((total.pending / total.open) * 100).toFixed(0)}%)`
              : `Atendida (${total.open}%)`}
          </Typography>
        }
        icon={<CheckCircleOutlineOutlinedIcon color="success" sx={{ fontSize: 48 }} />}
        color={theme.palette.success.main}
      />
    );
  }, [total.pending, total.open, theme.palette.success.main]);

  return renderCard;
}

export { EndendCard };
