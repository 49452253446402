import React, { useEffect, useMemo, useState } from 'react';
import { Typography, useTheme } from '@mui/material';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import api from '../../../../../services/api';
import { DashboardInfoCard } from '../../../../../components/DashboardInfoCard';

function CanceledCard() {
  const theme = useTheme();
  const [total, setTotal] = useState({ delay: 0, open: 0 });
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/delay');
      const { data: open } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');
      const newTotal = total;
      newTotal.delay = data.total;
      newTotal.open = open.total;

      setTotal(newTotal);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <DashboardInfoCard
        loading={loading}
        title=""
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total.delay)}
          </Typography>
        }
        subcontent={
          <Typography variant="caption">
            {total.open > 1
              ? `Canceladas (${((total.delay / total.open) * 100).toFixed(0)}%)`
              : `Cancelada (${total.open}%)`}
          </Typography>
        }
        icon={<CancelOutlinedIcon color="error" sx={{ fontSize: 48 }} />}
        color={theme.palette.error.main}
      />
    );
  }, [theme.palette.error.main, total.delay, total.open]);
  return renderCard;
}

export { CanceledCard };
