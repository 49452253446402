import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
    },
    contentRoot: {
      width: '100%',
      maxWidth: 1080,
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    profileImage: {
      height: 128,
      width: 128,
      backgroundColor: darkMode ? '#ababab' : '#bababa',
      borderRadius: 8,
      margin: 8,
    },
    aboutContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    aboutTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 16,
    },
    aboutTitleIcon: {
      marginRight: 8,
      fontSize: 28,
      lineHeight: 1,
    },
    aboutTitleText: {
      fontSize: 18,
      lineHeight: 1,
    },
    aboutDataContainer: {
      display: 'flex',
      padding: 16,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: 16,
    },
    profileContainer: {
      display: 'flex',
      flex: 1,
    },
    profileDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flex: 1,
      height: '100%',
      padding: 8,
    },
    profileName: {
      fontSize: 26,
      color: darkMode ? grey[200] : grey[800],
    },
    profileSector: {
      fontSize: 18,
      color: darkMode ? grey[400] : grey[600],
    },
    profilePosition: {
      fontSize: 12,
      lineHeight: 0.7,
      color: darkMode ? grey[400] : grey[600],
    },
    profileLoggedAtContainer: {
      display: 'flex',

      alignItems: 'flex-end',
      justifyContent: 'flex-end',

      height: 52,
      width: '100%',
    },
    profileLoggedAtText: {
      fontSize: 10,
      width: '100%',
      color: darkMode ? grey[400] : grey[600],

      flex: 1,
    },
    aboutEditContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    aboutDataValue: {
      color: darkMode ? grey[400] : grey[600],
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
    },
    activityNoneText: {
      fontSize: 16,
      color: darkMode ? grey[400] : grey[600],
    },
    aboutActionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 16,
    },
  });
});
