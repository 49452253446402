import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridSlots,
} from '@mui/x-data-grid';
import generateUniqueId from 'generate-unique-id';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DateField } from '@mui/x-date-pickers';
import InputSearchAsync, { ValueProps } from '../../../../../components/inputs/InputSearchAsync';
import { toNumber } from '../../../../../utils/functions';
import api from '../../../../../services/api';
import InputSearchDate from '../../../../../components/inputs/InputSearchDate';

type RowType = {
  row_id: string;
  product: ValueProps;
  derivation: ValueProps;
  amount: number;
  stock: number;
  um: string;
  cc: ValueProps;
  expected_date: string;
  obs: string;
  isNew: boolean;
};

const emptyRow: RowType = {
  amount: 0,
  stock: 0,
  cc: null,
  expected_date: '',
  obs: '',
  product: null,
  derivation: null,
  row_id: generateUniqueId(),
  um: '',
  isNew: true,
};

let initialRows: RowType[] = [JSON.parse(window.localStorage.getItem('erp/stock/request/rows')) || emptyRow];

interface IRow {
  row: RowType;
  onRowChange: (row: RowType) => void;
  onRowDelete: (row: RowType) => void;
  disableDeleteButton?: boolean;
}

function Row(props: IRow) {
  const { row, onRowChange, onRowDelete, disableDeleteButton } = props;
  const { product, derivation, amount, stock, row_id, cc, expected_date, obs, um } = row;
  // const theme = useTheme();

  useEffect(() => {
    if (derivation !== null)
      api
        .get<{ stock_amount: number }>('erp/stock/amount', {
          params: {
            product: product?.id,
            derivation: derivation?.id,
          },
        })
        .then(response => {
          onRowChange({ ...row, stock: response?.data?.stock_amount || 0 });
        });
  }, [derivation]);

  const inputProduct = useMemo(() => {
    return (
      <InputSearchAsync
        source="erp-products"
        id={`input-product-${row_id}`}
        value={product}
        onChange={(e: ValueProps) => {
          onRowChange({ ...row, product: e, derivation: null, um: e?.um });
        }}
        label="Produto"
        multiple={false}
        autoFocus
        startAdornment={<InputAdornment position="start" />}
        fullWidth
      />
    );
  }, [row]);

  const inputProductDescription = useMemo(
    () => (
      <TextField
        id={`input-product-description-${row_id}`}
        label="Descrição"
        variant="outlined"
        value={product?.subdescription ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        disabled={product === null}
        fullWidth
      />
    ),
    [row],
  );

  const inputDerivation = useMemo(
    () => (
      <InputSearchAsync
        id={`input-derivations-${row_id}`}
        source="erp-derivations"
        value={derivation}
        searchParams={{ product_id: product?.id }}
        onChange={(e: ValueProps) => {
          onRowChange({ ...row, derivation: e });
        }}
        label="Derivação"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        disabled={product === null}
        fullWidth
      />
    ),
    [row],
  );

  const inputAmount = useMemo(
    () => (
      <TextField
        id={`input-amount-${row_id}`}
        label="Qtde."
        variant="outlined"
        type="number"
        value={amount}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment: <InputAdornment position="end">{um}</InputAdornment>,
          inputProps: { style: { textAlign: 'right' } },
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onRowChange({ ...row, amount: toNumber(event.target.value) });
        }}
        size="small"
        disabled={derivation === null}
        fullWidth
      />
    ),
    [row],
  );

  const inputStockAmount = useMemo(
    () => (
      <TextField
        id={`input-stock-${row_id}`}
        label="Qtd. Estoque"
        variant="outlined"
        type="number"
        value={stock}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment: <InputAdornment position="end">{um}</InputAdornment>,
          inputProps: { style: { textAlign: 'right' } },
          readOnly: true,
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onRowChange({ ...row, stock: toNumber(event.target.value) });
        }}
        size="small"
        disabled={derivation === null}
        fullWidth
      />
    ),
    [row],
  );

  const inputCCU = useMemo(
    () => (
      <InputSearchAsync
        id={`input-ccu-${row_id}`}
        source="erp-ccu"
        value={cc}
        searchParams={{}}
        onChange={(e: ValueProps) => {
          onRowChange({ ...row, cc: e });
        }}
        label="Centro de Custo"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        disabled={derivation === null}
        fullWidth
      />
    ),
    [row],
  );

  const inputObs = useMemo(() => {
    console.log(`input-obs-${row_id}`);
    return (
      <TextField
        id={`input-obs-${row_id}`}
        label="Obs."
        variant="outlined"
        value={obs}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        onChange={e => {
          onRowChange({ ...row, obs: e.target.value });
        }}
        size="small"
        disabled={derivation === null}
        fullWidth
      />
    );
  }, [obs, derivation]);

  const inputExpectedDate = useMemo(
    () => (
      <InputSearchDate
        id={`input-expected-date-${row_id}`}
        label="Data Prevista"
        value={expected_date}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        onChange={e => {
          onRowChange({ ...row, expected_date: e.target.value });
        }}
        size="small"
        disabled={derivation === null}
        fullWidth
      />
    ),
    [row],
  );
  return (
    <Paper
      sx={{
        display: 'flex',
        gap: 1,
        p: 1.5,
      }}
    >
      <Grid2 container spacing={2} sx={{ flex: 1 }}>
        <Grid2 xs={12} sm={12} md={5} lg={4} xl={2}>
          {inputProduct}
        </Grid2>
        <Grid2 xs={12} sm={12} md={7} lg={8} xl={4}>
          {inputProductDescription}
        </Grid2>
        <Grid2 xs={12} sm={4} md={4} lg={2} xl={1.5}>
          {inputDerivation}
        </Grid2>
        <Grid2 xs={6} sm={4} md={4} lg={2.5} xl={1.5}>
          {inputAmount}
        </Grid2>
        <Grid2 xs={6} sm={4} md={4} lg={2.5} xl={1.5}>
          {inputStockAmount}
        </Grid2>
        <Grid2 xs={5} sm={6} md={6} lg={2.3} xl={1.5}>
          {inputCCU}
        </Grid2>
        <Grid2 xs={7} sm={6} md={6} lg={2.7} xl={2}>
          {inputExpectedDate}
        </Grid2>
        <Grid2 xs={12} sm={12} md={12} lg={12} xl={10}>
          {inputObs}
        </Grid2>
      </Grid2>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Tooltip title="Remover linha">
          <span>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                onRowDelete(row);
              }}
              disabled={disableDeleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Paper>
  );
}

export default function RequestsAdd() {
  const [rows, setRows] = useState<RowType[]>(initialRows);

  const rowContainer = useRef<HTMLElement>(null);
  const rowScrollTarget = useRef<HTMLElement>(null);
  const theme = useTheme();

  const onRowDelete = (currentRow: RowType) => {
    if (rows.length <= 1) {
      initialRows = [emptyRow];
      window.localStorage.setItem('erp/stock/request/rows', JSON.stringify([emptyRow]));
      setRows([emptyRow]);
    } else {
      setRows(oldRows => oldRows.filter(row => row.row_id !== currentRow.row_id));
    }
  };

  const onRowChange = (newRow: RowType) => {
    setRows(oldRows => oldRows.map(row => (row.row_id === newRow.row_id ? { ...newRow, isNew: false } : row)));
  };

  const handleNewRowClick = () => {
    if (rows.filter(row => row.product === null).length > 0) {
      return;
    }
    setRows(oldRows => [...oldRows, { ...emptyRow, row_id: generateUniqueId(), isNew: true }]);
  };

  useEffect(() => {
    if (rows.length <= 1) {
      initialRows = [emptyRow];
      window.localStorage.setItem('erp/stock/request/rows', JSON.stringify([emptyRow]));
    } else {
      window.localStorage.setItem('erp/stock/request/rows', JSON.stringify(rows));
      if (rows.filter(row => row.isNew === true).length > 0)
        rowScrollTarget?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [rows]);

  return (
    <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
      <Paper sx={{ display: 'flex', gap: 2, width: '100%', p: 1 }}>
        <Tooltip title="Gerar Requesição" aria-label="new-request">
          <Button color="primary" variant="contained" onClick={handleNewRowClick}>
            INSERIR LINHA
          </Button>
        </Tooltip>
        <Box sx={{ display: 'flex', flex: 1 }} />
        <Tooltip title="Pesquisar Requesições" aria-label="search-request">
          <Button color="primary" variant="contained" component={Link} to="/erp/stock/request/search">
            GERAR
          </Button>
        </Tooltip>
      </Paper>

      <Box
        ref={rowContainer}
        sx={{
          display: 'flex',
          gap: 1,
          width: '100%',
          flexDirection: 'column',
          height: 'calc(100vh - 328px)',
          overflowY: 'scroll',
          backgroundColor: theme.palette.background.default,
          p: 1,
          borderRadius: 2,
        }}
      >
        {rows?.map(row => {
          if (row.row_id === undefined)
            return (
              <Row
                key={emptyRow.row_id}
                row={emptyRow}
                onRowChange={onRowChange}
                onRowDelete={onRowDelete}
                disableDeleteButton={rows.length === 1}
              />
            );
          return (
            <Row
              key={row.row_id}
              row={row}
              onRowChange={onRowChange}
              onRowDelete={onRowDelete}
              disableDeleteButton={rows.length === 1}
            />
          );
        })}

        <Box ref={rowScrollTarget} sx={{ height: 0, p: 0, m: 0, width: '100%', border: 'none', borderWidth: 0 }} />
      </Box>
    </Box>
  );
}
