import { makeStyles, createStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

import { blueGrey, green, grey, orange } from '@mui/material/colors';
import { tooltipClasses, useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    '@global': {
      body: {
        background: darkMode ? '#313131' : grey[200],
      },
      [`.${tooltipClasses.tooltip}`]: {
        color: darkMode ? grey[100] : grey[900],
        background: darkMode ? grey[700] : grey[100],
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgb(0 0 0 / 20%)',
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
      },
      '.MuiBreadcrumbs-separator': {
        color: grey[700],
      },
      'input[type="date"]::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
        borderRadius: '4px',
        marginRight: '2px',
        opacity: '0.8',
        filter: darkMode ? 'invert(1)' : 'invert(0)',
      },
      'input[type="time"]::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
        borderRadius: '4px',
        marginRight: '2px',
        opacity: '0.8',
        filter: darkMode ? 'invert(1)' : 'invert(0)',
      },
      '.MuiFormHelperText-root.Mui-error': {
        color: darkMode ? '#ff9d9d' : '#ff5d5d',
      },
      '.MuiFormLabel-root.Mui-error': {
        color: darkMode ? '#bdbdbd' : '#757575',
      },
    },
    grow: {
      flexGrow: 1,
    },
    appbarRoot: {
      backgroundColor: darkMode ? blueGrey[800] : blueGrey[900],
    },
    topbar: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    titleText: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      height: '20px',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    avatar: {
      color: theme.palette.getContrastText(darkMode ? orange[800] : orange[900]),
      backgroundColor: darkMode ? orange[800] : orange[900],
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    submenuBadge: {},

    messages: {
      marginRight: 1,
      '@global': {
        '.MuiBadge-badge': {
          height: '15px',
          fontSize: '11px',
        },
      },
    },

    notifications: {
      marginRight: theme.spacing(2),
      '@global': {
        '.MuiBadge-badge': {
          height: '15px',
          fontSize: '11px',
        },
      },
    },
    topbarSpace: {
      paddingTop: theme.spacing(7),
    },
    breadcrumbs: {
      width: '100%',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    breadcrumbLink: {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
      transition: 'color 0.2s',
      fontWeight: 'bold',

      '&:hover': {
        color: grey[600],
      },
    },
    breadcrumbNoLink: {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    breadcrumbCurrent: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      fontWeight: 'bolder',
    },

    scrollTopButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    scrollTopButtonColor: {
      color: darkMode ? grey[50] : grey[100],
      backgroundColor: darkMode ? green[700] : green[800],
      '&:hover': {
        backgroundColor: darkMode ? green[800] : green[900],
      },
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        color: grey[500],

        backgroundColor: green[900],
        '&:hover': {
          backgroundColor: green[900],
        },
      },
    },
    userAvatarImage: {
      color: theme.palette.getContrastText(darkMode ? orange[800] : orange[900]),
      backgroundColor: darkMode ? orange[800] : orange[900],
      margin: theme.spacing(0.5),
      width: 128,
      height: 128,
      fontSize: 36,
    },
    userNameText: {
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: 1,
      fontSize: 16,
    },
    userEmployeePositionText: {
      color: darkMode ? grey[400] : grey[600],
      fontSize: 11,
    },
    userSectorText: {
      color: darkMode ? grey[400] : grey[600],
      fontSize: 14,
    },
    contentRoot: {
      width: '100%',
      height: 'calc(100vh - 56px)',
      display: 'flex',
    },
    content: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
      flex: '1 1 auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
      },
    },
    footer: {
      bottom: 0,
      marginLeft: 8,
      padding: 8,
      fontSize: 9,
      userSelect: 'none',
      textDecoration: 'none',
      color: darkMode ? '#8c8c8c' : '#4c4c4c',
      '& a, span': {
        fontSize: 9,
        textDecoration: 'none',
        color: darkMode ? '#8c8c8c' : '#4c4c4c',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      flex: '0 1 28px',
    },

    footerCredits: {
      fontSize: 9,
      fontStyle: 'italic',
    },
  });
});
