import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Button,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  List,
  CircularProgress,
  Snackbar,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import moment from 'moment';

import { Alert } from '@mui/lab';
import Axios, { Canceler } from 'axios';
import { UserProps } from '../dashboard';
import api from '../../../../services/api';
import styles from './styles';

// moment(result.datent).format('DD/MM/YYYY')

export interface RuleProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}

export default function AdminUsersAddEdit() {
  const classes = styles();
  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 - 00:00' });

  const { id } = useParams() as any;
  const userSituation = ['Indefinido', 'Trabalhando', '2', '3', '4', '5', '6', '7', '8', '9'];

  const [userData, setUserData] = useState<UserProps>();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [waitingInsert, setWaitingInsert] = useState<boolean>(false);

  const [inputIdValue, setInputIdValue] = useState('');
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputEpValue, setInputEpValue] = useState('');
  const [inputEmailValue, setInputEmailValue] = useState('');
  const [inputEmail2Value, setInputEmail2Value] = useState('');
  const [inputPasswordValue, setInputPasswordValue] = useState('');
  const [inputLoginValue, setInputLoginValue] = useState('');
  const [inputCreatedValue, setInputCreatedValue] = useState('');
  const [inputUpdatedValue, setInputUpdatedValue] = useState('');
  const [inputLoggedValue, setInputLoggedValue] = useState('');
  const [inputErpIDValue, setInputErpIDValue] = useState('');
  const [inputSituationValue, setInputSituationValue] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const [rulesToggleAllChecked, setRulesToggleAllChecked] = useState<boolean>(false);

  const [rulesChecked, setRulesChecked] = React.useState([] as { id: number; value: number }[]);

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleInputEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmailValue(event.target.value);
  };
  const handleInputEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail2Value(event.target.value);
  };
  const handleInputPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPasswordValue(event.target.value);
  };

  const handleInputErpIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputErpIDValue(event.target.value);
  };
  const handleInputSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSituationValue(event.target.checked ? 1 : 0);
  };
  React.useEffect(() => {
    let active = true;
    if (active) {
      loadUserData(id);
    }
    return () => {
      active = false;
    };
  }, []);

  const loadUserData = (id: number) => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      api
        .get(`intranet/users/${id}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const data = response.data as UserProps;
          setUserData(data);
          let checkedAll = true;
          const newChecked = [...rulesChecked];

          for (let i = 0; i < data.rules?.length; i++) {
            const { id } = data.rules[i];
            const value = parseInt(data.rules[i].allow);
            if (value === 0) checkedAll = false;
            newChecked[i] = { id, value };
          }

          setInputIdValue(data.id);
          setInputNameValue(data.name);
          setInputEpValue(data.employee_position);
          setInputLoginValue(data.login);
          setInputCreatedValue(data.created_at);
          setInputUpdatedValue(data.updated_at);
          setInputLoggedValue(data.logged_at);
          setInputErpIDValue(data.erp_id);
          setInputSituationValue(data.situation);

          setInputEmailValue(data.email);
          setInputEmail2Value(data.email2);

          setRulesChecked(newChecked);
          setRulesToggleAllChecked(checkedAll);
          setLoadingData(false);
        })
        .catch(error => {
          setLoadingData(false);
        });
    }
    return () => cancel();
  };
  const handleToggleAll = () => () => {
    const newChecked = [...rulesChecked];

    for (let i = 0; i < (userData?.rules?.length || 0); i++) {
      const id = userData?.rules[i].id || 0;
      newChecked[i] = { id, value: rulesToggleAllChecked ? 0 : 1 };
    }

    setRulesChecked(newChecked);
    setRulesToggleAllChecked(!rulesToggleAllChecked);
  };

  const handleToggle = (index: number, id: number) => () => {
    const newChecked = [...rulesChecked];

    newChecked[index] = newChecked[index] ? { id, value: newChecked[index].value === 1 ? 0 : 1 } : { id, value: 1 };
    if (newChecked[index].value === 0) {
      setRulesToggleAllChecked(false);
    }

    setRulesChecked(newChecked);
  };
  const handleCancelUser = () => {
    loadUserData(id);
  };
  const handleUpdateUser = () => {
    if (inputEmailValue?.toString().trim()?.length > 0 && !validateEmail(inputEmailValue)) {
      setSnackbarType('error');
      setSnackbarMsg('Email da Empresa informado é inválido, verifique');
      setOpenSnackbar(true);
      return;
    }
    if (inputEmail2Value?.toString().trim()?.length > 0 && !validateEmail(inputEmail2Value?.toString().trim())) {
      setSnackbarType('error');
      setSnackbarMsg('Email particular informado é inválido, verifique');
      setOpenSnackbar(true);
      return;
    }
    const rules = [...rulesChecked].filter(function (el) {
      return el != null;
    });

    if (!waitingInsert) {
      setWaitingInsert(true);

      api
        .post(`intranet/users/${id}`, {
          id,
          email: inputEmailValue || '',
          email2: inputEmail2Value || '',
          erp_id: inputErpIDValue || '',
          password: inputPasswordValue || '',
          rules,
          situation: inputSituationValue,
        })
        .then(response => {
          const data = response.data as UserProps;
          setUserData(data);
          setSnackbarType('success');
          setSnackbarMsg('Usuário atualizando com sucesso!');
          setOpenSnackbar(true);
          setWaitingInsert(false);
        })
        .catch(error => {
          const { data } = error.response;
          if (data) {
            const errorMessage = data.message;
            if (errorMessage === 'empty_email') {
              setSnackbarType('error');
              setSnackbarMsg('É Obrigatório informar um email, verifique!');
              setOpenSnackbar(true);
            } else if (errorMessage === 'invalid_email') {
              setSnackbarType('error');
              setSnackbarMsg('O email informado não é válido, verifique!');
              setOpenSnackbar(true);
            } else if (errorMessage === 'duplicate_email') {
              setSnackbarType('error');
              setSnackbarMsg('Email já cadastrado, verifique!');
              setOpenSnackbar(true);
            }
          }
          setWaitingInsert(false);
        });
    }
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box p={1.5} display="flex" flexDirection="row">
              <Box>
                <Tooltip title="Novo Usuário" aria-label="novo-usuario">
                  <Button color="primary" variant="contained" component={Link} to="/admin/users/add">
                    NOVO
                  </Button>
                </Tooltip>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 12 }}>
            <Box pb={3}>
              <Typography variant="caption" className={classes.sectionTitle}>
                INFORMAÇÕES DA CONTA
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      label="Situação"
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={inputSituationValue === 1}
                          color="primary"
                          onChange={handleInputSituation}
                          name="input_situation"
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1} lg={1} xl={1}>
                <TextField
                  fullWidth
                  id="input-id"
                  label="ID"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={inputIdValue || ''}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  id="input-login"
                  label="Usuário"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={inputLoginValue || ''}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  id="input-name"
                  label="Nome"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={inputNameValue || ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  id="input-ep"
                  label="Cargo"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={inputEpValue || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  autoComplete="new-erp-id"
                  id="input-erp-id"
                  label="Sapiens"
                  variant="outlined"
                  type="text"
                  size="small"
                  value={inputErpIDValue || ''}
                  onChange={handleInputErpIDChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  id="input-created-at"
                  label="Criado"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={moment.utc(inputCreatedValue).format('DD/MM/YYYY - HH:mm') || ''}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  id="input-updated-at"
                  label="Atualizado"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={moment.utc(inputUpdatedValue).format('DD/MM/YYYY - HH:mm') || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  id="input-logged-at"
                  label="Autenticado"
                  variant="outlined"
                  type="text"
                  size="small"
                  disabled
                  value={moment.utc(inputLoggedValue).format('DD/MM/YYYY - HH:mm') || ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  id="input-password"
                  label="Senha"
                  variant="outlined"
                  type="password"
                  size="small"
                  value={inputPasswordValue || ''}
                  onChange={handleInputPasswordChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  autoComplete="new-email"
                  id="input-email"
                  label="Email Empresa"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={inputEmailValue || ''}
                  onChange={handleInputEmailChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  autoComplete="new-email2"
                  id="input-email2"
                  label="Email Particular"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={inputEmail2Value || ''}
                  onChange={handleInputEmail2Change}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 12 }}>
            <Box pb={3}>
              <Typography variant="caption" className={classes.sectionTitle}>
                PERMISSÕES DO USUÁRIO
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ListItem role={undefined} dense button onClick={handleToggleAll()}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={rulesToggleAllChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'rules-toggle-all' }}
                    />
                  </ListItemIcon>
                  <ListItemText id="rules-toggle-all" primary="Selecionar todas" />
                </ListItem>

                <List className={classes.permissionList} dense={false}>
                  {userData?.rules?.map((rule: RuleProps, index) => {
                    const labelId = `checkbox-list-rules-${index}`;

                    const checked = rulesChecked[index]?.value === 1;

                    return (
                      <>
                        {rule.value !== 'master' && (
                          <ListItem key={index} role={undefined} dense button onClick={handleToggle(index, rule.id)}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={`${rule.type === 1 ? 'Página - ' : ''}${rule.type === 0 ? 'Tipo - ' : ''}${
                                rule.description
                              }`}
                            />
                          </ListItem>
                        )}
                      </>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.actionButtonContainer}>
          <Tooltip title="Cancelar Alterações" aria-label="insert-user">
            <Button disabled={waitingInsert} color="secondary" variant="contained" onClick={handleCancelUser}>
              CANCELAR
            </Button>
          </Tooltip>
          <Tooltip title="Atualizar Usuário" aria-label="insert-user">
            <Button
              style={{ marginLeft: 12 }}
              disabled={waitingInsert}
              color="primary"
              variant="contained"
              onClick={handleUpdateUser}
            >
              {waitingInsert && <CircularProgress size={24} style={{ color: '#fff', marginRight: 12 }} />}
              SALVAR
            </Button>
          </Tooltip>
        </Grid>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert severity={snackbarType} onClose={handleSnackbarClose}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </Box>
  );
}
