import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey, orange } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    sectionTitle: {
      color: darkMode ? grey[500] : grey[700],
      fontWeight: 'bold',
    },
    actionButtonContainer: {
      padding: theme.spacing(2),
    },
    permissionList: {
      maxHeight: 'calc(100vh - 320px)',
      width: '100%',
      backgroundColor: darkMode ? '#333333' : '#e8e8e8',
      position: 'relative',
      overflow: 'auto',
      paddingLeft: 8,
      paddingRight: 8,
    },
  });
});
