import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';

interface IHelpdeskDashboard {}

function HelpdeskDashboard() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Paper sx={{ p: 2 }}>Em desenvolvimento</Paper>
      </Box>
    </Box>
  );
}

export { HelpdeskDashboard };
