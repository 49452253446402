import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EggIcon from '@mui/icons-material/Egg';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import { FaCarrot } from 'react-icons/fa';
import { GiCook } from 'react-icons/gi';

function JobOpportunityCard() {
  const [responseData, setResponseData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  return (
    <Box>
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <PersonSearchIcon />
          <Typography>Vagas em aberto</Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            pt: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {!responseData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 1,
                minHeight: 60,
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              <Typography>Nenhuma vaga disponível</Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            borderTop: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Button size="small" variant="outlined" color="warning" disabled component={Link} to="/hcm/jobs">
            Detalhes
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export { JobOpportunityCard };
