/* TODO:
Colocar Valor do Material, Solicitado pelo Helbert
*/

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Icon,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Autocomplete,
  CircularProgress,
  TextField,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import moment from 'moment';

import { Pagination, Skeleton } from '@mui/lab/';
import Axios, { Canceler } from 'axios';
import { ExpandMore as ExpandMoreIcon, Save as SaveIcon } from '@mui/icons-material';
import api from '../../../../services/api';
import styles from './styles';
import Filters from '../../../../components/Filters';
import InputSearchAsync, { ValueProps } from '../../../../components/inputs/InputSearchAsync';

interface StockDataProps {
  row: string;
  edit_address: boolean;
  codpro: string;
  despro: string;
  cplpro: string;
  unimed: string;
  qtdest_total: number;
  qtdres_total: number;
  qtdblo_total: number;
  qtdord_total: number;
  qtdccl_total: number;
  qtdcfo_total: number;
  qtdccr_total: number;
  qtdrae_total: number;
  qtddep_total: number;
  stock: StockProps[];
}

interface StockProps {
  coddep: string;
  desdep: string;
  codpro: string;
  despro: string;
  codder: string;
  codori: string;
  codfam: string;
  datval: string;
  estrep: number;
  estmin: number;
  estmax: number;
  estmid: number;
  estmad: number;
  qtdest: number;
  qtdord: number;
  qtdres: number;
  qtdblo: number;
  qtdccl: number;
  qtdcfo: number;
  qtdccr: number;
  premed: number;
  codage: string;
  unime2: string;
  unime3: string;
  seqcmd: number;
  indinv: number;
  sitest: string;
  codmot: number;
  obsmot: string;
  ctrsep: string;
  ctrlot: string;
  tippro: string;
  codemp: number;
  codmdp: string;
  codagp: string;
  codagc: string;
  indmis: string;
  qtdrae: number;
  qtdemb: number;
  codagr: number;
  estneg: string;
  endpro: string;
  endder: string;
  endest: string;
  codfil: number;
  proimp: number;
  itefis: string;
  desfis: string;
  indenc: string;
  // production_batch: ProductionBatchType[];
}
/*
type ProductionBatchType = {
  codlot: string;
  qtdest: number;
  qtdblo: number;
  qtdres: number;
  qtdrae: number;
  datfab: string;
  datfab_formated: string;
  qtddis: number;
};
*/
function formatNumber(number: number) {
  let numberFormated = '';
  numberFormated = number
    .toFixed(3)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return numberFormated;
}

export default function ErpStockPage() {
  const classes = styles();

  const [stockData, setStockData] = useState({
    total: '0',
    page: '1',
    pageTotal: '1',
    limit: '10',
    data: [],
  });

  const [loadingData, setLoadingData] = useState(false);
  const [editAddress, setEditAddress] = useState(true);
  const [postingAddress, setPostingAddress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [productValue, setProductValue] = useState<ValueProps[]>([]);
  const [depositValue, setDepositValue] = useState<ValueProps[]>([]);
  const [stockGroupingValue, setStockGroupingValue] = useState<ValueProps[]>([]);

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  useEffect(() => {
    let active = true;
    if (active) {
      setProductValue(JSON.parse(localStorage.getItem('erp-stock-search-product') || '[]'));
      setDepositValue(JSON.parse(localStorage.getItem('erp-stock-search-deposit') || '[]'));

      setStockGroupingValue(JSON.parse(localStorage.getItem('erp-stock-search-grouping') || '[]'));
    }
    return () => {
      active = false;
    };
  }, []);

  const getStockData = (page = 1) => {
    setCurrentPage(page);
    if (productValue?.length === 0 && depositValue?.length === 0 && stockGroupingValue?.length === 0) {
      setStockData({
        total: '0',
        page: '1',
        pageTotal: '1',
        limit: '10',
        data: [],
      });
      return;
    }
    let cancel: Canceler;

    let searchProducts = '';
    for (const product of productValue) {
      searchProducts += `;${product.id}`;
    }
    let searchDeposits = '';
    for (const deposit of depositValue) {
      searchDeposits += `;${deposit.id}`;
    }
    let searchStockGrouping = '';
    for (const stockGrouping of stockGroupingValue) {
      searchStockGrouping += `;${stockGrouping.id}`;
    }

    if (!loadingData) {
      const params = {
        page,
        limit,
        codPro: searchProducts,
        codDep: searchDeposits,
        codAgp: searchStockGrouping,
      };
      setLoadingData(true);

      api
        .get('erp/stock/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          setStockData(response.data);
          setLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > parseInt(stockData.pageTotal, 10)) return;
    getStockData(page);
  };

  const handleApplyFilter = (e?: any) => {
    localStorage.setItem('erp-stock-search-product', JSON.stringify(productValue));
    localStorage.setItem('erp-stock-search-deposit', JSON.stringify(depositValue));
    localStorage.setItem('erp-stock-search-grouping', JSON.stringify(stockGroupingValue));
    setProductValue(productValue);
    setDepositValue(depositValue);
    setStockGroupingValue(stockGroupingValue);

    getStockData();
  };

  const ProductionBatch = (props: any) => {
    interface BatchProps {
      row: number;
      codori: string;
      codfam: string;
      codlot: string;
      codpro: string;
      codder: string;
      coddep: string;
      qtdest: number;
      datvlt: string;
      qtdres: number;
      qtdblo: number;
      qtdrae: number;
      qtdemb: number;
      vlrdm1: number;
      vlrdm2: number;
      vlrdm3: number;
      vlrdm4: number;
      vlrdm5: number;
      vlrdm6: number;
      perger: number;
      perpur: number;
      perumi: number;
      dattes: string;
      codslt: string;
      codend: string;
      codsaf: string;
      codtrm: string;
      codbnf: string;
      codcat: string;
      lotori: string;
      codagc: string;
      codage: string;
      codagp: string;
      datfab: string;
      datfab_formated: string;
      unimed: string;
      codpne: number;
      codetp: number;
      codcul: number;
      numter: string;
      numaog: string;
      numcer: string;
      numbol: string;
      numamo: string;
      obsdls: string;
      indfab: string;
      codfab: string;
      lotfab: string;
      vltfab: string;
      profab: string;
      codmar: string;
      itefis: string;
      desfis: string;
    }
    const { codPro, codDep } = props;

    const [loadingData, setLoadingData] = useState(false);
    const [batchData, setBatchData] = useState<BatchProps[]>([]);
    React.useEffect(() => {
      let active = true;
      if (active) {
        if (batchData?.length === 0) {
          getProductionBatchData();
        }
      }

      return () => {
        active = false;
      };
    }, []);
    const getProductionBatchData = (page = 1) => {
      let cancel: Canceler;
      if (!loadingData) {
        const params = {
          page,
          limit,
          codPro,
          codDep,
        };
        setLoadingData(true);

        api
          .get('erp/production_batch/', {
            params,
            cancelToken: new Axios.CancelToken(c => {
              cancel = c;
            }),
          })
          .then(response => {
            setBatchData(response.data.data);
            setLoadingData(false);
          })
          .catch(error => {
            setLoadingData(false);
          });
      }
      return () => cancel();
    };

    return (
      <Box margin={1} width="100%">
        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.lotAccordionRoot}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1batch-content" id="panel1batch-header">
            <Typography>Lote(s):</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.lotAccordionDetailsRoot}>
            <Box pt={1} width="100%">
              <TableContainer component={Paper}>
                <Table aria-label="lotes">
                  <TableHead className={classes.lotTableHeadRoot}>
                    <TableRow>
                      <TableCell align="right">Lote Fab.</TableCell>
                      <TableCell align="right">Saldo Lote</TableCell>
                      <TableCell align="right">Data Fab.</TableCell>
                      <TableCell align="right">Qtd Bloqueada</TableCell>
                      <TableCell align="right">Qtd Reservada</TableCell>
                      <TableCell align="right">Qtd Res. Analise</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.lotTableBodyRoot}>
                    {loadingData ? (
                      <TableRow>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                      </TableRow>
                    ) : batchData?.length > 0 ? (
                      batchData?.map((result, index) => (
                        <TableRow key={index} hover>
                          <TableCell scope="row" align="right">
                            {result.codlot}
                          </TableCell>
                          <TableCell align="right">{`${formatNumber(result.qtdest)} ${result.unimed}`}</TableCell>
                          <TableCell align="right">{`${result.datfab_formated}`}</TableCell>
                          <TableCell align="right">{`${formatNumber(result.qtdblo)} ${result.unimed}`}</TableCell>
                          <TableCell align="right">{`${formatNumber(result.qtdres)} ${result.unimed}`}</TableCell>
                          <TableCell align="right">{`${formatNumber(result.qtdrae)} ${result.unimed}`}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} scope="row" align="center">
                          Nenhum lote foi encontrado.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const Reservations = (props: any) => {
    const { title, header, rows } = props;
    return (
      <Box>
        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.resAccordionSub}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panelbatch-content" id="panelbatch-header">
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={`${classes.resAccordionDetailsSub}`}>
            <TableContainer style={{ maxHeight: '360px' }}>
              <Table aria-label="res-op" className={classes.resTableHeadRoot} stickyHeader>
                <TableHead className={classes.resTableHeadRoot}>
                  <TableRow>
                    {header?.map((data: { text: string; align: 'left' | 'right' | 'center' }, index: number) => (
                      <TableCell key={index} className={classes.resTableHeadRoot} align={data.align}>
                        {data.text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.resTableBodyRoot}>{rows}</TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const StockReservations = (props: any) => {
    interface ReservationsProps {
      row: number;
      codemp: string;
      codfam: string;
      codpro: string;
      despro: string;
      cplpro: string;
      codder: string;
      coddep: string;
      qtdres: number;
      qtdrae: number;
      qtdest: number;
      sitest: string;
      tippro: string;
      unimed: string;
      codori: string;
      qtdord: number;
      resOP: Array<{
        codori: string;
        numorp: string;
        codetg: string;
        qtdres: number;
        codfil: string;
        numped: string;
        sitorp: string;
        relprd: string;
        sublot: string;
        dtpfim: string;
        numpri: number;
      }>;
      resNecProd: Array<{
        codpvp: string;
        pvppai: string;
        codfil: string;
        numped: string;
        qtdres: number;
      }>;
      resPedVend: Array<{
        codcli: string;
        codfil: string;
        numped: string;
        seqipd: string;
        datent: string;
        qtdabe: number;
        qtdres: number;
        pedprv: string;
      }>;
      resManual: Array<{
        codtns: string;
        datmov: string;
        numdoc: string;
        qtdmov: number;
        seqmov: string;
        esteos: string;
      }>;
      resNF: Array<{
        codcli: string;
        codfil: string;
        codsnf: string;
        numnfv: string;
        seqipv: string;
        tnspro: string;
        codpro: string;
        codder: string;
        coddep: string;
        qtdfat: number;
      }>;
      resReq: Array<{
        codemp: string;
        numeme: string;
        seqeme: string;
        codpro: string;
        codder: string;
        coddep: string;
        codtns: string;
        qtdapr: number;
        qtdcpr: number;
        qtdcan: number;
        dateme: string;
        qtdatd: number;
        qtdeme: number;
        qtdres: number;
      }>;
      resPreFat: Array<{
        codcli: string;
        codfil: string;
        numane: string;
        numpfa: string;
        seqpes: string;
        tnspro: string;
        codpro: string;
        codder: string;
        coddep: string;
        qtdppf: number;
        datprp: string;
      }>;
      resOthes: Array<{
        prcori: string;
        codemp: string;
        docori: string;
        desres: string;
        codpro: string;
        codder: string;
        coddep: string;
        qtdres: number;
        datres: string;
        horres: string;
        usures: string;
        pernam: string;
      }>;
    }
    const { codPro, codDep } = props;

    const [loadingData, setLoadingData] = useState(false);
    const [reservationData, setReservationData] = useState<ReservationsProps[]>([]);
    React.useEffect(() => {
      let active = true;
      if (active) {
        if (reservationData?.length === 0) {
          getReservationData();
        }
      }

      return () => {
        active = false;
      };
    }, []);
    const getReservationData = (page = 1) => {
      let cancel: Canceler;
      if (!loadingData) {
        const params = {
          page,
          limit,
          codPro,
          codDep,
        };
        setLoadingData(true);

        api
          .get('erp/stock/reservations', {
            params,
            cancelToken: new Axios.CancelToken(c => {
              cancel = c;
            }),
          })
          .then(response => {
            setReservationData(response.data.data);
            setLoadingData(false);
          })
          .catch(error => {
            setLoadingData(false);
          });
      }
      return () => cancel();
    };

    return (
      <Box margin={1} width="100%">
        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.resAccordionRoot}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panelbatch-content" id="panelbatch-header">
            <Typography>Reservas:</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.resAccordionDetailsRoot}>
            {loadingData ? (
              <Box width="100%" pt={1}>
                <Skeleton variant="text" height={60} />
                <Skeleton variant="text" height={60} />
                <Skeleton variant="text" height={60} />
              </Box>
            ) : reservationData?.length > 0 ? (
              <Box width="100%" pt={1}>
                {reservationData[0].resOP?.length > 0 && (
                  <Reservations
                    title="Res. P/ O.Ps."
                    header={[
                      { text: 'Origem', align: 'right' },
                      { text: 'O.P.', align: 'right' },
                      { text: 'Data Fim Prev.', align: 'center' },
                      { text: 'Estágio', align: 'right' },
                      { text: 'Qtde Reservada', align: 'right' },
                      { text: 'Filial', align: 'center' },
                      { text: 'Pedido', align: 'right' },
                    ]}
                    rows={reservationData[0].resOP?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="right" scope="row">
                          {result.codori}
                        </TableCell>
                        <TableCell align="right">{result.numorp}</TableCell>
                        <TableCell align="center">{moment(result.dtpfim).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="right">{result.codetg}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdres)}`}</TableCell>
                        <TableCell align="center">{result.codfil}</TableCell>
                        <TableCell align="right">{result.numped}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}

                {reservationData[0].resNecProd?.length > 0 && (
                  <Reservations
                    title="Res. P/ Nec. Prod."
                    header={[
                      { text: 'Período', align: 'center' },
                      { text: 'Pre. Proc.', align: 'center' },
                      { text: 'Filial', align: 'center' },
                      { text: 'Pedido', align: 'center' },
                      { text: 'Qtde Reservada', align: 'center' },
                    ]}
                    rows={reservationData[0].resNecProd?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell scope="row">{result.codpvp}</TableCell>
                        <TableCell>{result.pvppai}</TableCell>
                        <TableCell>{result.codfil}</TableCell>
                        <TableCell>{result.numped}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdres)}`}</TableCell>
                        <TableCell align="right">{result.codfil}</TableCell>
                        <TableCell align="right">{result.numped}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}
                {reservationData[0].resPedVend?.length > 0 && (
                  <Reservations
                    title="Res. P/ Ped. Venda"
                    header={[
                      { text: 'Filial', align: 'center' },
                      { text: 'Pedido', align: 'center' },
                      { text: 'Seq.', align: 'center' },
                      { text: 'Data Entrega', align: 'center' },
                      { text: 'Qtde Pedido', align: 'center' },
                      { text: 'Qtde Reservada', align: 'center' },
                      { text: 'Ped. Prev.', align: 'center' },
                      { text: 'Cliente', align: 'center' },
                    ]}
                    rows={reservationData[0].resPedVend?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell scope="row">{result.codfil}</TableCell>
                        <TableCell>{result.numped}</TableCell>
                        <TableCell>{result.seqipd}</TableCell>
                        <TableCell>{moment(result.datent).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdabe)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdres)}`}</TableCell>
                        <TableCell>{result.pedprv}</TableCell>
                        <TableCell>{result.codcli}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}

                {reservationData[0].resManual?.length > 0 && (
                  <Reservations
                    title="Res. Manual"
                    header={[
                      { text: 'Data Mov.', align: 'center' },
                      { text: 'Seq.', align: 'center' },
                      { text: 'Tipo', align: 'center' },
                      { text: 'Documento', align: 'center' },
                      { text: 'Qtde Reservada', align: 'center' },
                    ]}
                    rows={reservationData[0].resManual?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{moment(result.datmov).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{result.seqmov}</TableCell>
                        <TableCell>{result.esteos}</TableCell>
                        <TableCell>{result.numdoc}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdmov)}`}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}
                {reservationData[0].resNF?.length > 0 && (
                  <Reservations
                    title="Res. NF Saída"
                    header={[
                      { text: 'Filial', align: 'center' },
                      { text: 'Série', align: 'center' },
                      { text: 'Número', align: 'center' },
                      { text: 'Item', align: 'center' },
                      { text: 'Trans. Produto', align: 'center' },
                      { text: 'Produto', align: 'center' },
                      { text: 'Derivação', align: 'center' },
                      { text: 'Cliente', align: 'center' },
                      { text: 'Qtde Faturada', align: 'right' },
                    ]}
                    rows={reservationData[0].resNF?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{result.codfil}</TableCell>
                        <TableCell>{result.codsnf}</TableCell>
                        <TableCell>{result.numnfv}</TableCell>
                        <TableCell>{result.seqipv}</TableCell>
                        <TableCell>{result.tnspro}</TableCell>
                        <TableCell>{result.codpro}</TableCell>
                        <TableCell>{result.codder}</TableCell>
                        <TableCell>{result.codcli}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdfat)}`}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}

                {reservationData[0].resReq?.length > 0 && (
                  <Reservations
                    title="Res. Requisições"
                    header={[
                      { text: 'Requisição', align: 'center' },
                      { text: 'Seq.', align: 'center' },
                      { text: 'Trans.', align: 'center' },
                      { text: 'Produto', align: 'center' },
                      { text: 'Derivação', align: 'center' },
                      { text: 'Qtde Pedida', align: 'center' },
                      { text: 'Qtde Aprovada', align: 'center' },
                      { text: 'Qtde Atendida', align: 'center' },
                      { text: 'Qtde Cancelada', align: 'center' },
                      { text: 'Qtde Comprada', align: 'center' },
                      { text: 'Qtde Reservada', align: 'center' },
                      { text: 'Data Emissão', align: 'center' },
                    ]}
                    rows={reservationData[0].resReq?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{result.numeme}</TableCell>
                        <TableCell>{result.seqeme}</TableCell>
                        <TableCell>{result.codtns}</TableCell>
                        <TableCell>{result.codpro}</TableCell>
                        <TableCell>{result.codder}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdeme)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdapr)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdatd)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdcan)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdcpr)}`}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdres)}`}</TableCell>
                        <TableCell>{moment(result.dateme).format('DD/MM/YYYY')}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}
                {reservationData[0].resPreFat?.length > 0 && (
                  <Reservations
                    title="Res. Pré Faturas"
                    header={[
                      { text: 'Filial', align: 'center' },
                      { text: 'Nr. Análize', align: 'center' },
                      { text: 'Nr. Pré-Fat.', align: 'center' },
                      { text: 'Seq.', align: 'center' },
                      { text: 'Trans. Produto', align: 'center' },
                      { text: 'Produto', align: 'center' },
                      { text: 'Derivação', align: 'center' },
                      { text: 'Qtd. Pré-Fat.', align: 'center' },
                      { text: 'Cliente', align: 'center' },
                      { text: 'Data Prep.', align: 'center' },
                    ]}
                    rows={reservationData[0].resPreFat?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{result.codfil}</TableCell>
                        <TableCell>{result.numane}</TableCell>
                        <TableCell>{result.numpfa}</TableCell>
                        <TableCell>{result.seqpes}</TableCell>
                        <TableCell>{result.tnspro}</TableCell>
                        <TableCell>{result.codpro}</TableCell>
                        <TableCell>{result.codder}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdppf)}`}</TableCell>
                        <TableCell>{result.codcli}</TableCell>
                        <TableCell>{moment(result.datprp).format('DD/MM/YYYY')}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}
                {reservationData[0].resOthes?.length > 0 && (
                  <Reservations
                    title="Res. Pré Faturas"
                    header={[
                      { text: 'Tipo', align: 'center' },
                      { text: 'Número', align: 'center' },
                      { text: 'Descrição', align: 'center' },
                      { text: 'Produto', align: 'center' },
                      { text: 'Derivação', align: 'center' },
                      { text: 'Qtd. Res.', align: 'center' },
                      { text: 'Data Reserva.', align: 'center' },
                      { text: 'Usuário', align: 'center' },
                    ]}
                    rows={reservationData[0].resOthes?.map((result, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{result.prcori}</TableCell>
                        <TableCell>{result.docori}</TableCell>
                        <TableCell>{result.desres}</TableCell>
                        <TableCell>{result.codpro}</TableCell>
                        <TableCell>{result.codder}</TableCell>
                        <TableCell align="right">{`${formatNumber(result.qtdres)}`}</TableCell>
                        <TableCell>{moment(result.datres).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{result.usures}</TableCell>
                      </TableRow>
                    ))}
                  />
                )}
              </Box>
            ) : (
              <Box flex={1} display="flex" justifyContent="center" alignItems="center" pt={1}>
                <Typography>Nenhuma reserva encontrada.</Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const ItemDataRow = (props: { title: string; value: string; xs?: any; sm?: any; md?: any; lg?: any; xl?: any }) => {
    const { title, value, xs = 12, sm = 6, md = 6, lg = 4, xl = 3 } = props;

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.itemDataRowGrid}>
        <Typography variant="caption" className={classes.itemDataRowTitle}>
          {title}
        </Typography>
        <Typography className={classes.itemDataRowValue}>{`${value}\u00a0`}</Typography>
        <Divider />
      </Grid>
    );
  };

  const filtersRender = (
    <Box>
      <Filters
        onFilterApply={e => {
          handleApplyFilter(e);
        }}
      >
        <InputSearchAsync
          fullWidth
          source="erp-products"
          id="erp-stock-search-products"
          value={productValue}
          noOptionsText="Nenhum produto encontrado."
          placeholder="Pesquisar Produtos"
          label="Produtos (+)"
          onChange={(e: ValueProps[]) => {
            setProductValue(e);
          }}
        />
        <InputSearchAsync
          fullWidth
          source="erp-deposits"
          id="erp-stock-search-deposits"
          value={depositValue}
          noOptionsText="Nenhum depósito encontrado."
          placeholder="Pesquisar Depósitos"
          label="Depósitos (+)"
          onChange={(e: ValueProps[]) => {
            setDepositValue(e);
          }}
        />

        <InputSearchAsync
          fullWidth
          source="erp-products-grouping"
          id="erp-stock-search-grouping"
          value={stockGroupingValue}
          noOptionsText="Nenhum agrupamento encontrado."
          placeholder="Pesquisar Agrupamentos"
          label="Agrupamentos (+)"
          onChange={(e: ValueProps[]) => {
            setStockGroupingValue(e);
          }}
        />
      </Filters>
    </Box>
  );

  const LoadingRender = () => {
    const rowsSkeleton = [];
    for (let i = 0; i < limit; i++) {
      rowsSkeleton.push(i);
    }
    return (
      <Box>
        {rowsSkeleton?.map(index => (
          <div key={index}>
            <div
              style={{
                height: '65px',
                width: '100%',
                paddingTop: '8px',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-start',
                alignItems: 'left',
              }}
            >
              <div
                style={{
                  paddingTop: '8px',
                  paddingLeft: '28px',
                }}
              >
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div
                style={{
                  paddingLeft: '26px',
                  paddingRight: '42px',
                  width: '100%',
                }}
              >
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" width={90} height={20} />
              </div>
            </div>
            <Divider />
          </div>
        ))}
        <div className={classes.actions}>
          <Pagination
            disabled
            count={parseInt(stockData.pageTotal, 10)}
            page={parseInt(stockData.page, 10)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </Box>
    );
  };

  const emptyResultRender = (
    <Box mt={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Paper className={classes.emptyPaper}>
        <Typography
          variant="subtitle1"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Não foi encontrado nenhum Resultado. <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente novamente.
        </Typography>
      </Paper>
      <Box>
        <Pagination disabled count={0} page={0} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );

  function AddressInput(props: { product: string; derivation: string; deposit: string; address: string }) {
    let cancel: Canceler;

    const [openAddressSearch, setOpenAddressSearch] = useState(false);
    const [optionsAddressSearch, setOptionsAddressSearch] = useState<ValueProps[]>([]);
    const loadingAddressSearch = openAddressSearch && optionsAddressSearch?.length === 0;
    const [inputAddressText, setInputAddressText] = useState('');
    const [inputAddressValue, setInputAddressValue] = useState<ValueProps | null>({
      id: props?.address,
      description: props?.address,
      subdescription: '',
    });

    const handleInputAddressChange = (value: string) => {
      setInputAddressText(value);
    };

    useEffect(() => {
      if (!openAddressSearch) {
        setOptionsAddressSearch([]);
      }
    }, [openAddressSearch]);

    useEffect(() => {
      let active = true;

      if (!loadingAddressSearch) {
        return undefined;
      }

      try {
        (async () => {
          const params = {
            limit: 50,
            source: 'erp-stock-address',
            search: inputAddressText,
          };

          const response = await api.get('search/input', {
            params,
            cancelToken: new Axios.CancelToken(c => {
              cancel = c;
            }),
          });

          const { data } = response;

          if (active) {
            setOptionsAddressSearch(data);
          }
        })();
      } catch (error) {
        console.error(error);
      }

      return () => {
        active = false;
      };
    }, [loadingAddressSearch]);

    async function HandleUpdateAddress(product: string, derivation: string, deposit: string, address: string) {
      let cancel: Canceler;

      setPostingAddress(true);
      try {
        const response = await api.post('erp/stock/address', {
          product,
          derivation,
          deposit,
          address,

          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setSnackbarType('success');
        setSnackbarMsg('Endereçamento alterado com sucesso');
        setOpenSnackbar(true);
        setPostingAddress(false);
      } catch (error) {
        console.error(error);
        const { data } = error.response;
        setSnackbarType('error');
        setSnackbarMsg(data);
        setOpenSnackbar(true);

        setPostingAddress(false);
      }
      return () => cancel();
    }

    return (
      <Box display="flex" justifyContent="center">
        <Autocomplete
          open={openAddressSearch}
          value={inputAddressValue}
          onChange={(event: any, newValue: ValueProps | null) => {
            setInputAddressValue(newValue);
          }}
          inputValue={inputAddressText}
          onInputChange={(event: any, newInputValue: any) => {
            handleInputAddressChange(newInputValue);
          }}
          onOpen={() => {
            setOpenAddressSearch(true);
          }}
          onClose={(e: any) => {
            setOpenAddressSearch(false);
          }}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          getOptionLabel={(option: any) => option.description}
          options={optionsAddressSearch}
          loading={loadingAddressSearch}
          fullWidth
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Endereçamento"
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingAddressSearch ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Tooltip title="Salvar">
          <IconButton
            style={{ marginLeft: 8 }}
            onClick={async () => {
              await HandleUpdateAddress(props.product, props.derivation, props.deposit, inputAddressValue?.id || '');
              handleApplyFilter();
            }}
            disabled={(inputAddressValue?.id || '') === props?.address}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  const resultRender = (
    <Box mt={2}>
      <Box>
        {stockData.data?.map((product: StockDataProps, index) => (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={`${index}-${product.codpro}`}
            className={`${classes.row} ${classes.resultRow}`}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
              <div className={classes.rowTitle}>
                <Typography className={classes.rowTitleMain}>
                  {`${product.codpro} - ${product.despro}`.toUpperCase()}
                </Typography>

                <Typography variant="caption" className={classes.rowSubtitle}>
                  {`Estoque de ${formatNumber(product.qtdest_total)} ${product.unimed}  em ${product.stock?.length} ${
                    product.stock?.length > 1 ? 'Depósitos' : 'Depósito'
                  }`}
                </Typography>
              </div>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.resultDetails}>
              <Box width="100%" mt={1}>
                {product.stock?.map((stock: StockProps, index) => {
                  return (
                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      key={index}
                      className={`${classes.row} ${classes.subResultRow}`}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                        <div className={classes.rowTitle}>
                          <Typography className={classes.rowTitleMain}>
                            {`${stock.coddep} - ${stock.desdep}`.toUpperCase()}
                          </Typography>

                          <Typography variant="caption" className={classes.rowSubtitle}>
                            {`${formatNumber(stock.qtdest)} ${product.unimed}  em estoque - END:${stock.endest}`}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails className={classes.subResultDetails}>
                        <Box margin={1} width="100%">
                          <Grid container spacing={3}>
                            <ItemDataRow title="Codigo" value={`${product.codpro}`} />
                            <ItemDataRow title="Descrição" value={`${product.despro}`} />
                            <ItemDataRow title="Complemento" value={`${product.cplpro} `} />
                            <ItemDataRow
                              title="Qtde Estoque"
                              value={`${formatNumber(stock.qtdest)} ${product.unimed}`}
                            />
                            <ItemDataRow
                              title="Qtde Diponível"
                              value={`${formatNumber(stock.qtdest - stock.qtdres - stock.qtdblo + stock.qtdord)} ${
                                product.unimed
                              }`}
                            />
                            <ItemDataRow
                              title="Qtde Reserva"
                              value={`${formatNumber(stock.qtdres)} ${product.unimed}`}
                            />
                            <ItemDataRow
                              title="Qtde Res. Exclusiva"
                              value={`${formatNumber(stock.qtdrae)} ${product.unimed}`}
                            />
                            <ItemDataRow
                              title="Qtde Bloqueada"
                              value={`${formatNumber(stock.qtdblo)} ${product.unimed}`}
                            />
                            <ItemDataRow
                              title="Qtde Ordens"
                              value={`${formatNumber(stock.qtdord)} ${product.unimed}`}
                            />
                            <ItemDataRow title="Est. Min" value={`${formatNumber(stock.estmin)} ${product.unimed}`} />
                            <ItemDataRow title="Est. Max" value={`${formatNumber(stock.estmax)} ${product.unimed}`} />
                            <ItemDataRow
                              title="Est. Ressuprimento"
                              value={`${formatNumber(stock.estrep)} ${product.unimed}`}
                            />
                            {product.edit_address ? (
                              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className={classes.itemDataRowGrid}>
                                <AddressInput
                                  product={stock.codpro}
                                  derivation={stock.codder}
                                  deposit={stock.coddep}
                                  address={stock.endest}
                                />
                                <Divider />
                              </Grid>
                            ) : (
                              <ItemDataRow title="Endereçamento" value={`${stock.endest}`} />
                            )}

                            <ItemDataRow title="Agrupamento Est." value={`${stock.codage}`} />
                          </Grid>
                        </Box>
                        <ProductionBatch codPro={stock.codpro} codDep={stock.coddep} />
                        <StockReservations codPro={stock.codpro} codDep={stock.coddep} />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {parseInt(stockData.pageTotal, 10) > 1 && (
        <div className={classes.actions}>
          <Pagination
            count={parseInt(stockData.pageTotal, 10)}
            page={parseInt(stockData.page, 10)}
            variant="outlined"
            shape="rounded"
            onChange={handlePagination}
          />
        </div>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.root}>
        {filtersRender}
        {loadingData ? <LoadingRender /> : stockData.data?.length === 0 ? emptyResultRender : resultRender}
      </Box>
    </Box>
  );
}
