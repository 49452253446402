/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import MarkdownEditor from '@uiw/react-markdown-editor';

import { format, addBusinessDays, isAfter } from 'date-fns';
// eslint-disable-next-line import/no-duplicates

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import InputSearchAsync, { ValueProps } from '../../../../components/inputs/InputSearchAsync';
import { humanFileSize, toDate, toNumber } from '../../../../utils/functions';
import api from '../../../../services/api';

type FormStateType = {
  target_id: number;
  task_id: number;
  request_start: string;
  request_end: string;
  title: string;
  description: string;
  parent_id: number;
  private: number;
  deadline: number;
  custom: { id: number; value: string }[];
};

type FormStateError = {
  [Property in keyof FormStateType]: string;
};

type FieldType = {
  id: number;
  task_id: number;
  title: string;
  description: string;
  requirement: number;
  field_order: number;
  field_type: number;
  read_only: number;
  default_value: string;
  min_value: string;
  max_value: string;
  situation: number;
  json: string;
};

interface HelpdeskNewFormProps {
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  onSubmited?: (success: boolean, msg: string) => void;
}

const today = new Date().toISOString().split('T')[0];

const defaultFormState: FormStateType = {
  target_id: 0,
  task_id: 0,
  request_start: today,
  request_end: today,
  title: '',
  description: '',
  parent_id: 0,
  private: 0,
  deadline: 0,
  custom: [],
};

interface ICustomField {
  field: FieldType;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const CustomFields = (props: ICustomField) => {
  const { field, onChange, value = '', disabled = false, error = false, helperText = '' } = props;

  const data = useMemo(() => {
    if (!field) return null;

    const { field_type } = field;

    if (field_type === 0 || field_type === 1 || field_type === 2) {
      return (
        <TextField
          sx={{ maxWidth: field_type === 2 ? 210 : '100%' }}
          fullWidth
          label={field?.title}
          id={field?.title}
          type={field_type === 2 ? 'number' : 'text'}
          onChange={e => {
            onChange(e.target.value);
          }}
          value={value}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
          multiline={field_type === 1}
          rows={field_type === 1 ? 3 : 1}
          required={field?.requirement === 1}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      );
    }

    if (field_type === 3 || field_type === 4) {
      return (
        <TextField
          fullWidth
          label={field?.title}
          id={field?.title}
          type={field_type === 3 ? 'date' : 'time'}
          onChange={e => {
            onChange(e.target.value);
          }}
          value={value}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
          required={field?.requirement === 1}
          disabled={disabled}
          error={error}
          helperText={helperText}
          sx={{
            maxWidth: 210,
          }}
        />
      );
    }

    if (field_type === 5) {
      const {
        list,
      }: {
        list: {
          text: string;
          value: string;
        }[];
      } = JSON.parse(field.json);

      return (
        <TextField
          fullWidth
          label={field?.title}
          id={field?.title}
          select
          onChange={e => {
            onChange(e.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
          size="small"
          defaultValue={field?.default_value}
          required={field?.requirement === 1}
          disabled={disabled}
          error={error}
          helperText={helperText}
        >
          {list.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    if (field_type === 6) {
      const isChecked = value === '1';
      return (
        <TextField
          sx={{ width: 180 }}
          label={field?.title}
          id={field?.title}
          type="string"
          size="small"
          value={isChecked ? 'Sim' : 'Não'}
          disabled={disabled}
          required={field?.requirement === 1}
          error={error}
          helperText={helperText}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <Switch
                sx={{ mr: 1 }}
                checked={isChecked}
                size="small"
                color="warning"
                onChange={e => {
                  onChange(e.target.checked === true ? '1' : '0');
                }}
              />
            ),
          }}
        />
      );
    }
    return null;
  }, [value, error]);
  return data;
};

function HelpdeskNewForm(props: HelpdeskNewFormProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  const isDarkMode = theme.palette.mode === 'dark';
  const refInputAttachment = useRef<HTMLInputElement | null>(null);
  const { handleClose, handleOpen, open, onSubmited } = props;

  let totalSize = 0;

  const [formState, setFormState] = useState(defaultFormState);
  const [errorState, setErrorState] = useState<FormStateError>({} as FormStateError);

  const [submiting, setSubmiting] = useState<boolean>(false);

  const [fetchingFields, setFetchingFields] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [fields, setFields] = useState<FieldType[]>([]);

  const resetFormState = () => {
    setErrorState({} as FormStateError);
    setFormState(defaultFormState);
    setFields([]);
    setSubmiting(false);
    setFetchingFields(false);
    setAttachments([]);
  };

  function handleValideData() {
    const errors: FormStateError = {} as FormStateError;
    let errorsCount = 0;

    if (!formState.target_id || formState.target_id < 1) {
      errors.target_id = 'É necessário informar o setor';
      errorsCount++;
    }
    if (!formState.task_id || formState.task_id < 1) {
      errors.task_id = 'É necessário informar uma tarefa';
      errorsCount++;
    }
    if (!formState.request_start || formState.request_start.length === 0) {
      errors.request_start = 'É necessário informar uma previsão de início';
      errorsCount++;
    }
    if (!formState.request_end || formState.request_end.length === 0) {
      errors.request_end = 'É necessário informar uma previsão de fim';
      errorsCount++;
    }
    if (!formState.title || formState.title?.trim()?.length === 0) {
      errors.title = 'É necessário informar um título';
      errorsCount++;
    } else if (!formState.title || formState.title?.trim()?.length < 10) {
      errors.title = 'Título muito curto';
      errorsCount++;
    }
    if (!formState.description || formState.description?.trim()?.length === 0) {
      errors.description = 'É necessário informar uma descrição';
      errorsCount++;
    } else if (!formState.description || formState.description?.trim()?.length < 10) {
      errors.description = 'Descrição muito curto';
      errorsCount++;
    }

    fields.forEach(field => {
      const input = formState.custom.filter(f => f.id === field.id)?.[0];
      input.value = input.value || '';
      errors[field.id.toString()] = '';
      if (field.requirement === 1 && input?.value?.toString()?.trim()?.length === 0) {
        errors[field.id.toString()] = 'Campo obrigatório';
        errorsCount++;
      }
    });

    setErrorState(errors);

    if (errorsCount > 0) return false;

    return true;
  }

  const handleSubmit = async () => {
    if (submiting) return;
    setSubmiting(true);
    if (handleValideData()) {
      try {
        const data = new FormData();
        data.append('target_id', formState.target_id.toString());
        data.append('task_id', formState.task_id.toString());
        data.append('request_start', formState.request_start.toString());
        data.append('request_end', formState.request_end);
        data.append('title', formState.title);
        data.append('description', formState.description);
        data.append('parent_id', formState.parent_id.toString());
        data.append('private', formState.private.toString());
        formState.custom.map(custom => data.append('custom', `${custom.id};${custom.value}`));

        attachments.map(attachment => data.append('files', attachment));

        const { data: helpdesk } = await api.post('v2/helpdesk/add', data);
        if (helpdesk?.id > 0) {
          onSubmited?.(true, 'Inserido com sucesso.');
          resetFormState();
        } else {
          onSubmited?.(false, 'Erro ao gerar Helpdesk.');
        }
      } catch (error) {
        const { data: response } = error.response;

        onSubmited?.(false, response.message ? response.message : error);
      }
    }
    setSubmiting(false);
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    let { value } = e.target;
    value = value || '';

    setFormState(old => ({ ...old, [id]: value }));
  };

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachments;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of event.target.files) {
          newAttachments.push(attachment);
        }
      } catch (error) {
        const { data: response } = error.response;

        onSubmited?.(false, response.message ? response.message : error);
      }

      setAttachments([...newAttachments]);
    }
  };

  const handleRemoveAllAttachments = () => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    setAttachments([]);
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachments;
    newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);
  };

  const handleOnCloseDialog = () => {
    if (submiting) return;
    handleClose?.();
  };

  const handleClickCancelDialog = () => {
    setAttachments([]);
    setFormState(defaultFormState);
    handleClose?.();
  };

  useEffect(() => {
    if (open) {
      handleOpen?.();
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getFields = async (task_id: number) => {
    if (fetchingFields) {
      return;
    }

    setFetchingFields(true);

    try {
      let { data } = await api.get<FieldType[]>('v2/helpdesk/field/get', {
        params: { task_id },
      });

      data = data.map(field => ({
        ...field,
        default_value: field.default_value || '',
        value: field.default_value || '',
      }));

      setFormState(old => ({
        ...old,
        custom: data.map(field => ({
          id: field.id,
          default_value: field.default_value || '',
          value: field.default_value || '',
        })),
      }));

      const error = {} as FormStateError;

      data.forEach(field => {
        error[field.id.toString()] = '';
      });

      setErrorState(error);

      setFields(data);
    } catch (error) {
      const { data: response } = error.response;

      onSubmited?.(false, response.message ? response.message : error);
    }

    setFetchingFields(false);
  };

  useEffect(() => {
    if (open) {
      getFields(formState.task_id);
    }
  }, [formState.task_id]);

  const inputTarget = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-target"
        id="target_id"
        onChange={(e: ValueProps) => {
          const newTarget = toNumber(e?.id);
          if (newTarget === 0) {
            setFormState(old => ({ ...old, target_id: 0 }));
          }
          setFormState(old => ({ ...old, target_id: newTarget }));
        }}
        label="Setor"
        required
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        error={errorState?.target_id?.length > 0}
        helperText={errorState?.target_id}
      />
    ),
    [formState?.target_id, errorState?.target_id],
  );

  const inputTask = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-task"
        id="task_id"
        onChange={(e: ValueProps) => {
          const deadline = toNumber(e?.deadline);
          const request_end = format(addBusinessDays(toDate(formState.request_start), deadline), 'yyyy-MM-dd');

          setFormState(old => ({
            ...old,
            task_id: toNumber(e?.id),
            deadline,
            request_end,
          }));
        }}
        label="Tarefa"
        searchParams={{ targets: toNumber(formState?.target_id) }}
        multiple={false}
        required
        startAdornment={<InputAdornment position="start" />}
        disabled={formState?.target_id === 0}
        error={errorState?.task_id?.length > 0}
        helperText={errorState?.task_id}
      />
    ),
    [formState?.task_id, formState?.target_id, errorState?.task_id],
  );

  const inputParent = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-id"
        id="parent_id"
        onChange={(e: ValueProps) => {
          setFormState(old => ({ ...old, parent_id: toNumber(e?.id) }));
        }}
        label="Helpdesk Pai"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        disabled={formState?.task_id === 0}
        error={errorState?.parent_id?.length > 0}
        helperText={errorState?.parent_id}
      />
    ),
    [formState?.parent_id, formState?.task_id, errorState?.parent_id],
  );

  const inputPrivate = useMemo(
    () => (
      <TextField
        sx={{ width: 180 }}
        label="Privado"
        id="rework"
        type="string"
        size="small"
        value={formState.private === 0 ? 'Não' : 'Sim'}
        error={errorState?.private?.length > 0}
        helperText={errorState?.private}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Switch
              sx={{ mr: 1 }}
              checked={formState.private === 1}
              size="small"
              color="warning"
              onChange={e => {
                setFormState(old => ({ ...old, private: e.target.checked === true ? 1 : 0 }));
              }}
            />
          ),
        }}
      />
    ),
    [formState.private, errorState?.private],
  );

  const infoDeadline = useMemo(
    () => (
      <TextField
        sx={{ maxWidth: 210 }}
        label="Prazo (dias úteis)"
        id="deadline"
        type="string"
        size="small"
        value={`${formState.deadline} ${formState.deadline > 1 ? 'dias' : 'dia'}`}
        disabled={formState?.task_id === 0}
        error={errorState?.deadline?.length > 0}
        helperText={errorState?.deadline}
      />
    ),
    [formState?.deadline, errorState?.deadline],
  );

  const inputStartDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Início"
        id="request_start"
        type="date"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          let { request_end } = formState;
          const startDate = toDate(value);
          request_end = format(addBusinessDays(startDate, formState.deadline), 'yyyy-MM-dd');
          setFormState(old => ({ ...old, request_start: value, request_end }));
        }}
        value={formState.request_start}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState?.request_start?.length > 0}
        helperText={errorState?.request_start}
        required
        sx={{
          maxWidth: 210,
        }}
        disabled={formState?.task_id === 0}
      />
    ),
    [formState.request_start, formState.request_end, formState.deadline, errorState?.request_start, formState?.task_id],
  );

  const inputEndDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Fim Previsto"
        id="request_end"
        type="date"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          let { request_start } = formState;
          const request_end = toDate(value);
          request_start = isAfter(toDate(request_start), request_end) ? value : request_start;
          setFormState(old => ({ ...old, request_start, request_end: value }));
        }}
        value={formState.request_end}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState?.request_end?.length > 0}
        helperText={errorState?.request_end}
        required
        sx={{
          maxWidth: 210,
        }}
        disabled={formState?.task_id === 0}
      />
    ),
    [formState?.request_end, formState?.deadline, formState?.task_id, errorState?.request_end],
  );

  const inputTitle = useMemo(
    () => (
      <TextField
        fullWidth
        label="Título"
        id="title"
        type="text"
        onChange={handleFieldChange}
        value={formState.title}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        required
        disabled={formState?.task_id === 0}
        error={errorState?.title?.length > 0}
        helperText={errorState?.title}
      />
    ),
    [formState.title, formState?.task_id, errorState?.title],
  );

  const inputDescription = useMemo(
    () => (
      <TextField
        fullWidth
        label="Descrição"
        id="description"
        type="text"
        onChange={handleFieldChange}
        value={formState.description}
        size="small"
        multiline
        minRows={12}
        required
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        disabled={formState?.task_id === 0}
        error={errorState?.description?.length > 0}
        helperText={errorState?.description}
      />
    ),
    [formState.description, formState?.task_id, errorState?.description],
  );

  const inputAttachment = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.text[formState.task_id > 0 ? 'primary' : 'disabled'],
          }}
        >
          Arquivos
        </Typography>

        <Box
          sx={{
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
              border: formState.task_id > 0 ? '1px solid' : `1px solid ${theme.palette.action.disabled}`,
            },
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            p: 1,
            maxHeight: 128,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              flex: 1,
              height: '100%',
            }}
          >
            {attachments?.length === 0 ? (
              <Box sx={{ p: 0.5 }}>
                <Typography
                  sx={{ fontSize: 14, color: theme.palette.text[formState.task_id > 0 ? 'secondary' : 'disabled'] }}
                >
                  Nenhum arquivo informado
                </Typography>
              </Box>
            ) : (
              attachments?.map((attachment: File, index: number) => {
                const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                const name = attachment.name.toLocaleLowerCase();
                const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                const size = humanFileSize(attachment.size);
                const key = `${index}-${name}-${type}-${size}`;
                totalSize += attachment.size;
                return (
                  <Box key={key} sx={{ display: 'flex', gap: 1, flex: 1, alignItems: 'center' }}>
                    <Tooltip title="Remover arquivo" arrow disableInteractive>
                      <IconButton
                        color="error"
                        aria-label="remove file"
                        component="label"
                        size="small"
                        onClick={() => handleRemoveAttachments(index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                      {name} ({size})
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {attachments?.length > 0 && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption">{`${attachments?.length} ${
                attachments?.length === 1 ? 'arquivo selecionado' : 'arquivos selecionados'
              } (${humanFileSize(totalSize)})`}</Typography>
            </Box>
          )}
          <Tooltip title="Remover todos os anexos">
            <IconButton
              color="error"
              aria-label="remove files"
              component="label"
              size="small"
              disabled={attachments?.length === 0}
              onClick={() => handleRemoveAllAttachments()}
            >
              <DeleteSweepOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Selecionar arquivo">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              size="small"
              disabled={formState?.task_id === 0}
            >
              <input
                style={{ display: 'none' }}
                id="attachment-input"
                hidden
                type="file"
                multiple
                ref={refInputAttachment}
                onChange={handleAddAttachments}
              />
              <NoteAddOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),
    [attachments, formState?.task_id],
  );

  return (
    <Dialog
      open={open}
      onClose={handleOnCloseDialog}
      fullWidth={true}
      maxWidth="md"
      fullScreen={sm}
      disableEscapeKeyDown
    >
      <DialogTitle>Abertura de Helpdesk</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
          }}
        >
          <Tooltip title="Se estiver em modo privado, apenas os envolvidos no processo podem visualizar a descrição, resposta e chat.">
            {inputPrivate}
          </Tooltip>
          {inputTarget}
          {inputTask}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2.5,
            }}
          >
            {inputStartDate}
            {inputEndDate}
            {infoDeadline}
          </Box>
          {inputParent}
          {inputTitle}
          {inputDescription}

          {fields.map(field => {
            return (
              <CustomFields
                key={field.id}
                field={field}
                value={formState.custom.filter(v => v.id === field.id)?.[0]?.value}
                error={errorState[field?.id?.toString()]?.length > 0}
                helperText={errorState[field?.id?.toString()]}
                onChange={e => {
                  setFormState(old => ({
                    ...old,
                    custom: [...old.custom].map(object => {
                      if (object.id === field.id) return { ...object, value: e };
                      return object;
                    }),
                  }));
                }}
              />
            );
          })}

          {inputAttachment}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClickCancelDialog} disabled={submiting}>
          Cancelar
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={handleSubmit}
          disabled={formState?.task_id === 0 || submiting}
        >
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { HelpdeskNewForm };
