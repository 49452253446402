import Birthday from './birthday';
import Dashboard from './dashboard';
import Myaccesses from './myaccesses';
import Payday from './payday';
import Menu from './menu';

export default {
  Birthday,
  Dashboard,
  Myaccesses,
  Payday,
  Menu,
};
