import React from 'react';
import { Box } from '@mui/material';
import styles from './styles';
import UnderDevelopment from '../../../../../components/UnderDevelopment';

export default function RequestsSearch() {
  const classes = styles();

  return (
    <Box className={classes.root}>
      <UnderDevelopment />
    </Box>
  );
}
