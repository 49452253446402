import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, grey, indigo, lightBlue } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',

      justifyContent: 'center',
      alignItems: 'center',
    },
    chartBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 296,
      width: '100%',
      border: `1px solid ${darkMode ? grey[700] : grey[400]}`,
      backgroundColor: darkMode ? grey[800] : grey[100],
      borderRadius: 6,
    },
    chartTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 36,
      width: '100%',
      backgroundColor: darkMode ? '#002f4a' : blue[900],
      color: darkMode ? grey[200] : grey[100],
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    chartContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: 8,
    },
  });
});
