import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Paper,
  Typography,
  IconButton,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import api from '../../../../services/api';
import { toNumber } from '../../../../utils/functions';
import { ProfileField } from '../../../../components/ProfileField';
import ProfileTooltip from '../../../../components/ProfileTooltip';

interface Props {
  date?: string;
  bookings?: BookingType[];
}

export type BookingType = {
  start: string;
  end: string;
  description?: string;
  type?: string;
  userID?: string;
  userName?: string;
  bgColor?: string;
  days?: number;
};

export default function MonthCalendar({ date = moment().toISOString(), bookings = [] }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const calendarDate = moment(date);

  const today = moment();

  const isThisMonth = calendarDate.format('yyyy-M') === today.format('yyyy-M');
  const currentDay = calendarDate.date();
  const currentMonth = calendarDate.month() + 1;
  const currentMonthName = calendarDate.format('MMMM');
  const currentYear = calendarDate.year();

  const weekdaysShort = moment.weekdaysShort();
  const firstDay = toNumber(moment(calendarDate).startOf('month').format('d'));
  const totalDaysInMonth = moment(calendarDate).daysInMonth();

  const monthDays = [];

  for (let i = 0; i < firstDay; i++) {
    monthDays.push('');
  }
  for (let d = 1; d <= totalDaysInMonth; d++) {
    monthDays.push(d);
  }

  const rows = [];
  let cells = [];

  monthDays.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row); // if index not equal 7 that means not go to next week
    } else {
      rows.push(cells); // when reach next week we contain all td in last week to rows
      cells = []; // empty container
      cells.push(row); // in current loop we still push current row to new container
    }
    if (i === monthDays.length - 1) {
      // when end loop we add remain date
      rows.push(cells);
    }
  });

  const allBookingsOnMonth = bookings.filter(f =>
    moment(`${currentYear}-${currentMonth}-1 00:00:00`).isBetween(
      moment(`${f.start} 00:00:00`).format('yyyy-M'),
      moment(`${f.end} 00:00:00`).format('yyyy-M'),
      undefined,
      '[]',
    ),
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
        {currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)}
      </Box>
      <TableContainer component={Paper} sx={{ p: 1, height: '100%', width: '100%' }}>
        <Table aria-label="calendar">
          <TableHead>
            <TableRow>
              {weekdaysShort.map((day, index) => {
                return (
                  <TableCell
                    key={day}
                    align="center"
                    sx={{
                      color:
                        index === 0
                          ? theme.palette.error.light
                          : index === 6
                          ? theme.palette.info.light
                          : theme.palette.text.secondary,
                    }}
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {rows.map((row, ri) => {
              const rKey = `row-${ri}`;
              return (
                <TableRow key={rKey}>
                  {row.map((day, di) => {
                    const dKey = `row-${di}`;

                    const isToday = isThisMonth && day === today.date();
                    const cDate = `${currentYear}-${currentMonth}-${day} 00:00:00`;

                    const currentBookings = bookings.filter(f =>
                      moment(cDate).isBetween(
                        moment(`${f.start} 00:00:00`),
                        moment(`${f.end} 00:00:00`),
                        undefined,
                        '[)',
                      ),
                    );

                    return (
                      <Tooltip title={isToday ? 'Hoje' : ''} key={dKey}>
                        <TableCell
                          align="center"
                          sx={{
                            p: 1,

                            bgcolor: day !== '' ? (isDarkMode ? 'rgb(0 0 0 / 10%)' : 'rgb(0 0 0 / 2%)') : '',
                            borderLeft: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            ':first-of-type': {
                              borderLeft: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            },

                            ':last-child': {
                              borderRight: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            },
                            outline: isToday ? `4px solid ${theme.palette.info.light}` : '',
                            outlineOffset: '-4.5px',

                            color:
                              di === 0
                                ? theme.palette.error.main
                                : di === 6
                                ? theme.palette.info.main
                                : theme.palette.text.primary,
                          }}
                        >
                          {day !== '' && (
                            <>
                              <Box sx={{ pb: 1 }}>
                                <Typography sx={{ fontWeight: isToday ? 'bold' : 'normal' }}>{day}</Typography>
                              </Box>

                              <Box
                                sx={{
                                  // maxHeight: 24,
                                  minHeight: 24,
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  gap: 0.25,
                                }}
                              >
                                {currentBookings.map((booking, bi) => {
                                  const key = `${cDate}-${bi}`;

                                  return (
                                    <ProfileTooltip userId={booking.userID} key={key}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          // position: 'absolute',
                                          bgcolor: booking.bgColor,
                                          width: 12,
                                          height: 12,
                                          borderRadius: '50%',
                                          outline: `1px solid ${theme.palette.text.primary}`,
                                          ':hover': {
                                            outline: `3px solid ${theme.palette.text.primary}`,
                                            cursor: 'pointer',
                                          },
                                        }}
                                      />
                                    </ProfileTooltip>
                                  );
                                })}
                              </Box>
                            </>
                          )}
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ py: allBookingsOnMonth.length > 0 ? 2 : 0 }}>
          {allBookingsOnMonth.map((booking, bi) => {
            const key = `${currentMonth}-${bi}`;

            return (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: 1,

                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    // position: 'absolute',
                    bgcolor: booking.bgColor,

                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    outline: `1px solid ${theme.palette.text.primary}`,
                    ':hover': {
                      outline: `3px solid ${theme.palette.text.primary}`,
                      cursor: 'pointer',
                    },
                  }}
                />
                <ProfileField
                  userId={booking.userID}
                  userName={`${booking.userName} (${booking.days} dias)`}
                  size={22}
                />
              </Box>
            );
          })}
        </Box>
      </TableContainer>
    </Box>
  );
}
