import React from 'react';

import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import styles from './styles';

export default function SalesXProduction() {
  const classes = styles();

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box className={classes.container}>
        <Box className={classes.title}>
          <span>Acompanhamento Vendas x Produção - 2022</span>
        </Box>
        <Box className={classes.content}>
          <Table size="small" aria-label="a dense table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleRow} align="center">
                  Mês
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Jan.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Fev.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Mar.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Abr.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Mai.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Jun.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Jul.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Ago.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Set.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Out.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Nov.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Dez.
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Média Mês
                </TableCell>
                <TableCell className={classes.titleHeader} align="right">
                  Acúmulo Anual
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.valueRow}>
                <TableCell className={classes.titleRow} align="center">
                  Vendas Equipamentos
                </TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
              </TableRow>
              <TableRow className={classes.valueRow}>
                <TableCell className={classes.titleRow} align="center">
                  Produção Equipamentos
                </TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
              </TableRow>
              <TableRow className={classes.valueRow}>
                <TableCell className={classes.titleRow} align="center">
                  Vendas Caldeirarias
                </TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
              </TableRow>
              <TableRow className={classes.valueRow}>
                <TableCell className={classes.titleRow} align="center">
                  Produção Caldeirarias
                </TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
                <TableCell align="right">10,00</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
}
