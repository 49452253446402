import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Axios, { Canceler } from 'axios';
import api from '../../services/api';
import NewsIcon from '../../images/logo_b.svg';
import { TagsData } from '../../pages/news/dashboard';
import ProfileTooltip from '../ProfileTooltip';
import 'suneditor/dist/css/suneditor.min.css';
import styles from './styles';

interface Props {
  id: number;
  title: string;
  date: string;
  author: {
    id: number;
    name: string;
    sector: string;
  };
  content: any;
  likesCount: number;
  commentsCount: number;
  canLike: boolean;
  canComment: boolean;
  category: number;
  categoryDescription: string;
  categoryHandleClick?: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  tags: TagsData;
  liked: boolean;
  situation: number;
  edit: number;
  updateDate: string;
  updateUserName: string;
  updateUserId: string;
}

export default function NewsCard(props: any & Props) {
  const {
    id,
    title,
    date,
    updateDate,
    updateUserName,
    updateUserId,
    author = { id: 0, name: ' ', sector: '' },
    content,
    likesCount,
    canLike,
    category,
    categoryDescription,
    tags,
    categoryHandleClick,
    liked,
    style,
    edit,
    situation,
  } = props;
  const classes = styles();

  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });
  moment.locale('pt-br');

  const previewImg = `${api.getUri()}/static/uploads/news/news-preview-${id}.jpg`;
  const [loadingLike, setLoadingLike] = useState<boolean>(false);
  const [likes, setLikes] = useState(likesCount);
  const [iLike, setILike] = React.useState(liked);

  const handleLike = (id: number) => {
    if (id === 0) return;
    let cancel: Canceler;
    if (!loadingLike) {
      setLoadingLike(true);
      api
        .post(`intranet/news/${id}/like`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setILike(response.data.situation);
          if (response.data.situation === 0) {
            setLikes(likes - 1);
          } else {
            setLikes(likes + 1);
          }
          setLoadingLike(false);
        })
        .catch((error: any) => {
          console.error(error);
          setLoadingLike(false);
        });
    }
    return () => cancel();
  };

  return (
    <Card className={classes.cardRoot} style={style}>
      <CardHeader
        avatar={
          <Box className={classes.newsPreview}>
            <img
              src={previewImg}
              onError={(e: any) => {
                e.target.src = NewsIcon;
              }}
              alt="news"
              style={{ width: 64, height: 64 }}
            />
          </Box>
        }
        title={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.newsTitle}>{title}</Typography>
            <Box display="flex" alignItems="center">
              {situation === 0 && (
                <Tooltip title="Notícia não publicada">
                  <VisibilityOffIcon color="action" style={{ marginRight: 8 }} />
                </Tooltip>
              )}
              {edit === 1 && (
                <IconButton color="primary" component={Link} to={`/news/${id}/edit`} size="large">
                  <Tooltip title="Editar">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              )}
            </Box>
          </Box>
        }
        subheader={
          <>
            <Typography className={classes.newsDate}>
              {'Criado em '}
              {moment.utc(date).format('LLLL')}
              {' por '}
              <ProfileTooltip userId={author?.id}>
                <Link className={classes.userLink} to={`/users/${author?.id}`}>
                  {author?.name}
                </Link>
              </ProfileTooltip>
              {' em '}
              <Link
                className={classes.categoryLink}
                to="/news/"
                onClick={() => {
                  if (categoryHandleClick) categoryHandleClick(category);
                }}
              >
                {categoryDescription}
              </Link>
            </Typography>
            {updateUserId && (
              <Typography className={classes.newsDate}>
                {'Editado em '}
                {moment.utc(updateDate).format('LLLL')}
                {' por '}
                <Link className={classes.userLink} to={`/users/${updateUserName}`}>
                  {updateUserId}
                </Link>
              </Typography>
            )}
          </>
        }
      />

      <CardContent>
        <div
          className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.textContainer}`}
          dangerouslySetInnerHTML={{
            __html: `${content}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`),
          }}
        />
      </CardContent>
      <CardActions disableSpacing>
        {canLike ? (
          <Box>
            <IconButton
              disabled={!canLike}
              onClick={() => {
                if (id > 0) handleLike(id);
              }}
              style={{ marginRight: 16 }}
              size="large"
            >
              <Tooltip title={`${iLike ? 'Deixar de Gostar' : 'Gostei!'}`}>
                <Badge className={classes.badge} badgeContent={likes}>
                  {iLike ? <FavoriteIcon color="secondary" /> : <FavoriteBorderIcon color="inherit" />}
                </Badge>
              </Tooltip>
            </IconButton>
          </Box>
        ) : (
          <Tooltip title="Gostei foi desabilitado para essa notícia">
            <FavoriteIcon color="disabled" style={{ cursor: 'pointer', margin: 12, marginRight: 28 }} />
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
}
