import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    searchContainer: {
      display: 'flex',
    },
    resultContainer: {
      display: 'flex',
      flex: 1,
      minHeight: 'calc(100vh - 318px)',
      maxHeight: 'calc(100vh - 318px)',
      overflowY: 'auto',
      marginTop: 0,
    },
    inputRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      margin: 8,
      padding: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    closeIconButton: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
    resultTextHighlights: {
      fontWeight: 700,
      color: darkMode ? yellow[400] : yellow[900],
      backgroundColor: 'transparent',
    },
    profileImageContainer: {
      height: '100%',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: darkMode ? grey[800] : '#fff',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: 16,
      borderBottomColor: darkMode ? '#363636' : grey[300],
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
    profileImage: {
      height: 96,
      width: 96,
      borderColor: darkMode ? grey[700] : grey[400],
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: darkMode ? '#ababab' : '#bababa',
      borderRadius: '50%',
    },
    aboutContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 358,
    },
    profileName: {
      marginTop: 8,
      fontSize: 22,
      color: darkMode ? grey[200] : grey[800],
    },
    profileSector: {
      fontSize: 14,
      lineHeight: 1.5,

      color: darkMode ? grey[400] : grey[600],
    },
    profilePosition: {
      fontSize: 12,
      lineHeight: 0.7,
      color: darkMode ? grey[400] : grey[600],
    },
    profileDataContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      padding: 16,
    },
    profileInfoContainer: {
      marginBottom: 8,
    },
    profileInfoTitle: {
      fontSize: 10,
      lineHeight: 1.2,
      color: darkMode ? grey[400] : grey[600],
    },
    profileInfoText: {
      fontSize: 14,
      lineHeight: 1.2,
      minHeight: 16,
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: 6,
    },
  });
});
