import React, { useState, useEffect, useRef } from 'react';
import { Link, redirect, useLocation } from 'react-router-dom';

import {
  Hidden,
  Typography,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  CircularProgress,
  Box,
  Snackbar,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import { Alert } from '@mui/lab';
import imgWave from '../../images/wave.svg';
import imgBackgroundLoginPage from '../../images/background_login_page.svg';
import imgLogo from '../../images/logo_b.svg';
import styles from './styles';

import auth from '../../services/auth';
import LoadingPage from '../templates/loading';
import OfflinePage from '../templates/offline';

interface ResponseAuth {
  status: number;
  data: {
    message: string;
    data: any;
  };
}

function LoginForm({
  loading,
  setLoading,
  setOpenSnackbar,
  inputLoginPasswordRef,
  setSnackbarType,
  setSnackbarMsg,
  inputLoginLoginRef,
  setIsOffline,
  setAuthenticate,
  redirectPage,
  classes,
}) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [remember, setremember] = useState(false);

  const showMessagemError = (type: string, msg: string) => {
    setOpenSnackbar(false);
    if (type === 'password') {
      setPasswordError(msg);
      inputLoginPasswordRef.current.focus();
    } else if (type === 'login') {
      setLoginError(msg);
      inputLoginLoginRef.current.focus();
    }
    setSnackbarType('error');
    setSnackbarMsg(msg);
    setOpenSnackbar(true);
    setLoading(false);
  };

  const handleLogin = (e: any) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      auth.login(login, password, remember, (response: ResponseAuth) => {
        if (typeof response !== 'undefined' && response != null) {
          if (![200, 201].includes(response.status)) {
            const message = response.data.message || '';
            if (message === 'user_not_found' || message === 'invalid_employee')
              showMessagemError('login', 'Usuário não encontrado, contate o RH');
            else if (message === 'invalid_password') showMessagemError('password', 'Senha inválida');
            else if (message === 'wrong_password') showMessagemError('password', 'Senha incorreta');
            else if (message === 'empty_login') showMessagemError('login', 'É obrigatório informar um usuário');
            else if (message === 'invalid_login') showMessagemError('login', 'Usuário inválido, contate o TI');
            else if (message === 'user_disabled') showMessagemError('login', 'Usuário desativado, contate o RH');
            else if (message === 'empty_password') showMessagemError('password', 'É obrigatório informar uma senha');
            else if (message === 'response_invalid') {
              setIsOffline(true);
            } else {
              setSnackbarType('error');
              setSnackbarMsg(message);
              setOpenSnackbar(true);
              setLoading(false);
            }
          } else {
            setAuthenticate(true);

            window.location.href = `${redirectPage || '/dashboard'}`;
            return null;
          }
        }
        setLoading(false);
      });
    }
  };

  return (
    <form noValidate onSubmit={handleLogin} className={classes.form}>
      <TextField
        className={classes.input}
        inputRef={inputLoginLoginRef}
        error={loginError !== '' ?? true}
        helperText={loginError}
        margin="normal"
        required
        fullWidth
        id="login"
        label="Usuário"
        name="login"
        type="login"
        autoComplete="login"
        autoFocus
        value={login}
        color="primary"
        onChange={e => {
          setLogin(e.target.value);
          setLoginError('');
        }}
      />
      <TextField
        className={classes.input}
        inputRef={inputLoginPasswordRef}
        error={passwordError !== '' ?? true}
        helperText={passwordError}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Senha"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={e => {
          setPassword(e.target.value);
          setPasswordError('');
        }}
      />
      <FormControlLabel
        className={classes.rememberLogin}
        control={
          <Checkbox
            value="remember"
            color="default"
            className={classes.checkBoxLogin}
            onChange={e => {
              setremember(e.target.checked);
            }}
          />
        }
        label="Lembrar dados"
      />
      <div className={classes.wrapper}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.submitButton}
          onClick={handleLogin}
          type="submit"
        >
          Autenticar
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <Grid item xs>
        <Link to="/authenticate/recovery" className={classes.forget}>
          Esqueceu a senha?
        </Link>
      </Grid>
    </form>
  );
}

function RecoveryForm({
  loading,
  setLoading,
  setOpenSnackbar,
  inputRecoveryLoginRef,
  setSnackbarType,
  setSnackbarMsg,
  classes,
}) {
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState('');

  const [wasSuccessfullySent, setWasSuccessfullySent] = useState(false);

  const handleRecovery = (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      auth.recovery(login, (response: ResponseAuth) => {
        if (typeof response !== 'undefined' && response != null) {
          if (response.status !== 200) {
            const { message } = response.data;
            if (message === 'invalid_login' || message === 'invalid_employee' || message === 'user_not_found') {
              setSnackbarType('error');
              setSnackbarMsg('Usuário não encontrado');
              setOpenSnackbar(true);
              inputRecoveryLoginRef.current.focus();
              setLoading(false);
            } else if (message === 'empty_login') {
              setSnackbarType('error');
              setSnackbarMsg('É obrigatório informar um login');
              setOpenSnackbar(true);
              inputRecoveryLoginRef.current.focus();
              setLoading(false);
            } else {
              setSnackbarType('error');
              setSnackbarMsg(message);
              setOpenSnackbar(true);
              inputRecoveryLoginRef.current.focus();
              setLoading(false);
            }
          } else {
            setWasSuccessfullySent(true);
          }
        }
        setLoading(false);
      });
    }
  };

  return (
    <form name="forget-password" noValidate onSubmit={handleRecovery} className={classes.form}>
      <Box
        style={{
          display: wasSuccessfullySent ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          style={{
            color: grey[100],
            paddingTop: '32px',
            paddingBottom: '32px',
            fontSize: '18px',
          }}
        >
          Um email de recuperação de senha foi enviado para seu email com sucesso!
        </Typography>
        <Typography variant="caption" style={{ color: grey[300], paddingBottom: '16px' }}>
          Siga as intruções para recuperação da senha enviada no seu email.
        </Typography>
        <Link to="/authenticate" className={classes.forget}>
          Voltar para autenticação
        </Link>
      </Box>
      <Box
        style={{
          display: wasSuccessfullySent ? 'none' : 'block',
        }}
      >
        <TextField
          className={classes.input}
          inputRef={inputRecoveryLoginRef}
          error={loginError !== '' ?? true}
          helperText={loginError}
          margin="normal"
          required
          fullWidth
          id="login"
          label="Usuário"
          name="login"
          autoComplete="login"
          autoFocus
          value={login}
          color="primary"
          onChange={e => {
            setLogin(e.target.value);
            setLoginError('');
          }}
        />

        <div className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submitButton}
            onClick={handleRecovery}
            type="submit"
          >
            RECUPERAR
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <Grid item xs>
          <Link to="/authenticate" className={classes.forget}>
            Lembrou a senha?
          </Link>
        </Grid>
      </Box>
    </form>
  );
}

function ResetForm({
  loading,
  setLoading,
  setOpenSnackbar,
  inputResetPassword1Ref,
  inputResetPassword2Ref,
  setSnackbarType,
  setSnackbarMsg,
  setIsOffline,
  redirectPage,
  classes,
}) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [wasSuccessfullyReseted, setWasSuccessfullyReseted] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const params = new URLSearchParams(window.location.search);
  const tokenParams = params.get('token') || '';
  const loginParams = params.get('login') || '';

  const handleNewPassword = (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      if (password1 === '') {
        setSnackbarType('error');
        setOpenSnackbar(true);
        setSnackbarMsg('É obrigatório informar uma senha');
        inputResetPassword1Ref.current.focus();
        setLoading(false);

        return;
      }
      if (password1?.length < 6) {
        setSnackbarType('error');
        setOpenSnackbar(true);
        setSnackbarMsg('A senha não pode ser menor do que 6 caracteres');
        inputResetPassword1Ref.current.focus();
        setLoading(false);

        return;
      }
      if (password1 !== password2) {
        setSnackbarType('error');
        setOpenSnackbar(true);
        setSnackbarMsg('As senhas não são iguais');
        inputResetPassword2Ref.current.focus();
        setLoading(false);
        return;
      }

      auth.reset(tokenParams, loginParams, password1, (response: ResponseAuth) => {
        if (typeof response !== 'undefined' && response != null) {
          if (response.status !== 200) {
            const message = response?.data?.message;
            if (message === 'invalid_login' || message === 'invalid_employee' || message === 'user_not_found') {
              setSnackbarType('error');
              setSnackbarMsg('Usuário não encontrado');
              setOpenSnackbar(true);
            } else if (response.data.message === 'invalid_password') {
              setSnackbarType('error');
              setSnackbarMsg('Senha inválida');
              setOpenSnackbar(true);
              inputResetPassword1Ref.current.focus();
              setLoading(false);
            } else if (response.data.message === 'empty_password') {
              setSnackbarType('error');
              setSnackbarMsg('É obrigatório informar uma senha');
              setOpenSnackbar(true);
              setLoading(false);
              inputResetPassword1Ref.current.focus();
            } else if (response.data.message === 'invalid_token') {
              setSnackbarType('error');
              setSnackbarMsg('Token inválido');
              setOpenSnackbar(true);
              setLoading(false);
            } else if (response.data.message === 'response_invalid') {
              setIsOffline(true);
            } else {
              setSnackbarType('error');
              setSnackbarMsg(message);
              setOpenSnackbar(true);
              setLoading(false);
            }
          } else {
            window.location.href = `${redirectPage || '/dashboard'}`;
            setLoading(false);
          }
        }
        setLoading(false);
      });
    } else {
      setSnackbarType('error');
      setSnackbarMsg('Erro desconhecido');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <form
        name="new-password"
        noValidate
        onSubmit={handleNewPassword}
        className={classes.form}
        style={{
          display: tokenParams === null ? 'none' : 'block',
        }}
      >
        <TextField
          className={classes.input}
          inputRef={inputResetPassword1Ref}
          error={passwordError !== '' ?? true}
          helperText={passwordError}
          margin="normal"
          required
          fullWidth
          name="password1"
          label="Senha"
          type="password"
          id="password1"
          autoComplete="current-password"
          value={password1}
          onChange={e => {
            setPassword1(e.target.value);
            setPasswordError('');
          }}
        />
        <TextField
          className={classes.input}
          inputRef={inputResetPassword2Ref}
          margin="normal"
          required
          fullWidth
          name="password2"
          label="Confirmar senha"
          type="password"
          id="password2"
          value={password2}
          onChange={e => {
            setPassword2(e.target.value);
            setPasswordError('');
          }}
        />
        <div className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submitButton}
            onClick={handleNewPassword}
            type="submit"
          >
            Alterar Senha
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <Grid item xs>
          <Link to="/authenticate" className={classes.forget}>
            Lembrou a senha?
          </Link>
        </Grid>
      </form>
      <Box
        style={{
          display: wasSuccessfullyReseted ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          style={{
            color: grey[100],
            paddingTop: '32px',
            paddingBottom: '32px',
            fontSize: '18px',
          }}
        >
          Senha alterada com sucesso!
        </Typography>
        <Link to="/dashboard" className={classes.forget}>
          Ir para o Dashboard
        </Link>
      </Box>
    </div>
  );
}

export default function AuthenticatePage(props: any) {
  const classes = styles();
  const { darkMode, setDarkMode, page } = props;
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectPage = queryParams.get('redirect');

  const inputLoginLoginRef = useRef<any>();
  const inputLoginPasswordRef = useRef<any>();

  const inputRecoveryLoginRef = useRef<any>();

  const inputResetPassword1Ref = useRef<any>();
  const inputResetPassword2Ref = useRef<any>();

  const [authenticate, setAuthenticate] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const checkIfIsAuthenticate = () => {
    setLoadingPage(true);

    const token = localStorage.getItem('token');
    if (token && token !== '') {
      auth.check((response: any) => {
        if (response.data.message === 'response_invalid') {
          setIsOffline(true);
        }
        if (response.status !== 200) {
          setAuthenticate(false);
          setLoadingPage(false);
        } else if (response.data.user) {
          setAuthenticate(true);
          window.location.href = `${redirectPage || '/dashboard'}`;
        }
      });
    } else {
      setAuthenticate(false);
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    setDarkMode(false);
    checkIfIsAuthenticate();
    for (let index = 0; index < 100; index++) {
      window.clearInterval(index);
    }
  }, []);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (isOffline) {
    return <OfflinePage />;
  }

  if (authenticate) {
    redirect('/dashboard');
    return null;
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <img src={imgWave} alt="Wave" className={classes.wave} />

      <Grid item xs={false} sm={false} md={6} className={classes.backgroundImageContainer}>
        <Hidden only={['xs', 'sm']}>
          <img src={imgBackgroundLoginPage} alt="IntraIMIC" className={classes.backgroundImage} />
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.loginContainer}>
        <div className={classes.formContainer}>
          <Grid className={classes.logo}>
            <img src={imgLogo} alt="logo" />
          </Grid>

          <Typography component="h1" variant="h5" className={classes.loginTitle}>
            <span>Intra</span>IMIC
          </Typography>

          {page === 'auth' && (
            <LoginForm
              inputLoginLoginRef={inputLoginLoginRef}
              classes={classes}
              loading={loading}
              setLoading={setLoading}
              setOpenSnackbar={setOpenSnackbar}
              inputLoginPasswordRef={inputLoginPasswordRef}
              setSnackbarType={setSnackbarType}
              setSnackbarMsg={setSnackbarMsg}
              setIsOffline={setIsOffline}
              setAuthenticate={setAuthenticate}
              redirectPage={redirectPage}
            />
          )}
          {page === 'recovery' && (
            <RecoveryForm
              loading={loading}
              setLoading={setLoading}
              setOpenSnackbar={setOpenSnackbar}
              inputRecoveryLoginRef={inputRecoveryLoginRef}
              setSnackbarType={setSnackbarType}
              setSnackbarMsg={setSnackbarMsg}
              classes={classes}
            />
          )}
          {page === 'reset' && (
            <ResetForm
              loading={loading}
              setLoading={setLoading}
              setOpenSnackbar={setOpenSnackbar}
              inputResetPassword1Ref={inputResetPassword1Ref}
              inputResetPassword2Ref={inputResetPassword2Ref}
              setSnackbarType={setSnackbarType}
              setSnackbarMsg={setSnackbarMsg}
              setIsOffline={setIsOffline}
              redirectPage={redirectPage}
              classes={classes}
            />
          )}
        </div>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
