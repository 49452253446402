import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import axios, { Canceler } from 'axios';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import api from '../../services/api';

type Dayoff = {
  id: string;
  type: string;
  number: string;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  date_start: string;
  date_end: string;
  hours: string;
  date_now: string;
  minutes: string;
  hours_formated: string;
  minutes_formated: string;
  days_remaining: number;
};

function DayoffCard() {
  const [responseData, setResponseData] = useState<Dayoff[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const { data } = await api.get<Dayoff[]>('hcm/dayoffs', {
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setResponseData(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };
  useEffect(() => {
    getData();
  }, []);

  const Row = (props: { first?: boolean; date: string; hours: string; minutes: string }) => {
    const { first, date, hours, minutes } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '0.6rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            Data da folga
          </Typography>

          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              lineHeight: '1.3rem',
              color: theme => (theme.palette.mode === 'light' ? orange[800] : orange[300]),
            }}
          >
            {date}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '0.6rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            Total de horas
          </Typography>
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              lineHeight: '1.3rem',
              color: theme => (theme.palette.mode === 'dark' ? blue[300] : blue[700]),
            }}
          >
            {`${hours}:${minutes}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const FakeRow = (props: { first?: boolean }) => {
    const { first } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box>
          <Skeleton variant="rectangular" height="0.7rem" width={60} sx={{ borderRadius: 0.5, mb: 0.5 }} />
          <Skeleton variant="rectangular" height="1.1rem" width={100} sx={{ borderRadius: 0.5 }} />
        </Box>
        <Box>
          <Skeleton variant="rectangular" height="0.7rem" width={70} sx={{ borderRadius: 0.5, mb: 0.5 }} />
          <Skeleton variant="rectangular" height="1.1rem" width={50} sx={{ borderRadius: 0.5 }} />
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <MoreTimeIcon sx={{ color: theme => (theme.palette.mode === 'dark' ? blue[300] : blue[700]) }} />
          <Typography>Programação de Folgas</Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            borderEndStartRadius: 4,
            borderEndEndRadius: 4,
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {!loadingData ? (
            responseData?.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  mt: 1,
                  minHeight: 60,
                  color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                }}
              >
                <Typography>Nenhuma programação de folga encontrada</Typography>
              </Box>
            ) : (
              responseData?.map((dayoff, i) => {
                const { date_start, hours_formated, minutes_formated } = dayoff;
                const key = `${i}-${i}`;
                return (
                  <Row first={i === 0} key={key} date={date_start} hours={hours_formated} minutes={minutes_formated} />
                );
              })
            )
          ) : (
            <FakeRow first />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
export { DayoffCard };
