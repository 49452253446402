import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';
import styles from './styles';

export type FeedbackProps = {
  message: string;
  show: boolean;
  type?: FeedbackAlertType;
  durations?: number;
  onClose?: () => void;
};
export type FeedbackAlertType = 'success' | 'error' | 'info' | 'warning' | undefined;
export default function FeedbackAlert(props: FeedbackProps) {
  const { type = 'success', message = '', durations = 3000, show, onClose } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  function handleSnackbarClose(event?: SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') return;
    setOpen(false);
    onClose?.();
  }

  return (
    <Snackbar open={open} autoHideDuration={durations} onClose={handleSnackbarClose}>
      <Alert severity={type} onClose={handleSnackbarClose} closeText="Fechar">
        {message}
      </Alert>
    </Snackbar>
  );
}
