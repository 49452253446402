import React, { useEffect, useRef, useState } from 'react';

import { Button, TextField, Box } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';

import FeedbackAlert, { FeedbackAlertType } from '../../components/FeedbackAlert';
import HtmlEditor from '../../components/HtmlEditor';

export default function MessagesPage() {
  const editorRef = useRef<any>(null);
  const [loginText, setLoginText] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [typeFeedbackAlert, setTypeFeedbackAlert] = useState<FeedbackAlertType>('success');
  const [messageFeedbackAlert, setMessageFeedbackAlert] = useState('');
  const [showFeedbackAlert, setShowFeedbackAlert] = useState(false);

  return (
    <Box>
      <HtmlEditor />
      <FeedbackAlert message={messageFeedbackAlert} type={typeFeedbackAlert} show={showFeedbackAlert} />
    </Box>
  );
}
