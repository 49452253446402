import React, { useEffect, useState } from 'react';

import { Avatar, Box, IconButton, Grid, Paper, Typography, Tooltip } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  PersonRemove as DeleteIcon,
  PersonAdd as AddIcon,
  CheckCircleOutline as AccessAlowedIcon,
  Block as AccessDeniedIcon,
} from '@mui/icons-material';
import DeleteAllIcon from '@mui/icons-material/GroupRemove';
import AddAllIcon from '@mui/icons-material/GroupAdd';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import Axios, { Canceler } from 'axios';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import styles from './styles';
import { avatarUrl } from '../../../utils/functions';

type PermissionListType = {
  id: number;
  parent: number;
  position: number;
  type: string;
  title: string;
  icon: string;
  path: string;
  rule_id: number;
  situation: number;
  childrens: PermissionListType[];
};

type UserType = {
  id: string;
  nickname: string;
  sector: string;
  allowed: string;
};

export default function PermissionsPage() {
  const classes = styles();

  const [loadingListData, setLoadingListData] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [updatingUserData, setUpdatingUserData] = useState(false);
  const [list, setList] = useState<PermissionListType[]>([]);
  const [usersAllowed, setUsersAllowed] = useState<UserType[]>([]);
  const [usersDenied, setUsersDenied] = useState<UserType[]>([]);

  const [ruleSelected, setRuleSelected] = useState(0);

  async function getList() {
    let cancel: Canceler;
    try {
      setLoadingListData(true);

      const response = await api.get<PermissionListType[]>('intranet/permissions/rules', {
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      setList(response?.data);
    } catch (error: any) {}

    setLoadingListData(false);

    return () => cancel();
  }

  useEffect(() => {
    getList();
  }, []);

  async function getUsersList(rule_id = 0) {
    let cancel: Canceler;
    if (rule_id === 0) return () => cancel();
    setLoadingUserData(true);
    try {
      const params = {
        rule_id,
      };
      const response = await api.get<UserType[]>('intranet/permissions', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      const responseUsers = response?.data;
      setUsersAllowed(responseUsers?.filter(u => u.allowed === '1'));
      setUsersDenied(responseUsers?.filter(u => u.allowed === '0'));
    } catch (error: any) {}
    setLoadingUserData(false);

    return () => cancel();
  }

  useEffect(() => {
    getUsersList(ruleSelected);
    return () => {};
  }, [ruleSelected]);

  async function handleUpdateUserPermition(rule_id = 0, user_id = '0', allow = true) {
    let cancel: Canceler;
    if (rule_id === 0) return () => cancel();
    setUpdatingUserData(true);
    try {
      const response = await api.post<UserType[]>('intranet/permissions', {
        user_id,
        rule_id,
        allow,
        response: 'all',
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      const responseUsers = response?.data;
      setUsersAllowed(responseUsers?.filter(u => u.allowed === '1'));
      setUsersDenied(responseUsers?.filter(u => u.allowed === '0'));
    } catch (error: any) {}
    setUpdatingUserData(false);

    return () => cancel();
  }
  async function handleUpdateAllUserPermition(rule_id = 0, allow = true) {
    let cancel: Canceler;
    if (rule_id === 0) return () => cancel();
    setUpdatingUserData(true);
    try {
      const response = await api.post<UserType[]>('intranet/permissions', {
        rule_id,
        allow,
        response: 'all',
        all: true,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      const responseUsers = response?.data;
      setUsersAllowed(responseUsers?.filter(u => u.allowed === '1'));
      setUsersDenied(responseUsers?.filter(u => u.allowed === '0'));
    } catch (error: any) {}
    setUpdatingUserData(false);

    return () => cancel();
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.itemsContainer}>
            <Box className={classes.userListContainer}>
              {updatingUserData && (
                <Box className={classes.overlayUpdating}>
                  <Typography>Atualizado...</Typography>
                </Box>
              )}
              {loadingListData ? (
                <Box className={classes.loadingListContainer}>
                  <Typography className={classes.loadingText}>Carregando...</Typography>
                </Box>
              ) : (
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {list?.map(item => {
                    if (item.type === 'folder') {
                      return (
                        <TreeItem
                          key={item.id}
                          nodeId={item.id.toString()}
                          label={item.title}
                          onClick={() => {
                            setRuleSelected(item.rule_id);
                          }}
                        >
                          {item?.childrens?.map(children_1 => {
                            return (
                              <TreeItem
                                key={children_1.id}
                                nodeId={children_1.id.toString()}
                                label={children_1.title}
                                onClick={() => {
                                  setRuleSelected(children_1.rule_id);
                                }}
                              >
                                {children_1?.childrens?.map(children_2 => {
                                  return (
                                    <TreeItem
                                      key={children_2.id}
                                      nodeId={children_2.id.toString()}
                                      label={children_2.title}
                                      onClick={() => {
                                        setRuleSelected(children_2.rule_id);
                                      }}
                                    >
                                      {children_2?.childrens?.map(children_3 => {
                                        return (
                                          <TreeItem
                                            key={children_3.id}
                                            nodeId={children_3.id.toString()}
                                            label={children_3.title}
                                            onClick={() => {
                                              setRuleSelected(children_3.rule_id);
                                            }}
                                          >
                                            {children_3?.childrens?.map(children_4 => {
                                              return (
                                                <TreeItem
                                                  key={children_4.id}
                                                  nodeId={children_4.id.toString()}
                                                  label={children_4.title}
                                                  onClick={() => {
                                                    setRuleSelected(children_4.rule_id);
                                                  }}
                                                />
                                              );
                                            })}
                                          </TreeItem>
                                        );
                                      })}
                                    </TreeItem>
                                  );
                                })}
                              </TreeItem>
                            );
                          })}
                        </TreeItem>
                      );
                    }
                    return (
                      <TreeItem
                        key={item.id}
                        nodeId={item.id.toString()}
                        label={item.title}
                        onClick={() => {
                          setRuleSelected(item.rule_id);
                        }}
                      />
                    );
                  })}
                </TreeView>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper className={classes.userListRoot}>
            <Box className={`${classes.titleContainer} ${classes.titleGreen}`}>
              <AccessAlowedIcon />
              <Typography>Acesso Permitido</Typography>
              <Box display="flex" justifyContent="flex-end" flex={1}>
                <Tooltip title={usersAllowed.length === 0 ? '' : 'Remover todos os usuários mostrados'}>
                  <IconButton
                    onClick={() => {
                      handleUpdateAllUserPermition(ruleSelected, false);
                    }}
                    disabled={usersAllowed.length === 0 || updatingUserData}
                    color="secondary"
                    size="small"
                  >
                    <DeleteAllIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className={classes.userListContainer}>
              {updatingUserData && (
                <Box className={classes.overlayUpdating}>
                  <Typography>Atualizado...</Typography>
                </Box>
              )}
              {ruleSelected === 0 ? (
                <Box className={classes.loadingListContainer}>
                  <Typography className={classes.loadingText}>Nenhuma permissão selecionada</Typography>
                </Box>
              ) : loadingUserData ? (
                <Box className={classes.loadingListContainer}>
                  <Typography className={classes.loadingText}>Carregando...</Typography>
                </Box>
              ) : (
                usersAllowed.map(user => {
                  return (
                    <Box className={classes.userListItem} key={user.id}>
                      <Box className={classes.avatarContainer}>
                        <Link
                          to={`/profile/${user.id}`}
                          className={classes.avatarLink}
                          style={{ textDecoration: 'none' }}
                        >
                          <Avatar
                            alt={`${user.nickname}`}
                            src={avatarUrl(user.id, true)}
                            aria-label={`${user.nickname.toLowerCase()}`}
                            className={classes.avatarComponent}
                          />
                        </Link>
                      </Box>
                      <Box className={classes.descriptionContainer}>
                        <Typography className={classes.descriptionName}>{user.nickname}</Typography>
                        <Typography className={classes.descriptionSector}>{user.sector}</Typography>
                      </Box>
                      <Box
                        className={`${classes.buttonContainer} ${classes.redButton}`}
                        onClick={() => {
                          handleUpdateUserPermition(ruleSelected, user.id, false);
                        }}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  );
                })
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper className={classes.userListRoot}>
            <Box className={`${classes.titleContainer} ${classes.titleRed}`}>
              <AccessDeniedIcon />
              <Typography>Acesso Negado</Typography>
              <Box display="flex" justifyContent="flex-end" flex={1}>
                <Tooltip title={usersDenied.length === 0 ? '' : 'Adicionar todos os usuários mostrados'}>
                  <IconButton
                    onClick={() => {
                      handleUpdateAllUserPermition(ruleSelected, true);
                    }}
                    disabled={usersDenied.length === 0 || updatingUserData}
                    color="success"
                    size="small"
                  >
                    <AddAllIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className={classes.userListContainer}>
              {updatingUserData && (
                <Box className={classes.overlayUpdating}>
                  <Typography>Atualizado...</Typography>
                </Box>
              )}
              {ruleSelected === 0 ? (
                <Box className={classes.loadingListContainer}>
                  <Typography className={classes.loadingText}>Nenhuma permissão selecionada</Typography>
                </Box>
              ) : loadingUserData ? (
                <Box className={classes.loadingListContainer}>
                  <Typography className={classes.loadingText}>Carregando...</Typography>
                </Box>
              ) : (
                usersDenied.map(user => {
                  return (
                    <Box className={classes.userListItem} key={user.id}>
                      <Box
                        className={`${classes.buttonContainer} ${classes.greenButton}`}
                        onClick={() => {
                          handleUpdateUserPermition(ruleSelected, user.id, true);
                        }}
                      >
                        <AddIcon />
                      </Box>
                      <Box className={classes.avatarContainer}>
                        <Link
                          to={`/profile/${user.id}`}
                          className={classes.avatarLink}
                          style={{ textDecoration: 'none' }}
                        >
                          <Avatar
                            alt={`${user.nickname}`}
                            src={avatarUrl(user.id, true)}
                            aria-label={`${user.nickname.toLowerCase()}`}
                            className={classes.avatarComponent}
                          />
                        </Link>
                      </Box>
                      <Box className={classes.descriptionContainer}>
                        <Typography className={classes.descriptionName}>{user.nickname}</Typography>
                        <Typography className={classes.descriptionSector}>{user.sector}</Typography>
                      </Box>
                    </Box>
                  );
                })
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
