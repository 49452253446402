import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  id: string;
}

export default function DownloadPage() {
  return <></>;
}
