import Home from './home';
import View from './view';

export default {
  Home,
  View,
};

type EngOsSituationType =
  | 'canceled'
  | 'canceled_late'
  | 'ended'
  | 'ended_late'
  | 'not_started'
  | 'not_started_late'
  | 'paused'
  | 'paused_late'
  | 'started'
  | 'started_late'
  | 'undefined';

const descriptionList = {
  canceled: 'Cancelado',
  canceled_late: 'Cancelado (Atraso)',
  ended: 'Finalizado',
  ended_late: 'Finalizado (Atraso)',
  not_started: 'Não Iniciado',
  not_started_late: 'Não Iniciado (Atraso)',
  paused: 'Parado',
  paused_late: 'Parado (Atraso)',
  started: 'Andamento',
  started_late: 'Andamento (Atraso)',
};

const engOsSituationDescription = (situation: EngOsSituationType | string) => {
  if (descriptionList[situation.toString().toLowerCase()]) return descriptionList[situation.toString().toLowerCase()];
  return 'Situação inválida';
};

export { descriptionList, engOsSituationDescription };
export type { EngOsSituationType };
