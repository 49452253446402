import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paginationRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    newsTitle: {
      textDecoration: 'none',
      color: darkMode ? '#fafafa' : '#383838',
      '&:hover': {
        color: darkMode ? '#b1b1b1' : '#808080',
      },
    },
    newsTitleViewed: {
      textDecoration: 'none',
      color: darkMode ? '#b1b1b1' : '#808080',

      '&:hover': {
        color: darkMode ? '#fafafa' : '#383838',
      },
    },

    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    iconFilterResult: {
      marginLeft: '4px',
      marginRight: '4px',
      color: darkMode ? deepPurple[300] : deepPurple[700],
    },
    filterText: {
      color: darkMode ? '#e57373' : '#e53935',
      fontWeight: 'bold',
      '& .MuiSelect-selectMenu, input': {
        color: darkMode ? '#e57373' : '#e53935',
        fontWeight: 'bold',
      },
    },
  });
});
