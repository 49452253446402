import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  MenuItem,
  Paper,
  Backdrop,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  Badge,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Icon,
  TableContainer,
  FormControlLabel,
  Switch,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import imageCompression from 'browser-image-compression';
// import DOMPurify from 'dompurify';

import { Send as SendIcon } from '@mui/icons-material';
import { FileIcon } from 'react-file-icon';

import moment from 'moment';
import 'moment/locale/pt-br';
import { Alert, Rating } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ForumIcon from '@mui/icons-material/Forum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GradeIcon from '@mui/icons-material/Grade';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Axios, { Canceler } from 'axios';
import Picker from 'emoji-picker-react';
import HtmlEditor from '../../../components/HtmlEditor';

import ProfileTooltip from '../../../components/ProfileTooltip';
import notFoundLightImg from '../../../images/not_found_light.svg';
import notFoundImg from '../../../images/not_found.svg';
import notFoundStyles from '../../templates/not_found/styles';
import {
  Helpdesk,
  HelpdeskAttachment,
  HelpdeskAttendant,
  HelpdeskLink,
  HelpdeskPost,
  IconTypeName,
} from '../dashboard';
import { humanFileSize, toNumber } from '../../../utils/functions';
import api from '../../../services/api';
import styles from './styles';
import Comment from '../../../components/Comment';

moment.locale('pt-br');
moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });

export type HelpdeskCategoryType = {
  description: string;
  id: number;
  situation: number;
};

export type HelpdeskSubcategoryType = {
  days: number;
  deadline_formated: string;
  description: string;
  id: number;
  private: number;
  situation: number;
};

export type HelpdeskAttendantType = {
  target_id: number;
  attendants_id: number;
  attendants_name: string;
  type: number;
  situation: number;
};

export default function HelpdeskViewPage(props: any) {
  const classesNf = notFoundStyles();
  const { darkMode } = props as { darkMode: boolean };
  const classes = styles();
  const { id } = useParams() as any;

  const refInputAttachment = useRef<HTMLInputElement | null>(null);

  const [gettingHelpdeskData, setGettingHelpdeskData] = useState<boolean>(false);
  const [updatingHelpdeskPostView, setUpdatingHelpdeskPostView] = useState<boolean>(false);

  const [readedPosts, setReadedPosts] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);

  const [helpdeskData, setHelpdeskData] = useState<Helpdesk>({} as Helpdesk);
  const [helpdeskPosts, setHelpdeskPosts] = useState<HelpdeskPost[]>([] as HelpdeskPost[]);
  const [postingExtendAnswer, setPostingExtendAnswer] = useState(false);

  const [invalidHelpdesk, setInvalidHelpdesk] = useState(false);
  const [openGetHelpdeskDialog, setOpenGetHelpdeskDialog] = useState(false);
  const [openEndHelpdeskDialog, setOpenEndHelpdeskDialog] = useState(false);
  const [openRateHelpdeskDialog, setOpenRateHelpdeskDialog] = useState(false);
  const [openOwnerRateHelpdeskDialog, setOpenOwnerRateHelpdeskDialog] = useState(false);
  const [openExtendHelpdeskDialog, setOpenExtendHelpdeskDialog] = useState(false);

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  // Get Helpdesk Dialog
  const [categorys, setCategory] = useState<HelpdeskCategoryType[]>([]);
  const [subcategorys, setSubcategory] = useState<HelpdeskSubcategoryType[]>([]);
  const [attendants, setAttendants] = useState<HelpdeskAttendantType[]>([]);
  const [gettingAttendants, setGettingAttendants] = useState<boolean>(false);
  const [gettingCategory, setGettingCategory] = useState<boolean>(false);
  const [gettingSubcategory, setGettingSubcategory] = useState<boolean>(false);
  const [helpdeskAttendant, setHelpdeskAttendant] = useState<number>(-1);
  const [helpdeskCategory, setHelpdeskCategory] = useState<number>(-1);
  const [helpdeskSubcategory, setHelpdeskSubcategory] = useState<number>(-1);
  const [helpdeskDays, setHelpdeskDays] = useState<number>(-1);
  const [enableCategorys, setEnableCategorys] = useState<boolean>(false);
  const [enableSubcategorys, setEnableSubcategorys] = useState<boolean>(false);
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [updatingHelpdesk, setUpdatingHelpdesk] = useState<boolean>(false);

  const [selectEmoji, setSelectEmoji] = useState<boolean>(false);
  const [recidivismChecked, setRecidivismChecked] = useState(false);

  const [helpdeskOwnerRate, setHelpdeskOwnerRate] = useState<number | null>(0);
  const [helpdeskOwnerRateFeedback, setHelpdeskRateOwnerFeedback] = useState<string>('');

  const handleHelpdeskOwnerRateFeedbackChange = (newText: string) => {
    setHelpdeskRateOwnerFeedback(newText);
  };

  const handleRecidivismChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecidivismChecked(event.target.checked);
  };

  const getCategory = (targetId = 0) => {
    let cancel: Canceler;
    if (targetId < 1) return;
    if (!gettingCategory) {
      setGettingCategory(true);
      setHelpdeskCategory(0);
      setHelpdeskSubcategory(0);
      setHelpdeskDays(0);
      api
        .get(`helpdesk/category?target_id=${targetId}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setCategory(response.data);
          setHelpdeskCategory(0);
          setHelpdeskSubcategory(0);
          setEnableCategorys(response.data?.length > 0);
          setEnableSubcategorys(false);
          setGettingCategory(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingCategory(false);
        });
    }
    return () => cancel();
  };

  const getSubcategory = (categoryId = 0) => {
    let cancel: Canceler;
    if (categoryId < 1) return;
    if (!gettingSubcategory) {
      setGettingSubcategory(true);
      setHelpdeskSubcategory(0);
      setHelpdeskDays(0);
      api
        .get(`helpdesk/subcategory?category_id=${categoryId}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setSubcategory(response?.data);
          setHelpdeskSubcategory(0);
          setEnableSubcategorys(response?.data?.length > 0);
          setGettingSubcategory(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingSubcategory(false);
        });
    }
    return () => cancel();
  };

  const getHelpdeskData = async ({ postsOnly = false } = {}) => {
    let cancel: Canceler;
    if (postsOnly) {
      const response = await api.get(`helpdesk/?id=${id}`, {
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      const helpdesk = response?.data?.data?.[0] as Helpdesk;
      if (helpdesk) {
        setHelpdeskPosts(helpdesk.posts);
      }
    } else if (!gettingHelpdeskData) {
      setGettingHelpdeskData(true);
      api
        .get(`helpdesk/?id=${id}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          if (response.data.data?.length > 0) {
            const helpdesk = response.data.data[0] as Helpdesk;
            setHelpdeskData(helpdesk);
            setHelpdeskPosts(helpdesk.posts);
          } else {
            setInvalidHelpdesk(true);
          }
          setGettingHelpdeskData(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingHelpdeskData(false);
        });
    }
    return () => cancel();
  };

  const getAttendants = () => {
    let cancel: Canceler;
    if (!gettingAttendants) {
      setGettingAttendants(true);
      api
        .get(`helpdesk/attendants?target=${helpdeskData?.target}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setAttendants(response.data);
          setGettingAttendants(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingAttendants(false);
        });
    }
    return () => cancel();
  };

  useEffect(() => {
    getHelpdeskData({});
  }, [id]);

  useEffect(() => {
    if (helpdeskData?.category < 1) {
      getCategory(helpdeskData?.target);
    } else {
      setHelpdeskCategory(helpdeskData?.category || -1);
      setHelpdeskSubcategory(helpdeskData?.subcategory || -1);
      setHelpdeskAttendant(helpdeskData?.attendance || -1);
    }

    if (helpdeskData?.target < 0) {
    }
    getAttendants();
  }, [helpdeskData]);

  useEffect(() => {
    if (openGetHelpdeskDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openGetHelpdeskDialog]);

  const updateGetHelpdesk = ({
    action = '',
    helpdeskId = 0,
    attendance = 0,
    target = 0,
    category = 0,
    subcategory = 0,
    feedback = '',
    feedback_delay = '',
  }) => {
    if (!updatingHelpdesk) {
      setUpdatingHelpdesk(true);
      const params = {
        action,
        helpdesk_id: helpdeskId,
        attendance,
        target,
        category,
        subcategory,
        feedback,
        feedback_delay,
      };
      api
        .post(`helpdesk/update`, params)
        .then((response: any) => {
          setUpdatingHelpdesk(false);
          if (response?.data?.message?.length > 5) {
            let msg = response?.data?.message;
            if (msg === 'defined_attendant') msg = 'O Atendente foi definido com sucesso.';
            else if (msg === 'transferred_attendant') msg = 'Atendente transferido com sucesso.';

            setOpenGetHelpdeskDialog(false);
            getHelpdeskData();
            setSnackbarType('success');
            setSnackbarMsg(msg);
            setOpenSnackbar(true);
          }
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'helpdesk_id_invalid') msg = 'Numero do Helpdesk Inválido.';
          else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
          else if (msg === 'access_denied') msg = 'Acesso negado.';
          else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
          else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'category_invalid') msg = 'Categoria inválida.';
          else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
          else if (msg === 'invalid_helpdesk_rate') msg = 'É necessário informar a avaliação do solicitante';
          else if (msg === 'cannot_change_the_category')
            msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdesk(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const handleInputAttendant = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.value);
    setHelpdeskAttendant(id);
  };

  const handleInputCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.value);
    setHelpdeskCategory(id);
    getSubcategory(id);
  };

  const handleInputSubcategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.value);
    const days = subcategorys?.filter((c: any) => c.id === id)[0]?.days | 1;
    setHelpdeskDays(days);
    setHelpdeskSubcategory(Number(event.target.value));
  };

  const handleUpdateGetHelpdesk = (
    helpdeskId: number,
    attendance: number,
    target: number,
    category: number,
    subcategory: number,
  ) => {
    updateGetHelpdesk({ action: 'attendant', helpdeskId, attendance, target, category, subcategory });
  };
  // --------------------------------

  // Post Comment
  const [newComment, setNewComment] = useState('');
  const [postingComment, setPostingComment] = useState(false);
  const [attachmentsPostComment, setAttachmentsPostComment] = useState<File[]>([]);

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachmentsPostComment;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        for await (const attachment of event.target.files) {
          const type = attachment.type.split('/')[0];
          if (type === 'image') {
            const compressedFile = (await imageCompression(attachment, options)) as File;
            newAttachments.push(new File([compressedFile], compressedFile.name));
          } else {
            newAttachments.push(attachment);
          }
        }
      } catch (error) {
        console.error(error);
      }

      setAttachmentsPostComment([...newAttachments]);
    }
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachmentsPostComment;
    newAttachments.splice(index, 1);
    setAttachmentsPostComment([...newAttachments]);
  };

  const handleSendMessage = () => {
    if (newComment?.length === 0) {
      return;
    }
    if (!postingComment) {
      const data = new FormData();
      data.append('helpdesk_id', id?.toString());
      data.append('text', newComment);

      for (let i = 0; i < attachmentsPostComment?.length; i++) {
        data.append('files', attachmentsPostComment[i]);
      }

      setPostingComment(true);

      api
        .post(`helpdesk/posts`, data)
        .then((response: any) => {
          setNewComment('');
          setAttachmentsPostComment([]);
          setHelpdeskPosts(response.data);
          setPostingComment(false);
        })
        .catch((error: any) => {
          console.error(error);
          setPostingComment(false);
        });
    }
  };

  const handleNewCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  // --------------------------------------------------------------

  // End Helpdesk Dialog
  const [dialogAction, setDialogAction] = useState('end' as 'end' | 'cancel');
  const [helpdeskFeedback, setHelpdeskFeedback] = useState<string>('');
  const [helpdeskFeedbackDelay, setHelpdeskFeedbackDelay] = useState<string>('');
  const [disableButton, setDisableButton] = useState<boolean>(true);

  useEffect(() => {
    if (openEndHelpdeskDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openEndHelpdeskDialog]);

  const updateEndHelpdesk = ({
    feedback = '',
    feedback_delay = '',
    helpdesk_feedback = '',
    helpdesk_rate = '',
    recidivism = 0,
  }) => {
    if (!updatingHelpdesk) {
      setUpdatingHelpdesk(true);
      const params = {
        action: dialogAction,
        helpdesk_id: id,
        feedback,
        feedback_delay,
        helpdesk_feedback,
        helpdesk_rate,
        recidivism,
      };
      api
        .post(`helpdesk/update`, params)
        .then((response: any) => {
          setUpdatingHelpdesk(false);

          let msg = response?.data?.message;
          if (msg === 'success')
            msg = dialogAction === 'end' ? 'Helpdesk finalizado com sucesso.' : 'Helpdesk cancelado com sucesso.';
          else if (msg === '') msg = '';

          setSnackbarType('success');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);

          setOpenEndHelpdeskDialog(false);
          getHelpdeskData({});
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'empty_feedback')
            msg =
              dialogAction === 'end'
                ? 'É necessário informar uma solução'
                : 'É necessário informar o motivo do cancelamento';
          else if (msg === 'empty_feedback_delay') msg = 'É necessário informar uma justificativa de atraso';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
          else if (msg === 'access_denied') msg = 'Acesso negado.';
          else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
          else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'category_invalid') msg = 'Categoria inválida.';
          else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
          else if (msg === 'invalid_helpdesk_rate') msg = 'É necessário informar a avaliação do solicitante';
          else if (msg === 'cannot_change_the_category')
            msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
          else if (msg === '') msg = '';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdesk(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const handleFeedbackChange = (newText: string) => {
    setHelpdeskFeedback(newText);
    if (newText.replace(/(<([^>]+)>)/gi, '')?.length === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const handleFeedbackDelayChange = (newText: string) => {
    setHelpdeskFeedbackDelay(newText);
    if (newText.replace(/(<([^>]+)>)/gi, '')?.length === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const handleHelpdeskOwnerRateChange = (newRate: number) => {
    setHelpdeskOwnerRate(newRate);
    if (newRate > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleUpdateEndHelpdesk = () => {
    updateEndHelpdesk({
      feedback: helpdeskFeedback,
      feedback_delay: helpdeskFeedbackDelay,
      helpdesk_feedback: helpdeskOwnerRateFeedback,
      helpdesk_rate: helpdeskOwnerRate.toString(),
      recidivism: recidivismChecked ? 1 : 0,
    });
  };

  // --------------------------------------------------------------
  const updateOwnerRateHelpdesk = ({ helpdesk_feedback = '', helpdesk_rate = 0, recidivism = 0 }) => {
    if (!updatingHelpdesk) {
      setUpdatingHelpdesk(true);
      const params = {
        action: 'rate_owner',
        helpdesk_id: id,
        helpdesk_feedback,
        helpdesk_rate,
        recidivism,
      };
      api
        .post(`helpdesk/update`, params)
        .then((response: any) => {
          setUpdatingHelpdesk(false);

          let msg = response?.data?.message;
          if (msg === 'success') msg = 'Solicitante avaliado com sucesso.';
          else if (msg === '') msg = 'Sucesso';

          setSnackbarType('success');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);

          setOpenRateHelpdeskDialog(false);
          getHelpdeskData({});
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'invalid_rate') msg = 'É necessário uma avaliação válida';
          else if (msg === 'has_already_been_rated') msg = 'Solicitante já avaliado';
          else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
          else if (msg === 'access_denied') msg = 'Acesso negado.';
          else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
          else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'category_invalid') msg = 'Categoria inválida.';
          else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
          else if (msg === 'invalid_helpdesk_rate') msg = 'É necessário informar a avaliação do solicitante';
          else if (msg === 'cannot_change_the_category')
            msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
          else if (msg === '') msg = 'Erro desconhecido';
          else if (msg === 'need_feedback')
            msg = 'Para avaliação de 3 estrelas ou menor é necessário informar o motivo.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdesk(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  // Rate Helpdesk Dialog
  const [helpdeskRate, setHelpdeskRate] = useState<number | null>(0);
  const [helpdeskRateFeedback, setHelpdeskRateFeedback] = useState<string>('');

  const updateRateHelpdesk = ({ rate_feedback = '', rate = 0 }) => {
    if (!updatingHelpdesk) {
      setUpdatingHelpdesk(true);
      const params = {
        action: 'rate',
        helpdesk_id: id,
        rate,
        rate_feedback,
      };
      api
        .post(`helpdesk/update`, params)
        .then((response: any) => {
          setUpdatingHelpdesk(false);

          let msg = response?.data?.message;
          if (msg === 'success') msg = 'Helpdesk avaliado com sucesso.';
          else if (msg === '') msg = 'Sucesso';

          setSnackbarType('success');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);

          setOpenRateHelpdeskDialog(false);
          getHelpdeskData({});
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'invalid_rate') msg = 'É necessário uma avaliação válida';
          else if (msg === 'has_already_been_rated') msg = 'Helpdesk já avaliado';
          else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
          else if (msg === 'access_denied') msg = 'Acesso negado.';
          else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
          else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'category_invalid') msg = 'Categoria inválida.';
          else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
          else if (msg === 'invalid_helpdesk_rate') msg = 'É necessário informar a avaliação do solicitante';
          else if (msg === 'cannot_change_the_category')
            msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
          else if (msg === '') msg = 'Erro desconhecido';
          else if (msg === 'need_feedback')
            msg = 'Para avaliação de 3 estrelas ou menor é necessário informar o motivo.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdesk(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const handleRateChange = (newRate: number) => {
    setHelpdeskRate(newRate);
    if (newRate > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleRateFeedbackChange = (newText: string) => {
    setHelpdeskRateFeedback(newText);
  };

  const handleUpdateRateHelpdesk = () => {
    updateRateHelpdesk({ rate_feedback: helpdeskRateFeedback, rate: helpdeskRate || 0 });
  };
  const handleUpdateOwnerRateHelpdesk = () => {
    updateOwnerRateHelpdesk({
      helpdesk_feedback: helpdeskOwnerRateFeedback,
      helpdesk_rate: helpdeskOwnerRate,
      recidivism: recidivismChecked ? 1 : 0,
    });
  };

  // --------------------------------------------------------------

  //  Extend Helpdesk Dialog
  const [extendDays, setExtendDays] = useState<number | null>(0);
  const [extendText, setExtendText] = useState<string>('');

  const updateExtendHelpdesk = ({ extend_days = 0, extend_text = '' }) => {
    if (!updatingHelpdesk) {
      if (extend_days < 1 || extend_text?.length < 4) {
        let msg = 'É necessário informar a quantidade de dias';
        if (extend_text?.length < 4) msg = 'Texto muito curto';
        setUpdatingHelpdesk(false);
        setSnackbarType('error');
        setSnackbarMsg(msg);
        setOpenSnackbar(true);
      }
      setUpdatingHelpdesk(true);

      const params = {
        action: 'extend_request',
        helpdesk_id: id,
        extend_days,
        extend_text,
      };
      api
        .post(`helpdesk/update`, params)
        .then((response: any) => {
          setUpdatingHelpdesk(false);

          let msg = response?.data?.message;
          if (msg === 'success') msg = 'Solicitação enviada com sucesso';
          else if (msg === '') msg = 'Sucesso';

          setSnackbarType('success');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);

          setOpenExtendHelpdeskDialog(false);
          getHelpdeskData({});
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'invalid_rate') msg = 'É necessário uma avaliação válida';
          else if (msg === 'has_already_been_rated') msg = 'Helpdesk já avaliado';
          else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
          else if (msg === 'access_denied') msg = 'Acesso negado.';
          else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
          else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
          else if (msg === 'target_invalid') msg = 'Setor inválido.';
          else if (msg === 'category_invalid') msg = 'Categoria inválida.';
          else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
          else if (msg === 'cannot_change_the_category')
            msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
          else if (msg === 'extend_days_invalid') msg = 'É necessário informar a quantidade de dias válida';
          else if (msg === 'extend_text_invalid') msg = 'Texto muito curto';
          else if (msg === 'already_have_a_request')
            msg = 'Já existe uma solicitação em andamento, aguarde o retorno do usuário';
          else if (msg === '') msg = 'Erro desconhecido';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdesk(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };
  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value);
    setExtendDays(newValue);
    if (newValue > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };
  const handleTextChange = (newText: string) => {
    setExtendText(newText);
  };

  const handleUpdateHelpdesk = () => {
    updateExtendHelpdesk({ extend_days: extendDays || 0, extend_text: extendText });
  };
  // --------------------------------------------------------------

  async function handleOpenAttachment(path: string) {
    if (!openAttachment) {
      setOpenAttachment(true);
      await api.post(`helpdesk/attachment/open`, { url: path });
      setOpenAttachment(false);
    }
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleInputTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.value);
  };

  const handleClickOpenGetHelpdeskDialog = () => {
    getAttendants();
    setOpenGetHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleCloseGetHelpdeskDialog = () => {
    setOpenGetHelpdeskDialog(false);
  };

  const handleCloseRateHelpdeskDialog = () => {
    setOpenRateHelpdeskDialog(false);
  };

  const handleCloseOwnerRateHelpdeskDialog = () => {
    setOpenOwnerRateHelpdeskDialog(false);
  };

  const handleCloseExtendHelpdeskDialog = () => {
    setOpenExtendHelpdeskDialog(false);
  };

  const handleClickOpenExtendHelpdeskDialog = () => {
    setOpenExtendHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleClickOpenEndHelpdeskDialog = () => {
    setDialogAction('end');
    setOpenEndHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleCloseEndHelpdeskDialog = () => {
    setOpenEndHelpdeskDialog(false);
    setOpenSnackbar(false);
  };

  const handleClickOpenCancelHelpdeskDialog = () => {
    setDialogAction('cancel');
    setOpenEndHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleClickOpenRateHelpdeskDialog = () => {
    setOpenRateHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleClickOpenOwnerRateHelpdeskDialog = () => {
    setOpenOwnerRateHelpdeskDialog(true);
    setOpenSnackbar(false);
  };

  const handleCloseCancelHelpdeskDialog = () => {
    setOpenEndHelpdeskDialog(false);
    setOpenSnackbar(false);
  };

  const handleHelpdeskPostView = () => {
    if (!readedPosts && !updatingHelpdeskPostView && helpdeskData?.posts_unreaded > 0 && helpdeskData?.situation < 2) {
      setUpdatingHelpdeskPostView(true);
      const params = {
        helpdesk_id: helpdeskData?.id,
      };
      api
        .post(`helpdesk/posts/read`, params)
        .then((response: any) => {
          setUpdatingHelpdeskPostView(false);
          setReadedPosts(true);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'invalid_rate') msg = 'É necessário uma avaliação válida';
          else if (msg === '') msg = 'Erro desconhecido';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingHelpdeskPostView(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const handleExtendAnswer = (answer: number, post_id) => {
    setPostingExtendAnswer(true);

    const params = {
      action: 'extend_answer',
      helpdesk_id: helpdeskData?.id,
      post_id,
      extend_answer: answer,
    };
    api
      .post(`helpdesk/update`, params)
      .then((response: any) => {
        setPostingExtendAnswer(false);
        if (response?.data?.message?.length > 5) {
          getHelpdeskData({});
          setSnackbarType('success');
          setSnackbarMsg('Resposta enviada com sucesso');
          setOpenSnackbar(true);
        }
      })
      .catch((error: any) => {
        let msg = error?.response?.data?.message;

        if (msg === 'helpdesk_id_invalid') msg = 'Numero do Helpdesk Inválido.';
        else if (msg === 'helpdesk_not_found') msg = 'Helpdesk não encontrado.';
        else if (msg === 'access_denied') msg = 'Acesso negado.';
        else if (msg === 'helpdesk_finalized') msg = 'Helpdesk finalizado.';
        else if (msg === 'attendance_invalid') msg = 'Atendente inválido.';
        else if (msg === 'target_invalid') msg = 'Setor inválido.';
        else if (msg === 'category_invalid') msg = 'Categoria inválida.';
        else if (msg === 'subcategory_invalid') msg = 'Subcategoria inválida.';
        else if (msg === 'cannot_change_the_category')
          msg = 'Não é possivel alterar a categoria depois que foi definido um atentende.';
        else
          msg = `Erro desconhecido: ${
            error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
          }`;

        setPostingExtendAnswer(false);
        setSnackbarType('error');
        setSnackbarMsg(msg);
        setOpenSnackbar(true);
      });
  };

  if (gettingHelpdeskData) {
    return (
      <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
        <Typography variant="caption" className={classes.loadingInitialDataText}>
          Carregando...
        </Typography>
      </Backdrop>
    );
  }

  if (invalidHelpdesk) {
    return (
      <div className={classesNf.root}>
        <img src={darkMode ? notFoundImg : notFoundLightImg} alt="404" className={classesNf.img} />
        <div className={classesNf.content}>
          <Typography variant="h6" className={classesNf.text}>
            O Helpdesk Nº{id} não foi encontrado.
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <Box>
      <Box className={classes.root}>
        {false && (
          <Paper className={classes.actionsRoot}>
            <Box>
              <Tooltip title="Novo Helpdesk" aria-label="add-helpdesk">
                <Button color="primary" variant="contained" component={Link} to="/helpdesk/add">
                  NOVO
                </Button>
              </Tooltip>
            </Box>
            <Box ml={2}>
              <Tooltip title="Consultar Helpdesk's" aria-label="view-helpdesk">
                <Button color="primary" variant="contained" component={Link} to="/helpdesk/search">
                  CONSULTAR
                </Button>
              </Tooltip>
            </Box>
            <Box flexGrow={1} />
            {helpdeskData?.im_attendant && (
              <Box>
                <Tooltip title="Atendimento aos Helpdesk's" aria-label="attendance-helpdesk">
                  <Button color="secondary" variant="contained" component={Link} to="/helpdesk/attendance">
                    ATENDIMENTO
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Paper>
        )}

        {helpdeskData?.situation <= 1 && (helpdeskData?.im_owner || helpdeskData?.im_can_attendant) && (
          <>
            <Box className={classes.boxContainer} style={{ paddingBottom: 0 }}>
              <Typography className={classes.paperTitle}>AÇÕES</Typography>
            </Box>
            <Paper className={classes.actionsRoot} style={{ marginTop: 8 }}>
              {helpdeskData?.im_attendant && (
                <Tooltip title="Todos os Helpdesk's para atendimento" aria-label="attendance-helpdesk">
                  <Button color="secondary" variant="outlined" component={Link} to="/helpdesk/attendance">
                    ATENDIMENTO
                  </Button>
                </Tooltip>
              )}
              {helpdeskData?.im_can_attendant && (
                <Tooltip title="Realizar o atendimento do Helpdesk" aria-label="save-helpdesk">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={false}
                    onClick={handleClickOpenGetHelpdeskDialog}
                  >
                    {false ? <CircularProgress size={24} /> : 'ATENDENTE'}
                  </Button>
                </Tooltip>
              )}

              {helpdeskData?.situation === 1 && helpdeskData?.im_attendant && (
                <Tooltip title="Finalizar o atendimento do Helpdesk" aria-label="save-helpdesk">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={false}
                    onClick={handleClickOpenEndHelpdeskDialog}
                  >
                    {false ? <CircularProgress size={24} /> : 'FINALIZAR'}
                  </Button>
                </Tooltip>
              )}
              {helpdeskData?.situation <= 1 && helpdeskData?.im_attendant && (
                <Tooltip title="Solicitar um novo prazo de atendimento do Helpdesk" aria-label="extend-helpdesk">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={false}
                    onClick={handleClickOpenExtendHelpdeskDialog}
                  >
                    {false ? <CircularProgress size={24} /> : 'PRAZO'}
                  </Button>
                </Tooltip>
              )}
              {(helpdeskData?.im_attendant || helpdeskData?.im_owner) && (
                <Tooltip title="Cancelar o Helpdesk" aria-label="save-helpdesk">
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={false}
                    onClick={handleClickOpenCancelHelpdeskDialog}
                  >
                    {false ? <CircularProgress size={24} /> : 'CANCELAR'}
                  </Button>
                </Tooltip>
              )}
            </Paper>
          </>
        )}
        <Box className={classes.actionsRoot}>
          {helpdeskData?.situation > 1 && helpdeskData?.im_owner && helpdeskData?.rate < 1 && (
            <Tooltip title="Avaliar o atendimento do Helpdesk" aria-label="rate-helpdesk">
              <Button
                color="primary"
                variant="contained"
                disabled={updatingHelpdesk}
                onClick={handleClickOpenRateHelpdeskDialog}
              >
                {updatingHelpdesk ? <CircularProgress size={24} /> : 'AVALIAR ATENDIMENTO'}
              </Button>
            </Tooltip>
          )}

          {helpdeskData?.situation > 1 && helpdeskData?.im_attendant && toNumber(helpdeskData?.helpdesk_rate) < 1 && (
            <Tooltip title="Avaliar se o helpdesk foi aberto de forma coerente" aria-label="rate-owner-helpdesk">
              <Button
                color="warning"
                variant="contained"
                disabled={updatingHelpdesk}
                onClick={handleClickOpenOwnerRateHelpdeskDialog}
              >
                {updatingHelpdesk ? <CircularProgress size={24} /> : 'AVALIAR SOLICITANTE'}
              </Button>
            </Tooltip>
          )}
        </Box>

        <Box className={classes.boxContainer}>
          <Typography className={classes.paperTitle}>DADOS</Typography>
        </Box>
        <Paper style={{ padding: 14, width: '100%', maxWidth: 1080, marginBottom: 8 }}>
          <Grid container spacing={2} style={{ marginTop: 1 }}>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                fullWidth
                id="helpdesk-id"
                label="Número"
                variant="outlined"
                type="text"
                size="small"
                value={helpdeskData?.id}
                InputProps={{
                  startAdornment: helpdeskData?.private === 1 && (
                    <Tooltip title="Conteudo Privado">
                      <LockIcon
                        className={classes.warningIimeSituationColor}
                        style={{ marginRight: 8, cursor: 'default' }}
                      />
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                fullWidth
                id="helpdesk-created_at"
                label="Data Abertura"
                variant="outlined"
                type="text"
                size="small"
                value={helpdeskData?.created_at_formated}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                fullWidth
                id="helpdesk-deadline"
                label="Data Prevista"
                variant="outlined"
                type="text"
                size="small"
                value={
                  helpdeskData?.deadline_formated === '00/00/0000' ? '-------------' : helpdeskData?.deadline_formated
                }
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                fullWidth
                id="helpdesk-ended_in"
                label="Data Atendimento"
                variant="outlined"
                type="text"
                size="small"
                value={
                  helpdeskData?.ended_in_formated === '00/00/0000 00:00'
                    ? '-------------'
                    : helpdeskData?.ended_in_formated
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                fullWidth
                id="helpdesk-situation-description"
                label="Situação"
                variant="outlined"
                type="text"
                size="small"
                InputProps={{
                  startAdornment:
                    helpdeskData?.situation === 0 ? (
                      <WarningIcon className={classes.warningIimeSituationColor} style={{ marginRight: 8 }} />
                    ) : helpdeskData?.situation === 1 ? (
                      <WatchLaterIcon
                        className={
                          helpdeskData?.days_delay === 0 ? classes.onTimeSituationColor : classes.offTimeSituationColor
                        }
                        style={{ marginRight: 8 }}
                      />
                    ) : helpdeskData?.situation === 2 ? (
                      <CheckCircleIcon
                        className={
                          helpdeskData?.days_delay === 0 ? classes.onTimeSituationColor : classes.offTimeSituationColor
                        }
                        style={{ marginRight: 8 }}
                      />
                    ) : (
                      helpdeskData?.situation === 3 && (
                        <CancelIcon className={classes.offTimeSituationColor} style={{ marginRight: 8 }} />
                      )
                    ),
                }}
                value={helpdeskData?.situation_description}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                id="input-target"
                label="Setor Atendimento"
                value={helpdeskData?.target_description === null ? '-------------' : helpdeskData?.target_description}
                size="small"
                onChange={handleInputTarget}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                fullWidth
                id="helpdesk-attendence"
                label="Atendente"
                variant="outlined"
                type="text"
                size="small"
                value={helpdeskData?.attendance_name === null ? '-------------' : helpdeskData?.attendance_name}
                InputProps={{
                  startAdornment: helpdeskData?.attendance_name !== null && (
                    <ProfileTooltip userId={helpdeskData?.attendance}>
                      <AccountCircleIcon style={{ marginRight: 8, cursor: 'default' }} />
                    </ProfileTooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="helpdesk-owner"
                label="Solicitante"
                variant="outlined"
                type="text"
                size="small"
                value={helpdeskData?.owner_name}
                InputProps={{
                  startAdornment: (
                    <ProfileTooltip userId={helpdeskData?.owner}>
                      <AccountCircleIcon style={{ marginRight: 8, cursor: 'default' }} />
                    </ProfileTooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="helpdesk-category"
                label="Categoria"
                variant="outlined"
                type="text"
                size="small"
                value={
                  helpdeskData?.category_description === null ? '-------------' : helpdeskData?.category_description
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="helpdesk-subcategory"
                label="Subcategoria"
                variant="outlined"
                type="text"
                size="small"
                value={
                  helpdeskData?.subcategory_description === null
                    ? '-------------'
                    : helpdeskData?.subcategory_description
                }
              />
            </Grid>
          </Grid>
        </Paper>
        <Box className={classes.boxContainer}>
          <Typography className={classes.paperTitle}>SOLICITAÇÂO</Typography>
        </Box>
        <Paper style={{ padding: 14, width: '100%', maxWidth: 1080, marginBottom: 8 }}>
          <TextField
            fullWidth
            id="input-title"
            label="Título"
            variant="outlined"
            type="text"
            size="small"
            value={helpdeskData?.title}
          />

          <Typography className={classes.titleDescription}>Descrição</Typography>
          <div
            className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.textContainer}`}
            dangerouslySetInnerHTML={{
              // DOMPurify.sanitize(

              __html: `${helpdeskData?.description}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`),
              // ),
            }}
          />
        </Paper>

        <Box style={{ width: '100%', maxWidth: 1080 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <AttachFileIcon className={classes.accordionTitleIcon} />
              <Box className={classes.accordionTitleBox}>
                <Typography className={classes.accordionTitleText}>ANEXOS</Typography>
                <Typography
                  className={classes.accordionTitleSubtext}
                >{`${helpdeskData?.attachments?.length} anexo(s)`}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classes.attachmentContainer}>
                {helpdeskData?.attachments?.map((attachment: HelpdeskAttachment, index: number) => {
                  const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                  const name = attachment.name.toLocaleLowerCase();
                  const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                  const path = `${attachment.url}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`);
                  const iconType = IconTypeName(type);

                  return (
                    <Paper className={classes.attachmentItemContainer} key={index}>
                      <Box px={2} py={0.5} height={60}>
                        <a
                          href={`${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            handleOpenAttachment(path);
                          }}
                        >
                          <FileIcon
                            extension={type}
                            type={iconType}
                            foldColor={darkMode ? '#808080' : '#4e4e4e'}
                            glyphColor={darkMode ? '#808080' : '#4e4e4e'}
                            gradientColor={darkMode ? '#808080' : '#4e4e4e'}
                            labelColor={darkMode ? '#808080' : '#4e4e4e'}
                          />
                        </a>
                      </Box>
                      <a
                        href={`${path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.attachmentItemName}
                        onClick={() => {
                          handleOpenAttachment(path);
                        }}
                      >
                        {name}
                      </a>
                    </Paper>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
          {helpdeskData?.links?.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <LinkIcon className={classes.accordionTitleIcon} />
                <Box className={classes.accordionTitleBox}>
                  <Typography className={classes.accordionTitleText}>{` HELPDESK'S ASSOCIADOS`}</Typography>
                  <Typography
                    className={classes.accordionTitleSubtext}
                  >{`${helpdeskData?.links?.length} helpdesk's`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.linksContainer}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      className={classes.tableLinks}
                      aria-labelledby="tableTitle"
                      size="small"
                      aria-label="table"
                    >
                      <TableHead>
                        <TableRow className={classes.tableLinksHeader}>
                          <TableCell align="center" />
                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              Nº
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              SITUAÇÃO
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption" color="textSecondary">
                              TÍTULO
                            </Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              ABERTURA (DATA)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              PREVISÃO (DATA)
                            </Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              ATENDENTE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              ATRASO (DIAS)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption" color="textSecondary">
                              TOTAL (DIAS)
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {helpdeskData?.links?.map((helpdesk: HelpdeskLink, index: number) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell width={16} padding="none" align="center">
                                <Tooltip title="Visualizar" aria-label="view">
                                  <IconButton
                                    component={Link}
                                    to={`/helpdesk/${helpdesk.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    size="large"
                                  >
                                    <Icon>open_in_new</Icon>
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell width={18} align="left">
                                {helpdesk.id}
                              </TableCell>
                              <TableCell width={150} align="left">
                                <Box display="flex" alignItems="center">
                                  {helpdesk.situation === 0 && (
                                    <WarningIcon className={classes.warningIimeSituationColor} />
                                  )}
                                  {helpdesk.situation === 1 && (
                                    <WatchLaterIcon
                                      className={
                                        helpdesk.days_delay === 0
                                          ? classes.onTimeSituationColor
                                          : classes.offTimeSituationColor
                                      }
                                    />
                                  )}
                                  {helpdesk.situation === 2 && (
                                    <CheckCircleIcon
                                      className={
                                        helpdesk.days_delay === 0
                                          ? classes.onTimeSituationColor
                                          : classes.offTimeSituationColor
                                      }
                                    />
                                  )}
                                  {helpdesk.situation === 3 && <CancelIcon className={classes.offTimeSituationColor} />}
                                  <span style={{ marginLeft: 4 }}>{helpdesk.situation_description}</span>
                                </Box>
                              </TableCell>
                              <TableCell align="left">{helpdesk.title}</TableCell>
                              <TableCell align="center">{helpdesk.created_at_formated}</TableCell>
                              <TableCell align="center">{helpdesk.deadline_formated}</TableCell>
                              <TableCell align="left">{helpdesk.attendance_name}</TableCell>
                              <TableCell align="center">{helpdesk.days_delay}</TableCell>
                              <TableCell align="center">{helpdesk.days_spent}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion TransitionProps={{ unmountOnExit: true }} onChange={handleHelpdeskPostView}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Badge badgeContent={helpdeskData?.posts_unreaded} color="error">
                <ForumIcon className={classes.accordionTitleIcon} />
              </Badge>

              <Box className={classes.accordionTitleBox}>
                <Typography className={classes.accordionTitleText}>TRÂMITES</Typography>
                <Typography
                  className={classes.accordionTitleSubtext}
                >{`${helpdeskData?.posts?.length} trâminte(s)`}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails} style={{ paddingRight: 16 }}>
              {helpdeskPosts?.map((post: HelpdeskPost, index) => {
                return (
                  <Comment
                    key={index}
                    userId={post?.owner}
                    userName={post?.owner_name}
                    userSector={post?.owner_sector}
                    text={post?.text}
                    date={post?.created_at}
                    date_formated={post?.created_at_formated}
                    fullWidth
                    flip={post?.is_my}
                    system={post?.type >= 2}
                    readCheck
                    readed={post?.readed}
                    readed_date={post?.readed_at_formated}
                    content={
                      <Box className={classes.postAccordionAttachmentsContainer}>
                        {post?.extend_request === 1 && post?.extend_answer < 0 && (
                          <Box>
                            {helpdeskData?.im_owner ? (
                              <Box className={classes.requestExtendBoxContainer}>
                                <Tooltip title="Aceitar o prazo solicitado" aria-label="extend-request-helpdesk">
                                  <Button
                                    color="primary"
                                    className={classes.extendAcceptedText}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleExtendAnswer(1, post?.id)}
                                    style={{ paddingLeft: 16, paddingRight: 16 }}
                                  >
                                    ACEITAR
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Recusar o prazo solicitado" aria-label="extend-request-helpdesk">
                                  <Button
                                    color="secondary"
                                    size="small"
                                    variant="outlined"
                                    className={classes.extendDeniedText}
                                    onClick={() => handleExtendAnswer(0, post?.id)}
                                    style={{ paddingLeft: 16, paddingRight: 16 }}
                                  >
                                    RECUSAR
                                  </Button>
                                </Tooltip>
                                {post?.is_my && (
                                  <Box display="flex" justifyContent="flex-end" width="100%">
                                    <Tooltip
                                      title="Cancelar a solicitação do prazo"
                                      aria-label="extend-request-helpdesk"
                                    >
                                      <Button
                                        className={classes.extendDeniedText}
                                        color="secondary"
                                        size="small"
                                        variant="outlined"
                                        onClick={() => handleExtendAnswer(2, post?.id)}
                                        style={{ paddingLeft: 16, paddingRight: 16 }}
                                      >
                                        CANCELAR
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            ) : (
                              <Box className={classes.extendBoxContainer}>
                                <Box className={`${classes.extendBox} ${classes.wattingText}`}>
                                  <WarningIcon
                                    className={classes.wattingText}
                                    style={{ fontSize: 22, marginRight: 4 }}
                                  />
                                  <Typography className={classes.wattingText}>Aguardando aprovação</Typography>
                                </Box>
                                {post?.is_my && (
                                  <Tooltip title="Cancelar a solicitação do prazo" aria-label="extend-request-helpdesk">
                                    <Button
                                      className={classes.extendDeniedText}
                                      color="secondary"
                                      size="small"
                                      variant="outlined"
                                      onClick={() => handleExtendAnswer(2, post?.id)}
                                      style={{ paddingLeft: 16, paddingRight: 16 }}
                                    >
                                      CANCELAR
                                    </Button>
                                  </Tooltip>
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                        {post?.extend_request === 1 && post?.extend_answer === 0 && (
                          <Box className={`${classes.extendBox} ${classes.extendDeniedText}`}>
                            <CancelIcon className={classes.extendDeniedText} style={{ fontSize: 22, marginRight: 4 }} />
                            <Typography className={classes.extendDeniedText}>Solicitação Recusada</Typography>
                          </Box>
                        )}
                        {post?.extend_request === 1 && post?.extend_answer === 1 && (
                          <Box className={`${classes.extendBox} ${classes.extendAcceptedText}`}>
                            <CheckCircleIcon
                              className={classes.extendAcceptedText}
                              style={{ fontSize: 22, marginRight: 4 }}
                            />
                            <Typography className={classes.extendAcceptedText}>Solicitação Aceita</Typography>
                          </Box>
                        )}
                        {post?.extend_request === 1 && post?.extend_answer === 2 && (
                          <Box className={`${classes.extendBox} ${classes.extendDeniedText}`}>
                            <CancelIcon className={classes.extendDeniedText} style={{ fontSize: 22, marginRight: 4 }} />
                            <Typography className={classes.extendDeniedText}>Solicitação Cancelada</Typography>
                          </Box>
                        )}
                        {post?.attachments?.length > 0 && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <AttachFileIcon style={{ fontSize: 18, marginRight: 4 }} />
                              <Typography
                                className={classes.accordionTitleSubtext}
                              >{`${post?.attachments?.length} anexo(s)`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                              <Box className={classes.attachmentContainer}>
                                {post?.attachments?.map((attachment: HelpdeskAttachment, index: number) => {
                                  const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                                  const name = attachment.name.toLocaleLowerCase();
                                  const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                                  const path = `${attachment.url}`.replace(
                                    /{UPLOAD_PATH}/g,
                                    `${api.getUri()}/static/uploads`,
                                  );
                                  const iconType = IconTypeName(type);

                                  return (
                                    <Paper className={classes.attachmentItemContainer} key={index}>
                                      <Box px={2} py={0.5} height={60}>
                                        <a
                                          href={`${path}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() => {
                                            handleOpenAttachment(path);
                                          }}
                                        >
                                          <FileIcon
                                            extension={type}
                                            type={iconType}
                                            foldColor={darkMode ? '#808080' : '#4e4e4e'}
                                            glyphColor={darkMode ? '#808080' : '#4e4e4e'}
                                            gradientColor={darkMode ? '#808080' : '#4e4e4e'}
                                            labelColor={darkMode ? '#808080' : '#4e4e4e'}
                                          />
                                        </a>
                                      </Box>
                                      <a
                                        href={`${path}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.attachmentItemName}
                                        onClick={() => {
                                          handleOpenAttachment(path);
                                        }}
                                      >
                                        {name}
                                      </a>
                                    </Paper>
                                  );
                                })}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Box>
                    }
                  />
                );
              })}
              {(helpdeskData?.im_owner || helpdeskData?.im_attendant) && helpdeskData?.situation < 2 && (
                <>
                  <div
                    style={{
                      margin: 0,
                      marginTop: 20,
                      marginLeft: 6,
                      border: 'none',
                      height: '1px',
                      flexShrink: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.12)',
                      width: '100%',
                    }}
                  />
                  <Box className={classes.newCommentRoot} style={{ width: '100%', marginTop: 16, paddingRight: 16 }}>
                    <TextField
                      size="small"
                      className={classes.newCommentInput}
                      fullWidth
                      id="helpdesk-comment-input"
                      variant="outlined"
                      placeholder="Digite o seu tramite aqui..."
                      value={newComment}
                      onChange={handleNewCommentChange}
                    />
                    <Tooltip title="Emojis">
                      <IconButton
                        aria-label="attachments"
                        className={classes.newCommentSendButton}
                        component="span"
                        onClick={() => {
                          setSelectEmoji(!selectEmoji);
                        }}
                        size="large"
                      >
                        😀
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={newComment?.length === 0 ? '' : `Anexar Aquivos`}>
                      <label htmlFor="icon-button-file">
                        <IconButton
                          aria-label="attachments"
                          className={classes.newCommentSendButton}
                          component="span"
                          disabled={newComment?.length === 0}
                          size="large"
                        >
                          <AttachFileIcon fontSize="medium" color="inherit" />
                        </IconButton>
                      </label>
                    </Tooltip>

                    <Tooltip title={newComment?.length === 0 ? '' : `Enviar`}>
                      <IconButton
                        aria-label="send"
                        className={classes.newCommentSendButton}
                        disabled={newComment?.length === 0}
                        onClick={handleSendMessage}
                        size="large"
                      >
                        <SendIcon fontSize="medium" color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box className={classes.attachmentContainer}>
                    <input
                      style={{ display: 'none' }}
                      id="icon-button-file"
                      type="file"
                      multiple
                      ref={refInputAttachment}
                      onChange={handleAddAttachments}
                      disabled={newComment?.length === 0}
                    />
                    {attachmentsPostComment?.map((attachment: File, index: number) => {
                      const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                      const name = attachment.name.toLocaleLowerCase();
                      const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                      const size = humanFileSize(attachment.size);
                      const iconType = IconTypeName(type);

                      return (
                        <Paper className={classes.attachmentItemContainer} key={index}>
                          <Tooltip title={name} aria-label="attachment-remove-helpdesk">
                            <Typography className={classes.attachmentItemName}>{name}</Typography>
                          </Tooltip>

                          <Box px={2} py={0.5} height={50}>
                            <FileIcon
                              extension={type}
                              type={iconType}
                              foldColor={darkMode ? '#808080' : '#4e4e4e'}
                              glyphColor={darkMode ? '#808080' : '#4e4e4e'}
                              gradientColor={darkMode ? '#808080' : '#4e4e4e'}
                              labelColor={darkMode ? '#808080' : '#4e4e4e'}
                            />
                          </Box>

                          <Typography className={classes.attachmentItemSize}>{size}</Typography>
                          <Tooltip title="Remover Anexo" aria-label="attachment-remove-helpdesk">
                            <Button
                              size="small"
                              fullWidth
                              color="secondary"
                              variant="outlined"
                              onClick={() => {
                                handleRemoveAttachments(index);
                              }}
                            >
                              <DeleteIcon style={{ height: 18 }} />
                            </Button>
                          </Tooltip>
                        </Paper>
                      );
                    })}
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          {helpdeskData?.situation > 1 && (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <AssignmentTurnedInIcon className={classes.accordionTitleIcon} />
                  <Box className={classes.accordionTitleBox}>
                    <Typography className={classes.accordionTitleText}>SOLUÇÃO</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Box style={{ padding: 10, paddingTop: 0, width: '100%' }}>
                    <Typography className={classes.titleDescription}>Descrição</Typography>
                    <div
                      className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.textContainer}`}
                      dangerouslySetInnerHTML={{
                        // DOMPurify.sanitize(
                        __html: `${helpdeskData?.feedback}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`),
                        // ),
                      }}
                    />
                    {helpdeskData?.days_delay > 0 && (
                      <>
                        <Typography className={classes.titleDescription}>Justificativa de Atraso</Typography>
                        <div
                          className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.textContainer}`}
                          dangerouslySetInnerHTML={{
                            // DOMPurify.sanitize(
                            __html: `${helpdeskData?.feedback_delay}`.replace(
                              /{UPLOAD_PATH}/g,
                              `${api.getUri()}/static/uploads`,
                            ),
                            // ),
                          }}
                        />
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
              {helpdeskData?.rate > 0 && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <GradeIcon className={classes.accordionTitleIcon} />
                    <Box className={classes.accordionTitleBox}>
                      <Typography className={classes.accordionTitleText}>AVALIAÇÃO</Typography>
                      <Rating size="small" name="read-only" value={helpdeskData?.rate} precision={1} readOnly />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Box p={1} flex={1} width="100%">
                      {helpdeskData?.rate_feedback.replace(/(<([^>]+)>)/gi, '')?.length === 0 ? (
                        <Box>
                          <Typography className={classes.noRateText}>Não foi deixando nenhum comentário</Typography>
                        </Box>
                      ) : (
                        <div
                          className={`se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable ${classes.textContainer}`}
                          dangerouslySetInnerHTML={{
                            // DOMPurify.sanitize(
                            __html: `${helpdeskData?.rate_feedback}`.replace(
                              /{UPLOAD_PATH}/g,
                              `${api.getUri()}/static/uploads`,
                            ),
                            // ),
                          }}
                        />
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </Box>
      </Box>

      {helpdeskData?.rate === 0 && (
        <Dialog
          open={openRateHelpdeskDialog}
          fullWidth
          maxWidth="xl"
          onClose={handleCloseRateHelpdeskDialog}
          scroll="paper"
          aria-labelledby="dialog-rate-helpdesk-scroll-dialog-title}"
          aria-describedby="dialog-rate-helpdesk-scroll-dialog-description"
        >
          <DialogTitle id="dialog-rate-helpdesk-title">Avaliar Helpdesk</DialogTitle>
          <DialogContent dividers>
            <Box>
              <Typography className={classes.paperTitle} style={{ marginBottom: 8 }}>
                AVALIAÇÃO
              </Typography>

              <Rating
                name="helpdesk-input-rate"
                value={helpdeskRate}
                onChange={(event: any, newValue: any) => {
                  handleRateChange(newValue || 0);
                }}
              />

              <Typography className={classes.paperTitle} style={{ marginBottom: 8, marginTop: 8 }}>
                COMENTÁRIO
              </Typography>

              <HtmlEditor
                name="helpdesk-input-rate-feedback"
                onChange={(content: any) => handleRateFeedbackChange(content)}
                setContents={helpdeskRateFeedback}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseRateHelpdeskDialog} color="secondary">
              Fechar
            </Button>
            <Button
              disabled={disableButton}
              onClick={() => {
                handleUpdateRateHelpdesk();
              }}
              color="primary"
            >
              Avaliar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {toNumber(helpdeskData?.helpdesk_rate) === 0 && (
        <Dialog
          open={openOwnerRateHelpdeskDialog}
          fullWidth
          maxWidth="xl"
          onClose={handleCloseOwnerRateHelpdeskDialog}
          scroll="paper"
          aria-labelledby="dialog-rate-owner-helpdesk-scroll-dialog-title}"
          aria-describedby="dialog-rate-owner-helpdesk-scroll-dialog-description"
        >
          <DialogTitle id="dialog-rate-owner-helpdesk-title">Avaliar Solicitante</DialogTitle>
          <DialogContent dividers>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <Typography className={classes.paperTitle} style={{ marginBottom: 8 }}>
                  SOLICITANTE DO SOLICITANTE
                </Typography>

                <Rating
                  name="helpdesk-owner-input-rate"
                  value={helpdeskOwnerRate}
                  onChange={(event: any, newValue: any) => {
                    handleHelpdeskOwnerRateChange(newValue || 0);
                  }}
                />
              </Box>
              <FormControlLabel
                control={
                  <Tooltip title="Já houve outro helpdesk aberto com esse assunto?">
                    <Switch
                      checked={recidivismChecked}
                      onChange={handleRecidivismChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Tooltip>
                }
                label="Reincidência?"
              />
              <Box>
                <Typography className={classes.paperTitle} style={{ marginBottom: 8, marginTop: 8 }}>
                  COMENTÁRIO
                </Typography>

                <HtmlEditor
                  name="helpdesk-owner-input-rate-feedback"
                  onChange={(content: any) => handleHelpdeskOwnerRateFeedbackChange(content)}
                  setContents={helpdeskOwnerRateFeedback}
                />
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseOwnerRateHelpdeskDialog} color="secondary">
              Fechar
            </Button>
            <Button
              disabled={disableButton}
              onClick={() => {
                handleUpdateOwnerRateHelpdesk();
              }}
              color="primary"
            >
              Avaliar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {helpdeskData?.situation <= 1 && (helpdeskData?.im_owner || helpdeskData?.im_can_attendant) && (
        <>
          <Dialog
            open={openGetHelpdeskDialog}
            fullWidth
            maxWidth="sm"
            onClose={handleCloseGetHelpdeskDialog}
            scroll="paper"
            aria-labelledby="dialog-get-helpdesk-scroll-dialog-title}"
            aria-describedby="dialog-get-helpdesk-scroll-dialog-description"
          >
            <DialogTitle id="dialog-get-helpdesk-title">Definir Atendente do Helpdesk</DialogTitle>
            <DialogContent dividers>
              <TextField
                fullWidth
                id="input-helpdesk-attendants"
                select
                label="Atendente"
                value={helpdeskAttendant}
                size="small"
                onChange={handleInputAttendant}
                variant="outlined"
                style={{ marginBottom: 18 }}
              >
                {attendants?.map((attendant: HelpdeskAttendant) => {
                  return (
                    <MenuItem key={attendant.attendants_id} value={attendant.attendants_id}>
                      {attendant.attendants_name}
                    </MenuItem>
                  );
                })}
              </TextField>
              {helpdeskData?.situation === 0 && (
                <>
                  <TextField
                    disabled={!enableCategorys}
                    fullWidth
                    id="input-news-category"
                    select
                    label="Categoria"
                    value={helpdeskCategory}
                    size="small"
                    onChange={handleInputCategory}
                    variant="outlined"
                    style={{ marginBottom: 18 }}
                  >
                    {categorys?.map((category: any) => {
                      return (
                        <MenuItem key={category.id} value={category.id}>
                          {category.description}
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  <TextField
                    disabled={!enableSubcategorys}
                    fullWidth
                    id="input-news-category"
                    select
                    label="Sub Categoria"
                    value={helpdeskSubcategory}
                    size="small"
                    onChange={handleInputSubcategory}
                    variant="outlined"
                    style={{ marginBottom: 18 }}
                  >
                    {subcategorys?.map((subcategory: any, index: number) => {
                      return (
                        <MenuItem key={index} value={subcategory.id}>
                          {subcategory.description}
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  <TextField
                    fullWidth
                    id="input-helpdes-deadline"
                    label="Prazo"
                    style={{ maxWidth: 180 }}
                    value={
                      helpdeskDays > 0
                        ? moment
                            .utc(helpdeskData?.created_at)
                            .add(helpdeskDays as number, 'days')
                            .format('DD/MM/YYYY')
                        : '---------'
                    }
                    size="small"
                    variant="outlined"
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGetHelpdeskDialog} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  handleUpdateGetHelpdesk(
                    helpdeskData?.id,
                    helpdeskAttendant,
                    helpdeskData?.target,
                    helpdeskCategory,
                    helpdeskSubcategory,
                  );
                }}
                color="primary"
              >
                Salvar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openEndHelpdeskDialog}
            fullWidth
            maxWidth="xl"
            onClose={handleCloseEndHelpdeskDialog}
            scroll="paper"
            aria-labelledby="dialog-end-helpdesk-scroll-dialog-title}"
            aria-describedby="dialog-end-helpdesk-scroll-dialog-description"
          >
            <DialogTitle id="dialog-end-helpdesk-title">
              {dialogAction === 'end' ? 'Finalizar Helpdesk' : 'Cancelar Helpdesk'}
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
              <Box>
                <Typography className={classes.paperTitle} style={{ marginBottom: 8 }}>
                  {dialogAction === 'end' ? `SOLUÇÂO` : 'JUSTIFICATIVA'}
                </Typography>
                <Box className={classes.textBox}>
                  <HtmlEditor
                    onChange={(content: any) => handleFeedbackChange(content)}
                    setContents={helpdeskFeedback}
                  />
                </Box>
              </Box>
              {helpdeskData?.days_delay > 0 && (
                <Box>
                  <Typography className={classes.paperTitle} style={{ marginTop: 16, marginBottom: 8 }}>
                    JUSTIFICATIVA DE ATRASO
                  </Typography>

                  <Box className={classes.textBox}>
                    <HtmlEditor
                      onChange={(content: any) => handleFeedbackDelayChange(content)}
                      setContents={helpdeskFeedbackDelay}
                    />
                  </Box>
                </Box>
              )}

              <FormControlLabel
                control={
                  <Tooltip title="Já houve outro helpdesk aberto com esse assunto?">
                    <Switch
                      checked={recidivismChecked}
                      onChange={handleRecidivismChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Tooltip>
                }
                label="Reincidência?"
              />

              <Box>
                <Typography className={classes.paperTitle} style={{ marginBottom: 8 }}>
                  AVALIAÇÃO DO SOLICITANTE
                </Typography>

                <Rating
                  name="helpdesk-owner-input-rate"
                  value={helpdeskOwnerRate}
                  onChange={(event: any, newValue: any) => {
                    handleHelpdeskOwnerRateChange(newValue || 0);
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <Typography className={classes.paperTitle} style={{ marginBottom: 8, marginTop: 8 }}>
                  COMENTÁRIO SOBRE O ATENDENTE
                </Typography>

                <HtmlEditor
                  name="helpdesk-owner-input-rate-feedback"
                  onChange={(content: any) => handleHelpdeskOwnerRateFeedbackChange(content)}
                  setContents={helpdeskRateFeedback}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={dialogAction === 'end' ? handleCloseEndHelpdeskDialog : handleCloseCancelHelpdeskDialog}
                color="secondary"
              >
                Fechar
              </Button>
              <Button
                disabled={disableButton}
                onClick={() => {
                  handleUpdateEndHelpdesk();
                }}
                color="primary"
              >
                {dialogAction === 'end' ? 'Finalizar' : 'Cancelar Helpdesk'}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openExtendHelpdeskDialog}
            fullWidth
            maxWidth="xl"
            onClose={handleCloseExtendHelpdeskDialog}
            scroll="paper"
            aria-labelledby="dialog-extend-helpdesk-scroll-dialog-title}"
            aria-describedby="dialog-extend-helpdesk-scroll-dialog-description"
          >
            <DialogTitle id="dialog-extend-helpdesk-title">Solicitar novo prazo</DialogTitle>
            <DialogContent dividers>
              <Box>
                <Box display="flex" alignItems="center" mb={3}>
                  <Typography className={classes.paperTitle} style={{ marginRight: 16 }}>
                    {'DIAS ÚTEIS: '}
                  </Typography>
                  <Input
                    value={extendDays}
                    margin="dense"
                    onChange={handleDaysChange}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 999,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Box>

                <Typography className={classes.paperTitle} style={{ marginBottom: 8, marginTop: 16 }}>
                  COMENTÁRIO
                </Typography>
                <Box className={classes.textBox}>
                  <HtmlEditor
                    lang="pt_br"
                    name="helpdesk-input-extend-feedback"
                    setDefaultStyle={`min-height:260px; font-family: Arial, 'Roboto'; font: 16px Arial;`}
                    height="100%"
                    onChange={(content: any) => handleTextChange(content)}
                    setContents={extendText}
                    setOptions={{
                      font: ['Arial', 'Roboto', 'Courier New, Courier'],
                      buttonList: [
                        ['undo', 'redo'],
                        ['bold', 'underline', 'italic', 'strike'],
                        ['fontSize', 'fontColor', 'hiliteColor'],
                        ['removeFormat'],
                      ],
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseExtendHelpdeskDialog} color="secondary">
                Fechar
              </Button>
              <Button
                disabled={disableButton}
                onClick={() => {
                  handleUpdateHelpdesk();
                }}
                color="primary"
              >
                Solicitar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
