import Home from './home';
import Add from './add';
import Edit from './edit';
import View from './view';

export default {
  Home,
  Add,
  Edit,
  View,
};
