import React, { useMemo, useRef, useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Box,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  MenuItem,
  Paper,
  Backdrop,
  Typography,
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  useTheme,
} from '@mui/material';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Alert } from '@mui/lab';
import imageCompression from 'browser-image-compression';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Axios, { Canceler } from 'axios';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { UploadBeforeHandler } from 'suneditor-react/dist/types/upload';
import styles from './styles';
import api from '../../../services/api';
import NewsIcon from '../../../images/logo_b.svg';
import { UploadProps } from '../dashboard';
import { humanFileSize } from '../../../utils/functions';

moment.locale('pt-br');
moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });

export default function AdminUsersAddPage(props: any) {
  let totalSize = 0;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const refInputAttachment = useRef<HTMLInputElement | null>(null);
  const refInputThumbnailImg = useRef<HTMLInputElement | null>(null);
  const classes = styles();
  const [thumbnail, setThumbnail] = useState<File>();
  const [thumbnailImg, setThumbnailImg] = useState<string>(NewsIcon);

  const [galleryImgs, setGalleryImgs] = useState<File[]>([]);

  const [newsContent, setNewsContent] = useState('');
  const [newsTitle, setNewsTitle] = useState('');
  const [newsCategory, setNewsCategory] = useState<number>(1);
  const [newsType, setNewsType] = useState<number>(0);
  const [newsTags, setNewsTags] = useState('');
  const [newsLikes, setNewsLikes] = useState<number>(1);
  const [newsComments, setNewsComments] = useState<number>(1);

  const [gettingCategorys, setGettingCategorys] = useState<boolean>(false);
  const [categorys, setCategorys] = useState([{ id: 0, description: '' }]);
  const [waitingPostNews, setWaitingPostNews] = useState<boolean>(false);

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    let active = true;
    if (active) {
      getCategorys();
    }
    return () => {
      active = false;
    };
  }, []);

  const getCategorys = () => {
    let cancel: Canceler;
    if (!gettingCategorys) {
      setGettingCategorys(true);

      api
        .get('intranet/news/categorys', {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          const commentsData = response.data;
          setCategorys(commentsData);
          setGettingCategorys(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingCategorys(false);
        });
    }
    return () => cancel();
  };

  const handleOnChange = (content: any) => {
    setNewsContent(content);
  };

  const handleInputNewsTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewsTitle(event.target.value);
  };

  const handleInputCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewsCategory(Number(event.target.value));
  };

  const handleInputType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewsType(Number(event.target.value));
  };

  const handleInputComments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewsComments(event.target.checked ? 1 : 0);
  };

  const handleInputLikes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewsLikes(event.target.checked ? 1 : 0);
  };

  const handleImageGalleryImgsUpload = async (newsId: string) => {
    const data = new FormData();
    data.append('folder', `news/${newsId}/gallery`);
    for (let i = 0; i < galleryImgs.length; i++) {
      data.append('files', galleryImgs[i]);
    }

    try {
      await api.post(`services/upload`, data);
    } catch (error) {}
  };
  const handlePostNews = async () => {
    if (waitingPostNews) {
      return;
    }
    const data = new FormData();
    data.append('news_title', newsTitle);
    data.append('news_content', `${newsContent}`);
    data.append('category', newsCategory?.toString());
    data.append('likes', newsLikes?.toString());
    data.append('comments', newsComments?.toString());
    data.append('tags', newsTags?.toString());
    if (thumbnail) data.append('files', thumbnail);

    setWaitingPostNews(true);

    try {
      const response = await api.post('intranet/news', data);
      if (response) {
        await handleImageGalleryImgsUpload(response.data.id);
        setWaitingPostNews(false);
        window.location.href = `/news/${response.data.id}`;
      }
    } catch (error) {
      const { postData } = error.response;
      if (postData) {
        let errorMessage = postData.message;

        if (errorMessage === 'invalid_news_title') errorMessage = 'Título muito curto ou inválido';
        else if (errorMessage === '') errorMessage = '';

        setSnackbarType('error');
        setSnackbarMsg(errorMessage);
        setOpenSnackbar(true);
      }
    }
    setWaitingPostNews(false);
  };

  const handleChangeThumbnail = async (event: any) => {
    if (event.target) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 512,
        useWebWorker: true,
      };

      try {
        const compressedThumbnail = (await imageCompression(event.target.files[0], options)) as File;

        setThumbnailImg(await imageCompression.getDataUrlFromFile(compressedThumbnail));

        setThumbnail(compressedThumbnail);
      } catch (error) {
        console.error(error);
      }
    }
  };

  function handleImageUploadBefore(files: Array<File>, info: object, uploadHandler: UploadBeforeHandler) {
    const data = new FormData();
    data.append('folder', 'news');

    data.append('files', files[0]);

    api
      .post('services/upload', data)
      .then(async (response: any) => {
        const responseData = { result: [] as any };
        responseData.result = response.data.map((img: { path: any; name: any; size: any }) => ({
          url: `${api.getUri()}/${img.path}`,
          name: img.name,
          size: img.size,
        }));

        uploadHandler(responseData);
      })
      .catch((error: any) => {
        const { data } = error.response;
        if (data) {
          let errorMessage = data.message;

          if (!errorMessage) errorMessage = data.error;
          if (errorMessage === 'invalid_news_title') errorMessage = 'Título muito curto ou inválido';
          else if (errorMessage === '') errorMessage = '';

          setSnackbarType('error');
          setSnackbarMsg(errorMessage);
          setOpenSnackbar(true);
        }
        setWaitingPostNews(false);
      });
    return null;
  }

  const handleAddGalleryImg = async (event: any) => {
    if (event.target) {
      const newAttachments = galleryImgs;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of event.target.files) {
          newAttachments.push(attachment);
        }
      } catch (err) {
        console.error(err);
      }

      setGalleryImgs([...newAttachments]);
    }
  };

  const handleRemoveAllGalleryImg = () => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    setGalleryImgs([]);
  };

  const handleRemoveGalleryImg = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = galleryImgs;
    newAttachments.splice(index, 1);
    setGalleryImgs([...newAttachments]);
  };

  const renderGalleryImgs = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', width: '100%' }}>
        <Typography sx={{ fontSize: 12 }}>Galeria</Typography>

        <Box
          sx={{
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
              border: `1px solid`,
            },
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            p: 1,
            maxHeight: 384,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              flex: 1,
              height: '100%',
            }}
          >
            {galleryImgs?.length === 0 ? (
              <Box sx={{ p: 0.5 }}>
                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                  Nenhuma imagem selecionada
                </Typography>
              </Box>
            ) : (
              galleryImgs?.map((attachment: File, index: number) => {
                const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                const name = attachment.name.toLocaleLowerCase();
                const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                const size = humanFileSize(attachment.size);
                const key = `${index}-${name}-${type}-${size}`;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                totalSize += attachment.size;
                return (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      gap: 1,
                      flex: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title="Remover imagem" arrow disableInteractive>
                      <IconButton
                        color="error"
                        aria-label="remove img"
                        component="label"
                        size="small"
                        onClick={() => handleRemoveGalleryImg(index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Box sx={{ border: `1px solid ${theme.palette.text.secondary}`, height: 48, borderRadius: 1 }}>
                      <img
                        src={URL.createObjectURL(attachment)}
                        onError={(e: any) => {
                          e.target.src = NewsIcon;
                        }}
                        alt="news"
                        style={{ height: 48 }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                      {name} ({size})
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {galleryImgs?.length > 0 && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption">{`${galleryImgs?.length} ${
                galleryImgs?.length === 1 ? 'imagem selecionada' : 'imagens selecionadas'
              } (${humanFileSize(totalSize)})`}</Typography>
            </Box>
          )}
          <Tooltip title="Remover todas as imagens">
            <IconButton
              color="error"
              aria-label="remove imgs"
              component="label"
              size="small"
              disabled={galleryImgs?.length === 0}
              onClick={() => handleRemoveAllGalleryImg()}
            >
              <DeleteSweepOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Selecionar arquivo">
            <IconButton color="primary" aria-label="upload picture" component="label" size="small">
              <input
                style={{ display: 'none' }}
                id="attachment-input"
                hidden
                type="file"
                multiple
                accept="image/*"
                ref={refInputAttachment}
                onChange={handleAddGalleryImg}
              />
              <NoteAddOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),
    [galleryImgs],
  );

  if (gettingCategorys) {
    return (
      <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
        <Typography variant="caption" className={classes.loadingInitialDataText}>
          Carregando...
        </Typography>
      </Backdrop>
    );
  }

  return (
    <Box>
      <Box className={classes.root} sx={{ gap: 2 }}>
        <Paper
          sx={{
            padding: 2,
            width: '100%',
            maxWidth: 1080,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ marginRight: 16 }}
          >
            <Typography className={classes.thumbnailTitleText}>Image da Capa</Typography>
            <Box className={classes.newsPreview}>
              <input
                style={{ display: 'none' }}
                id="thumbnail-file"
                type="file"
                accept="image/*"
                ref={refInputThumbnailImg}
                onChange={handleChangeThumbnail}
              />

              <img
                src={thumbnailImg}
                onError={(e: any) => {
                  e.target.src = NewsIcon;
                }}
                alt="news"
                style={{ width: 108, height: 108 }}
              />
            </Box>
            <Typography className={classes.thumbnailSubTitleText}>Tamanho Recomendado</Typography>
            <Typography className={classes.thumbnailSubTitleText} style={{ marginBottom: 8 }}>
              (256 X 256 pixels)
            </Typography>

            <Tooltip title="Alterar Imagem de capa" aria-label="img-thumbnail">
              <label htmlFor="thumbnail-file">
                <Button color="primary" variant="contained" size="small" component="span">
                  ALTERAR
                </Button>
              </label>
            </Tooltip>
          </Box>
          <Box flex={1}>
            <TextField
              fullWidth
              id="input-news-title"
              label="Título"
              variant="outlined"
              type="text"
              size="small"
              value={newsTitle || ''}
              onChange={handleInputNewsTitle}
              style={{ marginTop: 16 }}
            />

            <TextField
              fullWidth
              id="input-news-category"
              select
              label="Categoria"
              value={newsCategory}
              size="small"
              onChange={handleInputCategory}
              variant="outlined"
              style={{ marginTop: 16 }}
            >
              {categorys?.map((category: any, index: number) => {
                const key = `${index}`;
                return (
                  <MenuItem key={key} value={category.id}>
                    {category.description}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              fullWidth
              id="input-news-type"
              select
              label="Tipo"
              value={newsType}
              size="small"
              onChange={handleInputType}
              variant="outlined"
              style={{ marginTop: 16 }}
            >
              <MenuItem value={0}>Texto</MenuItem>
              <MenuItem value={1} disabled>
                Enquete
              </MenuItem>
            </TextField>
            <FormControl component="fieldset" style={{ marginTop: 8 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newsComments === 1}
                      color="primary"
                      onChange={handleInputComments}
                      name="comments"
                    />
                  }
                  label="Comentários"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={newsLikes === 1} color="primary" onChange={handleInputLikes} name="likes" />
                  }
                  label="Likes"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Paper>

        <Paper sx={{ padding: 2, width: '100%', maxWidth: 1080 }}>
          {newsType === 0 ? (
            <>
              <Typography className={classes.paperTitleText} style={{ marginBottom: 8 }}>
                Texto
              </Typography>

              <SunEditor
                lang="pt_br"
                name="news-editor"
                height="100%"
                setDefaultStyle={`min-height:260px; font-family: Arial, 'Roboto'; font: 16px Arial;`}
                onChange={content => setNewsContent(content)}
                setContents={newsContent}
                onImageUploadBefore={handleImageUploadBefore}
                setOptions={{
                  font: ['Arial', 'Roboto', 'Courier New, Courier'],
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    '/',
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image', 'video'],
                    ['fullScreen', 'showBlocks', 'codeView', 'preview'],
                  ],
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Paper>
        <Paper sx={{ padding: 2, width: '100%', maxWidth: 1080 }}>{renderGalleryImgs}</Paper>
        <Paper sx={{ padding: 2, width: '100%', maxWidth: 1080 }}>
          <Tooltip title="Postar Notícia" aria-label="insert-user">
            <Button color="primary" variant="contained" onClick={handlePostNews} disabled={waitingPostNews}>
              {waitingPostNews && <CircularProgress size={24} />}
              Postar
            </Button>
          </Tooltip>
        </Paper>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
