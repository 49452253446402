import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { green, grey, orange, red, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 260px)',
      width: '100%',
    },

    loadingText: {
      color: darkMode ? grey[500] : grey[700],
      fontSize: 12,
    },

    containerRoot: {
      padding: 16,
    },

    itemContainer: {
      display: 'flex',
      backgroundColor: darkMode ? grey[700] : '#fff',
      padding: 8,
      borderRadius: 6,
      alignItems: 'center',
      width: '100%',
      height: 116,
    },

    titleItemRed: {
      borderLeftStyle: 'solid',
      borderLeftWidth: 6,
      borderLeftColor: darkMode ? red[400] : red[600],
    },

    titleItemGreen: {
      borderLeftStyle: 'solid',
      borderLeftWidth: 6,
      borderLeftColor: darkMode ? green[400] : green[600],
    },

    titleItemYellow: {
      borderLeftStyle: 'solid',
      borderLeftWidth: 6,
      borderLeftColor: darkMode ? yellow[400] : yellow[800],
    },

    colorOrange: {
      color: darkMode ? orange[300] : orange[600],
    },

    colorYellow: {
      color: darkMode ? yellow[400] : yellow[800],
    },

    vacationDaysRemainingContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
      width: 48,
    },

    vacationDaysRemainingIcon: {
      fontSize: 42,
      lineHeight: 0,
    },
    vacationDaysRemainingValue: {
      fontSize: 28,
      lineHeight: 1,
      fontWeight: 'bold',
      color: darkMode ? grey[200] : grey[700],
    },
    vacationDaysRemainingDescription: {
      fontSize: 11,
      lineHeight: 1,
      color: darkMode ? grey[400] : grey[600],
    },

    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      width: '100%',
    },
    avatarLink: {
      textDecoration: 'none',
    },
    avatar: {
      height: 72,
      width: 72,
    },
    avatarDescriptionContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: 8,
      marginLeft: 8,
    },
    avatarDescriptionName: {
      fontSize: 20,
      lineHeight: 1.4,
      color: darkMode ? grey[300] : grey[800],
    },
    vacationNoVacationContainer: {
      display: 'flex',
      width: '100%',

      justifyContent: 'center',
    },
    vacationNoVacationText: {
      color: darkMode ? grey[400] : grey[600],
    },
    avatarDescriptionDateContainer: {
      display: 'flex',
      width: '100%',
    },
    avatarDescriptionDateDescriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginRight: 8,
    },
    avatarDescriptionDateDescription: {
      fontSize: 14,
      lineHeight: 1.2,
      color: darkMode ? grey[400] : grey[600],
    },
    avatarDescriptionDateValueContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },

    avatarDescriptionDateValue: {
      fontSize: 14,
      lineHeight: 1.2,
      color: darkMode ? grey[400] : grey[500],
    },
    actionsContainer: {
      display: 'flex',
      paddingLeft: 16,
    },
  });
});
