import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { avatarUrl } from '../utils/functions';
import ProfileTooltip from './ProfileTooltip';

interface ProfileFieldProps {
  userId: number | string | null | undefined;
  userName?: string;
  size?: number;
}
function ProfileField({ userId, userName = '', size = 30 }: ProfileFieldProps) {
  return (
    <ProfileTooltip userId={userId}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              height: size,
              width: size,
              borderRadius: '50%',
              border: '1px solid rgba(0,0,0,0.2)',
            }}
            src={avatarUrl(userId)}
            alt={userName}
            onError={(e: any) => {
              e.target.src = avatarUrl(0);
            }}
          />
        </Box>

        {userName}
      </Box>
    </ProfileTooltip>
  );
}

export { ProfileField };
