import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Box, Button, Icon, Backdrop, Typography, Paper, Tooltip } from '@mui/material';

import { Masonry, Pagination } from '@mui/lab';

import Axios, { Canceler } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import api from '../../../services/api';
import styles from './styles';
import Filters, { FiltersInputsProps } from '../../../components/Filters';
import SmallNewsCard from '../../../components/SmallNewsCard';
import { NewsCard } from './NewsCard';

export type OrderProp = 'asc' | 'desc';

export interface NewsData {
  id: number;
  next_news: number;
  preview_news: number;
  author_id: number;
  author_name: string;
  author_sector: string;
  news_title: string;
  news_content: string;
  category: number;
  category_description: string;
  likes: number;
  comments: number;
  created_at: number;
  created_at_formated: string;
  created_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  updated_at: number;
  updated_at_formated: string;
  updated_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  edit: number;
  comments_data: CommentsData[];
  likes_data: LikesData[];
  liked: boolean;
  tags: TagsData[];
  viewed: boolean;
  views_count: number;
  situation: number;
  gallery: string[];
}
export interface CommentsData {
  id: number;
  user_id: string;
  user_name: string;
  user_sector: string;
  text: string;
  date: string;
  date_formated: string;
  is_my: boolean;
}
export interface LikesData {
  user_id: string;
  user_name: string;
  user_sector: string;
  date: string;
}
export interface TagsData {
  tag: string;
}
export interface ResponseNewsData {
  total: number | 0;
  page: number | 1;
  pageTotal: number | 1;
  limit: number;
  sortDir: OrderProp;
  sortBy: string;
  user: number;
  news_add: number;
  data: NewsData[];
}
export interface NewsSearchProps {
  page?: number;
  sortBy?: string;
  limit?: number;
  sortDir?: string;
  date_start?: string;
  date_end?: string;
  title?: string;
  content?: string;
  category?: number;
  pagination?: boolean;
}
export interface UploadProps {
  path: string;
  name: string;
  size: number;
  type: string;
  mimetype: string;
  md5: string;
}

export default function NewsPage() {
  const classes = styles();
  const [responseData, setResponseData] = useState<ResponseNewsData>({} as ResponseNewsData);
  const [isEditor, setIsEditor] = useState(false);
  const [news, setNews] = useState<NewsData[]>([]);
  const [categorys, setCategorys] = useState([{}] as any);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [isLoadingCategorys, setIsLoadingCategorys] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-news-order-sort') || 'desc',
    localStorage.getItem('search-news-date-start') || '',
    localStorage.getItem('search-news-date-end') || '',
    localStorage.getItem('search-news-title') || '',
    localStorage.getItem('search-news-category') || '0',
  ]);
  const fetchingRef = useRef(null);
  const filtersInputs = [
    {
      type: 'combobox',
      id: 'news-order-sort',
      label: 'Ordenação',
      defaultValue: 'desc',
      comboBoxItems: [
        { value: 'desc', text: 'Data - Recentes/Antigos' },
        { value: 'asc', text: 'Data - Antigos/Recentes' },
      ],
    },
    {
      type: 'date',
      id: 'news-date-start',
      label: 'Data Início',
      defaultValue: '',
    },
    {
      type: 'date',
      id: 'news-date-end',
      label: 'Data Fim',
      defaultValue: '',
    },
    {
      type: 'text',
      id: 'news-title',
      label: 'Título',
      defaultValue: '',
    },
    {
      type: 'combobox',
      id: 'news-category',
      label: 'Categoria',
      defaultValue: 0,
      comboBoxItems: [
        { value: 0, text: 'Todas' },
        ...categorys?.map((category: any) => ({ value: category.id, text: category.description })),
      ],
    },
  ] as FiltersInputsProps[];
  const scrollToBottom = () => {
    fetchingRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [isFetchingNextPage]);

  const getCategorys = async () => {
    let cancel: Canceler;
    if (!isLoadingCategorys) {
      setIsLoadingCategorys(true);
      try {
        const { data } = await api.get('intranet/news/categorys', {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });
        const commentsData = data || [];
        setCategorys(commentsData);
      } catch (_error) {
        setIsError(true);
        setError(_error);
        console.error(error);
      }
    }
    setIsLoadingCategorys(false);
    return () => cancel();
  };

  const getNewsData = async () => {
    setIsLoading(true);
    let cancel: Canceler;
    const params = {
      page: 1,
      sortBy: 'date',
      limit: 12,
      sortDir: inputsValue[0],
      date_start: inputsValue[1],
      date_end: inputsValue[2],
      title: inputsValue[3],
      category: Number(inputsValue[4]),
    };
    if (!isFetching) {
      setIsFetching(true);
      try {
        const { data } = await api.get<ResponseNewsData>('intranet/news', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });

        const currentPage = Number(data.page);
        const total = Number(data.pageTotal);

        setPage(currentPage);
        setPageTotal(total);
        setHasNextPage(currentPage < total);
        setNews(data.data);
        setIsEditor(data.news_add === 1);
      } catch (_error) {
        setIsError(true);
        setError(_error);
        console.error(error);
      }
    }
    setIsFetching(false);
    setIsLoading(false);
    return () => cancel();
  };

  const fetchingNextPage = async () => {
    let cancel: Canceler;
    const params = {
      page: page + 1,
      sortBy: 'date',
      limit: 12,
      sortDir: inputsValue[0],
      date_start: inputsValue[1],
      date_end: inputsValue[2],
      title: inputsValue[3],
      category: Number(inputsValue[4]),
    };
    if (!isFetching || !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      try {
        const { data } = await api.get<ResponseNewsData>('intranet/news', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });

        const currentPage = Number(data.page);
        const total = Number(data.pageTotal);

        setPage(currentPage);
        setPageTotal(total);
        setHasNextPage(currentPage < total);
        setNews([...news, ...data.data]);
        setIsEditor(data.news_add === 1);
      } catch (_error) {
        setIsError(true);
        setError(_error);
        console.error(error);
      }
    }
    setIsFetchingNextPage(false);
    return () => cancel();
  };

  const RenderResult = () => {
    return (
      <Grid container spacing={2}>
        {news?.map((currentNews, index) => {
          return (
            <Grid key={currentNews.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <NewsCard news={currentNews} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const handleApplyFilter = (e: string[]) => {
    localStorage.setItem('search-news-order-sort', e[0]);
    localStorage.setItem('search-news-date-start', e[1]);
    localStorage.setItem('search-news-date-end', e[2]);
    localStorage.setItem('search-news-title', e[3]);
    localStorage.setItem('search-news-category', e[4]);

    setInputsValue(e);
  };

  useEffect(() => {
    getNewsData();
  }, [inputsValue]);

  useEffect(() => {
    getCategorys();
    getNewsData();
  }, []);
  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />

      {isEditor && (
        <Paper
          style={{
            padding: 14,
            width: '100%',
            marginBottom: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip title="Criar uma nova Notícia" aria-label="new-news">
            <Button color="primary" variant="contained" component={Link} to="/news/add">
              NOVO
            </Button>
          </Tooltip>
          <Tooltip title="Minhas Notícas publicas" aria-label="new-news">
            <Button color="secondary" variant="contained" component={Link} to="/news/published">
              PUBLICAÇÕES
            </Button>
          </Tooltip>
        </Paper>
      )}
      {!isLoading || !isFetching ? (
        <RenderResult />
      ) : (
        <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando notícias...
          </Typography>
        </Backdrop>
      )}
      {hasNextPage && (
        <>
          <Button
            variant="outlined"
            color="warning"
            fullWidth
            disabled={isLoading || isFetching || isFetchingNextPage}
            sx={{ mt: 4, mb: isFetchingNextPage ? 4 : 0 }}
            onClick={() => {
              fetchingNextPage();
            }}
          >
            {isFetchingNextPage ? ' Carregando, aguarde...' : 'Carregar Mais'}
          </Button>

          {isFetchingNextPage && <Box sx={{ height: '8rem', width: '100%' }} ref={fetchingRef} />}
        </>
      )}
    </Box>
  );
}
