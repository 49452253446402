import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, grey, indigo, lightBlue, teal } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      border: `1px solid ${darkMode ? grey[700] : grey[400]}`,
      backgroundColor: darkMode ? grey[800] : grey[100],
      borderRadius: 6,
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',

      backgroundColor: darkMode ? '#002f4a' : blue[900],
      color: darkMode ? grey[200] : grey[100],
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      padding: 8,

      '& > span': {
        height: '100%',
        width: '100%',
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflowX: 'auto',
      height: '100%',
      width: '100%',
      padding: 8,
    },
    table: {
      overflowX: 'auto',
      height: '100%',
    },
    titleHeader: {
      backgroundColor: darkMode ? '#666666' : grey[300],
      fontWeight: 'bold',
    },
    valueRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    },
    titleRow: {
      color: darkMode ? grey[200] : grey[100],
      backgroundColor: darkMode ? '#323232' : blue[800],
    },
  });
});
