import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  return createStyles({
    cardRoot: {
      width: '100%',
      maxWidth: 1080,
    },
    actionsButton: {
      marginLeft: 'auto',
    },
    avatar: {
      borderColor: '#eeeeee',
      backgroundColor: darkMode ? '#ef6c00' : '#e65100',
      fontSize: 26,
      textDecoration: 'none',
      width: theme.spacing(7),
      height: theme.spacing(7),
      '&:hover': {
        color: darkMode ? '#d6d6d6' : '#616161',
      },
    },
    newsTitle: {
      textDecoration: 'none',
      fontSize: 22,
    },
    newsTitleViewed: {
      textDecoration: 'none',
      color: darkMode ? '#b1b1b1' : '#808080',
      fontSize: 22,
    },
    newsDate: {
      fontSize: 12,
      color: darkMode ? '#b1b1b1' : '#808080',
    },
    userLink: {
      textDecoration: 'none',
      fontStyle: 'italic',
      color: darkMode ? '#b1b1b1' : '#808080',

      '&:hover': {
        color: darkMode ? '#d6d6d6' : '#616161',
      },
    },
    categoryLink: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: darkMode ? '#b1b1b1' : '#808080',

      '&:hover': {
        color: darkMode ? '#d6d6d6' : '#616161',
      },
    },
    badge: {
      '&>span': {
        justifyContent: 'flex-start',
        transform: 'scale(1) translate(22px, 2px)',
        color: darkMode ? '#b1b1b1' : '#808080',
        width: '100%',
      },
    },
    rootContent: {
      '& img': {
        display: 'block',
        maxWidth: '100%',
      },
    },
    newsPreview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: darkMode ? '#b1b1b1' : '#b3b3b3',
      borderRadius: '50%',
      borderStyle: 'solid',
      borderColor: darkMode ? '#d8d8d8' : '#7b7b7b',
      borderWidth: 2,
      minWidth: '64x',
      maxnWidth: '64px',
      overflow: 'hidden',
    },
    textContainer: {
      height: '100%',
      width: '100%',
      minHeight: 260,
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bcbcbc',
    },
  });
});
