/* eslint-disable dot-notation */
import '@syncfusion/ej2-base/styles/material3-dark.css';
import '@syncfusion/ej2-buttons/styles/material3-dark.css';
import '@syncfusion/ej2-layouts/styles/material3-dark.css';
import '@syncfusion/ej2-dropdowns/styles/material3-dark.css';
import '@syncfusion/ej2-inputs/styles/material3-dark.css';
import '@syncfusion/ej2-navigations/styles/material3-dark.css';
import '@syncfusion/ej2-popups/styles/material3-dark.css';
import '@syncfusion/ej2-react-kanban/styles/material3-dark.css';

import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { extend, addClass } from '@syncfusion/ej2-base';
import { useUrlQuery } from '../../../hooks/useUrlQuery';

const cardData = [
  {
    Id: 'Task 1',
    Title: 'Task - 29001',
    Status: 'Open',
    Summary: 'Analyze customer requirements.',
    Priority: 'High',
    Tags: 'Bug, Release Bug',
    RankId: 1,
    Assignee: 'Nancy Davloio',
  },
  {
    Id: 'Task 2',
    Title: 'Task - 29002',
    Status: 'InProgress',
    Summary: 'Add responsive support to applicaton',
    Priority: 'Low',
    Tags: 'Story, Kanban',
    RankId: 1,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 3',
    Title: 'Task - 29003',
    Status: 'Open',
    Summary: 'Show the retrived data from the server in grid control.',
    Priority: 'High',
    Tags: 'Bug, Breaking Issue',
    RankId: 2,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 4',
    Title: 'Task - 29004',
    Status: 'Open',
    Summary: 'Fix the issues reported in the IE browser.',
    Priority: 'High',
    Tags: 'Bug, Customer',
    RankId: 3,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 5',
    Title: 'Task - 29005',
    Status: 'Review',
    Summary: 'Improve application performance.',
    Priority: 'Normal',
    Tags: 'Story, Kanban',
    RankId: 1,
    Assignee: 'Steven walker',
  },
  {
    Id: 'Task 6',
    Title: 'Task - 29009',
    Status: 'Review',
    Summary: 'API Improvements.',
    Priority: 'Critical',
    Tags: 'Bug, Customer',
    RankId: 2,
    Assignee: 'Nancy Davloio',
  },
  {
    Id: 'Task 7',
    Title: 'Task - 29010',
    Status: 'Close',
    Summary: "Fix cannot open user's default database sql error.",
    Priority: 'High',
    Tags: 'Bug, Internal',
    RankId: 8,
    Assignee: 'Margaret hamilt',
  },
  {
    Id: 'Task 8',
    Title: 'Task - 29015',
    Status: 'Open',
    Summary: 'Fix the filtering issues reported in safari.',
    Priority: 'Critical',
    Tags: 'Bug, Breaking Issue',
    RankId: 4,
    Assignee: 'Margaret hamilt',
  },
  {
    Id: 'Task 9',
    Title: 'Task - 29016',
    Status: 'Review',
    Summary: 'Fix the issues reported in IE browser.',
    Priority: 'High',
    Tags: 'Bug, Customer',
    RankId: 3,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 10',
    Title: 'Task - 29017',
    Status: 'Review',
    Summary: 'Enhance editing functionality.',
    Priority: 'Normal',
    Tags: 'Story, Kanban',
    RankId: 4,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 11',
    Title: 'Task - 29018',
    Status: 'Close',
    Summary: 'Arrange web meeting with customer to get login page requirement.',
    Priority: 'High',
    Tags: 'Feature',
    RankId: 1,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 12',
    Title: 'Task - 29020',
    Status: 'Close',
    Summary: 'Login page validation.',
    Priority: 'Low',
    Tags: 'Bug',
    RankId: 2,
    Assignee: 'Margaret hamilt',
  },
  {
    Id: 'Task 13',
    Title: 'Task - 29021',
    Status: 'Close',
    Summary: 'Test the application in IE browser.',
    Priority: 'Normal',
    Tags: 'Bug',
    RankId: 3,
    Assignee: 'Steven walker',
  },
  {
    Id: 'Task 14',
    Title: 'Task - 29022',
    Status: 'Close',
    Summary: 'Analyze stored procedure.',
    Priority: 'Critical',
    Tags: 'CustomSample',
    RankId: 4,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 15',
    Title: 'Task - 29024',
    Status: 'Review',
    Summary: 'Check login page validation.',
    Priority: 'Low',
    Tags: 'Story',
    RankId: 5,
    Assignee: 'Nancy Davloio',
  },
  {
    Id: 'Task 16',
    Title: 'Task - 29025',
    Status: 'Close',
    Summary: 'Add input validation for editing.',
    Priority: 'Critical',
    Tags: 'Bug, Breaking Issue',
    RankId: 5,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 17',
    Title: 'Task - 29026',
    Status: 'InProgress',
    Summary: 'Improve performance of editing functionality.',
    Priority: 'Critical',
    Tags: 'Bug, Customer',
    RankId: 2,
    Assignee: 'Nancy Davloio',
  },
  {
    Id: 'Task 18',
    Title: 'Task - 29027',
    Status: 'Open',
    Summary: 'Arrange web meeting for cutomer requirement.',
    Priority: 'High',
    Tags: 'Story',
    RankId: 5,
    Assignee: 'Steven walker',
  },
  {
    Id: 'Task 19',
    Title: 'Task - 29029',
    Status: 'Review',
    Summary: 'Fix the issues reported by the customer.',
    Priority: 'High',
    Tags: 'Bug',
    RankId: 6,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 20',
    Title: 'Task - 29030',
    Status: 'InProgress',
    Summary: 'Test editing functionality',
    Priority: 'Low',
    Tags: 'Story',
    RankId: 3,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 21',
    Title: 'Task - 29031',
    Status: 'InProgress',
    Summary: 'Check filtering validation',
    Priority: 'Normal',
    Tags: 'Feature, Release',
    RankId: 4,
    Assignee: 'Janet Leverling',
  },
  {
    Id: 'Task 22',
    Title: 'Task - 29032',
    Status: 'InProgress',
    Summary: 'Arrange web meeting with customer to get login page requirement',
    Priority: 'Critical',
    Tags: 'Feature',
    RankId: 5,
    Assignee: 'Margaret hamilt',
  },
  {
    Id: 'Task 23',
    Title: 'Task - 29033',
    Status: 'Open',
    Summary: 'Arrange web meeting with customer to get editing requirements',
    Priority: 'Critical',
    Tags: 'Story, Improvement',
    RankId: 6,
    Assignee: 'Andrew Fuller',
  },
  {
    Id: 'Task 24',
    Title: 'Task - 29034',
    Status: 'InProgress',
    Summary: 'Fix the issues reported by the customer.',
    Priority: 'Critical',
    Tags: 'Bug, Customer',
    RankId: 6,
    Assignee: 'Steven walker',
  },
  {
    Id: 'Task 25',
    Title: 'Task - 29035',
    Status: 'Close',
    Summary: 'Fix the issues reported in Safari browser.',
    Priority: 'High',
    Tags: 'Bug',
    RankId: 6,
    Assignee: 'Nancy Davloio',
  },
  {
    Id: 'Task 26',
    Title: 'Task - 29036',
    Status: 'Review',
    Summary: 'Check Login page validation.',
    Priority: 'Critical',
    Tags: 'Bug, Customer',
    RankId: 7,
    Assignee: 'Margaret hamilt',
  },
  {
    Id: 'Task 27',
    Title: 'Task - 29037',
    Status: 'Open',
    Summary: 'Fix the issues reported in data binding.',
    Priority: 'Normal',
    Tags: 'Bug',
    Estimate: '3.5',
    RankId: 7,
    Assignee: 'Steven walker',
  },
  {
    Id: 'Task 28',
    Title: 'Task - 29038',
    Status: 'Close',
    Summary: 'Test editing functionality.',
    Priority: 'Normal',
    Tags: 'Story',
    Estimate: 0.5,
    RankId: 7,
    Assignee: 'Steven walker',
  },
];

const getString = assignee => {
  return assignee
    .match(/\b(\w)/g)
    .join('')
    .toUpperCase();
};

export default function HelpDeskHomePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();

  const currentUrlView = urlQuery.get('view') ?? 'dashboard';
  const currentUrlFilter = urlQuery.get('filter') ?? 'started';

  const [fetching, setFetching] = useState(false);

  const data = extend([], cardData, null, true);

  const fields = [
    { text: 'ID', key: 'Title', type: 'TextBox' },
    { key: 'Status', type: 'DropDown' },
    { key: 'Assignee', type: 'DropDown' },
    { key: 'RankId', type: 'TextBox' },
    { key: 'Summary', type: 'TextArea' },
  ];

  const cardRendered = args => {
    const val = args.data.Priority;
    addClass([args.element], val);
  };

  const columnTemplate = props => {
    return (
      <div className="header-template-wrap">
        <div className={`header-icon e-icons ${props.keyField}`} />
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  };

  const cardTemplate = props => {
    return (
      <div className="card-template">
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-header-title e-tooltip-text">{props.Title}</div>
          </div>
        </div>
        <Box sx={{ p: 2 }}>
          <Typography variant="overline">{props.Summary}</Typography>
        </Box>
        <Box sx={{ display: 'flex', p: 2, gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {props.Tags.split(',').map(tag => (
              <Chip key={tag} label={tag} variant="outlined" />
            ))}
          </Box>
          <Avatar alt={getString(props.Assignee)} sx={{ width: 32, height: 32 }} src="/static/images/avatar/1.jpg" />
        </Box>
      </div>
    );
  };

  return (
    <Box>
      <KanbanComponent
        id="kanban"
        cssClass="kanban-overview"
        keyField="Status"
        dataSource={data as any}
        // enableTooltip={true}
        swimlaneSettings={{ keyField: 'Assignee' }}
        cardSettings={{
          headerField: 'Title',
          template: cardTemplate.bind(this),
          selectionType: 'Multiple',
        }}
        dialogSettings={{ fields }}
        cardRendered={cardRendered.bind(this)}
      >
        <ColumnsDirective>
          <ColumnDirective headerText="To Do" keyField="Open" allowToggle={true} template={columnTemplate.bind(this)} />
          <ColumnDirective
            headerText="In Progress"
            keyField="InProgress"
            allowToggle={true}
            template={columnTemplate.bind(this)}
          />
          <ColumnDirective
            headerText="In Review"
            keyField="Review"
            allowToggle={true}
            template={columnTemplate.bind(this)}
          />
          <ColumnDirective headerText="Done" keyField="Close" allowToggle={true} template={columnTemplate.bind(this)} />
        </ColumnsDirective>
      </KanbanComponent>
    </Box>
  );
}
