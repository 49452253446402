import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    filterContainer: {
      marginBottom: 16,
    },
    filterRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 16,
      padding: 16,
      marginBottom: 16,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
      backgroundColor: darkMode ? '#2d2d2d' : '#ffffff',
      padding: 16,
      borderRadius: 4,
    },
    inputDepositSearch: {},
    gridContainer: { width: '100%', height: '100%' },

    liquidRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      gap: 16,
      padding: 16,
    },
    liquidTitle: {
      fontSize: 20,
    },
    liquidContainer: {
      margin: '0 auto',
    },
    liquidDepositContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: 4,
    },
    liquidDepositText: {
      fontSize: 14,
      color: darkMode ? '#d0d0d0' : '#444444',
    },

    paginationContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    loadingText: {
      fontWeight: 'bold',
      color: darkMode ? '#d0d0d0' : '#9c9c9c',
      fontSize: 16,
    },
    filterButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
    },
    filterButtonText: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8,
      fontSize: 12,
    },
  });
});
