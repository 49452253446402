import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    titleDescription: {
      fontSize: 12,
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
      marginTop: 12,
      marginBottom: 6,
    },
    attachmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 6,
    },
    attachmentItemContainer: {
      backgroundColor: darkMode ? grey[700] : grey[900],
      margin: 8,

      width: 86,
      height: 122,
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 6,
      '& button': {
        height: 26,

        minWidth: 42,
        padding: 0,
      },
      '& :last-child': {},
    },
    attachmentItemName: {
      fontSize: 11,
      color: darkMode ? grey[400] : grey[900],
      marginLeft: 4,
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    attachmentItemTypeContainer: {
      color: darkMode ? grey[800] : grey[900],

      backgroundColor: darkMode ? grey[400] : grey[200],
      margin: 4,
      borderRadius: 6,
      padding: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    attachmentItemTypeName: {
      fontSize: 11,
      maxWidth: 24,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    attachmentItemTypeImg: {
      maxWidth: '100%',
      maxHeight: '48px!important',
      borderRadius: 6,
      padding: 4,
    },
    attachmentItemSize: {
      fontSize: 9,
      color: darkMode ? grey[500] : grey[900],
      margin: 4,
    },
    attachmentCountText: {
      fontSize: 12,
      color: darkMode ? grey[500] : grey[900],
      margin: 4,
      marginLeft: 16,
    },
  });
});
