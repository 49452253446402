import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    content: {
      width: '100%',
      maxWidth: 1600,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionsRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 8,
      marginTop: 16,
      flexWrap: 'wrap',
      width: '100%',
      '& button': {
        margin: 8,
      },
    },
    sectionIcon: {
      marginRight: 8,
    },
    categoryTitle: {
      color: darkMode ? '#bdbdbd' : '#252525',
      fontSize: 20,
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    sectionTitleSeconday: {
      marginLeft: 8,
      color: darkMode ? '#bdbdbd' : '#757575',
      fontSize: 14,
    },

    progressBarRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    progressBar: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: darkMode ? '#1f1f1f' : '#6f6f6f',
      width: '100%',
      height: 6,
      backgroundColor: darkMode ? '#2b2b2b' : '#bdbdbd',

      borderRadius: 4,
      '& > div': {
        borderRightColor: darkMode ? '#1f1f1f' : '#6f6f6f',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRadius: 3,
      },
    },
    progressBarColor0: {
      '& > div': {
        backgroundColor: darkMode ? '#ff5a5a' : '#ff5f5f',
      },
    },
    progressBarColor25: {
      '& > div': {
        backgroundColor: darkMode ? '#ffa65f' : '#ffa65f',
      },
    },
    progressBarColor50: {
      '& > div': {
        backgroundColor: darkMode ? '#ffe85f' : '#ffe85f',
      },
    },
    progressBarColor75: {
      '& > div': {
        backgroundColor: darkMode ? '#98ff5f' : '#6af12c',
      },
    },
    progressBarColor100: {
      '& > div': {
        backgroundColor: darkMode ? '#00ca1e' : '#008c01',
        borderRightWidth: 0,
      },
    },
    progressBarText: {
      fontSize: 12,
      color: darkMode ? '#bdbdbd' : '#292929',
      minWidth: '100%',

      marginTop: 8,
    },
    timelineRoot: {
      borderRadius: 4,
      backgroundColor: darkMode ? '#272727' : '#e0e0e0',
    },
    productsRoot: {
      borderRadius: 4,
      padding: 4,
      backgroundColor: darkMode ? '#272727' : '#c7c7c7',
    },
    timelinePaper: {
      padding: '6px 16px',
    },
    timelineTitleText: {
      color: darkMode ? '#bdbdbd' : '#757575',
      fontSize: 12,
      fontStyle: 'italic',
    },
    table: {
      minWidth: 750,
      backgroundColor: darkMode ? '#383838' : '#f7f7f7',
    },
    tableHead: {
      fontWeight: 'bold',
      '& > tr > th': {
        backgroundColor: darkMode ? '#1d1d1d' : '#b1b1b1',
      },
    },
    tableBody: {},
    tableContainer: {
      maxHeight: 'calc(100vh - 298px)',
      height: '100%',
    },
    noItemsText: {
      color: darkMode ? '#bdbdbd' : '#383838',
      fontSize: 14,
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    timelineDotStart: {
      backgroundColor: darkMode ? '#3f9dff' : '#3f9dff',
    },
    timelineDotEnd: {
      backgroundColor: darkMode ? '#00ca1e' : '#008c01',
    },
    timelineDotCancel: {
      backgroundColor: darkMode ? '#ea4d4d' : '#ea4d4d',
    },
    timelineDotProductionStart: {
      backgroundColor: darkMode ? '#ac2cd6' : '#ac2cd6',
    },
    timelineDotProductionEnd: {
      backgroundColor: darkMode ? '#ac2cd6' : '#ac2cd6',
    },
  });
});
