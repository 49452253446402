import Dashboard from './dashboard';
import Search from './search';
import View from './view';
import { FollowUp } from './follow-up';

export default {
  Dashboard,
  Search,
  View,
  FollowUp,
};
