import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, deepPurple, green, grey, orange, red, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      width: '100%',
      overflow: 'scroll',
    },
    tableContainer: {
      minHeight: '312px',
      height: 'calc(100vh - 398px)',
      overflowY: 'auto',
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    helpdeskFooter: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& button, a': {
        fontSize: 12,
        paddingLeft: 8,
        paddingRight: 8,

        marginTop: 12,
      },
    },
    actionsRoot: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      justifyItems: 'center',
      flexWrap: 'nowrap',
      padding: 2,
      textAlign: 'left',
    },
    tableTitle: {
      color: darkMode ? orange[100] : orange[900],
      paddingBottom: 3,
    },
    helpdeskOnForecastDescription: {
      color: darkMode ? green[400] : green[900],
      fontSize: 10,
    },
    helpdeskNearForecastDescription: {
      color: darkMode ? yellow[400] : yellow[900],
      fontSize: 10,
    },
    helpdeskWaittingDateText: {
      fontSize: 12,
      color: darkMode ? orange[400] : orange[900],
    },
    helpdeskOutForecastDescription: {
      color: darkMode ? red[300] : red[900],
      fontSize: 10,
    },
    rowOpenIcon: {},
    paper: {
      padding: 2,
      textAlign: 'center',
    },
    footerRoot: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      justifyItems: 'center',
      flexWrap: 'nowrap',
      paddingTop: 2,

      textAlign: 'left',
    },
    onTimeSituationColor: {
      color: darkMode ? '#93fb9b' : '#13a71e',
    },
    offTimeSituationColor: {
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    warningIimeSituationColor: {
      color: darkMode ? yellow[400] : yellow[900],
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 8px',
    },
  });
});
