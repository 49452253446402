import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

interface ConfirmationDialogProps extends DialogProps {
  title: any;
  content: any;
  loading?: boolean;
  invertButtons?: boolean;
  onClickNo?: () => void;
  onClickYes?: () => void;
  onClose?: () => void;
}

const ConfirmationDialog = ({
  title,
  content,
  onClickNo,
  onClickYes,
  loading,
  invertButtons,
  onClose,
  ...props
}: ConfirmationDialogProps) => {
  const handleOnClickNo = () => {
    onClickNo?.();
  };
  const handleOnClickYes = () => {
    onClickYes?.();
  };
  const handleOnClose = () => {
    if (loading) return;
    onClose?.();
  };
  return (
    <Dialog
      {...props}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={handleOnClose}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: invertButtons ? 'row-reverse' : 'row' }}>
          <Button onClick={handleOnClickNo} color="inherit" disabled={loading}>
            Não
          </Button>
          <Button onClick={handleOnClickYes} color="info" disabled={loading}>
            Sim
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export { ConfirmationDialog };
export type { ConfirmationDialogProps };
