import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../../services/api';
import InputSearchAsync, { ValueProps } from '../../../../../components/inputs/InputSearchAsync';

interface Props {
  id: number | string;
  open: boolean;
  onClose?: () => void;
  onConfirm?: (user: ValueProps) => void;
  onOpen?: () => void;
}

function OsTransfer({ id, open, onClose, onOpen, onConfirm }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [user, setUser] = useState<ValueProps>(null);
  const handleOnOpen = () => {
    setUser(null);
    onOpen?.();
  };

  const handleOnClose = () => {
    onClose?.();
  };
  const handleTransfer = () => {
    onConfirm?.(user);
  };

  useEffect(() => {
    if (open) {
      handleOnOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth={true} maxWidth="md">
      <DialogTitle>
        O.S - Transferência
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleOnClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
            gap: 3,
            p: 2,
            borderRadius: 1,
            border: `1px solid ${isDarkMode ? '#3a3a3a' : '#d3d3d3'}`,
            overflowY: 'auto',
          }}
        >
          <Typography>Selecione o novo atentende</Typography>
          <InputSearchAsync
            fullWidth
            source="intranet-users"
            id="attendant"
            searchParams={{ sectores: '233;142;145;231;232' }}
            value={user}
            onChange={(e: ValueProps) => {
              setUser(e);
            }}
            label="Colaborador"
            multiple={false}
            startAdornment={<InputAdornment position="start" />}
            sx={{
              mb: 1,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleOnClose}>
          Cancelar
        </Button>
        <Button color="info" onClick={handleTransfer}>
          Transferir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export { OsTransfer };
