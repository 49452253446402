import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { EngOsHomeTitle, FiltersType } from '../EngOsHomeTitle';
import { EngOsSearchList, EngOsSearchListFilterType } from './EngOsSearchList';
import { EngOsSearchSchedulesList } from './EngOsSearchSchedulesList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
};

interface EngOsSearchProps {
  filters?: EngOsSearchListFilterType;
}

function EngOsSearch({ filters: attendanceFilters }: EngOsSearchProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const [filters, setFilters] = useState({
    budgets: [],
    customers: [],
    attendants: [],
    helpdesks: [],
    products: [],
    ended_at_end: '',
    os: [],
    orders: [],
    started_by: [],
    ended_by: [],
    situation: attendanceFilters?.situation ?? [],
    rework: '',
  } as EngOsSearchListFilterType);

  useEffect(() => {
    setFilters(old => ({ ...old, ...attendanceFilters }));
  }, [attendanceFilters]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography fontSize="1.3rem" sx={{ color: isDarkMode ? '#c1c1c1' : '#767676' }}>
            Pesquisar
          </Typography>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="search tab">
            <Tab label="O.S." id="os-tab" />
            <Tab label="Apontamentos" id="schedules-tab" />
            {/* <Tab label="Trâmites" id="chat-tab" /> */}
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EngOsHomeTitle
              title="O.S. - Filtro"
              initialFilter={attendanceFilters}
              onChange={_filters => {
                setFilters(old => ({ ...old, ..._filters }));
              }}
            />
            <Paper sx={{ p: 2 }}>
              <EngOsSearchList filters={filters} />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EngOsHomeTitle
              title="Apontamentos - Filtro"
              filterList={{
                situation: false,
                rework: false,
                customers: false,
                created_at: false,
                attendants: false,
                created_by: false,
              }}
              initialFilter={attendanceFilters}
              onChange={_filters => {
                setFilters(old => ({ ...old, ..._filters }));
              }}
            />
            <Paper sx={{ p: 2 }}>
              <EngOsSearchSchedulesList filters={filters} />
            </Paper>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}

export { EngOsSearch };

/*
<TabPanel value={currentTab} index={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EngOsHomeTitle
              title="Trâmites - Filtro"
              initialFilter={attendanceFilters}
              onChange={_filters => {
                setFilters(old => ({ ...old, ..._filters }));
              }}
            />
            <Paper sx={{ p: 2 }}>
              <EngOsSearchList filters={filters} />
            </Paper>
          </Box>
        </TabPanel>

*/
