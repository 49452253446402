import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, grey, indigo, lightBlue } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    chartBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',

      width: '100%',
      border: `1px solid ${darkMode ? grey[700] : grey[400]}`,
      backgroundColor: darkMode ? grey[800] : '#ffffff',
      borderRadius: 6,
    },
    chartTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      backgroundColor: darkMode ? '#002f4a' : blue[900],
      color: darkMode ? grey[200] : grey[100],
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      padding: 8,

      '& > span': {
        height: '100%',
        width: '100%',
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    chartContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 296,
      width: '100%',
      padding: 8,
    },
    chartDetails: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: 8,
      borderRadius: 6,
      borderColor: darkMode ? grey[600] : grey[400],
      borderTopStyle: 'solid',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopWidth: 1,
      backgroundColor: darkMode ? '#4a4a4a' : '#ffffff',
    },
    tooltipChartContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 8,
      border: '1px solid',
      borderRadius: 6,
      borderColor: darkMode ? grey[800] : grey[400],
      backgroundColor: darkMode ? grey[700] : grey[100],
    },
    tooltipChartTitle: {
      width: '100%',
      textAlign: 'center',
      color: darkMode ? grey[200] : grey[700],
    },
    tooltipChartDataLabel: {
      color: darkMode ? grey[200] : grey[700],
    },
    tooltipChartDataValue: {},
    tooltipChartPlaned: {
      color: darkMode ? grey[200] : grey[700],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
    },
    tooltipChartRealized: {
      color: darkMode ? grey[200] : grey[400],
    },
    tooltipChart: {
      backgroundColor: darkMode ? grey[800] : grey[100],
    },
    tooltipChartLabel: {
      color: darkMode ? grey[200] : grey[800],
    },
    detailsGrid: {
      '& > :nth-child(even)': {
        backgroundColor: darkMode ? '#565656' : '#f1f1f1',
      },
      '& > :nth-child(odd)': {},
    },
    detailsContainer: {
      padding: 2,
      color: darkMode ? grey[300] : grey[800],
      fontSize: 13,
      display: 'flex',
      justifyContent: 'space-between',
    },
  });
});
