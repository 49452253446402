import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Pagination, Tooltip, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { ColumnType, DataTable, RowType } from '../../../../../../components/DataTable';
import api from '../../../../../../services/api';
import { ValueProps } from '../../../../../../components/inputs/InputSearchAsync';
import { engOsSituationDescription, EngOsSituationType } from '../..';
import { toNumber } from '../../../../../../utils/functions';

type EngOsOS = {
  id: number;
  order_number: string;
  budget_number: number;
  helpdesk_id: number;
  task_id: number;
  task_description: string;
  created_at: string;
  created_at_formated: string;
  created_by: number;
  created_by_name: string;
  attendant_id: number;
  attendant_name: string;
  expected_start: string;
  expected_start_formated: string;
  started_at: string;
  started_at_formated: string;
  started_by: number;
  started_by_name: string;
  expected_end: string;
  expected_end_formated: string;
  ended_at: string;
  ended_at_formated: string;
  ended_by: number;
  ended_by_name: string;
  canceled_at: string;
  canceled_at_formated: string;
  canceled_by: string;
  canceled_by_name: string;
  obs: string;
  rework: number;
  minutes_spent: number;
  minutes_left: number;
  situation: EngOsSituationType;
  time_spent_formated: string;
  situation_description: string;
};

type ResponseData = {
  total: number;
  limit: number;
  page_current: number;
  page_total: number;
  result: EngOsOS[];
};

type EngOsAttendanceListFilterType = {
  os?: ValueProps[];
  tasks?: ValueProps[];
  budgets?: ValueProps[];
  customers?: ValueProps[];
  orders?: ValueProps[];
  helpdesks?: ValueProps[];
  situation?: ValueProps[];
  created_at_start?: string;
  created_at_end?: string;
  ended_at_start?: string;
  ended_at_end?: string;
};

const columnSize = (size: number | string) => ({ minWidth: size, width: size, maxWidth: size } as CSSProperties);

const renderStatusIndicator = (situation: EngOsSituationType) => {
  const sit = situation?.toString() || '';
  const is_late = sit.includes('_late');
  const is_not_started = sit.includes('not_started');
  const is_paused = sit.includes('paused');
  const is_started = sit.includes('started');
  const is_ended = sit.includes('ended');
  const is_canceled = sit.includes('canceled');
  return (
    <Tooltip title={engOsSituationDescription(sit)} disableInteractive arrow>
      {is_not_started ? (
        <ErrorOutlineOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_paused ? (
        <PauseCircleOutlineOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_started ? (
        <PlayCircleFilledWhiteOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_ended ? (
        <CheckCircleOutlineOutlinedIcon color={is_late ? 'error' : 'success'} />
      ) : is_canceled ? (
        <CancelOutlinedIcon color="error" />
      ) : (
        <HelpOutlineOutlinedIcon color="disabled" />
      )}
    </Tooltip>
  );
};

interface Props {
  filters?: EngOsAttendanceListFilterType;
}

function EngOsAttendanceList({ filters }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);
  const [sort, setSort] = useState({ by: 'order_number', dir: 'asc' });
  const [page, setPage] = useState(1);

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>('erp/engineering/os/list', {
        params: {
          order_by: sort.by,
          order_dir: sort.dir,
          attendants: 'own',
          os: filters.os.map(f => f.id).join(';'),
          tasks: filters.tasks.map(f => f.id).join(';'),
          budgets: filters.budgets.map(f => f.id).join(';'),
          customers: filters.customers.map(f => f.id).join(';'),
          orders: filters.orders.map(f => f.id).join(';'),
          helpdesks: filters.helpdesks.map(f => f.id).join(';'),
          situation: filters.situation.map(f => f.id).join(';'),
          created_at_start: filters.created_at_start,
          created_at_end: filters.created_at_end,
          ended_at_start: filters.ended_at_start,
          ended_at_end: filters.ended_at_end,
          page,
        },
      });
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };

  const handleSort = (by: string, dir: string) => {
    setSort({ by, dir });
  };

  const handlePage = (event: any, current_page: number) => {
    if (current_page < 1 || current_page > responseData.page_total) return;
    setPage(current_page);
  };

  const columns: ColumnType[] = [
    { id: 'id', content: 'Código', align: 'center', orderable: true, style: { ...columnSize(128) } },
    { id: 'task_description', content: 'Tarefa', align: 'left', orderable: true, style: { ...columnSize('100%') } },
    { id: 'situation', align: 'center', content: 'Status', orderable: true, style: { ...columnSize(158) } },
    { id: 'order_number', align: 'center', content: 'Pedido', orderable: true, style: { ...columnSize(158) } },
    { id: 'budget_number', align: 'center', content: 'Orçamento', orderable: true, style: { ...columnSize(158) } },
    { id: 'helpdesk_id', align: 'center', content: 'Helpdesk', orderable: true, style: { ...columnSize(158) } },
    { id: 'started_at', align: 'center', content: 'Inicio', orderable: true, style: { ...columnSize(158) } },
    { id: 'expected_end', align: 'center', content: 'Previsão', orderable: true, style: { ...columnSize(158) } },
    { id: 'ended_at', align: 'center', content: 'Fim', orderable: true, style: { ...columnSize(158) } },
    { id: 'canceled_at', align: 'center', content: 'Cancelado', orderable: true, style: { ...columnSize(158) } },
    { id: 'time_spend', align: 'center', content: 'Tempo Gasto', orderable: true, style: { ...columnSize(172) } },
  ];

  const rows: RowType[] = responseData?.result?.map(os => {
    return {
      cells: [
        {
          content: (
            <Tooltip title="Visualizar O.S" disableInteractive arrow>
              <Button component={Link} to={`/erp/engineering/os/${os?.id}`}>
                {os.id}
              </Button>
            </Tooltip>
          ),
          align: 'center',
        },
        {
          content:
            os.rework === 0 ? (
              os.task_description
            ) : (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Tooltip title="Retrabalho">
                  <SettingsBackupRestoreOutlinedIcon sx={{ cursor: 'default' }} color="warning" />
                </Tooltip>
                {os.task_description}
              </Box>
            ),
          align: 'left',
        },
        { content: renderStatusIndicator(os.situation), align: 'center' },
        {
          content:
            toNumber(os?.order_number) > 0 ? (
              <Tooltip title="Visualizar Pedido" disableInteractive arrow>
                <Button component={Link} to={`/erp/orders/${os?.order_number}`}>
                  {os.order_number}
                </Button>
              </Tooltip>
            ) : (
              '-'
            ),
          align: 'center',
        },
        { content: os.budget_number, align: 'center' },
        {
          content:
            toNumber(os?.helpdesk_id) > 0 ? (
              <Tooltip title="Visualizar Helpdesk" disableInteractive arrow>
                <Button component={Link} to={`/helpdesk/${os?.helpdesk_id}`} target="_blank" rel="noopener noreferrer">
                  {os.helpdesk_id}
                </Button>
              </Tooltip>
            ) : (
              '-'
            ),
          align: 'center',
        },
        { content: os.started_at_formated, align: 'center' },
        { content: os.expected_end_formated, align: 'center' },
        { content: os.ended_at_formated, align: 'center' },
        { content: os.canceled_at_formated, align: 'center' },
        { content: os.time_spent_formated, align: 'center' },
      ],
    } as RowType;
  });

  const renderTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        sx={{ width: '100%', overflow: 'auto' }}
        loading={fetching}
        onSort={handleSort}
        variant="primary"
        minHeight={96}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );

  useEffect(() => {
    getOsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filters]);

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      {renderTable}

      <Box display="flex" width="100%" sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          {`${responseData?.total ?? 0}`} {responseData?.total > 1 ? 'Registros encontrados ' : 'Registro encontrado '}
        </Typography>

        <Pagination
          size="medium"
          count={responseData?.page_total ?? 0}
          page={responseData?.page_current ?? 0}
          variant="outlined"
          shape="rounded"
          disabled={fetching || responseData?.page_total === 1}
          onChange={handlePage}
        />
        <Typography
          variant="caption"
          sx={{
            fontSize: 9,
            marginTop: 1,
            color: isDarkMode ? grey[400] : grey[700],
          }}
        />
      </Box>
    </Box>
  );
}

export { EngOsAttendanceList };
export type { EngOsAttendanceListFilterType, EngOsSituationType };
