import { Box } from '@mui/material';
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import UnderDevelopment from '../../../components/UnderDevelopment';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export default function PaydayPage() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  return (
    <Box sx={{ width: 360, height: 360 }}>
      <Bar
        options={options}
        data={{
          labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: labels.map(() => Math.floor(Math.random() * 100)),
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Dataset 2',
              data: labels.map(() => Math.floor(Math.random() * 100)),
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        }}
      />
    </Box>
  );
}
