import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Box, Button, IconButton, Pagination, Tooltip, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import userUndefinedImg from '../../../../../../images/user-undefined.jpg';

import { ColumnType, DataTable, RowType } from '../../../../../../components/DataTable';
import { CircularProgressWithLabel } from '../../../../../../components/CircularProgressWithLabel';
import api from '../../../../../../services/api';
import ProfileTooltip from '../../../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../../../utils/functions';
import { ValueProps } from '../../../../../../components/inputs/InputSearchAsync';
import { engOsSituationDescription, EngOsSituationType } from '../..';
import { ProfileField } from '../../../../../../components/ProfileField';

type EngOsOS = {
  id: number;
  task_id: number;
  task_description: string;
  expected_start: string;
  expected_start_formated: string;
  started_at: string;
  started_at_formated: string;
  expected_end: string;
  expected_end_formated: string;
  ended_at: string;
  ended_at_formated: string;
  attendant_id: number;
  attendant_name: string;
  minutes_left: number;
  time_spent: number;
  time_spent_formated: string;
  rework: number;
  situation: EngOsSituationType;
};

type EngOsTask = {
  id: number;
  description: string;
  parent_task: number;
  progress: number;
  expected_start: string;
  expected_start_formated: string;
  started_at: string;
  started_at_formated: string;
  expected_end: string;
  expected_end_formated: string;
  ended_at: string;
  ended_at_formated: string;
  total_os: number;
  total_started: number;
  total_ended: number;
  minutes_left: number;
  time_spent: number;
  time_spent_formated: string;
  situation: EngOsSituationType;
  tasks: EngOsTask[];
  oss: EngOsOS[];
};

type EngOsMain = {
  order_number: number;
  budget_number: number;
  helpdesk_id: number;
  client_id: number;
  client_name: string;
  progress: number;
  expected_start: string;
  expected_start_formated: string;
  started_at: string;
  started_at_formated: string;
  expected_end: string;
  expected_end_formated: string;
  ended_at: string;
  ended_at_formated: string;
  total_os: number;
  total_started: number;
  total_ended: number;
  minutes_left: number;
  time_spent: number;
  time_spent_formated: string;
  situation: EngOsSituationType;
  tasks: EngOsTask[];
};

type ResponseData = {
  total: number;
  limit: number;
  page_current: number;
  page_total: number;
  result: EngOsMain[];
};

type OSListFilterType = {
  budgets?: ValueProps[];
  customers?: ValueProps[];
  orders?: ValueProps[];
  attendants?: ValueProps[];
  situation?: ValueProps[];
  helpdesks?: ValueProps[];
};

const columnSize = (size: number | string) => ({ minWidth: size, width: size, maxWidth: size }) as CSSProperties;

const renderStatusIndicator = (situation: EngOsSituationType) => {
  const sit = situation?.toString() || '';
  const is_late = sit.includes('_late');
  const is_not_started = sit.includes('not_started');
  const is_paused = sit.includes('paused');
  const is_started = sit.includes('started');
  const is_ended = sit.includes('ended');
  const is_canceled = sit.includes('canceled');
  return (
    <Tooltip title={engOsSituationDescription(sit)} disableInteractive arrow>
      {is_not_started ? (
        <ErrorOutlineOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_paused ? (
        <PauseCircleOutlineOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_started ? (
        <PlayCircleFilledWhiteOutlinedIcon color={is_late ? 'warning' : 'info'} />
      ) : is_ended ? (
        <CheckCircleOutlineOutlinedIcon color={is_late ? 'error' : 'success'} />
      ) : is_canceled ? (
        <CancelOutlinedIcon color="error" />
      ) : (
        <HelpOutlineOutlinedIcon color="disabled" />
      )}
    </Tooltip>
  );
};

function OsTaskDetails({
  tasks = [],
  oss = [],
  handleSort = null,
}: {
  tasks: EngOsTask[];
  oss: EngOsOS[];
  handleSort: (by: string, dir: string) => void;
}) {
  let columns_details: ColumnType[] = [];
  let rows_details: RowType[] = [];

  if (oss.length > 0) {
    columns_details = [
      { id: 'situation', align: 'center', content: 'Status', style: { ...columnSize(50) } },
      { id: 'id', align: 'center', content: 'Código', style: { ...columnSize(120) } },
      { id: 'description', align: 'left', content: 'Classificação' },
      { id: 'user', align: 'left', content: 'Atendente' },
      { id: 'date_start', align: 'center', content: 'Inicio', style: { ...columnSize(158) } },
      { id: 'deadline', align: 'center', content: 'Previsão', style: { ...columnSize(158) } },
      { id: 'date_end', align: 'center', content: 'Fim', style: { ...columnSize(158) } },
      { id: 'time_spend', align: 'center', content: 'Tempo Gasto', style: { ...columnSize(128) } },
    ];

    rows_details = oss.map(os => {
      return {
        cells: [
          { content: renderStatusIndicator(os.situation), align: 'center', style: { maxWidth: 30 } },
          {
            content: (
              <Tooltip title="Visualizar O.S" disableInteractive arrow>
                <Button component={Link} to={`/erp/engineering/os/${os?.id}`} target="_blank" rel="noopener noreferrer">
                  {os.id}
                </Button>
              </Tooltip>
            ),
            align: 'right',
          },
          {
            content:
              os.rework === 0 ? (
                os.task_description
              ) : (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Tooltip title="Retrabalho">
                    <SettingsBackupRestoreOutlinedIcon sx={{ cursor: 'default' }} color="warning" />
                  </Tooltip>
                  {os.task_description}
                </Box>
              ),
            align: 'left',
          },
          {
            content: <ProfileField userId={os.attendant_id} userName={os.attendant_name} />,
            align: 'left',
          },
          { content: os.started_at_formated, align: 'center' },
          { content: os.expected_end_formated, align: 'center' },
          { content: os.ended_at_formated, align: 'center' },
          { content: os.time_spent_formated, align: 'center' },
        ],
      } as RowType;
    });
  } else {
    columns_details = [
      {
        id: 'situation',
        align: 'center',
        content: 'Status',
        style: { ...columnSize(128) },
      },
      { id: 'description', align: 'left', content: 'Tarefa' },
      { id: 'progress', align: 'center', content: 'Progresso', style: { ...columnSize(128) } },
      { id: 'date_start', align: 'center', content: 'Inicio', style: { ...columnSize(158) } },
      { id: 'deadline', align: 'center', content: 'Previsão', style: { ...columnSize(158) } },
      { id: 'date_end', align: 'center', content: 'Fim', style: { ...columnSize(158) } },
      { id: 'time_spend', align: 'center', content: 'Tempo Gasto', style: { ...columnSize(128) } },
    ];

    rows_details = tasks.map(task => {
      return {
        cells: [
          { content: renderStatusIndicator(task.situation), align: 'center', style: { ...columnSize(78) } },
          { content: task.description, align: 'left' },
          { content: <CircularProgressWithLabel value={task.progress} />, align: 'center' },
          { content: task.started_at_formated, align: 'center' },
          { content: task.expected_end_formated, align: 'center' },
          { content: task.ended_at_formated, align: 'center' },
          { content: task.time_spent_formated, align: 'center' },
        ],
        content: <OsTaskDetails tasks={task.tasks} oss={task.oss} handleSort={handleSort} />,
      } as RowType;
    });
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 1, pl: 2, width: '100%' }}>
      <Typography>{oss.length > 0 ? 'O.S.' : 'Tarefas'}</Typography>
      <DataTable
        columns={columns_details}
        rows={rows_details}
        sx={{ width: '100%', height: 'calc(100vh - 316px)', overflow: 'auto' }}
        loading={false}
        onSort={handleSort}
        variant="primary"
      />
    </Box>
  );
}

interface Props {
  filters?: OSListFilterType;
}

function OSList({ filters }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);
  const [sort, setSort] = useState({ by: 'order_number', dir: 'asc' });
  const [page, setPage] = useState(1);

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>('erp/engineering/os/dashboard/list', {
        params: {
          order_by: sort.by,
          order_dir: sort.dir,
          attendants: filters.attendants.map(f => f.id).join(';'),
          budgets: filters.budgets.map(f => f.id).join(';'),
          customers: filters.customers.map(f => f.id).join(';'),
          orders: filters.orders.map(f => f.id).join(';'),
          helpdesks: filters.helpdesks.map(f => f.id).join(';'),
          situation: filters.situation.map(f => f.id).join(';'),
          page,
        },
      });
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };

  const handleSort = (by: string, dir: string) => {
    setSort({ by, dir });
  };

  const handlePage = (event: any, current_page: number) => {
    if (current_page < 1 || current_page > responseData.page_total) return;
    setPage(current_page);
  };

  const columns: ColumnType[] = [
    {
      id: 'situation',
      content: 'Status',
      align: 'center',
      style: { ...columnSize(128) },
      orderable: true,
    },
    { id: 'order_number', align: 'center', content: 'Pedido', orderable: true, style: { ...columnSize(128) } },
    { id: 'budget_number', align: 'center', content: 'Orçamento', orderable: true, style: { ...columnSize(128) } },
    { id: 'helpdesk_id', align: 'center', content: 'Helpdesk', orderable: true, style: { ...columnSize(128) } },
    { id: 'client_name', align: 'left', content: 'Cliente', orderable: true },
    { id: 'progress', align: 'center', content: 'Progresso', orderable: true, style: { ...columnSize(128) } },
    { id: 'started_at', align: 'center', content: 'Inicio', orderable: true, style: { ...columnSize(158) } },
    { id: 'expected_end', align: 'center', content: 'Previsão', orderable: true, style: { ...columnSize(158) } },
    { id: 'ended_at', align: 'center', content: 'Fim', orderable: true, style: { ...columnSize(158) } },
    { id: 'time_spend', align: 'center', content: 'Tempo Gasto', orderable: true, style: { ...columnSize(172) } },
  ];

  const rows: RowType[] = responseData?.result?.map(os => {
    return {
      cells: [
        { content: renderStatusIndicator(os.situation), align: 'center', style: { ...columnSize(78) } },
        {
          content:
            os?.order_number > 0 ? (
              <Tooltip title="Visualizar Pedido" disableInteractive arrow>
                <Button
                  component={Link}
                  to={`/erp/orders/${os?.order_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {os.order_number}
                </Button>
              </Tooltip>
            ) : (
              '-'
            ),
          align: 'center',
        },
        { content: os.budget_number, align: 'center' },
        {
          content:
            os?.helpdesk_id > 0 ? (
              <Tooltip title="Visualizar Helpdesk" disableInteractive arrow>
                <Button component={Link} to={`/helpdesk/${os?.helpdesk_id}`} target="_blank" rel="noopener noreferrer">
                  {os.helpdesk_id}
                </Button>
              </Tooltip>
            ) : (
              '-'
            ),
          align: 'center',
        },
        {
          content: (
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 420 }}>
              <Typography fontSize="0.75rem">{os.client_id}</Typography>
              <Typography fontSize="0.875rem">{os.client_name}</Typography>
            </Box>
          ),
          align: 'left',
        },
        { content: <CircularProgressWithLabel value={os.progress} />, align: 'center' },
        { content: os.started_at_formated, align: 'center' },
        { content: os.expected_end_formated, align: 'center' },
        { content: os.ended_at_formated, align: 'center' },
        { content: os.time_spent_formated, align: 'center' },
      ],
      content: <OsTaskDetails tasks={os.tasks ?? []} oss={[]} handleSort={handleSort} />,
    } as RowType;
  });

  const renderTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        sx={{ width: '100%', overflow: 'auto' }}
        loading={fetching}
        onSort={handleSort}
        variant="primary"
        minHeight={96}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );

  useEffect(() => {
    getOsData();
  }, [page, sort, filters]);

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      {renderTable}

      <Box display="flex" width="100%" sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          {`${responseData?.total ?? 0}`} {responseData?.total > 1 ? 'Registros encontrados ' : 'Registro encontrado '}
        </Typography>

        <Pagination
          size="medium"
          count={responseData?.page_total ?? 0}
          page={responseData?.page_current ?? 0}
          variant="outlined"
          shape="rounded"
          disabled={fetching || responseData?.page_total === 1}
          onChange={handlePage}
        />
        <Typography
          variant="caption"
          sx={{
            fontSize: 9,
            marginTop: 1,
            color: isDarkMode ? grey[400] : grey[700],
          }}
        />
      </Box>
    </Box>
  );
}

export { OSList };
export type { OSListFilterType, EngOsSituationType };
