import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    newsPreview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 8,
      backgroundColor: darkMode ? '#b1b1b1' : '#b3b3b3',
      borderRadius: 8,
      borderStyle: 'solid',
      borderColor: darkMode ? '#d8d8d8' : '#7b7b7b',
      borderWidth: 2,
      minWidth: '112px',
      maxnWidth: '112px',
      overflow: 'hidden',
    },
    thumbnailTitleText: {
      color: darkMode ? '#d8d8d8' : '#505050',
    },
    thumbnailSubTitleText: {
      fontSize: 10,
      color: darkMode ? '#b7b7b7' : '#545454',
    },
    paperTitleText: {
      color: darkMode ? '#d8d8d8' : '#505050',
    },
  });
});
