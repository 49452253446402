import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import LogsIcon from '@mui/icons-material/ArticleOutlined';
import DoneIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SwapIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import ChatIcon from '@mui/icons-material/CommentOutlined';
import TimeIcon from '@mui/icons-material/PendingActionsOutlined';
import AlertIcon from '@mui/icons-material/InfoOutlined';
import StartIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AttachIcon from '@mui/icons-material/AttachFileOutlined';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import api from '../../../../../services/api';
import ProfileTooltip from '../../../../../components/ProfileTooltip';
import { avatarUrl, toNumber } from '../../../../../utils/functions';
import FeedbackAlert, { FeedbackProps } from '../../../../../components/FeedbackAlert';
import { ConfirmationDialog, ConfirmationDialogProps } from '../../../../../components/ConfirmationDialog';
import NoDataImg from '../../../../../images/no_data.svg';
import NoDataLightImg from '../../../../../images/no_data_light.svg';

import { OsLogsView } from './OsLogsView';
import { OsChatView } from './OsChatView';
import { OsAttachs } from './OsAttachs';
import { OsTransfer } from './OsTransfer';
import { ValueProps } from '../../../../../components/inputs/InputSearchAsync';
import { EngOsSituationType } from '../home/EnOsDashboard/OSList';
import { OsSchedulesView } from './OsSchedulesView';

type OsLogType = {
  id: number;
  type: string;
  field: string;
  value: string;
  created_at: string;
  created_at_formated: string;
  created_by: number;
  created_by_name: string;
};

type OsAttachmentType = {
  id: number;
  file_name: string;
  url: string;
  uploaded_by: number;
  uploaded_by_name: string;
  uploaded_at: string;
  file_type: string;
  file_size: number;
  uploaded_at_formated: string;
  file_size_formated: string;
};

type OsDataType = {
  id: number;
  order_number: number;
  budget_number: number;
  task_id: number;
  product_id: string;
  drawn_id: string;
  helpdesk_id: number;
  task_description: string;
  created_at: string;
  created_by: number;
  created_by_name: string;
  attendant_id: number;
  attendant_name: string;
  expected_start: string;
  started_at: null | string;
  started_by: null | number;
  started_by_name: null | string;
  expected_end: string;
  ended_at: null | string;
  ended_by: null | number;
  ended_by_name: null | string;
  canceled_at: null | string;
  canceled_at_formated: null | string;
  canceled_by: null | null;
  canceled_by_name: null | null;
  time_spent: number;
  time_spent_formated: string;
  obs: string;
  rework: number;
  created_at_formated: string;
  expected_start_formated: string;
  expected_end_formated: string;
  started_at_formated: string;
  ended_at_formated: string;
  situation: EngOsSituationType;
  situation_description: string;
  attachments: OsAttachmentType[];
  the_attendant: boolean;
  the_creator: boolean;
  logs: OsLogType[];
};

export default function OsView() {
  const { id: osId } = useParams();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [osData, setOsData] = useState<OsDataType>({} as OsDataType);
  const [fetching, setFetching] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [invalidOS, setInvalidOS] = useState(false);

  const [showLogs, setShowLogs] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showAttachs, setShowAttachs] = useState(false);
  const [showSchedules, setShowSchedules] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);

  const [confirmation, setConfirmation] = useState<ConfirmationDialogProps>(null);

  const [feedback, setFeedback] = useState<FeedbackProps>({
    message: '',
    show: false,
  });

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<OsDataType>(`erp/engineering/os/${osId}`, {
        params: { type: 'os' },
      });

      setOsData(data);
    } catch (error) {
      const { data: response } = error.response;
      if (response.message === 'invalid_os') {
        setInvalidOS(true);
      }
    }
    setFetching(false);
  };

  const postOsStart = async (os_id: number | string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/start`, {});

      getOsData();
      setFeedback({
        message: 'O.S. iniciada com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'its_not_the_attendant'
            ? 'Somente o antendente que pode iniciar a O.S.'
            : response.message === 'invalid_os'
              ? 'OS inválida.'
              : response.message === 'has_already_been_started'
                ? 'O.S. já iniciada.'
                : response.message === 'os_canceled'
                  ? 'Não é possivel iniciar uma O.S. cancelada.'
                  : response.message === 'os_ended'
                    ? 'Não é possivel iniciar uma O.S. já finalizada.'
                    : response.message === 'other_open_os'
                      ? 'Não é possivel iniciar uma O.S. com outras O.S. em aberto.'
                      : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const postOsPause = async (os_id: number | string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/pause`, {});

      getOsData();
      setFeedback({
        message: 'O.S. parada com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'its_not_the_attendant'
            ? 'Somente o antendente que pode iniciar a O.S.'
            : response.message === 'invalid_os'
              ? 'OS inválida.'
              : response.message === 'has_already_been_started'
                ? 'O.S. já iniciada.'
                : response.message === 'os_canceled'
                  ? 'Não é possivel iniciar uma O.S. cancelada.'
                  : response.message === 'os_ended'
                    ? 'Não é possivel iniciar uma O.S. já finalizada.'
                    : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const postOsContinue = async (os_id: number | string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/play`, {});

      getOsData();
      setFeedback({
        message: 'O.S. parada com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'its_not_the_attendant'
            ? 'Somente o antendente que pode iniciar a O.S.'
            : response.message === 'invalid_os'
              ? 'OS inválida.'
              : response.message === 'has_already_been_started'
                ? 'O.S. já iniciada.'
                : response.message === 'os_canceled'
                  ? 'Não é possivel iniciar uma O.S. cancelada.'
                  : response.message === 'os_ended'
                    ? 'Não é possivel iniciar uma O.S. cancelada.'
                    : response.message === 'other_open_os'
                      ? 'Não é possivel iniciar uma O.S. com outras O.S. em aberto.'
                      : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const postOsTransfer = async (os_id: number | string, user: ValueProps) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/transfer`, {
        target_id: user?.id,
      });

      getOsData();
      setShowTransfer(false);
      setFeedback({
        message: 'O.S. transferida com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'invalid_target_id'
            ? 'Atendente inválido'
            : response.message === 'its_not_the_attendant'
              ? 'Somente o antendente que pode trânsferir a O.S.'
              : response.message === 'invalid_os'
                ? 'OS inválida.'
                : response.message === 'target_already_the_attendant'
                  ? 'O colaborador selecionado já é o atendente atual.'
                  : response.message === 'os_canceled'
                    ? 'Não é possivel iniciar uma O.S. cancelada.'
                    : response.message === 'os_ended'
                      ? 'Não é possivel iniciar uma O.S. já finalizada.'
                      : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const postOsCancel = async (os_id: number | string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/cancel`, {});

      getOsData();
      setFeedback({
        message: 'O.S. cancelada com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'invalid_os'
            ? 'OS inválida.'
            : response.message === 'its_not_the_attendant'
              ? 'Somente o antendente que pode cancelar a O.S.'
              : response.message === 'os_canceled'
                ? 'Não é possivel cancelar uma O.S. já cancelada.'
                : response.message === 'os_ended'
                  ? 'Não é possivel cancelar uma O.S. já finalizada.'
                  : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const postOsEnd = async (os_id: number | string) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.post(`erp/engineering/os/${os_id}/end`, {});

      getOsData();
      setFeedback({
        message: 'O.S. finalizada com sucesso',
        show: true,
        type: 'success',
      });
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'invalid_os'
            ? 'OS inválida.'
            : response.message === 'its_not_the_attendant'
              ? 'Somente o antendente que pode finalizar a O.S.'
              : response.message === 'os_not_started'
                ? 'Não é possivel finalizar uma O.S. não iniciada.'
                : response.message === 'os_already_canceled'
                  ? 'Não é possivel finalizar uma O.S. já cancelada.'
                  : response.message === 'os_already_ended'
                    ? 'Não é possivel finalizar uma O.S. já finalizada.'
                    : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setConfirmation(old => ({ ...old, open: false }));
    setSubmiting(false);
  };

  const handleStart = () => {
    if (['not_started', 'not_started_late'].includes(osData?.situation)) {
      const onClickNo = () => {
        setConfirmation(old => ({ ...old, open: false }));
      };
      const onClickYes = () => {
        postOsStart(osId);
      };
      setConfirmation({
        open: true,
        title: 'Realmente deseja INICIAR essa O.S.?',
        content: 'Essa ação não pode ser desfeita',
        onClickNo,
        onClickYes,
      });
    }
  };

  const handleContinue = () => {
    if (['paused', 'paused_late'].includes(osData?.situation)) {
      const onClickNo = () => {
        setConfirmation(old => ({ ...old, open: false }));
      };
      const onClickYes = () => {
        postOsContinue(osId);
      };
      setConfirmation({
        open: true,
        title: 'Realmente deseja CONTINUAR essa O.S.?',
        content: 'Essa ação vai iníciar o contador do tempo gasto',
        onClickNo,
        onClickYes,
      });
    }
  };

  const handlePause = () => {
    if (['started', 'started_late'].includes(osData?.situation)) {
      const onClickNo = () => {
        setConfirmation(old => ({ ...old, open: false }));
      };
      const onClickYes = () => {
        postOsPause(osId);
      };
      setConfirmation({
        open: true,
        title: 'Realmente deseja PAUSAR essa O.S.?',
        content: 'Essa ação vai parar o contador do tempo gasto',
        onClickNo,
        onClickYes,
      });
    }
  };

  const handleCancel = () => {
    if (!['canceled', 'canceled_late', 'ended', 'ended_late'].includes(osData?.situation)) {
      const onClickNo = () => {
        setConfirmation(old => ({ ...old, open: false }));
      };
      const onClickYes = () => {
        postOsCancel(osId);
      };
      setConfirmation({
        open: true,
        title: 'Realmente deseja CANCELAR essa O.S.?',
        content: 'Essa ação não pode ser desfeita',
        onClickNo,
        onClickYes,
      });
    }
  };

  const handleEnd = () => {
    if (['started', 'started_late', 'paused', 'paused_late'].includes(osData?.situation)) {
      const onClickNo = () => {
        setConfirmation(old => ({ ...old, open: false }));
      };

      const onClickYes = () => {
        postOsEnd(osId);
      };

      setConfirmation({
        open: true,
        title: 'Realmente deseja FINALIZAR essa O.S.?',
        content: 'Essa ação não pode ser desfeita',
        onClickNo,
        onClickYes,
      });
    }
  };

  function InputUser({ userId, id, value, label }: { userId: number; id: string; value: string; label: string }) {
    return (
      <TextField
        id={id}
        label={label}
        variant="outlined"
        value={value ?? '-'}
        InputProps={{
          startAdornment:
            userId > 0 ? (
              <ProfileTooltip userId={userId.toString()} openNewTab>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img
                    style={{
                      width: 22,
                      height: 22,
                      borderRadius: '50%',
                      marginRight: 8,
                    }}
                    src={avatarUrl(userId)}
                    alt={value}
                    onError={(e: any) => {
                      e.target.src = avatarUrl(0, true);
                    }}
                  />
                </Box>
              </ProfileTooltip>
            ) : (
              <InputAdornment position="start" />
            ),
        }}
        size="small"
        fullWidth
      />
    );
  }

  const inputId = useMemo(
    () => (
      <TextField
        id="input-id"
        label="Código"
        variant="outlined"
        value={osData?.id ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.id],
  );

  const inputSituation = useMemo(
    () => (
      <TextField
        id="input-id"
        label="Situação"
        variant="outlined"
        value={osData?.situation_description}
        InputProps={{
          startAdornment: ['canceled', 'canceled_late'].includes(osData?.situation) ? (
            <CancelIcon sx={{ mr: 1 }} color="error" />
          ) : ['ended', 'ended_late'].includes(osData?.situation) ? (
            <DoneIcon sx={{ mr: 1 }} color={osData?.situation === 'ended' ? 'success' : 'error'} />
          ) : ['started', 'started_late'].includes(osData?.situation) ? (
            <StartIcon sx={{ mr: 1 }} color={osData?.situation === 'started' ? 'success' : 'error'} />
          ) : (
            <AlertIcon sx={{ mr: 1 }} color="warning" />
          ),
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.situation],
  );

  const inputOrder = useMemo(
    () => (
      <TextField
        id="input-order"
        label="Pedido"
        variant="outlined"
        value={osData?.order_number > 0 ? osData?.order_number : null ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment:
            toNumber(osData?.order_number) > 0 ? (
              <Tooltip title="Visualizar Pedido" disableInteractive arrow>
                <IconButton
                  color="default"
                  aria-label="select"
                  component={Link}
                  to={`/erp/orders/${osData?.order_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <OpenInNewIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : null,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.order_number],
  );

  const inputBudget = useMemo(
    () => (
      <TextField
        id="input-budget"
        label="Orçamento"
        variant="outlined"
        value={osData?.budget_number > 0 ? osData?.budget_number : null ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.budget_number],
  );

  const inputHelpdesk = useMemo(
    () => (
      <TextField
        id="input-helpdesk"
        label="Helpdesk"
        variant="outlined"
        value={osData?.helpdesk_id > 0 ? osData?.helpdesk_id : null ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment:
            toNumber(osData?.helpdesk_id) > 0 ? (
              <Tooltip title="Visualizar Helpdesk" disableInteractive arrow>
                <IconButton
                  color="default"
                  aria-label="select"
                  component={Link}
                  to={`/helpdesk/${osData?.helpdesk_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <OpenInNewIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : null,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.helpdesk_id],
  );

  const inputProduct = useMemo(
    () => (
      <TextField
        id="input-product"
        label="Produto"
        variant="outlined"
        value={osData?.product_id?.length > 0 ? osData?.product_id : null ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.product_id],
  );

  const inputDrawn = useMemo(
    () => (
      <TextField
        id="input-drawn"
        label="Desenho"
        variant="outlined"
        value={osData?.drawn_id?.length > 0 ? osData?.drawn_id : null ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.drawn_id],
  );
  const inputTask = useMemo(
    () => (
      <TextField
        id="input-task"
        label="Tarefa"
        variant="outlined"
        value={osData?.task_description ?? '-'}
        InputProps={{
          startAdornment:
            osData?.rework === 0 ? (
              <InputAdornment position="start" />
            ) : (
              <Tooltip title="Retrabalho">
                <SettingsBackupRestoreOutlinedIcon sx={{ mr: 1, cursor: 'default' }} color="warning" />
              </Tooltip>
            ),
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.task_id],
  );

  const inputCreatedAt = useMemo(
    () => (
      <TextField
        id="input-created-at"
        label="Data/Hora Geração"
        variant="outlined"
        value={osData?.created_at_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.created_at_formated],
  );

  const inputCreatedBy = useMemo(
    () => (
      <InputUser id="input-created-by" label="Criado por" userId={osData?.created_by} value={osData?.created_by_name} />
    ),
    [osData?.created_by, osData?.created_by_name],
  );

  const inputAttendant = useMemo(
    () => (
      <InputUser id="input-attendant" label="Atendente" userId={osData?.attendant_id} value={osData?.attendant_name} />
    ),
    [osData?.attendant_id, osData?.attendant_name],
  );

  const inputExpectedStart = useMemo(
    () => (
      <TextField
        id="input-expected-start"
        label="Previsão Início"
        variant="outlined"
        value={osData?.expected_start_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.expected_start_formated],
  );

  const inputExpectedEnd = useMemo(
    () => (
      <TextField
        id="input-expected-end"
        label="Previsão fim"
        variant="outlined"
        value={osData?.expected_end_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.expected_end_formated],
  );

  const inputStartedAt = useMemo(
    () => (
      <TextField
        id="input-started-at"
        label="Data/Hora Início"
        variant="outlined"
        value={osData?.started_at_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.started_at_formated],
  );

  const inputStartedBy = useMemo(
    () => (
      <InputUser
        id="input-started-by"
        label="Iníciado por"
        userId={osData?.started_by}
        value={osData?.started_by_name}
      />
    ),
    [osData?.started_by, osData?.started_by_name],
  );

  const inputEndedAt = useMemo(
    () => (
      <TextField
        id="input-ended-at"
        label="Data/Hora Fim"
        variant="outlined"
        value={osData?.ended_at_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.ended_at_formated],
  );

  const inputEndedBy = useMemo(
    () => (
      <InputUser id="input-ended-by" label="Finalizado por" value={osData?.ended_by_name} userId={osData?.ended_by} />
    ),
    [osData?.ended_by, osData?.ended_by_name],
  );

  const inputCanceledAt = useMemo(
    () => (
      <TextField
        id="input-canceled-at"
        label="Data/Hora Cancelamento"
        variant="outlined"
        value={osData?.canceled_at_formated ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.canceled_at_formated],
  );

  const inputCanceledBy = useMemo(
    () => (
      <InputUser
        id="input-canceled-by"
        label="Cancelado por"
        value={osData?.canceled_by_name}
        userId={osData?.canceled_by}
      />
    ),
    [osData?.canceled_by, osData?.canceled_by_name],
  );

  const inputTimeSpent = useMemo(
    () => (
      <TextField
        id="input-time-spent"
        label="Tempo Gasto"
        variant="outlined"
        value={osData?.time_spent_formated}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        fullWidth
      />
    ),
    [osData?.time_spent_formated],
  );

  const inputObs = useMemo(
    () => (
      <TextField
        id="input-obs"
        label="Obs."
        variant="outlined"
        value={osData?.obs ?? '-'}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        size="small"
        multiline
        fullWidth
        minRows={3}
      />
    ),
    [osData?.obs],
  );

  const osAttachs = useMemo(
    () => <OsAttachs id={osId} open={showAttachs} handleClose={() => setShowAttachs(false)} />,
    [osId, showAttachs],
  );

  const osSchedules = useMemo(
    () => <OsSchedulesView id={osId} open={showSchedules} handleClose={() => setShowSchedules(false)} />,
    [osId, showSchedules],
  );

  const osChats = useMemo(
    () => (
      <OsChatView
        id={osId}
        open={showChat}
        handleClose={() => setShowChat(false)}
        disableChat={['canceled', 'canceled_late', 'ended', 'ended_late'].includes(osData?.situation)}
      />
    ),
    [osId, showChat],
  );

  const osLogs = useMemo(
    () => <OsLogsView id={osId} open={showLogs} handleClose={() => setShowLogs(false)} />,
    [osId, showLogs],
  );

  const osTransfer = useMemo(
    () => (
      <OsTransfer
        id={osId}
        open={showTransfer}
        onConfirm={user => {
          postOsTransfer(osId, user);
        }}
        onClose={() => {
          setShowTransfer(false);
        }}
      />
    ),
    [osId, showTransfer],
  );

  const renderFeedbackAlert = useMemo(
    () => (
      <FeedbackAlert
        message={feedback.message}
        show={feedback.show}
        durations={8000}
        type={feedback.type}
        onClose={() =>
          setFeedback(old => ({
            ...old,
            show: false,
          }))
        }
      />
    ),
    [feedback],
  );

  useEffect(() => {
    getOsData();
  }, [osId]);

  if (fetching) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant="caption" color="darkgray">
          Carregando...
        </Typography>
      </Box>
    );
  }

  if (invalidOS) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          gap: 4,
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          src={isDarkMode ? NoDataImg : NoDataLightImg}
          alt="no data"
          sx={{ width: '50%', maxWidth: 240, minWidth: 120 }}
        />
        <Typography variant="h5">O.S. Não encontrada</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          maxWidth: 1200,
          width: '100%',
        }}
      >
        {['not_started', 'not_started_late', 'paused', 'paused_late', 'started', 'started_late'].includes(
          osData?.situation,
        ) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
            }}
          >
            <Typography color={theme.palette.text.secondary} variant="subtitle2">
              AÇÕES
            </Typography>
            <Paper
              sx={{
                display: 'flex',
                gap: 2,
                width: '100%',
                p: 2,
                flexWrap: 'wrap',
                flex: 1,
              }}
            >
              {['paused', 'paused_late'].includes(osData?.situation) && osData?.the_attendant && (
                <Tooltip title="Continuar o atendimento da O.S.">
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<PlayIcon />}
                    onClick={handleContinue}
                    sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                    disabled={submiting}
                  >
                    CONTINUAR
                  </Button>
                </Tooltip>
              )}
              {['started', 'started_late'].includes(osData?.situation) && osData?.the_attendant && (
                <Tooltip title="Pausar o atendimento da O.S.">
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<PauseIcon />}
                    onClick={handlePause}
                    sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                    disabled={submiting}
                  >
                    PAUSAR
                  </Button>
                </Tooltip>
              )}
              {['not_started', 'not_started_late'].includes(osData?.situation) && osData?.the_attendant && (
                <Tooltip title="Iniciar o atendimento da O.S.">
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<StartIcon />}
                    onClick={handleStart}
                    sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                    disabled={submiting}
                  >
                    INICIAR
                  </Button>
                </Tooltip>
              )}

              {['started', 'started_late', 'paused', 'paused_late'].includes(osData?.situation) &&
                osData?.the_attendant && (
                  <Tooltip title="Finalizar o atendimento da O.S.">
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<DoneIcon />}
                      sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                      disabled={submiting}
                      onClick={handleEnd}
                    >
                      FINALIZAR
                    </Button>
                  </Tooltip>
                )}
              {['started', 'started_late', 'paused', 'paused_late', 'not_started', 'not_started_late'].includes(
                osData?.situation,
              ) &&
                osData?.the_attendant && (
                  <Tooltip title="Cancelar a O.S.">
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<CancelIcon />}
                      sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                      disabled={submiting}
                      onClick={handleCancel}
                    >
                      CANCELAR
                    </Button>
                  </Tooltip>
                )}
              {['not_started', 'not_started_late', 'started', 'started_late', 'paused', 'paused_late'].includes(
                osData?.situation,
              ) && (
                <Tooltip title="Realizar a transferência de atendimento da O.S.">
                  <Button
                    variant="outlined"
                    color="info"
                    startIcon={<SwapIcon />}
                    sx={{ flex: 1, maxWidth: 160, minWidth: 115 }}
                    disabled={submiting}
                    onClick={() => setShowTransfer(true)}
                  >
                    TRANSFERIR
                  </Button>
                </Tooltip>
              )}
            </Paper>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <Typography color={theme.palette.text.secondary} variant="subtitle2">
            DADOS
          </Typography>
          <Paper
            sx={{
              p: 2,
              pt: 2.5,
              borderLeft: `4px solid ${
                ['canceled', 'canceled_late'].includes(osData?.situation)
                  ? theme.palette.error.main
                  : ['ended', 'ended_late'].includes(osData?.situation)
                    ? theme.palette.success.main
                    : ['started', 'started_late', 'paused', 'paused_late'].includes(osData?.situation)
                      ? theme.palette.warning.main
                      : theme.palette.warning.light
              }`,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} sm={4} md={2} lg={2}>
                {inputId}
              </Grid>
              <Grid xs={12} sm={4} md={2} lg={2}>
                {inputOrder}
              </Grid>
              <Grid xs={12} sm={4} md={2} lg={2}>
                {inputBudget}
              </Grid>
              <Grid xs={12} sm={4} md={2} lg={2}>
                {inputHelpdesk}
              </Grid>
              <Grid xs={12} sm={8} md={4} lg={2}>
                {inputProduct}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={2}>
                {inputDrawn}
              </Grid>

              <Grid xs={12} sm={6} md={3} lg={4}>
                {inputSituation}
              </Grid>
              <Grid xs={12} sm={6} md={6} lg={8}>
                {inputTask}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {inputCreatedAt}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {inputStartedAt}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {inputExpectedStart}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {inputExpectedEnd}
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                {inputCreatedBy}
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                {inputAttendant}
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6}>
                {inputStartedBy}
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                {['canceled', 'canceled_late'].includes(osData?.situation) ? inputCanceledBy : inputEndedBy}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {['canceled', 'canceled_late'].includes(osData?.situation) ? inputCanceledAt : inputEndedAt}
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                {inputTimeSpent}
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12}>
                {inputObs}
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <Typography color={theme.palette.text.secondary} variant="subtitle2">
            INFORMAÇÕES
          </Typography>
          <Paper
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
              p: 2,
              flexWrap: 'wrap',
            }}
          >
            <Tooltip title="Visualizar anexos da O.S.">
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<AttachIcon />}
                onClick={() => setShowAttachs(true)}
              >
                ANEXOS
              </Button>
            </Tooltip>
            <Tooltip title="Visualizar trâmites da O.S.">
              <Button variant="outlined" color="inherit" startIcon={<ChatIcon />} onClick={() => setShowChat(true)}>
                TRÂMITES
              </Button>
            </Tooltip>
            <Tooltip title="Visualizar apontamentos da O.S.">
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<TimeIcon />}
                onClick={() => setShowSchedules(true)}
              >
                APONTAMENTOS
              </Button>
            </Tooltip>
            <Tooltip title="Visualizar logs da O.S.">
              <Button variant="outlined" color="inherit" startIcon={<LogsIcon />} onClick={() => setShowLogs(true)}>
                LOGS
              </Button>
            </Tooltip>
          </Paper>
        </Box>
      </Box>
      {osAttachs}
      {osSchedules}
      {osChats}
      {osLogs}
      {osTransfer}

      {renderFeedbackAlert}
      <ConfirmationDialog
        title={confirmation?.title ?? ''}
        content={confirmation?.content ?? ''}
        open={confirmation?.open ?? false}
        onClose={confirmation?.onClose ?? null}
        onClickNo={confirmation?.onClickNo ?? null}
        onClickYes={confirmation?.onClickYes ?? null}
        loading={submiting}
      />
    </Box>
  );
}
