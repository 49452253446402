import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { HelpdeskOpenCard } from './HelpdeskOpenCard';
import { HelpdeskDelayCard } from './HelpdeskDelayCard';
import { HelpdeskRunCard } from './HelpdeskRunCard';
import { HelpdeskPendingCard } from './HelpdeskPendingCard';
import { HelpdeskOpenClosedYear } from './HelpdeskOpenClosedYear';

interface IHelpdeskDashboard {}

function HelpdeskDashboard() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <HelpdeskOpenCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <HelpdeskPendingCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <HelpdeskRunCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <HelpdeskDelayCard />
        </Grid2>
        <Grid2 xs={12}>
          <HelpdeskOpenClosedYear />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export { HelpdeskDashboard };
