import React, { useEffect, useRef, useState } from 'react';
import Axios, { Canceler } from 'axios';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ptBR as localePtBR } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';

import Routes, { LoadingPage } from './routes';
import api from './services/api';

type BranchType = {
  codfil: string;
  nomfil: string;
  sigfil: string;
};
type CompanyType = {
  codemp: string;
  nomemp: string;
  sigemp: string;
  branches: BranchType[];
};

function App() {
  const [darkMode, setDarkMode] = useState(window.localStorage.getItem('theme') === 'dark');
  const [company, setCompany] = useState(window.localStorage.getItem('company') ?? '1');
  const [branch, setBranch] = useState(window.localStorage.getItem('branch') ?? '1');

  const mountedRef = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  let theme = createTheme(
    {
      palette: {
        mode: darkMode ? 'dark' : 'light',
        background: {
          default: darkMode ? grey[800] : grey[300],
          paper: darkMode ? grey[800] : grey[100],
        },
        primary: {
          light: darkMode ? blue[100] : blue[500],
          main: darkMode ? blue[300] : blue[600],
          dark: darkMode ? blue[400] : blue[700],
          contrastText: grey[50],
        },
        secondary: {
          light: darkMode ? red[100] : red[500],
          main: darkMode ? red[300] : red[600],
          dark: darkMode ? red[400] : red[700],
          contrastText: grey[50],
        },
        success: {
          light: darkMode ? green[100] : green[500],
          main: darkMode ? green[400] : green[400],
          dark: darkMode ? green[400] : green[700],
          contrastText: grey[50],
        },
        error: {
          light: red[400],
          main: red[500],
          dark: red[600],
          contrastText: grey[50],
        },
        text: {
          primary: darkMode ? grey[50] : grey[900],
          secondary: darkMode ? grey[400] : grey[600],
          disabled: grey[600],
        },
      },
      typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
      },
    },
    localePtBR,
  );

  theme = responsiveFontSizes(theme);

  async function getUserData() {
    if (!mountedRef.current) return;
    if (user) return;
    if (isLoading) return;
    setIsLoading(true);

    let cancel: Canceler;
    try {
      const response = await api.get('intranet/auth/check', {
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      const newUser = response?.data?.user;

      setUser(newUser);
      const themeMode = newUser?.settings?.darkMode === '1' ? 'dark' : 'light';

      const newCompany = newUser?.settings?.company ?? '1';
      const newBranch = newUser?.settings?.branch ?? '1';

      setDarkMode(themeMode === 'dark');
      setCompany(newCompany);
      setBranch(newBranch);

      theme.palette.mode = themeMode;

      window.localStorage.setItem('theme', themeMode);
      window.localStorage.setItem('company', newCompany);
      window.localStorage.setItem('branch', newBranch);

      document.documentElement.setAttribute('data-color-mode', themeMode);
    } catch (error: any) {
      console.error(error);
      setUser(null);
    }

    setIsLoading(false);
    mountedRef.current = false;

    // eslint-disable-next-line consistent-return
    return cancel();
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="App">
              {mountedRef.current ? (
                <LoadingPage darkMode={darkMode} />
              ) : (
                <Routes
                  user={user}
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                  branch={branch}
                  setBranch={setBranch}
                  company={company}
                  setCompany={setCompany}
                  loading={isLoading}
                />
              )}
            </div>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
export type { CompanyType, BranchType };
