import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Box, Typography, IconButton, Tooltip, InputBase, Grid, Pagination, Skeleton } from '@mui/material';
import latinize from 'latinize';
import Highlighter from 'react-highlight-words';
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';

import Axios, { Canceler } from 'axios';

import userUndefinedImg from '../../images/user-undefined.jpg';
import api from '../../services/api';
import styles from './styles';
import { avatarUrl } from '../../utils/functions';

type getContactsType = {
  search?: string;
  page?: number;
};
type ContactType = {
  id: string;
  name: string;
  employee_position: string;
  sector: string;
  birthday_date: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  pc_name: string;
  logged_at: string;
  situation: string;
  my: string;
};

type ResponseType = {
  page_current: string;
  page_total: string;
  result_limit: string;
  result_total: string;
  result: ContactType[];
};

export default function ContactsPage() {
  const classes = styles();
  const [loadingData, setLoadingData] = useState(false);
  const [contacts, setContacts] = useState<ResponseType>();
  const refInputSearch = useRef<HTMLInputElement>(null);
  const [textSearch, setTextSearch] = useState('');

  const getContacts = async (props?: getContactsType) => {
    const { search = '', page = 1 } = props || {};
    let cancel: Canceler;
    if (loadingData) return () => cancel();
    setLoadingData(true);

    try {
      const response = await api.get(`intranet/users/profiles`, {
        params: {
          page,
          limit: 24,
          search,
        },
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      setContacts(response.data);
      setLoadingData(false);
    } catch (error) {
      const { data } = error.response;
      if (data) {
        const errorMessage = data?.message;
        console.error(errorMessage);
      }
      setLoadingData(false);
    }
    return () => cancel();
  };

  useEffect(
    () => {
      const delay = setTimeout(() => {
        getContacts({ search: textSearch });
        // Send Axios request here
      }, 400);

      return () => clearTimeout(delay);
    },
    [textSearch], // Only call effect if debounced search term changes
  );

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const search = e?.currentTarget?.value;
    setTextSearch(search);
  }
  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > parseInt(contacts.page_total, 10)) return;
    getContacts({ search: textSearch, page });
  };
  function handleClearSearch() {
    setTextSearch('');
    getContacts();
    if (refInputSearch.current) {
      const input = refInputSearch.current.children[0] as HTMLInputElement;
      input.value = '';
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.searchContainer}>
        <Paper className={classes.inputRoot}>
          <Box className={classes.iconButton}>
            <SearchIcon />
          </Box>
          <InputBase
            ref={refInputSearch}
            className={classes.input}
            placeholder="Pesquisar"
            inputProps={{ 'aria-label': 'pesquisar' }}
            onChange={handleSearchTextChange}
          />
          <IconButton
            className={classes.iconButton}
            onClick={handleClearSearch}
            disabled={textSearch.length === 0}
            color="secondary"
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Paper>
      </Box>
      <Grid container spacing={2} className={classes.resultContainer}>
        {contacts?.result.map((contact: ContactType) => {
          return (
            <Grid key={`contact-${contact.id}`} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Paper className={classes.profileContainer}>
                {loadingData ? (
                  <>
                    <Skeleton variant="circular" width={128} height={128} />
                    <Skeleton variant="text" width="75%" height={42} />
                    <Skeleton variant="text" width="60%" height={20} />

                    <Skeleton variant="text" width="45%" height={20} style={{ marginBottom: 24 }} />
                    <Skeleton variant="text" width="90%" height={42} />
                    <Skeleton variant="text" width="90%" height={42} />
                    <Skeleton variant="text" width="90%" height={42} />
                  </>
                ) : (
                  <>
                    <Box className={classes.profileImageContainer}>
                      <Tooltip title="Visualizar Perfil">
                        <Link to={`/profile/${contact?.id}`}>
                          <img
                            className={classes.profileImage}
                            src={avatarUrl(contact?.id)}
                            alt="Foto"
                            onError={(e: any) => {
                              e.target.src = avatarUrl(0);
                            }}
                          />
                        </Link>
                      </Tooltip>
                      <Typography className={classes.profileName}>
                        <Highlighter
                          highlightClassName={classes.resultTextHighlights}
                          searchWords={textSearch.split(' ')}
                          autoEscape
                          textToHighlight={contact?.name}
                          sanitize={latinize}
                        />
                      </Typography>
                      <Typography className={classes.profileSector}>
                        <Highlighter
                          highlightClassName={classes.resultTextHighlights}
                          searchWords={textSearch.split(' ')}
                          autoEscape
                          textToHighlight={contact?.sector}
                          sanitize={latinize}
                        />
                      </Typography>
                      <Typography className={classes.profilePosition}>
                        <Highlighter
                          highlightClassName={classes.resultTextHighlights}
                          searchWords={textSearch.split(' ')}
                          autoEscape
                          textToHighlight={contact?.employee_position}
                          sanitize={latinize}
                        />
                      </Typography>
                    </Box>

                    <Box className={classes.profileDataContainer}>
                      <Box className={classes.profileInfoContainer}>
                        <Typography className={classes.profileInfoTitle}>Email Corporativo:</Typography>
                        <Typography className={classes.profileInfoText}>
                          <Highlighter
                            highlightClassName={classes.resultTextHighlights}
                            searchWords={textSearch.split(' ')}
                            autoEscape
                            textToHighlight={contact?.email?.trim().length > 0 ? contact?.email : 'Não informado'}
                            sanitize={latinize}
                          />
                        </Typography>
                      </Box>
                      <Box className={classes.profileInfoContainer}>
                        <Typography className={classes.profileInfoTitle}>Ramal:</Typography>
                        <Typography className={classes.profileInfoText}>
                          <Highlighter
                            highlightClassName={classes.resultTextHighlights}
                            searchWords={textSearch.split(' ')}
                            autoEscape
                            textToHighlight={contact?.phone1?.trim().length > 0 ? contact?.phone1 : 'Não informado'}
                            sanitize={latinize}
                          />
                        </Typography>
                      </Box>
                      <Box className={classes.profileInfoContainer}>
                        <Typography className={classes.profileInfoTitle}>Celular:</Typography>
                        <Typography className={classes.profileInfoText}>
                          <Highlighter
                            highlightClassName={classes.resultTextHighlights}
                            searchWords={textSearch.split(' ')}
                            autoEscape
                            textToHighlight={contact?.phone2?.trim().length > 0 ? contact?.phone2 : 'Não informado'}
                            sanitize={latinize}
                          />
                        </Typography>
                      </Box>
                      <Box className={classes.profileInfoContainer}>
                        <Typography className={classes.profileInfoTitle}>PC:</Typography>
                        <Typography className={classes.profileInfoText}>
                          <Highlighter
                            highlightClassName={classes.resultTextHighlights}
                            searchWords={textSearch.split(' ')}
                            autoEscape
                            textToHighlight={contact?.pc_name?.trim().length > 0 ? contact?.pc_name : 'Não informado'}
                            sanitize={latinize}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Box className={classes.paginationContainer}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>{`${
          contacts?.result_total || 0
        } contato(s) encontrado(s)`}</Typography>
        <Pagination
          siblingCount={0}
          disabled={loadingData}
          count={parseInt(contacts?.page_total || '1', 10)}
          page={parseInt(contacts?.page_current || '1', 10)}
          variant="outlined"
          shape="rounded"
          onChange={handlePagination}
        />
      </Box>
    </Box>
  );
}
