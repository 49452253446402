import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    table: {
      minWidth: 750,
    },
    tablePaper: {
      width: '100%',
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 256px)',
    },
    tableVisuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
  });
});
