import React, { FC, useRef } from 'react';
import Axios, { Canceler } from 'axios';
import SunEditor from 'suneditor-react';

import SunEditorCore from 'suneditor/src/lib/core';
import 'suneditor/dist/css/suneditor.min.css';

import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import api from '../../services/api';

interface HtmlEditorType extends SunEditorReactProps {
  minHeight?: string;
  defaultFontSize?: string;
  deleteImageOnServer?: boolean;
  folderUpload?: string;
  showPathLabel?: boolean;
  maxCharCount?: number;
  buttonList?: any[];
}

export default function HtmlEditor(props: HtmlEditorType) {
  const {
    height = '100%',
    width = '100%',
    lang = 'pt_br',
    deleteImageOnServer = false,
    minHeight = '254px',
    defaultFontSize = '16px',
    folderUpload = '',
    showPathLabel = false,
    maxCharCount = 0,
    buttonList = [
      ['undo', 'redo'],
      ['bold', 'underline', 'italic', 'strike'],
      ['font', 'fontSize', 'fontColor', 'hiliteColor'],
      ['blockquote', 'table', 'link'],
      ['image'],
      ['removeFormat', 'preview', 'codeView'],
    ],
  } = props;
  const editor = useRef<SunEditorCore>();
  let cancel: Canceler;
  const imgList: string[] = [];

  function getSunEditorInstance(sunEditor: SunEditorCore) {
    editor.current = sunEditor;
    return editor;
  }

  async function handleImageUpload(
    targetImgElement: HTMLImageElement,
    index: any,
    state: any,
    imageInfo: any,
    remainingFilesCount: any,
  ) {
    if (state === 'delete') {
      if (deleteImageOnServer) {
        cancel?.();
        await api.delete('services/upload/suneditor', {
          params: {
            url: imgList?.[index],
          },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        });
        return () => cancel();
      }
    } else if (state === 'create') {
      imgList[index] = targetImgElement.src;
    }
  }

  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      setDefaultStyle={`min-height:${minHeight}; font-family: Arial; font: ${defaultFontSize} Arial;`}
      onImageUpload={handleImageUpload}
      height={height}
      width={width}
      lang={lang}
      setOptions={{
        imageUploadHeader: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        imageUploadUrl: `${api.getUri()}/services/upload/suneditor?folder=${folderUpload}`,
        showPathLabel,
        charCounter: false,
        maxCharCount,
        buttonList,
      }}
      {...props}
    />
  );
}
