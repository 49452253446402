import React, { ChangeEvent } from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import styles from './styles';

export interface InputSearchDateProps extends StandardTextFieldProps {
  id?: string | undefined;
  label?: string | undefined;
  value?: string | undefined;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any | undefined;
  className?: string | undefined;
  type?: 'single' | 'start' | 'end';
  readOnly?: boolean;
}

function InputSearchDate(props: InputSearchDateProps) {
  const classes = styles();
  const { id, label, value, className, onChange, type, ...rest } = props;
  const currentValue = value || '';
  return (
    <TextField
      type="time"
      variant="outlined"
      size="small"
      id={id}
      label={label}
      onChange={onChange}
      style={type === 'start' ? { paddingRight: 4 } : type === 'end' ? { paddingLeft: 4 } : {}}
      className={`${className} ${currentValue?.length > 0 ? classes.highlightText : ''} ${classes.input}`}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
      // onChange={handleTextInput}
      // className={searchDateEnd !== '00/00/0000' && searchDateEnd !== '' ? classes.filterText : ''}
    />
  );
}

export default InputSearchDate;
