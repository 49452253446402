import React from 'react';
import { Button, Icon, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import styles from './styles';
import notFoundImg from '../../../images/not_found.svg';
import notFoundLightImg from '../../../images/not_found_light.svg';

type PageProps = {
  darkMode?: boolean;
};

export default function NotFound(props: PageProps) {
  const classes = styles();
  const { darkMode = false } = props;

  return (
    <div className={classes.root}>
      <img src={darkMode ? notFoundImg : notFoundLightImg} alt="404" className={classes.img} />
      <div className={classes.content}>
        <Typography variant="h6" className={classes.text}>
          A página que procura não foi encontrada, para retornar ao inicio clique no botão abaixo.
        </Typography>
        <div className={classes.button}>
          <Button variant="contained" color="primary" startIcon={<Icon>home</Icon>} component={Link} to="/dashboard">
            Home
          </Button>
        </div>
      </div>
    </div>
  );
}
NotFound.defaultProps = {
  darkMode: false,
};
