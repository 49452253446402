import React, { ChangeEvent } from 'react';
import { MenuItem, StandardTextFieldProps, TextField } from '@mui/material';
import styles from './styles';

export interface InputSearchComboBoxProps extends StandardTextFieldProps {
  id?: string | undefined;
  label?: string | undefined;
  value?: string | undefined;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any | undefined;
  className?: string | undefined;
  children?: any | undefined;
  items: InputSearchComboBoxItems[] | undefined;
  readOnly?: boolean;
}
export interface InputSearchComboBoxItems {
  text: string;
  value: string;
  disabled?: boolean;
}

function InputSearchComboBox(props: InputSearchComboBoxProps) {
  const classes = styles();
  const { id, label, value, className, onChange, items, readOnly = false, ...rest } = props;
  const currentValue = value || '';
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      id={id}
      label={label}
      select
      onChange={onChange}
      className={`${className} ${currentValue?.length > 0 ? classes.highlightText : ''}`}
      value={value}
      disabled={readOnly}
      {...rest}
      // onChange={handleTextInput}
      // className={searchText?.length > 0 ? classes.filterText : ''}
    >
      {items?.map(item => {
        return (
          <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
            {item.text}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default InputSearchComboBox;
