import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Typography,
  Icon,
  TableBody,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
} from '@mui/material';

import { Autocomplete } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';
import Axios, { Canceler } from 'axios';
import api from '../../../../services/api';
import styles from './styles';
import ProfileTooltip from '../../../../components/ProfileTooltip';
import { ProfileField } from '../../../../components/ProfileField';

type OrderProp = 'asc' | 'desc';
interface ResponseData {
  end_connection: string;
  connections: ConnectionData[];
}
interface ConnectionData {
  id_process: string;
  pc_name_instance: string;
  user_name: string;
  process: string;
  nickname: string;
  pc_name: string;
  user_id: number;
}

const processListFilter = [
  { value: 'All', title: 'All - Flutuante' },
  { value: 'RVOR', title: 'RVOR - Mercado - Gestão de Vendas - Orçamentos' },
  { value: 'RVPE', title: 'RVPE - Mercado - Gestão de Vendas - Pedidos' },
  { value: 'RVTR', title: 'RVTR - Mercado - Gestão de Vendas - Contratos' },
  { value: 'RVCM', title: 'RVCM - Mercado - Gestão de Vendas - Controle de Metas' },
  { value: 'RDCG', title: 'RDCG - Mercado - Gestão de Distribuição - Cargas' },
  { value: 'RDAC', title: 'RDAC - Mercado - Gestão de Distribuição - Acertos' },
  { value: 'RTTR', title: 'RTTR - Mercado - Gestão de Transporte (TMS) - Controle de Transporte' },
  { value: 'RFEX', title: 'RFEX - Mercado - Gestão de Faturamento e Outras Saídas - Expedição' },
  { value: 'RFNF', title: 'RFNF - Mercado - Gestão de Faturamento e Outras Saídas - Notas Fiscais de Saída' },
  { value: 'RFSB', title: 'RFSB - Mercado - Gestão de Faturamento e Outras Saídas - Saída via Balança' },
  { value: 'RRCA', title: 'RRCA - Mercado - Gestão de Relacionamento (CRM) - Controle de Atendimento' },
  { value: 'SCSC', title: 'SCSC - Suprimentos - Gestão de Compras - Solicitação de Compra' },
  { value: 'SCCP', title: 'SCCP - Suprimentos - Gestão de Compras - Cotação de Preço' },
  { value: 'SCOC', title: 'SCOC - Suprimentos - Gestão de Compras - Ordens de Compra' },
  { value: 'SCTR', title: 'SCTR - Suprimentos - Gestão de Compras - Contratos' },
  { value: 'SRNF', title: 'SRNF - Suprimentos - Gestão de Recebimento - Notas Fiscais de Entrada' },
  { value: 'SREB', title: 'SREB - Suprimentos - Gestão de Recebimento - Entrada via Balança' },
  { value: 'SRCP', title: 'SRCP - Suprimentos - Gestão de Recebimento - Coleta de Produtos em Fornecedores' },
  { value: 'SECE', title: 'SECE - Suprimentos - Gestão de Estoques - Controle de Estoque' },
  { value: 'SERE', title: 'SERE - Suprimentos - Gestão de Estoques - Requisição Eletrônica' },
  { value: 'SEAR', title: 'SEAR - Suprimentos - Gestão de Estoques - Análise de Reposição' },
  { value: 'SEIV', title: 'SEIV - Suprimentos - Gestão de Estoques - Inventário' },
  { value: 'FPCP', title: 'FPCP - Finanças - Gestão de Contas a Pagar - Contas a Pagar' },
  { value: 'FPMI', title: 'FPMI - Finanças - Gestão de Contas a Pagar - Comissões' },
  { value: 'FPPE', title: 'FPPE - Finanças - Gestão de Contas a Pagar - Pagamento Eletrônico' },
  { value: 'FRCR', title: 'FRCR - Finanças - Gestão de Contas a Receber - Contas a Receber' },
  { value: 'FRES', title: 'FRES - Finanças - Gestão de Contas a Receber - Cobrança Escritural' },
  { value: 'FTCB', title: 'FTCB - Finanças - Gestão de Tesouraria - Caixa e Bancos' },
  { value: 'FTAF', title: 'FTAF - Finanças - Gestão de Tesouraria - Aplicação e Financiamento' },
  { value: 'FTFC', title: 'FTFC - Finanças - Gestão de Tesouraria - Fluxo de Caixa' },
  { value: 'FTON', title: 'FTON - Finanças - Gestão de Tesouraria - Conciliação' },
  { value: 'FFOR', title: 'FFOR - Finanças - Gestão de Plano Financeiro - Orçamentos' },
  { value: 'FFCC', title: 'FFCC - Finanças - Gestão de Plano Financeiro - Controle de Contas Financeiras' },
  { value: 'FFFC', title: 'FFFC - Finanças - Gestão de Plano Financeiro - Fluxo de Caixa Gerencial' },
  { value: 'CCCT', title: 'CCCT - Controladoria - Gestão de Contabilidade - Contabilidade' },
  { value: 'CCMM', title: 'CCMM - Controladoria - Gestão de Contabilidade - Contabilidade Multimoeda' },
  { value: 'CCSP', title: 'CCSP - Controladoria - Gestão de Contabilidade - Arquivos Fiscais' },
  { value: 'CCVC', title: 'CCVC - Controladoria - Gestão de Contabilidade - Visões Contábeis' },
  { value: 'CIEC', title: 'CIEC - Controladoria - Gestão de Tributos - Escrituração' },
  { value: 'CICI', title: 'CICI - Controladoria - Gestão de Tributos - CIAP' },
  { value: 'CIOF', title: 'CIOF - Controladoria - Gestão de Tributos - Operações e Cálculos Fiscais' },
  { value: 'CIAF', title: 'CIAF - Controladoria - Gestão de Tributos - Arquivos Fiscais' },
  { value: 'CISO', title: 'CISO - Controladoria - Gestão de Tributos - EFD - Reinf' },
  { value: 'CPBE', title: 'CPBE - Controladoria - Gestão de Patrimônio - Controle dos Bens' },
  { value: 'CPAT', title: 'CPAT - Controladoria - Gestão de Patrimônio - Atualização Patrimonial' },
  { value: 'CPIV', title: 'CPIV - Controladoria - Gestão de Patrimônio - Inventário Patrimonial' },
  {
    value: 'MECO',
    title: 'MECO - Manufatura - Gestão de Engenharia de Produto/Serviço - Composição Produto/Serviço (Modelo)',
  },
  { value: 'MEBI', title: 'MEBI - Manufatura - Gestão de Engenharia de Produto/Serviço - Combinações' },
  { value: 'MERP', title: 'MERP - Manufatura - Gestão de Engenharia de Produto/Serviço - Roteiro de Produção' },
  {
    value: 'MEDU',
    title: 'MEDU - Manufatura - Gestão de Engenharia de Produto/Serviço - Duplicação de Roteiro/Modelo',
  },
  { value: 'MPCR', title: 'MPCR - Manufatura - Gestão de PCP - Carga de Recursos da Produção' },
  { value: 'MPNE', title: 'MPNE - Manufatura - Gestão de PCP - Necessidades de Produção/Compra (MRP)' },
  { value: 'MPOP', title: 'MPOP - Manufatura - Gestão de PCP - Ordens de Produção/Serviço' },
  { value: 'MPMO', title: 'MPMO - Manufatura - Gestão de PCP - Manutenção de OP/OS' },
  { value: 'MPAD', title: 'MPAD - Manufatura - Gestão de PCP - Análise de Disponibilidade de Componentes' },
  { value: 'MPLP', title: 'MPLP - Manufatura - Gestão de PCP - Cancelamento de Produção' },
  { value: 'MCAP', title: 'MCAP - Manufatura - Gestão de Chão de Fábrica - Apontamentos de OP/OS' },
  { value: 'MCMM', title: 'MCMM - Manufatura - Gestão de Chão de Fábrica - Manutenção de Movimentos' },
  { value: 'MCSS', title: 'MCSS - Manufatura - Gestão de Chão de Fábrica - Separação de Componentes Depósito' },
  { value: 'MCRR', title: 'MCRR - Manufatura - Gestão de Chão de Fábrica - Remessa/Retorno Serviço Terceiros' },
  { value: 'MCCO', title: 'MCCO - Manufatura - Gestão de Chão de Fábrica - Componentes (OP/OS)' },
  { value: 'UCFP', title: 'UCFP - Custos - Gestão de Preço para Comércio - Formação de Preços' },
  { value: 'UCAD', title: 'UCAD - Custos - Gestão de Preço para Comércio - Análises de Desempenho' },
  { value: 'UIFP', title: 'UIFP - Custos - Gestão de Preço para Indústria - Formação de Preços' },
  { value: 'UIAD', title: 'UIAD - Custos - Gestão de Preço para Indústria - Análises de Desempenho' },
  { value: 'UTCC', title: 'UTCC - Custos - Gestão de Contabilidade de Custos - Custo Integrado' },
  { value: 'UTUA', title: 'UTUA - Custos - Gestão de Contabilidade de Custos - Custo Não Integrado' },
  { value: 'UTAD', title: 'UTAD - Custos - Gestão de Contabilidade de Custos - Análises de Desempenho' },
  { value: 'QQDC', title: 'QQDC - Qualidade - Gestão de Qualidade (SGQ) - Controle de Documentos' },
  { value: 'QQRG', title: 'QQRG - Qualidade - Gestão de Qualidade (SGQ) - Registros' },
  { value: 'QQIN', title: 'QQIN - Qualidade - Gestão de Qualidade (SGQ) - Inspeções' },
  { value: 'QQAF', title: 'QQAF - Qualidade - Gestão de Qualidade (SGQ) - Avaliação de Fornecedores' },
];

export default function AdminUsersAddPage() {
  const classes = styles();
  const [orderBy, setOrderBy] = useState('process');
  const [orderDir, setOrderDir] = useState<OrderProp>('asc');
  const [gettingData, setGettingData] = useState(false);
  const [endingProcess, setEndingProcess] = useState(false);
  const [filterProcess, setFilterProcess] = useState('');
  const [searchText, setSearchText] = useState('');
  const [initGettingData, setInitGettingData] = useState(true);
  const [responseData, setResponseData] = useState<ResponseData>({} as ResponseData);
  const [searchBase, setSearchBase] = useState('erp');

  const handleBaseChange = (event: SelectChangeEvent) => {
    setSearchBase(event.target.value as string);
  };

  const handleSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const getData = ({
    order_by = '',
    order_dir = 'asc',
    process = '',
    user_name = '',
    search = '',
    base = searchBase,
  }) => {
    let cancel: Canceler;
    if (!gettingData) {
      const params = {
        order_by,
        order_dir,
        process,
        user_name,
        search,
        base,
      };
      setGettingData(true);

      api
        .get('erp/connections/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          setResponseData(response?.data);
          setGettingData(false);
          setInitGettingData(false);
        })
        .catch(error => {
          console.error(error);
          setGettingData(false);
          setInitGettingData(false);
        });
    }
    return () => cancel();
  };

  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';

    setInitGettingData(true);
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem('search-users-order-by', index);
    localStorage.setItem('search-users-order-dir', isAsc ? 'desc' : 'asc');
    getData({ order_by: index, order_dir: isAsc ? 'desc' : 'asc', base: searchBase });
  };

  const handleEndConnection = async (process_id: string, base: string) => {
    if (!endingProcess) {
      try {
        await api.post(`erp/connections`, {
          process_id,
          base,
        });
        getData({ order_by: orderBy, order_dir: orderDir });
        setEndingProcess(false);
      } catch (error) {
        console.error(error);
        setEndingProcess(false);
      }
    }
  };

  useEffect(() => {
    getData({ order_by: 'process', order_dir: 'asc', process: filterProcess, base: searchBase });
  }, []);

  useEffect(() => {
    getData({ order_by: 'process', order_dir: 'asc', process: filterProcess, search: searchText, base: searchBase });
  }, [searchText, searchBase]);

  const renderSearchTextInput = useMemo(
    () => (
      <TextField
        id="string-search"
        label="PC / Usuário"
        variant="outlined"
        size="small"
        sx={{ width: 260 }}
        onChange={handleSearchFieldChange}
        value={searchText}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
      />
    ),
    [searchText],
  );

  const renderBaseInput = useMemo(
    () => (
      <FormControl sx={{ width: 256 }}>
        <InputLabel id="base-select-label">Base</InputLabel>
        <Select
          labelId="base-select-label"
          id="base-select"
          value={searchBase}
          label="Base"
          size="small"
          onChange={handleBaseChange}
        >
          <MenuItem value="erp">Sapiens Produção</MenuItem>
          <MenuItem value="erp_dev">Sapiens Homologação</MenuItem>
          <MenuItem value="erp_old">Sapiens Antiga</MenuItem>
          <MenuItem value="hcm">HCM Produção</MenuItem>
          <MenuItem value="hcm_dev">HCM Homologação</MenuItem>
        </Select>
      </FormControl>
    ),
    [searchBase],
  );

  const renderProcessFilterSelect = useMemo(
    () => (
      <Autocomplete
        id="process-filter"
        options={processListFilter}
        getOptionLabel={(option: any) => option?.title}
        onChange={(event: ChangeEvent<{}>, newValue: { value: string; title: string } | null) => {
          setFilterProcess(`${newValue?.value ? newValue?.value : ''}`);
          getData({
            order_by: orderBy,
            order_dir: orderDir,
            process: `${newValue?.value ? newValue?.value : ''}`,
            base: searchBase,
          });
        }}
        value={processListFilter.filter(process => process.value === filterProcess)[0]}
        renderInput={params => <TextField {...params} label="Processo" variant="outlined" />}
        fullWidth
        size="small"
      />
    ),
    [processListFilter, searchBase],
  );

  const renderResult = useMemo(
    () => (
      <Paper className={classes.tablePaper}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="table">
            <TableHead>
              <TableRow>
                {responseData?.end_connection === '1' && (
                  <TableCell style={{ minWidth: 42, maxWidth: 42, width: 42 }} align="center">
                    <Tooltip title="Atualizar Dados">
                      <Button
                        style={{ minWidth: 42, width: 42, maxWidth: 42 }}
                        onClick={() => {
                          getData({ order_by: orderBy, order_dir: orderDir, process: filterProcess, base: searchBase });
                        }}
                        color="primary"
                      >
                        <RefreshIcon />
                      </Button>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell style={{ width: 42 }} align="left" sortDirection={orderBy === 'pc_name' ? orderDir : false}>
                  <Tooltip title="Computador (Nome)" aria-label="orderby-pc-name">
                    <TableSortLabel
                      active={orderBy === 'pc_name'}
                      direction={orderBy === 'pc_name' ? orderDir : 'asc'}
                      onClick={() => {
                        handleSort('pc_name');
                      }}
                      style={{ width: 32 }}
                    >
                      PC
                      {orderBy === 'pc_name' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir === 'desc' ? 'desc' : 'asc'}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ width: 42 }}
                  align="left"
                  sortDirection={orderBy === 'pc_name_instance' ? orderDir : false}
                >
                  <Tooltip title="Computador (Instância)" aria-label="orderby-pc-name-instance">
                    <TableSortLabel
                      active={orderBy === 'pc_name_instance'}
                      direction={orderBy === 'pc_name_instance' ? orderDir : 'asc'}
                      onClick={() => {
                        handleSort('pc_name_instance');
                      }}
                    >
                      PC - Instância
                      {orderBy === 'pc_name_instance' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir === 'desc' ? 'desc' : 'asc'}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ minWidth: 120, maxWidth: 120, width: 120 }}
                  align="left"
                  sortDirection={orderBy === 'user_name' ? orderDir : false}
                >
                  <Tooltip title="Usuário (Login)" aria-label="orderby-user_name">
                    <TableSortLabel
                      active={orderBy === 'user_name'}
                      direction={orderBy === 'user_name' ? orderDir : 'asc'}
                      onClick={() => {
                        handleSort('user_name');
                      }}
                    >
                      Login - ERP
                      {orderBy === 'user_name' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir === 'desc' ? 'desc' : 'asc'}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ minWidth: 120, maxWidth: 120, width: 120 }}
                  align="left"
                  sortDirection={orderBy === 'nickname' ? orderDir : false}
                >
                  <Tooltip title="Usuário (Nome)" aria-label="orderby-nickname">
                    <TableSortLabel
                      active={orderBy === 'nickname'}
                      direction={orderBy === 'nickname' ? orderDir : 'asc'}
                      onClick={() => {
                        handleSort('nickname');
                      }}
                      style={{ width: 32 }}
                    >
                      Usuário
                      {orderBy === 'nickname' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir === 'desc' ? 'desc' : 'asc'}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ width: 360, minWidth: 360 }}
                  align="left"
                  sortDirection={orderBy === 'process' ? orderDir : false}
                >
                  <Tooltip title="Processo" aria-label="orderby-process">
                    <TableSortLabel
                      active={orderBy === 'process'}
                      direction={orderBy === 'process' ? orderDir : 'asc'}
                      onClick={() => {
                        handleSort('process');
                      }}
                      style={{ minWidth: 360 }}
                    >
                      Processo
                      {orderBy === 'process' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir === 'desc' ? 'desc' : 'asc'}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gettingData ? (
                <TableRow style={{ height: 'calc(100vh - 242px)' }}>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="caption" className={classes.loadingInitialDataText}>
                      Carregando dados...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : responseData?.connections?.length === 0 ? null : (
                responseData?.connections?.map((connection: ConnectionData, index) => {
                  const rowId = `row-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={rowId}>
                      {responseData?.end_connection === '1' && (
                        <TableCell style={{ minWidth: 42, maxWidth: 42, width: 42 }} align="center">
                          <Tooltip title="Desconectar Usuário" aria-label="desconect">
                            <Button
                              variant="outlined"
                              color="secondary"
                              style={{
                                padding: 0,
                                minWidth: 42,
                                maxWidth: 42,
                                width: 42,
                              }}
                              size="large"
                              onClick={() => {
                                handleEndConnection(connection.id_process, searchBase);
                              }}
                              startIcon={
                                <Icon
                                  fontSize="small"
                                  style={{
                                    marginLeft: 12,
                                    padding: 0,
                                  }}
                                >
                                  close
                                </Icon>
                              }
                            />
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell style={{ minWidth: 52, maxWidth: 52, width: 52 }} align="left">
                        {connection.pc_name?.toUpperCase()}
                      </TableCell>
                      <TableCell style={{ minWidth: 52, maxWidth: 52, width: 52 }} align="left">
                        {connection.pc_name_instance?.toUpperCase()}
                      </TableCell>
                      <TableCell style={{ minWidth: 120, maxWidth: 120, width: 120 }} align="left">
                        {connection.user_name?.toLowerCase()}
                      </TableCell>
                      <TableCell style={{ minWidth: 120, maxWidth: 120, width: 120 }} align="left">
                        <ProfileField userId={connection.user_id} userName={connection.nickname} />
                      </TableCell>
                      <TableCell style={{ width: 360, minWidth: 360 }} align="left">
                        {`${processListFilter.filter(process => process.value === connection.process)[0]?.title}`}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    ),
    [gettingData, searchBase],
  );

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Paper className={classes.tablePaper}>
        <Box p={1} pt={2} display="flex" alignItems="center" gap={2}>
          {renderBaseInput}
          {renderSearchTextInput}
          {renderProcessFilterSelect}
          <Typography
            variant="caption"
            style={{ paddingLeft: 8, width: 96, textAlign: 'center' }}
          >{`TOTAL: ${responseData?.connections?.length}`}</Typography>
        </Box>
      </Paper>
      {renderResult}
    </Box>
  );
}
