import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

export default function Loading() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 12,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        transition: 'all 0.3s',
        height: '100vh',
        width: '100%',
      }}
    >
      <CircularProgress color="inherit" disableShrink />
      <Typography variant="h6">Carregando</Typography>
    </Box>
  );
}
