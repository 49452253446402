import React, { useEffect, useState } from 'react';
import Axios, { Canceler } from 'axios';
import {
  Box,
  Typography,
  Avatar,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import styles from './styles';

import api from '../../../../services/api';
import ProfileTooltip from '../../../../components/ProfileTooltip';
import users from '../../../admin/users';
import { avatarUrl } from '../../../../utils/functions';

type UserType = {
  id: number;
  type: number;
  number: number;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  date_start: string;
  date_end: string;
  hours: number;
  minutes: number;
  hours_formated: string;
  minutes_formated: string;
  days_remaining: number;
};

export default function DashboardPage() {
  const classes = styles();
  const [userData, setUserData] = useState<UserType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState<UserType>(null);
  const [waitingDelete, setWaitingDelete] = useState<boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const loadingText = 'Carregando...';

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<UserType[]>('manager/dayoff', {
          cancelToken: new Axios.CancelToken(async c => {
            cancel = c;
          }),
        });
        setUserData(response.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    let active = true;
    if (active) {
      getData();
    }
    return () => {
      active = false;
    };
  }, []);

  const handleDeleteDialogClickOpen = (user: UserType) => {
    setDeleteUser(user);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    if (waitingDelete) return;
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    if (!waitingDelete) {
      setWaitingDelete(true);
      try {
        const response = await api.post('manager/dayoff/', {
          type: 'E',
          users: [deleteUser?.id],
          date: deleteUser?.date_start,
          hours: `${deleteUser?.hours_formated}:${deleteUser?.minutes_formated}`,
        });
        setSnackbarType('success');
        setSnackbarMsg('Deletado com sucesso');
        setOpenSnackbar(true);
        setDeleteUser(null);
        setDeleteDialogOpen(false);
        getData();
      } catch (error) {
        const { data } = error.response;
        if (data) {
          let errorMessage = data.message;
          if (!errorMessage) errorMessage = data.error;
          if (errorMessage === 'invalid_date') errorMessage = 'É necessário informar uma data válida';
          else if (errorMessage === 'invalid_hours') errorMessage = 'É necessário informar um horário válido';
          else if (errorMessage === 'invalid_user') errorMessage = `Colaborador "${data.value}" inválido`;
          else if (errorMessage === 'erp_webservice_user, erp_webservice_password not found in settings')
            errorMessage = 'Erro nas configurações do banco de dados, contate o Departamento de TI';
          else if (errorMessage === 'already_an_entry_for_this_period')
            errorMessage = `Já existe um lançamento para essa data e hora para o usuário: "${data.value}"`;
          else if (errorMessage === 'invalid_date_same_or_less')
            errorMessage = 'A data informada não pode ser menor ou igual a data atual';
          else if (errorMessage === '') errorMessage = 'Erro desconhecido';

          setSnackbarType('error');
          setSnackbarMsg(errorMessage);
          setOpenSnackbar(true);
        }
      }
      setWaitingDelete(false);
    }
  };
  if (loadingData)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>{loadingText}</Typography>
      </Box>
    );
  if (userData?.length === 0)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>Nenhuma programação encontrada</Typography>
      </Box>
    );
  return (
    <>
      {userData.map(user => {
        const userId = user.id.toString();
        const nickName = user.nickname.split(' ');
        let newName = '';

        for (let index = 0; index < nickName.length; index++) {
          if (index < 2) {
            newName += nickName[index];
          }
          newName += ' ';
        }
        const userName = newName.trim();
        const userSector = user.sector;
        const dateStart = user.date_start;
        const dateEnd = user.date_end;
        const hours = user.hours_formated;
        const minutes = user.minutes_formated;
        const remaining = user.days_remaining;

        return (
          <Box
            key={`${user.id}-${user.date_start}-${user.hours}`}
            className={`${classes.itemContainer} ${
              remaining < 1 ? classes.titleItemRed : remaining < 7 ? classes.titleItemYellow : classes.titleItemGreen
            }`}
          >
            <Box className={classes.deleteContainer}>
              <Tooltip title="Excluir programação">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={() => handleDeleteDialogClickOpen(user)}
                  disabled={waitingDelete}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Box>
            <Box className={classes.hoursContainer}>
              <Typography className={classes.hoursValue}>{`${hours}:${minutes}`}</Typography>
              <Typography className={classes.hoursDescription}>horas</Typography>
            </Box>
            <Box className={classes.avatarContainer}>
              <Link to={`/profile/${userId}`} className={classes.avatarLink} style={{ textDecoration: 'none' }}>
                <ProfileTooltip userId={userId}>
                  <Avatar
                    alt={`${userName}`}
                    src={avatarUrl(userId, true)}
                    aria-label={`${userName.toLowerCase()}`}
                    className={classes.avatar}
                  />
                </ProfileTooltip>
              </Link>
              <Box className={classes.avatarDescriptionContainer}>
                <Box width="100%">
                  <Typography className={classes.avatarDescriptionName}>{userName}</Typography>
                </Box>
                {dateStart !== dateEnd ? (
                  <Box className={classes.avatarDescriptionDateContainer}>
                    <Box className={classes.avatarDescriptionDateDescriptionContainer}>
                      <Typography className={classes.avatarDescriptionDateDescription}>Inicio:</Typography>
                      <Typography className={classes.avatarDescriptionDateDescription}>Fim:</Typography>
                    </Box>
                    <Box className={classes.avatarDescriptionDateValueContainer}>
                      <Typography className={classes.avatarDescriptionDateValue}>{dateStart}</Typography>
                      <Typography className={classes.avatarDescriptionDateValue}>{dateEnd}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.avatarDescriptionDateContainer}>
                    <Box className={classes.avatarDescriptionDateDescriptionContainer}>
                      <Typography className={classes.avatarDescriptionDateDescription}>Data:</Typography>
                    </Box>
                    <Box className={classes.avatarDescriptionDateValueContainer}>
                      <Typography className={classes.avatarDescriptionDateValue}>{dateStart}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja excluir essa programação de folga?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${deleteUser?.nickname}, dia: ${deleteUser?.date_start} as ${deleteUser?.hours_formated}:${deleteUser?.minutes_formated}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleDeleteDialogClose} autoFocus disabled={waitingDelete}>
            Cancelar
          </Button>
          <Button color="success" onClick={handleDelete} disabled={waitingDelete}>
            {waitingDelete ? (
              <>
                <CircularProgress color="inherit" style={{ height: 24, width: 24, marginRight: 8 }} />
                Excluindo
              </>
            ) : (
              <>Excluir</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
