import React, { useEffect, useState } from 'react';

import { Box, Paper, useTheme } from '@mui/material';
import { EngOsHomeTitle } from '../EngOsHomeTitle';
import { EngOsAttendanceList, EngOsAttendanceListFilterType } from './EngOsAttendanceList';

interface EngOsAttendanceProps {
  filters?: EngOsAttendanceListFilterType;
}

function EngOsAttendance({ filters: attendanceFilters }: EngOsAttendanceProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [filters, setFilters] = useState({
    budgets: [],
    customers: [],
    orders: [],
    helpdesks: [],
    attendants: [],
    situation: attendanceFilters?.situation ?? [],
  } as EngOsAttendanceListFilterType);

  useEffect(() => {
    setFilters(old => ({ ...old, ...attendanceFilters }));
  }, [attendanceFilters]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <EngOsHomeTitle
          title="Atendimento"
          initialFilter={attendanceFilters}
          filterList={{
            attendants: false,
            budgets: true,
            customers: true,
            orders: true,
            helpdesks: true,
            situation: true,
          }}
          onChange={_filters => {
            setFilters(old => ({ ...old, ..._filters }));
          }}
        />
        <Paper sx={{ p: 2 }}>
          <EngOsAttendanceList filters={filters} />
        </Paper>
      </Box>
    </Box>
  );
}

export { EngOsAttendance };
