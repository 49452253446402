import Dashboard from './dashboard';
import Request from './request';
import Search from './search';
import Level from './level';

export default {
  Dashboard,
  Request,
  Search,
  Level,
};
