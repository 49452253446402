import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      padding: 1,
      width: '100%',
      height: 'auto',
      maxHeight: 'calc(100vh - 294px)',
      minHeight: '256px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      padding: 1,
      maxWidth: '480px',
      textAlign: 'center',
    },
    button: {
      padding: 1,
    },
  }),
);
export default styles;
