import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import NewsIcon from '@mui/icons-material/Forum';
import { Carousel } from 'react-responsive-carousel';
import axios, { Canceler } from 'axios';
import { blue, grey } from '@mui/material/colors';
import newsPreviewErrorImg from '../../../images/logo_b.svg';

import api from '../../../services/api';

interface NewsData {
  id: number;
  author_id: number;
  author_name: string;
  author_sector: string;
  news_title: string;
  news_content: string;
  category: number;
  category_description: string;
  likes: number;
  comments: number;
  created_at: number;
  created_at_formated: string;
  created_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  updated_at: number;
  updated_at_formated: string;
  updated_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  edit: number;
  comments_data: CommentsData[];
  likes_data: LikesData[];
  liked: boolean;
  tags: TagsData[];
  viewed: boolean;
  views_count: number;
  situation: number;
}
interface CommentsData {
  id: number;
  user_id: string;
  user_name: string;
  user_sector: string;
  text: string;
  date: string;
  date_formated: string;
  is_my: boolean;
}
interface LikesData {
  user_id: string;
  user_name: string;
  user_sector: string;
  date: string;
}
interface TagsData {
  tag: string;
}

interface INewsCard {
  news: NewsData;
}
function NewsCard(props: INewsCard) {
  const { news } = props;
  const {
    id = 0,
    news_title = '',
    created_at_split = {
      day: '',
      month: '',
      year: '',
      hours: '',
    },
    viewed = false,
  } = news;
  const previewImg = `${api.getUri()}/static/uploads/news/news-preview-${id}.jpg`;

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme => (theme.palette.mode === 'dark' ? blue[200] : blue[700]),
            }}
          >
            <Typography sx={{ fontSize: '0.5rem', lineHeight: '0.1rem' }}>{created_at_split.year}</Typography>
            <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>{created_at_split.day}</Typography>
            <Typography sx={{ fontSize: '0.75rem', lineHeight: '0.3rem' }}>{created_at_split.month}</Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Box
              component={Link}
              to={`/news/${id}`}
              sx={{
                width: '100%',
                textDecoration: 'none',
                '& :hover': {
                  color: theme => (theme.palette.mode === 'dark' ? blue[200] : blue[700]),
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  textAlign: 'left',
                  color: theme => (theme.palette.mode === 'dark' ? (viewed ? grey[500] : grey[200]) : grey[600]),
                }}
              >
                {news_title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          height: '100%',
        }}
      >
        <Box
          component={Link}
          to={`/news/${id}`}
          sx={{
            width: '100%',
            height: '18rem',
            display: 'flex',
            overflow: 'hidden',
            bgcolor: grey[800],
            borderRadius: 1,
            flex: 1,
          }}
        >
          <img
            alt={news_title}
            src={previewImg}
            style={{
              objectFit: 'cover',
              width: '100%',
              objectPosition: '50% 0%',
            }}
            onError={(e: any) => {
              e.target.src = newsPreviewErrorImg;
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid rgba(0,0,0,0.2)',

          p: 1,

          flex: 1,
        }}
      >
        <Button size="small" variant="outlined" color="info" component={Link} to={`/news/${id}`} fullWidth>
          Abrir notícia
        </Button>
      </Box>
    </Paper>
  );
}
export { NewsCard };
