import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import Axios, { Canceler } from 'axios';
import { FaFileExcel } from 'react-icons/fa';
import styles from './styles';
import api from '../../../../../services/api';
import { LevelData } from '../LevelTable';
import { ValueProps } from '../../../../../components/inputs/InputSearchAsync';

type ExportDialogProps = {
  show: boolean;
  deposits: ValueProps[];
  onClose?: () => void;
};

let cancel: Canceler;

export default function ExportDialog(props: ExportDialogProps) {
  const { show, deposits, onClose = () => {} } = props;
  const classes = styles();
  const [exporting, setExporting] = useState(false);
  const [taskFeedbackText, setTaskFeedbackText] = useState('');
  const [taskFeedbackType, setTaskFeedbackType] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  function exportToCSV(csvData: any, fileName: string) {
    try {
      const ws = XLSX.utils.json_to_sheet(csvData);

      ws.A1.v = 'PRODUTO';
      ws.B1.v = 'DESCRIÇÃO';
      ws.C1.v = 'DERIVAÇÃO';
      ws.D1.v = 'DESCRIÇÃO';
      ws.E1.v = 'ESTOQUE';
      ws.F1.v = 'DISPONÍVEL';
      ws.G1.v = 'BLOQUEADO';
      ws.H1.v = 'RESERVADO';
      ws.I1.v = 'RESERVA EXCLUSIVA';
      ws.J1.v = 'QTD. SC';
      ws.K1.v = 'ORDEM O.C./O.P.';
      ws.L1.v = 'EST. MAX';
      ws.M1.v = 'LEAD TIME TOTAL';
      ws.N1.v = 'LEAD TIME 1 ANO';
      ws.O1.v = 'CURVA ABC';

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error: any) {}
  }

  async function exportToExcel({ deposits = [] as ValueProps[] }) {
    if (exporting) return;
    setExporting(true);

    try {
      let searchDeposits = '';
      for (const deposit of deposits) {
        searchDeposits += `;${deposit.id}`;
      }

      const params = {
        result_limit: -1,
        page_current: 1,
        deposits: searchDeposits,
        xlsx: 1,
      };

      const response = await api.get('erp/stock/level', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      const data = response?.data as LevelData;

      exportToCSV(data.results, 'nivel_estoque');

      setTaskFeedbackType('success');
      setTaskFeedbackText(`Relatório gerado com sucesso`);
      setExporting(false);
    } catch (error: any) {
      setTaskFeedbackType('error');
      setTaskFeedbackText(`Erro ao gerar relatório: ${error?.message}`);

      setExporting(false);
    }
    return () => cancel();
  }

  async function handleExport() {
    try {
      await exportToExcel({ deposits });
    } catch (error: any) {}
  }
  function handleCancel() {
    cancel();
    setExporting(false);
  }
  function handleCloseDialog() {
    if (exporting) {
      return;
    }
    setTaskFeedbackType('success');
    setTaskFeedbackText('');
    setExporting(false);

    onClose?.();
  }
  return (
    <Box>
      <Dialog
        open={show}
        fullWidth
        onClose={handleCloseDialog}
        scroll="paper"
        aria-labelledby="export-dialog-title}"
        aria-describedby="export-dialog-description"
      >
        <DialogTitle id="export-title">Exportar</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tipo</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value="xlsx">
                  <FormControlLabel
                    value="xlsx"
                    control={<Radio />}
                    label={
                      <Typography className={classes.typeFilterText}>
                        <FaFileExcel style={{ marginRight: 4 }} /> Excel (xlsx)
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={1}>
              <Box className={classes.separator} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box className={classes.filterRoot}>
                <Typography style={{ marginBottom: 4 }}>Filtros</Typography>
                <Box display="flex" alignItems="flex-start" mb={1} flexDirection="column" style={{ gap: 4 }}>
                  {deposits.map((deposit, index) => {
                    return (
                      <Typography key={index} className={classes.depositsFilterText}>
                        {deposit.id} - {deposit.description}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              {exporting && (
                <Box mt={1}>
                  Gerando Relatório, isso pode demorar dependendo do filtro aplicado.
                  <LinearProgress />
                </Box>
              )}
              {taskFeedbackText?.length > 0 && (
                <Box mt={1}>
                  <Typography
                    className={taskFeedbackType === 'error' ? classes.taskFeedbackError : classes.taskFeedbackSuccess}
                  >
                    {taskFeedbackText}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" disabled={exporting}>
            Fechar
          </Button>
          <Button
            onClick={() => {
              if (exporting) handleCancel();
              else handleExport();
            }}
            color={exporting ? 'secondary' : 'primary'}
          >
            {exporting ? 'CANCELAR' : 'INICIAR'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
