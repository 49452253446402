import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import MoneyIcon from '@mui/icons-material/LocalAtm';
import { green } from '@mui/material/colors';
import axios, { Canceler } from 'axios';
import api from '../../services/api';

interface PaydayList {
  title: string;
  subtitle: string;
  date: string;
  day: string;
  month: string;
  year: string;
}

function PaydayCard() {
  const [paydayList, setPaydayList] = useState<PaydayList[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const { data } = await api.get<PaydayList[]>('hcm/paydays', {
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setPaydayList(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };
  useEffect(() => {
    getData();
  }, []);

  const Row = (props: {
    first?: boolean;
    day: string;
    month: string;
    year: string;
    title: string;
    description: string;
  }) => {
    const { first, day, month, year, description, title } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
            color: theme => (theme.palette.mode === 'light' ? green[800] : green[300]),
          }}
        >
          <Typography sx={{ fontSize: '0.5rem', lineHeight: '0.2rem' }}>{year}</Typography>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.9rem' }}>{day}</Typography>
          <Typography sx={{ fontSize: '0.75rem', lineHeight: '0.4rem' }}>{month}</Typography>
        </Box>

        <Box>
          <Typography sx={{ fontSize: '1rem' }}>{title}</Typography>
          <Typography
            sx={{
              fontSize: '0.7rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    );
  };

  const FakeRow = (props: { first?: boolean }) => {
    const { first } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
          }}
        >
          <Skeleton variant="rectangular" height="0.4rem" width={20} sx={{ mb: 0.4, borderRadius: 0.5 }} />
          <Skeleton variant="rectangular" height="1.1rem" width={30} sx={{ mb: 0.4, borderRadius: 0.5 }} />
          <Skeleton variant="rectangular" height="0.7rem" width={30} sx={{ borderRadius: 0.5 }} />
        </Box>

        <Box>
          <Skeleton variant="rectangular" height="0.9rem" width={100} sx={{ mb: 1.1, borderRadius: 0.5 }} />
          <Skeleton variant="rectangular" height="0.7rem" width={140} sx={{ borderRadius: 0.5 }} />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Paper>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            borderBottom: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <MoneyIcon sx={{ color: theme => (theme.palette.mode === 'light' ? green[800] : green[300]) }} />
          <Typography>Próximos Pagamentos</Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
            borderEndEndRadius: 4,
            borderEndStartRadius: 4,
          }}
        >
          {!loadingData ? (
            paydayList?.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  mt: 1,
                  minHeight: 60,
                  color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                }}
              >
                <Typography>Nenhum pagamento encontrado</Typography>
              </Box>
            ) : (
              paydayList?.map((payday, i) => {
                const key = `${i}-${payday.date}`;
                return (
                  <Row
                    first={i === 0}
                    key={key}
                    day={payday.day}
                    month={payday.month}
                    year={payday.year}
                    description={payday.subtitle}
                    title={payday.title}
                  />
                );
              })
            )
          ) : (
            Array.from({ length: 4 }, (v, k) => k).map((k, i) => {
              return <FakeRow first={k === 0} key={k} />;
            })
          )}
        </Box>
      </Paper>
    </Box>
  );
}
export { PaydayCard };
