import React, { useEffect, useRef, useState } from 'react';
import Axios, { Canceler, CancelTokenSource } from 'axios';

import { Link, useParams } from 'react-router-dom';
import {
  Snackbar,
  Box,
  Paper,
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  LinearProgress,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextFieldProps,
  Backdrop,
  Tooltip,
  Button,
} from '@mui/material';

import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import api from '../../../../services/api';
import OrderMessage from '../../../../components/OrderMessage';

import notFoundStyles from '../../../templates/not_found/styles';
import notFoundImg from '../../../../images/not_found.svg';
import notFoundLightImg from '../../../../images/not_found_light.svg';
import styles from './styles';

type HeaderColumnProps = {
  colSpan: number;
  aling: AlingProps;
  sort?: boolean;
  fieldName: string;
  fieldAling: AlingProps;
  text: string;
  tooltipTitle?: string;
  width?: string | '100%';
};
type RowColumnProps = {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
};
type ResponseDataType = {
  results: OrderType[];
  filters: {
    result_limit: number;
    page_current: number;
    order: string[];
    order_id: string[];
    situation: string[];
    show_items: boolean;
    created_at: string[];
  };
  statistics: {
    total: {
      results: number;
      pages: number;
      messages: number;
    };
  };
};
type OrderType = {
  numped: string;
  codcli: string;
  nomcli: string;
  codcpg: string;
  descpg: string;
  tipped: string;
  tipped_description: string;
  datemi: string;
  datemi_formated: string;
  horemi: string;
  horemi_formated: string;
  datprv: string;
  datprv_formated: string;
  horprv: string;
  horprv_formated: string;
  usuger: string;
  usuger_name: string;
  codrep: string;
  nomrep: string;
  sitped: string;
  sitped_description: string;
  cgccpf: string;
  insest: string;
  insmun: string;
  endcli: string;
  nencli: string;
  baicli: string;
  cidcli: string;
  sigufs: string;
  cplend: string;
  cepcli: string;
  foncli: string;
  foncl2: string;
  foncl3: string;
  foncl4: string;
  foncl5: string;
  intnet: string;
  timeline: TimelineType[];
  products: OrderProductType[];
  services: OrderServiceType[];
  finances: OrderfinanceType[];
  progress: {
    billing: {
      max: number;
      min: number;
      current: number;
      percent: number;
    };
    production: {
      estimated_time: number;
      real_time: number;
      start_date: string;
      start_date_formated: string;
      end_date: string;
      end_date_formated: string;
      operation_total: number;
      percent: number;
    };
  };
};
type TimelineType = {
  type: 'start' | 'end' | 'production_start' | 'production_end' | 'cancel';
  date: string;
  text: string;
};
type OrderProductType = {
  seqipd: string;
  codpro: string;
  despro: string;
  cplipd: string;
  codder: string;
  desder: string;
  qtdped: number;
  qtdaen: string;
  qtdpoc: number;
  qtdfat: number;
  qtdcan: number;
  qtdabe: number;
  qtdrae: number;
  qtdnlp: number;
  qtdres: number;
  unimed: string;
  preuni: number;
  vlrbru: number;
  pretot: number;
  usu_obsct1: string;
  usu_obsct2: string;
  usuger: string;
  obsipd: string;
  sitipd: string;
  sitipd_description: string;
  pretot_formated: string;
  preuni_formated: string;
  vlrbru_formated: string;
  qtdped_formated: string;
  qtdfat_formated: string;
  qtdcan_formated: string;
  qtdabe_formated: string;
  qtdres_formated: string;
};
type OrderServiceType = {
  seqisp: string;
  tnsser: string;
  codser: string;
  cplisp: string;
  codfam: string;
  qtdped: number;
  qtdaen: string;
  qtdpoc: number;
  qtdfat: number;
  qtdcan: number;
  qtdabe: number;
  qtdrae: number;
  qtdnlp: number;
  qtdres: number;
  unimed: string;
  preuni: number;
  vlrbru: number;
  pretot: number;
  obsisp: string;
  usuger: string;
  desser: string;
  sitisp: string;
  sitisp_description: string;
  pretot_formated: string;
  preuni_formated: string;
  vlrbru_formated: string;
  qtdped_formated: string;
  qtdfat_formated: string;
  qtdcan_formated: string;
  qtdabe_formated: string;
  qtdaen_formated: string;
  qtdpoc_formated: string;
  qtdrae_formated: string;
  qtdnlp_formated: string;
  qtdres_formated: string;
};

type OrderfinanceType = {
  numtit: string;
  codtpt: string;
  sittit: string;
  datemi: string;
  vctori: string;
  vctpro: string;
  vlrori: number;
  vlrabe: number;
  codsnf: string;
  numnfv: string;
  usu_datpgt: string;
  obstcr: string;
  usu_datpgt_formated: string;
  datemi_formated: string;
  vctori_formated: string;
  vctpro_formated: string;
};
export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export default function HelpdeskViewPage(props: any) {
  const mountedRef = useRef(true);
  const { id } = useParams() as { id: string };
  const { darkMode } = props as { darkMode: boolean };

  const classes = styles();
  const classesNf = notFoundStyles();

  const [loadingOrderData, setLoadingOrderData] = useState<boolean>(false);
  const [invalidOrder, setInvalidOrder] = useState<boolean>(false);

  const [responseData, setResponseData] = useState<ResponseDataType>({} as ResponseDataType);
  const [order, setOrder] = useState<OrderType>({} as OrderType);

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    loadOrderData();
    return () => {
      mountedRef.current = false;
    };
  }, [id]);

  async function loadOrderData() {
    if (!mountedRef.current) return;
    if (loadingOrderData) return;
    let cancel: Canceler;
    try {
      setLoadingOrderData(true);
      const response: ResponseDataType = await (
        await api.get('erp/orders', {
          params: { order_id: [id] },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
      ).data;

      if (response?.results?.length > 0) {
        setResponseData(response);
        setOrder(response.results[0]);
        setInvalidOrder(false);
      } else {
        setOrder({} as OrderType);
        setInvalidOrder(true);
      }
      setLoadingOrderData(false);
    } catch (error: any) {
      const data = error?.response?.data;
      if (data) {
        let errorMessage = data?.message;
        if (!errorMessage) errorMessage = data?.error;
        if (errorMessage === '') errorMessage = '';
        else if (errorMessage === '') errorMessage = '';
        else errorMessage = '';

        setSnackbarType('error');
        setSnackbarMsg(errorMessage);
        setOpenSnackbar(true);
      }
    }
    return () => cancel();
  }

  function Field(props: TextFieldProps) {
    return <TextField fullWidth variant="outlined" type="text" size="small" {...props} />;
  }
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  function DataBasicInformation() {
    return (
      <Paper style={{ padding: 14, width: '100%' }}>
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          <Grid item xs={3} sm={2} md={2}>
            <Field id="order-numped" label="Número" value={order.numped} />
          </Grid>
          <Grid item xs={9} sm={10} md={4}>
            <Field id="order-codcli" label="Cliente" value={`${order.codcli} - ${order.nomcli}`} />
          </Grid>
          <Grid item xs={6} sm={2} md={2}>
            <Field id="order-datemi" label="Emissão" value={order.datemi_formated} />
          </Grid>
          <Grid item xs={6} sm={2} md={2}>
            <Field id="order-datemi" label="Previsão" value={order.datprv_formated} />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field id="order-sitped" label="Situação" value={`${order.sitped} - ${order.sitped_description}`} />
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Field id="order-codcpg" label="Con. Pagamento" value={`${order.codcpg} - ${order.descpg}`} />
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <Field id="order-codrep" label="Representante" value={`${order.codrep} - ${order.nomrep}`} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Field id="order-usuger" label="Usuário Geração" value={`${order.usuger} - ${order.usuger_name}`} />
          </Grid>
          <Grid item xs={12}>
            <Field
              id="order-endcli"
              label="Endereço Cliente"
              value={`${order.endcli} - ${order.cplend} - ${order.nencli} - ${order.baicli} - ${order.cidcli} - ${order.sigufs} - ${order.cepcli}`}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function ProgressNfe() {
    const currentPercent = order?.progress?.billing?.percent | 0;

    return (
      <Box display="flex" alignItems="flex-start" justifyContent="center" width="100%" flexDirection="column" flex={1}>
        <Typography className={classes.progressBarText}>Faturamento</Typography>
        <Typography
          className={classes.progressBarText}
          style={{ marginTop: 0 }}
        >{`Qtd Item(s) : ${order?.progress?.billing?.current?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })} / ${order?.progress?.billing?.max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })} (${currentPercent}%)`}</Typography>
        <LinearProgress
          variant="determinate"
          value={currentPercent}
          className={`${
            currentPercent < 25
              ? classes.progressBarColor0
              : currentPercent < 50
              ? classes.progressBarColor25
              : currentPercent < 75
              ? classes.progressBarColor50
              : currentPercent < 100
              ? classes.progressBarColor75
              : classes.progressBarColor100
          } ${classes.progressBar}`}
        />
      </Box>
    );
  }
  function ProgressProduction() {
    const currentPercent = order?.progress?.production?.percent | 0;
    return (
      <Box display="flex" alignItems="flex-start" justifyContent="center" width="100%" flexDirection="column" flex={1}>
        <Typography className={classes.progressBarText}>Produção</Typography>
        <Typography
          className={classes.progressBarText}
          style={{ marginTop: 0 }}
        >{`Horas prevista / realizadas : ${order?.progress?.production?.estimated_time?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}h / ${order?.progress?.production?.real_time?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}h (${currentPercent}%)`}</Typography>
        <LinearProgress
          variant="determinate"
          value={currentPercent > 100 ? 100 : currentPercent}
          className={`${
            currentPercent < 50
              ? classes.progressBarColor25
              : currentPercent < 75
              ? classes.progressBarColor50
              : currentPercent < 100
              ? classes.progressBarColor75
              : currentPercent === 100
              ? classes.progressBarColor100
              : classes.progressBarColor0
          } ${classes.progressBar}`}
        />
      </Box>
    );
  }
  function AccordionTimeline() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <TimelineIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>Timeline</Typography>
            <Typography className={classes.sectionTitleSeconday}>(Em desenvolvimento)</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.timelineRoot} width="100%">
            <Timeline position="alternate">
              {order?.timeline?.map((timeline: TimelineType, index) => {
                const icon =
                  timeline.type === 'start' ? (
                    <TimelineDot className={classes.timelineDotStart}>
                      <AddIcon />
                    </TimelineDot>
                  ) : timeline.type === 'end' ? (
                    <TimelineDot className={classes.timelineDotEnd}>
                      <DoneIcon />
                    </TimelineDot>
                  ) : timeline.type === 'cancel' ? (
                    <TimelineDot className={classes.timelineDotCancel}>
                      <BuildIcon />
                    </TimelineDot>
                  ) : timeline.type === 'production_start' ? (
                    <TimelineDot className={classes.timelineDotProductionStart}>
                      <AssignmentLateIcon />
                    </TimelineDot>
                  ) : timeline.type === 'production_end' ? (
                    <TimelineDot className={classes.timelineDotProductionEnd}>
                      <AssignmentTurnedInIcon />
                    </TimelineDot>
                  ) : (
                    <></>
                  );
                const end = index === order?.timeline?.length - 1;
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      {icon}
                      {!end && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={classes.timelinePaper}>
                        <Typography className={classes.timelineTitleText}>{timeline.date}</Typography>
                        <Typography>{timeline.text}</Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionProducts() {
    const columns = [
      { aling: 'right', width: '48px', fieldName: 'seqipd', fieldAling: 'right', text: 'Seq.' },
      { aling: 'left', width: '148px', fieldName: 'codpro', fieldAling: 'left', text: 'Cod. Produto.' },
      { aling: 'left', width: '312px', fieldName: 'despro', fieldAling: 'left', text: 'Des. Produto' },
      { aling: 'left', width: '148px', fieldName: 'sitipd', fieldAling: 'left', text: 'Situação' },
      { aling: 'left', width: '312px', fieldName: 'cplipd', fieldAling: 'left', text: 'Complemento.' },
      { aling: 'right', width: '128px', fieldName: 'codder', fieldAling: 'right', text: 'Cod. Der.' },
      { aling: 'left', width: '128px', fieldName: 'desder', fieldAling: 'left', text: 'Des. Der.' },
      { aling: 'right', width: '142px', fieldName: 'qtdped', fieldAling: 'right', text: 'Qtd. Pedido' },
      { aling: 'right', width: '142px', fieldName: 'qtdfat', fieldAling: 'right', text: 'Qtd. Faturada' },
      { aling: 'right', width: '142px', fieldName: 'qtdcan', fieldAling: 'right', text: 'Qtd. Cancelada' },
      { aling: 'right', width: '142px', fieldName: 'qtdabe', fieldAling: 'right', text: 'Qtd. Aberto' },
      { aling: 'right', width: '142px', fieldName: 'qtdres', fieldAling: 'right', text: 'Qtd. Reservada' },
      { aling: 'center', width: '128px', fieldName: 'unimed', fieldAling: 'center', text: 'U.M.' },
      { aling: 'right', width: '142px', fieldName: 'preuni', fieldAling: 'right', text: 'Preço Uni.' },
      { aling: 'right', width: '142px', fieldName: 'pretot', fieldAling: 'right', text: 'Preço Total' },
      { aling: 'right', width: '142px', fieldName: 'vlrbru', fieldAling: 'right', text: 'Valor Bruto.' },
      { aling: 'left', width: '512px', fieldName: 'usu_obsct1', fieldAling: 'left', text: 'Obs. Carteira 1' },
      { aling: 'left', width: '512px', fieldName: 'usu_obsct2', fieldAling: 'left', text: 'Obs. Carteira 2' },
    ] as HeaderColumnProps[];

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection="column" flex={1}>
            <Box display="flex" alignItems="flex-start" justifyContent="center">
              <SettingsApplicationsOutlinedIcon className={classes.sectionIcon} />
              <Box display="flex" alignItems="center">
                <Typography className={classes.sectionTitle}>Produtos</Typography>
                <Typography className={classes.sectionTitleSeconday}>{`(${order?.products?.length})`}</Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {order?.products?.length > 0 ? (
            <Box className={classes.productsRoot} width="100%">
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      {columns?.map((column: HeaderColumnProps, index) => {
                        return (
                          <TableCell
                            key={`products_columns_${index}`}
                            style={{ minWidth: column.width, width: column.width, maxWidth: column.width }}
                            colSpan={column.colSpan}
                            align={column.aling}
                          >
                            <Typography style={{ fontSize: 14 }}>{column.text}</Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {order?.products?.map((product: OrderProductType, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={`order-${index}`}>
                          {columns?.map((column: HeaderColumnProps, index) => {
                            return (
                              <TableCell
                                key={`columns-${index}`}
                                colSpan={column.colSpan}
                                align={column.fieldAling}
                                style={{
                                  minWidth: column.width,
                                  width: column.width,
                                  maxWidth: column.width,
                                }}
                              >
                                {column.fieldName === 'seqipd' && product.seqipd}
                                {column.fieldName === 'codpro' && product.codpro}
                                {column.fieldName === 'sitipd' && `${product.sitipd} - ${product.sitipd_description}`}

                                {column.fieldName === 'despro' && product.despro}
                                {column.fieldName === 'cplipd' && product.cplipd}
                                {column.fieldName === 'codder' && product.codder}
                                {column.fieldName === 'desder' && product.desder}
                                {column.fieldName === 'qtdped' && product.qtdped_formated}
                                {column.fieldName === 'qtdfat' && product.qtdfat_formated}
                                {column.fieldName === 'qtdcan' && product.qtdcan_formated}
                                {column.fieldName === 'qtdabe' && product.qtdabe_formated}
                                {column.fieldName === 'qtdres' && product.qtdres_formated}
                                {column.fieldName === 'unimed' && product.unimed}
                                {column.fieldName === 'preuni' && product.preuni_formated}
                                {column.fieldName === 'pretot' && product.pretot_formated}
                                {column.fieldName === 'vlrbru' && product.vlrbru_formated}
                                {column.fieldName === 'usu_obsct1' && product.usu_obsct1}
                                {column.fieldName === 'usu_obsct2' && product.usu_obsct2}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography className={classes.noItemsText}>NENHUM PRODUTOS CADASTRADO PARA ESSE PEDIDO</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionServices() {
    const columns = [
      { aling: 'right', width: '48px', fieldName: 'seqisp', fieldAling: 'right', text: 'Seq.' },
      { aling: 'left', width: '148px', fieldName: 'codser', fieldAling: 'left', text: 'Cod. Serviço.' },
      { aling: 'left', width: '312px', fieldName: 'desser', fieldAling: 'left', text: 'Des. Serviço' },
      { aling: 'left', width: '148px', fieldName: 'sitisp', fieldAling: 'left', text: 'Situação' },
      { aling: 'left', width: '312px', fieldName: 'cplisp', fieldAling: 'left', text: 'Complemento.' },
      { aling: 'right', width: '128px', fieldName: 'codfam', fieldAling: 'right', text: 'Cod. Fam.' },
      { aling: 'right', width: '142px', fieldName: 'qtdped', fieldAling: 'right', text: 'Qtd. Pedido' },
      { aling: 'right', width: '142px', fieldName: 'qtdfat', fieldAling: 'right', text: 'Qtd. Faturada' },
      { aling: 'right', width: '142px', fieldName: 'qtdcan', fieldAling: 'right', text: 'Qtd. Cancelada' },
      { aling: 'right', width: '142px', fieldName: 'qtdabe', fieldAling: 'right', text: 'Qtd. Aberto' },
      { aling: 'right', width: '142px', fieldName: 'qtdres', fieldAling: 'right', text: 'Qtd. Reservada' },
      { aling: 'center', width: '128px', fieldName: 'unimed', fieldAling: 'center', text: 'U.M.' },
      { aling: 'right', width: '142px', fieldName: 'preuni', fieldAling: 'right', text: 'Preço Uni.' },
      { aling: 'right', width: '142px', fieldName: 'pretot', fieldAling: 'right', text: 'Preço Total' },
      { aling: 'right', width: '142px', fieldName: 'vlrbru', fieldAling: 'right', text: 'Valor Bruto.' },
      { aling: 'left', width: '142px', fieldName: 'usuger', fieldAling: 'left', text: 'Usuário Ger.' },
      { aling: 'left', width: '512px', fieldName: 'obsisp', fieldAling: 'left', text: 'Obs.' },
    ] as HeaderColumnProps[];
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <AssignmentOutlinedIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>Serviços</Typography>
            <Typography className={classes.sectionTitleSeconday}>{`(${order?.services?.length})`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {order?.services?.length > 0 ? (
            <Box className={classes.productsRoot} width="100%">
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      {columns?.map((column: HeaderColumnProps, index) => {
                        return (
                          <TableCell
                            key={`columns1-${index}`}
                            style={{ minWidth: column.width, width: column.width, maxWidth: column.width }}
                            colSpan={column.colSpan}
                            align={column.aling}
                          >
                            <Typography style={{ fontSize: 14 }}>{column.text}</Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {order?.services?.map((service: OrderServiceType, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={`orders2-${index}`}>
                          {columns?.map((column: HeaderColumnProps, index) => {
                            return (
                              <TableCell
                                key={`columns2-${index}`}
                                colSpan={column.colSpan}
                                align={column.fieldAling}
                                style={{
                                  minWidth: column.width,
                                  width: column.width,
                                  maxWidth: column.width,
                                }}
                              >
                                {column.fieldName === 'seqisp' && service.seqisp}
                                {column.fieldName === 'tnsser' && service.tnsser}
                                {column.fieldName === 'codser' && service.codser}
                                {column.fieldName === 'cplisp' && service.cplisp}
                                {column.fieldName === 'sitisp' && `${service.sitisp} - ${service.sitisp_description} `}
                                {column.fieldName === 'codfam' && service.codfam}
                                {column.fieldName === 'qtdped' && service.qtdped_formated}
                                {column.fieldName === 'qtdaen' && service.qtdaen_formated}
                                {column.fieldName === 'qtdpoc' && service.qtdpoc_formated}
                                {column.fieldName === 'qtdfat' && service.qtdfat_formated}
                                {column.fieldName === 'qtdcan' && service.qtdcan_formated}
                                {column.fieldName === 'qtdabe' && service.qtdabe_formated}
                                {column.fieldName === 'qtdrae' && service.qtdrae_formated}
                                {column.fieldName === 'qtdnlp' && service.qtdnlp_formated}
                                {column.fieldName === 'qtdres' && service.qtdres_formated}
                                {column.fieldName === 'unimed' && service.unimed}
                                {column.fieldName === 'preuni' && service.preuni_formated}
                                {column.fieldName === 'vlrbru' && service.vlrbru_formated}
                                {column.fieldName === 'pretot' && service.pretot_formated}
                                {column.fieldName === 'obsisp' && service.obsisp}
                                {column.fieldName === 'usuger' && service.usuger}
                                {column.fieldName === 'desser' && service.desser}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography className={classes.noItemsText}>NENHUM SERVIÇO CADASTRADO PARA ESSE PEDIDO</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionCustomer() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <AccountBoxOutlinedIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>Cliente</Typography>
            <Typography className={classes.sectionTitleSeconday}>(Em desenvolvimento)</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={3} sm={2} md={2}>
              <TextField
                fullWidth
                id="order-numped"
                label="Código"
                variant="outlined"
                type="text"
                size="small"
                value="345"
              />
            </Grid>
            <Grid item xs={9} sm={10} md={4}>
              <TextField
                fullWidth
                id="order-codcli"
                label="Nome"
                variant="outlined"
                type="text"
                size="small"
                value="345 - MINERACAO MONTREAL LTDA."
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="order-sitped"
                label="Endereço"
                variant="outlined"
                type="text"
                size="small"
                value="AV FAUSTO RIBEIRO DA SILVA - S/N - BANDEIRINHAS - BETIM - MG - (31) 3599-7000"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionOthersInformations() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <InfoOutlinedIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>Diversos</Typography>
            <Typography className={classes.sectionTitleSeconday}>(Em desenvolvimento)</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.noItemsText}>EM DESENVOLVIMENTO</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionInvoices() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <DescriptionOutlinedIcon className={classes.sectionIcon} />
          <Typography className={classes.sectionTitle}>Notas fiscais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.noItemsText}>EM DESENVOLVIMENTO</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
  function AccordionOP() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <BuildIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>O.P.</Typography>
            <Typography className={classes.sectionTitleSeconday}>{`(${0})`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.noItemsText}>EM DESENVOLVIMENTO</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
  function Accordionfinance() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <LocalAtmRoundedIcon className={classes.sectionIcon} />
          <Box display="flex" alignItems="center">
            <Typography className={classes.sectionTitle}>Financeiro</Typography>
            <Box display="flex" alignItems="center">
              <Typography className={classes.sectionTitleSeconday}>{`(${
                responseData?.statistics?.total?.messages && 0
              })`}</Typography>
            </Box>
            <Typography className={classes.sectionTitleSeconday}>(Em desenvolvimento)</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {order?.finances?.length > 0 ? (
            <Box className={classes.productsRoot} width="100%">
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="right" style={{ width: 120 }}>
                        Nº Título
                      </TableCell>
                      <TableCell align="left" style={{ width: 120 }}>
                        Tipo Título
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Emissão
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Venc. Original
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Venc. Prorrogado
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Pagamento
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Valor Orignal
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Valor Aberto
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Serie NFS
                      </TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        Nº NFS
                      </TableCell>
                      <TableCell align="left">Obs.</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {order?.finances?.map((finance, index) => {
                      return (
                        <TableRow key={`${finance.numtit}.${finance.codtpt}.${index}`}>
                          <TableCell component="th" scope="row" align="right" style={{ width: 120 }}>
                            {finance.numtit}
                          </TableCell>
                          <TableCell align="left" style={{ width: 120 }}>
                            {finance.codtpt}
                          </TableCell>
                          <TableCell align="center" style={{ width: 120 }}>
                            {finance.datemi_formated}
                          </TableCell>
                          <TableCell align="center" style={{ width: 120 }}>
                            {finance.vctori_formated}
                          </TableCell>
                          <TableCell align="center" style={{ width: 120 }}>
                            {finance.vctpro_formated}
                          </TableCell>
                          <TableCell align="center" style={{ width: 120 }}>
                            {finance.usu_datpgt_formated}
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            {finance.vlrori}
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            {finance.vlrabe}
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            {finance.codsnf}
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            {finance.numnfv}
                          </TableCell>
                          <TableCell align="left">{finance.obstcr}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography className={classes.noItemsText}>NENHUM LANÇAMENTO FINANCEIRO PARA ESSE PEDIDO</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
  const AccordionChat = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <ChatOutlinedIcon className={classes.sectionIcon} />
        <Box display="flex" alignItems="center">
          <Typography className={classes.sectionTitle}>Mensagens</Typography>
          <Typography className={classes.sectionTitleSeconday}>{`(${
            responseData?.statistics?.total?.messages | 0
          })`}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <OrderMessage id={id} />
      </AccordionDetails>
    </Accordion>
  );
  const SectionProgress = (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <LinearScaleIcon className={classes.sectionIcon} />
        <Box display="flex" alignItems="center">
          <Typography className={classes.sectionTitle}>Progressos</Typography>
          <Typography className={classes.sectionTitleSeconday}>(Em desenvolvimento)</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" alignItems="center" flexDirection="column" flex={1} style={{ gap: 8 }}>
          <ProgressProduction />
          <ProgressNfe />
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  if (loadingOrderData) {
    return (
      <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
        <Typography variant="caption" className={classes.loadingInitialDataText}>
          Carregando...
        </Typography>
      </Backdrop>
    );
  }
  if (invalidOrder) {
    return (
      <div className={classesNf.root}>
        <img src={darkMode ? notFoundImg : notFoundLightImg} alt="404" className={classesNf.img} />
        <div className={classesNf.content}>
          <Typography variant="h6" className={classesNf.text}>
            O pedido {id} não foi encontrado.
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Paper style={{ width: '100%' }}>
          <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="baseline" style={{ gap: 12 }} p={1.5}>
            <Tooltip title="Pesquisar Pedidos" aria-label="view-orders">
              <Button color="primary" variant="contained" component={Link} to="/erp/orders/search">
                PESQUISAR
              </Button>
            </Tooltip>
          </Box>
        </Paper>

        <Box mt={2} mb={1} display="flex" alignItems="flex-start" width="100%">
          <Typography className={classes.categoryTitle}>DADOS GERAIS</Typography>
        </Box>

        <DataBasicInformation />
        <Box style={{ width: '100%', marginTop: 16 }}>
          <Box>
            <AccordionTimeline />
          </Box>
          <Box>
            <Box mt={2} mb={1}>
              {SectionProgress}
            </Box>
          </Box>
          <Box>
            <Box mt={2} mb={1}>
              {AccordionChat}
            </Box>
          </Box>
          <Box>
            <Box mt={2} mb={1}>
              <Typography className={classes.categoryTitle}>ITENS</Typography>
            </Box>
            <Box>
              <AccordionProducts />
              <AccordionServices />
            </Box>
          </Box>
          <Box>
            <Box mt={2} mb={1}>
              <Typography className={classes.categoryTitle}>INFORMAÇÕES</Typography>
            </Box>
            <Box>
              <AccordionCustomer />
              <Accordionfinance />
              <AccordionOthersInformations />
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
