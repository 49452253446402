import Home from './home';
import View from './view';

type HelpDeskStatusType =
  | 'canceled'
  | 'canceled_late'
  | 'ended'
  | 'ended_late'
  | 'not_started'
  | 'not_started_late'
  | 'started'
  | 'started_late';

export default {
  Home,
  View,
};

export type { HelpDeskStatusType };
