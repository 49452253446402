import React, { useEffect, useState } from 'react';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography, useTheme } from '@mui/material';
import api from '../../../../../../services/api';

import { MessageAlert, MessageAlertType } from '../../../../../../components/MessageAlert';
import { ConfirmationDialog, ConfirmationDialogProps } from '../../../../../../components/ConfirmationDialog';
import { EnOsNewTaskForm } from './EnOsNewTaskForm';
import { EnOsEditTaskForm } from './EnOsEditTaskForm';

type TaskType = {
  id: number;
  description: string;
  parent_task: number;
  situation: number;
  updated_at: string;
  updated_at_formated: string;
  updated_by: number;
  updated_by_name: string;
  days: number;
  childrens: TaskType[];
};

function EngOsTasks() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [tasks, setTasks] = useState<TaskType[]>([]);

  const [openNewTaskDialog, setOpenNewTaskDialog] = useState(false);
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [task, setTask] = useState<TaskType>(null);

  const [messageAlert, setMessagemAlert] = useState({ message: '', show: false, type: 'success' as MessageAlertType });
  const [confirmation, setConfirmation] = useState<ConfirmationDialogProps>(null);

  const fetchingData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<TaskType[]>('erp/engineering/os/tasks/tree', {
        params: {
          situation: 1,
        },
      });

      setTasks([...data]);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setFetching(false);
  };

  const postDelete = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data: response } = await api.delete(`erp/engineering/os/tasks/${task?.id}/del`);
      setMessagemAlert({
        message:
          response.message === 'successfully_deleted'
            ? 'Tarefa removida com sucesso'
            : `Mensagem desconhecida: ${response.message}`,
        show: true,
        type: 'success',
      });
      // eslint-disable-next-line no-empty
    } catch (error) {
      const { data: response } = error.response;
      setMessagemAlert({
        message: response.message === 'invalid_os' ? 'OS inválida.' : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setFetching(false);
    setConfirmation(old => ({ ...old, open: false }));
    fetchingData();
  };

  const renderTask = (_task: TaskType) => {
    return (
      <TreeItem
        key={_task.id}
        nodeId={_task.id.toString()}
        label={`${_task.description} ${
          _task?.childrens.length > 0
            ? ''
            : _task.days === 0
            ? ''
            : ` - (${_task.days} ${_task.days > 0 ? 'dias' : 'dia'})`
        }`}
        onClick={() => setTask(old => (_task?.id !== old?.id ? _task : null))}
        onDoubleClick={() => {
          setTask(_task);
          setOpenEditTaskDialog(true);
        }}
      >
        {_task?.childrens.map(children => renderTask(children))}
      </TreeItem>
    );
  };

  useEffect(() => {
    fetchingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1, width: '100%', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
        <Typography>Tarefas</Typography>
      </Box>
      {fetching ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            height: 240,
          }}
        >
          <Typography color={theme.palette.text.secondary}>Carregando</Typography>
        </Box>
      ) : tasks.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <Typography color={theme.palette.text.secondary}>Nenhuma tarefa encontrada</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.5)',
            p: 1,
            borderRadius: 1,
            border: `1px solid ${isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.3)'}`,
          }}
        >
          <TreeView
            aria-label="task navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={task?.id?.toString() || ''}
            sx={{
              height: 240,
              flexGrow: 1,
              overflowY: 'auto',
            }}
          >
            {tasks?.map(_task => renderTask(_task))}
          </TreeView>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexGrow: 1, gap: 1 }}>
        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            setOpenNewTaskDialog(true);
          }}
        >
          ADICIONAR
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenEditTaskDialog(true);
          }}
          disabled={!task}
        >
          EDITAR
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            const onClickNo = () => {
              setConfirmation(old => ({ ...old, open: false }));
            };
            const onClickYes = () => {
              postDelete();
            };

            setConfirmation({
              open: true,
              title: 'Realmente deseja EXCLUIR essa Tarefa?',
              content: 'Essa ação não pode ser desfeita',
              onClickNo,
              onClickYes,
            });
          }}
        >
          EXCLUIR
        </Button>
      </Box>
      <EnOsNewTaskForm
        open={openNewTaskDialog}
        handleClose={() => setOpenNewTaskDialog(false)}
        handleOpen={() => {}}
        parent={task}
        onSubmited={(success, message) => {
          setMessagemAlert(old => ({ ...old, show: true, message, type: success ? 'success' : 'error' }));
          if (success) {
            setOpenNewTaskDialog(false);
            fetchingData();
          }
        }}
      />
      <EnOsEditTaskForm
        open={openEditTaskDialog}
        taskId={task?.id}
        handleClose={() => setOpenEditTaskDialog(false)}
        handleOpen={() => {}}
        onSubmited={(success, message) => {
          setMessagemAlert(old => ({ ...old, show: true, message, type: success ? 'success' : 'error' }));
          if (success) {
            setOpenEditTaskDialog(false);
            fetchingData();
          }
        }}
      />
      <MessageAlert
        message={messageAlert.message}
        show={messageAlert.show}
        type={messageAlert.type}
        onClose={() => setMessagemAlert(old => ({ ...old, show: false }))}
      />
      <ConfirmationDialog
        title={confirmation?.title ?? ''}
        content={confirmation?.content ?? ''}
        loading={fetching}
        invertButtons
        open={confirmation?.open ?? false}
        onClose={confirmation?.onClose ?? null}
        onClickNo={confirmation?.onClickNo ?? null}
        onClickYes={confirmation?.onClickYes ?? null}
      />
    </Box>
  );
}
export { EngOsTasks };
