import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      cursor: 'default',
    },
    userContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
      width: 196,
    },
    userImg: {
      height: 128,
      width: 128,
      borderRadius: '50%',
      border: '1px solid',
      borderColor: '#1d1d1d',
    },
    userName: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: 18,
      marginTop: 8,
      marginBottom: 2,
    },
    userSector: {
      textAlign: 'center',
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  });
});
