import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';
import zIndex from '@mui/material/styles/zIndex';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    itemsContainer: {
      position: 'relative',

      height: 'calc( 100vh - 180px)',
      overflowY: 'auto',
      width: '100%',
      padding: 10,
    },
    listRoot: {
      overflowY: 'auto',
      width: '100%',
    },
    userListRoot: {
      padding: 16,
      height: 'calc( 100vh - 180px)',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%',
    },
    userListContainer: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 8,
      overflowY: 'auto',
      padding: 16,
      backgroundColor: darkMode ? grey[900] : grey[300],
      borderRadius: 6,
      height: '100%',
    },
    userListItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 8,
      gap: 16,
      backgroundColor: darkMode ? grey[800] : grey[200],
      borderRadius: 6,
      width: '100%',
      '&:hover': {
        backgroundColor: darkMode ? grey[700] : grey[100],
      },
    },
    avatarContainer: {},
    avatarComponent: {
      height: 48,
      width: 48,
      borderStyle: 'solid',
      borderWidth: 1,
      color: darkMode ? grey[800] : grey[300],
    },

    avatarLink: {
      textDecoration: 'none',
    },
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    descriptionName: {
      fontSize: 18,
    },
    descriptionSector: {
      fontSize: 14,
      color: darkMode ? '#bbbbbb' : '#565656',
    },
    buttonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      marginTop: -8,
      marginBottom: -8,
      marginLeft: -8,
      marginRight: -8,
      height: 'calc( 100% + 16px)',
      width: 48,
      transition: 'background-color 0.25s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: darkMode ? grey[400] : grey[500],
        color: darkMode ? grey[100] : grey[100],
      },
    },
    redButton: {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      '&:hover': {
        backgroundColor: darkMode ? red[400] : red[500],
        color: darkMode ? grey[100] : grey[100],
      },
    },
    greenButton: {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      '&:hover': {
        backgroundColor: darkMode ? green[400] : green[500],
        color: darkMode ? grey[100] : grey[100],
      },
    },
    loadingListContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingText: {
      fontSize: 14,
      color: darkMode ? grey[600] : grey[500],
    },
    titleContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      marginBottom: 8,
      gap: 8,
      color: darkMode ? green[200] : green[600],
    },
    titleGreen: {
      color: darkMode ? green[200] : green[600],
    },
    titleRed: {
      color: darkMode ? red[200] : red[600],
    },
    removeAllIconButton: {},
    overlayUpdating: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: darkMode ? '#00000096' : '#0000001c',
      width: '100%',
      height: '100%',
    },
  });
});
