import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    resultContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    resultDescriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 1,
    },
    resultDescriptionText: {
      fontWeight: 600,
      color: darkMode ? grey[200] : grey[800],
    },
    resultSubDescriptionText: {
      fontSize: '12px',
      color: darkMode ? grey[400] : grey[700],
    },
    resultTextHighlights: {
      fontWeight: 700,
      color: darkMode ? yellow[400] : yellow[900],
      backgroundColor: 'transparent',
    },
    resultUserImg: {
      height: 48,
      width: 48,
      borderRadius: '50%',
      border: '1px solid',
      borderColor: darkMode ? grey[700] : grey[400],
    },
    resultUserImgChip: {
      height: 24,
      width: 24,
      borderRadius: '50%',
      border: '1px solid',

      borderColor: darkMode ? grey[700] : grey[400],
    },
  });
});
