import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { format, addBusinessDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ClearIcon from '@mui/icons-material/Clear';

import InputSearchAsync, { ValueProps } from '../../../components/inputs/InputSearchAsync';
import { humanFileSize, toDate, toNumber } from '../../../utils/functions';
import api from '../../../services/api';
import { InputEngOsTask } from '../../../components/inputs/InputEngOsTask';

type TaskType = {
  id: number;
  description: string;
};

type FormStateType = {
  order_number: string;
  budget_number: string;
  task: number;
  days: number;
  classification: string;
  description: string;
  attendant: ValueProps;
  expected_start_date: string;
  expected_end_date: string;
  obs: string;
  rework: number;
  target: number;
};

type ErrorStateType = {
  order_error: string;
  budget_error: string;
  task_error: string;
  start_date_error: string;
  end_date_error: string;
  attendant_error: string;
  attachments_error: string;
};

interface HelpdeskNewFormProps {
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  onSubmited?: (success: boolean, msg: string) => void;
}

const today = new Date().toISOString().split('T')[0];

const defaultFormState: FormStateType = {
  order_number: '',
  budget_number: '',
  task: 0,
  classification: '',
  description: '',
  attendant: null,
  expected_start_date: today,
  expected_end_date: today,
  obs: '',
  rework: 0,
  days: 0,
  target: 0,
};

const defaultErrorState: ErrorStateType = {
  order_error: '',
  budget_error: '',
  task_error: '',
  start_date_error: '',
  end_date_error: '',
  attendant_error: '',
  attachments_error: '',
};

function HelpdeskNewForm(props: HelpdeskNewFormProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const refInputAttachment = useRef<HTMLInputElement | null>(null);
  const { handleClose, handleOpen, open, onSubmited } = props;

  let totalSize = 0;

  const [formState, setFormState] = useState(defaultFormState);
  const [errorState, setErrorState] = useState<ErrorStateType>(defaultErrorState);

  const [submiting, setSubmiting] = useState<boolean>(false);

  const [attachments, setAttachments] = useState<File[]>([]);
  const [orderOrBudget, setOrderOrBudget] = useState('order');

  const resetFormState = () => {
    setErrorState(defaultErrorState);
    setFormState(defaultFormState);
  };

  function handleValideData() {
    let order_error = '';
    let budget_error = '';
    let task_error = '';
    let start_date_error = '';
    let end_date_error = '';
    let attendant_error = '';

    const attachments_error = '';

    if (
      (formState.order_number?.length === 0 && formState.budget_number?.length === 0) ||
      (!formState.order_number && !formState.budget_number)
    ) {
      budget_error = 'É necessário informar um pedido ou orçamento';
      order_error = 'É necessário informar um pedido ou orçamento';
    }
    if (!formState.task || formState.task < 1) {
      task_error = 'É necessário informar uma tarefa';
    }
    if (!formState.expected_start_date || formState.expected_start_date.length === 0) {
      start_date_error = 'É necessário informar uma data de início';
    }
    if (!formState.expected_end_date || formState.expected_end_date.length === 0) {
      end_date_error = 'É necessário informar uma data de fim';
    }
    if (!formState.attendant || formState.attendant?.id?.length === 0) {
      attendant_error = 'É necessário informar um atendente';
    }
    setErrorState({
      order_error,
      budget_error,
      task_error,
      start_date_error,
      end_date_error,
      attendant_error,
      attachments_error,
    });
    if (
      order_error.length > 0 ||
      budget_error.length > 0 ||
      task_error.length > 0 ||
      start_date_error.length > 0 ||
      end_date_error.length > 0 ||
      attendant_error.length > 0 ||
      attachments_error.length > 0
    )
      return false;

    return true;
  }

  const handleSubmit = async () => {
    if (submiting) return;
    setSubmiting(true);
    if (handleValideData()) {
      const data = new FormData();
      data.append('order_number', formState.order_number);
      data.append('budget_number', formState.budget_number);
      data.append('task_id', formState?.task?.toString());
      data.append('attendant_id', formState.attendant?.id?.toString());
      data.append('expected_start_date', formState.expected_start_date);
      data.append('expected_end_date', formState.expected_end_date);
      data.append('obs', formState.obs);
      attachments.map(attachment => data.append('files', attachment));

      try {
        const { data: os } = await api.put('erp/engineering/os/add', data);
        if (os?.id > 0) {
          onSubmited?.(true, 'Inserido com sucesso.');
          resetFormState();
        }
      } catch (error) {
        const { data: response } = error.response;
        onSubmited?.(false, response.message);
      }
    }
    setSubmiting(false);
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    const { value } = e.target;
    setFormState(old => ({ ...old, [id]: value }));
  };

  const handleExpedtedStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let { expected_end_date } = formState;
    const startDate = toDate(value);
    expected_end_date = format(addBusinessDays(startDate, formState.days), 'yyyy-MM-dd');
    setFormState(old => ({ ...old, expected_start_date: value, expected_end_date }));
  };

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachments;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of event.target.files) {
          newAttachments.push(attachment);
        }
      } catch (err) {
        console.error(err);
      }

      setAttachments([...newAttachments]);
    }
  };

  const handleRemoveAllAttachments = () => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    setAttachments([]);
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachments;
    newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);
  };

  const handleOnCloseDialog = () => {
    if (submiting) return;
    handleClose?.();
  };

  const handleClickCancelDialog = () => {
    setAttachments([]);
    setFormState(defaultFormState);
    handleClose?.();
  };

  useEffect(() => {
    if (open) {
      handleOpen?.();
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOrderOrBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    let { order_number } = formState;
    let { budget_number } = formState;
    if (value === 'order') {
      budget_number = '';
    } else {
      order_number = '';
    }
    setFormState(old => ({ ...old, order_number, budget_number }));
    setErrorState(old => ({ ...old, order_error: '', budget_error: '' }));
    setOrderOrBudget(value);
  };

  const inputTarget = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-target"
        id="helpdesk_target"
        onChange={(e: ValueProps) => {
          const newTarget = toNumber(e?.id);
          if (newTarget === 0) {
            setFormState(old => ({ ...old, task: 0 }));
          }
          setFormState(old => ({ ...old, target: newTarget }));
        }}
        label="Setor"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
      />
    ),
    [formState?.target],
  );

  const inputTask = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-task"
        id="helpdesk_task"
        onChange={(e: ValueProps) => {
          setFormState(old => ({ ...old, task: toNumber(e?.id) }));
        }}
        label="Tarefa"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
        disabled={formState?.target === 0}
      />
    ),
    [formState.task, formState?.target],
  );

  const inputPrivate = useMemo(
    () => (
      <TextField
        sx={{ width: 150 }}
        label="Privado"
        id="rework"
        type="string"
        size="small"
        value={formState.rework === 0 ? 'Não' : 'Sim'}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Switch
              sx={{ mr: 1 }}
              checked={formState.rework === 1}
              size="small"
              color="warning"
              onChange={e => {
                setFormState(old => ({ ...old, rework: e.target.checked === true ? 1 : 0 }));
              }}
            />
          ),
        }}
      />
    ),
    [formState.rework],
  );

  const inputStartDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Início Previsto"
        id="expected_start_date"
        type="date"
        onChange={handleExpedtedStartDateChange}
        value={formState.expected_start_date}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.start_date_error?.length > 0}
        helperText={errorState.start_date_error}
        sx={{
          maxWidth: 210,
        }}
        disabled={formState?.task === 0}
      />
    ),
    [
      formState.expected_start_date,
      formState.expected_end_date,
      formState.days,
      errorState.start_date_error,
      formState?.task,
    ],
  );
  const inputEndDate = useMemo(
    () => (
      <TextField
        fullWidth
        label="Data Fim Previsto"
        id="expected_end_date"
        type="date"
        onChange={handleFieldChange}
        value={formState.expected_end_date}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.end_date_error?.length > 0}
        helperText={errorState.end_date_error}
        sx={{
          maxWidth: 210,
        }}
        disabled={formState?.task === 0}
      />
    ),
    [formState.expected_end_date, formState.days, errorState.start_date_error, formState?.task],
  );

  const inputRequestText = useMemo(
    () => (
      <TextField
        fullWidth
        label="Descrição Tarefa"
        id="request_text"
        type="text"
        onChange={handleFieldChange}
        value={formState.obs}
        size="small"
        multiline
        minRows={4}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        disabled={formState?.task === 0}
      />
    ),
    [formState.obs, formState?.task],
  );

  const inputAttachment = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography sx={{ fontSize: 12 }}>Arquivos</Typography>

        <Box
          sx={{
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
              border: `1px solid`,
            },
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            p: 1,
            maxHeight: 128,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              flex: 1,
              height: '100%',
            }}
          >
            {attachments?.length === 0 ? (
              <Box sx={{ p: 0.5 }}>
                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                  Nenhum arquivo informado
                </Typography>
              </Box>
            ) : (
              attachments?.map((attachment: File, index: number) => {
                const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                const name = attachment.name.toLocaleLowerCase();
                const type = nameSplit?.length > 0 ? nameSplit[toNumber(nameSplit?.length) - 1] : 'none';
                const size = humanFileSize(attachment.size);
                const key = `${index}-${name}-${type}-${size}`;
                totalSize += attachment.size;
                return (
                  <Box key={key} sx={{ display: 'flex', gap: 1, flex: 1, alignItems: 'center' }}>
                    <Tooltip title="Remover arquivo" arrow disableInteractive>
                      <IconButton
                        color="error"
                        aria-label="remove file"
                        component="label"
                        size="small"
                        onClick={() => handleRemoveAttachments(index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                      {name} ({size})
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {attachments?.length > 0 && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption">{`${attachments?.length} ${
                attachments?.length === 1 ? 'arquivo selecionado' : 'arquivos selecionados'
              } (${humanFileSize(totalSize)})`}</Typography>
            </Box>
          )}
          <Tooltip title="Remover todos os anexos">
            <IconButton
              color="error"
              aria-label="remove files"
              component="label"
              size="small"
              disabled={attachments?.length === 0}
              onClick={() => handleRemoveAllAttachments()}
            >
              <DeleteSweepOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Selecionar arquivo">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              size="small"
              disabled={formState?.task === 0}
            >
              <input
                style={{ display: 'none' }}
                id="attachment-input"
                hidden
                type="file"
                multiple
                ref={refInputAttachment}
                onChange={handleAddAttachments}
              />
              <NoteAddOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),
    [attachments, formState?.task],
  );

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md" disableEscapeKeyDown>
      <DialogTitle>Abertura de Helpdesk</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
          }}
        >
          <Tooltip title="Se estiver em modo privado, apenas os envolvidos no processo podem visualizar a descrição, resposta e chat.">
            {inputPrivate}
          </Tooltip>
          {inputTarget}
          {inputTask}
          {inputStartDate}
          {inputEndDate}
          {inputRequestText}
          {inputAttachment}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClickCancelDialog} disabled={submiting}>
          Cancelar
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={handleSubmit}
          disabled={formState?.target === 0 || submiting}
        >
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { HelpdeskNewForm };
