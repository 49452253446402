import React, { ReactElement, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EggIcon from '@mui/icons-material/Egg';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { FaCarrot } from 'react-icons/fa';
import { GiCook } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import api from '../../../services/api';
import InputSearchAsync from '../../../components/inputs/InputSearchAsync';

type FoodType = {
  id: string;
  text: string;
};

type DisheType = {
  category_id: string;
  category_text: string;
  foods: FoodType[];
};

type MenuType = {
  id: string;
  date: string;
  situation: string;
  date_formated: string;
  date_full_formated: string;
  day_name: string;
  dishes: DisheType[];
};

function MenuPage() {
  const [menuData, setMenuData] = useState<MenuType[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [foods, setFoods] = useState<FoodType[]>([]);

  const getMenus = async () => {
    if (loadingData) return;
    setLoadingData(true);

    try {
      const { data: menusResponseData } = await api.get('/hcm/menu');
      setMenuData(menusResponseData.menus);
      const { data: foodsResponseData } = await api.get('/hcm/menu/food');
      setFoods(foodsResponseData.foods);
    } catch (error) {}

    setLoadingData(false);
  };

  const Row = (props: { first?: boolean; title: string; content: ReactElement }) => {
    const { first = false, content, title } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 1,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            {title}
          </Typography>
          {content}
        </Box>
      </Box>
    );
  };
  const Menus = (props: { menu: MenuType }) => {
    const { menu } = props;
    return (
      <Box>
        <Paper>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              borderBottom: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <Typography>{menu.date_full_formated}</Typography>
            <Typography sx={{ fontSize: 12, color: theme => (theme.palette.mode === 'dark' ? grey[400] : grey[600]) }}>
              {menu.day_name}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              pt: 1,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center',
              bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
            }}
          >
            <Row
              title="Prato Principal"
              first={true}
              content={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={foods?.map(option => option.text)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <Autocomplete
                    freeSolo
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={foods?.map(option => option.text)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </Box>
              }
            />

            <Row title="Ovo" content={<TextField size="small" />} />
            <Row title="Arroz" content={<TextField size="small" />} />
            <Row title="Feijão" content={<TextField size="small" />} />
            <Row title="Guarnição" content={<TextField size="small" />} />
            <Row
              title="Salada"
              content={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <TextField size="small" fullWidth />
                  <TextField size="small" fullWidth />
                  <TextField size="small" fullWidth />
                  <TextField size="small" fullWidth />
                </Box>
              }
            />
            <Row title="Sobremesa" content={<TextField size="small" />} />

            <Row title="Prato do Chef" content={<TextField size="small" />} />
          </Box>

          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
              borderTop: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <Button size="small" variant="outlined" color="warning" component={Link} disabled to="/hcm/restaurant">
              Salvar
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  };
  useEffect(() => {
    getMenus();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <Button variant="outlined">Novo</Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={1}>
          {menuData.map((menu, index) => {
            return (
              <Grid key={menu.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Menus menu={menu} />{' '}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default MenuPage;
