import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ItemType, StageType } from '.';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { ColumnType, RowType } from '../../../../components/DataTable';
import { OrderObsTextField } from './OrderObsTextField';
import orders from '..';
import { CircularProgressWithLabel } from '../../../../components/CircularProgressWithLabel';

type OrderDetailsProps = {
  edit?: boolean;
  order_id: number;
  items: ItemType[];
};

function Stages({ stage = {} as StageType }) {
  return (
    <Tooltip
      title={
        <>
          <Typography sx={{ fontSize: 14 }}>{stage.desetg}</Typography>
          <Typography variant="caption">Ritmo Produtivo: {`${stage.ritpro}%`}</Typography>
        </>
      }
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width={72}
        sx={{
          cursor: 'default',
          borderRadius: 1,
          backgroundColor: theme => {
            const dark = theme.palette.mode === 'dark';
            return dark ? grey[800] : grey[100];
          },
          padding: 0.5,
        }}
      >
        <Typography sx={{ width: '100%', textAlign: 'center' }}>{stage.codetg.toString()}</Typography>
        <LinearProgressWithLabel value={stage.prgpro} sx={{ width: '100%' }} />
      </Box>
    </Tooltip>
  );
}
function Row(row: RowType) {
  const { cells = [] } = row;

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      {cells?.map((cell, index) => {
        const cellIndex = `cell-${index}`;
        return (
          <TableCell key={cellIndex} component="th" scope="row" align={cell.align}>
            {cell.content}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function OrderDetails({ order_id, items, edit }: OrderDetailsProps) {
  const columns: ColumnType[] = [
    { id: 'seqipd', content: 'Item', align: 'center', orderable: true },
    { id: 'codpro', content: 'Produto', align: 'center', orderable: true },
    { id: 'despro', content: 'Descrição', orderable: true },
    { id: 'qtdped', content: 'Qtd.', align: 'center', orderable: true },
    { id: 'datest', content: 'Data Estimada', align: 'center', orderable: true },
    { id: 'ritpro', content: 'Ritmo Produtivo', align: 'center', orderable: true },
    { id: 'prgpro', content: 'Produção', align: 'center', orderable: true },
    { id: 'prgsup', content: 'Suprimentos', align: 'center', orderable: true },
    { id: 'prgexp', content: 'Expedição', align: 'center', orderable: true },
    { id: 'prgtot', content: 'Progresso', align: 'center', orderable: true },
    { id: 'stages', content: 'Estágios' },
    { id: 'obsct1', content: 'Obs. 1' },
    { id: 'obsct2', content: 'Obs. 2' },
  ];

  const rows: any = items?.map(item => ({
    cells: [
      { content: item.seqipd.toLocaleString(), align: 'center', style: { width: 60 } },
      { content: item.codpro, align: 'center' },
      { content: item.despro },
      { content: item.qtdped.toLocaleString(), align: 'center' },
      { content: item.datest_f, align: 'center' },
      { content: `${item.ritpro}%`, align: 'center' },
      { content: <CircularProgressWithLabel value={item.prgpro} />, align: 'center' },
      {
        content: (
          <Tooltip
            title={
              <Box display="flex" sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 14 }}>Solicitações</Typography>
                <Typography variant="caption">Qtd. Sol.: {`${item.qtdsol.toLocaleString()}`}</Typography>
                <Typography variant="caption">Qtd. Atd.: {`${item.qtdatd.toLocaleString()}`}</Typography>
              </Box>
            }
          >
            <Box>
              <CircularProgressWithLabel value={item.prgsup} />
            </Box>
          </Tooltip>
        ),
        align: 'center',
      },
      {
        content: (
          <Tooltip
            title={
              <Box display="flex" sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 14 }}>Item(s)</Typography>
                <Typography variant="caption">Qtd. Prv.: {`${item.qtdprv.toLocaleString()}`}</Typography>
                <Typography variant="caption">Qtd. Uti.: {`${item.qtduti.toLocaleString()}`}</Typography>
              </Box>
            }
          >
            <Box>
              <CircularProgressWithLabel value={item.prgexp} />
            </Box>
          </Tooltip>
        ),
        align: 'center',
      },

      { content: <CircularProgressWithLabel value={item.prgtot} />, align: 'center' },
      {
        content: (
          <Box display="flex" gap={1}>
            {item?.stages?.map(stage => <Stages key={stage.codetg} stage={stage} />)}
          </Box>
        ),
      },
      {
        content: edit ? (
          <OrderObsTextField order_id={order_id} item_id={item.seqipd} obs="obsct1" value={item.obsct1} />
        ) : (
          <Typography sx={{ minWidth: 120 }}>{item.obsct1}</Typography>
        ),
      },
      {
        content: edit ? (
          <OrderObsTextField order_id={order_id} item_id={item.seqipd} obs="obsct2" value={item.obsct2} />
        ) : (
          <Typography sx={{ minWidth: 120 }}>{item.obsct1}</Typography>
        ),
      },
    ],
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: 1,
        overflow: 'auto',
        maxHeight: 'calc(100vh - 400px)',
        '&>p': {
          color: theme => {
            const dark = theme.palette.mode === 'dark';
            return dark ? grey[400] : grey[600];
          },
          marginBottom: 0.5,
          fontSize: 16,
        },
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-labelledby="tableTitle" size="small" aria-label="table" sx={{ width: 'max-content' }}>
          <TableHead
            sx={{
              height: 30,
              '& > tr > th': {
                backgroundColor: theme => {
                  const dark = theme.palette.mode === 'dark';
                  return dark ? grey[600] : grey[100];
                },
              },
            }}
          >
            <TableRow>
              {columns?.map((column, index) => {
                const { id, align = 'left', content = '', orderable = false } = column;
                const columnIndex = `column-${id}-${index}`;
                return (
                  <TableCell key={columnIndex} align={align} sx={{ pointerEvents: 'none' }}>
                    {content}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row, index) => {
              const { content, cells } = row;
              const rowIndex = `$row-${index}`;
              return <Row key={rowIndex} content={content} cells={cells} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export { OrderDetails };
