import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  InputBase,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellEditStartParams,
  GridCellEditStartReasons,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  MuiEvent,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import WaitingIcon from '@mui/icons-material/HourglassEmpty';
import { ChatComment } from '../../../../../components/ChatComment';
import api from '../../../../../services/api';
import FeedbackAlert, { FeedbackProps } from '../../../../../components/FeedbackAlert';
import { ConfirmationDialog } from '../../../../../components/ConfirmationDialog';

interface Props {
  id: number | string;
  open: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
}

type OsSchedulesType = {
  id: number;
  os_id: number;
  started_at: string;
  started_at_formated: string;
  started_by_name: string;
  ended_at: string;
  ended_at_formated: string;
  ended_by_name: string;
  time_spent: number;
  time_spent_formated: string;
  editable: boolean;
};
type ResponseType = {
  schedules: OsSchedulesType[];
};
function OsSchedulesView({ id, open, handleClose, handleOpen }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [osSchedules, setOsSchedules] = useState<OsSchedulesType[]>([] as OsSchedulesType[]);
  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getOsSchedules = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseType>(`erp/engineering/os/${id}`, {
        params: { type: 'schedules' },
      });

      setOsSchedules(data?.schedules ?? []);
    } catch (error) {}
    setFetching(false);
  };

  const updateOsSchedules = async (id: number, started_at: string, ended_at: string) => {
    if (updating) return;
    setUpdating(true);
    try {
      await api.post('erp/engineering/os/schedule', {
        id,
        started_at,
        ended_at,
      });
      setSnackbarType('success');
      setSnackbarMsg('Atualizado com sucesso');
      setOpenSnackbar(true);
    } catch (error: any) {
      let msg = error?.response?.data?.message;
      if (!msg) msg = error?.response?.data;
      else if (msg === 'invalid_os' || msg === 'invalid_os_schedules') msg = 'Registro de apontamento inválido';
      else if (msg === 'invalid_ended_at') msg = 'Data fim inválida';
      else if (msg === 'os_not_started') msg = 'Apontamento não pode ser iniciado por aqui';
      else if (msg === 'os_not_ended_at') msg = 'Apontamento não pode ser finalizado por aqui';
      else if (msg === 'invalid_started_at') msg = 'Data início inválida';
      else msg = '';
      setSnackbarType('error');
      setSnackbarMsg(msg);
      setOpenSnackbar(true);
    }
    setUpdating(false);
    await getOsSchedules();
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    updateOsSchedules(newRow.id, newRow.started_at_formated, newRow.ended_at_formated);
    return updatedRow;
  };
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleOnCloseDialog = () => {
    handleClose?.();
  };
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', align: 'right', width: 96 },
    { field: 'started_at_formated', headerName: 'Início (Data/Hora)', align: 'center', width: 160, editable: true },
    { field: 'started_by_name', headerName: 'Início (Usuário)', flex: 1 },
    { field: 'ended_at_formated', headerName: 'Fim (Data/Hora)', align: 'center', width: 160, editable: true },
    { field: 'ended_by_name', headerName: 'Fim (Usuário)', flex: 1 },
    { field: 'time_spent_formated', headerName: 'Tempo Gasto', align: 'right', width: 124 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ação',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const editable = osSchedules.filter(row => row.id === id)?.[0].editable || false;

        if (editable && isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (updating) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<WaitingIcon />}
              label="Wait"
              className="textPrimary"
              color="inherit"
              disabled={true}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={!editable}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (open) {
      handleOpen?.();
      getOsSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md">
        <DialogTitle>
          O.S - Apontamentos
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleOnCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent
          sx={{
            bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
            m: 1,
            p: 0,
            borderRadius: 1,
            borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
            borderStyle: 'solid',
            borderWidth: 1,
            minHeight: 200,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
              p: 1,
              overflowY: 'auto',
              height: 'calc(100vh - 200px)',
            }}
          >
            {fetching ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Typography color={theme.palette.text.secondary}>Carregando...</Typography>
              </Box>
            ) : osSchedules?.length > 0 ? (
              <DataGrid
                rows={osSchedules}
                columns={columns}
                // pageSize={20}
                // rowsPerPageOptions={[5]}
                loading={fetching}
                sx={{ width: '100%', height: 'calc(100vh - 138px)' }}
                density="compact"
                hideFooterSelectedRowCount
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}

                // showCellRightBorder
                // showColumnRightBorder
                // disableSelectionOnClick
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Typography color={theme.palette.text.secondary}>Nenhum trâmite encontrado</Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export { OsSchedulesView };
