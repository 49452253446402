import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 16,
    },

    container: {
      width: '100%',
      maxWidth: 1080,
      padding: 16,
    },
    pageTitleContainer: {
      display: 'flex',
      width: '100%',
      maxWidth: 1080,
      alignItems: 'center',
    },
    pageTitle: {
      fontSize: 21,
      color: darkMode ? grey[400] : grey[600],
    },
    gridContainer: {
      marginTop: -8,
    },
    actionContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 1080,
      padding: 16,
    },
    insertButtonIcon: {
      width: 8,
      height: 8,
      marginRight: 8,
    },
    totalHoursTitle: {
      fontSize: 12,
      color: darkMode ? grey[400] : grey[600],
    },
    totalHoursValue: {},
  });
});
