import Home from './home';
import Add from './add';
import Search from './search';
import View from './view';

export default {
  Home,
  Add,
  Search,
  View,
};
