import React, { CSSProperties, ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Paper, TextField, Typography, useTheme } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { BarChart } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { HelpdeskCard } from './HelpdeskCard';
import styles from './styles';
import api from '../../../../../services/api';
import { toNumber } from '../../../../../utils/functions';

interface IHelpdeskOpenClosedYear {}
type YearDataType = {
  month: number;
  name: string;
  abb: string;
  created: number;
  fulfilled: number;
};

const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
};

function HelpdeskOpenClosedYear() {
  const theme = useTheme();
  const [date, setDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false);
  const [yearData, setYearData] = useState<YearDataType[]>(
    Array.from({ length: 12 }, (v, i) => ({ created: 0, fulfilled: 0, abb: months[i] }) as YearDataType),
  );

  const getData = async ({ newDate }) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<YearDataType[]>('v2/helpdesk/dashboard/my/year', {
        params: {
          year: newDate?.getFullYear(),
        },
      });
      setYearData(data || []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({ newDate: date });
  }, [date]);

  const handleSwitchDate = (increment = 0) => {
    const newDate = new Date(date);

    newDate.setFullYear(newDate.getFullYear() + increment);
    if (newDate.getFullYear() < 2019) return;
    if (newDate.getFullYear() > new Date().getFullYear()) return;

    setDate(newDate);
  };

  const inputSelectYear = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
        <IconButton aria-label="delete" size="medium" onClick={() => handleSwitchDate(-1)}>
          <ArrowLeftIcon fontSize="inherit" />
        </IconButton>

        <DatePicker
          views={['year']}
          label="Data"
          minDate={new Date('2020')}
          maxDate={new Date()}
          value={date}
          slotProps={{ textField: { size: 'small' } }}
          onAccept={newValue => {
            setDate(newValue);
          }}
          /*
          renderInput={params => (
            <TextField sx={{ minWidth: 180 }} size="small" {...params} helperText={null} />
          )}
          */
        />

        <IconButton aria-label="delete" size="medium" onClick={() => handleSwitchDate(+1)}>
          <ArrowRightIcon fontSize="inherit" />
        </IconButton>
      </Box>
    ),
    [date],
  );

  const Chart = useMemo(() => {
    const month = yearData?.map(d => d.abb);
    const created = yearData?.map(d => toNumber(d.created));
    const fulfilled = yearData?.map(d => toNumber(d.fulfilled));

    return (
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <BarChart
          xAxis={[{ scaleType: 'band', data: month }]}
          colors={[theme.palette.info.main, theme.palette.success.main]}
          series={[
            { data: created, label: 'Gerados' },
            { data: fulfilled, label: 'Atendidos' },
          ]}
          height={500}
        />
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              backgroundColor: theme.palette.background.paper,
              opacity: 0.8,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
            }}
          >
            <ScaleLoader
              color={theme.palette.info.main}
              loading={loading}
              cssOverride={override}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        )}
      </Box>
    );
  }, [yearData, loading]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 1,
        p: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>Volume de helpdesk&apos;s Gerados x Atendidos por mês</Typography>
        {inputSelectYear}
      </Box>

      {Chart}
    </Paper>
  );
}

export { HelpdeskOpenClosedYear };
