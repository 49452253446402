import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TableSortLabel,
  Tooltip,
  Snackbar,
  Backdrop,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Alert, Pagination, Skeleton } from '@mui/lab/';
import moment from 'moment';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Axios, { Canceler } from 'axios';
import { NewsData, ResponseNewsData } from '../dashboard';
import Filters, { FiltersInputsProps } from '../../../components/Filters';
import api from '../../../services/api';
import styles from './styles';

export interface HeaderColumnProps {
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}

export interface RowColumnProps {
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}

export interface RulesProps {
  id: number;
  type: number;
  description: string;
  value: string;
  allow: string;
}

export interface NewsSearchProps {
  page?: number;
  sortBy?: string;
  limit?: number;
  sortDir?: string;
  id?: string;
  situation?: string;
  date_start?: string;
  date_end?: string;
  title?: string;
  category?: number;
  author_id?: string;
}

export type OrderProp = 'asc' | 'desc';
export type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export interface NewsSearchPageProps {
  darkMode?: boolean;
  search?: NewsSearchProps;
}

export default function NewsPublishedPage(props: NewsSearchPageProps) {
  const classes = styles();
  moment.locale('pt-br');
  moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000 00:00' });

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [loaddingInitialData, setLoaddingInitialData] = useState<boolean>(true);
  const [gettingNewsData, setGettingNewsData] = useState<boolean>(false);
  const [newsData, setNewsData] = useState<ResponseNewsData>({} as ResponseNewsData);

  const [categorysData, setCategorysData] = useState([{}] as any);
  const [gettingCategorysData, setGettingCategorysData] = useState(false);

  const [dataLimit, setDataLimit] = useState<number>(
    parseInt(localStorage.getItem(`search-news-published-data-limit`) || '10', 10),
  );
  const [orderDir, setOrderDir] = useState<OrderProp>(
    (localStorage.getItem(`search-news-published-order-dir`) || 'asc') as OrderProp,
  );
  const [orderBy, setOrderBy] = useState<string>(localStorage.getItem(`search-news-published-order-by`) || 'name');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const columns = [
    { aling: 'center', sort: false, width: '32px', fieldName: 'open_news', text: '', tooltipTitle: '' },
    {
      aling: 'right',
      sort: true,
      width: '104px',
      fieldName: 'id',
      text: 'Número',
      tooltipTitle: 'Ordernar por Número',
    },
    {
      aling: 'left',
      sort: true,
      width: '96px',
      fieldName: 'situation',
      text: 'Situação',
      tooltipTitle: 'Ordernar por Situação',
    },
    {
      aling: 'left',
      sort: true,
      width: '196px',
      fieldName: 'author_name',
      text: 'Autor',
      tooltipTitle: 'Ordernar por Autor',
    },
    { aling: 'left', sort: false, width: '480px', fieldName: 'news_title', text: 'Título', tooltipTitle: '' },
    {
      aling: 'left',
      sort: true,
      width: '164px',
      fieldName: 'created_at',
      text: 'Criado',
      tooltipTitle: 'Ordernar por Data de Criação',
    },
  ] as HeaderColumnProps[];

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem(`search-news-published-data-limit`) || '10',
    localStorage.getItem(`search-news-published-created-at-start`) || '',
    localStorage.getItem(`search-news-published-created-at-end`) || '',
  ]);

  const getCategorys = () => {
    let cancel: Canceler;
    if (!gettingCategorysData) {
      setGettingCategorysData(true);

      api
        .get('intranet/news/categorys', {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const commentsData = response.data;
          setCategorysData(commentsData);
          setGettingCategorysData(false);
        })
        .catch(error => {
          setGettingCategorysData(false);
        });
    }
    return () => cancel();
  };
  const getNewsData = ({
    page = 1,
    sortBy = 'date',
    limit = Number(inputsValue[0]),
    sortDir = inputsValue[1],
    date_start = inputsValue[2],
    date_end = inputsValue[3],
    title = inputsValue[4],
    category = Number(inputsValue[5]),
    author_id = 'my',
  }: NewsSearchProps) => {
    let cancel: Canceler;
    if (!gettingNewsData) {
      const params = {
        page,
        limit,
        sortBy,
        sortDir,
        category,
        date_start,
        date_end,
        title,
        situation: '-1',
        author_id,
      };
      setGettingNewsData(true);
      api
        .get('intranet/news', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setNewsData(response.data);
          setGettingNewsData(false);
          setLoaddingInitialData(false);
        })
        .catch((error: any) => {
          console.error(error);
          setGettingNewsData(false);
          setLoaddingInitialData(false);
        });
    }
    return () => cancel();
  };

  useEffect(() => {
    getCategorys();
    getNewsData({});
  }, []);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > newsData.pageTotal) return;
    setCurrentPage(page);
    getNewsData({ page });
  };

  const handleApplyFilter = (e: any[]) => {
    localStorage.setItem('search-news-published-limit', e[0]);
    localStorage.setItem('search-news-published-order-sort', e[1]);
    localStorage.setItem('search-news-published-date-start', e[2]);
    localStorage.setItem('search-news-published-date-end', e[3]);
    localStorage.setItem('search-news-published-title', e[4]);
    localStorage.setItem('search-news-published-category', e[5]);
    getNewsData({
      page: 1,
      sortBy: 'date',
      limit: Number(e[0]),
      sortDir: e[1],
      date_start: e[2],
      date_end: e[3],
      title: e[4],
      category: Number(e[5]),
    });
    setInputsValue(e);
  };

  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem(`search-news-published-order-by`, index);
    localStorage.setItem(`search-news-published-order-dir`, isAsc ? 'desc' : 'asc');
    getNewsData({ page: currentPage, sortBy: index, sortDir: isAsc ? 'desc' : 'asc' });
  };

  const RenderResult = (propsRender: {
    newsRender: ResponseNewsData;
    loaddingDataRender: boolean;
    currentPageRender: number;
    dataLimitRender: number;
    columnsRender: HeaderColumnProps[];
  }) => {
    const { newsRender, loaddingDataRender, currentPageRender, dataLimitRender, columnsRender } = propsRender;
    const newDataLimit = dataLimit < 1 ? 30 : dataLimit;
    const emptyRows = newsRender.data?.length > 0 ? newDataLimit - newsRender.data?.length : newDataLimit - 1;
    const rowsSkeleton = [] as number[];

    for (let i = 0; i < newDataLimit; i++) {
      rowsSkeleton.push(i);
    }

    return (
      <Box mt={2}>
        <Paper className={classes.tablePaper}>
          <Box p={1}>
            {!loaddingDataRender && newsRender.data?.length === 0 ? (
              <Box
                className={classes.tableContainer}
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Não foi encontrado nenhum Resultado.{' '}
                  <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente novamente.
                </Typography>
              </Box>
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead>
                    <TableRow>
                      {columnsRender?.map((column: HeaderColumnProps, columnsIndex) => {
                        const columnsMapKey = columnsIndex;
                        return (
                          <TableCell
                            key={columnsMapKey}
                            style={{ minWidth: column.width, width: column.width, maxWidth: column.width }}
                            colSpan={column.colSpan}
                            align={column.aling}
                            sortDirection={orderBy === column.fieldName ? orderDir : false}
                          >
                            <Tooltip title={column.tooltipTitle} aria-label={`orderby-${column.fieldName}`}>
                              {column.sort ? (
                                <TableSortLabel
                                  active={orderBy === column.fieldName}
                                  direction={orderBy === column.fieldName ? orderDir : 'asc'}
                                  onClick={() => {
                                    handleSort(column.fieldName);
                                  }}
                                >
                                  {column.text}
                                  {orderBy === column.fieldName ? (
                                    <span className={classes.tableVisuallyHidden}>
                                      {orderDir === 'desc' ? 'desc' : 'asc'}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                <Typography style={{ fontSize: 14 }}>{column.text}</Typography>
                              )}
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaddingDataRender
                      ? rowsSkeleton?.map((row: any, rowsSkeletonIndex: any) => {
                          const rowsSkeletonMapKey = rowsSkeletonIndex;
                          return (
                            <TableRow key={rowsSkeletonMapKey}>
                              {columnsRender?.map((column: RowColumnProps, columnsIndex) => {
                                const columnsMapKey = `column_${columnsIndex}`;

                                return (
                                  <TableCell
                                    key={columnsMapKey}
                                    style={{ padding: 4 }}
                                    colSpan={column.colSpan}
                                    width={column.width}
                                    height={37}
                                    align={column.aling}
                                  >
                                    <Skeleton variant="text" />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : newsRender.data?.map((_news: NewsData, rowIndex) => {
                          const rowId = `row-${rowIndex}`;
                          return (
                            <TableRow hover tabIndex={-1} key={rowId}>
                              {columnsRender?.map((column: HeaderColumnProps, columnIndex) => {
                                const columnId = columnIndex;
                                return (
                                  <TableCell
                                    key={`${columnId}-${column.fieldName}`}
                                    colSpan={column.colSpan}
                                    align={column.aling}
                                    style={{
                                      minWidth: column.width,
                                      width: column.width,
                                      maxWidth: column.width,
                                    }}
                                  >
                                    {column.fieldName === 'open_news' && (
                                      <Tooltip title={`Abrir Notícia ${_news.id}`} aria-label="view">
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          component={Link}
                                          to={`/news/${_news.id}`}
                                          style={{
                                            margin: 2,
                                            marginLeft: 6,
                                            padding: 2,
                                            minWidth: 32,
                                            maxWidth: 32,
                                          }}
                                          size="large"
                                          startIcon={
                                            <Icon
                                              fontSize="small"
                                              style={{
                                                padding: 0,
                                                marginLeft: 12,
                                              }}
                                            >
                                              open_in_new
                                            </Icon>
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                    {column.fieldName === 'id' && <> {_news.id}</>}
                                    {column.fieldName === 'situation' && (
                                      <Box style={{ marginLeft: 12 }}>
                                        {_news.situation === 0 && (
                                          <VisibilityOffIcon className={classes.offTimeSituationColor} />
                                        )}
                                        {_news.situation === 1 && (
                                          <VisibilityIcon className={classes.onTimeSituationColor} />
                                        )}
                                      </Box>
                                    )}
                                    {column.fieldName === 'author_name' && <>{_news.author_name}</>}
                                    {column.fieldName === 'news_title' && <>{_news.news_title}</>}
                                    {column.fieldName === 'created_at' && <>{_news.created_at_formated}</>}
                                    {column.fieldName === 'updated_at' && <>{_news.updated_at_formated}</>}
                                    {column.fieldName === 'views_count' && <>{_news.views_count}</>}
                                    {column.fieldName === 'likes' && <>{_news.likes === 1 ? 'Ativo' : 'Inativo'}</>}
                                    {column.fieldName === 'comments' && (
                                      <>{_news.comments === 1 ? 'Ativo' : 'Inativo'}</>
                                    )}
                                    {column.fieldName === 'category' && <>{_news.category_description}</>}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    {!loaddingDataRender && emptyRows > 0 && (
                      <TableRow style={{ height: 37 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Paper>

        <div className={classes.actions}>
          <Typography
            variant="caption"
            style={{ marginBottom: 4 }}
          >{`${newsData.total} Registros encontrados`}</Typography>

          <Pagination
            size="medium"
            count={newsData.pageTotal}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            disabled={loaddingDataRender || newsData.pageTotal <= 1}
            onChange={handlePagination}
          />
        </div>
      </Box>
    );
  };

  const filtersInputs = [
    {
      type: 'combobox',
      id: 'news-published-limit',
      label: 'Limite',
      defaultValue: 10,
      comboBoxItems: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    },
    {
      type: 'date',
      id: 'news-published-date-start',
      label: 'Data Início',
      defaultValue: '',
    },
    {
      type: 'date',
      id: 'news-published-date-end',
      label: 'Data Fim',
      defaultValue: '',
    },
    {
      type: 'text',
      id: 'news-published-title',
      label: 'Título',
      defaultValue: '',
    },
    {
      type: 'combobox',
      id: 'news-published-category',
      label: 'Categoria',
      defaultValue: 0,
      comboBoxItems: [
        { value: 0, text: 'Todas' },
        ...categorysData?.map((category: any) => ({ value: category.id, text: category.description })),
      ],
    },
  ] as FiltersInputsProps[];

  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      {!loaddingInitialData ? (
        <Box className={classes.root}>
          <Grid container className={classes.contentRoot}>
            <Grid item xs={12}>
              <RenderResult
                newsRender={newsData}
                loaddingDataRender={gettingNewsData}
                currentPageRender={currentPage}
                dataLimitRender={dataLimit}
                columnsRender={columns}
              />
            </Grid>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
              <Alert severity={snackbarType} onClose={handleSnackbarClose}>
                {snackbarMsg}
              </Alert>
            </Snackbar>
          </Grid>
        </Box>
      ) : (
        <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando notícias...
          </Typography>
        </Backdrop>
      )}
    </Box>
  );
}
