import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';
import Axios, { Canceler } from 'axios';
import styles from './styles';
import api from '../../../../services/api';

type OrderDashboardDataType = {
  new_orders: NewOrders[];
  out_date_orders: OutDateOrders[];
};
type NewOrders = {
  id: number;
  client_id: number;
  client_name: string;
  created_at: string;
  deadline_date: string;
  diff_days: number;
  total_items: number;
};
type OutDateOrders = {
  id: number;
  client_id: number;
  client_name: string;
  created_at: string;
  deadline_date: string;
  diff_days: number;
  total_items: number;
};
export default function AdminUsersAddPage() {
  const classes = styles();
  const [loadingData, setLoadingData] = useState(false);
  const [dashboardData, setDashboardData] = useState<OrderDashboardDataType>();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    if (loadingData) return;
    let cancel: Canceler;
    try {
      setLoadingData(true);
      const response = await api.get('/erp/orders/dashboard', {
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      setDashboardData(response.data);
    } catch (error: any) {}
    setLoadingData(false);
    return () => cancel();
  }
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="baseline" style={{ gap: 12 }} p={1.5}>
              <Tooltip title="Pesquisar Pedidos" aria-label="view-orders">
                <Button color="primary" variant="contained" component={Link} to="/erp/orders/search">
                  PESQUISAR
                </Button>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.tableTitle}>ÚLTIMOS PEDIDOS GERADOS</Typography>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">
                      <Typography variant="caption" color="textSecondary">
                        Nº
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption" color="textSecondary">
                        CLIENTE
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="caption" color="textSecondary">
                        DATA
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        ITENS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="caption" className={classes.loadingInitialDataText}>
                          Carregando...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : dashboardData?.new_orders?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="caption">Não foi encontrado nenhum novo pedido</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    dashboardData?.new_orders?.map(order => {
                      const rowId = `hd-row-${order.id}`;
                      return (
                        <TableRow hover tabIndex={-1} key={rowId}>
                          <TableCell width={16} padding="none" align="center">
                            <Tooltip title="Visualizar" aria-label="view">
                              <IconButton component={Link} to={`/erp/orders/${order.id}`} size="large">
                                <Icon>open_in_new</Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell width={18} align="left">
                            {order.id}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: 200 }}>
                            {order.client_id} - {order.client_name}
                          </TableCell>

                          <TableCell width={150} align="center">
                            <Box>
                              <Tooltip title="Data Geração">
                                <Typography className={`${classes.createdAtText} ${classes.blueColor}`}>
                                  {order.created_at}
                                </Typography>
                              </Tooltip>
                              <Tooltip title="Data Prevista">
                                <Typography className={`${classes.deadlineDateText} `}>
                                  {order.deadline_date}
                                </Typography>
                              </Tooltip>
                              <Tooltip title="Diferença em dias (Data Prevista - Data Atual)">
                                <Typography
                                  className={`${classes.diffDaysText} ${
                                    order.diff_days > 15
                                      ? classes.greenColor
                                      : order.diff_days < 0
                                      ? classes.redColor
                                      : classes.yellowColor
                                  }`}
                                >
                                  {`${Math.abs(order.diff_days)} dias ${
                                    order.diff_days < 0 ? 'atrasado(s)' : 'restante(s)'
                                  }`}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell align="right">{order.total_items}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.tableTitle}>PEDIDOS ATRASADOS</Typography>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">
                      <Typography variant="caption" color="textSecondary">
                        Nº
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption" color="textSecondary">
                        CLIENTE
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="caption" color="textSecondary">
                        PREVISÃO
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        ITENS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="caption" className={classes.loadingInitialDataText}>
                          Carregando...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : dashboardData?.out_date_orders?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="caption">Não foi encontrado nenhum pedido atrasado.</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    dashboardData?.out_date_orders?.map(order => {
                      const rowId = `hd-row-${order.id}`;
                      return (
                        <TableRow hover tabIndex={-1} key={rowId}>
                          <TableCell width={16} padding="none" align="center">
                            <Tooltip title="Visualizar" aria-label="view">
                              <IconButton component={Link} to={`/erp/orders/${order.id}`} size="large">
                                <Icon>open_in_new</Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell width={18} align="left">
                            {order.id}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: 200 }}>
                            {order.client_id} - {order.client_name}
                          </TableCell>

                          <TableCell width={150} align="center">
                            <Box>
                              <Tooltip title="Data Prevista">
                                <Typography className={`${classes.deadlineDateText} `}>
                                  {order.deadline_date}
                                </Typography>
                              </Tooltip>
                              <Tooltip title="Diferença em dias (Data Prevista - Data Atual)">
                                <Typography
                                  className={`${classes.diffDaysText} ${
                                    order.diff_days > 15
                                      ? classes.greenColor
                                      : order.diff_days < 0
                                      ? classes.redColor
                                      : classes.yellowColor
                                  }`}
                                >
                                  {`${Math.abs(order.diff_days)} dias ${
                                    order.diff_days < 0 ? 'atrasado(s)' : 'restante(s)'
                                  }`}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell align="right">{order.total_items}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
