import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  Badge,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import { format } from 'date-fns';
import InputSearchAsync, { ValueProps } from '../../../../../components/inputs/InputSearchAsync';
import { toDate } from '../../../../../utils/functions';

type FiltersType = {
  os?: ValueProps[];
  budgets?: ValueProps[];
  customers?: ValueProps[];
  helpdesks?: ValueProps[];
  products?: ValueProps[];
  orders?: ValueProps[];
  attendants?: ValueProps[];
  situation?: ValueProps[];
  rework?: string;
  tasks?: ValueProps[];
  created_by?: ValueProps[];
  created_at_start?: string;
  created_at_end?: string;
  started_by?: ValueProps[];
  started_at_start?: string;
  started_at_end?: string;
  ended_by?: ValueProps[];
  ended_at_start?: string;
  ended_at_end?: string;
};

type FilterListType = {
  os?: boolean;
  budgets?: boolean;
  customers?: boolean;
  helpdesks?: boolean;
  products?: boolean;
  orders?: boolean;
  attendants?: boolean;
  situation?: boolean;
  tasks?: boolean;
  created_by?: boolean;
  created_at?: boolean;
  started_by?: boolean;
  started_at?: boolean;
  ended_by?: boolean;
  ended_at?: boolean;
  rework?: boolean;
};

interface EngOsHomeTitleProps {
  title?: string;
  filterList?: FilterListType;
  initialFilter?: FiltersType;
  filterButton?: boolean;
  refressButton?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (filters: FiltersType) => void;
}

function EngOsHomeTitle({
  title,
  filterButton = true,
  refressButton = true,
  filterList,
  onChange,
  initialFilter,
}: EngOsHomeTitleProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const newFilterList = {
    ...({
      attendants: true,
      budgets: true,
      products: true,
      customers: true,
      helpdesks: true,
      orders: true,
      situation: true,
      rework: true,
      created_at: true,
      started_at: true,
      ended_at: true,
      created_by: true,
      ended_by: true,
      started_by: true,
      os: true,
      tasks: true,
    } as FilterListType),
    ...filterList,
  };
  const [openFilter, setOpenFilter] = useState(false);

  const [filters, setFilters] = useState<FiltersType>({
    os: [],
    budgets: [],
    customers: [],
    products: [],
    helpdesks: [],
    orders: [],
    attendants: [],
    situation: [],
    rework: '',
    tasks: [],
    created_at_start: '',
    created_at_end: '',
    started_at_start: '',
    started_at_end: '',
    created_by: [],
    ended_by: [],
    started_by: [],
    ended_at_start: '',
    ended_at_end: '',
  });

  const handleRefress = () => {
    setFilters(old => ({ ...old }));
  };

  const inputSearchEngOs = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="eng-os"
        id="os_id"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, os: e }));
        }}
        value={filters.os}
        label="O.S. (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.os],
  );

  const inputSearchBudget = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-budgets"
        id="budget_number"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, budgets: e }));
        }}
        value={filters.budgets}
        label="Orçamento (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.budgets],
  );

  const inputSearchProduct = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-products"
        id="products_id"
        searchParams={{ type: 'P' }}
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, products: e }));
        }}
        value={filters.products}
        label="Produto (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.products],
  );

  const inputSearchOrder = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-orders"
        id="budget_number"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, orders: e }));
        }}
        value={filters.orders}
        label="Pedido (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.orders],
  );

  const inputSearchCustomers = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="erp-customers"
        id="budget_number"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, customers: e }));
        }}
        value={filters.customers}
        label="Cliente (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.customers],
  );
  const inputSearchHelpdesks = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-id"
        id="helpdesk_id"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, helpdesks: e }));
        }}
        searchParams={{ target: 5 }}
        value={filters.helpdesks}
        label="Helpdesk (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.helpdesks],
  );
  const inpuSearchSituation = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="custom"
        id="situation"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, situation: e }));
        }}
        value={filters.situation}
        label="Situação (+)"
        customList={[
          { id: 'canceled', description: 'Cancelado' },
          { id: 'canceled_late', description: 'Cancelado (Atraso)' },
          { id: 'ended', description: 'Finalizado' },
          { id: 'ended_late', description: 'Finalizado (Atraso)' },
          { id: 'not_started', description: 'Não Iniciado' },
          { id: 'not_started_late', description: 'Não Iniciado (Atraso)' },
          { id: 'paused', description: 'Parado' },
          { id: 'paused_late', description: 'Parado (Atraso)' },
          { id: 'started', description: 'Andamento' },
          { id: 'started_late', description: 'Andamento (Atraso)' },
        ].sort((a, b) => {
          return a.description > b.description ? 1 : a.description === b.description ? 0 : -1;
        })}
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.situation],
  );

  const inpuSearchRework = useMemo(
    () => (
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="rework-label">Retrabalho</InputLabel>
        <Select
          fullWidth
          id="rework"
          onChange={e => {
            setFilters(old => ({ ...old, rework: e.target.value }));
          }}
          value={filters.rework}
          label="Retrabalho"
          startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
          sx={{ minWidth: 240, flex: 1, maxHeight: 48 }}
          defaultValue="-1"
        >
          {[
            { text: 'Todos', value: '-1' },
            { text: 'Não', value: '0' },
            { text: 'Sim', value: '1' },
          ].map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [filters.rework],
  );

  const inputSearchAttendances = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        searchParams={{ sectores: '233;142;145;231;232' }}
        id="attendance"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, attendants: e }));
        }}
        value={filters.attendants}
        label="Atendente (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.attendants],
  );

  const inputSearchCreatedBy = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        searchParams={{ sectores: '233;142;145;231;232' }}
        id="created_by"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, created_by: e }));
        }}
        value={filters.created_by}
        label="Criado por (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.created_by],
  );

  const inputSearchEndedBy = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        searchParams={{ sectores: '233;142;145;231;232' }}
        id="ended_by"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, ended_by: e }));
        }}
        value={filters.ended_by}
        label="Finalizado por (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.ended_by],
  );

  const inputSearchStarteddBy = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        searchParams={{ sectores: '233;142;145;231;232' }}
        id="started_by"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, started_by: e }));
        }}
        value={filters.started_by}
        label="Iníciado por (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.started_by],
  );

  const inputSearchTasks = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="eng-os-tasks"
        id="tasks"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, tasks: e }));
        }}
        value={filters.tasks}
        label="Tarefas (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.tasks],
  );

  const inputSearchCreatedAt = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, maxWidth: 420 }}>
        <TextField
          fullWidth
          label="Data Criação (de)"
          id="created_at_start"
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const created_at_start = format(toDate(e.target.value), 'yyyy-MM-dd');
            setFilters(old => ({ ...old, created_at_start }));
          }}
          value={filters.created_at_start}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
        <TextField
          fullWidth
          label="Data Criação (até)"
          id="created_at_end"
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const created_at_end = format(toDate(e.target.value), 'yyyy-MM-dd');
            setFilters(old => ({ ...old, created_at_end }));
          }}
          value={filters.created_at_end}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
      </Box>
    ),
    [filters.created_at_start, filters.created_at_end],
  );

  const inputSearchStartedAt = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, maxWidth: 420 }}>
        <TextField
          fullWidth
          label="Data Início (de)"
          id="started_at_start"
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const started_at_start = format(toDate(e.target.value), 'yyyy-MM-dd');
            setFilters(old => ({ ...old, started_at_start }));
          }}
          value={filters.started_at_start}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
        <TextField
          fullWidth
          label="Data Início (até)"
          id="started_at_end"
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const started_at_end = format(toDate(e.target.value), 'yyyy-MM-dd');
            setFilters(old => ({ ...old, started_at_end }));
          }}
          value={filters.started_at_end}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
      </Box>
    ),
    [filters.started_at_start, filters.started_at_end],
  );

  const inputSearchEndedAt = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, maxWidth: 420 }}>
        <TextField
          fullWidth
          label="Data Finalização (de)"
          id="ended_at_start"
          type="date"
          // onChange={handleFieldChange}
          // value={formState.expected_start_date}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
          // error={errorState.start_date_error?.length > 0}
          // helperText={errorState.start_date_error}
        />
        <TextField
          fullWidth
          label="Data Finalização (até)"
          id="ended_at_end"
          type="date"
          // onChange={handleFieldChange}
          // value={formState.expected_start_date}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
          // error={errorState.start_date_error?.length > 0}
          // helperText={errorState.start_date_error}
        />
      </Box>
    ),
    [],
  );
  const filtersCounter = () => {
    let count = 0;

    Object.entries(filters).map(f => {
      count += Array.isArray(f[1]) ? f[1]?.length : f[1]?.length > 0 ? 1 : 0;
      return f;
    });

    return count;
  };

  useEffect(() => {
    onChange?.(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFilters(old => ({ ...old, ...(initialFilter ?? {}) }));
  }, [initialFilter]);

  return (
    <Paper>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        disableGutters
        expanded={openFilter}
        onChange={(e, expanded) => {
          setOpenFilter(expanded);
        }}
      >
        <Box
          sx={{
            p: 1.5,
            display: 'flex',
            gap: 1,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 64,
          }}
        >
          {filterButton ? (
            <Tooltip title="Filtro">
              <IconButton aria-label="filter" size="medium" onClick={() => setOpenFilter(!openFilter)}>
                <Badge badgeContent={filtersCounter()} color="error">
                  <FilterAltOutlinedIcon fontSize="inherit" />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
          <Typography fontSize="1.3rem" sx={{ color: isDarkMode ? '#c1c1c1' : '#767676' }}>
            {title ?? ''}
          </Typography>
          {refressButton ? (
            <Tooltip title="Atualizar dados">
              <IconButton aria-label="refress" size="medium" onClick={handleRefress}>
                <RefreshIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
        </Box>

        <AccordionDetails>
          <Box
            sx={{
              bgcolor: isDarkMode ? '#404040' : '#fff',
              p: 2,
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              flexWrap: 'wrap',
              overflow: 'hidden',
              borderRadius: 1,
              borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <Typography sx={{ m: 1, color: isDarkMode ? '#c1c1c1' : '#767676' }}>Filtros</Typography>

            {newFilterList?.os && inputSearchEngOs}
            {newFilterList?.situation && inpuSearchSituation}
            {newFilterList?.rework && inpuSearchRework}
            {newFilterList?.budgets && inputSearchBudget}
            {newFilterList?.orders && inputSearchOrder}
            {newFilterList?.helpdesks && inputSearchHelpdesks}
            {newFilterList?.products && inputSearchProduct}
            {newFilterList?.customers && inputSearchCustomers}
            {newFilterList?.attendants && inputSearchAttendances}
            {newFilterList?.tasks && inputSearchTasks}
            {newFilterList?.started_at && inputSearchStartedAt}
            {newFilterList?.ended_at && inputSearchEndedAt}
            {newFilterList?.created_at && inputSearchCreatedAt}
            {newFilterList?.started_by && inputSearchStarteddBy}
            {newFilterList?.ended_by && inputSearchEndedBy}
            {newFilterList?.created_by && inputSearchCreatedBy}

            {/* {inputSearchCreatedAt} */}
            {/* {inputSearchEndedAt} */}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
export { EngOsHomeTitle };
export type { FiltersType };
