import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import { grey } from '@mui/material/colors';
import InputSearchAsync, { ValueProps } from '../../../../../components/inputs/InputSearchAsync';
import api from '../../../../../services/api';
import { ColumnType, DataTable, RowType } from '../../../../../components/DataTable';

type FilterType = {
  request_id?: ValueProps[];
  product_id?: ValueProps[];
  derivation_id?: ValueProps[];
  request_situation_id: [];
  ccu_id?: ValueProps[];
  user_request_id?: ValueProps[];
  created_at?: ValueProps[];
  user_approval_id?: ValueProps[];
  approval_at?: ValueProps[];
  user_attended_id?: ValueProps[];
  attended_at?: ValueProps[];
  user_cancelled_id?: ValueProps[];
  cancelled_at?: ValueProps[];
};

type ResponseData = {
  search: {
    limit: number;
    page: number;
    total_data: number;
    total_pages: number;
  };
  data: RequestType[];
};

type RequestType = {
  request_id: number;
  request_seq: number;
  product_id: string;
  product_name: string;
  derivation_id: string;
  derivation_name: string;
  um_id: string;
  qtd_resquest: number;
  qtd_approved: number;
  qtd_met: number;
  qtd_canceled: number;
  request_situation_id: number;
  ccu_id: string;
  ccu_description: string;
  request_situation_des: string;
  user_request_id_erp: string;
  user_request_id: number;
  user_request_nickname: string;
  created_at: string;
  created_at_formated: string;
  user_approval_id_erp: string;
  user_approval_id: number;
  user_approval_nickname: string;
  approval_at: string;
  approval_at_formated: string;
  user_attended_id_erp: string;
  user_attended_id: number;
  user_attended_nickname: string;
  attended_at: string;
  attended_at_formated: string;
  user_cancelled_id_erp: string;
  user_cancelled_id: number;
  user_cancelled_nickname: string;
  cancelled_at: string;
  cancelled_at_formated: string;
};

export default function RequestsSearch() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [openFilter, setOpenFilter] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);
  const [sort, setSort] = useState({ by: 'order_number', dir: 'asc' });
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    request_id: [],
    product_id: [],
    derivation_id: [],
    request_situation_id: [],
    ccu_id: [],
    user_request_id: [],
    created_at: [],
    user_approval_id: [],
    approval_at: [],
    user_attended_id: [],
    attended_at: [],
    user_cancelled_id: [],
    cancelled_at: [],
  } as FilterType);

  const handleRefress = () => {
    setFilters(old => ({ ...old }));
  };
  const handleSort = (by: string, dir: string) => {
    setSort({ by, dir });
  };

  const handlePage = (event: any, current_page: number) => {
    if (current_page < 1 || current_page > responseData?.search?.total_pages) return;
    setPage(current_page);
  };

  const filtersCounter = () => {
    let count = 0;

    Object.entries(filters).map(f => {
      const lenght = f?.[1]?.length;
      count += Array.isArray(f?.[1]) ? f?.[1]?.length : lenght > 0 ? 1 : 0;
      return f;
    });

    return count;
  };

  const inputSearchCreatedBy = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="intranet-users"
        id="request_id"
        onChange={(e: ValueProps[]) => {
          setFilters(old => ({ ...old, request_id: e }));
        }}
        value={filters.request_id}
        label="Requisitado por (+)"
        startAdornment={<InputAdornment position="start" sx={{ minHeight: 36 }} />}
        sx={{ minWidth: 240, flex: 1 }}
      />
    ),
    [filters.request_id],
  );

  const renderFilters = useMemo(
    () => (
      <Paper>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          disableGutters
          expanded={openFilter}
          onChange={(e, expanded) => {
            setOpenFilter(expanded);
          }}
        >
          <Box
            sx={{
              p: 1.5,
              display: 'flex',
              gap: 1,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              minHeight: 64,
            }}
          >
            <Tooltip title="Filtro">
              <IconButton aria-label="filter" size="medium" onClick={() => setOpenFilter(!openFilter)}>
                <Badge badgeContent={filtersCounter()} color="error">
                  <FilterAltOutlinedIcon fontSize="inherit" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Typography fontSize="1.3rem" sx={{ color: isDarkMode ? '#c1c1c1' : '#767676' }}>
              Requisições - Pesquisar
            </Typography>

            <Tooltip title="Atualizar dados">
              <IconButton aria-label="refress" size="medium" onClick={handleRefress}>
                <RefreshIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                bgcolor: isDarkMode ? '#404040' : '#fff',
                p: 2,
                pt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                flexWrap: 'wrap',
                overflow: 'hidden',
                borderRadius: 1,
                borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
                borderStyle: 'solid',
                borderWidth: 1,
              }}
            >
              <Typography sx={{ m: 1, color: isDarkMode ? '#c1c1c1' : '#767676' }}>Filtros</Typography>
              {inputSearchCreatedBy}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, openFilter],
  );

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>('erp/stock/requisition', {
        params: {
          ...filters,
          page,
        },
      });
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };

  const columnSize = (size: number | string) => ({ minWidth: size, width: size, maxWidth: size } as CSSProperties);

  const columns: ColumnType[] = [
    { id: 'request_id', content: 'request_id', align: 'right' },
    { id: 'request_seq', content: 'request_seq', align: 'right' },
    { id: 'product_id', content: 'product_id', align: 'right' },
    { id: 'product_name', content: 'product_name', align: 'left' },
    { id: 'derivation_id', content: 'derivation_id', align: 'right' },
    { id: 'derivation_name', content: 'derivation_name', align: 'left' },
    { id: 'um_id', content: 'um_id', align: 'center' },
    { id: 'qtd_resquest', content: 'qtd_resquest', align: 'right' },
    { id: 'qtd_approved', content: 'qtd_approved', align: 'right' },
    { id: 'qtd_met', content: 'qtd_met', align: 'right' },
    { id: 'qtd_canceled', content: 'qtd_canceled', align: 'right' },
    { id: 'request_situation_id', content: 'request_situation_id', align: 'right' },
    { id: 'ccu_id', content: 'ccu_id', align: 'right' },
    { id: 'ccu_description', content: 'ccu_description', align: 'right' },
    { id: 'user_request_id_erp', content: 'user_request_id_erp', align: 'right' },
    { id: 'user_request_id', content: 'user_request_id', align: 'right' },
    { id: 'user_request_nickname', content: 'user_request_nickname', align: 'right' },
    { id: 'created_at', content: 'created_at', align: 'right' },
    { id: 'user_approval_id_erp', content: 'user_approval_id_erp', align: 'right' },
    { id: 'user_approval_id', content: 'user_approval_id', align: 'right' },
    { id: 'user_approval_nickname', content: 'user_approval_nickname', align: 'right' },
    { id: 'approval_at', content: 'approval_at', align: 'right' },
    { id: 'user_attended_id_erp', content: 'user_attended_id_erp', align: 'right' },
    { id: 'user_attended_id', content: 'user_attended_id', align: 'right' },
    { id: 'user_attended_nickname', content: 'user_attended_nickname', align: 'right' },
    { id: 'attended_at', content: 'attended_at', align: 'right' },
    { id: 'user_cancelled_id_erp', content: 'user_cancelled_id_erp', align: 'right' },
    { id: 'user_cancelled_id', content: 'user_cancelled_id', align: 'right' },
    { id: 'user_cancelled_nickname', content: 'user_cancelled_nickname', align: 'right' },
    { id: 'cancelled_at', content: 'cancelled_at', align: 'right' },
  ];

  const rows: RowType[] = responseData?.data?.map(req => {
    return {
      cells: [
        { content: `${req.request_id ?? '-'}`, align: 'right' },
        { content: `${req.request_seq ?? '-'}`, align: 'right' },
        { content: `${req.product_id ?? '-'}`, align: 'right' },
        { content: `${req.product_name ?? '-'}`, align: 'left' },
        { content: `${req.derivation_id ?? '-'}`, align: 'right' },
        { content: `${req.derivation_name ?? '-'}`, align: 'left' },
        { content: `${req.um_id ?? '-'}`, align: 'center' },
        { content: `${req.qtd_resquest ?? '-'}`, align: 'right' },
        { content: `${req.qtd_approved ?? '-'}`, align: 'right' },
        { content: `${req.qtd_met ?? '-'}`, align: 'right' },
        { content: `${req.qtd_canceled ?? '-'}`, align: 'right' },
        { content: `${req.request_situation_des ?? '-'}`, align: 'center' },
        { content: `${req.ccu_id ?? '-'}`, align: 'right' },
        { content: `${req.ccu_description ?? '-'}`, align: 'left' },
        { content: `${req.user_request_id_erp ?? '-'}`, align: 'right' },
        { content: `${req.user_request_id ?? '-'}`, align: 'right' },
        { content: `${req.user_request_nickname ?? '-'}`, align: 'left' },
        { content: `${req.created_at_formated ?? '-'}`, align: 'center' },
        { content: `${req.user_approval_id_erp ?? '-'}`, align: 'right' },
        { content: `${req.user_approval_id ?? '-'}`, align: 'right' },
        { content: `${req.user_approval_nickname ?? '-'}`, align: 'left' },
        { content: `${req.approval_at_formated ?? '-'}`, align: 'center' },
        { content: `${req.user_attended_id_erp ?? '-'}`, align: 'right' },
        { content: `${req.user_attended_id ?? '-'}`, align: 'right' },
        { content: `${req.user_attended_nickname ?? '-'}`, align: 'left' },
        { content: `${req.attended_at_formated ?? '-'}`, align: 'center' },
        { content: `${req.user_cancelled_id_erp ?? '-'}`, align: 'right' },
        { content: `${req.user_cancelled_id ?? '-'}`, align: 'right' },
        { content: `${req.user_cancelled_nickname ?? '-'}`, align: 'left' },
        { content: `${req.cancelled_at_formated ?? '-'}`, align: 'center' },
      ],
    } as RowType;
  });

  const renderTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        sx={{ width: '100%', overflow: 'auto' }}
        loading={fetching}
        variant="primary"
        minHeight={96}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );

  const renderSearchResult = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        {renderTable}
        <Box
          display="flex"
          width="100%"
          sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography variant="caption" style={{ marginBottom: 4 }}>
            {`${responseData?.search?.total_data ?? 0}`}
            {responseData?.search?.total_data > 1 ? 'Registros encontrados ' : 'Registro encontrado '}
          </Typography>

          <Pagination
            size="medium"
            count={responseData?.search?.total_pages ?? 0}
            page={responseData?.search?.page ?? 0}
            variant="outlined"
            shape="rounded"
            disabled={fetching || responseData?.search?.total_pages === 1}
            onChange={handlePage}
          />
          <Typography
            variant="caption"
            sx={{
              fontSize: 9,
              marginTop: 1,
              color: isDarkMode ? grey[400] : grey[700],
            }}
          />
        </Box>
      </Box>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetching],
  );

  useEffect(() => {
    getOsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page]);

  return (
    <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Gerar Requesição" aria-label="new-request">
          <Button color="warning" variant="outlined" component={Link} to="/erp/stock/request">
            Dashboard
          </Button>
        </Tooltip>
        <Tooltip title="Gerar Requesição" aria-label="new-request">
          <Button color="success" variant="outlined" component={Link} to="/erp/stock/request/add">
            GERAR
          </Button>
        </Tooltip>
      </Box>

      {renderFilters}

      <Paper sx={{ display: 'flex', gap: 2, p: 2, flex: 1, flexDirection: 'column' }}>{renderSearchResult}</Paper>
    </Box>
  );
}
