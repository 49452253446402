import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import moment from 'moment';

import {
  Drawer,
  DrawerProps,
  Tooltip,
  Typography,
  Box,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Backdrop,
} from '@mui/material';

// ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Pagination } from '@mui/lab';
import Axios, { Canceler } from 'axios';
import IMICLogoImg from '../../images/logo-icon.png';
import api from '../../services/api';

import styles from './styles';
import ProfileTooltip from '../ProfileTooltip';
import { avatarUrl } from '../../utils/functions';

moment.locale('pt-br');
moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });

interface NotificationsProps extends DrawerProps {
  show: boolean;
  onUpdate?: (event?: { count: number }) => void;
  onClose?: () => void;
}
interface IntranetResponseData {
  data_total: number;
  data_limit: number;
  total_page: number;
  current_page: number;
  order_dir: string;
  order_by: string;
  unread_count: number;
  data: IntranetMessages[];
}
interface IntranetMessages {
  id: number;
  type: number;
  ref: string;
  from_id: number;
  from_name: string;
  to_id: number;
  title: string;
  text: string;
  created_at: string;
  readed_at: string;
  situation: number;
  notified: number;
  created_at_formated: string;
  readed_at_formated: string;
  deleted_at_formated: string;
}
let curPage = 1;
export default function Notifications(props: NotificationsProps) {
  const { show, onUpdate = () => {}, onClose = () => {} } = props;
  const navigate = useNavigate();
  const classes = styles();
  const [notifications, setNotifications] = useState<IntranetResponseData>({} as IntranetResponseData);
  const [gettingNotificationData, setGettingNotificationData] = useState<boolean>(false);
  const [backgroundUpdating, setBackgroundUpdating] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  /*
  const observer = useRef<IntersectionObserver>();

  const lastNotificationElement = useCallback<any>(
    (node: HTMLDivElement) => {
      if (loading) return;
      observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (hasMore && entries[0].isIntersecting) {
          setCurrentPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );
*/
  const [readingNotification, setReadingNotification] = useState<number | false>(0);
  const [deletingNotification, setDeletingNotification] = useState<number | false>(0);

  const getNotifications = ({ current_page = 1 }) => {
    let cancel: Canceler;
    if (!gettingNotificationData) {
      setGettingNotificationData(true);
      setCurrentPage(current_page);
      curPage = current_page;
      api
        .get(`intranet/message`, {
          params: {
            type: 1,
            from_id: 0,
            data_limit: 25,
            current_page,
          },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          const data = response?.data as IntranetResponseData | {} as IntranetResponseData;
          setNotifications(data);
          onUpdate({ count: data.unread_count });
          setGettingNotificationData(false);
          setBackgroundUpdating(false);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;
          if (!msg) msg = error?.response?.data;

          if (msg === 'token_invalid_password' || msg === '') {
            navigate('/authenticate');
          }

          setGettingNotificationData(false);
          setBackgroundUpdating(false);
        });
    } else {
      setBackgroundUpdating(false);
    }
    return () => cancel();
  };

  const ShowBrowserNewNotifications = () => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else {
      notifications.data?.forEach((notification: IntranetMessages) => {
        if (notification.notified === 0) {
          postNotifiedNotification(notification.id);
          notification.notified = 1;
          const options = {
            body: notification.text,
            icon: `${window.location.protocol}//${window.location.host}${IMICLogoImg}`,
            dir: 'ltr',
            renotify: false,
          } as NotificationOptions;

          const browserNotification = new Notification(notification.title, options);

          browserNotification.onclick = function (event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(notification.ref !== null ? `/${notification.ref}` : '/', '_blank');
          };
        }
      });
    }
  };

  async function postReadNotification(id: number) {
    if (readingNotification !== id) {
      setReadingNotification(id);
      notifications.data.filter(notification => notification.id === id)[0].readed_at = '00/00/0000 00:00';
      notifications.unread_count--;
      onUpdate({ count: notifications.unread_count });

      api.post(`intranet/message/read?id=${id}`);
    }
  }

  async function postDeleteNotification(id: number) {
    if (deletingNotification !== id) {
      setDeletingNotification(id);
      await api.post(`intranet/message/remove?id=${id}`);

      getNotifications({ current_page: currentPage });
      setDeletingNotification(false);
    }
  }

  const postNotifiedNotification = (id: number) => {
    api.post(`intranet/message/notified?id=${id}`);
  };

  const handleDeleteNotification = (id: number) => {
    postDeleteNotification(id);
  };

  const handleDeleteAllNotifications = () => {
    postDeleteNotification(-1);
  };
  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > notifications?.total_page || page === notifications?.current_page) return;
    const newData = notifications;
    newData.data = [];
    setNotifications(newData);
    getNotifications({ current_page: page });
  };

  useEffect(() => {
    try {
      ShowBrowserNewNotifications();
    } catch (error) {
      console.error(error);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    getNotifications({ current_page: currentPage });
    for (let index = 0; index < 100; index++) {
      window.clearInterval(index);
    }
    const interval = setInterval(() => {
      getNotifications({ current_page: curPage });
      setBackgroundUpdating(true);
    }, 120000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={() => {
        onClose();
      }}
      className={classes.root}
    >
      <Paper elevation={1} square className={classes.topBar}>
        <Box m={1} display="flex" alignItems="center">
          <Tooltip title="Fechar Filtro">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ minWidth: 30, maxWidth: 30, height: 30 }}
              onClick={() => {
                onClose();
              }}
            >
              <CloseRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>

          <Box flex={1} display="flex" justifyContent="center" alignItems="center">
            <Typography>Notificações</Typography>
          </Box>
          <Tooltip title={`${notifications?.data?.length > 0 ? 'Limpar todas as notificações' : ''}`}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              style={{ minWidth: 30, maxWidth: 30, height: 30 }}
              disabled={notifications?.data?.length === 0}
              onClick={() => {
                handleDeleteAllNotifications();
              }}
            >
              {deletingNotification ? (
                <CircularProgress color="secondary" size={10} style={{ width: 10, height: 10 }} disableShrink />
              ) : (
                <DeleteSweepIcon fontSize="small" />
              )}
            </Button>
          </Tooltip>
        </Box>
      </Paper>
      {notifications?.data_total > 0 ? (
        <>
          <Box borderRadius="4px" p={0.5}>
            <Box style={{ width: '100%', height: 'calc(100vh - 102px)', overflowY: 'auto', overflowX: 'hidden' }}>
              {notifications?.data?.map((notification: IntranetMessages) => {
                return (
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    key={`ntf-${notification.id}`}
                    style={{ width: '100%' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={`notification-header-${notification.id}`}
                      className={classes.drawerNotificationAccordionSummary}
                      onClick={() => {
                        if (deletingNotification !== notification.id && notification.readed_at === null)
                          postReadNotification(notification.id);
                      }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
                        <Typography
                          className={
                            notification.readed_at === null
                              ? classes.drawerNotificationItemTitle
                              : classes.drawerNotificationItemTitleReaded
                          }
                        >
                          {notification.title}
                        </Typography>
                        <Typography className={classes.drawerNotificationItemCreatedDate}>
                          {notification.created_at_formated}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml={1}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          style={{ minWidth: 30, maxWidth: 30, height: 30 }}
                          onClick={(event: any) => {
                            if (deletingNotification !== notification.id) handleDeleteNotification(notification.id);
                            event.stopPropagation();
                          }}
                        >
                          {deletingNotification === notification.id ? (
                            <CircularProgress
                              color="secondary"
                              size={10}
                              style={{ width: 10, height: 10 }}
                              disableShrink
                            />
                          ) : (
                            <DeleteIcon fontSize="small" />
                          )}
                        </Button>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" justifyContent="center" flex={1}>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          flexDirection="column"
                          justifyContent="center"
                          flex={1}
                          gap={1}
                          sx={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          <Typography>{notification.text}</Typography>

                          {notification.from_id > 0 && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                width: '100%',
                                justifyContent: 'flex-end',
                                mt: 1,
                              }}
                            >
                              <Typography variant="caption">Enviado por</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap' }}>
                                <ProfileTooltip userId={notification.from_id.toString()} openNewTab>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                      style={{
                                        width: 22,
                                        height: 22,
                                        borderRadius: '50%',
                                        marginRight: 8,
                                      }}
                                      src={avatarUrl(notification.from_id)}
                                      alt={`user-${notification.from_id}`}
                                      onError={(e: any) => {
                                        e.target.src = avatarUrl(0, true);
                                      }}
                                    />
                                  </Box>
                                </ProfileTooltip>
                                <Typography>{notification?.from_name}</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>

                        {notification?.ref?.length > 0 && (
                          <Box
                            display="flex"
                            alignItems="flex-end"
                            flexDirection="column"
                            justifyContent="center"
                            flex={1}
                            mt={1}
                          >
                            <Button
                              component={Link}
                              color="primary"
                              variant="outlined"
                              size="small"
                              to={`/${notification.ref}`}
                              onClick={() => onClose()}
                            >
                              VISUALIZAR <OpenInNewIcon style={{ marginLeft: 8 }} />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Box>

          <Box m={1} display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.totalText} />

            <Pagination
              disabled={notifications?.total_page === 1}
              count={notifications?.total_page}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
            />
            <Typography className={classes.totalText}>{`Total: ${notifications?.data_total}`}</Typography>
          </Box>

          {gettingNotificationData && !backgroundUpdating && (
            <Box className={`${classes.backdrop} ${classes.backdropColor}`}>
              <Typography>Carregando...</Typography>
            </Box>
          )}
        </>
      ) : (
        <Box className={classes.backdrop}>
          <Typography className={classes.backdropText}>Nenhuma notificação encontrada</Typography>
        </Box>
      )}
    </Drawer>
  );
}
