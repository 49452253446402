import Dashboard from './dashboard';
import Helpdesk from './helpdesk';
import Users from './users';
import Configs from './configs';
import Contacts from './contacts';
import Permissions from './permissions';
import Hcm from './hcm';

export default {
  Helpdesk,
  Dashboard,
  Users,
  Configs,
  Contacts,
  Permissions,
  Hcm,
};
