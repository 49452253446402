import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Avatar, Skeleton, Tooltip, IconButton } from '@mui/material';
import BirthdayIcon from '@mui/icons-material/Cake';
import axios, { Canceler } from 'axios';
import { purple } from '@mui/material/colors';
import CelebrationIcon from '@mui/icons-material/Celebration';
import api from '../../../services/api';
import ProfileTooltip from '../../../components/ProfileTooltip';
import balloonImage from '../../../images/balloon.svg';
import { avatarUrl } from '../../../utils/functions';
import { BirthdayCelebrateForm } from './BirthdayCelebrateForm';

interface BirthdayList {
  id: number;
  name: string;
  date: string;
  sector: string;
  age: string;
  day: string;
  month: string;
  year: string;
  isToday: boolean;
  celebrated: boolean;
  daysLeft: number;
}

function BirthdayCard() {
  const [birthdayList, setBirthdayList] = useState<BirthdayList[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [birthdayCelebrate, setBirthdayCelebrate] = useState(0);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const { data } = await api.get<BirthdayList[]>('hcm/birthdays/nears', {
          params: {
            max: 12,
          },
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setBirthdayList(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  const renderBirthdayCelebrateForm = useMemo(
    () => (
      <BirthdayCelebrateForm
        id={birthdayCelebrate}
        open={birthdayCelebrate > 0}
        handleClose={() => {
          getData();
          setBirthdayCelebrate(0);
        }}
      />
    ),
    [birthdayCelebrate],
  );

  useEffect(() => {
    getData();
  }, []);

  const FakeRow = (props: { first?: boolean }) => {
    const { first } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
          }}
        >
          <Skeleton variant="rectangular" height="1.1rem" width={30} sx={{ mb: 0.4, borderRadius: 0.5 }} />
          <Skeleton variant="rectangular" height="0.7rem" width={30} sx={{ borderRadius: 0.5 }} />
        </Box>

        <Skeleton variant="circular" height={64} width={64} />

        <Box>
          <Skeleton variant="rectangular" height="1.1rem" width={200} sx={{ mb: 0.4, borderRadius: 0.5 }} />
          <Skeleton variant="rectangular" height="0.9rem" width={160} sx={{ mb: 0.4, borderRadius: 0.5 }} />
        </Box>
      </Box>
    );
  };

  const Row = (props: {
    first?: boolean;
    id: number;
    day: string;
    month: string;
    name: string;
    sector: string;
    isToday: boolean;
    celebrated: boolean;
    daysLeft: number;
  }) => {
    const { id, first, day, month, name, sector, isToday, celebrated, daysLeft } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          p: 0.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 1.5,
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 48,
              color: theme => (theme.palette.mode === 'light' ? purple[800] : purple[300]),
            }}
          >
            <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.9rem', fontWeight: 'bold' }}>{day}</Typography>
            <Typography sx={{ fontSize: '0.75rem', lineHeight: '0.4rem' }}>{month}</Typography>
          </Box>

          <Link to={`/profile/${id}`} style={{ textDecoration: 'none' }}>
            {isToday && (
              <img
                src={balloonImage}
                alt="happy-birthday"
                style={{
                  height: 28,
                  width: 28,
                  position: 'absolute',
                  zIndex: 1,
                  transform: 'translate(-60%, -25%)',
                }}
              />
            )}

            <ProfileTooltip userId={id}>
              <Avatar
                alt={`${name}`}
                src={avatarUrl(id, true)}
                aria-label={`${name.toLowerCase()}`}
                sx={{ height: '3rem', width: '3rem' }}
              />
            </ProfileTooltip>
          </Link>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '1rem' }}>{name}</Typography>
            </Box>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              {sector}
            </Typography>
          </Box>
        </Box>
        {daysLeft <= 4 && (
          <Tooltip title={celebrated ? '' : 'Comemorar!'} disableInteractive>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => setBirthdayCelebrate(id)}
              sx={{
                p: 0,
                m: 0,
                minWidth: 28,
              }}
              disabled={celebrated}
            >
              <CelebrationIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <BirthdayIcon
            sx={{
              color: theme => (theme.palette.mode === 'light' ? purple[800] : purple[300]),
            }}
          />
          <Typography>Aniversáriantes</Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {!loadingData
            ? birthdayList.map((birthday, i) => {
                const { id, name, sector, day, month, isToday, celebrated, daysLeft } = birthday;
                const key = `${i}-${birthday.id}`;
                return (
                  <Row
                    key={key}
                    first={i === 0}
                    id={id}
                    isToday={isToday}
                    day={day}
                    month={month}
                    name={name}
                    sector={sector}
                    celebrated={celebrated}
                    daysLeft={daysLeft}
                  />
                );
              })
            : Array.from({ length: 7 }, (v, k) => k).map((k, i) => {
                return <FakeRow key={k} first={k === 0} />;
              })}
        </Box>
        <Box
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            borderTop: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Button size="small" variant="outlined" color="warning" component={Link} to="/hcm/birthday">
            Ver todos
          </Button>
        </Box>
      </Paper>
      {renderBirthdayCelebrateForm}
    </Box>
  );
}
export { BirthdayCard };
