import Add from './add';
import Dashboard from './dashboard';
import Edit from './edit';
import Published from './published';
import View from './view';

export default {
  Add,
  Dashboard,
  Edit,
  Published,
  View,
};
