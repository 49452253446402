import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';

import { avatarUrl, toNumber } from '../../../../../../utils/functions';
import api from '../../../../../../services/api';
import ProfileTooltip from '../../../../../../components/ProfileTooltip';

interface EnOsEditTaskFormProps {
  open: boolean;
  taskId: number;
  handleClose?: () => void;
  handleOpen?: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmited?: (success: boolean, msg: string) => void;
}

type FormStateType = {
  id: number;
  description: string;
  updated_at: string;
  updated_at_formated: string;
  updated_by: number;
  updated_by_name: string;
  days: number;
};

const emptyFormState: FormStateType = {
  id: 0,
  description: '',
  updated_at: '',
  updated_at_formated: '',
  updated_by: 0,
  updated_by_name: '',
  days: 0,
};

function EnOsEditTaskForm({ handleClose, handleOpen, open, onSubmited, taskId }: EnOsEditTaskFormProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [errorState, setErrorState] = useState({
    parent_task_error: '',
    description_error: '',
    days_error: '',
  });

  const [formState, setFormState] = useState(emptyFormState);

  const [fetching, setFetching] = useState(false);

  const resetFormState = () => {
    setErrorState({
      parent_task_error: '',
      description_error: '',
      days_error: '',
    });

    setFormState(emptyFormState);
  };

  function handleValideData() {
    const parent_task_error = '';
    let description_error = '';
    const days_error = '';

    if (!formState.description || formState.description.length === 0) {
      description_error = 'É necessário informar uma descrição';
    }

    setErrorState({
      parent_task_error,
      description_error,
      days_error,
    });
    if (parent_task_error.length > 0 || description_error.length > 0 || days_error.length > 0) return false;

    return true;
  }

  const handleSubmit = async () => {
    if (fetching) return;
    if (handleValideData()) {
      const data = {
        description: formState.description,
        days: formState.days.toString(),
      };

      try {
        const { data: os } = await api.post(`erp/engineering/os/tasks/${formState.id}/update`, data);
        const message = os?.message ?? '';
        if (message === 'successfully_updated') {
          onSubmited?.(true, 'Atualizado com sucesso.');
          resetFormState();
        } else if (message === 'nothing_to_update') {
          onSubmited?.(true, 'Não houve alterações');
        }
      } catch (error) {
        const { data: response } = error.response;
        onSubmited?.(false, response.message);
      }
    }
  };

  const handleOnCloseDialog = () => {
    if (fetching) return;
    handleClose?.();
  };

  const handleClickCancelDialog = () => {
    if (fetching) return;
    handleClose?.();
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    let { value } = e.target;
    if (id === 'days' && value.length > 0) value = toNumber(value) < 1 ? '0' : value;
    setFormState(old => ({ ...old, [id]: value }));
  };

  const getTaskData = async () => {
    setFormState(emptyFormState);

    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<FormStateType>(`erp/engineering/os/tasks/${taskId}`, {
        params: {
          id: taskId,
        },
      });

      setFormState(old => ({
        ...old,
        ...data,
      }));
    } catch (error) {}
    setFetching(false);
  };

  useEffect(() => {
    if (open) {
      getTaskData();
      handleOpen?.();
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const inputID = useMemo(
    () => (
      <TextField
        label="Código"
        id="id"
        type="text"
        value={formState?.id}
        sx={{
          width: 120,
        }}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        disabled
      />
    ),
    [formState.id],
  );

  const inputDescription = useMemo(
    () => (
      <TextField
        fullWidth
        label="Descrição"
        id="description"
        type="text"
        onChange={handleFieldChange}
        value={formState?.description}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.description_error?.length > 0}
        helperText={errorState.description_error}
      />
    ),
    [formState.description, errorState.description_error],
  );

  const inputDays = useMemo(
    () => (
      <TextField
        fullWidth
        label="Prazo (dias)"
        id="days"
        type="number"
        onChange={handleFieldChange}
        value={formState.days}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        sx={{
          width: 120,
        }}
        error={errorState.days_error?.length > 0}
        helperText={errorState.days_error}
      />
    ),
    [formState.days, errorState.days_error],
  );

  const inputUpdatedBy = useMemo(
    () => (
      <TextField
        fullWidth
        label="Atualizado por"
        id="updated_at"
        type="string"
        InputProps={{
          startAdornment: (
            <ProfileTooltip userId={formState.updated_by}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 1 }}>
                <img
                  style={{
                    height: 24,
                    width: 24,
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.2)',
                  }}
                  src={avatarUrl(formState.updated_by)}
                  alt="updated_by"
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0);
                  }}
                />
              </Box>
            </ProfileTooltip>
          ),
        }}
        value={formState.updated_by_name}
        size="small"
        disabled
      />
    ),
    [formState.updated_by, formState.updated_by_name],
  );

  const inputUpdatedData = useMemo(
    () => (
      <TextField
        label="Atualizado em"
        id="updated_at"
        type="string"
        value={formState.updated_at_formated}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        disabled
        sx={{
          maxWidth: 180,
        }}
      />
    ),
    [formState.updated_at_formated],
  );

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md" disableEscapeKeyDown>
      <DialogTitle> OS - Editar Tarefa</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
            height: '100%',
            filter: fetching ? 'blur(1.5px)' : '',
          }}
        >
          {inputID}
          {inputDescription}
          {inputDays}
          {inputUpdatedData}
          {inputUpdatedBy}
        </Box>
        {fetching && (
          <Box
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              height: '100%',
              width: '100%',
              userSelect: 'none',
            }}
          >
            Carregando...
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClickCancelDialog}>
          Cancelar
        </Button>
        <Button color="success" variant="contained" onClick={handleSubmit} disabled={fetching}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { EnOsEditTaskForm };
