import React, { Dispatch, forwardRef, useCallback, useEffect, useState, SetStateAction, useMemo } from 'react';
import { isAndroid, isWindows } from 'react-device-detect';

import { useNavigate, Link, LinkProps, useParams } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  IconButton,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Menu,
  MenuItem,
  Breadcrumbs,
  Typography,
  ListItemSecondaryAction,
  Switch,
  useScrollTrigger,
  Zoom,
  Fab,
  Box,
  Tooltip,
  Button,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import PasswordIcon from '@mui/icons-material/Password';
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from 'moment';
import { Canceler } from 'axios';
import styles from './styles';

import api from '../../services/api';
import auth from '../../services/auth';
import Notifications from '../Notifications';
import intraIMICLogoImg from '../../images/IntraIMIC_logo.svg';
import MenuListDrawer from '../MenuListDrawer';
import { UserDataProps } from '../../routes';
import ChangePasswordDialog from '../ChangePasswordDialog';
import { avatarUrl, toNumber } from '../../utils/functions';
import ChangeCompany from '../ChangeCompany';
import { CompanyType } from '../../App';

const LAYOUT_VERSION = '2024.04.24h';

moment.locale('pt-br');
moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });

function ScrollButton({ classes, trigger }) {
  const handleScrollTopClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Zoom in={trigger} style={{ zIndex: 99 }}>
      <Box onClick={handleScrollTopClick} role="presentation" className={classes.scrollTopButton}>
        <Fab color="secondary" className={classes.scrollTopButtonColor} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}

function Children(props: {
  user: UserDataProps;
  darkMode: boolean | undefined;
  children: (user: UserDataProps) => React.ReactElement<UserDataProps>;
}) {
  const { children } = props;
  return children;
}

export default function Layout(props: {
  user: UserDataProps;
  darkMode: boolean | undefined;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  company: string | undefined;
  setCompany: Dispatch<SetStateAction<string>>;
  branch: string | undefined;
  setBranch: Dispatch<SetStateAction<string>>;
  pageSettings?: {
    path: string;
    breadcrumbs: { name: string; path: string }[];
    props?: any;
  };
  children: React.ReactElement<UserDataProps>;
}) {
  const navigate = useNavigate();
  const classes = styles();

  const { id } = useParams() as any;

  const { darkMode, setDarkMode, user, pageSettings, branch, company, setBranch, setCompany, children } = props;

  const breadcrumbs = pageSettings?.breadcrumbs;

  const trigger = useScrollTrigger({
    target: window || undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const [showMenuList, setShowMenuList] = useState(false);
  const [openNotificationsDrawer, setOpenNotificationsDrawer] = useState(false);
  const [openChangeCompanyDrawer, setOpenChangeCompanyDrawer] = useState(false);
  const [fetchingCompanyData, setFetchingCompanyData] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<CompanyType[]>(null);
  const [companyName, setCompanyName] = useState(window.localStorage.getItem('company-name') ?? '');

  const [notificationsCount, setNotificationsCount] = useState(0);

  const [forceChangingPassword, setForceChangingPassword] = useState(user?.change_password);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);

  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

  const getCompanyData = async () => {
    let cancel: Canceler;

    try {
      if (!fetchingCompanyData) {
        setFetchingCompanyData(true);
        const { data } = await api.get<CompanyType[]>('erp/company/', {});
        setCompanyData(data);
        const newCompanyName =
          data
            ?.filter(f => toNumber(f.codemp) === toNumber(company))?.[0]
            ?.branches?.filter(f => toNumber(f.codfil) === toNumber(branch))?.[0]?.sigfil || '';
        setCompanyName(newCompanyName);
        window.localStorage.setItem('company-name', newCompanyName);
      }
    } catch (error) {}
    setFetchingCompanyData(false);

    return () => cancel();
  };

  const setCount = useCallback(
    value => {
      setNotificationsCount(value);
    },
    [notificationsCount],
  );

  const menuProfileLink = forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => (
    <Link to={`/profile/${user?.id}`} {...props} ref={ref as any} />
  ));
  const handleSetDarkMode = async () => {
    setDarkMode(!darkMode);
    window.localStorage.setItem('theme', !darkMode ? 'dark' : 'light');
    await api.post('intranet/users/settings', {
      setting: 'darkMode',
      value: !darkMode,
    });
    window.location.reload();
  };

  const toggleMenu = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setShowMenuList(open);
  };

  const toggleNotificationDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenNotificationsDrawer(open);
  };

  const toggleChangeCompanyDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenChangeCompanyDrawer(open);
  };

  const handleLogout = () => {
    auth.logout(() => {
      navigate('/authenticate');
    });
  };

  const handleNavmenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const renderCompanyIconButton = useMemo(
    () => (
      <Button
        aria-label="notifications"
        color="inherit"
        aria-controls="nav-submenu-notifications"
        aria-haspopup="true"
        className={classes.notifications}
        onClick={toggleChangeCompanyDrawer(true)}
        size="large"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            flexDirection: 'row',
          }}
        >
          <BusinessIcon />
          <Typography>{companyName}</Typography>
        </Box>
      </Button>
    ),
    [companyName],
  );

  const renderNotificationsIconButton = useMemo(
    () => (
      <IconButton
        aria-label="notifications"
        color="inherit"
        aria-controls="nav-submenu-notifications"
        aria-haspopup="true"
        className={classes.notifications}
        onClick={toggleNotificationDrawer(true)}
        size="large"
      >
        <Badge color="error" badgeContent={notificationsCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    ),
    [notificationsCount],
  );

  function handleChangePasswordDialogOpen() {
    setShowChangePasswordDialog(true);
  }

  const renderAvatarIconButton = useMemo(
    () => (
      <IconButton
        aria-label="perfil"
        aria-controls="navMenuProfile"
        aria-haspopup="true"
        size="small"
        onClick={handleProfileMenuOpen}
        color="default"
      >
        <Avatar alt={`${user?.nickname}`} src={avatarUrl(user?.id, true)} className={classes.avatar} />
      </IconButton>
    ),
    [user?.id],
  );

  // menuProfileLink.displayName = 'ProfileLink';
  const renderMenuProfile = useMemo(
    () => (
      <Menu
        autoFocus={false}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={profileMenuAnchorEl}
        id="navMenuProfile"
        keepMounted
        open={isProfileMenuOpen}
        onClose={handleNavmenuClose}
      >
        <MenuItem
          onClick={handleNavmenuClose}
          component={menuProfileLink}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '256px',
            maxWidth: '100vw',
          }}
        >
          <Avatar alt={`${user?.nickname}`} src={avatarUrl(user?.id, true)} className={classes.userAvatarImage} />
          <Typography className={classes.userNameText} variant="subtitle1">{`${user?.nickname}`}</Typography>
          <Typography className={classes.userSectorText} variant="caption">{`${user?.sector}`}</Typography>
          <Typography
            className={classes.userEmployeePositionText}
            variant="caption"
          >{`${user?.employee_position}`}</Typography>
        </MenuItem>
        <Divider />
        <ListItem>
          <ListItemIcon
            style={{
              minWidth: '36px',
            }}
          >
            <Icon>palette</Icon>
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-theme"
            primary="Tema Escuro"
            style={{
              paddingRight: '24px',
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              color="primary"
              onChange={handleSetDarkMode}
              checked={darkMode}
              inputProps={{ 'aria-labelledby': 'switch-list-label-theme' }}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <MenuItem onClick={handleChangePasswordDialogOpen} style={{ minHeight: 48 }}>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          <ListItemText primary="Alterar Senha" />
        </MenuItem>
        <MenuItem onClick={handleLogout} style={{ minHeight: 48 }}>
          <ListItemIcon>
            <Icon color="secondary">power_settings_new</Icon>
          </ListItemIcon>
          <ListItemText color="text.secondary" primary="Sair" />
        </MenuItem>
      </Menu>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, darkMode, profileMenuAnchorEl],
  );

  const renderBreadcrumbs = useMemo(
    () =>
      breadcrumbs?.length > 0 && (
        <Box className={classes.breadcrumbs}>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs?.map((value: any, index: number) => {
              const name = value.name.replace(':id', id);
              const path = value.path.replace(':id', id);
              const key = `breadcrumbs-${index}`;
              if (index === toNumber(breadcrumbs?.length) - 1) {
                return (
                  <Typography className={classes.breadcrumbCurrent} key={key}>
                    {name}
                  </Typography>
                );
              }
              if (path === '') {
                return (
                  <Typography className={classes.breadcrumbNoLink} key={key}>
                    {name}
                  </Typography>
                );
              }
              return (
                <Link className={classes.breadcrumbLink} to={path} key={key}>
                  {name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
      ),
    [pageSettings, darkMode, fetchingCompanyData],
  );

  const renderFooter = useMemo(() => {
    return (
      <Box className={classes.footer}>
        <a href="/">
          <b>IntraIMIC</b>
        </a>
        {' - '}
        <span>
          {moment.utc().format('YYYY')}
          {' - '}ver:{LAYOUT_VERSION}
        </span>
        {' | '}
        <span className={classes.footerCredits}>Developed by Júlio César M. Amaral - TI</span>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LAYOUT_VERSION]);

  const renderMenu = useMemo(
    () => (
      <MenuListDrawer
        show={showMenuList}
        onClose={() => {
          setShowMenuList(false);
        }}
      />
    ),
    [showMenuList],
  );

  useEffect(() => {
    if (isAndroid || isWindows) {
      Notification?.requestPermission();
    }
    setForceChangingPassword(user?.change_password);
    setShowChangePasswordDialog(user?.change_password);
    window.localStorage.setItem('theme', user?.settings?.darkMode === '1' ? 'dark' : 'light');
  }, [user]);

  useEffect(() => {
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, branch]);

  return (
    user && (
      <Box className={classes.grow}>
        <Box id="back-to-top-anchor" />
        <AppBar position="fixed" className={classes.appbarRoot}>
          <Toolbar id="main-toolbar" variant="dense" className={classes.topbar}>
            <Tooltip title="Abrir menu">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={toggleMenu(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>

            <img src={intraIMICLogoImg} alt="IntraIMIC" className={classes.titleText} />
            <Box className={classes.grow} />
            <Box>
              <Button
                aria-label="notifications"
                color="inherit"
                aria-controls="nav-submenu-notifications"
                aria-haspopup="true"
                className={classes.notifications}
                onClick={toggleChangeCompanyDrawer(true)}
                size="large"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    flexDirection: 'row',
                  }}
                >
                  <BusinessIcon />
                  <Typography>{companyName}</Typography>
                </Box>
              </Button>
              <IconButton
                aria-label="notifications"
                color="inherit"
                aria-controls="nav-submenu-notifications"
                aria-haspopup="true"
                className={classes.notifications}
                onClick={toggleNotificationDrawer(true)}
                size="large"
              >
                <Badge color="error" badgeContent={notificationsCount}>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="perfil"
                aria-controls="navMenuProfile"
                aria-haspopup="true"
                size="small"
                onClick={handleProfileMenuOpen}
                color="default"
              >
                <Avatar alt={`${user?.nickname}`} src={avatarUrl(user?.id, true)} className={classes.avatar} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Menu
          autoFocus={false}
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={profileMenuAnchorEl}
          id="navMenuProfile"
          keepMounted
          open={isProfileMenuOpen}
          onClose={handleNavmenuClose}
        >
          <MenuItem
            onClick={handleNavmenuClose}
            component={menuProfileLink}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '256px',
              maxWidth: '100vw',
            }}
          >
            <Avatar alt={`${user?.nickname}`} src={avatarUrl(user?.id, true)} className={classes.userAvatarImage} />
            <Typography className={classes.userNameText} variant="subtitle1">{`${user?.nickname}`}</Typography>
            <Typography className={classes.userSectorText} variant="caption">{`${user?.sector}`}</Typography>
            <Typography
              className={classes.userEmployeePositionText}
              variant="caption"
            >{`${user?.employee_position}`}</Typography>
          </MenuItem>
          <Divider />
          <ListItem>
            <ListItemIcon
              style={{
                minWidth: '36px',
              }}
            >
              <Icon>palette</Icon>
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-theme"
              primary="Tema Escuro"
              style={{
                paddingRight: '24px',
              }}
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                color="primary"
                onChange={handleSetDarkMode}
                checked={darkMode}
                inputProps={{ 'aria-labelledby': 'switch-list-label-theme' }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <MenuItem onClick={handleChangePasswordDialogOpen} style={{ minHeight: 48 }}>
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText primary="Alterar Senha" />
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ minHeight: 48 }}>
            <ListItemIcon>
              <Icon color="secondary">power_settings_new</Icon>
            </ListItemIcon>
            <ListItemText color="text.secondary" primary="Sair" />
          </MenuItem>
        </Menu>
        <MenuListDrawer
          show={showMenuList}
          onClose={() => {
            setShowMenuList(false);
          }}
        />
        <ChangeCompany
          show={openChangeCompanyDrawer}
          onClose={() => {
            setOpenChangeCompanyDrawer(false);
          }}
          onUpdate={e => {}}
        />
        <Notifications
          show={openNotificationsDrawer}
          onClose={() => {
            setOpenNotificationsDrawer(false);
          }}
          onUpdate={e => {
            setCount(e?.count);
          }}
        />
        <Box className={classes.topbarSpace} />

        <Box className={classes.contentRoot}>
          <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Box className={classes.breadcrumbs}>
              <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs?.map((value: any, index: number) => {
                  const name = value.name.replace(':id', id);
                  const path = value.path.replace(':id', id);
                  const key = `breadcrumbs-${index}`;
                  if (index === toNumber(breadcrumbs?.length) - 1) {
                    return (
                      <Typography className={classes.breadcrumbCurrent} key={key}>
                        {name}
                      </Typography>
                    );
                  }
                  if (path === '') {
                    return (
                      <Typography className={classes.breadcrumbNoLink} key={key}>
                        {name}
                      </Typography>
                    );
                  }
                  return (
                    <Link className={classes.breadcrumbLink} to={path} key={key}>
                      {name}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </Box>
            <Box className={classes.content}>{children}</Box>

            <Box className={classes.footer}>
              <a href="/">
                <b>IntraIMIC</b>
              </a>
              {' - '}
              <span>
                {moment.utc().format('YYYY')}
                {' - '}ver:{LAYOUT_VERSION}
              </span>
              {' | '}
              <span className={classes.footerCredits}>Developed by Júlio César M. Amaral - TI</span>
            </Box>
          </Box>
        </Box>
        <ScrollButton classes={classes} trigger={trigger} />
        <ChangePasswordDialog
          show={showChangePasswordDialog}
          force={forceChangingPassword}
          onClose={() => {
            setShowChangePasswordDialog(false);
          }}
        />
      </Box>
    )
  );
}
