import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  TextField,
  Snackbar,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import Moment from 'moment';
import 'moment/locale/pt-br';

import { Alert, Autocomplete } from '@mui/lab';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import Axios, { Canceler } from 'axios';

import api from '../../../services/api';
import styles from './styles';
import InputSearchAsync, { ValueProps } from '../../../components/inputs/InputSearchAsync';

Moment.locale('pt-br');
Moment.updateLocale(Moment.locale(), { invalidDate: '00/00/0000' });

interface TargetProps {
  id: number;
  description: string;
  situation: number;
}

interface AttendantProps {
  target_id: number;
  attendants_id: number;
  attendants_name: string;
  type: number;
  situation: number;
}
interface CategoryProps {
  id: number;
  description: string;
  situation: number;
}
interface SubcategoryProps {
  id: number;
  description?: string;
  days?: number;
  private?: number;
  situation?: number;
}
export default function AdminHelpdeskPage(props: any) {
  const classes = styles();

  const [targets, setTargets] = useState<TargetProps[]>([]);
  const [categorys, setCategory] = useState<CategoryProps[]>([]);
  const [subcategorys, setSubcategory] = useState<SubcategoryProps[]>([]);
  const [attendants, setAttendants] = useState<AttendantProps[]>([]);

  const [gettingTargets, setGettingTargets] = useState<boolean>(false);
  const [gettingAttendants, setGettingAttendants] = useState<boolean>(false);
  const [gettingCategory, setGettingCategory] = useState<boolean>(false);
  const [gettingSubcategory, setGettingSubcategory] = useState<boolean>(false);

  const [updatingTarget, setUpdatingTarget] = useState<boolean>(false);
  const [updatingAttendant, setUpdatingAttendant] = useState<boolean>(false);
  const [updatingCategory, setUpdatingCategory] = useState<boolean>(false);
  const [updatingSubcategory, setUpdatingSubcategory] = useState<boolean>(false);

  const [selectedTarget, setSelectedTarget] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = useState(0);

  const [inputTargetText, setInputTargetText] = useState('');
  const [inputCategoryText, setInputCategoryText] = useState('');
  const [inputSubcategoryText, setInputSubcategoryText] = useState('');
  const [inputSubcategoryDay, setInputSubcategoryDay] = useState('');
  const [inputAttendantText, setInputAttendantText] = useState('');
  const [inputAttendantData, setInputAttendantData] = useState<ValueProps[] | null>([]);

  const [inputTargetAdd, setInputTargetAdd] = useState(true);
  const [inputCategoryAdd, setInputCategoryAdd] = useState(true);
  const [inputSubcategoryAdd, setInputSubcategoryAdd] = useState(true);

  const [openAttendantSearch, setOpenAttendantSearch] = useState(false);
  const [optionsAttendantSearch, setOptionsAttendantSearch] = useState<ValueProps[]>([]);
  const loadingAttendantSearch = openAttendantSearch && optionsAttendantSearch?.length === 0;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    if (!openAttendantSearch) {
      setOptionsAttendantSearch([]);
    }
  }, [openAttendantSearch]);

  const getTargets = () => {
    let cancel: Canceler;
    if (!gettingTargets) {
      setGettingTargets(true);
      setSelectedTarget(0);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setCategory([]);
      setSubcategory([]);
      setAttendants([]);
      api
        .get(`helpdesk/target?situation=-1`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setTargets(response.data);
          setSelectedCategory(0);
          setSelectedSubcategory(0);

          setGettingTargets(false);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_id') msg = 'É necessário informar um setor válido.';
          else if (msg === 'category_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setGettingTargets(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
    return () => cancel();
  };

  useEffect(() => {
    getTargets();
  }, []);

  const updateTarget = ({ id, description, situation }: TargetProps) => {
    if (!updatingTarget) {
      setSelectedTarget(0);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setUpdatingTarget(true);
      const params = {
        id,
        description,
        situation,
      };
      api
        .post(`helpdesk/target`, params)
        .then((response: any) => {
          setUpdatingTarget(false);
          getTargets();
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'target_not_found') msg = 'Não foi encontrado o cadastro.';
          else if (msg === 'update_error') msg = 'Erro na atualização do castrado.';
          else if (msg === 'invalid_id') msg = 'ID inválido.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingTarget(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
          getTargets();
        });
    }
  };

  const insertTarget = (description: string) => {
    if (!updatingTarget) {
      setUpdatingTarget(true);
      setSelectedTarget(0);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      const params = {
        description,
      };
      api
        .put(`helpdesk/target`, params)
        .then((response: any) => {
          setUpdatingTarget(false);
          getTargets();
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingTarget(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const getCategory = (targetId = 0) => {
    if (targetId < 1) return;
    let cancel: Canceler;
    if (!gettingCategory) {
      setGettingCategory(true);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setSubcategory([]);
      api
        .get(`helpdesk/category?target_id=${targetId}&situation=-1`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setCategory(response.data);
          setSelectedSubcategory(0);
          setGettingCategory(false);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_id') msg = 'É necessário informar um setor válido.';
          else if (msg === 'category_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setGettingCategory(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
    return () => cancel();
  };

  const updateCategory = ({ id, description = '', situation = -1 }: CategoryProps) => {
    if (!updatingCategory) {
      setUpdatingCategory(true);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setSubcategory([]);

      const params = {
        id,
        description,
        situation,
      };
      api
        .post(`helpdesk/category`, params)
        .then((response: any) => {
          setUpdatingCategory(false);

          getCategory(selectedTarget);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'category_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'category_not_found') msg = 'Não foi encontrado o cadastro.';
          else if (msg === 'update_error') msg = 'Erro na atualização do castrado.';
          else if (msg === 'invalid_id') msg = 'ID inválido.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingCategory(false);

          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const insertCategory = (description: string) => {
    if (!updatingCategory) {
      const params = {
        target_id: selectedTarget,
        description,
      };

      setUpdatingCategory(true);
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setSubcategory([]);

      api
        .put(`helpdesk/category`, params)
        .then((response: any) => {
          setUpdatingCategory(false);
          getCategory(selectedTarget);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_id') msg = 'É necessário informar um setor válido.';
          else if (msg === 'category_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingCategory(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const getSubcategory = (categoryId = selectedCategory) => {
    let cancel: Canceler;
    if (!gettingSubcategory) {
      setGettingSubcategory(true);
      setSelectedSubcategory(0);
      api
        .get(`helpdesk/subcategory?category_id=${categoryId}&situation=-1`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setSubcategory(response.data);
          setGettingSubcategory(false);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'subcategory_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'subcategory_not_found') msg = 'Não foi encontrado o cadastro.';
          else if (msg === 'update_error') msg = 'Erro na atualização do castrado.';
          else if (msg === 'invalid_id') msg = 'ID inválido.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setGettingSubcategory(false);

          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
    return () => cancel();
  };

  const updateSubcategory = (subcategoryData: SubcategoryProps) => {
    if (!updatingSubcategory) {
      setUpdatingSubcategory(true);
      setSelectedSubcategory(0);

      const params = {
        id: subcategoryData.id,
        description: subcategoryData.description,
        situation: subcategoryData.situation,
        days: subcategoryData.days,
        private: subcategoryData.private,
      };

      api
        .post(`helpdesk/subcategory`, params)
        .then((response: any) => {
          setUpdatingSubcategory(false);
          getSubcategory(selectedCategory);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'subcategory_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'subcategory_not_found') msg = 'Não foi encontrado o cadastro.';
          else if (msg === 'update_error') msg = 'Erro na atualização do castrado.';
          else if (msg === 'invalid_id') msg = 'ID inválido.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingSubcategory(false);

          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const insertSubcategory = (description: string, days: string) => {
    if (!updatingSubcategory) {
      const params = {
        category_id: selectedCategory,
        description,
        days,
      };

      setUpdatingSubcategory(true);
      setSelectedSubcategory(0);
      api
        .put(`helpdesk/subcategory`, params)
        .then((response: any) => {
          setUpdatingSubcategory(false);
          getSubcategory(selectedCategory);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'target_id') msg = 'É necessário informar um setor válido.';
          else if (msg === 'subcategory_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingSubcategory(false);
          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const getAttendants = (targetId = 0) => {
    let cancel: Canceler;
    if (!gettingAttendants) {
      setGettingAttendants(true);

      api
        .get(`helpdesk/attendants?target=${targetId}`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setAttendants(response.data);

          setSelectedCategory(0);
          setSelectedSubcategory(0);

          setGettingAttendants(false);
        })
        .catch((error: any) => {
          setSnackbarType('error');
          setSnackbarMsg(error);
          setOpenSnackbar(true);
          setGettingAttendants(false);
        });
    }
    return () => cancel();
  };

  const deleteAttendant = (target = '', user = '') => {
    if (!updatingAttendant) {
      setUpdatingAttendant(true);

      api
        .delete(`helpdesk/attendants`, {
          params: {
            target,
            user,
          },
        })
        .then((response: any) => {
          setUpdatingAttendant(false);

          getAttendants(selectedTarget);
        })
        .catch((error: any) => {
          let msg = error?.response?.data?.message;

          if (msg === 'access_denied') msg = 'Acesso Negado.';
          else if (msg === 'category_duplicate') msg = 'Já existe um cadastro com essa descrição.';
          else if (msg === 'category_not_found') msg = 'Não foi encontrado o cadastro.';
          else if (msg === 'update_error') msg = 'Erro na atualização do castrado.';
          else if (msg === 'invalid_id') msg = 'ID inválido.';
          else
            msg = `Erro desconhecido: ${
              error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
            }`;

          setUpdatingAttendant(false);

          setSnackbarType('error');
          setSnackbarMsg(msg);
          setOpenSnackbar(true);
        });
    }
  };

  const insertAttendant = async (target = '', user = '') => {
    if (!updatingAttendant) {
      const params = {
        target,
        user,
      };

      setUpdatingAttendant(true);
      try {
        await api.put(`helpdesk/attendants`, params);
        setUpdatingAttendant(false);
        getAttendants(selectedTarget);
        setInputAttendantData([]);
      } catch (error) {
        let msg = error?.response?.data?.message;

        if (msg === 'access_denied') msg = 'Acesso Negado.';
        else if (msg === 'target_id') msg = 'É necessário informar um setor válido.';
        else if (msg === 'attendant_duplicate') msg = 'Atendente já cadastrado';
        else if (msg === 'insert_error') msg = 'Erro ao inserir o castrado.';
        else
          msg = `Erro desconhecido: ${
            error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message
          }`;

        setUpdatingAttendant(false);
        setSnackbarType('error');
        setSnackbarMsg(msg);
        setOpenSnackbar(true);
      }
    }
  };
  const handleListTargetClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    let newIndex = index;
    setSelectedCategory(0);
    setInputCategoryText('');

    setSelectedSubcategory(0);
    setInputSubcategoryText('');

    setInputAttendantText('');
    if (selectedTarget === newIndex) {
      newIndex = -1;
      setInputTargetAdd(true);
      setInputTargetText('');
    } else {
      setInputTargetAdd(false);
      setInputTargetText(targets.filter(target => target.id === newIndex)[0].description);
    }

    setSelectedTarget(newIndex);

    getAttendants(newIndex);
    getCategory(newIndex);
    setSubcategory([]);
  };
  const handleListCategoryClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    let newIndex = index;

    if (selectedCategory === newIndex) {
      newIndex = -1;
      setInputCategoryAdd(true);
      setInputCategoryText('');
    } else {
      setInputCategoryAdd(false);
      setInputCategoryText(categorys.filter(category => category.id === newIndex)[0].description);
    }

    setSelectedCategory(newIndex);

    setSelectedSubcategory(0);
    setInputSubcategoryText('');

    setInputAttendantText('');

    getSubcategory(newIndex);
  };
  const handleListSubcategoryClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    let newIndex = index;

    if (selectedSubcategory === newIndex) {
      newIndex = -1;
      setInputSubcategoryAdd(true);
      setInputSubcategoryText('');
      setInputSubcategoryDay('');
    } else {
      const subcategory = subcategorys.filter(subcategory => subcategory.id === newIndex)[0];
      setInputSubcategoryAdd(false);
      setInputSubcategoryText(`${subcategory.description}`);
      setInputSubcategoryDay(`${subcategory.days}`);
    }

    setInputAttendantText('');
    setSelectedSubcategory(newIndex);
  };

  const handleUpdateTarget = (target: TargetProps) => {
    updateTarget(target);
    setInputTargetText('');
  };
  const handleUpdateCategory = (category: CategoryProps) => {
    updateCategory(category);
    setInputCategoryText('');
  };
  const handleUpdateSubcategory = (subcategory: SubcategoryProps) => {
    updateSubcategory(subcategory);
    setInputSubcategoryText('');
    setInputSubcategoryDay('');
  };

  const handleInputTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTargetText(event.target.value);
  };
  const handleInputCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCategoryText(event.target.value);
  };
  const handleInputSubcategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSubcategoryText(event.target.value);
  };
  const handleInputSubcategoryDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSubcategoryDay(event.target.value);
  };
  const handleInputAttendantChange = (value: string) => {
    setInputAttendantText(value);
  };

  const handleAddTarget = () => {
    if (inputTargetText?.length < 1) {
      return;
    }

    setInputTargetText('');
    setInputTargetAdd(true);
    insertTarget(inputTargetText);
  };
  const handleEditTarget = () => {
    if (inputTargetText?.length < 1) {
      return;
    }
    const target: TargetProps = {
      id: selectedTarget,
      description: inputTargetText,
      situation: targets.filter(target => target.id === selectedTarget)[0].situation,
    };
    setSelectedTarget(-1);
    setInputTargetText('');
    setInputTargetAdd(true);
    updateTarget(target);
  };

  const handleAddCategory = () => {
    if (inputCategoryText?.length < 1) {
      return;
    }

    setInputCategoryText('');
    setInputCategoryAdd(true);
    insertCategory(inputCategoryText);
  };
  const handleEditCategory = () => {
    if (inputCategoryText?.length < 1) {
      return;
    }
    const category: CategoryProps = {
      id: selectedCategory,
      description: inputCategoryText,
      situation: categorys.filter(category => category.id === selectedCategory)[0].situation,
    };
    setInputCategoryText('');
    setSelectedCategory(-1);
    setInputCategoryAdd(true);
    updateCategory(category);
  };

  const handleAddSubcategory = () => {
    if (inputSubcategoryText?.length < 1 || Number(inputSubcategoryDay) < 1) {
      return;
    }

    setInputSubcategoryText('');
    setInputSubcategoryDay('');
    setInputSubcategoryAdd(true);
    insertSubcategory(inputSubcategoryText, inputSubcategoryDay);
  };
  const handleEditSubcategory = () => {
    if (inputSubcategoryText?.length < 1 || Number(inputSubcategoryDay) < 1) {
      return;
    }

    const subcategory: SubcategoryProps = {
      id: selectedSubcategory,
      description: inputSubcategoryText,
      days: Number(inputSubcategoryDay),
    };
    setInputSubcategoryText('');
    setInputSubcategoryDay('');
    setSelectedSubcategory(-1);
    setInputSubcategoryAdd(true);
    updateSubcategory(subcategory);
  };

  const handleDeleteAttendant = (target = 0, user = 0) => {
    if (target < 1 || user < 1) {
      return;
    }

    deleteAttendant(target?.toString(), user?.toString());
  };
  const handleAddAttendant = async () => {
    for await (const attendant of inputAttendantData) {
      const user = attendant.id;
      const target = selectedTarget;

      if (user === undefined || user === '0' || selectedTarget < 1) {
        return;
      }
      await insertAttendant(target?.toString(), user);
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const ListDataLoading = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress disableShrink />
      </Box>
    );
  };

  return (
    <Box>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.gridItemRoot}>
              <Typography>SETORES</Typography>
              <Box className={classes.boxList}>
                {gettingTargets ? (
                  <ListDataLoading />
                ) : (
                  <List component="nav" aria-label="target">
                    {targets?.map((target: TargetProps, index) => {
                      return (
                        <ListItem
                          key={index}
                          button
                          selected={selectedTarget === target.id}
                          onClick={event => handleListTargetClick(event, target.id)}
                        >
                          <ListItemText primary={`${target.description}`} />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={() => {
                                handleUpdateTarget({
                                  id: target.id,
                                  situation: target.situation === 1 ? 0 : 1,
                                  description: target.description,
                                });
                              }}
                              checked={target.situation === 1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
              <Box display="flex" justifyContent="center" flexDirection="row">
                <TextField
                  id="input-target"
                  label="Descrição"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={inputTargetText}
                  onChange={handleInputTargetChange}
                />
                <IconButton
                  edge="end"
                  aria-label="save"
                  style={{ marginLeft: 4, width: 40 }}
                  size="small"
                  disabled={inputTargetText?.length < 1}
                  onClick={inputTargetAdd ? handleAddTarget : handleEditTarget}
                >
                  {inputTargetAdd ? <AddIcon /> : <CheckIcon />}
                </IconButton>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.gridItemRoot}>
              <Typography>ATENDENTES</Typography>
              <Box className={classes.boxList}>
                {gettingAttendants ? (
                  <ListDataLoading />
                ) : (
                  <List component="nav" aria-label="target">
                    {attendants?.map((attendant: AttendantProps, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText primary={`${attendant.attendants_name}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              color="secondary"
                              onClick={() => handleDeleteAttendant(attendant.target_id, attendant.attendants_id)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
              <Box display="flex" justifyContent="center" flexDirection="row">
                <InputSearchAsync
                  fullWidth
                  label="Atentende (+)"
                  source="intranet-users"
                  value={inputAttendantData}
                  onChange={(e: ValueProps[]) => {
                    setInputAttendantData(e);
                  }}
                  disabled={selectedTarget < 1}
                />

                <IconButton
                  edge="end"
                  aria-label="save"
                  style={{ marginLeft: 4, width: 40 }}
                  size="small"
                  disabled={!inputAttendantData || inputAttendantData.length === 0}
                  onClick={handleAddAttendant}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.gridItemRoot}>
              <Typography>CATEGORIAS</Typography>
              <Box className={classes.boxList}>
                {gettingCategory ? (
                  <ListDataLoading />
                ) : (
                  <List component="nav" aria-label="category">
                    {categorys?.map((category: CategoryProps, index) => {
                      return (
                        <ListItem
                          key={index}
                          button
                          selected={selectedCategory === category.id}
                          onClick={event => handleListCategoryClick(event, category.id)}
                        >
                          <ListItemText primary={`${category.description}`} />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={() => {
                                handleUpdateCategory({
                                  id: category.id,
                                  situation: category.situation === 1 ? 0 : 1,
                                  description: category.description,
                                });
                              }}
                              checked={category.situation === 1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
              <Box display="flex" justifyContent="center" flexDirection="row">
                <TextField
                  id="input-category"
                  label="Descrição"
                  variant="outlined"
                  disabled={selectedTarget < 1}
                  size="small"
                  fullWidth
                  value={inputCategoryText}
                  onChange={handleInputCategoryChange}
                />
                <IconButton
                  edge="end"
                  aria-label="save"
                  style={{ marginLeft: 4, width: 40 }}
                  size="small"
                  disabled={inputCategoryText?.length < 1}
                  onClick={inputCategoryAdd ? handleAddCategory : handleEditCategory}
                >
                  {inputCategoryAdd ? <AddIcon /> : <CheckIcon />}
                </IconButton>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.gridItemRoot}>
              <Typography>SUBCATEGORIAS</Typography>
              <Box className={classes.boxList}>
                {gettingSubcategory ? (
                  <ListDataLoading />
                ) : (
                  <List component="nav" aria-label="target">
                    {subcategorys?.map((subcategory: SubcategoryProps, index) => {
                      return (
                        <ListItem
                          key={index}
                          button
                          selected={selectedSubcategory === subcategory.id}
                          onClick={event => handleListSubcategoryClick(event, subcategory.id)}
                        >
                          <ListItemText primary={`${subcategory.description}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="lock"
                              style={{ marginRight: 4 }}
                              onClick={() => {
                                handleUpdateSubcategory({
                                  id: subcategory.id,
                                  private: subcategory.private === 1 ? 0 : 1,
                                });
                              }}
                              size="large"
                            >
                              {subcategory.private === 0 ? (
                                <LockOpenIcon color="disabled" />
                              ) : (
                                <LockIcon color="secondary" />
                              )}
                            </IconButton>
                            <Switch
                              edge="end"
                              onChange={() => {
                                handleUpdateSubcategory({
                                  id: subcategory.id,
                                  situation: subcategory.situation === 1 ? 0 : 1,
                                  description: subcategory.description,
                                });
                              }}
                              checked={subcategory.situation === 1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>

              <Box display="flex" justifyContent="center" flexDirection="row">
                <TextField
                  id="input-subcategory-description"
                  label="Descrição"
                  variant="outlined"
                  disabled={selectedCategory < 1}
                  size="small"
                  fullWidth
                  value={inputSubcategoryText}
                  onChange={handleInputSubcategoryChange}
                />
                <TextField
                  id="input-subcategory-days"
                  label="Dias"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 1, max: 999 }}
                  disabled={selectedCategory < 1}
                  size="small"
                  fullWidth
                  value={inputSubcategoryDay}
                  onChange={handleInputSubcategoryDayChange}
                  style={{ maxWidth: 78, marginLeft: 4 }}
                />
                <IconButton
                  edge="end"
                  aria-label="save"
                  style={{ marginLeft: 4, width: 40 }}
                  size="small"
                  disabled={inputSubcategoryText?.length < 1 || Number(inputSubcategoryDay) < 1}
                  onClick={inputSubcategoryAdd ? handleAddSubcategory : handleEditSubcategory}
                >
                  {inputSubcategoryAdd ? <AddIcon /> : <CheckIcon />}
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
