import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    filterRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 16,
      padding: 16,
      marginBottom: 16,
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 16,
    },
    inputDepositSearch: {},
    gridContainer: { width: '100%', height: '100%' },
    table: {
      minWidth: 750,
      backgroundColor: darkMode ? '#383838' : '#f7f7f7',
    },
    tableHead: {
      fontWeight: 'bold',
      '& > tr > th': {
        backgroundColor: darkMode ? '#1d1d1d' : '#b1b1b1',
      },
    },
    tableRoot: {
      width: '100%',
    },
    tablePaper: {
      height: 'calc(100vh - 172px)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
      padding: 16,
      position: 'relative',
    },
    loadingTablePaper: {
      height: 'calc(100vh - 336px)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableContainer: {
      width: '100%',
      height: '100%',
      borderRadius: 4,
      overflow: 'auto',
      backgroundColor: darkMode ? '#5d5d5d' : '#fdfdfd',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: darkMode ? '#353535' : '#d2d2d2',
    },
    tableBody: {},
    tableVisuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableLoading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 100,
      top: 0,
      left: 0,
      opacity: 0.8,
      borderRadius: 4,
      backgroundColor: darkMode ? 'rgba(49, 49, 49, 0.8)' : 'rgba(255, 255, 255, 0.8)',
    },
    liquidRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      gap: 16,
      padding: 16,
    },
    liquidTitle: {
      fontSize: 18,
    },
    liquidContainer: {
      margin: '0 auto',
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    loadingText: {
      fontWeight: 'bold',
      color: darkMode ? '#d0d0d0' : '#9c9c9c',
      fontSize: 16,
    },
    leadTime: {
      fontSize: '0.875rem',
    },
    leadTimeDays: {
      marginLeft: 4,
      fontSize: '0.675rem',
      lineHeight: 0.5,
    },
    leadTimeLastYear: {
      fontSize: 11,
    },
    leadTimeLastYearIcon: {
      fontSize: 19,
    },
    blueColor: {
      color: darkMode ? '#7dabff' : '#204fa7',
    },
    redColor: {
      color: darkMode ? '#e57373' : '#f44336',
    },
    greenColor: {
      color: darkMode ? '#9ae573' : '#377d12',
    },
  });
});
