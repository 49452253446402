import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Tab,
  TableContainerProps,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { ColumnType, DataTable, DataTableProps, RowType } from '../../../../../components/DataTable';

import api from '../../../../../services/api';

type ResponseData = {
  search: {
    limit: number;
    page: number;
    total_data: number;
    total_pages: number;
  };
  data: RequestType[];
};

type RequestType = {
  request_id: number;
  request_seq: number;
  product_id: string;
  product_name: string;
  derivation_id: string;
  derivation_name: string;
  um_id: string;
  qtd_resquest: number;
  qtd_approved: number;
  qtd_met: number;
  qtd_canceled: number;
  request_situation_id: number;
  ccu_id: string;
  ccu_description: string;
  request_situation_des: string;
  user_request_id_erp: string;
  user_request_id: number;
  user_request_nickname: string;
  created_at: string;
  created_at_formated: string;
  user_approval_id_erp: string;
  user_approval_id: number;
  user_approval_nickname: string;
  approval_at: string;
  approval_at_formated: string;
  user_attended_id_erp: string;
  user_attended_id: number;
  user_attended_nickname: string;
  attended_at: string;
  attended_at_formated: string;
  user_cancelled_id_erp: string;
  user_cancelled_id: number;
  user_cancelled_nickname: string;
  cancelled_at: string;
  cancelled_at_formated: string;
};

interface IRequestsList extends DataTableProps {
  pending?: boolean;
  attended?: boolean;
}
type ResquestListType = Omit<IRequestsList, 'columns' | 'rows'>;

function RequestsList({ pending, attended, ...props }: ResquestListType) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>('erp/stock/requisition', {
        params: {
          own: true,
          pending,
          attended,
          limit: 5,
        },
      });
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };
  const columnSize = (size: number | string) => ({ minWidth: size, width: size, maxWidth: size }) as CSSProperties;

  const columns: ColumnType[] = [
    { id: 'request', content: 'Nº / Seq.', style: { ...columnSize(125) }, align: 'right' },
    { id: 'situation', content: 'Situação', style: { ...columnSize(96) }, align: 'center' },
    { id: 'product', content: 'Cód. / Des. Produto', style: { width: '100%', minWidth: 180 }, align: 'left' },
    { id: 'derivation', content: 'Cód. / Des. Derivação', style: { width: '100%', minWidth: 180 }, align: 'left' },
    { id: 'qtd_request', content: 'Qtd. Sol.', style: { ...columnSize(96) }, align: 'right' },
    { id: 'qtd_approved', content: 'Qtd. Apr.', style: { ...columnSize(96) }, align: 'right' },
    { id: 'qtd_met', content: 'Qtd. Ate.', style: { ...columnSize(96) }, align: 'right' },
    { id: 'created_at', content: 'Criado em', style: { ...columnSize(140) }, align: 'center' },
    { id: 'approval_at', content: 'Data Aprovação', style: { ...columnSize(140) }, align: 'center' },
    { id: 'attended_at', content: 'Data Atendimento', style: { ...columnSize(140) }, align: 'center' },
  ];

  const rows: RowType[] = responseData?.data?.map(req => {
    return {
      cells: [
        { content: `${req.request_id} - ${req.request_seq}`, align: 'right' },
        { content: `${req.request_situation_des}`, align: 'center' },
        { content: `${req.product_id} - ${req.product_name}`, align: 'left' },
        { content: `${req.derivation_id} - ${req.derivation_name}`, align: 'left' },
        { content: `${req.qtd_resquest} (${req.um_id})`, align: 'right' },
        { content: `${req.qtd_approved} (${req.um_id})`, align: 'right' },
        { content: `${req.qtd_met} (${req.um_id})`, align: 'right' },
        {
          content: `${req.created_at_formated === '31/12/1900 00:00' ? ' - ' : req.created_at_formated}`,
          align: 'center',
        },
        {
          content: `${req.approval_at_formated === '31/12/1900 00:00' ? ' - ' : req.approval_at_formated}`,
          align: 'center',
        },
        {
          content: `${req.attended_at_formated === '31/12/1900 00:00' ? ' - ' : req.attended_at_formated}`,
          align: 'center',
        },
      ],
    } as RowType;
  });

  const renderTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        sx={{ width: '100%', overflow: 'auto' }}
        loading={fetching}
        variant="primary"
        minHeight={96}
        {...props}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );

  const OsSearchPage = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        {renderTable}

        <Box sx={{ display: 'flex', gap: 2, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="caption" color={theme.palette.text.secondary}>{`Mostrando ${
            responseData?.data?.length || 0
          } de ${responseData?.search?.total_data || 0}`}</Typography>
          <Box sx={{ flex: 1 }} />

          <Button color="warning" variant="outlined" component={Link} to="/erp/stock/request?view=search&filter=own">
            Ver todas
          </Button>
        </Box>
      </Box>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetching],
  );

  useEffect(() => {
    getOsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>{OsSearchPage}</Box>;
}
export { RequestsList };
