import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  InputAdornment,
} from '@mui/material';

import { Alert } from '@mui/lab';
import api from '../../services/api';
import { toNumber } from '../../utils/functions';

type LoginErpDialogProps = {
  show: boolean;
  force?: boolean;
  onClose?: (erp_id?, erp_login?) => void;
};
export default function LoginErpDialog(props: LoginErpDialogProps) {
  const { show = false, force = false, onClose } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [changingUser, setChangingUser] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  async function handleChangePassword() {
    if (!changingUser) {
      try {
        const { data } = await api.post('erp/auth', {
          user,
          password,
        });
        const erp_id = toNumber(data?.erp_id, -1);
        const erp_login = data?.erp_login;

        if (erp_id > 0) {
          onClose(erp_id, erp_login);
          setShowDialog(false);
          setChangingUser(false);
          setUser('');
          setPassword('');
        } else {
          setSnackbarType('error');
          setSnackbarMsg('Usuário inválido, verifique.');
          setOpenSnackbar(true);
          setChangingUser(false);
        }
      } catch (error: any) {
        let msg = error?.response?.data?.message;
        if (!msg) msg = error?.response?.data;
        else if (msg === 'invalid') msg = 'Senha ou usuário inválido, verifique.';
        else if (msg === 'user_not_found') msg = 'Usuário inválido, verifique.';
        else msg = '';
        setSnackbarType('error');
        setSnackbarMsg(msg);
        setOpenSnackbar(true);
        setChangingUser(false);
      }
    }
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  function handleLoginErpDialogClose() {
    if (force || changingUser) {
      return;
    }
    onClose?.();
    setShowDialog(false);
  }

  function handleUserChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUser(event.target.value);
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function userCheck() {
    if (user.trim()?.length === 0) {
      return 'O usuário não pode estar em branco';
    }
    return '';
  }

  function passwordCheck() {
    if (password.trim()?.length === 0) {
      return 'A senha não pode estar em branco';
    }

    return '';
  }

  const disableButton = () => {
    if (changingUser === true) return true;

    if (userCheck() !== '') return true;
    if (passwordCheck() !== '') return true;

    return false;
  };
  return (
    <Dialog open={showDialog} onClose={handleLoginErpDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Autenticar no ERP</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>
          Para realizar a autenticação no erp, digite o seu usuário e senha a baixo e clique em AUTENTICAR
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="user-erp"
          label="Usuário ERP"
          fullWidth
          value={user}
          onChange={handleUserChange}
          error={userCheck() !== ''}
          helperText={userCheck()}
          disabled={changingUser}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />

        <TextField
          margin="dense"
          id="password-erp"
          label="Senha"
          type="password"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          error={passwordCheck() !== ''}
          helperText={passwordCheck()}
          disabled={changingUser}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
      </DialogContent>
      <DialogActions>
        {!force && (
          <Button onClick={handleLoginErpDialogClose} disabled={changingUser} color="inherit">
            Cancelar
          </Button>
        )}
        <Button onClick={handleChangePassword} color="primary" disabled={disableButton()}>
          Autenticar
        </Button>
      </DialogActions>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
