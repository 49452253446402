import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import api from '../../../../../services/api';
import { FeedbackProps } from '../../../../../components/FeedbackAlert';
import { humanFileSize } from '../../../../../utils/functions';

interface Props {
  id: number | string;
  open: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
}

type AttachmentType = {
  id: number;
  file_name: string;
  url: string;
  uploaded_by: number;
  uploaded_by_name: string;
  uploaded_at: string;
  file_type: string;
  file_size: number;
  uploaded_at_formated: string;
  file_size_formated: string;
};
type ReturnType = {
  attachments: AttachmentType[];
  situation: string;
};

function OsAttachs({ id, open, handleClose, handleOpen }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  let totalSize = 0;
  const [osAttachments, setOsAttachments] = useState<AttachmentType[]>([] as AttachmentType[]);
  const [fetching, setFetching] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const refInputAttachment = useRef<HTMLInputElement | null>(null);
  const [submiting, setSubmiting] = useState(false);
  const [osSituation, setOsSituation] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const getOsAttachments = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ReturnType>(`erp/engineering/os/${id}`, {
        params: { type: 'all' },
      });
      setOsSituation(data?.situation);
      setOsAttachments(data?.attachments ?? []);
    } catch (error) {}
    setFetching(false);
  };

  const handleOnCloseDialog = () => {
    handleClose?.();
  };

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachments;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of event.target.files) {
          newAttachments.push(attachment);
        }
      } catch (err) {
        console.error(err);
      }

      setAttachments([...newAttachments]);
    }
  };

  const handleRemoveAllAttachments = () => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    setAttachments([]);
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachments;
    newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);
  };

  const handleSubmit = async () => {
    if (submiting) return;
    setSubmiting(true);

    const data = new FormData();
    attachments.map(attachment => data.append('files', attachment));

    try {
      const { data: os } = await api.put(`erp/engineering/os/${id}/attachments`, data);
      handleRemoveAllAttachments();
      getOsAttachments();
    } catch (error) {
      const { data: response } = error.response;
      setErrorMessage(response.message ?? '');
    }

    setSubmiting(false);
  };

  const inputAttachment = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography sx={{ fontSize: 12 }}>Enviar arquivos</Typography>

        <Box
          sx={{
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
              border: `1px solid`,
            },
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            p: 1,
            maxHeight: 128,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              flex: 1,
              height: '100%',
            }}
          >
            {attachments?.length === 0 ? (
              <Box sx={{ p: 0.5 }}>
                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                  Nenhum arquivo informado
                </Typography>
              </Box>
            ) : (
              attachments?.map((attachment: File, index: number) => {
                const nameSplit = attachment.name.toLocaleLowerCase().split('.');
                const name = attachment.name.toLocaleLowerCase();
                const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
                const size = humanFileSize(attachment.size);
                const key = `${index}-${name}-${type}-${size}`;
                totalSize += attachment.size;
                return (
                  <Box key={key} sx={{ display: 'flex', gap: 1, flex: 1, alignItems: 'center' }}>
                    <Tooltip title={submiting ? '' : 'Remover arquivo'} arrow disableInteractive>
                      <IconButton
                        color="error"
                        aria-label="remove file"
                        component="label"
                        size="small"
                        onClick={() => handleRemoveAttachments(index)}
                        disabled={submiting}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                      {name} ({size})
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {attachments?.length > 0 && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption">{`${attachments?.length} ${
                attachments?.length === 1 ? 'arquivo selecionado' : 'arquivos selecionados'
              } (${humanFileSize(totalSize)})`}</Typography>
            </Box>
          )}
          <Tooltip title={attachments?.length === 0 || submiting ? '' : 'Remover todos os anexos'}>
            <IconButton
              color="error"
              aria-label="remove files"
              component="label"
              size="small"
              disabled={attachments?.length === 0 || submiting}
              onClick={() => handleRemoveAllAttachments()}
            >
              <DeleteSweepOutlinedIcon fontSize="small" sx={{ width: 28, height: 22 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={submiting ? '' : 'Selecionar arquivo'}>
            <IconButton color="primary" aria-label="upload picture" component="label" size="small" disabled={submiting}>
              <input
                style={{ display: 'none' }}
                id="attachment-input"
                hidden
                type="file"
                multiple
                ref={refInputAttachment}
                onChange={handleAddAttachments}
              />
              <NoteAddOutlinedIcon fontSize="small" sx={{ width: 28, height: 22 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={attachments?.length === 0 || submiting ? '' : 'Enviar os arquivos'}>
            <Button
              variant="outlined"
              color="success"
              disabled={attachments?.length === 0 || submiting}
              endIcon={<UploadFileOutlinedIcon fontSize="medium" sx={{ width: 28, height: 22 }} />}
              onClick={() => handleSubmit()}
            >
              Enviar
            </Button>
          </Tooltip>
        </Box>
        {errorMessage.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Typography sx={{ color: isDarkMode ? '#fd8c8c' : '#ff0505' }}>{errorMessage}</Typography>
          </Box>
        )}
      </Box>
    ),
    [attachments, submiting],
  );
  useEffect(() => {
    if (open) {
      handleOpen?.();
      getOsAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md">
      <DialogTitle>
        O.S - Anexos
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleOnCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
          minHeight: 200,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 1,
            p: 1,
            overflowY: 'auto',
            height: 'calc(100vh - 220px)',
          }}
        >
          {fetching ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                flexGrow: 1,
                alignItems: 'center',
              }}
            >
              <Typography color={theme.palette.text.secondary}>Carregando...</Typography>
            </Box>
          ) : osAttachments?.length > 0 ? (
            osAttachments.map(attach => {
              return (
                <Box
                  key={attach.id}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    gap: 1,
                    bgcolor: isDarkMode ? '#3a3a3a' : '#fff',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    border: `1px solid ${isDarkMode ? '#282828' : '#3a3a3a'}`,
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      width: '100%',
                      gap: 1,
                      p: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      {attach.file_name} ({attach.file_size_formated})
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: theme.palette.text.secondary }}>
                      {attach.uploaded_by_name} - {attach.uploaded_at_formated}
                    </Typography>
                  </Box>

                  <Button
                    component="a"
                    href={attach.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="warning"
                    sx={{
                      minWidth: 0,
                    }}
                  >
                    <FileDownloadOutlinedIcon fontSize="medium" />
                  </Button>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                flexGrow: 1,
                alignItems: 'center',
              }}
            >
              <Typography color={theme.palette.text.secondary}>Nenhum anexo encontrado</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      {['not_started', 'not_started_late', 'paused', 'paused_late', 'started', 'started_late'].includes(
        osSituation,
      ) && (
        <Box
          sx={{
            bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
            m: 1,
            mt: 0,
            borderRadius: 1,
            borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
            borderStyle: 'solid',
            borderWidth: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 1,
          }}
        >
          {inputAttachment}
        </Box>
      )}
    </Dialog>
  );
}
export { OsAttachs };
