import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    rootComments: {
      width: '100%',
      maxWidth: 1080,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    newsTitle: {
      textDecoration: 'none',
      color: darkMode ? '#fafafa' : '#383838',
      '&:hover': {
        color: darkMode ? '#d6d6d6' : '#616161',
      },
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },

    commentRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      marginLeft: 16,
      marginRight: 16,
      marginTop: 16,
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 16,
      },
    },
    commentTitle: {
      color: darkMode ? '#ababab' : '#616161',
      marginBottom: 8,
    },
    newCommentRoot: {
      margin: 8,
      marginLeft: 12,
      marginRight: 2,
      padding: 6,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    newCommentInput: {
      backgroundColor: darkMode ? '#585858' : '#ffffff',
      marginRight: 8,
    },
    newCommentSendButton: {},
    newCommentSendButtonDisabled: {
      margin: 12,
    },
    commentContainer: {
      padding: 6,
    },
  });
});
