import React, { useState, useEffect } from 'react';
import { Backdrop, Button, Icon, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from './styles';
import serverDownImg from '../../../images/server_down.svg';

let interval: NodeJS.Timeout;

export default function Offline() {
  const classes = styles();

  return (
    <Backdrop className={classes.root} open transitionDuration={100}>
      <img src={serverDownImg} alt="Server Down" className={classes.img} />

      <Typography variant="h6" className={classes.text}>
        Não foi possivel se conectar ao servidor, aguarde uns segundos e tente novamente.
      </Typography>
      <div className={classes.button}>
        <Button
          style={{ height: 40, width: 230 }}
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          TENTAR NOVAMENTE
        </Button>
      </div>
    </Backdrop>
  );
}
