import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

import { Pagination } from '@mui/lab';
import Axios, { Canceler } from 'axios';
import { Link } from 'react-router-dom';
import styles from './styles';

import api from '../../../../../services/api';
import { ValueProps } from '../../../../../components/inputs/InputSearchAsync';

type OrdersTablePorps = {
  filter: FiltersType;
  onDataLoaded?: (data: OrdersData) => void;
};

export type FiltersType = {
  result_limit?: number;
  page_current?: number;

  created_at_start?: string;
  created_at_end?: string;
  deadline_start?: string;
  deadline_end?: string;

  order_by?: OrderByType;
  order_dir?: OrderDirType;
  order_id?: ValueProps[];
  custumer?: ValueProps[];
  situation?: ValueProps[];
  item?: ValueProps[];
};

type OrdersData = {
  results: OrderType[];
  filter: {
    result_limit: number;
    page_current: number;
    item: string;
  };
  statistics: {
    total: {
      results: number;
      pages: number;
    };
  };
};

type ResponseDataType = {
  results: OrderType[];
  filter: {
    result_limit: number;
    page_current: number;
    order: string[];
    id: string[];
    situation: string[];
    show_items: boolean;
    created_at: string[];
  };
  statistics: {
    total: {
      results: number;
      pages: number;
      messages: number;
    };
  };
};

type OrderType = {
  numped: string;
  codcli: string;
  nomcli: string;
  codcpg: string;
  descpg: string;
  tipped: string;
  tipped_description: string;
  datemi: string;
  datemi_formated: string;
  horemi: string;
  horemi_formated: string;
  datprv: string;
  datprv_formated: string;
  horprv: string;
  horprv_formated: string;
  usuger: string;
  usuger_name: string;
  codrep: string;
  nomrep: string;
  sitped: string;
  sitped_description: string;
  cgccpf: string;
  insest: string;
  insmun: string;
  endcli: string;
  nencli: string;
  baicli: string;
  cidcli: string;
  sigufs: string;
  cplend: string;
  cepcli: string;
  foncli: string;
  foncl2: string;
  foncl3: string;
  foncl4: string;
  foncl5: string;
  intnet: string;
};

type OrderByType = 'number' | 'client' | 'situation' | 'created_at' | 'deadline_date';
type OrderDirType = 'asc' | 'desc';
export default function OrdersTable(props: OrdersTablePorps) {
  const classes = styles();
  const { filter = {}, onDataLoaded = data => {} } = props;

  const [orderDir, setOrderDir] = useState<OrderDirType>(
    (localStorage.getItem('erp-orders-search-order-dir') || 'desc') as OrderDirType,
  );
  const [orderBy, setOrderBy] = useState<OrderByType>(
    (localStorage.getItem('erp-orders-search-order-by') as OrderByType) || 'number',
  );

  const [loadingData, setLoadingData] = useState(false);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [showExportDialog, setShowExportDialog] = useState(false);

  useEffect(() => {
    filter.page_current = page;
    filter.order_by = orderBy;
    filter.order_dir = orderDir;

    loadData({ filter });
  }, [filter, page, orderBy, orderDir]);

  async function loadData({ filter = {} as FiltersType }) {
    if (loadingData) return;
    let cancel: Canceler;
    setLoadingData(true);

    try {
      const params = {
        result_limit: filter?.result_limit || 20,
        page_current: filter?.page_current || 1,
        order: [filter?.order_by, filter?.order_dir],
        created_at: [filter?.created_at_start, filter?.created_at_end],
        deadline: [filter?.deadline_start, filter?.deadline_end],
        order_id: filter?.order_id,
        item: filter?.item,
        custumer: filter?.custumer,
        situation: filter?.situation,
        show_items: 'false',
        return_type: 'compact',
      };

      const response = await api.get('erp/orders', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      const data = response?.data as OrdersData;

      if (data) {
        setOrders(data.results);
        setPageTotal(data.statistics.total.pages);
        setOrdersTotal(data.statistics.total.results);
        onDataLoaded(data);
      }
    } catch (error: any) {}

    setLoadingData(false);
    return () => cancel();
  }

  const handlePagination = (event: any, p: number) => {
    if (p < 1 || p > pageTotal || p === page) return;
    setPage(p);
  };

  const handleSort = (index: OrderByType) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    localStorage.setItem('erp-orders-search-order-by', index);
    localStorage.setItem('erp-orders-search-order-dir', isAsc ? 'desc' : 'asc');
  };
  return (
    <Box className={classes.tableRoot}>
      <Paper className={classes.tablePaper}>
        {loadingData && (
          <Box className={classes.tableLoading}>
            <Typography className={classes.loadingText}>Carregando...</Typography>
          </Box>
        )}
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" sortDirection={orderBy === 'number' ? orderDir : false}>
                  <Tooltip title="Ordernar por Número" aria-label="orderby-number">
                    <TableSortLabel
                      active={orderBy === 'number'}
                      direction={orderDir}
                      onClick={() => {
                        handleSort('number');
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>NÚMERO</Typography>
                      {orderBy === 'number' ? <span className={classes.tableVisuallyHidden}>{orderDir}</span> : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Ordernar por Nome do Cliente" aria-label="orderby-client">
                    <TableSortLabel
                      active={orderBy === 'client'}
                      direction={orderDir}
                      onClick={() => {
                        handleSort('client');
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>CLIENTE</Typography>
                      {orderBy === 'client' ? <span className={classes.tableVisuallyHidden}>{orderDir}</span> : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Ordernar por Número da Situação" aria-label="orderby-situation">
                    <TableSortLabel
                      active={orderBy === 'situation'}
                      direction={orderDir}
                      onClick={() => {
                        handleSort('situation');
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>SITUAÇÃO</Typography>
                      {orderBy === 'situation' ? <span className={classes.tableVisuallyHidden}>{orderDir}</span> : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Ordernar por Data Geração" aria-label="orderby-created_at">
                    <TableSortLabel
                      active={orderBy === 'created_at'}
                      direction={orderDir}
                      onClick={() => {
                        handleSort('created_at');
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>DATA GERAÇÃO</Typography>
                      {orderBy === 'created_at' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Ordernar por Data Prevista" aria-label="orderby-deadline_date">
                    <TableSortLabel
                      active={orderBy === 'deadline_date'}
                      direction={orderDir}
                      onClick={() => {
                        handleSort('deadline_date');
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>DATA PREVISTA</Typography>
                      {orderBy === 'deadline_date' ? (
                        <span className={classes.tableVisuallyHidden}>{orderDir}</span>
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {orders.map((order: OrderType, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`order-${order.numped}-${index}`}>
                    <TableCell align="right" style={{ width: 128 }}>
                      {order.numped}
                      <IconButton
                        color="primary"
                        component={Link}
                        to={`/erp/orders/${order.numped}`}
                        style={{
                          marginLeft: 4,
                          width: 28,
                          height: 28,
                        }}
                        size="large"
                      >
                        <LaunchRoundedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 210 }}>
                      {order.codcli} - {order.nomcli}
                    </TableCell>
                    <TableCell align="left" style={{ width: 164 }}>
                      {order.sitped} - {order.sitped_description}
                    </TableCell>
                    <TableCell align="center" style={{ width: 128 }}>
                      {order.datemi_formated}
                    </TableCell>
                    <TableCell align="center" style={{ width: 128 }}>
                      {order.datprv_formated}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row" width="100%">
              <Box className={classes.paginationContainer}>
                <Pagination
                  size="medium"
                  count={pageTotal}
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  disabled={loadingData || pageTotal <= 1}
                  onChange={handlePagination}
                  siblingCount={0}
                />
                <Typography variant="caption" style={{ marginTop: 8 }}>
                  {`${ordersTotal || 0} Registros encontrados`}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
