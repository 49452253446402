import React, { useState } from 'react';
import {
  Tooltip,
  ResponsiveContainer,
  Legend,
  Pie,
  PieChart,
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';
import { useTheme } from '@mui/material/styles';

import { Box } from '@mui/material';

// import { ResponsiveLine } from '@nivo/line';

// import { Box } from '@mui/material';
import { blue, green, grey, orange, purple, red, teal, yellow } from '@mui/material/colors';
import styles from './styles';

type DataType = {
  name: string;
  value: number;
};

export interface PieChartProps {
  title: string;
  data: DataType[];
  height?: string | number;
  colorFill?: string;
  colorLabel?: string;
  maxValue?: number;
}
let darkMode = false;

export default function ProductionNote({
  title,
  data,
  height = '100%',
  colorFill = darkMode ? green[300] : green[600],
  colorLabel = darkMode ? grey[400] : grey[800],
  maxValue,
}: PieChartProps) {
  const classes = styles();
  const theme = useTheme();
  darkMode = theme.palette.mode === 'dark';
  function CustomizedXAxisTick(_props: any) {
    const { x, y, payload } = _props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={4}
          dy={0}
          fontSize={10}
          textAnchor="end"
          fill={darkMode ? grey[500] : grey[800]}
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltipChartContainer}>
          <span className={classes.tooltipChartTitle}>{`${label}`}</span>

          {payload[0] && (
            <div className={classes.tooltipChartPlaned}>
              <span>Helpdesk&apos;s:</span>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ textAlign: 'left', width: '100%', fontWeight: 'bold' }}>{`${payload[0]?.value}`}</span>
                {maxValue && (
                  <span style={{ textAlign: 'right', marginLeft: 8 }}>{`(${(
                    (payload[0]?.value / maxValue) *
                    100
                  ).toFixed(2)}%)`}</span>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Box width="100%" height={height} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box className={classes.chartBox}>
        <Box className={classes.chartTitle}>
          <span>{title}</span>
        </Box>
        <Box className={classes.chartContent}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ left: 0, right: 10, top: 20, bottom: Math.max(...data.map(el => el.name.length)) * 3 }}
            >
              <CartesianGrid stroke={darkMode ? grey[700] : grey[300]} />
              <Tooltip content={CustomTooltip} />

              <XAxis dataKey="name" allowDataOverflow={true} interval={0} tick={<CustomizedXAxisTick />} />
              <YAxis />
              <Bar dataKey="value" fill={colorFill} label={{ fill: colorLabel, position: 'top' }} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
}
