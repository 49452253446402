/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

import MarkdownEditor from '@uiw/react-markdown-editor';

import { format, addBusinessDays, isAfter } from 'date-fns';
// eslint-disable-next-line import/no-duplicates

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import InputSearchAsync, { ValueProps } from '../inputs/InputSearchAsync';

import api from '../../services/api';
import { toDate, toNumber } from '../../utils/functions';

type FormStateType = {
  searching: boolean;
};

type FieldType = {
  id: number;
  task_id: number;
  title: string;
  description: string;
  requirement: number;
  field_order: number;
  field_type: number;
  read_only: number;
  default_value: string;
  min_value: string;
  max_value: string;
  situation: number;
  json: string;
};

type RowType = {
  [key: string]: any;
};

interface FormBaseProps {
  title: string;
  open: boolean;
  columns: GridColDef[];
  rows: RowType[];
  onClose?: () => void;
  onSelect?: (rowsSelected: GridRowSelectionModel) => void;
  onOpen?: () => void;
}

function FormBase({ onClose, onOpen, onSelect, open, title, columns, rows }: FormBaseProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  const isDarkMode = theme.palette.mode === 'dark';

  const [formState, setFormState] = useState<FormStateType>({
    searching: false,
  });
  const [rowsSelected, setRowSelected] = useState<GridRowSelectionModel>([]);

  const handleOnCloseDialog = () => {
    if (formState.searching) return;
    onClose?.();
  };

  const handleOnSelecting = () => {
    if (formState.searching) return;
    onSelect?.(rowsSelected);
    handleOnCloseDialog();
  };

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleOnCloseDialog}
      fullWidth={true}
      maxWidth="md"
      fullScreen={sm}
      disableEscapeKeyDown
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            onRowSelectionModelChange={selection => {
              setRowSelected(selection);
            }}
            rowSelectionModel={rowsSelected}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleOnCloseDialog}>
          Cancelar
        </Button>
        <Box sx={{ display: 'flex', flex: 1 }} />
        <Button color="info" variant="contained" onClick={handleOnSelecting} disabled={formState.searching}>
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { FormBase };
