import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, green, grey, orange, red, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    tableTitle: {
      color: darkMode ? orange[100] : orange[900],
      paddingBottom: theme.spacing(3),
    },
    tableContainer: {
      minHeight: 280,
      height: 'calc(100vh - 328px)',
      overflowY: 'auto',
    },
    table: {
      width: '100%',
      overflow: 'scroll',
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    greenColor: {
      color: darkMode ? green[200] : green[900],
    },
    redColor: {
      color: darkMode ? red[200] : red[900],
    },
    yellowColor: {
      color: darkMode ? yellow[200] : yellow[900],
    },
    blueColor: {
      color: darkMode ? blue[200] : blue[900],
    },
    createdAtText: {
      fontSize: 10,
    },
    deadlineDateText: {
      fontSize: 14,
    },
    diffDaysText: {
      fontSize: 10,
    },
  });
});
