import React, { useEffect, useState } from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';
import stc from 'string-to-color';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios, { Canceler } from 'axios';
import api from '../../../../services/api';
import MonthCalendar, { BookingType } from './MonthCalendar';

import { ProfileField } from '../../../../components/ProfileField';

type VacationType = {
  id: number;
  type: number;
  number: number;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  vacation_date_start: string;
  vacation_date_end: string;
  vacation_days: number;
  days_remaining: number;
  start: string;
  end: string;
};

export default function CalendarPage() {
  const theme = useTheme();
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const loadingText = 'Carregando...';

  const [year, setYear] = useState(2023);
  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<VacationType[]>('manager/vacations', {
          params: {
            year,
          },
          cancelToken: new axios.CancelToken(async c => {
            cancel = c;
          }),
        });

        const newBookings = response.data.map<BookingType>(data => {
          return {
            start: data.start,
            end: data.end,
            userID: data.id.toString(),
            userName: data.nickname,
            bgColor: stc(`${data.id}${data.vacation_days}${data.number}${data.number}`),
            days: data.vacation_days,
          };
        });

        setBookings(newBookings);

        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    getData();
  }, [year]);

  if (loadingData)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.text.secondary,
          }}
        >
          {loadingText}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{ display: 'flex', gap: 4, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4, flex: 1 }}>
        <Button
          onClick={() => {
            setYear(y => y - 1);
          }}
        >
          <NavigateBeforeIcon />
        </Button>
        <Typography variant="h3">{year}</Typography>
        <Button
          onClick={() => {
            setYear(y => y + 1);
          }}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
      {bookings.filter(f => f.start === '1969-01-01 00:00:00').length > 0 && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flex: 1,
            width: '100%',
          }}
        >
          <Typography>Sem programação</Typography>
          <Paper
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flex: 1,
              width: '100%',
              p: 2,
            }}
          >
            {bookings
              .filter((f, i, s) => f.start === '1969-01-01 00:00:00' && i === s.findIndex(t => t.userID === f.userID))
              .map(booking => {
                return (
                  <ProfileField key={booking.userID} userId={booking.userID} userName={booking.userName} size={24} />
                );
              })}
          </Paper>
        </Box>
      )}
      <Grid container spacing={2}>
        {months.map(month => {
          const date = `${year}-${month}-1 00:00:00`;
          return (
            <Grid key={date} xs={12} sm={12} md={6} lg={4} xl={3}>
              <MonthCalendar date={date} bookings={bookings} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
