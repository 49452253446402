import Axios from 'axios';

const api = Axios.create({
  // baseURL: 'http://intranet.imic.com.br:7779',
  // baseURL: 'https://127.0.0.1:7779',
  baseURL: process.env?.NODE_ENV === 'development' ? process.env.REACT_APP_URL_API_DEV : process.env.REACT_APP_URL_API,
});

api.interceptors.request.use(
  (config: any) => {
    const newConfig = config;
    const token = localStorage.getItem('token');
    newConfig.headers.Authorization = token ? `Bearer ${token}` : '';
    newConfig.headers.Page = window.location.pathname;
    return newConfig;
  },
  error => {
    if (error.response.status === 401) {
      window.location.href = '/authenticate';
      // localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export default api;
// api
// .get(
//   'authenticate/check',
//   {
//     headers: { Authorization: `Bearer ${token}` },
//   },
//   signal,
// )
// .then((response) => {
//   this.isValidToken = true;
//   cb(response);
// })
// .catch((error) => {
//   this.isValidToken = false;
//   cb(error.response);
// });
