import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Axios, { Canceler } from 'axios';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { Rating } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { green, grey } from '@mui/material/colors';
import styles from './styles';

import api from '../../../services/api';

import InputSearchDate from '../../../components/inputs/InputSearchDate';
import InputSearchComboBox from '../../../components/inputs/InputSearchComboBox';

import InputSearchAsync, { ValueProps } from '../../../components/inputs/InputSearchAsync';
import BarChart from './BarChart';
import { Loading } from '../../../components/Loading';

type CategoryType = {
  category_id: number;
  category_description: string;
  total: number;
};

type AttendantType = {
  user_id: number;
  attendance_name: string;
  total: number;
};

type OwnerType = {
  owner_id: number;
  owner_name: string;
  total: number;
};

type SectorType = {
  sector: string;
  total: number;
};

type ReprogramedHelpdeskType = {
  id: number;
  attendance_name: string;
  extend_days: number;
  extend_answer: number;
  extend_text: string;
};

type IndicatorsDataType = {
  data: {
    v1: {
      new_total: number;
      pending_total: number;
      attended_total: number;
      rate_total: number;
      out_date_helpdesk: OutDateHelpdeskType[];
    };
    v2: {
      new_total: number;
      attended_total: number;
      attended_extended_total: number;
      rate: number;
      attended_out_date: OutDateHelpdeskType[];
      reprogrammed: ReprogramedHelpdeskType[];
    };
    categorys: CategoryType[];
    attendants: AttendantType[];
    owners: OwnerType[];
    sectors: SectorType[];
  };
  filter: FilterType;
};

type FilterType = {
  model: string;
  target: number;
  version: string;
  date_start_formated: string;
  date_start: string;
  date_end_formated: string;
  date_end: string;
};

type OutDateHelpdeskType = {
  id: number;
  attendance_name: string;
  days_delay: number;
  feedback_delay: string;
};

export default function StatisticsPage() {
  let cancel: Canceler;
  const classes = styles();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const dateNow = new Date();
  const models = [
    {
      value: '0',
      text: 'Indicador Qualidade',
      name: 'indicators',
      version: [
        { value: '0', version: '1.0', text: '1.0', disabled: true },
        { value: '1', version: '2.0', text: '2.0', disabled: false },
      ],
    },
  ];

  const [loadingData, setLoadingData] = useState(false);
  const [filterExpanded, setFilterExpanded] = useState(true);
  const [model, setModel] = useState(0);
  const [version, setVersion] = useState(1);
  const [target, setTarget] = useState<ValueProps>();
  const [dateStart, setDateStart] = useState(dateNow.toISOString().split('T')[0]);
  const [dateEnd, setDateEnd] = useState(dateNow.toISOString().split('T')[0]);
  const [indicatorsData, setIndicatorsData] = useState<IndicatorsDataType>();

  const [sectors, setSectors] = useState<SectorType[]>([]);
  const [categorys, setCategorys] = useState<CategoryType[]>([]);
  const [attendants, setAttendants] = useState<AttendantType[]>([]);

  function getIndicatorsData() {
    if (loadingData) return;
    setLoadingData(true);
    cancel?.();

    const params = {
      model: models[model].name,
      version: models[model].version[version].version,
      target: target?.id,
      date_start: dateStart,
      date_end: dateEnd,
    };

    (async () => {
      const { data } = await api.get('helpdesk/statistics', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      if (model === 0) {
        setIndicatorsData(data);
        setSectors(data.data.sectors);
        setCategorys(data.data.categorys);
        setAttendants(data.data.attendants);
        setDateStart(data.filter.date_start);
        setDateEnd(data.filter.date_end);
      }
      setLoadingData(false);
    })();

    setLoadingData(false);
  }

  function handleFilterClick() {
    setFilterExpanded(false);
    getIndicatorsData();
  }

  const ResultInfo = (_props: { description: string; value: any; rate?: { value: number; max: number } }) => {
    const { description, value, rate = null } = _props;
    return (
      <Box className={classes.indicatorsDataContainer}>
        <Typography className={classes.indicatorsDataText}>{description}</Typography>
        <Typography className={classes.indicatorsDataValue}>{rate ? `${rate.value}/${rate.max}` : value}</Typography>
        {rate && (
          <Rating
            size="small"
            name="read-only"
            value={rate.value}
            precision={0.1}
            max={rate.max}
            readOnly
            className={classes.indicatorsDataValue}
          />
        )}
      </Box>
    );
  };

  const OutDateHelpdesk = (_props: { outDateHelpdesks: OutDateHelpdeskType[] }) => {
    const { outDateHelpdesks } = _props;
    if (!outDateHelpdesks || outDateHelpdesks.length === 0) {
      return <></>;
    }
    return (
      <Box>
        <Typography
          className={classes.indicatorsDataText}
          style={{
            fontWeight: 'bold',
            marginTop: 16,
            fontSize: 18,
          }}
        >
          HELPDESK&apos;S ATRASADOS:
        </Typography>
        <TableContainer className={classes.tableOutDateContainer}>
          <Table stickyHeader aria-label="helpdesk atrasados" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ width: 120, maxWidth: 120 }}>
                  Nº
                </TableCell>
                <TableCell style={{ width: 260, maxWidth: 330 }}>Atendente</TableCell>
                <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                  Atraso (Dias)
                </TableCell>
                <TableCell>Justificativa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outDateHelpdesks?.map(helpdesk => {
                return (
                  <TableRow tabIndex={-1} key={helpdesk.id}>
                    <TableCell align="right" style={{ width: 120, maxWidth: 120 }}>
                      <Typography
                        className={classes.indicatorsDataValueLink}
                        component={Link}
                        to={`/helpdesk/${helpdesk.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {helpdesk.id}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 260, maxWidth: 330 }}>{helpdesk.attendance_name}</TableCell>
                    <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                      {helpdesk.days_delay}
                    </TableCell>
                    <TableCell>{helpdesk.feedback_delay.replace(/<\/?[^>]+(>|$)/g, '')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  const ReprogrammedHelpdesk = (_props: { reprogrammedHelpdesks: ReprogramedHelpdeskType[] }) => {
    const { reprogrammedHelpdesks } = _props;
    if (!reprogrammedHelpdesks || reprogrammedHelpdesks.length === 0) {
      return <></>;
    }
    return (
      <Box>
        <Typography
          className={classes.indicatorsDataText}
          style={{
            fontWeight: 'bold',
            marginTop: 16,
            fontSize: 18,
          }}
        >
          HELPDESK&apos;S PRAZO ALTERADO:
        </Typography>
        <TableContainer className={classes.tableOutDateContainer}>
          <Table stickyHeader aria-label="helpdesk atrasados" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ width: 120, maxWidth: 120 }}>
                  Nº
                </TableCell>
                <TableCell style={{ width: 260, maxWidth: 330 }}>Atendente</TableCell>
                <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                  Qtd. Dias
                </TableCell>
                <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                  Provado
                </TableCell>
                <TableCell>Justificativa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reprogrammedHelpdesks?.map(helpdesk => {
                return (
                  <TableRow tabIndex={-1} key={helpdesk.id}>
                    <TableCell align="right" style={{ width: 120, maxWidth: 120 }}>
                      <Typography
                        className={classes.indicatorsDataValueLink}
                        component={Link}
                        to={`/helpdesk/${helpdesk.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {helpdesk.id}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 260, maxWidth: 330 }}>{helpdesk.attendance_name}</TableCell>
                    <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                      {helpdesk.extend_days}
                    </TableCell>
                    <TableCell align="right" style={{ width: 130, maxWidth: 130 }}>
                      {helpdesk.extend_answer === 1 ? 'SIM' : 'NÃO'}
                    </TableCell>
                    <TableCell>{helpdesk.extend_text.replace(/<\/?[^>]+(>|$)/g, '')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container className={classes.gridContainer} spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.filterContainer}>
            <Accordion expanded={filterExpanded} onChange={() => setFilterExpanded(!filterExpanded)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Modelo ({models[model].text})</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.inputContainer}>
                  <Typography className={classes.filterTitle} style={{ marginBottom: 0 }}>
                    Modelo
                  </Typography>
                  <Box display="flex" flexDirection="row" style={{ gap: 8 }} width="100%">
                    <InputSearchComboBox
                      id="helpdesk-statistics-model"
                      label="Tipo"
                      value={model.toString()}
                      onChange={e => {
                        setModel(Number.parseInt(e?.target?.value, 10));
                      }}
                      items={models}
                    />
                    <InputSearchComboBox
                      id="helpdesk-statistics-version"
                      label="Versão"
                      value={version.toString()}
                      onChange={e => {
                        setVersion(Number.parseInt(e?.target?.value, 10));
                      }}
                      style={{ width: 96 }}
                      items={models[model].version}
                    />
                  </Box>
                  {model === 0 && (
                    <Box width="100%">
                      <Typography className={classes.filterTitle}>Filtros</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={8}>
                          <InputSearchAsync
                            fullWidth
                            source="helpdesk-target"
                            id="helpdesk-statistics-target"
                            value={target}
                            label="Setor"
                            noOptionsText="Nenhum Setor encontrado"
                            multiple={false}
                            onChange={(e: ValueProps) => {
                              setTarget(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <InputSearchDate
                            id="helpdesk-statistics-date-start"
                            label="Data (Início)"
                            type="start"
                            value={dateStart}
                            onChange={e => {
                              setDateStart(e?.target?.value);
                            }}
                          />
                          <InputSearchDate
                            id="helpdesk-statistics-date-start"
                            label="Data (Fim)"
                            type="end"
                            value={dateEnd}
                            onChange={e => {
                              setDateEnd(e?.target?.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>

                <Box className={classes.filterButtonContainer}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleFilterClick}
                    disabled={model === -1 || (model === 0 && !target)}
                  >
                    <Typography className={classes.filterButtonText}>APLICAR</Typography>
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        {model === 0 &&
          (loadingData ? (
            <Loading />
          ) : (
            indicatorsData && (
              <>
                <Grid item xs={12}>
                  <Paper>
                    <Box p={2}>
                      <Box className={classes.indicatorsDataContainer}>
                        <Typography className={classes.indicatorsDataText}>SETOR:</Typography>
                        <Typography className={classes.indicatorsDataValue}>
                          {target?.description || 'Nenhum setor selecionado'}
                        </Typography>
                      </Box>
                      <Box className={classes.indicatorsDataContainer}>
                        <Typography className={classes.indicatorsDataText}>PERÍODO:</Typography>
                        <Typography className={classes.indicatorsDataValue}>{`${
                          indicatorsData?.filter.date_start_formated || dateStart
                        } a ${indicatorsData?.filter.date_end_formated || dateEnd}`}</Typography>
                      </Box>
                      {version === 0 && (
                        <>
                          <ResultInfo description="HELPDESK'S NOVOS:" value={indicatorsData?.data.v1.new_total} />
                          <ResultInfo
                            description="HELPDESK'S PENDENTES:"
                            value={indicatorsData?.data.v1.pending_total || 0}
                          />
                          <ResultInfo
                            description="MÉDIA DE AVALIAÇÕES:"
                            value={indicatorsData?.data.v1.rate_total}
                            rate={{ value: indicatorsData?.data.v1.rate_total || 0, max: 5 }}
                          />
                          <ResultInfo
                            description="HELPDESK'S ATENDIDOS:"
                            value={indicatorsData?.data.v1.attended_total || 0}
                          />
                          <ResultInfo
                            description=" HELPDESK'S ATENDIDOS (ATRASADOS):"
                            value={indicatorsData?.data.v1.out_date_helpdesk?.length || 0}
                          />
                          <OutDateHelpdesk outDateHelpdesks={indicatorsData?.data.v1.out_date_helpdesk} />
                        </>
                      )}
                      {version === 1 && (
                        <>
                          <ResultInfo description="HELPDESK'S NOVOS:" value={indicatorsData?.data.v2.new_total} />

                          <ResultInfo
                            description="HELPDESK'S ATENDIDOS:"
                            value={indicatorsData?.data.v2.attended_total || 0}
                          />
                          <ResultInfo
                            description=" HELPDESK'S ATENDIDOS ATRASADOS:"
                            value={indicatorsData?.data.v2.attended_out_date?.length || 0}
                          />
                          <ResultInfo
                            description="HELPDESK'S ATENDIDOS COM PRAZO ALTERADO:"
                            value={indicatorsData?.data.v2.attended_extended_total || 0}
                          />
                          <ResultInfo
                            description="MÉDIA DE AVALIAÇÕES:"
                            value={`${indicatorsData?.data.v1.rate_total || 0}/5`}
                            rate={{ value: indicatorsData?.data.v2.rate || 0, max: 5 }}
                          />

                          <OutDateHelpdesk outDateHelpdesks={indicatorsData?.data.v2.attended_out_date} />
                          <ReprogrammedHelpdesk reprogrammedHelpdesks={indicatorsData?.data.v2.reprogrammed} />
                        </>
                      )}
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <BarChart
                    height={360}
                    data={categorys.map(v => {
                      return { name: v.category_description, value: v.total };
                    })}
                    maxValue={
                      version === 0
                        ? indicatorsData?.data.v1.attended_total
                        : version === 1
                        ? indicatorsData?.data?.v2.attended_total
                        : 0
                    }
                    title="Total por Categoria"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <BarChart
                    height={360}
                    data={attendants.map(v => {
                      return { name: v.attendance_name, value: v.total };
                    })}
                    maxValue={
                      version === 0
                        ? indicatorsData?.data.v1.attended_total
                        : version === 1
                        ? indicatorsData?.data?.v2.attended_total
                        : 0
                    }
                    title={`Total por Atendente `}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <BarChart
                    height={360}
                    data={sectors.map(v => {
                      return { name: v.sector, value: v.total };
                    })}
                    maxValue={
                      version === 0
                        ? indicatorsData?.data.v1.attended_total
                        : version === 1
                        ? indicatorsData?.data?.v2.attended_total
                        : 0
                    }
                    title="Total por Setor"
                  />
                </Grid>
              </>
            )
          ))}
        {indicatorsData?.data.attendants.length === 0 && (
          <Grid item xs={12}>
            <Typography>Nenhum resultado encontrado.</Typography>
            <Typography>Verifique o filtro e tente novamente.</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
