import { grey, red } from '@mui/material/colors';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const styles = makeStyles(() => {
  const theme = useTheme();

  return createStyles({
    root: {
      height: '100vh',
      backgroundColor: '#78909c',
      zIndex: 0,
    },
    wave: {
      position: 'fixed',
      height: '100%',
      left: 0,
      bottom: 0,
      zIndex: 1,
    },
    backgroundImageContainer: {
      display: 'flex',
      zIndex: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    backgroundImage: {
      width: '80%',
    },
    loginContainer: {
      zIndex: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      '& img': {
        maxWidth: '100px',
        maxHeight: '100px',
      },
    },
    loginTitle: {
      color: '#254a61',
      fontWeight: 'bold',
      fontSize: '32px',
      '& span': {
        fontWeight: '300',
        color: '#f8e000',
      },
      paddingBottom: 16,
    },
    formContainer: {
      maxWidth: '360px',
      flexGrow: 1,
      margin: theme.spacing(4, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    form: {
      width: '100%',
    },
    rememberLogin: {
      color: '#e4e4e4',
    },
    checkBoxLogin: {
      color: '#e4e4e4',
    },
    forget: {
      color: '#f8e000',
      textDecoration: 'none',
      '&:hover': {
        color: '#ffeb37',
      },
    },
    submitButton: {
      margin: theme.spacing(3, 0, 2),
      background: '#254a61',
      transition: 'background-color 0.3s',
      '&:hover': {
        background: '#136192',
      },
    },
    textField: {
      borderColor: '#e4e4e4',
    },
    input: {
      '& .MuiInputBase-input': {
        color: grey[100],
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: grey[400],
      },
      '& label,label.Mui-focused': {
        color: grey[300],
      },
      '& label.Mui-disabled': {
        color: grey[400],
      },
      '& label.Mui-error, span.Mui-error, p.Mui-error': {
        color: red[200],
      },
      '& p.Mui-error': {
        color: red[50],
      },
      '& ': {
        borderBottomColor: red[200],
      },
    },

    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: '#FFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -8,
      marginLeft: -12,
    },
  });
});
export default styles;
