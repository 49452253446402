import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import CloseIcon from '@mui/icons-material/Close';

import api from '../../../../../services/api';

interface Props {
  id: number | string;
  open: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
}
type OsLogType = {
  id: number;
  type: string;
  field: string;
  old_value: string;
  value: string;
  created_at: string;
  created_at_formated: string;
  created_by: number;
  created_by_name: string;
};

type ResponseType = {
  logs: OsLogType[];
};

function OsLogsView({ id, open, handleClose, handleOpen }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [osLogs, setOsLogs] = useState<OsLogType[]>([]);
  const [fetching, setFetching] = useState(false);

  const getOsLogs = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseType>(`erp/engineering/os/${id}`, {
        params: { type: 'logs' },
      });

      setOsLogs(data?.logs ?? []);
    } catch (error) {}
    setFetching(false);
  };

  const handleOnCloseDialog = () => {
    handleClose?.();
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96, align: 'right' },
    { field: 'type', headerName: 'Tipo', width: 120 },
    { field: 'field', headerName: 'Campo', width: 120 },
    { field: 'old_value', headerName: 'Valor Antigo', width: 120 },
    { field: 'value', headerName: 'Valor', width: 120 },
    { field: 'created_at_formated', headerName: 'Data', align: 'center', width: 160 },
    { field: 'created_by_name', headerName: 'Usuário' },
  ];

  useEffect(() => {
    if (open) {
      handleOpen?.();
      getOsLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="lg">
      <DialogTitle>
        O.S - Logs
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleOnCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
          minHeight: 200,
        }}
      >
        <DataGrid
          rows={osLogs}
          columns={columns}
          // pageSize={20}
          // rowsPerPageOptions={[5]}
          loading={fetching}
          sx={{ width: '100%', height: 'calc(100vh - 138px)' }}
          density="compact"
          hideFooterSelectedRowCount
          // showCellRightBorder
          // showColumnRightBorder
          // disableSelectionOnClick
        />
      </DialogContent>
    </Dialog>
  );
}
export { OsLogsView };
