import React, { useEffect, useMemo, useState } from 'react';

import { Typography, useTheme } from '@mui/material';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import api from '../../../../../services/api';
import { DashboardInfoCard } from '../../../../../components/DashboardInfoCard';

function OpenedCard() {
  const theme = useTheme();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');

      setTotal(data.total || 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <DashboardInfoCard
        loading={loading}
        title=""
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total)}
          </Typography>
        }
        subcontent={
          <Typography variant="caption">{total > 1 ? 'Requisições geradas' : 'Requisição gerada'}</Typography>
        }
        icon={<AddCircleOutlineOutlinedIcon color="primary" sx={{ fontSize: 48 }} />}
        color={theme.palette.primary.main}
      />
    );
  }, [theme.palette.primary.main, total]);

  return renderCard;
}

export { OpenedCard };
