import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Paper, Box, Typography, IconButton, Tooltip } from '@mui/material';

import { Edit as EditIcon, Info as InfoIcon, Timeline as TimelineIcon } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import Axios, { Canceler } from 'axios';
import UnderDevelopment from '../../../components/UnderDevelopment';
import userUndefinedImg from '../../../images/user-undefined.jpg';
import api from '../../../services/api';
import styles from './styles';
import { avatarUrl, toNumber } from '../../../utils/functions';
import LoginErpDialog from '../../../components/LoginErpDialog';

type ProfileParams = {
  id: string;
};
export interface UserProfileData {
  id: string;
  my: boolean;
  login: string;
  type: string;
  name: string;
  birthday_date: string;
  employee_position: string;
  sector: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  logged_at: string;
  situation: string;
  erp_id: string;
  erp_login: string;
  is_admin: boolean;
}

export default function ProfilePage(props) {
  console.log(props);

  let { id: userID } = useParams() as ProfileParams;

  userID = toNumber(userID, 0) > 0 ? userID : '';
  const classes = styles();
  const [loadingData, setLoadingData] = useState(false);
  const [userData, setUserData] = useState<UserProfileData>();
  const [syncUserData, setSyncUserData] = useState(false);

  const [showLoginErpDialog, setShowLoginErpDialog] = useState(false);

  function handleLoginErpDialogOpen() {
    setShowLoginErpDialog(true);
  }

  const loadUserData = (id: string) => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      api
        .get(`intranet/users/${id}/profile`, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setUserData(response.data);

          setLoadingData(false);
        })
        .catch((error: any) => {
          const { data } = error.response;
          if (data) {
            const errorMessage = data?.message;
            console.error(errorMessage);
          }
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const handleSyncDataUser = async (id: number) => {
    if (!syncUserData) {
      setSyncUserData(true);
      try {
        await api.post('intranet/profile/sync', {
          id,
        });

        setSyncUserData(false);
      } catch (error) {
        console.error(error);
        setSyncUserData(false);
      }
    }
  };

  useEffect(() => {
    loadUserData(userID);
  }, [userID]);

  return (
    <Box>
      <Box className={classes.root}>
        <Grid container spacing={2} className={classes.contentRoot}>
          <Grid item xs={12}>
            <Paper className={classes.topBar}>
              <Box className={classes.profileContainer} sx={{ height: '100%' }}>
                <img
                  className={classes.profileImage}
                  src={avatarUrl(userData?.id)}
                  alt="Foto"
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0);
                  }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flex: 1,
                    height: '100%',

                    width: '100%',
                    padding: 1,
                    position: 'relative',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                    <Typography className={classes.profileName}>{userData?.name}</Typography>
                    <Typography className={classes.profileSector}>{userData?.sector}</Typography>
                    <Typography className={classes.profilePosition}>{userData?.employee_position}</Typography>
                    <Box className={classes.profileLoggedAtContainer}>
                      <Typography className={classes.profileLoggedAtText}>
                        Último acesso: {userData?.logged_at}
                      </Typography>
                    </Box>
                  </Box>
                  {userData?.is_admin === true && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title="Sincronizar dados" aria-label="view">
                        <IconButton
                          onClick={async () => {
                            handleSyncDataUser(toNumber(userID));
                          }}
                          color="warning"
                          size="small"
                          disabled={syncUserData}
                        >
                          <SyncIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box className={classes.actionsContainer} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Paper>
                <Box className={classes.aboutContainer}>
                  <Box className={classes.aboutTitleContainer}>
                    <InfoIcon className={classes.aboutTitleIcon} color="warning" />
                    <Typography className={classes.aboutTitleText}>Sobre</Typography>
                    <Box className={classes.aboutEditContainer}>
                      {userData?.my && (
                        <Tooltip title="Editar Informações" aria-label="view">
                          <IconButton component={Link} to="/profile/edit" color="warning" size="small">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.aboutDataContainer}>
                    <Typography>
                      Aniversário: <span className={classes.aboutDataValue}>{userData?.birthday_date}</span>
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography>
                        Email: <span className={classes.aboutDataValue}>{userData?.email}</span>
                      </Typography>
                    </Box>
                    <Typography>
                      Ramal: <span className={classes.aboutDataValue}>{userData?.phone1}</span>
                    </Typography>
                    <Typography>
                      Celular: <span className={classes.aboutDataValue}>{userData?.phone2}</span>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              {userData?.my && (
                <Paper>
                  <Box className={classes.aboutContainer}>
                    <Box className={classes.aboutTitleContainer}>
                      <SettingsIcon className={classes.aboutTitleIcon} color="info" />
                      <Typography className={classes.aboutTitleText}>Configurações</Typography>
                    </Box>
                    <Box className={classes.aboutDataContainer} sx={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2,
                            width: '100%',
                          }}
                        >
                          <Typography sx={{ textWrap: 'nowrap' }}>Usuário ERP:</Typography>
                          <Typography sx={{ textWrap: 'nowrap' }} className={classes.aboutDataValue}>
                            {userData?.erp_id} - {userData?.erp_login}
                          </Typography>
                        </Box>
                        <Box className={classes.aboutEditContainer}>
                          <Tooltip title="Alterar usuário ERP" aria-label="view">
                            <IconButton color="info" size="small" onClick={handleLoginErpDialogOpen}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={7} md={8}>
            <Paper>
              <Box className={classes.aboutContainer}>
                <Box className={classes.aboutTitleContainer}>
                  <TimelineIcon className={classes.aboutTitleIcon} color="success" />
                  <Typography className={classes.aboutTitleText}>Atividade</Typography>
                </Box>
                <Box className={classes.aboutDataContainer}>
                  <Typography className={classes.activityNoneText}>Nenhuma atividade recente</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <LoginErpDialog
          show={showLoginErpDialog}
          onClose={(erp_id, erp_login) => {
            setShowLoginErpDialog(false);
            setUserData(old => ({ ...old, erp_id, erp_login }));
          }}
        />
      </Box>
    </Box>
  );
}
