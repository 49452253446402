import React from 'react';
import { Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { BirthdayCard } from './BirthdayCard';
import { PaydayCard } from './PaydayCard';
import { NewsCard } from './NewsCard';
import { MenuCard } from './MenuCard';
import { JobOpportunityCard } from './JobOpportunityCard';
import { HourBankCard } from './HourBankCard';
import { VacationCard } from './VacationCard';
import { DayoffCard } from './DayoffCard';

export default function DashboardPage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: 1 }}>
      <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
        <NewsCard />
        <BirthdayCard />
        <MenuCard />
        <PaydayCard />
        <HourBankCard />
        <DayoffCard />
        <VacationCard />
        <JobOpportunityCard />
      </Masonry>
    </Box>
  );
}
