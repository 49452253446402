import React, { useEffect, useState } from 'react';

import { Box, Paper, Typography } from '@mui/material';
import stc from 'string-to-color';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTheme } from '@mui/material/styles';

import axios, { Canceler } from 'axios';
import api from '../../../services/api';
import MonthBirthdayCalendar, { BirthdaysType } from './MonthBirthdayCalendar';

import { ProfileField } from '../../../components/ProfileField';
import balloonImage from '../../../images/balloon.svg';

export type BirthdaysListType = {
  january: BirthdaysType[];
  february: BirthdaysType[];
  march: BirthdaysType[];
  april: BirthdaysType[];
  may: BirthdaysType[];
  june: BirthdaysType[];
  july: BirthdaysType[];
  august: BirthdaysType[];
  september: BirthdaysType[];
  october: BirthdaysType[];
  november: BirthdaysType[];
  december: BirthdaysType[];
};

export default function CalendarPage() {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [birthdaysList, setBirthdaysList] = useState<BirthdaysListType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const loadingText = 'Carregando...';

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<BirthdaysListType[]>('hcm/birthdays', {
          cancelToken: new axios.CancelToken(async c => {
            cancel = c;
          }),
        });

        setBirthdaysList(response.data);

        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    getData();
  }, []);

  if (loadingData)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.text.secondary,
          }}
        >
          {loadingText}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{ display: 'flex', gap: 4, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
        <img src={balloonImage} alt="balloons" style={{ height: 64 }} />
        <Typography sx={{ color: darkMode ? '#e580ff' : '#8c0bad', fontWeight: 'bold', fontSize: 22 }}>
          Aniversáriantes
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {months.map((month, index) => {
          return (
            <Grid key={month} xs={12} sm={12} md={12} lg={6} xl={4}>
              <MonthBirthdayCalendar month={index + 1} birthdaysList={birthdaysList[month]} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
