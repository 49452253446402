import React, { useEffect, useState } from 'react';
import Axios, { Canceler } from 'axios';
import { Box, Typography, Avatar, IconButton, Tooltip } from '@mui/material';
import { Edit as EditIcon, PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import styles from './styles';

import api from '../../../../services/api';
import ProfileTooltip from '../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../utils/functions';

type UserType = {
  id: number;
  type: number;
  number: number;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  vacation_date_start: string;
  vacation_date_end: string;
  vacation_days: number;
  days_remaining: number;
  start: string;
  end: string;
};

export default function VacationsListPage() {
  const classes = styles();
  const [data, setData] = useState<UserType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const loadingText = 'Carregando...';

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<UserType[]>('manager/vacations', {
          cancelToken: new Axios.CancelToken(async c => {
            cancel = c;
          }),
        });
        setData(response.data);

        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    let active = true;
    if (active) {
      getData();
    }
    return () => {
      active = false;
    };
  }, []);
  if (loadingData)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>{loadingText}</Typography>
      </Box>
    );
  if (data?.length === 0)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>Nenhuma programação encontrada</Typography>
      </Box>
    );
  return (
    <>
      {data.map(user => {
        const userId = user.id.toString();
        const userName = user.nickname;
        const userSector = user.sector;
        const dateStart = user.vacation_date_start;
        const dateEnd = user.vacation_date_end;
        const totalDays = user.vacation_days;
        const targetRequest = '11';
        const titleRequest = 'Alteração na programação de férias';
        const textRequest = `<p>Gentileza alterar a programação de férias do colaborador <strong>(${user.number}) ${userName}</strong> para:</p><table class="se-table-size-auto"><thead><tr><th><div>Data Início</div></th><th><div>Data Fim</div></th><th><div>Total (dias)</div></th></tr></thead><tbody><tr><td><div>${user.vacation_date_start}</div></td><td><div>${user.vacation_date_end}</div></td><td><div>${user.vacation_days}</div></td></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr></tbody></table>`;
        const color =
          user.vacation_days === 0
            ? classes.titleItemYellow
            : user.days_remaining > 45
            ? classes.titleItemGreen
            : user.days_remaining > 15
            ? classes.titleItemYellow
            : classes.titleItemRed;

        return (
          <Box key={`${user.id}-${dateStart}-${dateEnd}`} className={`${classes.itemContainer} ${color}`}>
            {totalDays > 0 ? (
              <>
                <Box className={classes.vacationDaysRemainingContainer}>
                  <Typography className={classes.vacationDaysRemainingValue}>{totalDays}</Typography>
                  <Typography className={classes.vacationDaysRemainingDescription}>dias</Typography>
                </Box>
                <Box className={classes.avatarContainer}>
                  <Link to={`/profile/${userId}`} className={classes.avatarLink} style={{ textDecoration: 'none' }}>
                    <ProfileTooltip userId={userId}>
                      <Avatar
                        alt={`${userName}`}
                        src={avatarUrl(userId, true)}
                        aria-label={`${userName.toLowerCase()}`}
                        className={classes.avatar}
                      />
                    </ProfileTooltip>
                  </Link>
                  <Box className={classes.avatarDescriptionContainer}>
                    <Box width="100%">
                      <Typography className={classes.avatarDescriptionName}>{userName}</Typography>
                    </Box>
                    <Box className={classes.avatarDescriptionDateContainer}>
                      <Box className={classes.avatarDescriptionDateDescriptionContainer}>
                        <Typography className={classes.avatarDescriptionDateDescription}>Inicio:</Typography>
                        <Typography className={classes.avatarDescriptionDateDescription}>Fim:</Typography>
                      </Box>
                      <Box className={classes.avatarDescriptionDateValueContainer}>
                        <Typography className={classes.avatarDescriptionDateValue}>{dateStart}</Typography>
                        <Typography className={classes.avatarDescriptionDateValue}>{dateEnd}</Typography>
                      </Box>
                      <Box className={classes.actionsContainer}>
                        <Tooltip title="Solicitar alteração">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `/helpdesk/add/?target=${targetRequest}&title=${titleRequest}&private=true&text=${textRequest}`,
                              );
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className={classes.vacationDaysRemainingContainer}>
                  <PriorityHighIcon className={`${classes.vacationDaysRemainingIcon} ${classes.colorYellow}`} />
                </Box>
                <Box className={classes.avatarContainer}>
                  <Link to={`/profile/${userId}`} className={classes.avatarLink} style={{ textDecoration: 'none' }}>
                    <ProfileTooltip userId={userId}>
                      <Avatar
                        alt={`${userName}`}
                        src={avatarUrl(userId, true)}
                        aria-label={`${userName.toLowerCase()}`}
                        className={classes.avatar}
                      />
                    </ProfileTooltip>
                  </Link>
                  <Box className={classes.avatarDescriptionContainer}>
                    <Box width="100%">
                      <Typography className={classes.avatarDescriptionName}>{userName}</Typography>
                    </Box>
                    <Box className={classes.vacationNoVacationContainer}>
                      <Box display="flex" alignItems="center" width="100%" mr={2}>
                        <Typography className={classes.vacationNoVacationText}>Sem programação</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" width="100%">
                        <Tooltip title="Solicitar alteração">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `/helpdesk/add/?target=${targetRequest}&title=${titleRequest}&private=true&text=${textRequest}`,
                              );
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        );
      })}
    </>
  );
}
