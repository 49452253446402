import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      '& .MuiDrawer-paper': {
        backgroundColor: darkMode ? '#333333' : '#fafafa',
      },
    },
    menuList: {
      marginTop: 8,
      width: 384,
      maxWidth: '100vw',
      height: 'calc(100vh - 30px)',
      overflowY: 'auto',
    },
    subMenuList: {
      backgroundColor: theme.palette.mode === 'dark' ? '#3c3c3c' : '#e6e6e6',
    },

    menuListNested: {
      paddingLeft: theme.spacing(4),
    },
    searchRoot: {
      margin: 8,
      marginBottom: 0,

      display: 'flex',
      alignItems: 'center',
    },
    inputRoot: {
      width: '100%',

      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      margin: 8,
      padding: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    closeIconButton: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
    resultTextHighlights: {
      fontWeight: 700,
      color: darkMode ? yellow[400] : yellow[900],
      backgroundColor: 'transparent',
    },
  });
});
