import React from 'react';

import { Avatar, Box, Paper, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteIcon from '@mui/icons-material/Delete';
import ProfileTooltip from '../ProfileTooltip';
import BotIcon from '../../images/bot_avatar.svg';
import styles from './styles';
import { avatarUrl } from '../../utils/functions';
import api from '../../services/api';

export interface CommentProps {
  userId: number;
  userName: string;
  userSector: string;
  text: string;
  date: string;
  date_formated: string;
  content?: any;
  fullWidth?: boolean;
  system?: boolean;
  readCheck?: boolean;
  readed?: boolean;
  readed_date?: string;
  my_message?: boolean;
  comment_id?: number;
  htmlFormat?: boolean;
  onDelete?: (id: number) => void;
}

export default function Comment(props: any & CommentProps) {
  const classes = styles();
  const {
    userId,
    userName,
    userSector,
    text,
    content,
    fullWidth,
    flip,
    system,
    date_formated,
    readed = false,
    readCheck = false,
    readed_date,
    my_message = false,
    onDelete = null,
    comment_id = 0,
    htmlFormat = false,
  } = props;

  return (
    <Box
      className={classes.commentRoot}
      flex={1}
      width="100%"
      style={flip && !system ? { justifyContent: 'flex-end' } : {}}
    >
      {system === true ? (
        <>
          <img src={BotIcon} className={classes.commentSystemIconName} alt="system" />
          <div className={classes.commentPointer} />
          <Paper className={classes.commentBox} style={fullWidth ? { width: 'calc(100% - 124px)' } : {}}>
            <Box display="flex" alignItems="center" className={classes.commenNametBox}>
              <Typography className={classes.commentSystemName} color="primary">
                Sistema
              </Typography>
              <Typography className={classes.commentDate}>{date_formated}</Typography>
            </Box>

            <Typography
              className={classes.commentSystemText}
              style={{ wordWrap: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: `${text}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`),
              }}
            />

            {content}
          </Paper>
        </>
      ) : (
        <>
          {!flip && (
            <>
              <Link to={`/profile/${userId}`} style={{ textDecoration: 'none' }}>
                <ProfileTooltip userId={userId}>
                  <Avatar
                    alt={`${userName}`}
                    src={avatarUrl(userId, true)}
                    aria-label="author"
                    className={classes.commentAvatar}
                  />
                </ProfileTooltip>
              </Link>
              <div className={classes.commentPointer} />
            </>
          )}

          <Paper className={classes.commentBox} style={fullWidth ? { width: 'calc(100% - 124px)' } : {}}>
            <Box display="flex" alignItems="center">
              <Typography
                className={`${classes.commentUserName} ${my_message ? classes.commentUserNameMy : ''} `}
                color="primary"
              >
                {userName}
              </Typography>
              <Typography className={classes.commentDate}>{date_formated}</Typography>
              {readCheck && (
                <Tooltip
                  title={
                    <span style={{ fontStyle: 'italic' }}>
                      {readed ? `Visualizado em ${readed_date}` : 'Não visualizado'}
                    </span>
                  }
                >
                  {readed ? (
                    <DoneAllIcon style={{ fontSize: 14, marginRight: 8 }} color="primary" />
                  ) : (
                    <DoneIcon style={{ fontSize: 14, marginRight: 8 }} color="inherit" />
                  )}
                </Tooltip>
              )}
              {onDelete && my_message && (
                <Tooltip title="Deletar">
                  <DeleteIcon
                    className={classes.commenDeleteicon}
                    onClick={() => {
                      onDelete(comment_id);
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Typography
              className={classes.commentText}
              style={{ wordWrap: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: `${text}`.replace(/{UPLOAD_PATH}/g, `${api.getUri()}/static/uploads`),
              }}
            />

            {content}
          </Paper>
          {flip && (
            <>
              <div className={classes.commentPointerFlip} />
              <Link to={`/profile/${userId}`} style={{ textDecoration: 'none' }}>
                <ProfileTooltip userId={userId}>
                  <Avatar
                    alt={`${userName}`}
                    src={avatarUrl(userId, true)}
                    aria-label="author"
                    className={classes.commentAvatar}
                  />
                </ProfileTooltip>
              </Link>
            </>
          )}
        </>
      )}
    </Box>
  );
}
