import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  InputBase,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { ChatComment } from '../../../../../components/ChatComment';
import api from '../../../../../services/api';
import FeedbackAlert, { FeedbackProps } from '../../../../../components/FeedbackAlert';
import { ConfirmationDialog } from '../../../../../components/ConfirmationDialog';

interface Props {
  id: number | string;
  open: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  disableChat?: boolean;
}

type OsChatsType = {
  id: number;
  os_id: number;
  created_by: number;
  created_by_name: string;
  created_at: string;
  deleted_at: string;
  text: string;
  parent: number;
  created_at_formated: string;
  deleted_at_formated: string;
  owner: boolean;
  children: OsChatsType[];
};

type ReplayType = {
  id: number;
  name: string;
  text: string;
};

function OsChatView({ id, open, handleClose, handleOpen, disableChat }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const refInputText = useRef<HTMLInputElement>(null);
  const [replay, setReplay] = useState<ReplayType>(null);

  const [osChats, setOsChats] = useState<OsChatsType[]>([] as OsChatsType[]);
  const [fetching, setFetching] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(0);
  const [feedback, setFeedback] = useState<FeedbackProps>({
    message: '',
    show: false,
  });

  const getOsChats = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<OsChatsType[]>(`erp/engineering/os/${id}/chat`, {
        params: {},
      });

      setOsChats(data ?? []);
    } catch (error) {}
    setFetching(false);
  };

  const delChat = async (chat_id: number) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.delete<OsChatsType[]>(`erp/engineering/os/${id}/chat`, {
        data: {
          chat_id,
        },
      });
      setFeedback({
        message: 'Deletado com sucesso',
        show: true,
        type: 'success',
      });
      setDeleteConfirmation(0);
      getOsChats();
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'has_already_been_deleted'
            ? 'Trâminte já deletado'
            : response.message === 'invalid_os'
            ? 'OS inválida'
            : response.message === 'invalid_chat'
            ? 'Trâminte inválido'
            : response.message === 'invalid_chat'
            ? 'Trâminte inválido'
            : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
      setDeleteConfirmation(0);
    }
    setSubmiting(false);
  };

  const postChat = async (text: string, parent: number | null) => {
    if (submiting) return;
    setSubmiting(true);
    try {
      await api.put<OsChatsType[]>(`erp/engineering/os/${id}/chat`, {
        text,
        parent,
      });

      getOsChats();
      setFeedback({
        message: 'Trâmite criado com sucesso',
        show: true,
        type: 'success',
      });
      setReplay(null);
      const input = refInputText.current.children[0] as HTMLInputElement;
      input.value = '';
    } catch (error) {
      const { data: response } = error.response;

      setFeedback({
        message:
          response.message === 'os_ended'
            ? 'O.S. finalizada'
            : response.message === 'invalid_os'
            ? 'O.S. Inválida'
            : response.message === 'os_canceled'
            ? 'O.S. Cancelada'
            : response.message === 'invalid_text'
            ? 'Trâminte Inválido'
            : `Erro desconhecido: ${response.message}`,
        show: true,
        type: 'error',
      });
    }
    setSubmiting(false);
  };

  const handleOnCloseDialog = () => {
    handleClose?.();
  };

  const renderReplay = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          borderRadius: 1,
          gap: 1,
          p: 1,
          bgcolor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
        }}
      >
        <Box
          sx={{
            width: '100%',
            pl: 1,
          }}
        >
          <Typography color={theme.palette.text.secondary} sx={{ fontStyle: 'italic', mb: 1 }}>
            Responser a:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              borderRadius: 1,
              gap: 1,
              p: 1,
              bgcolor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
              borderLeft: `4px solid ${theme.palette.warning.main}`,
            }}
          >
            <Typography color={theme.palette.success.light} sx={{ fontStyle: 'italic' }} fontSize={12}>
              {replay?.name}
            </Typography>
            <Typography color={theme.palette.text.secondary} sx={{ fontStyle: 'italic' }} fontSize={14}>
              {replay?.text}
            </Typography>
          </Box>
        </Box>
        <Button
          color="error"
          sx={{
            minWidth: 0,
          }}
          onClick={() => setReplay(null)}
        >
          <CloseIcon fontSize="inherit" />
        </Button>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replay]);

  const renderComments = (_chats: OsChatsType[]) => {
    return _chats.map(c => {
      return (
        <Box
          key={c.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: c.parent > 0 ? 'calc(100% - 18px)' : '100%',
            borderLeft: c.parent > 0 ? `2px solid ${isDarkMode ? '#838383' : '#d3d3d3'}` : '',
          }}
        >
          <ChatComment
            key={c.id}
            // sx={{ marginLeft: 24 }}
            date={c.created_at_formated}
            name={c.created_by_name}
            text={c.text}
            userId={c.created_by}
            canDelete={c.owner}
            isReplay={c.parent > 0}
            onReplay={() => {
              setReplay({
                name: c.created_by_name,
                id: c.id,
                text: c.text.length > 120 ? `${c.text.substring(0, 120 - 3)}...` : c.text,
              });
            }}
            onDelete={() => {
              setDeleteConfirmation(c.id);
            }}
          />
          {renderComments(c.children)}
        </Box>
      );
    });
  };

  const handleDelete = () => {
    delChat(deleteConfirmation);
  };

  const renderFeedbackAlert = useMemo(
    () => (
      <FeedbackAlert
        message={feedback.message}
        show={feedback.show}
        durations={8000}
        type={feedback.type}
        onClose={() =>
          setFeedback(old => ({
            ...old,
            show: false,
          }))
        }
      />
    ),
    [feedback],
  );

  useEffect(() => {
    if (open) {
      handleOpen?.();
      getOsChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md">
        <DialogTitle>
          O.S - Trâmites
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleOnCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
              alignItems: 'flex-end',
              p: 1,
              bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
              borderRadius: 1,
              borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
              borderStyle: 'solid',
              borderWidth: 1,
              overflowY: 'auto',
              height: 'calc(100vh - 256px)',
            }}
          >
            {fetching ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Typography color={theme.palette.text.secondary}>Carregando...</Typography>
              </Box>
            ) : osChats?.length > 0 ? (
              renderComments(osChats)
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Typography color={theme.palette.text.secondary}>Nenhum trâmite encontrado</Typography>
              </Box>
            )}
          </Box>

          {replay && renderReplay}

          {!disableChat && (
            <Paper
              sx={{
                display: 'flex',

                borderRadius: 1,
                borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
                borderStyle: 'solid',
                borderWidth: 1,
                alignItems: 'flex-end',
                gap: 1,
                p: 1,
              }}
            >
              <InputBase
                ref={refInputText}
                fullWidth
                multiline
                placeholder="Digite o seu trâmite aqui..."
                inputProps={{ 'aria-label': 'pesquisar' }}
                sx={{ px: 1, minHeight: 40, maxHeight: 128, overflowY: 'auto' }}
                // onChange={handleSearchTextChange}
              />
              <IconButton
                aria-label="send"
                size="medium"
                onClick={() => {
                  const input = refInputText.current.children[0] as HTMLInputElement;

                  postChat(input.value ?? '', replay?.id ?? null);
                }}
              >
                <SendIcon fontSize="medium" color="inherit" />
              </IconButton>
            </Paper>
          )}
          <ConfirmationDialog
            title="Deletar trâmite?"
            content="Essa ação não pode ser desfeita"
            open={deleteConfirmation !== 0}
            onClose={() => setDeleteConfirmation(0)}
            onClickNo={() => setDeleteConfirmation(0)}
            onClickYes={handleDelete}
          />
        </DialogContent>
      </Dialog>
      {renderFeedbackAlert}
    </>
  );
}
export { OsChatView };
