import React, { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import { Box, BoxProps, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';

import { avatarUrl } from '../utils/functions';
import ProfileTooltip from './ProfileTooltip';

interface ChatCommentProps extends BoxProps {
  userId: number;
  name: string;
  date: string;
  text: string;
  canDelete?: boolean;
  flip?: boolean;
  flipOffset?: boolean;
  isReplay?: boolean;
  onDelete?: () => void;
  onReplay?: () => void;
}

const ChatComment = ({
  date,
  name,
  text,
  userId,
  canDelete,
  flip,
  flipOffset,
  onDelete,
  onReplay,
  isReplay,
  ...rest
}: ChatCommentProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: flip ? 'row-reverse' : 'row',
        alignItems: 'flex-end',
        p: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 48,
          height: 48,
          minWidth: 48,
          minHeight: 48,
          borderColor: isDarkMode ? '#3a3a3a' : '#cbcbcb',
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          bgcolor: isDarkMode ? '#3a3a3a' : '#ffffff',
        }}
      >
        <ProfileTooltip userId={userId} openNewTab>
          <img
            style={{
              width: 48,
              height: 48,
            }}
            src={avatarUrl(userId, true)}
            alt="user"
          />
        </ProfileTooltip>
      </Box>
      <Box
        sx={{
          p: {
            fontSize: 14,
          },
          'a:link': {
            color: theme.palette.info.main,
          },
          'a:visited': {
            color: theme.palette.info.main,
          },
          'a:hover': {
            color: theme.palette.info.light,
          },
          'a:active': {
            color: theme.palette.info.dark,
          },
          whiteSpace: 'pre-wrap',
          width: '100%',
          borderRadius: 1,
          borderColor: isDarkMode ? '#282828' : '#3a3a3a',
          borderStyle: 'solid',
          borderWidth: 1,
          minHeight: 48,
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          bgcolor: isDarkMode ? '#3a3a3a' : '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          '& > p': {
            m: 0,
            p: 0,
          },

          padding: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} color={theme.palette.success.main}>
              {name}
            </Typography>
            <Tooltip title="Responder mensagem" arrow>
              <IconButton
                aria-label="replay"
                size="small"
                onClick={() => {
                  onReplay?.();
                }}
              >
                <ReplyIcon fontSize="inherit" color="info" sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
            {canDelete && (
              <Tooltip title="Deletar mensagem" arrow>
                <IconButton
                  aria-label="deletar"
                  size="small"
                  onClick={() => {
                    onDelete?.();
                  }}
                >
                  <DeleteIcon fontSize="inherit" color="error" sx={{ fontSize: 14 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {date}
            </Typography>
          </Box>
        </Box>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      </Box>
      {flipOffset && <Box sx={{ width: 48, minWidth: 48 }} />}
    </Box>
  );
};

export { ChatComment };
