import React, { useEffect, useMemo, useState } from 'react';

import {
  Drawer,
  DrawerProps,
  Tooltip,
  Typography,
  Box,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

// ICONS

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Canceler } from 'axios';
import { toNumber } from 'lodash';
import api from '../../services/api';

import styles from './styles';
import { CompanyType } from '../../App';

interface ChangeCompanyProps extends DrawerProps {
  show: boolean;
  onUpdate?: (event?: { count: number }) => void;
  onClose?: () => void;
}

export default function ChangeCompany(props: ChangeCompanyProps) {
  const { show, onUpdate = () => {}, onClose = () => {} } = props;

  const classes = styles();

  const [fetchingCompanyData, setFetchingCompanyData] = useState<boolean>(false);

  const [company, setCompany] = useState(window.localStorage.getItem('company') ?? '1');
  const [branch, setBranch] = useState(window.localStorage.getItem('branch') ?? '1');

  const [companyData, setCompanyData] = useState<CompanyType[]>(null);

  const handleCompanyChange = (event: SelectChangeEvent) => {
    setCompany(event.target.value as string);
  };
  const handleBranchChange = (event: SelectChangeEvent) => {
    setBranch(event.target.value as string);
  };

  const getCompanyData = async () => {
    let cancel: Canceler;

    try {
      if (!fetchingCompanyData) {
        setFetchingCompanyData(true);
        const { data } = await api.get<CompanyType[]>('erp/company/', {});
        setCompanyData(data);

        const currentCompany = data?.filter(f => toNumber(f.codemp) === toNumber(company))?.[0];
        const currentBranch = currentCompany?.branches?.filter(f => toNumber(f.codfil) === toNumber(branch))?.[0];

        setCompany(currentCompany ? currentCompany.codemp : '1');
        setBranch(currentBranch ? currentBranch.codfil : '1');
      }
    } catch (error) {}
    setFetchingCompanyData(false);

    return () => cancel();
  };

  const handleSetCompany = async () => {
    window.localStorage.setItem('company', company);
    window.localStorage.setItem('branch', branch);
    setFetchingCompanyData(true);

    await api.post('intranet/users/settings', {
      setting: 'company',
      value: company,
    });
    await api.post('intranet/users/settings', {
      setting: 'branch',
      value: branch,
    });
    window.localStorage.setItem(
      'company-name',
      companyData
        ?.filter(f => toNumber(f.codemp) === toNumber(company))?.[0]
        ?.branches?.filter(f => toNumber(f.codfil) === toNumber(branch))?.[0]?.sigfil || '',
    );

    window.location.reload();
  };

  useEffect(() => {
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectBranch = useMemo(
    () => (
      <FormControl fullWidth>
        <InputLabel id="branch-select-label">Filial</InputLabel>
        <Select
          labelId="branch-select-label"
          id="branch-select"
          value={branch}
          label="Filial"
          onChange={handleBranchChange}
          size="small"
          disabled={company.length === 0 || fetchingCompanyData}
        >
          {companyData
            ?.filter(f => f.codemp === company)?.[0]
            ?.branches?.map(b => (
              <MenuItem key={b.codfil} selected={b.codfil === branch} value={b.codfil}>
                {b.sigfil}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    ),
    [company, branch, companyData, fetchingCompanyData],
  );

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={() => {
        onClose();
      }}
      className={classes.root}
    >
      <Paper elevation={1} square className={classes.topBar}>
        <Box m={1} display="flex" alignItems="center">
          <Tooltip title="Fechar">
            <Button
              variant="outlined"
              color="error"
              size="small"
              style={{ minWidth: 30, maxWidth: 30, height: 30 }}
              disabled={fetchingCompanyData}
              onClick={() => {
                onClose();
              }}
            >
              <CloseRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>

          <Box flex={1} display="flex" justifyContent="center" alignItems="center">
            <Typography>Trocar Empresa / Filial</Typography>
          </Box>
          <div />
        </Box>
      </Paper>
      <Box
        sx={{
          pt: 1,
          m: 2,
          display: 'flex',
          gap: 3,
          flexDirection: 'column',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="company-select-label">Empresa</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={company}
            label="Empresa"
            disabled={fetchingCompanyData}
            onChange={handleCompanyChange}
            size="small"
          >
            {companyData?.map(c => (
              <MenuItem key={c.codemp} selected={c.codemp === company} value={c.codemp}>
                {c.sigemp}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderSelectBranch}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          p: 2,
        }}
      >
        <Tooltip title="Cancelar alterações">
          <Button
            color="error"
            disabled={fetchingCompanyData}
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Tooltip>
        <Tooltip title="Salvar alterações">
          <Button color="success" variant="outlined" onClick={handleSetCompany} disabled={fetchingCompanyData}>
            Alterar
          </Button>
        </Tooltip>
      </Box>
    </Drawer>
  );
}
