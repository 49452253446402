import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
// ICONS
import InboxIcon from '@mui/icons-material/Inbox';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// CUSTON COMPONENTS
import OsRequestsPanel from './OsRequestsPanel';
import HelpDeskAttendancePanel from './HelpDeskAttendancePanel';

type ListType = {
  text: string;
  key: string;
  icon: ReactNode;
};

const viewList: ListType[] = [
  { key: 'dashboard', text: 'Dashboard', icon: <AssignmentLateOutlinedIcon /> },
  { key: 'requests', text: 'Solicitações', icon: <AssignmentLateOutlinedIcon /> },
  { key: 'attendance', text: 'Atendimento', icon: <AssignmentTurnedInOutlinedIcon /> },
  { key: 'management', text: 'Gestão', icon: <InsertChartOutlinedIcon /> },
  { key: 'report', text: 'Relatórios', icon: <InsertChartOutlinedIcon /> },
];
const quickListRequests: ListType[] = [
  { key: 'all', text: 'Tudo', icon: <FilterAltOffOutlinedIcon /> },
  { key: 'on_hold', text: 'Em Espera', icon: <PauseCircleOutlineIcon /> },
  { key: 'in_progress', text: 'Em execução', icon: <PlayCircleOutlineIcon /> },
  { key: 'finished', text: 'Finalizadas', icon: <CheckCircleOutlineIcon /> },
  { key: 'canceled', text: 'Cancelados', icon: <CancelOutlinedIcon /> },
];
const quickListAttendance: ListType[] = [
  { key: 'all', text: 'Tudo', icon: <FilterAltOffOutlinedIcon /> },
  { key: 'on_hold', text: 'Em Espera', icon: <PauseCircleOutlineIcon /> },
  { key: 'in_progress', text: 'Em execução', icon: <PlayCircleOutlineIcon /> },
  { key: 'finished', text: 'Finalizadas', icon: <CheckCircleOutlineIcon /> },
  { key: 'canceled', text: 'Cancelados', icon: <CancelOutlinedIcon /> },
];

export default function HelpDeskHomePage() {
  const theme = useTheme();
  const widthSmLess = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState('requests');
  const [quickFilter, setQuickFilter] = useState('all');
  const [quickFilterList, setQuickFilterList] = useState<ListType[]>(quickListRequests);

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      setView(nextView);
      setQuickFilterList([]);
      setQuickFilter('');

      if (nextView === 'requests') {
        setQuickFilterList(quickListRequests);
        setQuickFilter('all');
      } else if (nextView === 'attendance') {
        setQuickFilterList(quickListAttendance);
        setQuickFilter('all');
      }
    }
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, nextFilter: string) => {
    if (nextFilter !== null) {
      setQuickFilter(nextFilter);
    }
  };

  const ListOption = (props: { icon: ReactNode; text: string }) => {
    const { icon, text } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flex: 1,
          justifyContent: widthSmLess ? 'center' : 'flex-start',
          alignItems: 'center',
        }}
      >
        {icon}
        <Typography sx={{ display: widthSmLess ? 'none' : 'flex' }}>{text}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flex: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', flex: 1 }}>
              <Button variant="contained" fullWidth color="success" size="large">
                NOVO HELPDESK
              </Button>
            </Box>
            <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
              {widthSmLess && <Typography>{viewList.find(v => v.key === view)?.text?.toUpperCase()}</Typography>}
              <ToggleButtonGroup
                orientation={widthSmLess ? 'horizontal' : 'vertical'}
                value={view}
                exclusive
                onChange={handleViewChange}
                fullWidth
              >
                {viewList?.map((v, i) => {
                  const { icon, text } = v;
                  const key = `${v.key}-${i}`;
                  return (
                    <ToggleButton value={v.key} aria-label="list" key={key} color="info" fullWidth>
                      <ListOption text={text} icon={icon} />
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Paper>
            {quickFilterList?.length > 0 && (
              <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flex: 1,
                    color: theme.palette.mode === 'dark' ? '#c1c1c1' : '#767676',
                  }}
                >
                  <FilterAltOutlinedIcon />
                  <Typography>Filtro rápido</Typography>
                </Box>
                {widthSmLess && (
                  <Typography>{quickFilterList.find(f => f.key === quickFilter)?.text?.toUpperCase()}</Typography>
                )}
                <ToggleButtonGroup
                  orientation={widthSmLess ? 'horizontal' : 'vertical'}
                  value={quickFilter}
                  exclusive
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {quickFilterList?.map((filter, index) => {
                    const { icon, text, key } = filter;
                    const keyList = `${filter.key}-${index}`;
                    return (
                      <ToggleButton value={key} aria-label="list" key={keyList} color="info" fullWidth>
                        <ListOption text={text} icon={icon} />
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Paper>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
          {view === 'requests' && <OsRequestsPanel />}
          {view === 'attendance' && <HelpDeskAttendancePanel />}
        </Grid>
      </Grid>
    </Box>
  );
}
