import Orders from './orders';
import Production from './production';
import Stock from './stock';
import Engineering from './engineering';

export default {
  Orders,
  Production,
  Stock,
  Engineering,
};
