import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
// ICONS
import InboxIcon from '@mui/icons-material/Inbox';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// CUSTON COMPONENTS
import { EnOsDashboard } from './EnOsDashboard';
import { EnOsNewOSForm } from './EnOsNewOSForm';
import FeedbackAlert, { FeedbackAlertType } from '../../../../../components/FeedbackAlert';
import { EngOSManagement } from './EngOsManagement';
import { EngOsSearch } from './EngOsSearch';
import { EngOsAttendance } from './EngOsAttendance';
import { EngOsReport } from './EngOsReport';
import { useUrlQuery } from '../../../../../hooks/useUrlQuery';
import { engOsSituationDescription, EngOsSituationType } from '..';
import api from '../../../../../services/api';

// type ViewType = 'dashboard' | 'requests' | 'attendance' | 'management' | 'report';

type FilterType = {
  text: string;
  key: string;
  disabled: boolean;
  icon: ReactNode;
};
type ListType = {
  text: string;
  key: string;
  disabled: boolean;
  icon: ReactNode;
  filters: FilterType[];
};

type RulesType = {
  value: string;
  allow: string;
};

type PermissionsType = {
  manager: boolean;
};

let lastPermition: PermissionsType = {
  manager: false,
};

export default function HelpDeskHomePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();

  const currentUrlView = urlQuery.get('view') ?? 'dashboard';
  const currentUrlFilter = urlQuery.get('filter') ?? 'started';
  const currentUrlPage = urlQuery.get('page') ?? '1';

  const widthSmLess = useMediaQuery(theme.breakpoints.down('sm'));

  const [permissions, setPermissions] = useState<PermissionsType>(lastPermition);

  const viewList: ListType[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
      disabled: false,
      icon: <DashboardOutlinedIcon />,
      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'started', text: 'Iniciados', disabled: false, icon: <PlayCircleOutlineIcon /> },
        { key: 'paused', text: 'Parados', disabled: false, icon: <PauseCircleOutlineIcon /> },
        { key: 'ended', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'attendance',
      text: 'Atendimento',
      disabled: false,
      icon: <AssignmentTurnedInOutlinedIcon />,
      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'started', text: 'Iniciados', disabled: false, icon: <PlayCircleOutlineIcon /> },
        { key: 'paused', text: 'Parados', disabled: false, icon: <PauseCircleOutlineIcon /> },
        { key: 'ended', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'search',
      text: 'Pesquisar',
      disabled: false,
      icon: <ContentPasteSearchOutlinedIcon />,
      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'started', text: 'Iniciados', disabled: false, icon: <PlayCircleOutlineIcon /> },
        { key: 'paused', text: 'Parados', disabled: false, icon: <PauseCircleOutlineIcon /> },
        { key: 'ended', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'management',
      text: 'Gestão',
      disabled: false,
      icon: <InsertChartOutlinedIcon />,
      filters: [],
    },
    {
      key: 'report',
      text: 'Relatórios',
      disabled: true,
      icon: <ArticleOutlinedIcon />,
      filters: [],
    },
  ];
  const [fetching, setFetching] = useState(false);

  const selectedView = viewList.find(v => v.key === currentUrlView);
  const selectedFilter = selectedView?.filters?.find(v => v.key === currentUrlFilter);

  const [openNewOsDialog, setOpenNewOsDialog] = useState(false);

  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [feedbackType, setFeedbackType] = useState<FeedbackAlertType>('success');

  const checkPermition = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get(`intranet/users/my`, {
        params: {},
      });
      const rules: RulesType[] = data?.rules ?? [];
      const manager =
        ((rules.find(r => r.value === 'erp/engineering/os/manager') as RulesType) ?? ({} as RulesType))?.allow ===
          '1' ?? false;

      setPermissions({
        manager,
      });
      lastPermition = {
        manager,
      };
    } catch (error) {
      const { data: response } = error.response;
      if (response.message === 'invalid_os') {
      }
    }
    setFetching(false);
  };

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      let filter = 'all';
      if (nextView === 'attendance') filter = 'started';
      else if (nextView === 'dashboard') filter = 'started';
      const params = new URLSearchParams({ view: nextView, filter });
      navigate({ search: params.toString() });
    }
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, nextFilter: string) => {
    if (nextFilter !== null) {
      const params = new URLSearchParams({ view: currentUrlView, filter: nextFilter });
      navigate({ search: params.toString() });
    }
  };

  const handleAddNewOsClick = () => {
    setOpenNewOsDialog(true);
  };

  const handleOnCloseNewOsDialog = () => {
    setOpenNewOsDialog(false);
  };

  function ListOption(props: { icon: ReactNode; text: string }) {
    const { icon, text } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flex: 1,
          justifyContent: widthSmLess ? 'center' : 'flex-start',
          alignItems: 'center',
        }}
      >
        {icon}
        <Typography sx={{ display: widthSmLess ? 'none' : 'flex' }}>{text}</Typography>
      </Box>
    );
  }

  const onSubmited = (success: boolean, msg: string) => {
    setFeedbackMsg('');
    let text = msg;
    if (success) {
      setFeedbackType('success');
      setOpenNewOsDialog(false);
    } else {
      setFeedbackType('error');
      if (text === 'invalid_expected_end_date') {
        text = 'Data fim previsto não pode ser vazia ou menor que a data de início previsto.';
      }
    }
    setFeedbackMsg(text);
  };

  const renderMenu = useMemo(
    () => (
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
        {widthSmLess && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
            <Typography>{selectedView?.text?.toUpperCase()}</Typography>
          </Box>
        )}
        <ToggleButtonGroup
          orientation={widthSmLess ? 'horizontal' : 'vertical'}
          value={selectedView?.key}
          exclusive
          onChange={handleViewChange}
          fullWidth
        >
          {viewList?.map((v, i) => {
            const { icon, text } = v;
            const key = `${v.key}-${i}`;
            const disabled = v.key === 'management' ? !permissions?.manager ?? true : v.disabled;
            return (
              <ToggleButton value={v.key} aria-label="list" key={key} color="info" fullWidth disabled={disabled}>
                <ListOption text={text} icon={icon} />
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Paper>
    ),
    [selectedView, widthSmLess, permissions],
  );

  const renderQuickFilter = useMemo(
    () => (
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flex: 1,
            color: theme.palette.mode === 'dark' ? '#c1c1c1' : '#767676',
          }}
        >
          <FilterAltOutlinedIcon />
          <Typography>Filtro rápido</Typography>
        </Box>
        {widthSmLess && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
            <Typography>{selectedFilter?.text?.toUpperCase()}</Typography>
          </Box>
        )}
        <ToggleButtonGroup
          orientation={widthSmLess ? 'horizontal' : 'vertical'}
          value={selectedFilter?.key}
          exclusive
          onChange={handleFilterChange}
          fullWidth
        >
          {selectedView?.filters.map((filter, index) => {
            const { icon, text, key } = filter;
            const keyList = `${filter.key}-${index}`;
            return (
              <ToggleButton
                value={key}
                aria-label="list"
                key={keyList}
                color="info"
                fullWidth
                disabled={filter.disabled}
              >
                <ListOption text={text} icon={icon} />
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Paper>
    ),
    [selectedView, selectedFilter, widthSmLess],
  );

  const renderContent = useMemo(
    () => (
      <>
        {currentUrlView === 'dashboard' && (
          <EnOsDashboard
            filters={{
              situation:
                currentUrlFilter === 'ended'
                  ? (['ended', 'ended_late'] as EngOsSituationType[]).map(f => ({
                      id: f,
                      description: engOsSituationDescription(f),
                    }))
                  : currentUrlFilter === 'started'
                    ? (['started', 'started_late', 'paused', 'paused_late'] as EngOsSituationType[]).map(f => ({
                        id: f,
                        description: engOsSituationDescription(f),
                      }))
                    : currentUrlFilter === 'paused'
                      ? (['paused', 'paused_late', 'not_started', 'not_started_late'] as EngOsSituationType[]).map(
                          f => ({
                            id: f,
                            description: engOsSituationDescription(f),
                          }),
                        )
                      : [],
            }}
          />
        )}
        {currentUrlView === 'attendance' && (
          <EngOsAttendance
            filters={{
              situation:
                currentUrlFilter === 'ended'
                  ? (['ended', 'ended_late'] as EngOsSituationType[]).map(f => ({
                      id: f,
                      description: engOsSituationDescription(f),
                    }))
                  : currentUrlFilter === 'started'
                    ? (['started', 'started_late', 'paused', 'paused_late'] as EngOsSituationType[]).map(f => ({
                        id: f,
                        description: engOsSituationDescription(f),
                      }))
                    : currentUrlFilter === 'paused'
                      ? (['paused', 'paused_late', 'not_started', 'not_started_late'] as EngOsSituationType[]).map(
                          f => ({
                            id: f,
                            description: engOsSituationDescription(f),
                          }),
                        )
                      : [],
            }}
          />
        )}
        {currentUrlView === 'search' && (
          <EngOsSearch
            filters={{
              situation:
                currentUrlFilter === 'ended'
                  ? (['ended', 'ended_late'] as EngOsSituationType[]).map(f => ({
                      id: f,
                      description: engOsSituationDescription(f),
                    }))
                  : currentUrlFilter === 'started'
                    ? (['started', 'started_late'] as EngOsSituationType[]).map(f => ({
                        id: f,
                        description: engOsSituationDescription(f),
                      }))
                    : currentUrlFilter === 'paused'
                      ? (['paused', 'paused_late', 'not_started', 'not_started_late'] as EngOsSituationType[]).map(
                          f => ({
                            id: f,
                            description: engOsSituationDescription(f),
                          }),
                        )
                      : [],
            }}
          />
        )}
        {currentUrlView === 'management' && <EngOSManagement />}
        {currentUrlView === 'report' && <EngOsReport />}
      </>
    ),
    [currentUrlView, currentUrlFilter],
  );

  const renderNewOsForm = useMemo(
    () => (
      <EnOsNewOSForm
        open={openNewOsDialog}
        handleClose={handleOnCloseNewOsDialog}
        handleOpen={() => {}}
        onSubmited={onSubmited}
      />
    ),
    [openNewOsDialog],
  );

  useEffect(() => {
    checkPermition();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flex: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', flex: 1, gap: 2 }}>
              <Button
                variant="contained"
                fullWidth
                color="success"
                size="large"
                onClick={handleAddNewOsClick}
                disabled={!permissions?.manager}
              >
                NOVA O.S.
              </Button>
            </Box>
            {renderMenu}
            {selectedView?.filters?.length > 0 && renderQuickFilter}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
          {renderContent}
        </Grid>
      </Grid>
      {renderNewOsForm}
      <FeedbackAlert message={feedbackMsg} show={feedbackMsg?.length > 0} type={feedbackType} />
    </Box>
  );
}
