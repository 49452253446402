import React, { useMemo, useState } from 'react';

import { Box, Button, useMediaQuery } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { useUrlQuery } from '../../../../../hooks/useUrlQuery';
import PageHomeWithMenu, { ListType } from '../../../../../components/PageHomeWithMenu';
import { Requests } from '../Requests';
import { FormBase } from '../../../../../components/SearchForm/FormBase';
import RequestsAdd from '../add';

export default function RequestsDashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();
  const currentUrlView = urlQuery.get('view');

  const [open, setOpen] = useState(false);

  const widthSmLess = useMediaQuery(theme.breakpoints.down('sm'));

  const viewList: ListType[] = [
    {
      key: 'add',
      text: 'Nova Requisição',
      disabled: false,
      hidden: true,
      icon: null,
      content: <RequestsAdd />,
      filters: [],
    },
    {
      key: 'requests',
      text: 'Requisições',
      disabled: false,
      icon: <AssignmentLateOutlinedIcon />,
      content: <Requests />,

      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'pending', text: 'Pedente Apr.', disabled: false, icon: <ErrorOutlineOutlinedIcon /> },
        { key: 'canceled', text: 'Canceladas', disabled: false, icon: <CancelOutlinedIcon /> },
        { key: 'endend', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'attendance',
      text: 'Atendimento',
      disabled: false,
      icon: <AssignmentTurnedInOutlinedIcon />,
      content: <span>Atendimento</span>,

      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'pending', text: 'Pedente Atd.', disabled: false, icon: <ErrorOutlineOutlinedIcon /> },
        { key: 'canceled', text: 'Canceladas', disabled: false, icon: <CancelOutlinedIcon /> },
        { key: 'endend', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'search',
      text: 'Pesquisar',
      disabled: false,
      icon: <ContentPasteSearchOutlinedIcon />,
      content: <span>Pesquisar</span>,

      filters: [
        { key: 'all', text: 'Sem Filtro', disabled: false, icon: <FilterAltOffOutlinedIcon /> },
        { key: 'started', text: 'Iniciados', disabled: false, icon: <PlayCircleOutlineIcon /> },
        { key: 'paused', text: 'Parados', disabled: false, icon: <PauseCircleOutlineIcon /> },
        { key: 'ended', text: 'Finalizados', disabled: false, icon: <CheckCircleOutlineIcon /> },
      ],
    },
    {
      key: 'report',
      text: 'Relatórios',
      disabled: true,
      icon: <AssignmentOutlinedIcon />,
      content: <span>Relatórios</span>,

      filters: [],
    },
    {
      key: 'management',
      text: 'Configurações',
      disabled: true,
      icon: <SettingsApplicationsOutlinedIcon />,
      content: <span>Configurações</span>,

      filters: [],
    },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column' }}>
      <PageHomeWithMenu
        viewList={viewList}
        defaultView="requests"
        topButtons={
          <Button
            variant="contained"
            fullWidth
            color="success"
            size="large"
            component={Link}
            to="/erp/stock/request?view=add"
          >
            NOVA REQUISIÇÃO
          </Button>
        }
      />
      <FormBase
        columns={[{ field: 'id' }, { field: 'text' }]}
        open={open}
        rows={[
          { id: 8, text: 'abc' },
          { id: 70, text: 'efg' },
          { id: 53, text: 'hij' },
        ]}
        title="teste"
        onSelect={rows => {
          console.log(rows);
        }}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
}
