import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    commentRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      padding: 8,
    },
    commentAvatar: {
      marginRight: 2,
      height: 48,
      width: 48,
      borderColor: '#eeeeee',
      backgroundColor: darkMode ? '#ef6c00' : '#e65100',
      fontSize: 26,
      textDecoration: 'none',
      '&:hover': {
        color: darkMode ? '#d6d6d6' : '#616161',
      },
    },
    commentPointer: {
      // background: darkMode ? '#585858' : '#ffffff',

      position: 'relative',
      transform: 'rotate(45deg)',
      padding: 6,
      boxSizing: 'border-box',
      background: darkMode ? '#585858' : '#ffffff',
      overflow: 'hidden',
      borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
      left: 5.5,
      bottom: 12,
    },
    commentPointerFlip: {
      // background: darkMode ? '#585858' : '#ffffff',

      position: 'relative',
      transform: 'rotate(45deg)',
      padding: 6,
      boxSizing: 'border-box',
      background: darkMode ? '#585858' : '#ffffff',
      overflow: 'hidden',
      borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
      right: 5.5,
      zIndex: 0,
      bottom: 12,
    },
    commentTitle: {
      color: darkMode ? '#ababab' : '#616161',
      marginBottom: 8,
    },
    commentBox: {
      backgroundColor: darkMode ? '#585858' : '#ffffff',
      padding: 4,
      minWidth: 192,
      zIndex: 1,
    },
    commenNametBox: {
      marginBottom: 1,
    },
    commentUserName: {
      paddingLeft: 6,
      fontSize: 14,
      fontWeight: 'bold',
      color: darkMode ? '#c2ff8a' : '#4e7b25',
    },
    commentUserNameMy: {
      color: darkMode ? '#cd8aff' : '#8730c7',
    },
    commentSystemName: {
      paddingLeft: 6,
      fontSize: 16,
      fontWeight: 'bold',
      color: darkMode ? '#84d9ff' : '#3c6f9c',
    },
    commentSystemIconName: {
      marginRight: 2,
      height: 48,
      width: 48,
    },

    commentText: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    commentSystemText: {
      paddingLeft: 6,
      paddingRight: 6,
      color: darkMode ? '#d2d2d2' : '#6d6d6d',
      '& a': {
        color: darkMode ? '#c2ff8a' : '#4e7b25',
        textDecoration: 'none',
      },
    },
    commentDate: {
      fontSize: 11,
      marginLeft: 8,
      marginRight: 8,

      textAlign: 'right',
      color: darkMode ? '#a0a0a0' : '#8c8c8c',
      fontStyle: 'italic',
    },
    commenDeleteicon: {
      fontSize: 16,

      marginRight: 8,
      cursor: 'pointer',
      color: darkMode ? '#a0a0a0' : '#8c8c8c',

      '&:hover': {
        color: darkMode ? '#ff9d9d' : '#ff5d5d',
      },
    },
  });
});
