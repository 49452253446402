import Axios, { Canceler } from 'axios';
import api from './api';

const token = localStorage.getItem('token');

class Auth {
  hasToken: string | boolean | null;

  isValidToken: boolean | undefined;

  constructor() {
    this.hasToken = token && token !== '';
  }

  login(login: string, password: string, remember: boolean, cb: any) {
    if (login?.length === 0) {
      return cb({
        status: 400,
        data: {
          message: 'empty_login',
        },
      });
    }

    if (password?.length === 0) {
      return cb({
        status: 400,
        data: {
          message: 'empty_password',
        },
      });
    }

    if (password?.length < 6) {
      return cb({
        status: 400,
        data: {
          message: 'invalid_password',
        },
      });
    }

    api
      .post('intranet/auth/login', {
        login,
        password,
        remember,
      })
      .then(response => {
        if (response !== undefined && response.data.token) {
          localStorage.setItem('token', response.data.token);
          this.hasToken = true;
          this.isValidToken = true;
          return cb(response);
        }

        this.isValidToken = false;

        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      })
      .catch(error => {
        if (typeof error.response !== 'undefined' && error.response != null) {
          return cb(error.response);
        }
        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      });
    return cb();
  }

  check(cb: any) {
    if (token && token !== '') {
      this.hasToken = true;
      let cancel: Canceler;
      api
        .get('intranet/auth/check', {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          if (typeof response !== 'undefined' && response != null) {
            this.isValidToken = true;
            return cb(response);
          }
          this.isValidToken = false;
          return cb({
            status: 400,
            data: {
              message: 'response_invalid',
            },
          });
        })
        .catch(error => {
          this.isValidToken = false;
          if (typeof error.response !== 'undefined' && error.response != null) {
            return cb(error.response);
          }
          console.error(error?.response);
          return cb({
            status: 400,
            data: {
              message: 'response_invalid',
            },
          });
        });
      return () => cancel?.();
    }
    this.isValidToken = false;
    return cb({
      status: 400,
      data: {
        message: 'invalid_token',
      },
    });
  }

  logout(cb: any) {
    localStorage.removeItem('token');
    this.isValidToken = false;
    this.hasToken = false;
    return cb();
  }

  recovery(login: string, cb: any) {
    if (login?.length === 0) {
      return cb({
        status: 400,
        data: {
          message: 'empty_login',
        },
      });
    }

    localStorage.removeItem('token');
    this.isValidToken = false;
    this.hasToken = false;
    api
      .post('intranet/auth/recovery', {
        login,
      })
      .then(response => {
        if (response !== undefined) {
          return cb(response);
        }
        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      })
      .catch(error => {
        if (typeof error.response !== 'undefined' && error.response != null) {
          return cb(error.response);
        }
        this.isValidToken = false;
        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      });
  }

  reset(resetToken: string, login: string, password: string, cb: any) {
    if (login?.length === 0) {
      return cb({
        status: 400,
        data: {
          message: 'empty_email',
        },
      });
    }
    if (resetToken === '' || resetToken === null) {
      return cb({
        status: 400,
        data: {
          message: 'empty_token',
        },
      });
    }
    if (password === '' || password === null) {
      return cb({
        status: 400,
        data: {
          message: 'empty_password',
        },
      });
    }
    if (password?.length < 4) {
      return cb({
        status: 400,
        data: {
          message: 'invalid_password',
        },
      });
    }
    localStorage.removeItem('token');
    this.isValidToken = false;
    this.hasToken = false;
    api
      .post('intranet/auth/reset', {
        token: resetToken,
        login,
        password,
      })
      .then(response => {
        if (response !== undefined) {
          localStorage.setItem('token', response.data.token);
          this.hasToken = true;
          this.isValidToken = true;
          return cb(response);
        }
        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      })
      .catch(error => {
        if (typeof error.response !== 'undefined' && error.response != null) {
          return cb(error.response);
        }
        this.isValidToken = false;

        return cb({
          status: 400,
          data: {
            message: 'response_invalid',
          },
        });
      });
    return cb();
  }
}
export default new Auth();
