import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Snackbar,
} from '@mui/material';

import { Alert } from '@mui/lab';
import api from '../../services/api';
import styles from './styles';

type ChangePasswordDialogProps = {
  show: boolean;
  force?: boolean;
  onClose?: () => void;
};
export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
  const { show = false, force = false, onClose } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  async function handleChangePassword() {
    if (!changingPassword) {
      try {
        const { data } = await api.post('intranet/my/password/reset', {
          currentPassword,
          newPassword: newPassword2,
        });
        localStorage.setItem('token', data.token);
        setShowDialog(false);
        setChangingPassword(false);
        setCurrentPassword('');
        setNewPassword1('');
        setNewPassword2('');
      } catch (error: any) {
        let msg = error?.response?.data?.message;
        if (!msg) msg = error?.response?.data;
        else if (msg === 'empty_current_password') msg = 'A senha atual não pode estar vazia';
        else if (msg === 'empty_new_password') msg = 'A nova senha não pode estar vazia';
        else if (msg === 'invalid_new_password') msg = 'A senha nova não é válida';
        else if (msg === 'wrong_password') msg = 'A senha atual não é válida';
        else if (msg === 'same_password') msg = 'A senha não pode ser igual a anterior';
        else msg = '';
        setSnackbarType('error');
        setSnackbarMsg(msg);
        setOpenSnackbar(true);
        setChangingPassword(false);
      }
    }
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  function handleChangePasswordDialogClose() {
    if (force || changingPassword) {
      return;
    }
    onClose?.();
    setShowDialog(false);
  }

  function handleCurrentPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentPassword(event.target.value);
  }

  function handlePassword1Change(event: React.ChangeEvent<HTMLInputElement>) {
    setNewPassword1(event.target.value);
  }

  function handlePassword2Change(event: React.ChangeEvent<HTMLInputElement>) {
    setNewPassword2(event.target.value);
  }

  function currentPasswordCheck() {
    if (currentPassword.trim()?.length === 0) {
      return 'A senha não pode estar em branco';
    }
    return '';
  }

  function password1Check() {
    if (newPassword1.trim()?.length < 6) {
      return 'A nova senha precisa ter 6 caracteres ou mais';
    }
    if (newPassword1 === currentPassword) {
      return 'A nova senha não pode ser igual a atual';
    }
    return '';
  }

  function password2Check() {
    if (newPassword2.trim()?.length < 6) {
      return 'A nova senha precisa ter 6 caracteres ou mais';
    }
    if (newPassword2 !== newPassword1) {
      return 'As senhas não são iguais';
    }
    return '';
  }

  return (
    <Dialog open={showDialog} onClose={handleChangePasswordDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Alteração de senha</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para alterar a senha digite a atual e em seguida digite a nova e confirme.
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="current-password"
          label="Senha atual"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          error={currentPasswordCheck() !== ''}
          helperText={currentPasswordCheck()}
        />
        <TextField
          margin="dense"
          id="new-password-1"
          label="Nova senha"
          type="password"
          fullWidth
          value={newPassword1}
          onChange={handlePassword1Change}
          error={password1Check() !== ''}
          helperText={password1Check()}
        />
        <TextField
          margin="dense"
          id="new-password-2"
          label="Repetir nova senha"
          type="password"
          fullWidth
          value={newPassword2}
          onChange={handlePassword2Change}
          error={password2Check() !== ''}
          helperText={password2Check()}
        />
      </DialogContent>
      <DialogActions>
        {!force && (
          <Button onClick={handleChangePasswordDialogClose} color="secondary">
            Cancelar
          </Button>
        )}
        <Button
          onClick={handleChangePassword}
          color="primary"
          disabled={currentPasswordCheck() !== '' || password1Check() !== '' || password2Check() !== ''}
        >
          Alterar
        </Button>
      </DialogActions>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
