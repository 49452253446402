import React, { useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useNavigate } from 'react-router-dom';
import { OSList, OSListFilterType } from './OSList';
import InputSearchAsync, { ValueProps } from '../../../../../../components/inputs/InputSearchAsync';
import { EngOsHomeTitle } from '../EngOsHomeTitle';

interface EnOsDashboardProps {
  filters?: OSListFilterType;
}

function EnOsDashboard({ filters: dashboardFilters }: EnOsDashboardProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [filters, setFilters] = useState({
    budgets: [],
    customers: [],
    orders: [],
    helpdesks: [],
    attendants: [],
    situation: dashboardFilters?.situation ?? [],
  } as OSListFilterType);

  useEffect(() => {
    setFilters(old => ({ ...old, ...dashboardFilters }));
  }, [dashboardFilters]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <EngOsHomeTitle
          title="Dashboard"
          initialFilter={dashboardFilters}
          filterList={{
            attendants: false,
            tasks: false,
            products: false,
            os: false,
            rework: false,
            started_at: false,
            ended_at: false,
            created_at: false,
            created_by: false,
            started_by: false,
            ended_by: false,
          }}
          onChange={_filters => {
            setFilters(old => ({ ...old, ..._filters }));
          }}
        />
        <Paper sx={{ p: 2 }}>
          <Box sx={{ pb: 1.5, display: 'flex', gap: 1, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ color: isDarkMode ? '#c1c1c1' : '#767676' }}>Por Pedido/Orçamento</Typography>
          </Box>
          <OSList filters={filters} />
        </Paper>
      </Box>
    </Box>
  );
}

export { EnOsDashboard };
