import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, deepPurple } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      '& .MuiDrawer-paper': {
        backgroundColor: darkMode ? '#333333' : '#fafafa',
      },
    },
    grow: {
      flexGrow: 1,
    },
    topBar: {
      position: 'fixed',
      width: theme.spacing(60),
      maxWidth: '100vw',
      backgroundColor: darkMode ? grey[800] : '#f1f1f1',
      zIndex: 2,
    },
    drawer: {
      width: theme.spacing(60),
      maxWidth: '100vw',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& > *': {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
      },
    },
    itemRoot: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    filterButton: {
      position: 'fixed',
      top: theme.spacing(9),
      right: theme.spacing(1.5),
      zIndex: 99,
    },
    filterButtonColor: {
      color: darkMode ? grey[50] : grey[100],
      backgroundColor: darkMode ? deepPurple[400] : deepPurple[800],
      '&:hover': {
        backgroundColor: deepPurple[600],
      },
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        color: grey[500],

        backgroundColor: deepPurple[300],
        '&:hover': {
          backgroundColor: deepPurple[300],
        },
      },
    },
    highlightInput: {
      color: darkMode ? '#94e573' : '#348015',
      '& .MuiSelect-selectMenu, input': {
        color: darkMode ? '#94e573' : '#348015',
      },
    },
  });
});
