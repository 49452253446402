import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import HourBankIcon from '@mui/icons-material/AccessTime';
import axios, { Canceler } from 'axios';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import api from '../../services/api';

type Hours = {
  id: string;
  type: string;
  number: string;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  date_now: string;
  hours_total: string;
  hours_expiration: number;
  date_expiration: string;
  hours_total_formated: string;
  hours_expiration_formated: string;
  date_expiration_formated: string;
  last_date_updated: string;
  last_date_updated_formated: string;
};

function HourBankCard() {
  const [responseData, setResponseData] = useState<Hours[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const { data } = await api.get<Hours[]>('hcm/hours/total', {
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setResponseData(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <HourBankIcon sx={{ color: theme => (theme.palette.mode === 'dark' ? blue[300] : blue[700]) }} />
          <Typography>Saldo Banco de Horas</Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {loadingData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRight: theme => `solid 1px ${theme.palette.mode === 'dark' ? grey[700] : grey[300]}`,
                }}
              >
                <Skeleton height={18} width={170} variant="rectangular" sx={{ borderRadius: 1, mb: 1 }} />
                <Skeleton height={26} width={70} variant="rectangular" sx={{ borderRadius: 1, mb: 0.4 }} />
                <Skeleton height={12} width={32} variant="rectangular" sx={{ borderRadius: 1 }} />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',

                  alignItems: 'center',
                }}
              >
                <Skeleton height={18} width={110} variant="rectangular" sx={{ borderRadius: 1, mb: 1 }} />
                <Skeleton height={26} width={70} variant="rectangular" sx={{ borderRadius: 1, mb: 0.4 }} />
                <Skeleton height={12} width={32} variant="rectangular" sx={{ borderRadius: 1 }} />
              </Box>
            </Box>
          ) : responseData?.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 1,
                minHeight: 60,
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              <Typography>Nenhum banco de horas encontrado</Typography>
            </Box>
          ) : (
            responseData.map((data, i) => {
              const {
                id,
                date_expiration_formated,
                hours_expiration_formated,
                hours_total_formated,
                hours_expiration,
                last_date_updated_formated,
              } = data;
              const key = `${i}-${id}`;
              return (
                <Box
                  key={key}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      justifyContent: 'center',
                      gap: 1,
                      pl: 1,
                      pr: 1,
                      alignItems: 'center',
                      borderRight: theme => `solid 1px ${theme.palette.mode === 'dark' ? grey[700] : grey[300]}`,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                      }}
                    >
                      <Typography>Vencendo:</Typography>
                      <Typography
                        sx={{
                          ml: 0.5,
                          color: theme => (theme.palette.mode === 'dark' ? orange[300] : orange[700]),
                        }}
                      >
                        {date_expiration_formated}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '1.5rem',
                          fontWeight: 'bold',
                          lineHeight: '1.3rem',

                          color: theme => {
                            let newColor = '';
                            const isDark = theme.palette.mode === 'dark';
                            if (hours_expiration >= 0) {
                              newColor = isDark ? green[200] : green[600];
                            } else {
                              newColor = isDark ? red[200] : red[600];
                            }
                            return newColor;
                          },
                        }}
                      >
                        {hours_expiration_formated}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.6rem',
                          color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                        }}
                      >
                        horas
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      justifyContent: 'center',
                      gap: 1,
                      pl: 1,
                      pr: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                      }}
                    >
                      Saldo Total
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '1.5rem',
                          fontWeight: 'bold',
                          lineHeight: '1.3rem',
                          color: theme => {
                            let newColor = '';
                            const isDark = theme.palette.mode === 'dark';
                            if (hours_expiration >= 0) {
                              newColor = isDark ? green[200] : green[600];
                            } else {
                              newColor = isDark ? red[200] : red[600];
                            }
                            return newColor;
                          },
                        }}
                      >
                        {hours_total_formated}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.6rem',
                          color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                        }}
                      >
                        horas
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '0.6rem',
                        color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
                      }}
                    >
                      {`Atualização: ${last_date_updated_formated} `}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          )}
        </Box>

        <Box
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            borderTop: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Button size="small" variant="outlined" color="info" component={Link} disabled to="/hcm/hours">
            Detalhes
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export { HourBankCard };
