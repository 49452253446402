import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Pagination, Typography } from '@mui/material';

import axios, { Canceler } from 'axios';
import { grey } from '@mui/material/colors';
import styles from './styles';
import { ColumnType, DataTable, RowType } from '../../../../components/DataTable';
import { ValueProps } from '../../../../components/inputs/InputSearchAsync';
import api from '../../../../services/api';
import Filters, { FiltersInputsProps } from '../../../../components/Filters';

import { OrderDetails } from './OrderDetails';
import { CircularProgressWithLabel } from '../../../../components/CircularProgressWithLabel';

export type StageType = {
  codetg: number;
  desetg: string;
  proini: string;
  proini_f: string;
  siteop_E: number;
  siteop_L: number;
  siteop_A: number;
  siteop_F: number;
  siteop_S: number;
  siteop_R: number;
  total: number;
  qtdprv: number;
  qtduti: number;
  diapro: number;
  prgpro: number;
  ritpro: number;
};

export type ItemType = {
  seqipd: number;
  codpro: string;
  despro: string;
  codder: string;
  qtdped: number;
  datest: string;
  ritpro: number;
  prgpro: number;
  qtdsol: number;
  qtdatd: number;
  prgsup: number;
  qtdprv: number;
  qtduti: number;
  prgexp: number;
  prgtot: number;
  obsct1: string;
  obsct2: string;
  datest_f: string;
  stages: StageType[];
};

export type OrderType = {
  numped: number;
  codcli: number;
  nomcli: string;
  datger: string;
  datprv: string;
  datest: string;
  ritpro: number;
  prgpro: number;
  prgsup: number;
  prgexp: number;
  prgtot: number;
  tipped: number;
  tipped_d: string;
  datest_f: string;
  datger_f: string;
  datprv_f: string;
  items: ItemType[];
};

type DataFiltersType = {
  limit: number;
  page: number;
  numped: number[];
  codcli: number[];
};

type DataStatisticsType = {
  total: {
    results: number;
    pages: number;
  };
  updated_at: string;
  updated_at_f: string;
  edit: boolean;
};

type FollowUpDataType = {
  results: OrderType[];
  filters: DataFiltersType;
  statistics: DataStatisticsType;
};

type OrderByType =
  | 'numped'
  | 'codcli'
  | 'nomcli'
  | 'datger'
  | 'datprv'
  | 'datest'
  | 'ritpro'
  | 'prgpro'
  | 'prgsup'
  | 'prgexp'
  | 'prgtot';

type OrderDirType = 'asc' | 'desc';

type FiltersType = {
  limit?: number;
  page?: number;

  datger_start?: string;
  datger_end?: string;

  datprv_start?: string;
  datprv_end?: string;

  datest_start?: string;
  datest_end?: string;

  order_by?: OrderByType;
  order_dir?: OrderDirType;
  numped?: number[];
  codcli?: number[];
  tipped?: number[];
};

type LoadOrderDataType = {
  page?: number;
  limit?: number;
  order_by?: OrderByType;
  order_dir?: OrderDirType;
  numped?: number[];
  codcli?: number[];
  tipped?: number[];
  datger_start?: string;
  datger_end?: string;
  datprv_start?: string;
  datprv_end?: string;
  force?: number;
};

function FollowUp() {
  const classes = styles();
  const mountedRef = useRef(true);
  const [loadingData, setLoadingData] = useState(false);
  const [followUpData, setFollowUpData] = useState<OrderType[]>([]);
  const [statisticsData, setStatisticsData] = useState<DataStatisticsType>({
    total: { pages: 0, results: 0 },
    updated_at: '',
    updated_at_f: '00/00/0000 00:00',
  } as DataStatisticsType);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState<FiltersType>({
    page: 1,
    limit: 25,
    order_by: 'numped',
    order_dir: 'asc',
    numped: [],
    codcli: [],
    tipped: [],
    datger_start: '',
    datger_end: '',
    datprv_start: '',
    datprv_end: '',
  });

  const filtersInputs = [
    {
      type: 'combobox',
      id: 'erp-orders-follow-up-search-limit',
      label: 'Registros por página',
      defaultValue: 25,
      comboBoxItems: [
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 150, text: '150' },
        { value: -1, text: 'Tudo (Não recomendável)' },
      ],
    },
    {
      type: 'date',
      id: 'erp-orders-follow-up-search-created-start',
      label: 'Data Geração (Inicio)',
      dateType: 'start',
      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-follow-up-search-created-end',
      label: 'Data Geração (Fim)',
      dateType: 'end',
      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-follow-up-search-deadline-start',
      label: 'Data Prevista (Inicio)',
      dateType: 'start',
      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-follow-up-search-deadline-end',
      label: 'Data Prevista (Fim)',
      dateType: 'end',
      defaultValue: '',
    },
    {
      type: 'erp-orders-type',
      id: 'erp-orders-follow-up-search-type',
      defaultValue: '[]',
      resultLimit: 100,
    },
    {
      type: 'customers',
      id: 'erp-orders-follow-up-search-customers',
      defaultValue: '[]',
      resultLimit: 100,
    },
    {
      type: 'order',
      id: 'erp-orders-follow-up-search-id',
      label: 'Número Pedido(+)',
      placeholder: 'Pesquisar Número Pedido',
      noOptionsText: 'Nenhum pedido encontrado.',
      defaultValue: '[]',
      resultLimit: 100,
    },
  ] as FiltersInputsProps[];

  const columns: ColumnType[] = [
    { id: '', content: '', orderable: false, style: { minWidth: 50, width: 50, maxWidth: 50 } },
    { id: 'numped', align: 'center', content: 'Número', orderable: true },
    { id: 'tipped', align: 'left', content: 'Tipo', orderable: false, style: { minWidth: 90, width: 90 } },
    { id: 'codcli', align: 'center', content: 'Cliente', orderable: true },
    { id: 'nomcli', content: 'Nome', orderable: true },
    { id: 'datger', align: 'center', content: 'Data Abertura', orderable: true },
    { id: 'datprv', align: 'center', content: 'Data Prevista', orderable: true },
    { id: 'datest', align: 'center', content: 'Data Estimada', orderable: true },
    { id: 'ritpro', align: 'center', content: 'Ritmo Produtivo', orderable: true },
    { id: 'prgpro', align: 'center', content: 'Produção', orderable: true },
    { id: 'prgsup', align: 'center', content: 'Suprimentos', orderable: true },
    { id: 'prgexp', align: 'center', content: 'Expedição', orderable: true },
    { id: 'prgtot', align: 'center', content: 'Progresso', orderable: true },
  ];

  const rows: RowType[] = followUpData?.map(order => {
    return {
      cells: [
        { content: order.numped.toLocaleString(), align: 'center' },
        { content: `${order.tipped} - ${order.tipped_d}`, align: 'left', style: { minWidth: 110, width: 110 } },
        { content: order.codcli.toLocaleString(), align: 'center' },
        { content: order.nomcli },
        { content: order.datger_f, align: 'center' },
        { content: order.datprv_f, align: 'center' },
        { content: order.datest_f, align: 'center' },
        { content: `${order.ritpro}%`, align: 'center' },
        { content: <CircularProgressWithLabel value={order.prgpro} />, align: 'center' },
        { content: <CircularProgressWithLabel value={order.prgsup} />, align: 'center' },
        { content: <CircularProgressWithLabel value={order.prgexp} />, align: 'center' },
        { content: <CircularProgressWithLabel value={order.prgtot} />, align: 'center' },
      ],
      content: <OrderDetails order_id={order.numped} items={order.items} edit={statisticsData.edit} />,
    } as RowType;
  });

  async function loadOrderData(filters: LoadOrderDataType) {
    if (!mountedRef.current) return;
    const force = filters?.force && filters?.force === 1;
    if (!force && loadingData) return;
    const {
      codcli = filter.codcli,
      limit = filter.limit,
      numped = filter.numped,
      order_by = filter.order_by,
      order_dir = filter.order_dir,
      page = filter.page,
      tipped = filter.tipped,
      datger_start = filter.datger_start,
      datger_end = filter.datger_end,
      datprv_start = filter.datprv_start,
      datprv_end = filter.datprv_end,
    } = filters;

    setLoadingData(true);
    let cancel: Canceler;

    setFilter({
      page,
      limit,
      numped,
      codcli,
      order_by,
      order_dir,
      tipped,
      datger_start,
      datger_end,
      datprv_start,
      datprv_end,
    });
    try {
      const { data } = await api.get<FollowUpDataType>('erp/orders/follow-up', {
        params: {
          limit,
          page,
          order_by,
          order_dir,
          numped,
          codcli,
          tipped,
          datger_start,
          datger_end,
          datprv_start,
          datprv_end,
        },
        cancelToken: new axios.CancelToken(c => {
          cancel = c;
        }),
      });
      if (data) {
        setStatisticsData(data?.statistics);
        setFollowUpData(data?.results);
        setCurrentPage(data?.filters?.page || 1);
      }
      setLoadingData(false);
    } catch (error: any) {
      const data = error?.response?.data;
      if (data) {
        let errorMessage = data?.message;
        if (!errorMessage) errorMessage = data?.error;
        if (errorMessage === '') errorMessage = '';
        else if (errorMessage === '') errorMessage = '';
        else errorMessage = '';

        // setSnackbarType('error');
        // setSnackbarMsg(errorMessage);
        // setOpenSnackbar(true);
      }
    }
    return () => cancel();
  }

  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > statisticsData?.total?.pages || 0) return;

    loadOrderData({ page });
  };
  const handleSort = (by: OrderByType, dir: OrderDirType) => {
    loadOrderData({ order_by: by, order_dir: dir });
  };

  const handleApplyFilter = (e: any[]) => {
    const newFilter: FiltersType = {
      limit: Number(e[0]),
      datger_start: e[1],
      datger_end: e[2],
      datprv_start: e[3],
      datprv_end: e[4],
      tipped: e[5]?.map((c: ValueProps) => {
        return c.id;
      }),
      codcli: e[6]?.map((c: ValueProps) => {
        return c.id;
      }),
      numped: e[7]?.map((c: ValueProps) => {
        return c.id;
      }),
    };

    loadOrderData({
      page: 1,
      limit: newFilter.limit,
      numped: newFilter.numped,
      codcli: newFilter.codcli,
      tipped: newFilter.tipped,
      datger_start: newFilter.datger_start,
      datger_end: newFilter.datger_end,
      datprv_start: newFilter.datprv_start,
      datprv_end: newFilter.datprv_end,
    });
    setFilter(newFilter);
  };

  useEffect(() => {
    loadOrderData({ page: 1 });
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        // initialInputsValue={inputsValue}
      />
      <DataTable
        columns={columns}
        rows={rows}
        className={classes.tableContainer}
        loading={loadingData}
        sx={{ width: 'max-content' }}
        onSort={handleSort}
      />

      <Box display="flex" width="100%" sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>{`${
          statisticsData?.total?.results || 0
        } Registros encontrados `}</Typography>

        <Pagination
          size="medium"
          count={statisticsData?.total?.pages || 0}
          page={currentPage}
          variant="outlined"
          shape="rounded"
          disabled={loadingData || statisticsData?.total?.pages <= 1}
          onChange={handlePagination}
        />
        <Typography
          variant="caption"
          sx={{
            fontSize: 9,
            marginTop: 1,
            color: theme => {
              const dark = theme.palette.mode === 'dark';
              return dark ? grey[400] : grey[700];
            },
          }}
        >
          {`Atualizado em: ${statisticsData?.updated_at_f}`}{' '}
          <Button
            onClick={() => {
              loadOrderData({
                page: 1,
                limit: filter.limit,
                numped: filter.numped,
                codcli: filter.codcli,
                tipped: filter.tipped,
                datger_start: filter.datger_start,
                datger_end: filter.datger_end,
                datprv_start: filter.datprv_start,
                datprv_end: filter.datprv_end,
                force: 1,
              });
            }}
            sx={{ fontSize: 9 }}
          >
            Forçar atualização
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}

export { FollowUp };
