import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Axios, { Canceler } from 'axios';
import { Link, LinkProps } from 'react-router-dom';
import latinize from 'latinize';
import Highlighter from 'react-highlight-words';

import {
  Drawer,
  DrawerProps,
  Collapse,
  ListItemProps,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  Divider,
  List,
  IconButton,
  Paper,
  InputBase,
  Box,
  Tooltip,
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import styles from './styles';
import api from '../../services/api';

interface MenuListProps extends DrawerProps {
  show: boolean;
  onClose?: () => void;
}

type ItemMenuType = {
  id: number;
  parent: number;
  position: number;
  type: string;
  title: string;
  icon: string;
  path: string;
  situation: number;
  childrens: ItemMenuType[];
};

interface SubListItemMenuProps extends ListItemProps {
  item: ItemMenuType;
  childrens?: any;
  styles?: React.CSSProperties;
}

interface ListItemMenuProps extends ListItemProps {
  icon: string;
  title: string;
  path: string;
  type: string;
}

function ListItemMenu(listItemMenuProps: ListItemMenuProps & { onClose: any; classes: any; textSearch: any }) {
  const { path = '', icon = '', title = '', className = '', onClose, classes, textSearch } = listItemMenuProps;
  const links = forwardRef<HTMLAnchorElement, Partial<LinkProps>>((linksProps, ref) => {
    return <Link to={`/${path}`} {...linksProps} ref={ref as any} />;
  });
  // links.displayName = 'Link';
  return (
    <ListItem component={links} button key={0} color="textPrimary" onClick={() => onClose()} className={className}>
      {icon !== '' && (
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Highlighter
            highlightClassName={classes.resultTextHighlights}
            searchWords={textSearch.split(' ')}
            autoEscape
            textToHighlight={title}
            sanitize={latinize}
          />
        }
      />
    </ListItem>
  );
}

function SubListItemMenu(p: SubListItemMenuProps & { textSearch: any; classes: any; onClose: any }) {
  const { item, childrens, style, textSearch, classes, onClose } = p;

  const [open, setOpen] = useState(textSearch !== '');
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box style={style}>
      {item.type === 'item' ? (
        <ListItemMenu
          key={`other-${item.id}`}
          icon={item.icon}
          title={item.title}
          path={item.path}
          type={item.type}
          classes={classes}
          onClose={onClose}
          textSearch={textSearch}
        />
      ) : (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Highlighter
                  highlightClassName={classes.resultTextHighlights}
                  searchWords={textSearch.split(' ')}
                  autoEscape
                  textToHighlight={item.title}
                  sanitize={latinize}
                />
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.subMenuList}>
              {childrens}
            </List>
          </Collapse>
        </>
      )}
    </Box>
  );
}

export default function MenuListDrawer(props: MenuListProps) {
  const { show = false, onClose = () => {} } = props;
  const [data, setData] = useState<ItemMenuType[]>([]);
  const [textSearch, setTextSearch] = useState('');
  const refInputSearch = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const classes = styles();

  useEffect(() => {
    getUserData({ search: '' });
  }, []);

  async function getUserData({ search = '' }) {
    setLoading(true);
    let cancel: Canceler;
    try {
      const params = {
        search,
      };
      const response = await api.get('intranet/configs/sidebar-menu', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      setData(response?.data);
    } catch (error: any) {}
    setLoading(false);

    return () => cancel();
  }

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const search = e?.currentTarget?.value;
    setTextSearch(search);
  }

  useEffect(
    () => {
      setLoading(true);
      const delay = setTimeout(() => {
        getUserData({ search: textSearch });
        // Send Axios request here
      }, 400);

      return () => clearTimeout(delay);
    },
    [textSearch], // Only call effect if debounced search term changes
  );

  function handleClearSearch() {
    setTextSearch('');
    getUserData({ search: '' });
    if (refInputSearch.current) {
      const input = refInputSearch.current.children[0] as HTMLInputElement;
      input.value = '';
    }
  }

  return (
    <Box className={classes.root}>
      <Drawer
        anchor="left"
        open={show}
        onClose={() => {
          onClose();
        }}
        className={classes.container}
      >
        <Box className={classes.searchRoot}>
          <Paper className={classes.inputRoot}>
            <Box className={classes.iconButton}>
              <SearchIcon />
            </Box>
            <InputBase
              ref={refInputSearch}
              className={classes.input}
              placeholder="Pesquisar"
              inputProps={{ 'aria-label': 'pesquisar' }}
              onChange={handleSearchTextChange}
            />
            <IconButton
              className={classes.iconButton}
              onClick={handleClearSearch}
              disabled={textSearch.length === 0}
              color="secondary"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
          <Tooltip title="Fechar menu">
            <IconButton className={classes.closeIconButton} onClick={() => onClose()} color="warning" size="large">
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box className={classes.menuList} role="presentation" onKeyDown={() => onClose()}>
          <List>
            {data?.map((item: ItemMenuType) => {
              if (item.type === 'divider') {
                return <Divider key={`divider-${item.id}`} />;
              }
              if (item.type === 'folder') {
                return (
                  <SubListItemMenu
                    key={`folder-${item?.id}`}
                    item={item}
                    childrens={item?.childrens?.map(children_1 => (
                      <SubListItemMenu
                        key={`children_1-${children_1.id}`}
                        item={children_1}
                        childrens={children_1?.childrens?.map(children_2 => (
                          <SubListItemMenu
                            key={`children_2-${children_2.id}`}
                            item={children_2}
                            childrens={children_2?.childrens?.map(children_3 => (
                              <SubListItemMenu
                                key={`children_3-${children_3?.id}`}
                                item={children_3}
                                childrens={null}
                                styles={{ paddingLeft: 16 }}
                                classes={undefined}
                                textSearch={undefined}
                                onClose={undefined}
                              />
                            ))}
                            styles={{ paddingLeft: 16 }}
                            classes={classes}
                            textSearch={textSearch}
                            onClose={onClose}
                          />
                        ))}
                        styles={{ paddingLeft: 16 }}
                        classes={classes}
                        textSearch={textSearch}
                        onClose={onClose}
                      />
                    ))}
                    classes={classes}
                    textSearch={textSearch}
                    onClose={onClose}
                  />
                );
              }
              return (
                <ListItemMenu
                  key={`other-${item?.id}`}
                  icon={item?.icon}
                  title={item?.title}
                  path={item?.path}
                  type={item?.type}
                  classes={classes}
                  onClose={onClose}
                  textSearch={textSearch}
                />
              );
            })}
          </List>
          {loading && (
            <Box
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                height: '100%',
                width: '100%',
                userSelect: 'none',
              }}
            >
              Carregando...
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}
