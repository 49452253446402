import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { ResponsivePie } from '@nivo/pie';

import {
  Box,
  Paper,
  Typography,
  Grid,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Icon,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  FactCheckOutlined as FactCheckOutlinedIcon,
  Schedule as TimeIcon,
  ErrorOutline as AlertIcon,
} from '@mui/icons-material';

import Axios, { Canceler } from 'axios';
import api from '../../../../services/api';

import Filters, { FiltersInputsProps } from '../../../../components/Filters';
import styles from './styles';
import { ValueProps } from '../../../../components/inputs/InputSearchAsync';
import userUndefinedImg from '../../../../images/user-undefined.jpg';
import { avatarUrl } from '../../../../utils/functions';

export interface ProductivityProps {
  total: number | 0;
  page: number | 0;
  total_page: number | 0;
  limit: number | 0;
  order_dir: 'ASC' | 'DESC';
  order_by: string;
  operator_total: number | 0;
  operator_idle_total: number | 0;
  ops_total: number | 0;
  ops_overdue_total: number | 0;
  data: ProductivityData[];
}
export interface ProductivityData {
  codetg: string;
  desetg: string;
  overdue: number;
  production: ProductionData[];
}
export interface ProductionData {
  numped: number;
  codcli: number;
  nomcli: string;
  codgrp: string;
  numcad: number;
  nomope: string;
  banhor: string;
  horven: string;
  datven: string;
  accope: number;
  opeiid: number;
  codori: string;
  numorp: number;
  codpro: string;
  codder: string;
  codetg: number;
  seqrot: number;
  codopr: string;
  datini: string;
  horini: number;
  tmpprv: number;
  tmpgst: number;
  tmpdif: number;
  despro: string;
  cplpro: string;
  desder: string;
  descpl: string;
  desopr: string;
  qtdre1: number;
  qtdre2: number;
  qtdre3: number;
  seqeoq: number;
  numgop: number;
  diadif: number;
  obsorp: string;
}
interface ChartsDataProps {
  id: string;
  label: string;
  value: number;
  color: string;
}

let interval: NodeJS.Timeout;
const minToHours = (hour: number) => {
  const newHour = Math.ceil(hour);
  const hours = Math.trunc(newHour / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const minutes = Math.abs(newHour % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  return `${hours}:${minutes}`;
};

export default function ErpMonitoringProductivityPage(props: any) {
  Moment.updateLocale(Moment.locale(), { invalidDate: '0000-00-00' });
  const classes = styles();
  const { darkMode } = props;
  const [stageExpanded, setStageExpanded] = useState<string | false>(false);

  const [initialLoadingData, setInitialLoadingData] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [productivityData, setProductivityData] = useState<ProductivityProps>({} as ProductivityProps);

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-production-monitor-stages') || '[]',
    localStorage.getItem('search-production-monitor-orders') || '[]',
  ]);
  const [searchStages, setSearchStages] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-production-monitor-stages') || '[]'),
  );
  const [searchOrders, setSearchOrders] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-production-monitor-orders') || '[]'),
  );

  const [opStatistics, setOpStatistics] = useState<ChartsDataProps[]>([]);
  const [operatorStatistics, setOperatorStatistics] = React.useState<ChartsDataProps[]>([]);

  const filtersInputs = [
    {
      type: 'stage',
      id: 'production-monitor-stages',
      defaultValue: '[]',
    },
    {
      type: 'order',
      id: 'production-monitor-orders',
      defaultValue: '[]',
    },
  ] as FiltersInputsProps[];

  useEffect(() => {
    getProductivityData({});
  }, []);

  const getProductivityData = ({
    order_by = 'name',
    order_dir = 'asc',
    stages = searchStages,
    orders = searchOrders,
  }) => {
    let cancel: Canceler;
    if (!loadingData) {
      let ordersFilter = '';
      for (const order of orders) {
        ordersFilter += `;${order.id}`;
      }
      setSearchOrders(orders);
      setSearchStages(stages);
      let stagesFilter = '';
      for (const stage of stages) {
        stagesFilter += `;${stage.id}`;
      }
      const params = {
        order_by,
        order_dir,
        orders: ordersFilter,
        stages: stagesFilter,
      };
      setLoadingData(true);
      api
        .get('erp/monitoring/productivity/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const responseData = response.data as ProductivityProps;
          setProductivityData(responseData);
          const newOperatorData = [] as ChartsDataProps[];

          const operWorking = responseData.operator_total - responseData.operator_idle_total;
          const operWorkingPercent = (operWorking / responseData.operator_total) * 100;

          const operIdle = responseData.operator_idle_total;
          const operIdlePercent = (operIdle / responseData.operator_total) * 100;

          newOperatorData.push(
            {
              id: 'oper_working',
              label: `COM O.P. (${operWorkingPercent.toFixed(2)}%) `,
              value: responseData.operator_total - responseData.operator_idle_total,
              color: darkMode ? '#7cd8fb' : '#1e88e5',
            },
            {
              id: 'opr_idle',
              label: `SEM O.P. (${operIdlePercent.toFixed(2)}%) `,
              value: operIdle,
              color: darkMode ? '#ff7575' : '#f44336',
            },
          );

          setOperatorStatistics(newOperatorData);
          const newOpData = [] as ChartsDataProps[];

          const opOnTime = responseData.ops_total - responseData.ops_overdue_total;
          const opOnTimePercent = (opOnTime / responseData.ops_total) * 100;

          const opOutTime = responseData.ops_overdue_total;
          const opOutTimePercent = (opOutTime / responseData.ops_total) * 100;

          newOpData.push(
            {
              id: 'op_on_time',
              label: `SEM ATRASOS (${opOnTimePercent.toFixed(2)}%) `,
              value: opOnTime,
              color: darkMode ? '#5fe870' : '#2cc307',
            },
            {
              id: 'op_out_time',
              label: `COM ATRASOS (${opOutTimePercent.toFixed(2)}%) `,
              value: opOutTime,
              color: darkMode ? '#ff7575' : '#f44336',
            },
          );
          setOpStatistics(newOpData);
          setLoadingData(false);
          setInitialLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setStageExpanded(isExpanded ? panel : false);
  };

  function CustomTooltipCharts(e: any) {
    return (
      <div
        style={{
          background: darkMode ? '#2b2b2b' : '#f9f9f9',
          color: 'inherit',
          fontSize: 'inherit',
          borderRadius: '4px',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: darkMode ? '#c3c3c3' : '#313131',
          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
          padding: '5px 9px',
        }}
      >
        <div style={{ whiteSpace: 'pre', display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'block',
              borderRadius: 999999,
              width: '12px',
              height: '12px',
              backgroundColor: e.datum.color,
              marginRight: '7px',
            }}
          />
          <span style={{ color: darkMode ? '#e0e0e0' : '#313131' }}>
            {e.datum.label}: <strong>{e.datum.value}</strong>
          </span>
        </div>
      </div>
    );
  }
  function TotalCharts({ dataWithArc, centerX, centerY }: any) {
    let total = 0;
    dataWithArc.forEach((datum: { value: number }) => {
      total += datum.value;
    });

    return (
      <>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          fill={darkMode ? '#cccccc' : '#313131'}
          style={{
            fontFamily: 'Roboto,  sans-serif',
            fontSize: 10,
            fontWeight: 300,
          }}
        >
          Total
        </text>
        <text
          x={centerX}
          y={centerY + 2}
          textAnchor="middle"
          dominantBaseline="central"
          fill={darkMode ? '#e0e0e0' : '#313131'}
          style={{
            fontSize: 32,
            fontWeight: 600,
          }}
        >
          {total}
        </text>
      </>
    );
  }
  const StatisticsRender = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Paper className={classes.containerRoot} style={{ height: 256 }}>
          <Typography variant="subtitle1" className={classes.chartTitle}>
            ACOMPANHAMENTO DE OPERADORES
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            maxHeight="calc(100% - 24px)"
            width="100%"
            p={2}
          >
            <ResponsivePie
              data={operatorStatistics}
              animate
              activeOuterRadiusOffset={2}
              innerRadius={0.6}
              padAngle={2}
              cornerRadius={2}
              arcLinkLabelsTextColor={darkMode ? '#f3f3f3' : '#252525'}
              arcLabelsTextColor="#424242"
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={15}
              arcLinkLabelsColor={{
                from: 'color',
              }}
              arcLinkLabel={d => `${d.label}: ${d.value}`}
              arcLinkLabelsThickness={3}
              margin={{ top: 22, right: 22, bottom: 22, left: 22 }}
              colors={{ datum: 'data.color' }}
              // theme={{ textColor: darkMode ? '#f3f3f3' : '#252525' }}
              tooltip={CustomTooltipCharts}
              layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', TotalCharts]}
              legends={[]}
              borderWidth={1}
              borderColor="black"
              enableArcLabels={false}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Paper className={classes.containerRoot} style={{ height: 256 }}>
          <Typography variant="subtitle1" className={classes.chartTitle}>
            ACOMPANHAMENTO ORDEM DE PRODUÇÃO
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            maxHeight="calc(100% - 24px)"
            width="100%"
            p={2}
          >
            <ResponsivePie
              data={opStatistics}
              animate
              activeOuterRadiusOffset={2}
              innerRadius={0.6}
              padAngle={2}
              cornerRadius={2}
              arcLinkLabelsTextColor={darkMode ? '#f3f3f3' : '#252525'}
              arcLabelsTextColor="#424242"
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={15}
              arcLinkLabelsColor={{
                from: 'color',
              }}
              arcLinkLabel={d => `${d.label}: ${d.value}`}
              arcLinkLabelsThickness={3}
              margin={{ top: 22, right: 22, bottom: 22, left: 22 }}
              colors={{ datum: 'data.color' }}
              // theme={{ textColor: darkMode ? '#f3f3f3' : '#252525' }}
              tooltip={CustomTooltipCharts}
              layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', TotalCharts]}
              legends={[]}
              borderWidth={1}
              borderColor="black"
              enableArcLabels={false}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const handleApplyFilter = (e: any) => {
    setInitialLoadingData(true);
    setSearchStages(e[0]);
    setSearchOrders(e[1]);

    getProductivityData({ orders: e[1], stages: e[0] });
  };

  return (
    <Box>
      <Filters
        onFilterApply={(e: any) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      {!initialLoadingData ? (
        <Box>
          <Box>
            {window.navigator.userAgent.indexOf('Windows NT 5.1') === -1 && <Box>{StatisticsRender}</Box>}
            {searchOrders?.length > 0 && (
              <Paper style={{ marginBottom: 16, padding: 12 }}>
                <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
                  <Icon className={classes.orderTitle} style={{ marginRight: 8 }}>
                    filter_list
                  </Icon>
                  <Typography className={classes.orderTitle} style={{ fontSize: 12 }}>
                    PEDIDOS FILTRADOS
                  </Typography>
                </Box>
                {searchOrders?.map(order => {
                  return (
                    <Typography
                      style={{ fontSize: 14 }}
                      key={`${order.id}`}
                    >{`${order.id} - ${order.description}`}</Typography>
                  );
                })}
              </Paper>
            )}
            <Box>
              {productivityData?.data?.map((productivity, index) => {
                const isOpened = stageExpanded === productivity.codetg;
                const hasOP = Number(productivity.codetg) > 0;

                return (
                  <Accordion
                    key={`${productivity.codetg}-${index}`}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={isOpened}
                    onChange={handleChange(productivity.codetg)}
                    className={classes.stageContainer}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="content" id="header">
                      <Box display="flex" alignItems="center">
                        {!hasOP ? (
                          <AlertIcon
                            className={classes.outTimeText}
                            style={{ marginRight: 16, width: 42, height: 42 }}
                          />
                        ) : (
                          <Typography
                            className={classes.titleStage}
                            style={{ fontWeight: 'bold', fontSize: 28, marginRight: 16 }}
                          >
                            {`${productivity.codetg}`}
                          </Typography>
                        )}

                        {hasOP ? (
                          <Box>
                            <Typography>{`${productivity.desetg}`}</Typography>

                            <Typography
                              className={
                                productivity.overdue > 0
                                  ? classes.stageDescriptionOutTime
                                  : classes.stageDescriptionOnTime
                              }
                            >{`${productivity.production?.length} O.P. COM ${productivity.overdue} EM ATRASO`}</Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography>SEM O.P.</Typography>
                            <Typography
                              className={classes.stageDescriptionOutTime}
                            >{`${productivity.production?.length} OPERADORES`}</Typography>
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {isOpened && (
                        <Box flex={1}>
                          {productivity.production?.map((production, index) => {
                            const timeDifference = minToHours(production.tmpdif);
                            const timeDifferencePercent = parseFloat(
                              ((production.tmpdif / production.tmpprv) * 100).toFixed(2),
                            );

                            return (
                              <React.Fragment key={`production-${index}`}>
                                {hasOP ? (
                                  <Accordion
                                    TransitionProps={{ unmountOnExit: true }}
                                    className={classes.operatorContainer}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="content"
                                      id="header"
                                    >
                                      <Badge
                                        badgeContent={production.diadif}
                                        variant="dot"
                                        color="secondary"
                                        style={{ left: 2, top: 2 }}
                                        anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'left',
                                        }}
                                      />
                                      {production.tmpdif < 0 || Number(productivity.codetg) === 0 ? (
                                        <AlertIcon
                                          className={classes.outTimeText}
                                          style={{ marginRight: 16, width: 48, height: 48 }}
                                        />
                                      ) : (
                                        <TimeIcon
                                          className={classes.onTimeText}
                                          style={{ marginRight: 16, width: 48, height: 48 }}
                                        />
                                      )}
                                      <img
                                        className={classes.operatorImg}
                                        src={avatarUrl(production.opeiid)}
                                        alt={production.nomope}
                                        onError={(e: any) => {
                                          e.target.src = avatarUrl(0);
                                        }}
                                      />
                                      <Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                          <Tooltip
                                            title={
                                              production.accope > 0 ? 'Com marcação de ponto' : 'Sem marcação de ponto'
                                            }
                                          >
                                            <FactCheckOutlinedIcon
                                              className={`${classes.checkList} ${
                                                production.accope > 0 ? classes.greenColor : classes.redColor
                                              }`}
                                            />
                                          </Tooltip>
                                          <Tooltip title={`Banco de Horas (Vencimento: ${production.datven})`}>
                                            <Typography
                                              className={classes.hoursBank}
                                            >{`${production.horven}`}</Typography>
                                          </Tooltip>

                                          <Tooltip title="Banco de Horas (Total)">
                                            <Typography
                                              className={`${classes.hoursTotalBank} ${
                                                production.banhor.substring(0, 1) === '-' ? classes.redColor : ''
                                              }`}
                                            >{`${production.banhor}`}</Typography>
                                          </Tooltip>
                                          <Typography className={classes.operatorName}>{`${
                                            production.codgrp === '00001' ? '(1º TURNO)' : '(2º TURNO)'
                                          } ${production.numcad} - ${production.nomope}`}</Typography>
                                        </Box>
                                        <Typography
                                          className={classes.operatorStage}
                                        >{`ORIGEM / O.P.: ${production.codori} / ${production.numorp}`}</Typography>
                                        {production.numped > 0 ? (
                                          <Typography
                                            className={classes.operatorStage}
                                          >{`PEDIDO: ${production.numped} - ${production.nomcli}`}</Typography>
                                        ) : (
                                          <Typography className={classes.operatorStage}>SEM PEDIDO</Typography>
                                        )}
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {Number(productivity.codetg) !== 0 && (
                                        <Box className={classes.operatorDetails} flex={1}>
                                          <Grid container spacing={3}>
                                            <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                                              <Typography className={classes.productionDataTitle}>Estágio</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.codetg}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                                              <Typography className={classes.productionDataTitle}>Origem</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.codori}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                                              <Typography className={classes.productionDataTitle}>O.P.</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.numorp}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={6} sm={2} md={3} lg={1} xl={1}>
                                              <Typography className={classes.productionDataTitle}>Pedido</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.numped}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={10} md={12} lg={8} xl={8}>
                                              <Typography className={classes.productionDataTitle}>Cliente</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.nomcli}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={2} lg={1} xl={2}>
                                              <Typography className={classes.productionDataTitle}>Qtde</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.qtdre1}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={2} lg={2} xl={2}>
                                              <Typography className={classes.productionDataTitle}>Produto</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.codpro}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                              <Typography className={classes.productionDataTitle}>Descrição</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.despro}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography className={classes.productionDataTitle}>Obs.</Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {production.obsorp}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                                              <Typography className={classes.productionDataTitle}>
                                                Data (Inicío)
                                              </Typography>
                                              <Typography
                                                className={`${
                                                  production.diadif === 0
                                                    ? classes.productionDataValue
                                                    : classes.productionDataValueOnTime
                                                }`}
                                              >
                                                {Moment.utc(production.datini).format('DD/MM/YYYY')}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                                              <Typography className={classes.productionDataTitle}>
                                                Hora (Inicío)
                                              </Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {minToHours(production.horini)}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                                              <Typography className={classes.productionDataTitle}>
                                                Tempo Previsto
                                              </Typography>
                                              <Typography className={classes.productionDataValue}>
                                                {minToHours(production.tmpprv)}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                                              <Typography className={classes.productionDataTitle}>
                                                Tempo Gasto
                                              </Typography>
                                              <Typography
                                                className={`${
                                                  production.tmpdif < 0
                                                    ? classes.productionDataValueOnTime
                                                    : classes.productionDataValueOutTime
                                                }`}
                                              >
                                                {minToHours(production.tmpgst)}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                                              <Typography className={classes.productionDataTitle}>Difer.</Typography>
                                              <Typography
                                                className={`${
                                                  production.tmpdif < 0
                                                    ? classes.productionDataValueOnTime
                                                    : classes.productionDataValueOutTime
                                                }`}
                                              >{`${timeDifference} (${timeDifferencePercent}%)`}</Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                ) : (
                                  <Box className={classes.operatorWithoutOp}>
                                    <AlertIcon
                                      className={classes.outTimeText}
                                      style={{ marginRight: 8, width: 36, height: 36 }}
                                    />
                                    <img
                                      className={classes.operatorImg}
                                      src={avatarUrl(production.opeiid)}
                                      alt={production.nomope}
                                      onError={(e: any) => {
                                        e.target.src = avatarUrl(0);
                                      }}
                                    />
                                    <Box display="flex" alignItems="center" gap={1}>
                                      <Tooltip
                                        title={
                                          production.accope > 0 ? 'Com marcação de ponto' : 'Sem marcação de ponto'
                                        }
                                      >
                                        <FactCheckOutlinedIcon
                                          className={`${classes.checkList} ${
                                            production.accope > 0 ? classes.greenColor : classes.redColor
                                          }`}
                                        />
                                      </Tooltip>
                                      <Tooltip title={`Banco de Horas (Vencimento: ${production.datven})`}>
                                        <Typography className={classes.hoursBank}>{`${production.horven}`}</Typography>
                                      </Tooltip>

                                      <Tooltip title="Banco de Horas (Total)">
                                        <Typography
                                          className={`${classes.hoursTotalBank} ${
                                            production.banhor.substring(0, 1) === '-' ? classes.redColor : ''
                                          }`}
                                        >
                                          {`${production.banhor}`}
                                        </Typography>
                                      </Tooltip>

                                      <Typography className={classes.operatorName}>{`${
                                        production.codgrp === '00001' ? '(1º TURNO)' : '(2º TURNO)'
                                      }
                                      ${production.numcad} - ${production.nomope}`}</Typography>
                                    </Box>
                                  </Box>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando dados...
          </Typography>
        </Backdrop>
      )}
    </Box>
  );
}
