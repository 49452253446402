import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey, orange, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    tableContainer: {
      height: '100%',
      overflow: 'auto',

      boxShadow:
        '0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)',
    },
    table: {
      minWidth: 750,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: darkMode ? '#343434' : '#efefef',
      // backgroundColor: darkMode ? '#383838' : '#f7f7f7',
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#545454',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d1d1d1' : '#303030'}`,
      borderRadius: 4,
      color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      position: 'relative',
    },
    tableHead: {
      fontWeight: 'bold',
      '& > tr > th': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#d1d1d1' : '#000000'}`,
      },
      '& > tr > th:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#d1d1d1' : '#565656'}`,
      },
    },
    tableHeadPrimary: {
      '& > tr > th': {
        backgroundColor: theme.palette.mode === 'light' ? '#efefef' : '#343434',
      },
    },
    tableHeadBorder: {
      '& > tr > th': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#d1d1d1' : '#565656'}`,
      },
    },
    tableBody: {
      '& > tr > th': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#d1d1d1' : '#565656'}`,
      },
      [`& > tr.even`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#404040',
      },
      [`& > tr.odd`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#f7f7f7' : '#464646',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: 4,
      },
    },
    tableRow: {
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#404040',
    },
    tableVisuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    contentContainer: {
      overflow: 'hidden',
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
  });
});
