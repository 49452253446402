import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import {
  Box,
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  TableContainer,
  TableHead,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimeIcon from '@mui/icons-material/Schedule';
import AlertIcon from '@mui/icons-material/ErrorOutline';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import Axios, { Canceler } from 'axios';
import api from '../../../../services/api';

import Filters, { FiltersInputsProps } from '../../../../components/Filters';

import styles from './styles';
import { ValueProps } from '../../../../components/inputs/InputSearchAsync';

export interface ScheduleProps {
  data: ScheduleData[];
}
export interface ScheduleData {
  codetg: string;
  desetg: string;
  captot: number;
  hortot: number;
  datmin: string;
  datmax: string;
  datdif: number;
  resources: ResourcesData[];
}
export interface ResourcesData {
  codcre: string;
  codetg: number;
  abrcre: string;
  qtdpes: number;
  qtdmaq: number;
  hortrb: number;
  perefi: number;
  limcap: string;
  tipcre: string;
  date_start: string;
  date_end: string;
  diff_days: number;
  diff_months: number;
  cappro: number;
  horpro: number;
  orders: OrdersData[];
}
export interface OrdersData {
  codori: string;
  numorp: number;
  codpro: string;
  despro: string;
  numped: number;
  codcli: number;
  apecli: string;
  seqipd: number;
  qtdprv: number;
  siteop: string;
  numpri: number;
  seqrot: number;
  dtpini: string;
  dtpfim: string;
  dtrini: string;
  dtrfim: string;
  horini: number;
  horfim: number;
  codcre: number;
  tmptpr: number;
  pesbru: number;
  unimed: string;
  sitpro: string;
}
interface ChartsDataProps {
  id: string;
  label: string;
  value: number;
  color: string;
}

export default function ErpMonitoringSchedulePage(props: any) {
  Moment.updateLocale(Moment.locale(), { invalidDate: '0000-00-00' });
  const classes = styles();
  const { darkMode } = props;
  const [stageExpanded, setStageExpanded] = useState<string | false>(false);

  const [initialLoadingData, setInitialLoadingData] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<ScheduleProps>({} as ScheduleProps);

  const [inputsValue, setInputsValue] = useState<string[]>([
    localStorage.getItem('search-production-schedule-stages') || '[]',
    localStorage.getItem('search-production-schedule-orders') || '[]',
  ]);
  const [searchStages, setSearchStages] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-production-schedule-stages') || '[]'),
  );
  const [searchOrders, setSearchOrders] = useState<ValueProps[]>(
    JSON.parse(localStorage.getItem('search-production-schedule-orders') || '[]'),
  );

  const [opStatistics, setOpStatistics] = useState<ChartsDataProps[]>([
    {
      id: 'op_on_time',
      label: 'SEM ATRASOS',
      value: 1,
      color: darkMode ? '#5fe870' : '#2cc307',
    },
    {
      id: 'op_out_time',
      label: 'COM ATRASOS',
      value: 1,
      color: darkMode ? '#ff7575' : '#f44336',
    },
  ]);
  const [operatorStatistics, setOperatorStatistics] = React.useState<ChartsDataProps[]>([
    {
      id: 'oper_working',
      label: 'COM O.P.',
      value: 1,
      color: darkMode ? '#7cd8fb' : '#1e88e5',
    },
    {
      id: 'opr_idle',
      label: 'SEM O.P.',
      value: 1,
      color: darkMode ? '#ff7575' : '#f44336',
    },
  ]);

  const filtersInputs = [
    {
      type: 'stage',
      id: 'production-schedule-stages',
      defaultValue: '[]',
    },
    {
      type: 'order',
      id: 'production-schedule-orders',
      defaultValue: '[]',
    },
  ] as FiltersInputsProps[];

  useEffect(() => {
    getScheduleData({});
  }, []);

  const getScheduleData = ({ order_by = 'name', order_dir = 'asc', stages = searchStages, orders = searchOrders }) => {
    let cancel: Canceler;
    if (!loadingData) {
      let ordersFilter = '';
      for (const order of orders) {
        ordersFilter += `;${order.id}`;
      }
      setSearchOrders(orders);
      setSearchStages(stages);
      let stagesFilter = '';
      for (const stage of stages) {
        stagesFilter += `;${stage.id}`;
      }
      const params = {
        order_by,
        order_dir,
        orders: ordersFilter,
        stages: stagesFilter,
      };
      setLoadingData(true);
      api
        .get('erp/monitoring/schedule/', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const responseData = response.data as ScheduleProps;
          setScheduleData(responseData);
          setLoadingData(false);
          setInitialLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setStageExpanded(isExpanded ? panel : false);
  };
  const handleApplyFilter = (e: any) => {
    setInitialLoadingData(true);
    setSearchStages(e[0]);
    setSearchOrders(e[1]);

    getScheduleData({ orders: e[1], stages: e[0] });
  };

  return (
    <Box>
      <Filters
        onFilterApply={(e: any) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        initialInputsValue={inputsValue}
      />
      {!initialLoadingData ? (
        <Box>
          <Box>
            {/* <Box>{StatisticsRender}</Box> */}
            {searchOrders?.length > 0 && (
              <Paper style={{ marginBottom: 16, padding: 12 }}>
                <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
                  <Icon className={classes.orderTitle} style={{ marginRight: 8 }}>
                    filter_list
                  </Icon>
                  <Typography className={classes.orderTitle} style={{ fontSize: 12 }}>
                    PEDIDOS FILTRADOS
                  </Typography>
                </Box>
                {searchOrders?.map(order => {
                  return (
                    <Typography
                      style={{ fontSize: 14 }}
                      key={`${order.id}`}
                    >{`${order.id} - ${order.subdescription}`}</Typography>
                  );
                })}
              </Paper>
            )}
            <Box>
              {scheduleData?.data?.map((schedule, index) => {
                const isOpened = stageExpanded === schedule.codetg;
                const capacityStage = schedule.captot - schedule.hortot;
                return (
                  <Accordion
                    key={`${schedule.codetg}-${index}`}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={isOpened}
                    onChange={handleChange(schedule.codetg)}
                    className={classes.stageContainer}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="content" id="header1">
                      <Box display="flex" alignItems="center" flex={1}>
                        <Box display="flex" justifyContent="flex-end" minWidth={64} mr={2}>
                          <Typography className={classes.titleStage}>{`${schedule.codetg}`}</Typography>
                        </Box>

                        <Box width="100%">
                          <Typography>{`${schedule.desetg}`}</Typography>

                          <Grid container spacing={3} style={{ marginTop: 1 }}>
                            <Grid item xs={6} sm={3} md={2} lg={1} xl={1}>
                              <Typography className={classes.stageDataTitle}>CAP. TOTAL&nbsp;</Typography>
                              <Typography className={classes.stageDataValue}>{`${minToHours(
                                schedule.captot,
                              )}h`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} md={2} lg={1} xl={1}>
                              <Typography className={classes.stageDataTitle}>HORAS PROG.&nbsp;</Typography>
                              <Typography className={classes.stageDataValue}>{`${minToHours(
                                schedule.hortot,
                              )}h`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={2} md={2} lg={1} xl={1}>
                              <Typography className={classes.stageDataTitle}>CAP. DISP.&nbsp;</Typography>
                              <Typography
                                className={
                                  capacityStage > 0 ? classes.stageDataPositiveValue : classes.stageDataNegativeValue
                                }
                              >{`${minToHours(capacityStage)}h`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={6} lg={9} xl={9}>
                              <Typography className={classes.stageDataTitle}>PERÍODO&nbsp;</Typography>
                              <Typography className={classes.stageDataValue}>
                                {`
                                ${Moment.utc(schedule.datmin).format('DD/MM/YYYY')} - 
                                ${Moment.utc(schedule.datmax).format('DD/MM/YYYY')}
                                `}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {isOpened && (
                        <Box flex={1}>
                          {schedule.resources?.map((resources, index) => {
                            const capacityResources = resources.cappro - resources.horpro;
                            const tableTitle = [
                              { title: '', align: 'center' },
                              { title: '', align: 'center' },
                              { title: 'ORIGEM', align: 'center' },
                              { title: 'OP', align: 'center' },
                              { title: 'PRODUTO', align: 'center' },
                              { title: 'DESCRIÇÃO', align: 'left' },
                              { title: 'PEDIDO', align: 'center' },
                              { title: 'CLIENTE', align: 'right' },
                              { title: 'NOME', align: 'left' },
                              { title: 'ITEM PEDIDO', align: 'center' },
                              { title: 'QTD PREVISTA', align: 'center' },
                              { title: 'SITUAÇÃO ESTÁGIO', align: 'center' },
                              { title: 'PRIORIDADE', align: 'center' },
                              { title: 'SEQUÊNCIA ROTEIRO', align: 'center' },
                              { title: 'INÍCIO PREVISTO', align: 'center' },
                              { title: 'FIM PREVISTO', align: 'center' },
                              { title: 'INÍCIO REAL', align: 'center' },
                              { title: 'FIM REAL', align: 'center' },
                              { title: 'HORA INÍCIO', align: 'center' },
                              { title: 'HORA FIM', align: 'center' },
                              { title: 'CENTRO RECURSO', align: 'center' },
                              { title: 'TEMPO PREVISTO', align: 'center' },
                              { title: 'PESO UNITÁRIO', align: 'center' },
                              { title: 'UN. MED.', align: 'center' },
                            ];
                            return (
                              <React.Fragment key={`resources-${index}`}>
                                <Accordion
                                  TransitionProps={{ unmountOnExit: true }}
                                  className={classes.operatorContainer}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="content"
                                    id="header2"
                                  >
                                    <Box width="100%">
                                      <Typography>{`${resources.codcre} - ${resources.abrcre}`}</Typography>
                                      <Grid container spacing={3} style={{ marginTop: 2 }}>
                                        <Grid item xs={6} sm={3} md={2} lg={1} xl={1}>
                                          <Typography className={classes.stageDataTitle}>CAP. TOTAL&nbsp;</Typography>
                                          <Typography className={classes.stageDataValue}>{`${minToHours(
                                            resources.cappro,
                                          )}h`}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2} lg={1} xl={1}>
                                          <Typography className={classes.stageDataTitle}>HORAS PROG.&nbsp;</Typography>
                                          <Typography className={classes.stageDataValue}>{`${minToHours(
                                            resources.horpro,
                                          )}h`}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={2} lg={1} xl={1}>
                                          <Typography className={classes.stageDataTitle}>CAP. DISP.&nbsp;</Typography>
                                          <Typography
                                            className={
                                              capacityResources > 0
                                                ? classes.stageDataPositiveValue
                                                : classes.stageDataNegativeValue
                                            }
                                          >{`${minToHours(capacityResources)}h`}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={9} xl={9}>
                                          <Typography className={classes.stageDataTitle}>PERÍODO&nbsp;</Typography>
                                          <Typography className={classes.stageDataValue}>
                                            {`${Moment.utc(resources.date_start).format('DD/MM/YYYY')} - 
                                                ${Moment.utc(resources.date_end).format('DD/MM/YYYY')}
                                                `}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <TableContainer className={classes.tableContainer}>
                                      <Table
                                        stickyHeader
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        aria-label="table"
                                        className={classes.table}
                                      >
                                        <TableHead>
                                          <TableRow>
                                            {tableTitle?.map((title, index) => {
                                              return (
                                                <TableCell
                                                  key={index}
                                                  align={
                                                    title.align as
                                                      | 'right'
                                                      | 'left'
                                                      | 'center'
                                                      | 'inherit'
                                                      | 'justify'
                                                      | undefined
                                                  }
                                                >
                                                  <Typography variant="caption" color="textSecondary">
                                                    {title.title}
                                                  </Typography>
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {resources.orders?.map((order, index) => {
                                            const rowId = `row-${index}`;
                                            const dateDiff = Moment.utc(order.dtpfim).diff(Moment.now(), 'days');
                                            return (
                                              <TableRow hover tabIndex={-1} key={rowId}>
                                                <TableCell width={10} align="center">
                                                  {order.sitpro === 'E' ? (
                                                    <AssignmentLateIcon
                                                      className={classes.outTimeText}
                                                      style={{ width: 28, height: 28 }}
                                                    />
                                                  ) : (
                                                    <AssignmentTurnedInIcon
                                                      className={classes.onTimeText}
                                                      style={{ width: 28, height: 28 }}
                                                    />
                                                  )}
                                                </TableCell>
                                                <TableCell width={10} align="center">
                                                  {dateDiff <= 0 ? (
                                                    <AlertIcon
                                                      className={classes.outTimeText}
                                                      style={{ width: 28, height: 28 }}
                                                    />
                                                  ) : (
                                                    <TimeIcon
                                                      className={classes.onTimeText}
                                                      style={{ width: 28, height: 28 }}
                                                    />
                                                  )}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.codori}
                                                </TableCell>
                                                <TableCell width={24} align="left">
                                                  {order.numorp}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 148 }} align="right">
                                                  {order.codpro}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 386 }} align="left">
                                                  {order.despro}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 30 }} align="right">
                                                  {order.numped > 0 && order.numped}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 96 }} align="right">
                                                  {order.codcli}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 386 }} align="left">
                                                  {order.apecli}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.seqipd}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.qtdprv}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.siteop === 'L'
                                                    ? 'LIBERADO'
                                                    : order.siteop === 'A'
                                                    ? 'ANDAMENTO'
                                                    : 'PARADO'}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.numpri}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.seqrot}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.dtpini === '1900-12-31T00:00:00.000Z'
                                                    ? '00/00/0000'
                                                    : Moment.utc(order.dtpini).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.dtpfim === '1900-12-31T00:00:00.000Z'
                                                    ? '00/00/0000'
                                                    : Moment.utc(order.dtpfim).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.dtrini === '1900-12-31T00:00:00.000Z'
                                                    ? '00/00/0000'
                                                    : Moment.utc(order.dtrini).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.dtrfim === '1900-12-31T00:00:00.000Z'
                                                    ? '00/00/0000'
                                                    : Moment.utc(order.dtrfim).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {minToHours(order.horini)}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {minToHours(order.horfim)}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.codcre}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {minToHours(order.tmptpr)}
                                                </TableCell>
                                                <TableCell width={24} align="right">
                                                  {order.pesbru}
                                                </TableCell>
                                                <TableCell width={24} align="center">
                                                  {order.unimed}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando dados...
          </Typography>
        </Backdrop>
      )}
    </Box>
  );
}

const minToHours = (hour: number) => {
  const newHour = Math.ceil(hour);

  const hours = Math.trunc(newHour / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const minutes = Math.abs(newHour % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  return `${hours}:${minutes}`;
};
