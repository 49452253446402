import React from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';
import { EngOsTasks } from './EngOsTasks';
import { EngOsHomeTitle } from '../EngOsHomeTitle';

function EngOSManagement() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <EngOsHomeTitle title="Gestão" filterButton={false} refressButton={false} />
        <Paper sx={{ p: 2 }}>
          <EngOsTasks />
        </Paper>
      </Box>
    </Box>
  );
}

export { EngOSManagement };
