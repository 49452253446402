import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: 0,

      borderRadius: 4,
    },
    messagesContainer: {
      backgroundColor: darkMode ? '#313131' : '#e0e0e0',
      borderRadius: 4,
      display: 'flex',
      width: '100%',
      maxHeight: 'calc(100vh - 282px)',
      overflowY: 'auto',
      padding: 16,
      flexDirection: 'column-reverse',
    },
    sendMessageContainer: {
      marginTop: 8,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      paddingBottom: 0,
      marginBottom: 0,
    },
    sendMessageInput: {
      backgroundColor: darkMode ? '#585858' : '#ffffff',
    },
    loadingMessageBox: {
      display: 'flex',
      backgroundColor: darkMode ? '#3e3e3e' : '#efefef',
      margin: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 68,
      marginRight: 68,
      borderRadius: 6,
      minHeight: 32,
    },
    loadingMessageText: {
      color: darkMode ? '#b5b5b5' : '#848484',
      fontSize: 12,
    },
  });
});
