import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Grid,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Paper,
  Typography,
  IconButton,
  Alert,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import api from '../../../../services/api';
import styles from './styles';
import InputSearchAsync, { ValueProps } from '../../../../components/inputs/InputSearchAsync';
import InputSearchDate from '../../../../components/inputs/InputSearchDate';
import InputTime from '../../../../components/inputs/InputTime';
import { minuteToHours, toNumber } from '../../../../utils/functions';

export default function DayoffAddPage() {
  const classes = styles();
  const [waitingInsert, setWaitingInsert] = useState<boolean>(false);
  const [users, setUsers] = useState<ValueProps[]>([]);
  const [date, setDate] = useState<string>('');
  const [hours, setHours] = useState<string>('01:00');
  const [hoursNumber, setHoursNumber] = useState<number>(0);
  const [hoursStart, setHoursStart] = useState<string>('07:30');
  const [hoursEnd, setHoursEnd] = useState<string>('17:12');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleInsert = async () => {
    if (!waitingInsert) {
      setWaitingInsert(true);
      try {
        const response = await api.post('manager/dayoff/', {
          type: 'I',
          users: users.map(u => u.id),
          date,
          hours,
          hours_start: hoursStart,
          hours_end: hoursEnd,
        });
        setSnackbarType('success');
        setSnackbarMsg('Inserido com Sucesso');
        setOpenSnackbar(true);
        setUsers([]);
      } catch (error) {
        const { data } = error.response;
        if (data) {
          let errorMessage = data.message;
          if (!errorMessage) errorMessage = data.error;
          if (errorMessage === 'invalid_date') errorMessage = 'É necessário informar uma data válida';
          else if (errorMessage === 'invalid_hour_less')
            errorMessage = 'O horário informado não pode ser menor ou igual ao horário atual';
          else if (errorMessage === 'invalid_hours') errorMessage = 'É necessário informar um horário válido';
          else if (errorMessage === 'invalid_user') errorMessage = `Colaborador "${data.value}" inválido`;
          else if (errorMessage === 'erp_webservice_user, erp_webservice_password not found in settings')
            errorMessage = 'Erro nas configurações do banco de dados, contate o Departamento de TI';
          else if (errorMessage === 'already_an_entry_for_this_period')
            errorMessage = `Já existe um lançamento para essa data e hora para o usuário: "${data.value}"`;
          else if (errorMessage === 'invalid_date_same_or_less')
            errorMessage = 'A data informada não pode ser menor ou igual a data atual';
          else if (errorMessage === 'hour_end_is_bigger')
            errorMessage = 'O Horário Início não pode ser maior que o Horário Fim';
          else if (errorMessage === '') errorMessage = 'Erro desconhecido';

          setSnackbarType('error');
          setSnackbarMsg(errorMessage);
          setOpenSnackbar(true);
        }
      }
      setWaitingInsert(false);
    }
  };

  useEffect(() => {
    const start = toNumber(hoursStart);
    const end = toNumber(hoursEnd);
    const hour = end - start;
    setHoursNumber(hour);
    setHours(minuteToHours(hour));
    return () => {};
  }, [hoursStart, hoursEnd]);

  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.pageTitleContainer}>
          <Typography className={classes.pageTitle}>Adicionar folga</Typography>
        </Box>
        <Paper className={classes.container}>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputSearchAsync
                fullWidth
                source="intranet-users"
                onChange={(e: ValueProps[]) => {
                  setUsers(e);
                }}
                value={users}
                searchParams={{ subordinatesOnly: true, employeesOnly: true }}
                label="Colaborador (+)"
                disabled={waitingInsert}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputSearchDate
                label="Data"
                onChange={e => {
                  setDate(e.target.value);
                }}
                value={date}
                style={{ width: 178 }}
                disabled={waitingInsert}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" gap={2} flexWrap="wrap">
                <InputTime
                  label="Hora Início"
                  onChange={e => {
                    setHoursStart(e.target.value);
                  }}
                  value={hoursStart}
                  style={{ width: 128 }}
                  disabled={waitingInsert}
                />
                <InputTime
                  label="Hora Fim"
                  onChange={e => {
                    setHoursEnd(e.target.value);
                  }}
                  value={hoursEnd}
                  style={{ width: 128 }}
                  disabled={waitingInsert}
                />
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: 128 }}>
                  <Typography className={classes.totalHoursTitle}>Total Horas</Typography>
                  <Typography className={classes.totalHoursValue}>{hours}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.actionContainer}>
          <Button variant="contained" color="secondary" component={Link} to="/manager" disabled={waitingInsert}>
            Voltar
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleInsert}
            disabled={users.length === 0 || waitingInsert || hoursNumber <= 0 || hoursStart === '' || hoursEnd === ''}
            style={{ minWidth: 160 }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              {waitingInsert ? (
                <>
                  <CircularProgress color="inherit" style={{ height: 24, width: 24, marginRight: 8 }} />
                  <Typography>Inserindo</Typography>
                </>
              ) : (
                <>
                  <SaveIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                  <Typography>Inserir</Typography>
                </>
              )}
            </Box>
          </Button>
        </Paper>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
