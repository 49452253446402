import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  return createStyles({
    '@global': {},
    root: {
      '& .MuiDrawer-paper': {
        backgroundColor: darkMode ? '#333333' : '#fafafa',
        width: 512,
        maxWidth: '100vw',
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    grow: {
      flexGrow: 1,
    },
    topBar: {
      backgroundColor: darkMode ? grey[800] : '#f1f1f1',
      zIndex: 2,
    },
    drawer: {
      width: 512,
      maxWidth: '100vw',
      marginTop: 58,
      marginBottom: 0,
      paddingLeft: 6,
      paddingRight: 6,
      height: 'calc(100vh - 64px)',
    },
    itemRoot: {
      paddingLeft: 8,
      paddingRight: 8,
      marginTop: 12,
    },
    filterButton: {
      position: 'fixed',
      top: theme.spacing(9),
      right: theme.spacing(1.5),
      zIndex: 10,
    },
    filterButtonColor: {
      color: darkMode ? grey[50] : grey[100],
      backgroundColor: darkMode ? deepPurple[400] : deepPurple[800],
      '&:hover': {
        backgroundColor: deepPurple[600],
      },
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        color: grey[500],

        backgroundColor: deepPurple[300],
        '&:hover': {
          backgroundColor: deepPurple[300],
        },
      },
    },
    drawerNotificationRoot: {
      '& .MuiDrawer-paper': {
        backgroundColor: darkMode ? '#333333' : '#fafafa',
      },
    },
    drawerNotificationListRoot: {
      width: 512,
      maxWidth: '100vw',
    },
    drawerNotificationListTopBar: {
      position: 'fixed',
      width: 512,
      maxWidth: '100vw',
      backgroundColor: darkMode ? grey[800] : '#f1f1f1',
      zIndex: 2,
    },

    drawerNotificationTotalText: {
      fontSize: 12,
      textTransform: 'uppercase',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
    },

    drawerNotificationAccordionSummary: {
      '& .MuiAccordionSummary-content': {
        maxWidth: '100%',
      },
    },
    drawerNotificationItemTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      marginRight: 8,
    },
    drawerNotificationItemTitleReaded: {
      fontSize: 14,
      textTransform: 'uppercase',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
    },
    drawerNotificationItemCreatedDate: {
      fontSize: 10,
      textTransform: 'uppercase',
      color: darkMode ? '#bdbdbd' : '#4c4c4c',
      minWidth: 82,
    },
    notificationsContainer: {
      /*
      height: 'calc(100vh - 64px)',
      overflowY: 'auto',
      borderRadius: 4,
      paddingRight: 4,
      */
    },
    loadingMessageBox: {
      display: 'flex',
      backgroundColor: darkMode ? '#3e3e3e' : '#efefef',

      justifyContent: 'center',
      alignItems: 'center',

      borderRadius: 4,
      minHeight: 32,
      marginTop: 8,
      marginBottom: 8,
    },
    loadingMessageText: {
      color: darkMode ? '#b5b5b5' : '#848484',
      fontSize: 12,
    },
    totalText: {
      color: darkMode ? '#b5b5b5' : '#848484',
      fontSize: 12,
      minWidth: 62,
      width: 62,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      position: 'absolute',
      height: 'calc(100vh - 46px)',
      justifyContent: 'center',
      alignItems: 'center',
      width: 512,
      maxWidth: '100vw',
      marginTop: 46,
      pointerEvents: 'none',
    },
    backdropColor: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    backdropText: {
      pointerEvents: 'none',
      color: darkMode ? '#b5b5b5' : '#848484',
      fontSize: 14,
    },
  });
});
