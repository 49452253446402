import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  TextField,
  Box,
  Grid,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import styles from './styles';
import ChartLine from './ChartLine';
import ProductionNote from './ProductionNote';
import SalesXProduction from './SalesXProduction';
import InputSearchAsync, { ValueProps } from '../../../../components/inputs/InputSearchAsync';

export default function ProductionDashboardPage() {
  const classes = styles();
  const [stages, setStages] = useState<ValueProps[]>(
    JSON.parse(
      localStorage.getItem('intranet@erp-production-dashboard-stages') ||
        '[{"row_id":"1","id":"100","description":"100","subdescription":"PREPARACAO DA CALDEIRARIA"},{"row_id":"7","id":"400","description":"400","subdescription":"SOLDA"},{"row_id":"12","id":"500","description":"500","subdescription":"MONTAGEM MECANICA"},{"row_id":"17","id":"700","description":"700","subdescription":"PINTURA"}]',
    ),
  );
  const [date, setDate] = useState<Date | null>(new Date());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const handleSwitchDate = (direction: 'left' | 'right') => {
    const newDate = date;
    if (direction === 'left') {
      newDate.setMonth(newDate.getMonth() - 1);
    } else if (direction === 'right') {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
    setDate(newDate);
  };

  useEffect(() => {
    localStorage.setItem('intranet@erp-production-dashboard-stages', JSON.stringify(stages));
  }, [stages]);

  return (
    <Box className={classes.root}>
      <Box sx={{ width: '100%', p: 1, mb: 1 }}>
        <Accordion>
          <AccordionSummary
            sx={{ width: '100%' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Filtro</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: _theme => (_theme.palette.mode === 'dark' ? grey[700] : grey[50]),
                borderRadius: 1,
                border: _theme => `1px solid ${_theme.palette.mode === 'dark' ? grey[800] : grey[300]}`,
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                <IconButton aria-label="delete" size="medium" onClick={() => handleSwitchDate('left')}>
                  <ArrowLeftIcon fontSize="inherit" />
                </IconButton>

                <DatePicker
                  views={['month', 'year']}
                  label="Data"
                  minDate={new Date('2012-03-01')}
                  maxDate={new Date()}
                  value={date}
                  onChange={newValue => {}}
                  onAccept={newValue => {
                    setDate(newValue);
                    setMonth(newValue.getMonth() + 1);
                    setYear(newValue.getFullYear());
                  }}
                  /*
                    renderInput={params => (
                      <TextField sx={{ minWidth: 180 }} size="small" {...params} helperText={null} />
                    )}
                    */
                />

                <IconButton aria-label="delete" size="medium" onClick={() => handleSwitchDate('right')}>
                  <ArrowRightIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <InputSearchAsync
                fullWidth
                source="erp-op-stages"
                id="erp-production-dashboard-stages"
                value={stages}
                noOptionsText="Nenhum estágio encontrado."
                placeholder="Pesquisar Estágios"
                label="Estágios (+)"
                onChange={(e: ValueProps[]) => {
                  setStages(e);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Grid container spacing={2} style={{ width: '100%' }}>
        {stages?.map(stage => {
          return (
            <Grid key={stage.id} item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ChartLine stage={stage.id} month={month} year={year} />
            </Grid>
          );
        })}

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ProductionNote />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <SalesXProduction />
        </Grid>
      </Grid>
    </Box>
  );
}
