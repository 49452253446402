import React, { Dispatch, SetStateAction } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Backdrop, Typography } from '@mui/material';
import Axios, { Canceler } from 'axios';

import api from './services/api';
import { routers, breadcrumbs, routerList } from './pages';
import AuthenticatePage from './pages/authenticate';
import NotFoundPage from './pages/templates/not_found';
import AccessDeniedPage from './pages/templates/access_denied';
import DashboardPage from './pages/dashboard';
import Layout from './components/Layout';
import { PrivateRoute } from './components/PrivateRoute';

interface RoutesProps {
  darkMode: boolean | undefined;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  company: string | undefined;
  setCompany: Dispatch<SetStateAction<string>>;
  branch: string | undefined;
  setBranch: Dispatch<SetStateAction<string>>;
  user: UserDataProps;
  loading?: boolean;
}

export interface UserDataProps {
  change_password: boolean;
  email: string;
  email2: string;
  employee_position: string;
  erp_branch_id: number;
  erp_company_id: number;
  erp_id: number;
  id: number;
  login: string;
  menuItems: any[];
  name: string;
  nickname: string;
  numcad: number;
  rules: any;
  sector: string;
  settings: any;
  situation: number;
  tipcol: number;
}

export function LoadingPage({ darkMode }) {
  return (
    <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
      <Typography variant="caption" style={{ color: darkMode ? '#676767' : '#676767' }}>
        Carregando...
      </Typography>
    </Backdrop>
  );
}

function ProtectedPage({ path, content, user, darkMode }) {
  return !user ? <LoadingPage darkMode={darkMode} /> : user?.rules[path] === '1' ? content : <AccessDeniedPage />;
}

export default function AppRoutes(props: RoutesProps) {
  const { darkMode, setDarkMode, user, loading, branch, company, setBranch, setCompany } = props;

  if (loading) return <LoadingPage darkMode={darkMode} />;

  return (
    <BrowserRouter>
      <Routes>
        <>
          <Route
            path="/"
            element={!user ? <Navigate to="/authenticate" replace /> : <Navigate to="/dashboard" replace />}
          />
          {routerList?.map(route => (
            <Route
              key={route?.path}
              path={`/${route?.path}`}
              element={
                !user ? (
                  <Navigate to="/authenticate" replace />
                ) : (
                  <Layout
                    pageSettings={breadcrumbs.filter(f => f.path === route?.path)?.[0]}
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                    branch={branch}
                    setBranch={setBranch}
                    company={company}
                    setCompany={setCompany}
                    user={user}
                  >
                    <ProtectedPage path={route?.path} content={route?.element} user={user} darkMode={setDarkMode} />
                  </Layout>
                )
              }
              errorElement={route?.errorElement}
            />
          ))}

          <Route
            path="/authenticate/recovery"
            element={
              !user ? (
                <AuthenticatePage darkMode={darkMode} setDarkMode={setDarkMode} page="recovery" />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />
          <Route
            path="/authenticate/reset"
            element={
              !user ? (
                <AuthenticatePage darkMode={darkMode} setDarkMode={setDarkMode} page="reset" />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />
          <Route
            path="/authenticate"
            element={
              !user ? (
                <AuthenticatePage darkMode={darkMode} setDarkMode={setDarkMode} page="auth" />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />
          <Route
            path="*"
            element={
              !user ? (
                <Navigate to="/authenticate" replace />
              ) : (
                <Layout
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                  branch={branch}
                  setBranch={setBranch}
                  company={company}
                  setCompany={setCompany}
                  user={user}
                >
                  <NotFoundPage />
                </Layout>
              )
            }
          />
        </>
      </Routes>
    </BrowserRouter>
  );
}
