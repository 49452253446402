import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios, { Canceler } from 'axios';
import ImageGallery from 'react-image-gallery';

import api from '../../../services/api';

export default function GalleryPage() {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  const [loadingData, setLoadingData] = useState(false);

  const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<[]>('hcm/birthdays', {
          cancelToken: new axios.CancelToken(async c => {
            cancel = c;
          }),
        });

        // setBirthdaysList(response.data);
      } catch (error) {}
    }
    setLoadingData(false);
    return () => cancel();
  };

  useEffect(() => {
    getData();
  }, []);

  if (loadingData)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.text.secondary,
          }}
        >
          Carregando...
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{ display: 'flex', gap: 4, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
    >
      <ImageGallery items={images} />
    </Box>
  );
}
