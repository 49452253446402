import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    gridItemRoot: {
      padding: 8,
      minHeight: 'calc(100vh - 196px)',
      height: 'calc(100vh - 196px)',
      maxHeight: 'calc(100vh - 196px)',
    },
    boxList: {
      height: 'calc(100% - 76px)',
      backgroundColor: darkMode ? '#2d2d2d' : '#d4d4d4',
      padding: 2,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 4,
      marginTop: 4,
      marginBottom: 8,
      overflowY: 'auto',
    },
    loaddingText: {
      color: darkMode ? '#a0a0a0' : '#696969',
    },
  });
});
