import React, { useEffect, useMemo, useState } from 'react';

import { Box, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { EngOsHomeTitle } from '../EngOsHomeTitle';

import { EngOsReportSchedulesList, EngOsReportSchedulesListFilterType } from './EngOsReportSchedulesList';
import { toNumber } from '../../../../../../utils/functions';

interface EngOsReportProps {
  resourceEngineeringReportFilters?: EngOsReportSchedulesListFilterType;
}

const reportList = [
  { label: 'Recursos Engenharia', id: 1 },
  // { label: 'Recursos Engenharia 2', id: 2 },
];

function EngOsReport({ resourceEngineeringReportFilters: attendanceFilters }: EngOsReportProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [selectReportInputValue, setSelectReportInputValue] = React.useState(1);

  const handleSelectReportInputValueChange = (event: SelectChangeEvent) => {
    setSelectReportInputValue(toNumber(event.target.value));
  };

  const [resourceEngineeringReportFilters, setFilters] = useState({
    budgets: [],
    customers: [],
    helpdesks: [],
    attendants: [],
    products: [],
    ended_at_end: '',
    os: [],
    orders: [],
  } as EngOsReportSchedulesListFilterType);

  useEffect(() => {
    setFilters(old => ({ ...old, ...attendanceFilters }));
  }, [attendanceFilters]);

  const inputSelectReports = useMemo(
    () => (
      <FormControl fullWidth>
        <InputLabel id="select-reports-label">Relatório</InputLabel>
        <Select
          labelId="select-reports-label"
          id="select-reports"
          value={selectReportInputValue.toString()}
          label="Relatório"
          onChange={handleSelectReportInputValueChange}
        >
          {reportList.map(v => (
            <MenuItem key={v.id} value={v.id}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [selectReportInputValue],
  );

  const resourceEngineeringReport = useMemo(
    () => (
      <>
        <EngOsHomeTitle
          title="Filtro"
          filterList={{
            situation: false,
            rework: false,
            customers: false,
            created_at: false,
            attendants: false,
            created_by: false,
          }}
          initialFilter={attendanceFilters}
          onChange={_filters => {
            setFilters(old => ({ ...old, ..._filters }));
          }}
        />
        <Paper sx={{ p: 2 }}>
          <EngOsReportSchedulesList filters={resourceEngineeringReportFilters} />
        </Paper>
      </>
    ),
    [selectReportInputValue, resourceEngineeringReportFilters],
  );
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {inputSelectReports}
          {selectReportInputValue === 1 && resourceEngineeringReport}
        </Box>
      </Box>
    </Box>
  );
}

export { EngOsReport };

/*
<TabPanel value={currentTab} index={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EngOsHomeTitle
              title="Trâmites - Filtro"
              initialFilter={attendanceFilters}
              onChange={_filters => {
                setFilters(old => ({ ...old, ..._filters }));
              }}
            />
            <Paper sx={{ p: 2 }}>
              <EngOsReportList resourceEngineeringReportFilters={resourceEngineeringReportFilters} />
            </Paper>
          </Box>
        </TabPanel>

*/
