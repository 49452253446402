import React from 'react';
import { Box, LinearProgress, linearProgressClasses, LinearProgressProps, Typography } from '@mui/material';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const { value } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          {...props}
          variant="determinate"
          sx={{
            [`&>.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            },
            [`&>.${linearProgressClasses.bar}`]: {
              backgroundColor: theme => {
                const dark = theme.palette.mode === 'dark';
                const colors = [
                  dark ? '#ff0000' : '#ff0000',
                  dark ? '#ffa700' : '#ffa700',
                  dark ? '#fff400' : '#ffff00',
                  dark ? '#a3ff00' : '#a3ff00',
                  dark ? '#2cba00' : '#2cba00',
                ];
                return colors[Math.round(((colors.length - 1) * value) / 100)];
              },
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 42 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: 'center', fontSize: 10, mt: 0.2 }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
export { LinearProgressWithLabel };
