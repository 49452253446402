import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Pagination } from '@mui/lab';
import Axios, { Canceler } from 'axios';
import styles from './styles';

import api from '../../../../../services/api';
import ExportDialog from '../ExportDialog';
import { ValueProps } from '../../../../../components/inputs/InputSearchAsync';

type LevelTablePorps = {
  products: ValueProps[];
  deposits: ValueProps[];
  onDataLoaded?: (data: LevelData) => void;
};
export type LevelData = {
  results: LevelType[];
  filters: {
    result_limit: number;
    page_current: number;
    deposits: string;
    products: string;
  };
  statistics: {
    total: {
      results: number;
      pages: number;
    };
    stock: {
      max: number;
      avaliable: number;
      percent: number;
    };
  };
};

export type LevelType = {
  codpro: string;
  despro: string;
  codder: string;
  qtdest: number;
  qtdblo: number;
  qtdres: number;
  qtdrae: number;
  qtdord: number;
  estmax: number;
  przrsu: number;
  curabc: string;
  qtdsol: number;
  qtddis: number;
  qtdest_formated: string;
  qtdblo_formated: string;
  qtdres_formated: string;
  qtdrae_formated: string;
  qtdord_formated: string;
  estmax_formated: string;
  qtdsol_formated: string;
  qtddis_formated: string;
  lead_time: number;
  lead_time_last_year: number;
  lead_time_percent: number;
};

export default function LevelTable(props: LevelTablePorps) {
  const classes = styles();
  const { products = [], deposits = [], onDataLoaded = data => {} } = props;

  const [loadingData, setLoadingData] = useState(false);
  const [levelResults, setLevelResults] = useState<LevelType[]>([]);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [levelsTotal, setLevelsTotal] = useState(0);
  const [showExportDialog, setShowExportDialog] = useState(false);

  useEffect(() => {
    loadData({ deposits, products, page_current: page });
  }, [deposits, products, page]);

  async function loadData({ deposits = [] as ValueProps[], products = [] as ValueProps[], page_current = 1 }) {
    if (loadingData) return;

    setLoadingData(true);

    let cancel: Canceler;

    let searchProducts = '';
    for (const product of products) {
      searchProducts += `;${product.id}`;
    }

    let searchDeposits = '';
    for (const deposit of deposits) {
      searchDeposits += `;${deposit.id}`;
    }

    const params = {
      result_limit: 20,
      page_current,
      deposits: searchDeposits,
      products: searchProducts,
    };

    const response = await api.get('erp/stock/level', {
      params,
      cancelToken: new Axios.CancelToken(c => {
        cancel = c;
      }),
    });

    const data = response?.data as LevelData;

    if (data) {
      setLevelResults(data.results);
      setPageTotal(data.statistics.total.pages);
      setLevelsTotal(data.statistics.total.results);
      onDataLoaded(data);
    }
    setLoadingData(false);
    return () => cancel();
  }

  const handlePagination = (event: any, p: number) => {
    if (p < 1 || p > pageTotal || p === page) return;
    setPage(p);
  };

  return (
    <Box className={classes.tableRoot}>
      <Paper className={classes.tablePaper}>
        {loadingData && (
          <Box className={classes.tableLoading}>
            <Typography className={classes.loadingText}>Carregando...</Typography>
          </Box>
        )}
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>PRODUTO</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>DESCRIÇÃO</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>ESTOQUE</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>DISPONÍVEL</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>BLOQUEADO</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>RESERVADO</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>RESERVA EXCLUSIVA</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>QTD. SC</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>ORDEM O.C./O.P.</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>EST. MAX</Typography>
                </TableCell>
                <TableCell align="center" style={{ minWidth: 128 }}>
                  <Typography style={{ fontSize: 14 }}>LEAD TIME</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontSize: 14 }}>CURVA ABC</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {levelResults.map((level: LevelType, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`${level.codpro}-${index}`}>
                    <TableCell align="right" style={{ minWidth: 148 }}>
                      {level.codpro}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 210 }}>
                      {level.despro}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdest_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtddis_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdblo_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdres_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdrae_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdsol_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.qtdord_formated}
                    </TableCell>
                    <TableCell align="right" style={{ width: 128 }}>
                      {level.estmax_formated}
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 128 }}>
                      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="column">
                        <Tooltip title="Lead time do último ano">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            className={`${classes.leadTimeLastYear} ${
                              level.lead_time_last_year > level.lead_time
                                ? classes.redColor
                                : level.lead_time_last_year === level.lead_time
                                ? classes.blueColor
                                : classes.greenColor
                            }`}
                            width="100%"
                          >
                            {level.lead_time_last_year > level.lead_time ? (
                              <ArrowDropUpRoundedIcon
                                className={`${classes.leadTimeLastYearIcon} ${classes.redColor}`}
                              />
                            ) : level.lead_time_last_year === level.lead_time ? (
                              <ArrowRightRoundedIcon
                                className={`${classes.leadTimeLastYearIcon} ${classes.blueColor}`}
                              />
                            ) : (
                              <ArrowDropDownRoundedIcon
                                className={`${classes.leadTimeLastYearIcon} ${classes.greenColor}`}
                              />
                            )}
                            <Typography className={`${classes.leadTimeLastYear} `}>
                              {level.lead_time_last_year} dia(s)
                            </Typography>
                          </Box>
                        </Tooltip>
                        <Tooltip title="Lead time total">
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Typography className={classes.leadTime}>{level.lead_time}</Typography>
                            <Typography className={classes.leadTimeDays}>dia(s)</Typography>
                          </Box>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{level.curabc}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container>
          <Grid item xs={12} sm={11} md={11}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row" width="100%">
              <Box className={classes.paginationContainer}>
                <Pagination
                  size="medium"
                  count={pageTotal}
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  disabled={loadingData || pageTotal <= 1}
                  onChange={handlePagination}
                  siblingCount={0}
                />
                <Typography variant="caption" style={{ marginTop: 8 }}>
                  {`${levelsTotal || 0} Registros encontrados`}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={1} md={1}>
            <Box className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowExportDialog(true);
                }}
              >
                EXPORTAR
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <ExportDialog
        deposits={deposits}
        show={showExportDialog}
        onClose={() => {
          setShowExportDialog(false);
        }}
      />
    </Box>
  );
}
