import React, { useState } from 'react';
import { Box } from '@mui/material';

import styles from './styles';
import OrderTable, { FiltersType } from './OrderTable';
import Filters, { FiltersInputsProps } from '../../../../components/Filters';

export default function ErpOrdersSearch() {
  const classes = styles();
  const [filter, setFilter] = useState<FiltersType>({});
  const filtersInputs = [
    {
      type: 'combobox',
      id: 'erp-orders-search-limit',
      label: 'Registros por página',
      defaultValue: 25,
      comboBoxItems: [
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 150, text: '150' },
      ],
    },
    {
      type: 'date',
      id: 'erp-orders-search-created-start',
      label: 'Data Geração (Inicio)',
      dateType: 'start',

      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-search-created-end',
      label: 'Data Geração (Fim)',
      dateType: 'end',

      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-search-deadline-start',
      label: 'Data Prevista (Inicio)',
      dateType: 'start',

      defaultValue: '',
    },
    {
      type: 'date',
      id: 'erp-orders-search-deadline-end',
      label: 'Data Prevista (Fim)',
      dateType: 'end',

      defaultValue: '',
    },
    {
      type: 'customers',
      id: 'erp-orders-search-customers',
      defaultValue: '[]',
      resultLimit: 100,
    },
    {
      type: 'order',
      id: 'erp-orders-search-id',
      label: 'Número Pedido(+)',
      placeholder: 'Pesquisar Número Pedido',
      noOptionsText: 'Nenhum pedido encontrado.',
      defaultValue: '[]',
      resultLimit: 100,
    },
    {
      type: 'erp-orders-situation',
      id: 'erp-orders-search-situation',
      defaultValue: '[]',
    },
  ] as FiltersInputsProps[];

  const handleApplyFilter = (e: any[]) => {
    const newFilter: FiltersType = {
      page_current: Number(e[0]),
      created_at_start: e[1],
      created_at_end: e[2],
      deadline_start: e[3],
      deadline_end: e[4],
      custumer: e[5].map(c => {
        return c.id;
      }),
      order_id: e[6].map(c => {
        return c.id;
      }),
      situation: e[7].map(c => {
        return c.id;
      }),
    };
    setFilter(newFilter);
  };

  return (
    <Box>
      <Filters
        onFilterApply={(e: string[]) => {
          handleApplyFilter(e);
        }}
        inputs={filtersInputs}
        // initialInputsValue={inputsValue}
      />
      <Box className={classes.root}>
        <OrderTable filter={filter} />
      </Box>
    </Box>
  );
}
