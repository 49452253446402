import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Tooltip,
  TableContainer,
  Typography,
  Badge,
  Stack,
  ListItemButton,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Rating } from '@mui/lab';
import { Link } from 'react-router-dom';
import moment from 'moment';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ForumIcon from '@mui/icons-material/Forum';
import Axios, { Canceler } from 'axios';
import api from '../../../services/api';
import styles from './styles';

export const helpdeskSituation = ['Pendente', 'Andamento', 'Finalizado', 'Cancelado'];

interface HelpdeskDashboardData {
  pedding: HelpdeskPendingDashboardData[];
  done: HelpdeskDoneDashboardData[];
  im_attendant: boolean;
}
interface HelpdeskPendingDashboardData {
  id: number;
  title: string;
  situation: number;
  situation_description: string;
  deadline: number;
  deadline_formated: string;
  days_delay: number;
  days_remaining: number;
  posts_total: number;
  posts_unreaded: number;
}
interface HelpdeskDoneDashboardData {
  id: number;
  title: string;
  date: string;
  rate: number;
}

export interface HelpdeskData {
  data_total: number;
  current_page: number;
  total_page: number;
  data_limit: number;
  order_dir: string;
  order_by: string;
  statistics: HelpdeskStatistics;
  data: Helpdesk[];
}
export interface Helpdesk {
  id: number;
  owner: number;
  owner_name: string;
  owner_sector: string;
  attendance: number;
  attendance_name: string;
  attendance_sector: string;
  target: number;
  target_description: string;
  category: number;
  category_description: string;
  subcategory: number;
  subcategory_description: string;
  private: number;
  deadline: number;
  title: string;
  description: string;
  created_at: string;
  deadline_date: string;
  ended_in: string;
  rate: number;
  feedback: string;
  feedback_delay: string;
  situation: number;
  situation_description: string;
  im_owner: boolean;
  im_attendant: boolean;
  im_can_attendant: boolean;
  posts: HelpdeskPost[];
  attachments: HelpdeskAttachment[];
  diff_days: number;
  created_at_formated: string;
  deadline_formated: string;
  ended_in_formated: string;
  days_spent: number;
  days_delay: number;
  days_remaining: number;
  rate_feedback: string;
  posts_unreaded: number;
  links: HelpdeskLink[];
  helpdesk_feedback: string;
  helpdesk_rate: number;
  recidivism: number;
}
export interface HelpdeskLink {
  id: number;
  owner: number;
  owner_name: string;
  owner_sector: string;
  attendance: number;
  attendance_name: string;
  attendance_sector: string;
  target: number;
  target_description: string;
  category: number;
  category_description: string;
  subcategory: number;
  subcategory_description: string;
  private: number;
  deadline: number;
  title: string;
  created_at: string;
  deadline_date: string;
  ended_in: string;
  situation: number;
  situation_description: string;
  im_owner: boolean;
  im_attendant: boolean;
  im_can_attendant: boolean;
  diff_days: number;
  created_at_formated: string;
  deadline_formated: string;
  ended_in_formated: string;
  days_spent: number;
  days_delay: number;
  days_remaining: number;
}
export interface HelpdeskPost {
  id: number;
  owner: number;
  owner_name: string;
  owner_sector: string;
  text: string;
  created_at: string;
  created_at_formated: string;
  situation: number;
  attachments: HelpdeskAttachment[];
  type: number;
  is_my: boolean;
  extend_request: number;
  extend_days: number;
  extend_answer: number;
  readed: boolean;
  readed_at: string;
  readed_at_formated: string;
}
export interface HelpdeskAttachment {
  name: string;
  url: string;
  owner: number;
  owner_name: string;
  situation: number;
}
export interface HelpdeskAttendant {
  target_id: number;
  attendants_id: number;
  attendants_name: string;
  type: number;
  situation: number;
}
export interface HelpdeskStatistics {
  pending: number;
  in_progress_in_time: number;
  in_progress_out_of_time: number;
  finished_in_time: number;
  finished_out_of_time: number;
  canceled: number;
  rate: number;
  rate_amount: number;
  rate_medium: number;
}
export function IconTypeName(type: string) {
  return type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'bmp'
    ? 'image'
    : type === 'xls' || type === 'xlsx'
      ? 'spreadsheet'
      : type === 'gz' ||
          type === 'rar' ||
          type === 'tar' ||
          type === 'zip' ||
          type === 'zipx' ||
          type === '7zip' ||
          type === '7z'
        ? 'compressed'
        : type === 'yml' ||
            type === 'htm' ||
            type === 'html' ||
            type === 'java' ||
            type === 'js' ||
            type === 'json' ||
            type === 'xml'
          ? 'code'
          : type === 'pdf'
            ? 'acrobat'
            : type === 'ppt' || type === 'pptx'
              ? 'presentation'
              : 'document';
}

export default function HelpdeskDashboard(props: any) {
  const classes = styles();
  const [gettingData, setGettingData] = useState<boolean>(false);
  const [helpdeskDashboardData, setHelpdeskDashboardData] = useState<HelpdeskDashboardData>({
    pedding: [],
    done: [],
    im_attendant: false,
  } as HelpdeskDashboardData);

  const [selectedCategory, setSelectedCategoryIndex] = useState(0);
  const [selectedFilter, setSelectedFilterIndex] = useState(0);

  const handleListCategoryItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedCategoryIndex(index);
  };
  const handleListFilterItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedFilterIndex(index);
  };
  const getHelpDeskDashboardData = () => {
    let cancel: Canceler;
    if (!gettingData) {
      setGettingData(true);
      api
        .get('helpdesk/dashboard', {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setHelpdeskDashboardData(response.data);
          setGettingData(false);
        })
        .catch((error: any) => {
          console.error(error);
          setGettingData(false);
        });
    }
    return () => cancel();
  };

  useEffect(() => {
    getHelpDeskDashboardData();
  }, []);

  return (
    <Box>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="baseline" style={{ gap: 12 }} p={1.5}>
                {helpdeskDashboardData?.im_attendant && (
                  <Tooltip title="Atendimento aos Helpdesk's" aria-label="attendance-helpdesk">
                    <Button color="secondary" variant="outlined" component={Link} to="/helpdesk/attendance">
                      ATENDIMENTO
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Novo Helpdesk" aria-label="add-helpdesk">
                  <Button color="primary" variant="contained" component={Link} to="/helpdesk/add">
                    NOVO
                  </Button>
                </Tooltip>

                <Tooltip title="Consultar Helpdesk's" aria-label="view-helpdesk">
                  <Button color="primary" variant="contained" component={Link} to="/helpdesk/search">
                    CONSULTAR
                  </Button>
                </Tooltip>
                {false && (
                  <Tooltip title="Ralatórios Helpdesk's" aria-label="view-helpdesk">
                    <Button color="inherit" variant="contained" component={Link} to="/helpdesk/reports">
                      RELATÓRIOS
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.tableTitle}>MEUS ÚLTIMOS HELPDESK'S PENDENTES</Typography>
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" />
                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          Nº
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="caption" color="textSecondary">
                          TÍTULO
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          PREVISÃO
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption" color="textSecondary">
                          TRÂMITES
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          SITUAÇÃO
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gettingData ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="caption" className={classes.loadingInitialDataText}>
                            Carregando...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : helpdeskDashboardData?.pedding?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="caption">Não foi encontrado nenhum HELPDESK pendente.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      helpdeskDashboardData?.pedding?.map(helpdesk => {
                        const rowId = `hd-row-${helpdesk.id}`;
                        return (
                          <TableRow hover tabIndex={-1} key={rowId}>
                            <TableCell width={16} padding="none" align="center">
                              <Tooltip title="Visualizar" aria-label="view">
                                <IconButton component={Link} to={`/helpdesk/${helpdesk.id}`} size="large">
                                  <Icon>open_in_new</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell width={18} align="left">
                              {helpdesk.id}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 200 }}>
                              {helpdesk.title}
                            </TableCell>

                            <TableCell width={150} align="center">
                              {helpdesk.situation !== 0 ? (
                                <>
                                  {helpdesk.deadline_formated}
                                  {helpdesk.days_delay === 0 ? (
                                    <Typography className={classes.helpdeskOnForecastDescription}>
                                      {helpdesk.days_remaining > 0 ? (
                                        `${helpdesk.days_remaining} dias úteis de prazo`
                                      ) : helpdesk.days_remaining === 0 ? (
                                        <Typography className={classes.helpdeskNearForecastDescription}>
                                          Último dia
                                        </Typography>
                                      ) : (
                                        `${helpdesk.days_remaining} dia útil de prazo`
                                      )}
                                    </Typography>
                                  ) : (
                                    <Typography className={classes.helpdeskOutForecastDescription}>
                                      {helpdesk.days_delay > 1
                                        ? `${helpdesk.days_delay} dias em atraso`
                                        : `${helpdesk.days_delay} dia em atraso`}{' '}
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <Typography className={classes.helpdeskWaittingDateText}>SEM PREVISÃO</Typography>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {helpdesk.posts_total}
                              <Badge
                                badgeContent={helpdesk.posts_unreaded}
                                color="error"
                                variant="dot"
                                style={{ marginLeft: 8 }}
                              >
                                <ForumIcon style={{ fontSize: 16 }} />
                              </Badge>
                            </TableCell>
                            <TableCell width={24} align="center">
                              {helpdesk.situation === 0 && (
                                <WarningIcon className={classes.warningIimeSituationColor} />
                              )}
                              {helpdesk.situation === 1 && (
                                <WatchLaterIcon
                                  className={
                                    helpdesk.days_delay === 0
                                      ? classes.onTimeSituationColor
                                      : classes.offTimeSituationColor
                                  }
                                />
                              )}
                              {helpdesk.situation === 2 && (
                                <CheckCircleIcon
                                  className={
                                    helpdesk.days_delay === 0
                                      ? classes.onTimeSituationColor
                                      : classes.offTimeSituationColor
                                  }
                                />
                              )}
                              {helpdesk.situation === 3 && <CancelIcon className={classes.offTimeSituationColor} />}

                              {helpdeskSituation[helpdesk.situation]}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.helpdeskFooter}>
                <Button component={Link} color="primary" variant="outlined" to="/helpdesk/pending">
                  VISUALIZAR TODOS
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.tableTitle}>MEUS ÚLTIMOS HELPDESK'S FINALIZADOS</Typography>
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" />
                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          Nº
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="caption" color="textSecondary">
                          TÍTULO
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          FINALIZADO
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          AVALIAÇÃO
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gettingData ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="caption" className={classes.loadingInitialDataText}>
                            Carregando...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : helpdeskDashboardData?.done?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="caption">Não foi encontrando nenhum HELPDESK finalizado.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      helpdeskDashboardData?.done?.map(helpdesk => {
                        const rowId = `hpk-rate-row-${helpdesk.id}`;
                        const date = moment.utc(helpdesk.date).format('DD/MM/YYYY');
                        const hour = moment.utc(helpdesk.date).format('HH:mm');

                        return (
                          <TableRow hover tabIndex={-1} key={rowId}>
                            <TableCell width={16} padding="none" align="center">
                              <Tooltip title="Visualizar" aria-label="view">
                                <IconButton component={Link} to={`/helpdesk/${helpdesk.id}`} size="large">
                                  <Icon>open_in_new</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell width={18} align="left">
                              {helpdesk.id}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 200 }}>
                              {helpdesk.title}
                            </TableCell>
                            <TableCell width={150} align="center">
                              {date}
                              <Typography style={{ fontSize: 11 }}>{hour}</Typography>
                            </TableCell>
                            <Tooltip
                              title={helpdesk.rate > 0 ? `${helpdesk.rate} / 5 ESTRELAS` : 'Sem Avaliação'}
                              aria-label="rate"
                            >
                              <TableCell align="center">
                                <Rating size="small" name="read-only" value={helpdesk.rate} precision={1} readOnly />
                              </TableCell>
                            </Tooltip>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.helpdeskFooter}>
                <Button component={Link} color="primary" variant="outlined" to="/helpdesk/finalized">
                  VISUALIZAR TODOS
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
