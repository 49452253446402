import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { Box, CircularProgress, FilledInput, IconButton, InputAdornment, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import axios, { Canceler } from 'axios';
import { LoadingButton } from '@mui/lab';
import styles from './styles';

import api from '../../../../../services/api';
import { MessageAlert } from '../../../../../components/MessageAlert';

type OrderObsTextField = {
  order_id: number;
  item_id: number;
  obs: 'obsct1' | 'obsct2';
  value: string;
};

function OrderObsTextField({ order_id = 0, item_id = 0, obs = 'obsct1', value = '' }: OrderObsTextField) {
  const classes = styles();
  const [initialValue, setInitialValue] = useState(value?.toString().trim() || '');
  const [inputValue, setInputValue] = useState(initialValue);
  const [loadingData, setLoadingData] = useState(false);
  const [posting, setPosting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  async function postValue() {
    setPosting(true);
    try {
      const { data } = await api.post(`erp/order/${order_id}/${item_id}/details`, {
        fields: [obs],
        values: [inputValue.trim()],
      });
      setPosting(false);
      setSnackbarType('success');
      setSnackbarMsg('Obs atualizada com sucesso!');
      setOpenSnackbar(true);
      setInitialValue(inputValue.trim());
    } catch (error) {
      let errorText = error.response?.data?.message || error.message;

      if (errorText === 'invalid_order_id') {
        errorText = 'Número do pedido inválido';
      } else if (errorText === 'invalid_order_item_id') {
        errorText = 'Sequência do item do pedido inválido';
      } else if (errorText === 'unauthorized') {
        errorText = 'Você não tem autorização para atualizar a descrição.';
      } else {
        errorText = 'Houve um erro ao tentar atualizar a obs, tente mais tarde ou contate o TI para mais informações';
      }

      setPosting(false);
      setSnackbarType('error');
      setSnackbarMsg(errorText);
      setOpenSnackbar(true);
    }
  }

  const handleOnChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };
  const handleClickClear = () => {
    setInputValue(initialValue);
  };
  const handleClickSave = async () => {
    if (order_id < 1 || item_id < 1 || posting) return;

    await postValue();
  };

  const handleMouseDownSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <FilledInput
        id={obs}
        type="text"
        value={inputValue}
        onChange={handleOnChangeValue}
        size="small"
        hiddenLabel
        sx={{ borderRadius: 1 }}
        disableUnderline
        endAdornment={
          <>
            <InputAdornment position="end">
              <IconButton
                aria-label="reset"
                onClick={handleClickClear}
                onMouseDown={handleMouseDownSave}
                edge="end"
                disabled={posting || inputValue === initialValue}
              >
                <Tooltip title="Limpar">
                  <ClearIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                aria-label="save"
                onClick={handleClickSave}
                onMouseDown={handleMouseDownSave}
                edge="end"
                disabled={posting || inputValue === initialValue}
              >
                <Tooltip title="Salvar">
                  {posting ? <CircularProgress disableShrink size="20px" /> : <SaveIcon fontSize="small" />}
                </Tooltip>
              </IconButton>
            </InputAdornment>
          </>
        }
      />
      <MessageAlert message={snackbarMsg} type={snackbarType} show={openSnackbar} onClose={handleCloseAlert} />
    </Box>
  );
}

export { OrderObsTextField };
