import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, grey, purple } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {},
    containerRoot: {
      padding: 16,
      marginBottom: 16,
    },
    containerTitle: {
      fontWeight: 'bold',
      paddingBottom: 16,
    },
    tableRoot: {
      padding: 16,
      backgroundColor: darkMode ? '#525252' : '#e4e4e4',
    },
    tableHead: {
      backgroundColor: darkMode ? '#676767' : '#d2d2d2',
    },
    noWorkingRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingRight: 16,
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: darkMode ? '#525252' : '#e4e4e4',
    },
    noWorkingText: {
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    onTimeText: {
      color: darkMode ? '#93fb9b' : '#13a71e',
    },
    outTimeText: {
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    loadingInitialDataText: {
      color: darkMode ? '#676767' : '#676767',
    },
    chartTitle: {
      color: darkMode ? '#cccccc' : '#676767',
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 8,
    },
    titleStage: {
      color: darkMode ? '#7cd8fb' : '#1e88e5',
      fontSize: 16,
      fontWeight: 'bold',
      // marginBottom: 8,
    },
    titleStageZero: {
      color: darkMode ? '#ff7575' : '#676767',
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    operatorName: {
      // color: darkMode ? '#cccccc' : '#676767',
      fontSize: 14,
    },
    operatorStage: {
      // color: darkMode ? '#cccccc' : '#676767',
      color: darkMode ? '#cccccc' : '#676767',
      fontSize: 10,
    },
    operatorContainer2: {
      backgroundColor: darkMode ? '#565656' : '#e6e6e6',
      marginBottom: 16,
      padding: 8,
      borderRadius: 6,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    operatorHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    operatorDetails: {
      borderRadius: 6,
      padding: 8,
      marginTop: 8,
      backgroundColor: darkMode ? '#424242' : '#f9f9f9',
    },
    stageContainer: {
      backgroundColor: darkMode ? '#252525' : '#f9f9f9',
    },
    operatorContainer: {
      backgroundColor: darkMode ? '#383838' : '#eaeaea',
    },
    stageDescription: {
      fontSize: 12,
      color: darkMode ? '#cccccc' : '#676767',
    },
    stageDescriptionOnTime: {
      fontSize: 12,
      color: darkMode ? '#93fb9b' : '#13a71e',
    },
    stageDescriptionOutTime: {
      fontSize: 12,
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    productionDataTitle: {
      fontSize: 10,
      color: darkMode ? '#a2a2a2' : '#616161',
    },
    productionDataValue: {
      fontSize: 13,
      color: darkMode ? '#dedede' : '#212121',
      borderBottom: '1px solid #616161',
    },
    productionDataValueOnTime: {
      fontSize: 13,
      color: darkMode ? '#ec9e9b' : '#c12216',
      borderBottom: `1px solid ${darkMode ? '#ec9e9b' : '#c12216'}`,
    },
    productionDataValueOutTime: {
      fontSize: 13,
      color: darkMode ? '#93fb9b' : '#13a71e',
      borderBottom: `1px solid ${darkMode ? '#93fb9b' : '#13a71e'}`,
    },
    operatorWithoutOp: {
      padding: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: darkMode ? '#383838' : '#e4e4e4',
      borderBottom: `1px solid ${darkMode ? '#565656' : '#c3c3c3'}`,

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      },
      '&:last-child': {
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        borderBottom: 'none',
      },
    },
    orderTitle: {
      // color: darkMode ? '#cccccc' : '#676767',
      color: darkMode ? '#cccccc' : '#676767',
    },
    operatorImg: {
      height: 48,
      width: 48,
      borderRadius: '50%',
      border: '1px solid',
      borderColor: darkMode ? grey[700] : grey[400],
      marginRight: 16,
    },
    checkList: {
      fontSize: 20,
    },
    greenColor: {
      color: darkMode ? '#93fb9b!important' : '#13a71e!important',
    },
    redColor: {
      color: darkMode ? '#ec9e9b!important' : '#c12216!important',
    },
    hoursBank: {
      fontSize: 14,
      color: darkMode ? purple[200] : purple[600],
      fontWeight: 'bold',
    },
    hoursTotalBank: {
      fontSize: 14,
      color: darkMode ? blue[200] : blue[600],
      fontWeight: 'bold',
    },
  });
});
