import React from 'react';
import { Box, Typography } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import styles from './styles';

export interface Props {}

export default function UnderDevelopmentComponent(props: any & Props) {
  const classes = styles();
  const { value, colors } = props;
  return (
    <Box className={classes.root}>
      <div className={classes.img} />
      <WarningIcon className={classes.iconWarning} />
      <Typography variant="h6" className={classes.text}>
        O conteúdo que procura está em desenvolvimento
      </Typography>
    </Box>
  );
}
