import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey, orange, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentRoot: {},
    actions: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      paddingTop: 2,
    },
    row: {},
    rowTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
      alignItems: 'left',
      paddingLeft: 1,
    },
    inputPaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

      padding: 2,
    },
    emptyPaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: theme.spacing(11),
      marginBottom: 2,
    },
    summary: {},

    resultRow: {
      backgroundColor: darkMode ? '#505050' : '#f1f1f1',
    },
    filtersDetails: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: darkMode ? '#3c3c3c' : '#f1f1f1',
    },
    resultDetails: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: darkMode ? '#3c3c3c' : '#e0e0e0',
    },
    subResultRow: {
      backgroundColor: darkMode ? '#505050' : '#f9f9f9',
    },
    subResultDetails: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: darkMode ? '#313131' : '#f1f1f1',
    },
    rowTitleMain: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    rowTitleMainActive: {
      fontWeight: 'bold',
      color: darkMode ? '#313131' : '#096009',
    },
    rowTitleMainInative: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    rowSubtitle: {
      color: theme.palette.text.secondary,
    },
    progress: {},
    tableRowItems: {
      backgroundColor: darkMode ? '#525252' : '#d6d6d6',
      width: '100%',
    },

    tableRowItemsDetails: {
      backgroundColor: darkMode ? '#2f2f2f' : '#f9f9f9',
    },
    tableRowItemsButton: {
      width: theme.spacing(8),
      maxWidth: theme.spacing(8),
      minWidth: theme.spacing(8),
    },
    rowItems: {
      padding: 2,
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    itemDataRowTitle: {
      color: theme.palette.text.secondary,
    },
    itemDataRowValue: {
      color: theme.palette.text.primary,
    },
    iconFilterResult: {
      marginLeft: '4px',
      marginRight: '4px',
      color: darkMode ? deepPurple[300] : deepPurple[700],
    },
    filterInputRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    avatar: {
      color: theme.palette.getContrastText(darkMode ? orange[800] : orange[900]),
      backgroundColor: darkMode ? orange[800] : orange[900],
    },
    backgroundColor1: {
      backgroundColor: darkMode ? '#4c4c4c' : '#e8e8e8',
    },
    backgroundColor2: {
      backgroundColor: darkMode ? '#5f5e5e' : grey[300],
    },
    backgroundColor3: {
      backgroundColor: darkMode ? '#4c4c4c' : '#fff',
    },
    backgroundColor4: {
      backgroundColor: darkMode ? '#3c3c3c' : grey[400],
    },
    backgroundColor5: {
      backgroundColor: darkMode ? '#5f5f5f' : '#fff',
    },
    lotAccordionRoot: {
      backgroundColor: darkMode ? '#5a5a5a' : '#fff',
    },
    lotAccordionDetailsRoot: {
      backgroundColor: darkMode ? '#424242' : '#bbbbbb',
    },
    lotTableHeadRoot: {
      backgroundColor: darkMode ? '#4e4e4e' : '#e2e2e2',
    },
    lotTableBodyRoot: {
      backgroundColor: darkMode ? '#5f5f5f' : '#fbfbfb',
    },

    resAccordionRoot: {
      backgroundColor: darkMode ? '#5a5a5a' : '#fff',
    },
    resAccordionDetailsRoot: {
      backgroundColor: darkMode ? '#424242' : '#bbbbbb',
    },
    resAccordionSub: {
      backgroundColor: darkMode ? '#6b6b6b' : '#f3f3f3',
    },
    resAccordionDetailsSub: {
      backgroundColor: darkMode ? '#383636' : '#a0a0a0',
    },
    resTableHeadRoot: {
      backgroundColor: darkMode ? '#4e4e4e' : '#e2e2e2',
    },
    resTableBodyRoot: {
      backgroundColor: darkMode ? '#5f5f5f' : '#fbfbfb',
    },
    tableRoot: {
      width: '100%',
    },
    tablePaper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 298px)',
      height: 'calc(100vh - 298px)',
    },
    tableVisuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    onTimeSituationColor: {
      color: darkMode ? '#93fb9b' : '#13a71e',
    },
    offTimeSituationColor: {
      color: darkMode ? '#ec9e9b' : '#c12216',
    },
    warningIimeSituationColor: {
      color: darkMode ? yellow[400] : yellow[900],
    },
  });
});
