import React, { useEffect, useState } from 'react';
import Axios, { Canceler } from 'axios';
import { Box, Typography, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import styles from './styles';

import api from '../../../../services/api';
import ProfileTooltip from '../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../utils/functions';

type UserType = {
  id: number;
  type: number;
  number: number;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  date_now: string;
  hours_total: number;
  hours_expiration: number;
  date_expiration: string;
  hours_total_formated: string;
  hours_expiration_formated: string;
  date_expiration_formated: string;
  last_date_updated: string;
  last_date_updated_formated: string;
};

let lastDate = '';
export default function HourBankList() {
  const classes = styles();
  const [data, setData] = useState<UserType[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const loadingText = 'Carregando...';

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);

      try {
        const response = await api.get<UserType[]>('manager/hours', {
          cancelToken: new Axios.CancelToken(async c => {
            cancel = c;
          }),
        });
        setData(response.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
      }
    }
    return () => cancel();
  };

  useEffect(() => {
    let active = true;
    if (active) {
      getData();
    }
    return () => {
      active = false;
    };
  }, []);

  if (loadingData)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>{loadingText}</Typography>
      </Box>
    );
  if (data?.length === 0)
    return (
      <Box className={classes.loadingContainer}>
        <Typography className={classes.loadingText}>Nenhuma marcação encontrada</Typography>
      </Box>
    );
  return (
    <>
      {data.map(user => {
        const userId = user.id.toString();
        const userName = user.nickname.trim();
        const printDate = lastDate !== user.date_expiration_formated;
        if (printDate) {
          lastDate = user.date_expiration_formated;
        }
        return (
          <Box key={user.id}>
            {printDate && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                  color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 700 : 400],
                }}
              >
                <Typography>Data limite {lastDate}</Typography>
              </Box>
            )}
            <Box
              className={`${classes.itemContainer} ${classes.titleItem} ${
                user.hours_expiration < 0
                  ? classes.colorRed
                  : user.hours_expiration > 0
                    ? classes.colorOrange
                    : classes.colorGreen
              }`}
            >
              <Box className={classes.hoursContainer}>
                <Box className={classes.hoursContainer}>
                  <Typography className={classes.hoursTitle}>Vencendo</Typography>
                  <Typography
                    className={`${classes.hoursValue} ${
                      user.hours_expiration < 0
                        ? classes.colorRed
                        : user.hours_expiration > 0
                          ? classes.colorOrange
                          : classes.colorGreen
                    }`}
                  >{`${user.hours_expiration_formated} `}</Typography>
                  <Typography className={classes.hoursDescription}>horas</Typography>
                </Box>
                <Box className={classes.hoursContainer}>
                  <Typography className={classes.dateDescription}>Data limite</Typography>
                  <Typography
                    className={classes.avatarDescriptionDateValue}
                  >{`${user.date_expiration_formated}`}</Typography>
                </Box>
              </Box>
              <Box className={classes.avatarContainer}>
                <Link to={`/profile/${userId}`} className={classes.avatarLink} style={{ textDecoration: 'none' }}>
                  <ProfileTooltip userId={userId}>
                    <Avatar
                      alt={`${userName}`}
                      src={avatarUrl(userId, true)}
                      aria-label={`${userName.toLowerCase()}`}
                      className={classes.avatar}
                    />
                  </ProfileTooltip>
                </Link>
                <Box className={classes.avatarDescriptionContainer}>
                  <Box width="100%">
                    <Typography className={classes.avatarDescriptionName}>{userName}</Typography>
                  </Box>
                  <Box display="flex" gap={8}>
                    <Box className={classes.dateDescriptionContainer}>
                      <Typography className={classes.dateDescription}>Total:</Typography>
                      <Typography
                        className={`${classes.totalHours} ${
                          user.hours_total < 0
                            ? classes.colorRed
                            : user.hours_total > 0
                              ? classes.colorOrange
                              : classes.colorGrey
                        } `}
                      >{`${user.hours_total_formated}`}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <Typography className={classes.dateDescription}>Atualização:</Typography>
                    <Typography className={classes.dateDescription}>{`${user.last_date_updated_formated}`}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}
