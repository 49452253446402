import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { HelpdeskCard } from './HelpdeskCard';
import api from '../../../../../services/api';

interface IHelpdeskPendingCard {}

function HelpdeskPendingCard() {
  const theme = useTheme();
  const [total, setTotal] = useState({ pending: 0, open: 0 });

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/pending');
      const { data: open } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');
      const newTotal = total;

      newTotal.pending = data.total;
      newTotal.open = open.total;

      setTotal(newTotal);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <HelpdeskCard
        loading={loading}
        title="Solicitações pendentes"
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total.pending)}
          </Typography>
        }
        subcontent={
          <Typography variant="caption">
            {total.open > 0 ? `${((total.pending / total.open) * 100).toFixed(0)}% pendentes` : '0% pendentes'}
          </Typography>
        }
        icon={<ErrorOutlineOutlinedIcon color="warning" sx={{ fontSize: 64 }} />}
        color={theme.palette.warning.main}
      />
    );
  }, [total.pending, total.open, theme.palette.warning.main]);

  return <>{renderCard}</>;
}

export { HelpdeskPendingCard };
