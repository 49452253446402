import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import styles from './styles';

export interface SearchInputTextProps {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any | undefined;
  className?: string;
  readOnly?: boolean;
}

function InputSearchText(props: SearchInputTextProps) {
  const classes = styles();
  const { id, label, value, className, onChange, ...rest } = props;
  const currentValue = value || '';
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      id={id}
      label={label}
      type="text"
      onChange={onChange}
      className={`${className} ${currentValue?.length > 0 ? classes.highlightText : ''}`}
      value={value}
      {...rest}
      // onChange={handleTextInput}
      // className={searchText?.length > 0 ? classes.filterText : ''}
    />
  );
}

export default InputSearchText;
