import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EggIcon from '@mui/icons-material/Egg';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { FaCarrot } from 'react-icons/fa';
import { GiCook } from 'react-icons/gi';
import { orange } from '@mui/material/colors';
import api from '../../../services/api';

type FoodType = {
  id: number;
  text: string;
};

type DisheType = {
  category_id: number;
  category_text: string;
  foods: FoodType[];
};

type MenuType = {
  id: number;
  date: string;
  situation: string;
  date_formated: string;
  date_full_formated: string;
  day_name: string;
  dishes: DisheType[];
};

function MenuPage() {
  const [menuData, setMenuData] = useState<MenuType[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [foods, setFoods] = useState<FoodType[]>([]);

  const getMenus = async () => {
    if (loadingData) return;
    setLoadingData(true);

    try {
      const { data: menusResponseData } = await api.get('/hcm/menu');
      setMenuData(menusResponseData.menus);
      const { data: foodsResponseData } = await api.get('/hcm/menu/food');
      setFoods(foodsResponseData.foods);
    } catch (error) {}

    setLoadingData(false);
  };

  const Menu = (props: { menu: MenuType }) => {
    const { menu } = props;
    const { date_formated, day_name, dishes } = menu;

    const Row = (_props: { first?: boolean; title: string; content: string; icon: ReactElement }) => {
      const { first = false, content, title, icon } = _props;
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 1,
            borderTop: theme =>
              first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
          }}
        >
          <Box
            sx={{
              minWidth: 28,
              maxWidth: 28,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'warning.light',
            }}
          >
            {icon}
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              {title}
            </Typography>
            {loadingData ? (
              <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} height={18} width="50%" />
            ) : (
              <Typography>{content}</Typography>
            )}
          </Box>
        </Box>
      );
    };

    return (
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <MenuBookIcon sx={{ color: 'warning.light' }} />
          <Typography>{date_formated}</Typography>
          <Typography>({day_name})</Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            pt: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
          }}
        >
          {dishes.map((dish: DisheType, index) => {
            const { category_id, category_text, foods } = dish;
            const key = `${dish.category_id}-${index}`;

            return (
              <Row
                key={key}
                first={true}
                icon={
                  category_id === 1 ? (
                    <RestaurantIcon />
                  ) : category_id === 2 ? (
                    <EggIcon />
                  ) : category_id === 3 ? (
                    <RiceBowlIcon />
                  ) : category_id === 4 ? (
                    <SoupKitchenIcon />
                  ) : category_id === 5 ? (
                    <DinnerDiningIcon />
                  ) : category_id === 6 ? (
                    <FaCarrot style={{ fontSize: '1.3rem' }} />
                  ) : category_id === 7 ? (
                    <IcecreamIcon />
                  ) : category_id === 8 ? (
                    <GiCook style={{ fontSize: '1.5rem' }} />
                  ) : null
                }
                title={category_text}
                content={foods.length > 0 ? foods.map(food => ` ${food.text}`).toString() : ' - '}
              />
            );
          })}
        </Box>
      </Paper>
    );
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <Box>
      {menuData?.map(menu => {
        return <Menu key={menu.id} menu={menu} />;
      })}
    </Box>
  );
}

export default MenuPage;
