import React, { useEffect, useState } from 'react';
import {
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Collapse,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  TableSortLabel,
  TableContainerProps,
  TableCellProps,
  Box,
  Button,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from './styles';
import { Loading } from '../Loading';

export type OrderType = {
  by: string;
  dir: 'asc' | 'desc';
};
export type ColumnType = {
  id: string;
  content?: any;
  orderable?: boolean;
  sortText?: string;
} & TableCellProps;

export type RowType = TableCellProps & {
  cells?: CellType[];
  content?: any;
  width?: number | string;
};
export type CellType = TableCellProps & {
  content?: any;
};
export type FilterType = {
  content?: any;
};
export interface DataTableProps extends TableContainerProps {
  columns: ColumnType[];
  filters?: FilterType[];
  rows: RowType[];
  loading?: boolean;
  onSort?: (by: string, dir: string) => void | undefined;
  sortDir?: string;
  variant?: 'default' | 'primary';
  minHeight?: string | number;
  maxHeight?: string | number;
}

function DataTable({
  columns,
  filters,
  rows,
  loading,
  onSort,
  variant,
  minHeight,
  maxHeight,
  ...rest
}: DataTableProps) {
  const classes = styles();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [sort, setSort] = useState<OrderType>({ by: '', dir: 'asc' });

  function Row(row: RowType) {
    const [open, setOpen] = useState(false);
    const { cells = [], content = null, style = {}, className } = row;

    return (
      <>
        <TableRow style={style} sx={{ '& > *': { borderBottom: 'unset' } }} className={className}>
          {content && (
            <TableCell sx={{ maxWidth: 50, width: 50, minWidth: 50 }}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}

          {cells?.map((cell, index) => {
            const { ...__rest } = cell;
            const cellIndex = `cell-${index}`;
            return (
              <TableCell
                {...__rest}
                key={cellIndex}
                component="th"
                scope="row"
                colSpan={!content && index === 0 ? 2 : 0}
              >
                {cell.content}
              </TableCell>
            );
          })}
        </TableRow>
        {content && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={columns?.length + 1}>
              <Collapse in={open} timeout="auto" unmountOnExit className={classes.contentContainer}>
                {content}
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }

  const handleSort = (by: string) => {
    const dir = sort.dir === 'asc' ? 'desc' : 'asc';
    setSort({
      by,
      dir,
    });
    onSort(by, dir);
  };

  const columnsRender = (
    <TableHead
      className={`${classes.tableHead} ${variant === 'primary' ? classes.tableHeadPrimary : ''}`}
      sx={{
        filter: loading ? 'blur(1.5px)' : '',
      }}
    >
      <TableRow>
        {columns?.map((column, index) => {
          const { id, align = 'left', content = null, orderable = false, sortText = '', ..._rest } = column;
          const columnIndex = `column-${id}-${index}`;
          return (
            <React.Fragment key={columnIndex}>
              {orderable ? (
                <TableCell
                  {..._rest}
                  align={align}
                  sortDirection={sort.by === id ? sort.dir : false}
                  colSpan={content && index === 0 ? 2 : 0}
                >
                  <Tooltip title={sortText?.length > 0 ? sortText : ''} aria-label="orderby-number">
                    <TableSortLabel
                      active={sort.by === id}
                      direction={sort.dir}
                      onClick={() => {
                        handleSort(id);
                      }}
                    >
                      {content}

                      {sort.by === id && <span className={classes.tableVisuallyHidden}>{sort.dir}</span>}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell
                  {..._rest}
                  align={align}
                  sx={{ pointerEvents: 'none' }}
                  colSpan={content && index === 0 ? 2 : 0}
                >
                  {content}
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
      </TableRow>
    </TableHead>
  );

  const rowsRender = (
    <TableBody
      className={classes.tableBody}
      sx={{
        filter: loading ? 'blur(1.5px)' : '',
      }}
    >
      {rows?.map((row, index) => {
        const { content, cells, ..._rest } = row;
        const rowIndex = `$row-${index}`;
        return <Row className={classes.tableRow} key={rowIndex} content={content} cells={cells} {..._rest} />;
      })}
      {rows?.length === 0 && (
        <TableRow>
          <TableCell colSpan={columns.length} sx={{ border: 'none', height: 96 }}>
            <Typography>Nenhum dado encontrado</Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );

  const filtersRender = (
    <TableHead className={`${classes.tableHead} ${variant === 'primary' ? classes.tableHeadPrimary : ''}`}>
      <TableRow>
        {filters?.map((filter, index) => {
          const { content = '' } = filter;
          const filterIndex = `filter-${index}`;
          return (
            <TableCell key={filterIndex} colSpan={content && index === 0 ? 2 : 0}>
              {content}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer
      component={Paper}
      className={classes.tableContainer}
      sx={{
        minHeight: loading ? minHeight : 0,
        maxHeight: loading ? maxHeight : 0,
      }}
      {...rest}
    >
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
        aria-label="table"
        sx={{
          minHeight,
          maxHeight,
        }}
      >
        {columnsRender}
        {filtersRender}
        {rowsRender}
        {loading && (
          <TableBody
            className={classes.tableBody}
            sx={{
              bgcolor: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              width: '100%',
              userSelect: 'none',
              zIndex: 5,
              height: '100%',
            }}
          >
            <TableRow>
              <TableCell sx={{ border: 'none' }}>
                <Typography>Carregando...</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export { DataTable };
