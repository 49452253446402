import React, { ChangeEvent, useEffect, useState } from 'react';
import { Drawer, DrawerProps, Fab, Icon, Tooltip, Typography, Zoom, Box, Paper, Button, Badge } from '@mui/material';

import styles from './styles';
import InputSearchText from '../inputs/InputSearchText';
import InputSearchComboBox, { InputSearchComboBoxItems } from '../inputs/InputSearchComboBox';
import InputSearchDate from '../inputs/InputSearchDate';
import InputSearchAsync from '../inputs/InputSearchAsync';

interface FiltersProps extends DrawerProps {
  onFilterApply: (e: string[]) => void;
  initialInputsValue?: any[];
  // onFilterOpen?: (e: string[]) => void;
  // onFilterClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | ((e: any) => {});
  // onClearFilter?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | ((e: any) => {});
  buttonClearFilter?: boolean;
  highlightChanges?: boolean;
  inputs?: FiltersInputsProps[];
}
export interface FiltersInputsProps {
  type: 'text' | 'date' | 'combobox' | 'product' | 'order' | 'stage' | 'hcm-cc';
  id?: string | undefined;
  label?: string | undefined;
  value?: any;
  defaultValue?: any | undefined;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any | undefined;
  className?: string | undefined;
  comboBoxItems?: InputSearchComboBoxItems[] | undefined;
  highlight?: boolean;
  multiple?: boolean;
  noOptionsText?: string;
  placeholder?: string;
  dateType?: 'single' | 'start' | 'end';
  readOnly?: boolean;
  limit?: number;
  resultLimit?: number;
}

export default function Filters(props: FiltersProps) {
  const {
    children,
    onFilterApply,
    initialInputsValue = [],
    buttonClearFilter = true,
    highlightChanges = true,
    inputs = [],
  } = props;
  const classes = styles();
  const [showFilters, setShowFilters] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [inputsValue, setInputsValue] = useState<any[]>(initialInputsValue);

  const checkInputsValue = (resetDefault = false) => {
    let count = 0;
    const newInputsValue = [...inputsValue];
    for (let i = 0; i < inputs?.length; i++) {
      if (resetDefault && !newInputsValue[i]) {
        newInputsValue[i] = inputs[i].defaultValue?.toString() || '';
      }
      const currentValue = JSON.stringify(newInputsValue[i]);
      const currentValueObj = JSON.parse(currentValue);
      const defaultValue = JSON.stringify(inputs[i].defaultValue?.toString() || '');
      const defaultValueObj = JSON.parse(defaultValue);

      if (currentValue !== defaultValueObj && currentValueObj !== defaultValueObj) {
        count++;
        inputs[i].highlight = true;
      } else {
        inputs[i].highlight = false;
      }
    }
    setInputsValue(newInputsValue);
    setBadgeCount(count);
  };

  useEffect(() => {
    let active = true;
    if (active) {
      checkInputsValue(true);
    }
    return () => {
      active = false;
    };
  }, []);

  const handleOnChangeInput = (index: number, value: any) => {
    let count = 0;
    const newInputsValue = [...inputsValue];
    newInputsValue[index] = value;
    for (let i = 0; i < inputs?.length; i++) {
      const currentValue = JSON.stringify(newInputsValue[i]);
      const currentValueObj = JSON.parse(currentValue);
      const defaultValue = JSON.stringify(inputs[i].defaultValue?.toString() || '');
      const defaultValueObj = JSON.parse(defaultValue);

      if (currentValue !== defaultValueObj && currentValueObj !== defaultValueObj) {
        count++;
        inputs[i].highlight = true;
      } else {
        inputs[i].highlight = false;
      }
    }
    setInputsValue(newInputsValue);
    setBadgeCount(count);
  };

  const handleOpenFilter = () => {
    const newInputsValue = [...inputsValue];

    for (let i = 0; i < inputs?.length; i++) {
      if (!newInputsValue[i]) {
        newInputsValue[i] = inputs[i].defaultValue?.toString() || '';
      }
      try {
        // const valueObj = JSON.parse(JSON.stringify(newInputsValue?.[i].toString()));
        const valueObj = JSON.parse(newInputsValue?.[i].toString());
        if (valueObj && Array.isArray(valueObj)) {
          newInputsValue[i] = valueObj;
        }
      } catch (error: any) {
        // console.error(error);
      }
    }
    checkInputsValue();
    setInputsValue(newInputsValue);
    setShowFilters(true);
  };

  const handleApplyFilter = (e: any) => {
    setShowFilters(false);
    onFilterApply(inputsValue);
    for (let i = 0; i < inputs?.length; i++) {
      if (Array.isArray(inputsValue[i])) {
        localStorage.setItem(`search-${inputs[i].id}`, inputsValue[i] !== '' ? JSON.stringify(inputsValue[i]) : '');
      } else {
        localStorage.setItem(`search-${inputs[i].id}`, inputsValue[i]?.toString());
      }
    }
    checkInputsValue();
  };

  const resetInputsToDefaultValue = () => {
    const newInputsValue = [...inputsValue];
    for (let i = 0; i < inputs?.length; i++) {
      if (Array.isArray(newInputsValue[i])) {
        newInputsValue[i] = [];
      } else {
        newInputsValue[i] = inputs[i].defaultValue?.toString() || '';
      }
      inputs[i].highlight = false;
    }

    setInputsValue(newInputsValue);
    setBadgeCount(0);
  };

  const handleClearFilter = () => {
    resetInputsToDefaultValue();
  };

  return (
    <div className={classes.grow}>
      <Drawer
        anchor="right"
        open={showFilters}
        onClose={() => {
          setShowFilters(false);
        }}
        className={classes.root}
      >
        <Paper elevation={1} square className={classes.topBar}>
          <Box m={1} display="flex" alignItems="center">
            <Tooltip title="Fechar Filtro">
              <Button
                color="secondary"
                variant="outlined"
                onClick={e => {
                  setShowFilters(false);
                  // if (onFilterClose) onFilterClose(e);
                }}
              >
                FECHAR
              </Button>
            </Tooltip>
            <Box flex={1} display="flex" justifyContent="center" alignItems="center">
              <Typography>Filtros</Typography>
            </Box>
            <Tooltip title="Aplicar Filtro">
              <Button
                color="primary"
                variant="outlined"
                onClick={(e: any) => {
                  handleApplyFilter(e);
                }}
              >
                APLICAR
              </Button>
            </Tooltip>
          </Box>
        </Paper>
        <Box minHeight={52} height={52} maxHeight={52} />
        <Box pt={2} className={classes.drawer}>
          {buttonClearFilter && (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleClearFilter}
              disabled={badgeCount === 0}
              color="secondary"
            >
              <Icon style={{ fontSize: 16, marginRight: 8 }}>delete</Icon>Limpar Filtros
            </Button>
          )}
          {inputs?.map((input, index) => {
            const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              handleOnChangeInput(index, e.target.value);
            const key = `filter-input-${index}-${input.id}`;
            const limit = input.limit ? input.limit : Number.MAX_SAFE_INTEGER;
            if (input.type === 'text') {
              return (
                <InputSearchText
                  key={key}
                  id={input.id}
                  label={input.label}
                  value={inputsValue[index]}
                  onChange={onChange}
                  className={`${input.className} ${highlightChanges && input.highlight && classes.highlightInput}`}
                  readOnly={input.readOnly}
                />
              );
            }
            if (input.type === 'date') {
              return (
                <InputSearchDate
                  key={key}
                  id={input.id}
                  label={input.label}
                  value={inputsValue[index]}
                  onChange={onChange}
                  className={`${input.className} ${highlightChanges && input.highlight && classes.highlightInput}`}
                  type={input.dateType}
                  readOnly={input.readOnly}
                />
              );
            }
            if (input.type === 'combobox') {
              return (
                <InputSearchComboBox
                  key={key}
                  id={input.id}
                  label={input.label}
                  value={inputsValue[index]}
                  onChange={onChange}
                  className={`${input.className} ${highlightChanges && input.highlight && classes.highlightInput}`}
                  items={input.comboBoxItems}
                  readOnly={input.readOnly}
                />
              );
            }
            if (input.type === 'product') {
              return (
                <InputSearchAsync
                  fullWidth
                  key={key}
                  source="erp-products"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum produto encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Produtos'}
                  label={input.label || limit > 1 ? 'Produtos (+)' : 'Produto'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'stage') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="erp-op-stages"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum estágio encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Estágios'}
                  label={input.label || limit > 1 ? 'Estágios (+)' : 'Estágio'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'hcm-cc') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="hcm-cc"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum centro de custo encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Centro de Custos'}
                  label={input.label || limit > 1 ? 'Centro de Custos (+)' : 'Centro de Custo'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'order') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="erp-orders"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum pedido encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Pedidos'}
                  label={input.label || limit > 1 ? 'Pedidos (+)' : 'Pedido'}
                  resultLimit={input.resultLimit || 50}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'customers') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="erp-customers"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum cliente encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Clientes'}
                  label={input.label || limit > 1 ? 'Clientes (+)' : 'Cliente'}
                  resultLimit={input.resultLimit || 50}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'device') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="hcm-devices"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum dispositivo encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Despositivos'}
                  label={input.label || limit > 1 ? 'Dispositivos (+)' : 'Dispositivo'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'user') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="intranet-users"
                  searchParams={{ allUsers: true }}
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum usuário encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Usuário'}
                  label={input.label || (limit > 1 ? 'Usuários (+)' : 'Usuário')}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'helpdesk-target') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="helpdesk-target"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum setor encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Setor'}
                  label={input.label || limit > 1 ? 'Setores Atendimento (+)' : 'Setor Atendimento'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'erp-orders-situation') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="custom"
                  customList={[
                    { id: '1', description: 'Aberto Total', subdescription: '' },
                    { id: '2', description: 'Aberto Parcial', subdescription: '' },
                    { id: '3', description: 'Suspenso', subdescription: '' },
                    { id: '4', description: 'Liquidado', subdescription: '' },
                    { id: '5', description: 'Cancelado', subdescription: '' },
                    { id: '6', description: 'Aguardando Integração WMS', subdescription: '' },
                    { id: '7', description: 'Em Transmissão', subdescription: '' },
                    { id: '8', description: 'Preparação Análise ou NF', subdescription: '' },
                    { id: '9', description: 'Não Fechado', subdescription: '' },
                  ]}
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum situação encontrada.'}
                  placeholder={input.placeholder || 'Pesquisar Situações'}
                  label={input.label || limit > 1 ? 'Situações (+)' : 'Situação'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'erp-orders-type') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="custom"
                  customList={[
                    { id: '1', description: 'Normal', subdescription: '' },
                    { id: '2', description: 'Automático', subdescription: '' },
                    { id: '3', description: 'Rascunho', subdescription: '' },
                    { id: '4', description: 'Orçamento', subdescription: '' },
                    { id: '5', description: 'Previsão', subdescription: '' },
                    { id: '6', description: 'Prontra Entrega', subdescription: '' },
                    { id: '7', description: 'Aproveitamento Pedido Pronta Entrega', subdescription: '' },
                    { id: '8', description: 'Assistência Técnica', subdescription: '' },
                    { id: '9', description: 'Reposição', subdescription: '' },
                  ]}
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum tipo encontrada.'}
                  placeholder={input.placeholder || 'Pesquisar Tipo'}
                  label={input.label || limit > 1 ? 'Tipo (+)' : 'Tipo'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'erp-deposits') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="erp-deposits"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum depósito encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Depósitos'}
                  label={input.label || limit > 1 ? 'Depósitos (+)' : 'Depósito'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }
            if (input.type === 'erp-products-grouping') {
              return (
                <InputSearchAsync
                  key={key}
                  fullWidth
                  source="erp-products-grouping"
                  id={input.id}
                  limit={input.limit}
                  value={inputsValue[index]}
                  noOptionsText={input.noOptionsText || 'Nenhum agrupamento encontrado.'}
                  placeholder={input.placeholder || 'Pesquisar Agrupamentos'}
                  label={input.label || limit > 1 ? 'Agrupamentos (+)' : 'Agrupamento'}
                  onChange={e => {
                    handleOnChangeInput(index, e);
                  }}
                />
              );
            }

            return null;
          })}
          {children}
        </Box>
      </Drawer>
      <Zoom in unmountOnExit>
        <div role="presentation" className={classes.filterButton}>
          <Badge
            badgeContent={badgeCount}
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Fab
              onClick={e => {
                handleOpenFilter();
                // if (onFilterOpen) onFilterOpen(e);
              }}
              size="small"
              className={classes.filterButtonColor}
            >
              <Tooltip title="Filtros">
                <Icon>filter_list</Icon>
              </Tooltip>
            </Fab>
          </Badge>
        </div>
      </Zoom>
    </div>
  );
}
