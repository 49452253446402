import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { deepPurple, grey, orange, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    tableContainer: {
      width: '100%',
      height: 'calc(100vh - 316px)',
      overflow: 'auto',
    },
    percentValueText: {
      // color: darkMode ? grey[700] : grey[300],
      fontSize: 11,
    },
    percentText: {
      // color: darkMode ? grey[700] : grey[300],
      fontSize: 8,
    },
  });
});
