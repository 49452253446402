import React, { useEffect, useMemo, useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import api from '../../../../../services/api';
import { DashboardInfoCard } from '../../../../../components/DashboardInfoCard';

interface IPendingApprovalCard {}

function PendingApprovalCard() {
  const theme = useTheme();
  const [total, setTotal] = useState({ progress: 0, open: 0 });
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/progress');
      const { data: open } = await api.get<{ total: number }>('v2/helpdesk/dashboard/my/open');
      const newTotal = total;

      newTotal.progress = data.total;
      newTotal.open = open.total;

      setTotal(newTotal);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderCard = useMemo(() => {
    return (
      <DashboardInfoCard
        loading={loading}
        title=""
        content={
          <Typography variant="h3" fontWeight="400">
            {Intl.NumberFormat('pt-BR').format(total.progress)}
          </Typography>
        }
        onClick={() => {
          console.log('Pendente de aprovação');
        }}
        subcontent={
          <Typography variant="caption">
            {total.open > 1
              ? `Pendente de aprovação (${((total.progress / total.open) * 100).toFixed(0)}%)`
              : `Pendente de aprovação (${total.open}%)`}
          </Typography>
        }
        icon={<ErrorOutlineOutlinedIcon color="warning" sx={{ fontSize: 48 }} />}
        color={theme.palette.warning.main}
      />
    );
  }, [total.progress, total.open, theme.palette.warning.main]);

  return renderCard;
}

export { PendingApprovalCard };
