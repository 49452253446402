// eslint-disable-next-line import/no-duplicates
import { isValid, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import moment from 'moment';
import userUndefinedImg from '../images/user-undefined.jpg';
import api from '../services/api';

export function toNumber(value: any, defaultValue = 0) {
  let newValue = value;
  let result = 0;
  if (newValue === '') newValue = defaultValue;
  const valueSplit = newValue?.toString().split(':');
  if (valueSplit?.length === 2) {
    let h = Number(Number.isNaN(Number(valueSplit[0])) ? 0 : valueSplit[0]);
    const m = Number(Number.isNaN(Number(valueSplit[1])) ? 0 : valueSplit[1]);
    if (h > 0) h *= 60;

    return h + m;
  }
  result = Number(Number.isNaN(Number(newValue)) || newValue === null ? defaultValue : newValue);
  return result;
}

export function FormatNumber(number: number): string {
  let numberFormated = '';
  numberFormated = number
    .toFixed(3)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return numberFormated;
}

export function humanFileSize(bytes: number, si = false, dp = 1): string {
  let newBytes = bytes;
  const thresh = si ? 1000 : 1024;
  if (Math.abs(newBytes) < thresh) {
    return `${newBytes} B`;
  }
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    newBytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(newBytes) * r) / r >= thresh && u < toNumber(units?.length) - 1);
  return `${newBytes.toFixed(dp)} ${units[u]}`;
}
export const capitalizeFirstLetter = ([first, ...rest]: string[], locale = navigator.language): string =>
  first.toLocaleUpperCase(locale) + rest.join('');

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function minuteToHours(value: number) {
  const negative = value < 0;

  const hour = Math.ceil(Math.abs(value));
  const hours = Math.trunc(hour / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const minutes = Math.abs(hour % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${negative ? '-' : ''}${hours}:${minutes}`;
}

// export const isValidDate = (dateObject) => new Date(dateObject).toString() !== 'Invalid Date';

export async function urlExists(url) {
  const result = await fetch(url, { method: 'HEAD' });
  return result.ok;
}

interface toDateSettings {
  invalidDate?: Date;
}

export function toDate(value: string, settings?: toDateSettings) {
  const invalidDate = settings?.invalidDate ? settings.invalidDate : new Date();
  const isValidDate = new Date(value).toString() !== 'Invalid Date';
  const date = !isValidDate ? invalidDate.setHours(24, 0, 0, 0) : new Date(value).setHours(24, 0, 0, 0);
  return date;
}

export function avatarUrl(id: string | number, small = false) {
  const idUrl = toNumber(id);
  return idUrl > 0
    ? `${api.getUri()}/static/images/avatar/user-${idUrl}${small ? '-small' : ''}.jpg`
    : userUndefinedImg;
}
