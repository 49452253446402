import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Paper, Typography, Button, Icon, Box, Tooltip, TextField, IconButton, Backdrop, Fab } from '@mui/material';
import { Pagination } from '@mui/lab';
import { Send as SendIcon } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Axios, { Canceler } from 'axios';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './styles';
import notFoundStyles from '../../templates/not_found/styles';
import notFoundImg from '../../../images/not_found.svg';
import notFoundLightImg from '../../../images/not_found_light.svg';

import api from '../../../services/api';
import NewsCard from '../../../components/NewsCard';
import { CommentsData, NewsData, OrderProp, ResponseNewsData } from '../dashboard';
import Comment from '../../../components/Comment';

type PageProps = {
  darkMode?: boolean;
};

export interface ResponseNewsCommentsData {
  total: number | 0;
  page: number | 1;
  pageTotal: number | 1;
  limit: number;
  sortDir: OrderProp;
  sortBy: string;
  user: number;
  data: CommentsData[];
}

function NotFound({ darkMode }) {
  const notFoundClasses = notFoundStyles();
  return (
    <div className={notFoundClasses.root}>
      <img src={darkMode ? notFoundImg : notFoundLightImg} alt="404" className={notFoundClasses.img} />
      <div className={notFoundClasses.content}>
        <Typography variant="h6" className={notFoundClasses.text}>
          A notícia que procura não foi encontrada, para visualizar todas clique no botão abaixo.
        </Typography>
        <div className={notFoundClasses.button}>
          <Button variant="contained" color="primary" startIcon={<Icon>forum</Icon>} component={Link} to="/news">
            Notícias
          </Button>
        </div>
      </div>
    </div>
  );
}

function RenderComments({ newsCommentsData, getCommentsData, classes, handleDeleteComment, loadingCommentsData }) {
  const commentsData = newsCommentsData.data;
  if (!commentsData) return null;

  const handleNewsCommentsPagination = (event: any, page: number) => {
    if (page < 1 || page > newsCommentsData.pageTotal) return;
    getCommentsData({ page });
  };
  if (loadingCommentsData) {
    return (
      <Box>
        <Box className={classes.commentRoot}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando os comentários...
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box className={classes.commentContainer}>
      {commentsData?.length === 0 && (
        <Box className={classes.commentRoot}>
          <Typography style={{ textAlign: 'center', width: '100%' }}>
            Ainda não tem nenhum comentário nessa notícia, seja o primeiro!
          </Typography>
        </Box>
      )}
      {commentsData?.map((comment: CommentsData) => {
        return (
          <Comment
            key={comment.id}
            userId={comment.user_id}
            userName={comment.user_name}
            userSector={comment.user_sector}
            text={comment.text}
            date={comment.date}
            date_formated={comment.date_formated}
            onDelete={() => handleDeleteComment(comment.id)}
            my_message={comment.is_my}
            comment_id={comment.id}
          />
        );
      })}
      {commentsData?.length > 0 && (
        <Pagination
          style={{
            paddingTop: 24,
            margin: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          count={newsCommentsData.pageTotal}
          page={Number(newsCommentsData.page)}
          variant="outlined"
          shape="rounded"
          disabled={newsCommentsData.pageTotal <= 1}
          onChange={handleNewsCommentsPagination}
        />
      )}
    </Box>
  );
}

function RenderResult({
  newsData,
  classes,
  newsCommentsData,
  handleCategorySearch,
  renderGallery,
  renderPostComment,
  getCommentsData,
  handleDeleteComment,
  loadingCommentsData,
}) {
  const news = newsData.data[0] as NewsData;

  return (
    <Box className={classes.root} sx={{ width: '100%', gap: 2 }}>
      <Tooltip title="Próxima Notícia">
        <Fab
          color="warning"
          aria-label="next_news"
          size="small"
          sx={{
            position: 'fixed',
            bottom: 'calc((100vh / 2) - 52px)',
            left: 16,
          }}
          component={Link}
          to={`/news/${news.next_news}`}
          disabled={news.next_news === 0}
        >
          <KeyboardArrowLeftIcon />
        </Fab>
      </Tooltip>

      <NewsCard
        id={news.id}
        title={news.news_title}
        date={`${news.created_at}`}
        author={{ id: news.author_id, name: news.author_name, sector: news.author_sector }}
        content={news.news_content}
        likesCount={news.likes_data?.length}
        commentsCount={newsCommentsData.total}
        canLike={news.likes === 1}
        canComment={news.comments === 1}
        canEdit={news.edit === 1}
        category={news.category}
        categoryDescription={news.category_description}
        tags={news.tags}
        liked={news.liked}
        categoryHandleClick={handleCategorySearch}
        viewed={news.viewed}
        edit={news.edit}
        situation={news.situation}
      />

      {newsData?.data?.[0].gallery.length > 0 && renderGallery}
      {news.comments === 1 ? (
        !loadingCommentsData && (
          <Box className={classes.rootComments}>
            <Typography className={classes.commentTitle}>{`COMENTÁRIOS (${newsCommentsData.total})`}</Typography>
            <Paper style={{ width: '100%' }}>
              <RenderComments
                newsCommentsData={newsCommentsData}
                getCommentsData={getCommentsData}
                classes={classes}
                handleDeleteComment={handleDeleteComment}
                loadingCommentsData={loadingCommentsData}
              />
            </Paper>
            {renderPostComment}
          </Box>
        )
      ) : (
        <Box className={classes.rootComments}>
          <Paper style={{ width: '100%' }}>
            <Box className={classes.commentRoot}>
              <Typography style={{ textAlign: 'center', width: '100%' }}>
                Os comentários foram desativados para essa notícia.
              </Typography>
            </Box>
          </Paper>
        </Box>
      )}

      <Tooltip title="Notícia Anterior">
        <Fab
          color="warning"
          size="small"
          aria-label="preview_news"
          sx={{
            position: 'fixed',
            bottom: 'calc((100vh / 2) - 52px)',
            right: 16,
          }}
          component={Link}
          to={`/news/${news.preview_news}`}
          disabled={news.preview_news === 0}
        >
          <KeyboardArrowRightIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
}

export default function NewsViewPage(props: PageProps) {
  const { darkMode } = props;
  const { id } = useParams() as any;

  const classes = styles();

  const [newsData, setNewsData] = useState<ResponseNewsData>({} as ResponseNewsData);
  const [newsCommentsData, setCommentsNewsData] = useState<ResponseNewsCommentsData>({} as ResponseNewsCommentsData);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [postingView, setPostingView] = useState<boolean>(false);
  const [deletingComment, setDeletingComment] = useState<boolean>(false);
  const [loadingCommentsData, setCommentsLoadingData] = useState<boolean>(false);
  const [newComment, setNewComment] = useState('');
  const [postingComment, setPostingComment] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      getNewsData();
      postViewNews();
    }
    return () => {
      active = false;
    };
  }, [id]);

  const getCommentsData = ({ page = 1, situation = 1 }) => {
    let cancel: Canceler;
    if (!loadingCommentsData) {
      setCommentsLoadingData(true);

      api
        .get(`intranet/news/${id}/comment`, {
          params: { page, situation },
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setCommentsNewsData(response.data);
          setCommentsLoadingData(false);
        })
        .catch((error: any) => {
          console.error(error);
          setCommentsLoadingData(false);
        });
    }
    return () => cancel();
  };

  const getNewsData = () => {
    let cancel: Canceler;
    if (!loadingData) {
      const params = {
        id,
        situation: -1,
      };
      setLoadingData(true);

      api
        .get('intranet/news', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then((response: any) => {
          setNewsData(response.data);
          getCommentsData({});

          setLoadingData(false);
        })
        .catch((error: any) => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };

  const postViewNews = () => {
    if (!postingView) {
      setPostingView(true);

      api
        .post(`intranet/news/${id}/view`)
        .then((response: any) => {
          setPostingView(false);
        })
        .catch((error: any) => {
          setPostingView(false);
        });
    }
  };

  const handleCategorySearch = (categoryId: number) => {
    localStorage.setItem('search-news-category', String(categoryId));
  };

  function handleDeleteComment(comment_id: number) {
    if (!deletingComment) {
      setDeletingComment(true);

      api
        .delete(`intranet/news/${id}/comment`, {
          params: {
            comment_id,
          },
        })
        .then((response: any) => {
          const newData = newsCommentsData;
          newData.data = newsCommentsData?.data?.filter(data => data.id !== comment_id);

          setCommentsNewsData(newData);
          setDeletingComment(false);
        })
        .catch((error: any) => {
          setDeletingComment(false);
        });
    }
  }

  const handleSendMessage = (news_id: number) => {
    if (newComment?.length === 0) {
      return;
    }
    if (!postingComment) {
      const params = {
        comment: newComment,
      };
      setPostingComment(true);

      api
        .post(`intranet/news/${news_id}/comment`, params)
        .then((response: any) => {
          getCommentsData({ page: -1 });
          setNewComment('');

          setPostingComment(false);
        })
        .catch((error: any) => {
          console.error(error);
          setPostingComment(false);
        });
    }
  };

  const handleNewCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  const RenderPostComment = useMemo(() => {
    const news = newsData?.data?.[0] as NewsData;

    return (
      <Paper style={{ width: '100%', marginTop: 16 }}>
        <Box className={classes.newCommentRoot}>
          <TextField
            size="small"
            className={classes.newCommentInput}
            fullWidth
            id="new-comment-input"
            variant="outlined"
            placeholder="Digite o seu comentário aqui..."
            value={newComment}
            onChange={handleNewCommentChange}
          />

          {newComment?.length === 0 ? (
            <SendIcon fontSize="medium" className={classes.newCommentSendButtonDisabled} color="disabled" />
          ) : (
            <Tooltip title="Enviar">
              <IconButton
                aria-label="send"
                className={classes.newCommentSendButton}
                disabled={newComment?.length === 0}
                onClick={() => handleSendMessage(news.id)}
                size="large"
              >
                <SendIcon fontSize="medium" color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Paper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsCommentsData, newComment, postingComment]);

  const RenderGallery = useMemo(() => {
    const gallery = newsData?.data?.[0].gallery || [];
    return (
      <Paper
        sx={{
          width: '100%',
          maxWidth: 1080,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography>Galeria</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <ImageGallery
            items={gallery.map(img => {
              const imgUrl = `${api.getUri()}/static/uploads/news/${id}/gallery/${img}`;
              return {
                original: imgUrl,
                thumbnail: imgUrl,
              };
            })}
          />
        </Box>
      </Paper>
    );
  }, [id, newsData?.data]);

  return (
    <Box>
      {!loadingData ? (
        newsData.total > 0 ? (
          <RenderResult
            newsData={newsData}
            classes={classes}
            newsCommentsData={newsCommentsData}
            handleCategorySearch={handleCategorySearch}
            renderGallery={RenderGallery}
            renderPostComment={RenderPostComment}
            getCommentsData={getCommentsData}
            handleDeleteComment={handleDeleteComment}
            loadingCommentsData={loadingCommentsData}
          />
        ) : (
          <NotFound darkMode={darkMode} />
        )
      ) : (
        <Backdrop open style={{ zIndex: 10, color: '#fff', backgroundColor: '#00000000' }}>
          <Typography variant="caption" className={classes.loadingInitialDataText}>
            Carregando a notícia...
          </Typography>
        </Backdrop>
      )}
    </Box>
  );
}
NewsViewPage.defaultProps = {
  darkMode: false,
};
