import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Button,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  List,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import { Alert } from '@mui/lab';
import Axios, { Canceler } from 'axios';
import api from '../../../../services/api';
import styles from './styles';
import { RulesProps } from '../dashboard';

// moment(result.datent).format('DD/MM/YYYY')
export default function AdminUsersAddPage() {
  const classes = styles();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [waitingInsert, setWaitingInsert] = useState<boolean>(false);

  const userSituation = ['Indefinido', 'Trabalhando', '2', '3', '4', '5', '6', '7', '8', '9'];

  const [inputEmailValue, setInputEmailValue] = useState('');
  const [inputEmail2Value, setInputEmail2Value] = useState('');
  const [inputPasswordValue, setInputPasswordValue] = useState('');
  const [inputLoginValue, setInputLoginValue] = useState('');

  const [inputErpIDValue, setInputErpIDValue] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const [rulesToggleAllChecked, setRulesToggleAllChecked] = useState<boolean>(false);

  const [rulesChecked, setRulesChecked] = React.useState([] as { id: number; value: number }[]);

  const [responseRules, setResponseRules] = React.useState({
    total: '0',
    page: '0',
    pageTotal: '0',
    limit: '0',
    data: [] as RulesProps[],
  });

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleInputLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLoginValue(event.target.value);
  };
  const handleInputEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmailValue(event.target.value);
  };
  const handleInputEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail2Value(event.target.value);
  };
  const handleInputPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPasswordValue(event.target.value);
  };

  const handleInputErpIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputErpIDValue(event.target.value);
  };

  React.useEffect(() => {
    let active = true;
    if (active) {
      loadRules();
    }
    return () => {
      active = false;
    };
  }, []);

  const loadRules = () => {
    let cancel: Canceler;
    if (!loadingData) {
      const params = {
        id: '-1',
      };
      setLoadingData(true);

      api
        .get('intranet/users/rules', {
          params,
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
        })
        .then(response => {
          const rules = response.data;
          setResponseRules(rules);
          const newChecked = [...rulesChecked];

          for (let index = 0; index < rules.data?.length; index++) {
            const { id } = rules.data[index];
            if (id === 17 || id === 2 || id === 1) newChecked[id] = { id, value: 1 };
          }

          setRulesChecked(newChecked);

          setLoadingData(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingData(false);
        });
    }
    return () => cancel();
  };
  const handleToggleAll = () => () => {
    const newChecked = [...rulesChecked];

    for (let index = 0; index < responseRules.data?.length; index++) {
      const { id } = responseRules.data[index];
      newChecked[id] = { id, value: rulesToggleAllChecked ? 0 : 1 };
      // newChecked[index] = { id: , value: rulesToggleAllChecked ? 0 : 1 };
    }

    setRulesChecked(newChecked);
    setRulesToggleAllChecked(!rulesToggleAllChecked);
  };

  const handleToggle = (id: number) => () => {
    const newChecked = [...rulesChecked];

    newChecked[id] = newChecked[id] ? { id, value: newChecked[id].value === 1 ? 0 : 1 } : { id, value: 1 };
    if (newChecked[id].value === 0) {
      setRulesToggleAllChecked(false);
    }
    setRulesChecked(newChecked);
  };

  const handleInsertUser = () => {
    if (
      (inputEmailValue?.length > 0 && !validateEmail(inputEmailValue)) ||
      (inputEmail2Value?.length > 0 && !validateEmail(inputEmail2Value))
    ) {
      setSnackbarType('error');
      setSnackbarMsg('O email informado não é válido, verifique!');
      setOpenSnackbar(true);
      return;
    }
    if (inputPasswordValue?.length === 0) {
      setSnackbarType('error');
      setSnackbarMsg('A senha não pode estar vazia, verifique!');
      setOpenSnackbar(true);
      return;
    }
    if (inputPasswordValue?.length < 6) {
      setSnackbarType('error');
      setSnackbarMsg('A senha não pode ser menor do que 6 caracteres, verifique!');
      setOpenSnackbar(true);
      return;
    }
    const rules = [...rulesChecked].filter(function (el) {
      return el != null;
    });

    if (!waitingInsert) {
      setWaitingInsert(true);

      api
        .post('intranet/users/', {
          login: inputLoginValue,
          email: inputEmailValue,
          email2: inputEmail2Value,
          erp_id: inputErpIDValue,
          password: inputPasswordValue,
          rules,
        })
        .then(response => {
          setSnackbarType('success');
          setSnackbarMsg('Usuário cadastrado com sucesso!');
          setOpenSnackbar(true);
          setWaitingInsert(false);
          window.location.href = `/admin/users/${response.data.id}`;
        })
        .catch(error => {
          const { data } = error.response;
          if (data) {
            const errorMessage = data.message;
            if (errorMessage === 'empty_email') {
              setSnackbarType('error');
              setSnackbarMsg('É Obrigatório informar um email, verifique!');
              setOpenSnackbar(true);
            } else if (errorMessage === 'invalid_email') {
              setSnackbarType('error');
              setSnackbarMsg('O email informado não é válido, verifique!');
              setOpenSnackbar(true);
            } else if (errorMessage === 'duplicate_user') {
              setSnackbarType('error');
              setSnackbarMsg('Usuário já cadastrado, verifique!');
              setOpenSnackbar(true);
            } else if (errorMessage === 'employee_not_found') {
              setSnackbarType('error');
              setSnackbarMsg('Colaborador não encontrado, verifique o campo Usuário');
              setOpenSnackbar(true);
            } else {
              setSnackbarType('error');
              setSnackbarMsg(errorMessage);
              setOpenSnackbar(true);
            }
          }
          setWaitingInsert(false);
        });
    }
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ padding: 12 }}>
            <Box pb={3}>
              <Typography variant="caption" className={classes.sectionTitle}>
                INFORMAÇÕES DA CONTA
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  id="input-login"
                  label="Usuário"
                  variant="outlined"
                  type="text"
                  size="small"
                  value={inputLoginValue || ''}
                  onChange={handleInputLoginChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  autoComplete="new-erp-id"
                  id="input-erp-id"
                  label="Sapiens"
                  variant="outlined"
                  type="text"
                  size="small"
                  value={inputErpIDValue || ''}
                  onChange={handleInputErpIDChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  autoComplete="new-email"
                  id="input-email"
                  label="Email Empresa"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={inputEmailValue || ''}
                  onChange={handleInputEmailChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  autoComplete="new-email2"
                  id="input-email2"
                  label="Email Particular"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={inputEmail2Value || ''}
                  onChange={handleInputEmail2Change}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  id="input-password"
                  label="Senha"
                  variant="outlined"
                  type="password"
                  size="small"
                  value={inputPasswordValue || ''}
                  onChange={handleInputPasswordChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 12 }}>
            <Box pb={3}>
              <Typography variant="caption" className={classes.sectionTitle}>
                PERMISSÕES DO USUÁRIO
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ListItem role={undefined} dense button onClick={handleToggleAll()}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={rulesToggleAllChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'rules-toggle-all' }}
                    />
                  </ListItemIcon>
                  <ListItemText id="rules-toggle-all" primary="Selecionar todas" />
                </ListItem>

                <List className={classes.permissionList} dense={false}>
                  {responseRules.data?.map((rule: RulesProps, index) => {
                    const labelId = `checkbox-list-rules-${index}`;
                    const checked = rulesChecked[rule.id]?.value === 1;

                    return (
                      rule.value !== 'master' && (
                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(rule.id)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${rule.type === 1 ? 'Página - ' : ''}${rule.type === 0 ? 'Tipo - ' : ''}${
                              rule.description
                            }`}
                          />
                        </ListItem>
                      )
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.actionButtonContainer}>
          <Tooltip title="Inserir Usuário" aria-label="insert-user">
            <Button color="primary" variant="contained" onClick={handleInsertUser}>
              {waitingInsert && <CircularProgress size={24} />}
              INSERIR
            </Button>
          </Tooltip>
        </Grid>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert severity={snackbarType} onClose={handleSnackbarClose}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </Box>
  );
}
