import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputSearchAsync, { ValueProps } from '../../../../components/inputs/InputSearchAsync';

import styles from './styles';

import LiquidRender from './LiquidRender';
import LevelTable from './LevelTable';

export default function LevelPage() {
  const classes = styles();
  const initialDeposits: ValueProps[] = [{ id: '2-270', description: 'Peças Semi Acabadas', subdescription: '' }];
  const [inputProductValue, setInputProductValue] = useState<ValueProps[]>([]);
  const [inputDepositValue, setInputDepositValue] = useState<ValueProps[]>(initialDeposits);
  const [products, setProducts] = useState<ValueProps[]>(inputProductValue);
  const [deposits, setDeposits] = useState<ValueProps[]>(initialDeposits);
  const [filterExpanded, setFilterExpanded] = useState(false);

  function handleFilterClick() {
    setProducts(inputProductValue);
    setDeposits(inputDepositValue);
    setFilterExpanded(false);
  }
  const handleOnChangeDepositInput = (value: ValueProps[]) => {
    setInputDepositValue(value);
  };

  const handleOnChangeProductInput = (value: ValueProps[]) => {
    setInputProductValue(value);
  };

  return (
    <Box className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid item md={8} lg={9} style={{ overflow: 'auto' }}>
          <Box className={classes.filterContainer}>
            <Accordion expanded={filterExpanded} onChange={() => setFilterExpanded(!filterExpanded)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Filtros ({products.length + deposits.length})</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.inputContainer}>
                  <InputSearchAsync
                    fullWidth
                    source="erp-products"
                    id="erp-stock-level-product"
                    value={inputProductValue}
                    noOptionsText="Nenhum produto encontrado."
                    placeholder="Pesquisar Produtos"
                    label="Produtos (+)"
                    onChange={(e: ValueProps[]) => {
                      handleOnChangeProductInput(e);
                    }}
                  />
                  <InputSearchAsync
                    fullWidth
                    source="erp-deposits"
                    id="erp-stock-level-deposit"
                    value={inputDepositValue}
                    noOptionsText="Nenhum depósito encontrado."
                    placeholder="Pesquisar Depósitos"
                    label="Depósitos (+)"
                    onChange={(e: ValueProps[]) => {
                      handleOnChangeDepositInput(e);
                    }}
                  />
                </Box>
                <Box className={classes.filterButtonContainer}>
                  <Button variant="outlined" size="small" color="primary" onClick={handleFilterClick}>
                    <Typography className={classes.filterButtonText}>FILTRAR</Typography>
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <LevelTable products={products} deposits={deposits} onDataLoaded={data => {}} />
        </Grid>
        <Grid item md={4} lg={3} className={classes.liquidRoot}>
          <LiquidRender deposits={deposits} />
          <Typography className={classes.liquidTitle}>Nível de Estoque</Typography>
          <Box className={classes.liquidDepositContainer}>
            {deposits.map((deposit, index) => {
              return (
                <Typography key={index} className={classes.liquidDepositText}>
                  {deposit.id} - {deposit.description}
                </Typography>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
