import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import NewsIcon from '@mui/icons-material/Forum';
import { Carousel } from 'react-responsive-carousel';
import axios, { Canceler } from 'axios';
import { blue, grey } from '@mui/material/colors';
import newsPreviewErrorImg from '../../images/logo_b.svg';

import api from '../../services/api';

interface NewsData {
  id: number;
  author_id: number;
  author_name: string;
  author_sector: string;
  news_title: string;
  news_content: string;
  category: number;
  category_description: string;
  likes: number;
  comments: number;
  created_at: number;
  created_at_formated: string;
  created_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  updated_at: number;
  updated_at_formated: string;
  updated_at_split: {
    day: string;
    month: string;
    year: string;
    hours: string;
  };
  edit: number;
  comments_data: CommentsData[];
  likes_data: LikesData[];
  liked: boolean;
  tags: TagsData[];
  viewed: boolean;
  views_count: number;
  situation: number;
}
interface CommentsData {
  id: number;
  user_id: string;
  user_name: string;
  user_sector: string;
  text: string;
  date: string;
  date_formated: string;
  is_my: boolean;
}
interface LikesData {
  user_id: string;
  user_name: string;
  user_sector: string;
  date: string;
}
interface TagsData {
  tag: string;
}
interface ResponseNewsData {
  total: number | 0;
  page: number | 1;
  pageTotal: number | 1;
  limit: number;
  sortDir: string;
  sortBy: string;
  user: number;
  news_add: number;
  data: NewsData[];
}
function NewsCard() {
  const [responseData, setResponseData] = useState<ResponseNewsData>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [hover, setHover] = useState(false);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const params = {
          page: 1,
          limit: 5,
        };
        const { data } = await api.get<ResponseNewsData>('intranet/news', {
          params,
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setResponseData(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
        <NewsIcon sx={{ color: theme => (theme.palette.mode === 'dark' ? blue[200] : blue[700]) }} />
        <Typography>Últimas Notícias</Typography>
      </Box>
      {loadingData ? (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Skeleton variant="rectangular" sx={{ borderRadius: 1, mb: 0.25 }} height={90} width="100%" />
          <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} height={240} width="100%" />
        </Box>
      ) : (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              '.carousel': {
                borderRadius: 1,
                border: '1px solid rgb(200 200 200 / 60%)',
              },
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Carousel
              autoPlay
              autoFocus={false}
              infiniteLoop
              interval={4500}
              showThumbs={false}
              labels={{
                leftArrow: 'Anterior',
                rightArrow: 'Próximo',
                item: 'Item',
              }}
              showStatus={false}
              // statusFormatter={(currentItem, total) => `${currentItem} de ${total}`}
            >
              {responseData?.data?.map((news, index) => {
                const { id, news_title, created_at_split, views_count, likes, comments, viewed, liked } = news;

                const previewImg = `${api.getUri()}/static/uploads/news/news-preview-${id}.jpg`;
                return (
                  <Box key={id}>
                    <Box
                      sx={{
                        p: 2,
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        bgcolor: theme => (theme.palette.mode === 'dark' ? 'rgb(42 42 42)' : 'rgb(240 240 240)'),
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: theme => (theme.palette.mode === 'dark' ? blue[200] : blue[700]),
                        }}
                      >
                        <Typography sx={{ fontSize: '0.5rem', lineHeight: '0.1rem' }}>
                          {created_at_split.year}
                        </Typography>
                        <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                          {created_at_split.day}
                        </Typography>
                        <Typography sx={{ fontSize: '0.75rem', lineHeight: '0.3rem' }}>
                          {created_at_split.month}
                        </Typography>
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        <Box
                          component={Link}
                          to={`/news/${id}`}
                          sx={{
                            width: '100%',
                            textDecoration: 'none',
                            '& :hover': {
                              color: theme => (theme.palette.mode === 'dark' ? blue[200] : blue[700]),
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              textAlign: 'left',
                              color: theme =>
                                theme.palette.mode === 'dark' ? (viewed ? grey[500] : grey[200]) : grey[600],
                            }}
                          >
                            {news_title}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <img
                      alt={news_title}
                      src={previewImg}
                      style={{
                        display: 'block',
                        maxWidth: '100%',
                        height: '18rem',
                        objectFit: 'cover',
                        objectPosition: '50% 0%',
                      }}
                      onError={(e: any) => {
                        e.target.src = newsPreviewErrorImg;
                      }}
                    />
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          borderTop: '1px solid rgba(0,0,0,0.2)',
        }}
      >
        <Button size="small" variant="outlined" color="info" component={Link} to="/news">
          Ver todas
        </Button>
      </Box>
    </Paper>
  );
}
export { NewsCard };
