import React, { useEffect, useState } from 'react';

import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';

import LiquidFillGauge from 'react-liquid-gauge';
import Axios, { Canceler } from 'axios';

import styles from './styles';
import api from '../../../../../services/api';
import { ValueProps } from '../../../../../components/inputs/InputSearchAsync';

type LiquidRenderPorps = {
  deposits: ValueProps[];
  onDataLoaded?: (data: LevelStatisticsData) => void;
  darkMode?: boolean;
};
export type LevelStatisticsData = {
  filters: {
    deposits: string;
  };
  statistics: {
    max: number;
    avaliable: number;
    percent: number;
  };
};
export default function LiquidRender(props: LiquidRenderPorps) {
  const classes = styles();
  const { deposits = [], onDataLoaded = data => {}, darkMode = false } = props;

  const startColor = '#dc143c'; // crimson
  const endColor = '#6495ed'; // cornflowerblue
  const interpolate = interpolateRgb(startColor, endColor);

  const [loadingData, setLoadingData] = useState(false);
  const [percent, setPercent] = useState(0);

  const fillColor = interpolate(percent / 100);

  const gradientStops = [
    {
      key: '0%',
      stopColor: color(fillColor)?.brighter(0.5)?.toString(),
      stopOpacity: 1,
      offset: '0%',
    },
    {
      key: '50%',
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: '50%',
    },
    {
      key: '100%',
      stopColor: color(fillColor)?.darker(0.5)?.toString(),
      stopOpacity: 0.5,
      offset: '100%',
    },
  ];

  useEffect(() => {
    loadData({ deposits });
  }, [deposits]);

  async function loadData({ deposits = [] as ValueProps[] }) {
    if (loadingData) return;

    setLoadingData(true);

    let cancel: Canceler;

    let searchDeposits = '';
    for (const deposit of deposits) {
      searchDeposits += `;${deposit.id}`;
    }

    const params = {
      deposits: searchDeposits,
    };

    const response = await api.get('erp/stock/level/statistics', {
      params,
      cancelToken: new Axios.CancelToken(c => {
        cancel = c;
      }),
    });

    const data = response?.data as LevelStatisticsData;

    if (data) {
      setPercent(data.statistics.percent);
      onDataLoaded(data);
    }
    setLoadingData(false);
    return () => cancel();
  }

  return (
    <>
      {loadingData ? (
        <div className={classes.liquidLoading}>
          <div>Carregando...</div>
        </div>
      ) : (
        <LiquidFillGauge
          className={classes.liquidContainer}
          width={300}
          height={300}
          value={percent}
          percent="%"
          textSize={1}
          textOffsetX={0}
          textOffsetY={20}
          textRenderer={(props: any) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels,
            };
            const percentStyle = {
              fontSize: textPixels * 0.6,
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {value}
                </tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          gradientStops={gradientStops}
          circleStyle={{
            fill: fillColor,
          }}
          waveStyle={{
            fill: fillColor,
          }}
          textStyle={{
            fill: color(darkMode ? '#636363' : '#636363')?.toString(),
            fontFamily: 'Arial',
          }}
          waveTextStyle={{
            fill: color('#fff')?.toString(),
            fontFamily: 'Arial',
          }}
        />
      )}
    </>
  );
}
