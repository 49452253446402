/* eslint-disable no-empty */
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { FaFileExcel } from 'react-icons/fa';
import userUndefinedImg from '../../../../../../images/user-undefined.jpg';

import { ColumnType, DataTable, RowType } from '../../../../../../components/DataTable';
import { CircularProgressWithLabel } from '../../../../../../components/CircularProgressWithLabel';
import api from '../../../../../../services/api';
import ProfileTooltip from '../../../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../../../utils/functions';
import { ValueProps } from '../../../../../../components/inputs/InputSearchAsync';
import { engOsSituationDescription, EngOsSituationType } from '../..';
import { ProfileField } from '../../../../../../components/ProfileField';

type EngOsReportSchedulesType = {
  os_id: number;
  order_number: string;
  budget_number: number;
  product_id: string;

  task_id: number;
  task_description: string;
  started_at: string;
  started_at_formated: string;
  started_by: number;
  started_by_name: string;

  ended_at: string;
  ended_at_formated: string;
  ended_by: number;
  ended_by_name: string;

  time_spent: number;
  time_spent_formated: string;
};

type ResponseData = {
  total: number;
  limit: number;
  page_current: number;
  page_total: number;
  total_time_spent: number;
  total_time_spent_formated: string;
  result: EngOsReportSchedulesType[];
};

type EngOsReportSchedulesListFilterType = {
  os?: ValueProps[];
  tasks?: ValueProps[];
  budgets?: ValueProps[];
  products?: ValueProps[];

  orders?: ValueProps[];
  started_at_start?: string;
  started_at_end?: string;
  ended_at_start?: string;
  ended_at_end?: string;
};

const columnSize = (size: number | string) => ({ minWidth: size, width: size, maxWidth: size } as CSSProperties);

interface Props {
  filters?: EngOsReportSchedulesListFilterType;
}

function EngOsReportSchedulesList({ filters }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);
  const [sort, setSort] = useState({ by: 'order_number', dir: 'asc' });
  const [page, setPage] = useState(1);

  const getOsData = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>('erp/engineering/os/search/attendants', {
        params: {
          order_by: sort.by,
          order_dir: sort.dir,
          os: filters.os.map(f => f.id).join(';'),
          tasks: filters.tasks.map(f => f.id).join(';'),
          budgets: filters.budgets.map(f => f.id).join(';'),
          products: filters.products.map(f => f.id).join(';'),

          orders: filters.orders.map(f => f.id).join(';'),

          started_at_start: filters.started_at_start,
          started_at_end: filters.started_at_end,

          ended_at_start: filters.ended_at_start,
          ended_at_end: filters.ended_at_end,
          page,
        },
      });
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };

  const handleSort = (by: string, dir: string) => {
    setSort({ by, dir });
  };

  const handlePage = (event: any, current_page: number) => {
    if (current_page < 1 || current_page > responseData.page_total) return;
    setPage(current_page);
  };

  const columns: ColumnType[] = [
    { id: 'id', content: 'OS', style: { ...columnSize(96) }, align: 'center', orderable: true },
    { id: 'order_number', content: 'Pedido', style: { ...columnSize(96) }, align: 'center', orderable: true },
    { id: 'task_description', content: 'Tarefa', style: { minWidth: 256 }, align: 'left', orderable: true },
    { id: 'product_id', content: 'Entregável', style: { ...columnSize(96) }, align: 'center', orderable: true },
    {
      id: 'started_at',
      content: 'Iniciado em',
      style: { ...columnSize(160) },
      align: 'center',
      orderable: true,
    },
    { id: 'started_by', content: 'Iniciado por', style: { minWidth: 256 }, align: 'left', orderable: true },

    {
      id: 'ended_at',
      content: 'Finalizado em',
      style: { ...columnSize(160) },
      align: 'center',
      orderable: true,
    },
    { id: 'ended_by', content: 'Finalizado por', style: { minWidth: 256 }, align: 'left', orderable: true },
    {
      id: 'time_spent',
      content: 'Tempo gasto',
      style: { ...columnSize(160) },
      align: 'center',
      orderable: true,
    },
  ];

  const rows: RowType[] = responseData?.result?.map(os => {
    return {
      cells: [
        {
          content: (
            <Tooltip title="Visualizar O.S" disableInteractive arrow>
              <Button
                component={Link}
                to={`/erp/engineering/os/${os?.os_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {os.os_id}
              </Button>
            </Tooltip>
          ),
          align: 'center',
          style: { ...columnSize(64) },
        },

        {
          content:
            os?.order_number === '-' ? (
              '-'
            ) : (
              <Tooltip title="Visualizar Pedido" disableInteractive arrow>
                <Button
                  component={Link}
                  to={`/erp/orders/${os?.order_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {os?.order_number}
                </Button>
              </Tooltip>
            ),
          align: 'center',
        },
        { content: os.task_description, align: 'left' },
        { content: os.product_id, align: 'right' },

        { content: os.started_at_formated, align: 'center' },
        {
          content: <ProfileField userId={os.started_by} userName={os.started_by_name} />,
          align: 'left',
        },

        { content: os.ended_at_formated, align: 'center' },
        {
          content: os.ended_by === null ? '-' : <ProfileField userId={os.ended_by} userName={os.ended_by_name} />,
          align: 'left',
        },

        { content: os.time_spent_formated, align: 'center' },
      ],
    } as RowType;
  });

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [generating, setGenerating] = useState(false);

  function exportToCSV(csvData: any, fileName: string) {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  async function exportToExcel() {
    if (generating || fetching) return;
    setGenerating(true);
    try {
      const { data: response } = await api.get(`erp/engineering/os/search/attendants`, {
        params: {
          order_by: sort.by,
          order_dir: sort.dir,
          os: filters.os.map(f => f.id).join(';'),
          tasks: filters.tasks.map(f => f.id).join(';'),
          budgets: filters.budgets.map(f => f.id).join(';'),
          products: filters.products.map(f => f.id).join(';'),

          orders: filters.orders.map(f => f.id).join(';'),

          started_at_start: filters.started_at_start,
          started_at_end: filters.started_at_end,

          ended_at_start: filters.ended_at_start,
          ended_at_end: filters.ended_at_end,

          page: 1,
          limit: 0,
        },
      });

      exportToCSV(response.result, 'eng_os');
    } catch (error) {}
    setGenerating(false);
  }

  const renderTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        sx={{ width: '100%', overflow: 'auto' }}
        loading={fetching}
        onSort={handleSort}
        variant="primary"
        minHeight={96}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );
  const renderTotalTimespent = useMemo(
    () => (
      <Box
        display="flex"
        width="100%"
        sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}
      >
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          {`Tempo total: ${responseData?.total_time_spent_formated}`}
        </Typography>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, fetching],
  );
  useEffect(() => {
    getOsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filters]);

  const OsSearchPage = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Box>
          <Tooltip
            title={fetching ? '' : generating ? 'Gerando o arquivo, isso pode demorar' : 'Exportar os dados para excel'}
          >
            <Button
              sx={{ display: 'flex', gap: 1 }}
              variant="outlined"
              color="success"
              onClick={() => exportToExcel()}
              disabled={generating || fetching}
            >
              {generating ? (
                <>
                  <CircularProgress style={{ height: 14, width: 14, marginRight: 8 }} />
                  GERANDO
                </>
              ) : (
                <>
                  <FaFileExcel />
                  EXPORTAR
                </>
              )}
            </Button>
          </Tooltip>
        </Box>
        {renderTable}
        {renderTotalTimespent}
        <Box
          display="flex"
          width="100%"
          sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography variant="caption" style={{ marginBottom: 4 }}>
            {`${responseData?.total ?? 0}`}{' '}
            {responseData?.total > 1 ? 'Registros encontrados ' : 'Registro encontrado '}
          </Typography>

          <Pagination
            size="medium"
            count={responseData?.page_total ?? 0}
            page={responseData?.page_current ?? 0}
            variant="outlined"
            shape="rounded"
            disabled={fetching || responseData?.page_total === 1}
            onChange={handlePage}
          />
          <Typography
            variant="caption"
            sx={{
              fontSize: 9,
              marginTop: 1,
              color: isDarkMode ? grey[400] : grey[700],
            }}
          />
        </Box>
      </Box>
    ),

    [filters, fetching],
  );
  return <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>{OsSearchPage}</Box>;
}

export { EngOsReportSchedulesList };
export type { EngOsReportSchedulesListFilterType, EngOsSituationType };
