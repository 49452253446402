import React from 'react';
import { Box } from '@mui/material';

import UnderDevelopment from '../../../components/UnderDevelopment';

export default function HelpdeskReportsPage() {
  return (
    <Box>
      <UnderDevelopment />
    </Box>
  );
}
