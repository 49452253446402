import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Tooltip, TooltipProps, Typography, useTheme } from '@mui/material';
import userUndefinedImg from '../../images/user-undefined.jpg';
import styles from './styles';
import api from '../../services/api';
import { avatarUrl, toNumber } from '../../utils/functions';

interface Props extends Partial<TooltipProps> {
  userId: number | string;
  openNewTab?: boolean;
}
type ResponseData = {
  id: number;
  name: string;
  employee_position: string;
  sector: string;
  birthday_date: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  photo: string;
  my: true;
};
export default function ProfileTooltip(props: Props) {
  const { userId = 0, children, openNewTab = false, ...rest } = props;
  const id = toNumber(userId);
  const classes = styles();
  const theme = useTheme();

  const [fetching, setFetching] = useState(false);
  const [responseData, setResponseData] = useState<ResponseData>(null);

  const getUserInfo = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const { data } = await api.get<ResponseData>(`/intranet/users/${id}/profile`);
      data.photo = avatarUrl(id);
      setResponseData(data);
    } catch (error) {}
    setFetching(false);
  };

  useEffect(() => {
    if (id > 0) getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (id === 0) return <>{children}</>;

  return (
    <Tooltip
      {...rest}
      enterTouchDelay={0}
      className={classes.root}
      title={
        fetching ? null : (
          <Box className={classes.userContainer}>
            <Box
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                textDecoration: 'none',
              }}
              component={Link}
              to={`/profile/${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes.userImg}
                src={responseData?.photo ?? avatarUrl(id)}
                alt={responseData?.name ?? ''}
                onError={(e: any) => {
                  e.target.src = avatarUrl(0);
                }}
              />
            </Box>
            <Typography className={classes.userName}>{responseData?.name ?? ''}</Typography>
            <Typography className={classes.userSector}>{responseData?.sector ?? ''}</Typography>
            {(responseData?.phone1 || responseData?.phone2 || responseData?.email) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderTop: `1px solid ${theme.palette.divider}`,
                  mt: 1,
                  pb: 1,
                  pt: 1,
                  width: '100%',
                }}
              >
                {responseData?.phone1 && <Typography variant="caption">{`Ramal: ${responseData?.phone1}`}</Typography>}
                {responseData?.phone2 && (
                  <Typography variant="caption">{`Celular: ${responseData?.phone2}`}</Typography>
                )}
                {responseData?.email && <Typography variant="caption">{`${responseData?.email}`}</Typography>}
              </Box>
            )}
          </Box>
        )
      }
    >
      {openNewTab ? (
        <Box
          style={{ ...children.props.style }}
          sx={{
            ...children.props.sx,
            ':hover': {
              cursor: 'pointer',
            },
            textDecoration: 'none',
          }}
          component={Link}
          to={`/profile/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </Tooltip>
  );
}
