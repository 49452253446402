import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ClearIcon from '@mui/icons-material/Clear';

import { addBusinessDays, format } from 'date-fns';
import InputSearchAsync, { ValueProps } from '../../../../../../components/inputs/InputSearchAsync';
import { toDate, toNumber } from '../../../../../../utils/functions';
import api from '../../../../../../services/api';
import { InputEngOsTask, InputTaskType } from '../../../../../../components/inputs/InputEngOsTask';
import { TaskType } from '../../../../../../components/EngOsTasksSelectForm';

const returnMessages = {
  description_duplicate: 'Já existe um cadastro com essa descrição',
  invalid_days: 'Quantidade de dias inválido',
  invalid_description: 'Descrição inválida',
  successfully_created: 'Criado com sucesso.',
};

interface EnOsNewTaskFormProps {
  open: boolean;
  parent?: TaskType;
  handleClose: () => void;
  handleOpen?: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmited?: (success: boolean, msg: string) => void;
}
type FormStateType = {
  description: string;
  parent_task: string;
  parent_task_description: string;
  days: number;
};

function EnOsNewTaskForm({ handleClose, handleOpen, open, onSubmited, parent }: EnOsNewTaskFormProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [errorState, setErrorState] = useState({
    parent_task_error: '',
    description_error: '',
    days_error: '',
  });

  const [formState, setFormState] = useState({
    parent_task: '',
    parent_task_description: '',
    description: '',
    days: 0,
  } as FormStateType);

  const [posting, setPosting] = useState<boolean>(false);

  const resetFormState = () => {
    setErrorState({
      parent_task_error: '',
      description_error: '',
      days_error: '',
    });

    setFormState({
      parent_task: '',
      parent_task_description: '',
      description: '',
      days: 0,
    });
  };

  function handleValideData() {
    const parent_task_error = '';
    let description_error = '';
    const days_error = '';

    if (!formState.description || formState.description.length === 0) {
      description_error = 'É necessário informar uma descrição';
    }

    setErrorState({
      parent_task_error,
      description_error,
      days_error,
    });
    if (parent_task_error.length > 0 || description_error.length > 0 || days_error.length > 0) return false;

    return true;
  }

  const handleSubmit = async () => {
    if (posting) return;
    setPosting(true);

    if (handleValideData()) {
      const data = {
        description: formState.description,
        parent_task: formState.parent_task,
        days: formState.days.toString(),
      };

      try {
        const { data: os } = await api.put('erp/engineering/os/tasks/add', data);
        if (os?.message === 'successfully_created') {
          onSubmited?.(true, returnMessages[os?.message]);
          resetFormState();
        }
      } catch (error) {
        const { data: response } = error.response;
        onSubmited?.(false, returnMessages[response.message]);
      }
      setPosting(false);
    }
  };

  const handleOnCloseDialog = () => {
    if (posting) return;
    handleClose?.();
  };

  const handleClickCancelDialog = () => {
    if (posting) return;
    handleClose?.();
    resetFormState();
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    let { value } = e.target;
    if (id === 'days') {
      const newValue = toNumber(value);
      if (newValue < 0) value = '0';
    }
    setFormState(old => ({ ...old, [id]: value }));
  };

  useEffect(() => {
    if (open) {
      resetFormState();

      handleOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const inputDescription = useMemo(
    () => (
      <TextField
        fullWidth
        label="Descrição"
        id="description"
        type="text"
        onChange={handleFieldChange}
        value={formState.description}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        error={errorState.description_error?.length > 0}
        helperText={errorState.description_error}
      />
    ),
    [formState.description, errorState.description_error],
  );

  const inputTask = useMemo(
    () => (
      <InputEngOsTask
        initialValue={parent}
        onSelect={(_task: any) => {
          setFormState(old => ({
            ...old,
            parent_task: _task.id.toString(),
            parent_task_description: _task.description,
          }));
        }}
      />
    ),
    [parent, formState.parent_task],
  );
  const inputDays = useMemo(
    () => (
      <TextField
        fullWidth
        label="Prazo (dias)"
        id="days"
        type="number"
        onChange={handleFieldChange}
        value={formState.days}
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
        sx={{
          maxWidth: 180,
        }}
        error={errorState.days_error?.length > 0}
        helperText={errorState.days_error}
      />
    ),
    [formState.days, errorState.days_error],
  );

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth={true} maxWidth="md" disableEscapeKeyDown>
      <DialogTitle>Nova Tarefa</DialogTitle>
      <DialogContent
        sx={{
          bgcolor: isDarkMode ? '#4a4a4a' : '#ffffff',
          m: 1,
          p: 0,
          borderRadius: 1,
          borderColor: isDarkMode ? '#3a3a3a' : '#d3d3d3',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
            p: 2,
          }}
        >
          {inputDescription}
          {inputTask}
          {inputDays}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClickCancelDialog} disabled={posting}>
          Cancelar
        </Button>
        <Button color="success" variant="contained" onClick={handleSubmit} disabled={posting}>
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { EnOsNewTaskForm };
