import React from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { RequestsList } from './RequestsList';
import { DashboardInfoCard } from '../../../../../components/DashboardInfoCard';
import { EndendCard } from './EndendCard';
import { CanceledCard } from './CanceledCard';
import { OpenedCard } from './OpenedCard';
import { PendingApprovalCard } from './PendingApprovalCard';

interface IRequests {
  filter?: '';
}

export function Requests({ filter }: IRequests) {
  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <OpenedCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <PendingApprovalCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <CanceledCard />
        </Grid2>
        <Grid2 xs={12} sm={12} md={6} lg={3}>
          <EndendCard />
        </Grid2>

        <Grid2 xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Typography>Minhas últimas requisições</Typography>
            <Paper sx={{ display: 'flex', gap: 2, p: 2, flex: 1, flexDirection: 'column' }}>
              <RequestsList pending />
            </Paper>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}
