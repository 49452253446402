import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableSortLabel,
  Tooltip,
  Snackbar,
  Alert,
  Pagination,
  Skeleton,
  InputBase,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';

import Axios, { Canceler } from 'axios';

import api from '../../../services/api';
import styles from './styles';

type getContactsType = {
  search?: string;
  page?: number;
  sort?: string[];
  limit?: number;
};

type ContactType = {
  id: string;
  name: string;
  employee_position: string;
  sector: string;
  birthday_date: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  pc_name: string;
  logged_at: string;
  situation: string;
  my: string;
};
type ResponseType = {
  page_current: string;
  page_total: string;
  result_limit: string;
  result_total: string;
  result: ContactType[];
};
interface HeaderColumnProps {
  id: number;
  colSpan: number;
  aling: AlingProps;
  sort: boolean;
  fieldName: string;
  text: string;
  tooltipTitle: string;
  width?: string | '100%';
}
interface RowColumnProps {
  id: number;
  colSpan: number;
  aling: AlingProps;
  text: any;
  width?: string | '100%';
}

type OrderProp = 'asc' | 'desc';
type AlingProps = 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;

export default function AdminContactsPage() {
  const classes = styles();
  const [loadingData, setLoadingData] = useState(false);
  const [contacts, setContacts] = useState<ResponseType>();
  const [result, setResult] = useState<ContactType[]>([]);

  const refInputSearch = useRef<HTMLInputElement>(null);
  const [textSearch, setTextSearch] = useState('');
  const [phone2Values, setPhone2Values] = useState<string[]>([]);

  const limit = 50;
  const [orderDir, setOrderDir] = useState<OrderProp>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');
  const [updating, setUpdating] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const columns = [
    {
      id: 1,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'id',
      text: 'ID',
      tooltipTitle: 'Ordernar por Login',
      width: 16,
    },
    {
      id: 2,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'name',
      text: 'Nome',
      tooltipTitle: 'Ordernar por Nome',
    },
    {
      id: 3,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'ep',
      text: 'Setor',
      tooltipTitle: 'Ordernar por Setor',
    },
    {
      id: 4,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'email1',
      text: 'Email',
      tooltipTitle: 'Ordernar por Email',
      width: 64,
    },
    {
      id: 5,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'phone1',
      text: 'Ramal',
      tooltipTitle: 'Ordernar por Ramal',
    },
    {
      id: 6,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'phone2',
      text: 'Celular',
      tooltipTitle: 'Ordernar por Celular',
    },
    {
      id: 7,
      colSpan: 1,
      aling: 'left',
      sort: true,
      fieldName: 'pc_name',
      text: 'PC',
      tooltipTitle: 'Ordernar por PC',
    },
    {
      id: 8,
      colSpan: 1,
      aling: 'center',
      sort: false,
      fieldName: '',
      text: '',
      tooltipTitle: '',
    },
  ] as HeaderColumnProps[];

  const rowColumn = [
    { id: 1, aling: 'center', text: '', colSpan: 1 },
    { id: 2, aling: 'center', text: '', colSpan: 1 },
    { id: 3, aling: 'left', text: '', colSpan: 1 },
    { id: 4, aling: 'left', text: '', colSpan: 1 },
    { id: 5, aling: 'left', text: '', colSpan: 1 },
    { id: 6, aling: 'center', text: '', colSpan: 1 },
    { id: 7, aling: 'center', text: '', colSpan: 1 },
    { id: 9, aling: 'center', text: '', colSpan: 1 },
  ] as RowColumnProps[];

  const getContacts = async (props?: getContactsType) => {
    const { search = '', page = 1 } = props || {};
    let cancel: Canceler;
    if (loadingData) return () => cancel();
    setLoadingData(true);

    try {
      const { data } = await api.get<ResponseType>(`intranet/users/profiles`, {
        params: {
          page,
          limit: 15,
          search,
        },
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      setContacts(data);
      setResult(data?.result);
      setLoadingData(false);
    } catch (error) {
      console.error(error);
      const data = error?.response?.data;
      let msgError = '';
      if (data?.message?.length > 0) {
        msgError = '';
      } else {
        msgError = 'Erro desconhecido, favor contate o departamento de TI';
      }
      setSnackbarType('error');
      setSnackbarMsg(msgError);
      setOpenSnackbar(true);
      setLoadingData(false);
    }
    return () => cancel();
  };

  useEffect(
    () => {
      const delay = setTimeout(() => {
        getContacts({ search: textSearch });
        // Send Axios request here
      }, 400);

      return () => clearTimeout(delay);
    },
    [textSearch], // Only call effect if debounced search term changes
  );

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const search = e?.currentTarget?.value;
    setTextSearch(search);
  }
  const handlePagination = (event: any, page: number) => {
    if (page < 1 || page > parseInt(contacts.page_total, 10)) return;
    getContacts({ search: textSearch, page });
  };
  function handleClearSearch() {
    setTextSearch('');
    getContacts();
    if (refInputSearch.current) {
      const input = refInputSearch.current.children[0] as HTMLInputElement;
      input.value = '';
    }
  }
  const handleSort = (index: string) => {
    const isAsc = orderBy === index && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
    getContacts({ page: parseInt(contacts?.page_current, 10), sort: [index, isAsc ? 'desc' : 'asc'] });
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  async function HandleUpdateProfile({ id = '', email1 = '', phone1 = '', phone2 = '', pc_name = '' }) {
    let cancel: Canceler;
    if (updating) return;
    setUpdating(true);
    try {
      const response = await api.post('intranet/users/profile', {
        id,
        email1,
        phone1,
        phone2,
        pc_name,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });

      setSnackbarType('success');
      setSnackbarMsg('Informações atualizadas com sucesso');
      setOpenSnackbar(true);
      setUpdating(false);
      getContacts({ search: textSearch });
    } catch (error) {
      console.error(error);
      const { data } = error?.response;
      setSnackbarType('error');
      let msgError = '';
      if (data?.message?.length > 0) {
        msgError = '';
      } else {
        msgError = 'Erro desconhecido, favor contate o departamento de TI';
      }
      setSnackbarMsg(msgError);
      setOpenSnackbar(true);
      setUpdating(false);
    }
    return () => cancel();
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const newResult = [...result];

    const { value, name } = e?.target;
    let inputValue = value;
    if (name === 'phone2') {
      inputValue = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)$/, '$1');
    }
    newResult[index][name] = inputValue;
    setResult(newResult);
  };

  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.searchContainer}>
          <Paper className={classes.inputRoot}>
            <Box className={classes.iconButton}>
              <SearchIcon />
            </Box>
            <InputBase
              ref={refInputSearch}
              className={classes.input}
              placeholder="Pesquisar"
              inputProps={{ 'aria-label': 'pesquisar' }}
              onChange={handleSearchTextChange}
            />
            <IconButton
              className={classes.iconButton}
              onClick={handleClearSearch}
              disabled={textSearch.length === 0}
              color="secondary"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        </Box>
        <Grid container className={classes.contentRoot}>
          <Grid item xs={12}>
            <Box>
              <Paper className={classes.tablePaper}>
                <Box p={1}>
                  <TableContainer className={classes.tableContainer}>
                    <Table
                      stickyHeader
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="small"
                      aria-label="table"
                    >
                      <TableHead>
                        <TableRow>
                          {columns?.map((column: HeaderColumnProps) => {
                            return (
                              <TableCell
                                key={column.id}
                                width={column.width}
                                colSpan={column.colSpan}
                                align={column.aling}
                                sortDirection={orderBy === column.fieldName ? orderDir : false}
                              >
                                <Tooltip title={column.tooltipTitle} aria-label={`orderby-${column.fieldName}`}>
                                  {column.sort ? (
                                    <TableSortLabel
                                      active={orderBy === column.fieldName}
                                      direction={orderBy === column.fieldName ? orderDir : 'asc'}
                                      onClick={() => {
                                        handleSort(column.fieldName);
                                      }}
                                    >
                                      {column.text}
                                      {orderBy === column.fieldName ? (
                                        <span className={classes.tableVisuallyHidden}>
                                          {orderDir === 'desc' ? 'desc' : 'asc'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                  ) : (
                                    <Typography>{column.text}</Typography>
                                  )}
                                </Tooltip>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result?.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={rowColumn?.length} align="center">
                              <Typography
                                variant="subtitle1"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                Não foi encontrado nenhum Resultado.{' '}
                                <Icon style={{ marginLeft: '8px' }}>sentiment_very_dissatisfied</Icon>
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                Verifique o<Icon className={classes.iconFilterResult}>filter_list</Icon>filtro e tente
                                novamente.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          result?.map((contact: ContactType, index) => {
                            const rowId = `row-${contact?.id}`;

                            return (
                              <TableRow hover tabIndex={-1} key={rowId}>
                                <TableCell width={12} align="left">
                                  {loadingData ? <Skeleton variant="text" /> : contact?.id || ''}
                                </TableCell>

                                <TableCell width={24} align="left">
                                  {loadingData ? <Skeleton variant="text" /> : contact?.name || ''}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  {loadingData ? <Skeleton variant="text" /> : contact?.sector || ''}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  {loadingData ? (
                                    <Skeleton variant="text" height={40} />
                                  ) : (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      value={contact?.email || ''}
                                      fullWidth
                                      name="email"
                                      onChange={e => handleInputChange(e, index)}
                                    />
                                  )}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  {loadingData ? (
                                    <Skeleton variant="text" />
                                  ) : (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      defaultValue={contact?.phone1 || ''}
                                      fullWidth
                                      name="phone1"
                                      onChange={e => handleInputChange(e, index)}
                                    />
                                  )}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  {loadingData ? (
                                    <Skeleton variant="text" />
                                  ) : (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      defaultValue={contact?.phone2 || ''}
                                      fullWidth
                                      name="phone2"
                                      onChange={e => handleInputChange(e, index)}
                                    />
                                  )}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  {loadingData ? (
                                    <Skeleton variant="text" />
                                  ) : (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      defaultValue={contact?.pc_name || ''}
                                      fullWidth
                                      name="pc_name"
                                      onChange={e => handleInputChange(e, index)}
                                    />
                                  )}
                                </TableCell>
                                <TableCell width={24} align="left">
                                  <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={() =>
                                      HandleUpdateProfile({
                                        id: contact?.id,
                                        email1: contact?.email,
                                        phone1: contact?.phone1,
                                        phone2: contact?.phone2,
                                        pc_name: contact?.pc_name,
                                      })
                                    }
                                  >
                                    Salvar
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Paper>

              <div className={classes.actions}>
                <Typography variant="caption" style={{ marginBottom: 4 }}>{`${
                  contacts?.result_total || 0
                } Registros encontrados`}</Typography>

                <Pagination
                  size="medium"
                  count={parseInt(contacts?.page_total, 10) || 1}
                  page={parseInt(contacts?.page_current, 10) || 1}
                  variant="outlined"
                  shape="rounded"
                  disabled={(parseInt(contacts?.page_total, 10) || 1) <= 1}
                  onChange={handlePagination}
                />
              </div>
            </Box>
          </Grid>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarType} onClose={handleSnackbarClose}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
    </Box>
  );
}
