import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Paper,
  Typography,
  IconButton,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import api from '../../../services/api';
import { toNumber } from '../../../utils/functions';
import { ProfileField } from '../../../components/ProfileField';
import ProfileTooltip from '../../../components/ProfileTooltip';

interface Props {
  month: number;
  birthdaysList: BirthdaysType[];
}

export type BirthdaysType = {
  id: string;
  name: string;
  date: string;
  date_formated: string;
  date_day: string;
  date_month_number: string;
  date_month_name: string;
  sector: string;
};

export default function MonthBirthdayCalendar({ month, birthdaysList = [] }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const today = moment();
  const calendarYear = today.year();
  const calendarDay = today.date();
  const calendarDate = moment(`${calendarYear}-${month}-1 00:00:00`);

  const monthName = calendarDate.format('MMMM');

  const isThisMonth = calendarDate.format('yyyy-M') === today.format('yyyy-M');

  const weekdaysShort = moment.weekdaysShort();
  const firstDay = toNumber(moment(calendarDate).startOf('month').format('d'));
  const totalDaysInMonth = moment(calendarDate).daysInMonth();

  const monthDays = [];

  for (let i = 0; i < firstDay; i++) {
    monthDays.push('');
  }
  for (let d = 1; d <= totalDaysInMonth; d++) {
    monthDays.push(d);
  }

  const rows = [];
  let cells = [];

  monthDays.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row); // if index not equal 7 that means not go to next week
    } else {
      rows.push(cells); // when reach next week we contain all td in last week to rows
      cells = []; // empty container
      cells.push(row); // in current loop we still push current row to new container
    }
    if (i === monthDays.length - 1) {
      // when end loop we add remain date
      rows.push(cells);
    }
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
        {monthName.charAt(0).toUpperCase() + monthName.slice(1)}
      </Box>
      <TableContainer component={Paper} sx={{ p: 1, height: '100%', width: '100%' }}>
        <Table aria-label="calendar">
          <TableHead>
            <TableRow>
              {weekdaysShort.map((day, index) => {
                return (
                  <TableCell
                    key={day}
                    align="center"
                    sx={{
                      color:
                        index === 0
                          ? theme.palette.error.light
                          : index === 6
                          ? theme.palette.info.light
                          : theme.palette.text.secondary,
                    }}
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {rows.map((row, ri) => {
              const rKey = `row-${ri}`;
              return (
                <TableRow key={rKey}>
                  {row.map((day, di) => {
                    const dKey = `row-${di}`;

                    const isToday = isThisMonth && day === calendarDay;
                    const newBirthdaysList = birthdaysList.filter(f => toNumber(f.date_day) === toNumber(day));

                    return (
                      <Tooltip title={isToday ? 'Hoje' : ''} key={dKey}>
                        <TableCell
                          align="center"
                          sx={{
                            p: 1,
                            flex: 1,
                            bgcolor: day !== '' ? (isDarkMode ? 'rgb(0 0 0 / 10%)' : 'rgb(0 0 0 / 2%)') : '',
                            borderLeft: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            ':first-of-type': {
                              borderLeft: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            },

                            ':last-child': {
                              borderRight: day !== '' ? `1px solid ${theme.palette.divider}` : '',
                            },
                            outline: isToday ? `4px solid ${theme.palette.info.light}` : '',
                            outlineOffset: '-4.5px',

                            color:
                              di === 0
                                ? theme.palette.error.main
                                : di === 6
                                ? theme.palette.info.main
                                : theme.palette.text.primary,
                          }}
                        >
                          {day !== '' && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                height: '100%',
                                justifyContent: 'center',
                                alignContent: 'flex-start',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',

                                  flex: 1,
                                  height: '100%',
                                  justifyContent: 'center',
                                  alignContent: 'flex-start',
                                }}
                              >
                                <Typography sx={{ fontWeight: isToday ? 'bold' : 'normal' }}>{day}</Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  flex: 1,
                                  minHeight: 68,

                                  height: '100%',
                                  justifyContent: 'center',
                                  gap: 0.25,
                                }}
                              >
                                {newBirthdaysList.map((birthday, bi) => {
                                  const key = `birthday-${birthday.id}`;

                                  return <ProfileField key={key} userId={birthday.id} userName="" size={32} />;
                                })}
                              </Box>
                            </Box>
                          )}
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
