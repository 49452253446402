import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button, Skeleton } from '@mui/material';
import axios, { Canceler } from 'axios';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import api from '../../services/api';

type VacationType = {
  id: string;
  type: string;
  number: string;
  nickname: string;
  name: string;
  title: string;
  sector: string;
  vacation_days: string;
  date_now: string;
  vacation_date_start: string;
  days_remaining: number;
  vacation_date_end: string;
};
function VacationCard() {
  const [responseData, setResponseData] = useState<VacationType[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const getData = async () => {
    let cancel: Canceler;
    if (!loadingData) {
      setLoadingData(true);
      try {
        const { data } = await api.get<VacationType[]>('hcm/vacations', {
          cancelToken: new axios.CancelToken(c => {
            cancel = c;
          }),
        });
        setResponseData(data);
        setLoadingData(false);
      } catch (error: any) {
        const data = error?.response?.data;
        if (data) {
          let errorMessage = data?.message;
          if (!errorMessage) errorMessage = data?.error;
          if (errorMessage === '') errorMessage = '';
          else if (errorMessage === '') errorMessage = '';
          else errorMessage = '';
        }
        setLoadingData(false);
      }
    }
    return () => cancel();
  };
  useEffect(() => {
    getData();
  }, []);

  const Row = (props: { first?: boolean; dateStart: string; dateEnd: string; days: string }) => {
    const { first, dateStart, dateEnd, days } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          gap: 3,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '0.6rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            Data início
          </Typography>

          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: 'bold',
              lineHeight: '1.3rem',
              color: theme => (theme.palette.mode === 'light' ? orange[800] : orange[300]),
            }}
          >
            {dateStart}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '0.6rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            Data fim
          </Typography>

          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: 'bold',
              lineHeight: '1.3rem',
              color: theme => (theme.palette.mode === 'light' ? orange[800] : orange[300]),
            }}
          >
            {dateEnd}
          </Typography>
        </Box>
        <Box minWidth={64}>
          <Typography
            sx={{
              fontSize: '0.6rem',
              color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
            }}
          >
            Total dias
          </Typography>
          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: 'bold',
              lineHeight: '1.3rem',
              color: theme => (theme.palette.mode === 'dark' ? blue[300] : blue[700]),
            }}
          >
            {days}
          </Typography>
        </Box>
      </Box>
    );
  };

  const FakeRow = (props: { first?: boolean }) => {
    const { first } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          p: 1.5,
          borderTop: theme =>
            first ? '' : `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]}`,
        }}
      >
        <Box>
          <Skeleton variant="rectangular" height="0.7rem" width={60} sx={{ borderRadius: 0.5, mb: 0.5 }} />
          <Skeleton variant="rectangular" height="1.1rem" width={100} sx={{ borderRadius: 0.5 }} />
        </Box>
        <Box>
          <Skeleton variant="rectangular" height="0.7rem" width={70} sx={{ borderRadius: 0.5, mb: 0.5 }} />
          <Skeleton variant="rectangular" height="1.1rem" width={50} sx={{ borderRadius: 0.5 }} />
        </Box>
      </Box>
    );
  };
  return (
    <Paper>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
        <AirplaneTicketOutlinedIcon
          sx={{ color: theme => (theme.palette.mode === 'dark' ? green[300] : green[700]) }}
        />
        <Typography>Programação de Férias</Typography>
      </Box>

      <Box
        sx={{
          p: 2,
          borderEndStartRadius: 4,
          borderEndEndRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 50 : 800],
        }}
      >
        {!loadingData ? (
          responseData?.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 1,
                minHeight: 60,
                color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 600 : 400],
              }}
            >
              <Typography>Nenhuma programação de férias encontrada</Typography>
            </Box>
          ) : (
            responseData?.map((vacation, i) => {
              const { vacation_date_start, vacation_date_end, vacation_days } = vacation;
              const key = `vacation-${i}`;
              return (
                <Row
                  first={i === 0}
                  key={key}
                  dateStart={vacation_date_start}
                  dateEnd={vacation_date_end}
                  days={vacation_days}
                />
              );
            })
          )
        ) : (
          <FakeRow first />
        )}
      </Box>
    </Paper>
  );
}
export { VacationCard };
