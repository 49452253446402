import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, useTheme } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ScaleLoader from 'react-spinners/ScaleLoader';

interface IHelpdeskCard {
  icon: React.ReactNode;
  title: React.ReactNode;
  content: React.ReactNode;
  subcontent: React.ReactNode;
  color?: string;
  loading: boolean;
}

function HelpdeskCard({ content, icon, subcontent, title, color, loading }: IHelpdeskCard) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 1,
        p: 2,
        borderLeft: `4px solid ${color}`,

        '&:hover': {
          borderLeft: `8px solid ${color}`,
          paddingLeft: 1.5,
        },
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>

      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flex: 1,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {icon}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            width: '100%',
          }}
        >
          {content}
          {subcontent}
        </Box>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              backgroundColor: theme.palette.background.paper,
              opacity: 0.8,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
            }}
          >
            <ScaleLoader
              color={theme.palette.info.main}
              loading={loading}
              cssOverride={{
                display: 'block',
                margin: '0 auto',
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export { HelpdeskCard };
