import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Tooltip,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';

import imageCompression from 'browser-image-compression';
import { FileIcon } from 'react-file-icon';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Alert, Autocomplete } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { humanFileSize, toNumber } from '../../../utils/functions';
import { IconTypeName } from '../dashboard';
import HtmlEditor from '../../../components/HtmlEditor';
import styles from './styles';
import InputSearchAsync, { ValueProps } from '../../../components/inputs/InputSearchAsync';

moment.locale('pt-br');
moment.updateLocale(moment.locale(), { invalidDate: '00/00/0000' });

type HelpDeskNewPageProps = {
  darkMode?: boolean;
};
type QueryParams = {
  target: string;
};
export default function HelpdeskNewPage({ darkMode }: HelpDeskNewPageProps) {
  const classes = styles();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryTarget = Number.parseInt(query.get('target'), 10) | 0;
  const queryTitle = query.get('title');
  const queryText = query.get('text');
  const queryPrivate = query.get('private') === 'true';
  const refInputAttachment = useRef<HTMLInputElement | null>(null);

  const [helpdeskTarget, setHelpdeskTarget] = useState<number>(queryTarget);
  const [helpdeskTitle, setHelpdeskTitle] = useState(queryTitle);
  const [helpdeskLink, setHelpdeskLink] = useState('');
  const [helpdeskDescription, setHelpdeskDescrition] = useState(queryText);
  const [helpdeskPrivate, setHelpdeskPrivate] = useState(queryPrivate);

  const [waitingInsertHelpdesk, setWaitingInsertHelpdesk] = useState<boolean>(false);

  const [attachments, setAttachments] = useState<File[]>([]);

  const [openTargetSearch, setOpenTargetSearch] = useState(false);
  const [optionsTargetSearch, setOptionsTargetSearch] = useState<{ id: string; description: string }[]>([]);
  const loadingTargetSearch = openTargetSearch && optionsTargetSearch?.length === 0;
  const [inputTargetText, setInputTargetText] = useState('');

  const [inputTargetData, setInputTargetData] = useState<{ id: string; description: string } | null>({
    id: '',
    description: '',
  });

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  useEffect(() => {
    let active = true;

    if (!loadingTargetSearch) {
      return undefined;
    }

    (async () => {
      const response = await api.get('helpdesk/target', { params: { limit: 0 } });
      const { data } = response;

      if (active) {
        setOptionsTargetSearch(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingTargetSearch]);

  useEffect(() => {
    let active = true;
    window.history.pushState({}, '', '/helpdesk/add');
    if (queryTarget > 0) {
      (async () => {
        const response = await api.get('helpdesk/target', { params: { limit: 0, id: queryTarget } });
        const { data } = response;

        if (active) {
          setInputTargetData(data?.[0]);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    if (!openTargetSearch) {
      setOptionsTargetSearch([]);
    }
  }, [openTargetSearch]);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleAddAttachments = async (event: any) => {
    if (event.target) {
      const newAttachments = attachments;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        for await (const attachment of event?.target?.files) {
          const type = attachment.type.split('/')[0];

          if (type === 'image') {
            const compressedFile = await imageCompression(attachment, options);
            newAttachments.push(new File([compressedFile], compressedFile.name));
          } else {
            newAttachments.push(attachment);
          }
        }
      } catch (err) {
        console.error(err);
      }

      setAttachments([...newAttachments]);
    }
  };

  const handleRemoveAttachments = (index: number) => {
    if (refInputAttachment && refInputAttachment.current) refInputAttachment.current.value = '';
    const newAttachments = attachments;
    newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);
  };

  const handleInputHelpdeskTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHelpdeskTitle(event?.target?.value);
  };

  const handleInputHelpdeskLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHelpdeskLink(event?.target?.value);
  };

  const handleInputTargetChange = (value: string) => {
    setInputTargetText(value);
  };

  function handleHelpdeskPrivate() {
    setHelpdeskPrivate(!helpdeskPrivate);
  }

  const handleInsertHelpdesk = async () => {
    if (!waitingInsertHelpdesk) {
      const data = new FormData();
      data.append('target', helpdeskTarget?.toString());
      data.append('title', helpdeskTitle);
      data.append('link', helpdeskLink);
      data.append('description', helpdeskDescription);
      data.append('private', helpdeskPrivate ? '1' : '0');
      for await (const attachment of attachments) {
        data.append('files', attachment);
      }
      setWaitingInsertHelpdesk(true);
      api
        .post('helpdesk/put', data)
        .then((response: any) => {
          setWaitingInsertHelpdesk(false);
          window.location.href = `/helpdesk/${response?.data?.id}`;
        })
        .catch((error: any) => {
          const responseData = error?.response?.data;
          if (responseData) {
            let errorMessage = responseData?.message;
            if (!errorMessage) errorMessage = responseData?.error;
            if (errorMessage === 'target') errorMessage = 'É necessário um setor de atendimento do Helpdesk.';
            else if (errorMessage === 'title') errorMessage = 'É necessário informar um título para o Helpdesk.';
            else if (errorMessage === 'description') errorMessage = 'Descrição muito curta.';
            else if (errorMessage === 'insert_error') errorMessage = 'Erro ao criar Helpdesk.';
            else if (errorMessage === 'helpdesk_link_not_found') errorMessage = 'Helpdesk Link não encontrado.';
            else if (errorMessage === 'error_target') errorMessage = 'Setor não encontrado.';
            else if (errorMessage === '') errorMessage = 'Erro desconhecido';

            setSnackbarType('error');
            setSnackbarMsg(errorMessage);
            setOpenSnackbar(true);
          }
          setWaitingInsertHelpdesk(false);
        });
    }
  };
  const inputHelpdeskTarget = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-target"
        id="helpdesk_target"
        onChange={(e: ValueProps) => {
          setInputTargetData({ id: e?.id, description: e?.description });
          setHelpdeskTarget(toNumber(e?.id));
        }}
        label="Setor"
        multiple={false}
        startAdornment={<InputAdornment position="start" />}
      />
    ),
    [helpdeskTarget],
  );
  const inputHelpdeskLink = useMemo(
    () => (
      <InputSearchAsync
        fullWidth
        source="helpdesk-id"
        id="helpdesk_link"
        onChange={(e: ValueProps) => {
          setHelpdeskLink(toNumber(e?.id).toString());
        }}
        label="Helpdesk Pai"
        multiple={false}
        disabled={helpdeskTarget < 1}
        startAdornment={<InputAdornment position="start" />}
      />
    ),
    [helpdeskLink, helpdeskTarget],
  );
  const inputHelpdeskTitle = useMemo(
    () => (
      <TextField
        fullWidth
        disabled={helpdeskTarget < 1}
        id="input-title"
        label="Título"
        variant="outlined"
        type="text"
        size="small"
        value={helpdeskTitle || ''}
        onChange={handleInputHelpdeskTitle}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
        }}
      />
    ),
    [helpdeskTitle, helpdeskTarget],
  );

  const totalSize = useMemo(() => {
    let numberOfBytes = 0;
    attachments.map((attachment: File, index: number) => {
      numberOfBytes += attachment.size;
      return attachment.size;
    });

    return (
      <Typography className={classes.attachmentCountText}>{`${attachments?.length} ${
        attachments?.length === 1 ? 'arquivo selecionado' : 'arquivos selecionados'
      } (${humanFileSize(numberOfBytes)})`}</Typography>
    );
  }, [attachments]);

  return (
    <Box>
      <Box className={classes.root}>
        <Paper
          style={{
            padding: 14,
            width: '100%',
            maxWidth: 1080,
            marginBottom: 8,
          }}
        >
          <Grid container spacing={2} style={{ marginTop: 1 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <Tooltip title={`${helpdeskPrivate ? 'Helpdesk Privado' : 'Helpdesk Público'}`}>
                <IconButton
                  edge="end"
                  aria-label="lock"
                  onClick={handleHelpdeskPrivate}
                  style={{ height: 40, width: 40, marginRight: 12 }}
                  size="large"
                >
                  {!helpdeskPrivate ? <LockOpenIcon color="disabled" /> : <LockIcon color="secondary" />}
                </IconButton>
              </Tooltip>
              {inputHelpdeskTarget}
            </Grid>

            <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
              {inputHelpdeskTitle}
            </Grid>

            <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
              {inputHelpdeskLink}
            </Grid>
          </Grid>
          <Typography className={classes.titleDescription}>Descrição</Typography>

          <Box
            style={{
              borderRadius: 4,
              color: '#060606',

              flex: 1,
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <HtmlEditor
              disable={helpdeskTarget < 1}
              name="input-content"
              onChange={(content: any) => setHelpdeskDescrition(content)}
              setContents={helpdeskDescription}
            />
          </Box>
        </Paper>
        <Paper
          style={{
            width: '100%',
            maxWidth: 1080,
            marginBottom: 16,
            marginTop: 8,
          }}
        >
          <Box m={2} display="flex" alignItems="center" style={{ marginBottom: attachments?.length > 0 ? 0 : 5 }}>
            <input
              style={{ display: 'none' }}
              id="icon-button-file"
              type="file"
              multiple
              ref={refInputAttachment}
              onChange={handleAddAttachments}
              disabled={helpdeskTarget < 1}
            />

            <Tooltip title={`${helpdeskTarget < 1 ? '' : 'Adicionar Anexos'}`} aria-label="anexos-helpdesk">
              <label htmlFor="icon-button-file">
                <Button color="secondary" variant="contained" component="span" disabled={helpdeskTarget < 1}>
                  <NoteAddIcon style={{ width: 18, height: 18, marginRight: 8 }} /> ANEXOS
                </Button>
              </label>
            </Tooltip>

            {totalSize}
          </Box>
          <Box className={classes.attachmentContainer}>
            {attachments?.map((attachment: File, index: number) => {
              const nameSplit = attachment.name.toLocaleLowerCase().split('.');
              const name = attachment.name.toLocaleLowerCase();
              const type = nameSplit?.length > 0 ? nameSplit[nameSplit?.length - 1] : 'none';
              const size = humanFileSize(attachment.size);
              const iconType = IconTypeName(type);

              return (
                <Paper className={classes.attachmentItemContainer} key={index}>
                  <Tooltip title={name} aria-label="attachment-remove-helpdesk">
                    <Typography className={classes.attachmentItemName}>{name}</Typography>
                  </Tooltip>

                  <Box px={2} py={0.5} height={50}>
                    <FileIcon
                      extension={type}
                      type={iconType}
                      foldColor={darkMode ? '#808080' : '#4e4e4e'}
                      glyphColor={darkMode ? '#808080' : '#4e4e4e'}
                      gradientColor={darkMode ? '#808080' : '#4e4e4e'}
                      labelColor={darkMode ? '#808080' : '#4e4e4e'}
                    />
                  </Box>

                  <Typography className={classes.attachmentItemSize}>{size}</Typography>
                  <Tooltip title="Remover Anexo" aria-label="attachment-remove-helpdesk">
                    <Button
                      size="small"
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        handleRemoveAttachments(index);
                      }}
                    >
                      <DeleteIcon style={{ height: 18 }} />
                    </Button>
                  </Tooltip>
                </Paper>
              );
            })}
          </Box>
        </Paper>
        <Paper
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: 14,
            width: '100%',
            maxWidth: 1080,
          }}
        >
          <Tooltip
            title={`${helpdeskTarget < 1 ? '' : 'Realizar abertura de um Helpdesk'}`}
            aria-label="insert-helpdesk"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleInsertHelpdesk}
              disabled={helpdeskTarget < 1 || waitingInsertHelpdesk}
              style={{ width: 120 }}
            >
              {waitingInsertHelpdesk ? <CircularProgress size={24} /> : 'CRIAR'}
            </Button>
          </Tooltip>
        </Paper>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarType} onClose={handleSnackbarClose}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
