import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, green, grey, orange, red, yellow } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      background: '#78909c',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      maxHeight: '320px',
      padding: theme.spacing(4),
    },
    text: {
      padding: theme.spacing(4),
      color: grey[50],
      maxWidth: '480px',
      textAlign: 'center',
    },
    button: {
      padding: theme.spacing(2),
      pointerEvents: 'all',
    },
  });
});
