import React from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography, useTheme } from '@mui/material';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const { value } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const colors = [
    isDarkMode ? '#ff0000' : '#ff0000',
    isDarkMode ? '#ffa700' : '#ffa700',
    isDarkMode ? '#fff400' : '#ffff00',
    isDarkMode ? '#a3ff00' : '#a3ff00',
    isDarkMode ? '#2cba00' : '#2cba00',
  ];
  const circularColor = colors[Math.round(((colors.length - 1) * value) / 100)];
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'absolute',
          color: 'rgba(0 ,0 ,0 ,0.05)',
        }}
        size={40}
        thickness={4.5}
        {...props}
        value={100}
      />

      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color: circularColor,
          border: '1px solid rgba(0 ,0 ,0 ,0.3)',
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            border: `1px solid rgba(0 ,0 ,0 ,0.3)`,
            width: '80%',
            height: '80%',
            borderRadius: '50%',
            position: 'absolute',
          }}
        />
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{
            fontWeight: 600,
            fontSize: 11,
          }}
        >{`${Math.round(value)}`}</Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            fontSize: 8,
          }}
        >
          %
        </Typography>
      </Box>
    </Box>
  );
}
export { CircularProgressWithLabel };
